import React from 'react';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';
import { Container, Form, Row, Col, Modal, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../../lib/api/HttpService';
import { displayAlert } from '../../../../utilities/Response';
import { getCookie } from '../../../../utilities/Auth';
import { formatFiscalMonth } from '../../../../helpers/Date';
import { _delay } from '../../../../helpers/Util';

class PostJournalEntryDialog extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			fiscalMonth: '',
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		const company = await this.api.getCompany();
		const company2 = await this.api.getCompany2();

		let fiscalMonth = new Date();
		if (company2.autodeffmo) {
			fiscalMonth = new Date(
				fiscalMonth.setMonth(fiscalMonth.getMonth() + company.fiscaloffset)
			);
		} else {
			fiscalMonth = new Date(company2.closedglfmo);
			fiscalMonth.setMonth(fiscalMonth.getMonth() + 1);
		}

		this.setState({
			fiscalMonth: fiscalMonth,
		});
	}

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.fiscalMonth = date));
	};

	handleClose = () => {
		this.props.onCancel();
	};

	handlePost = async () => {
		if (!this.state.fiscalMonth) {
			displayAlert('danger', 'Please select a fiscal month.');
			return;
		}

		if (!this.props.hasData) {
			displayAlert('danger', 'No data available.');
			return;
		}

		this.dMloader(true);

		await this.api
			.postJournalEntryFiscalMonth({
				fiscalMonth: formatFiscalMonth(this.state.fiscalMonth),
			})
			.then(() => {
				displayAlert('success', 'Successfully posted entries.');
				this.props.onCancel();
				this.props.onSuccess();
			})
			.catch((error) => {
				displayAlert('danger', error.response.data.userError);
			})
			.finally(() => this.dMloader(false, true));
	};

	render() {
		return (
			<Modal
				size="md"
				show={this.props.isShowing}
				onHide={() => this.hide()}
				aria-labelledby="example-modal-sizes-title-lg"
				backdrop="static"
				centered
			>
				<Modal.Header>
					<Modal.Title>
						Would you like to post these {this.props.totalEntries} journal
						entries?
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="align-items-center">
						<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
							<Form.Label htmlFor="inputPassword5" className="ilabel">
								Fiscal Month
							</Form.Label>
						</Col>
						<Col>
							<DatePicker
								selected={this.state.fiscalMonth}
								onChange={this.handleDateChange}
								className="form-control"
								placeholderText="Please select"
								showMonthYearPicker
								dateFormat="MM/yyyy"
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={this.handlePost}>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(PostJournalEntryDialog);
