import React from 'react';
import { Col, Row, Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import URI from '../../../defaults/RoutesDefault';
import { ApiService } from '../../../lib/api/HttpService';
import AccountSettingsReceivableDialog from './AccountSettingsReceivableDialog';
import { displayAlert } from '../../../utilities/Response';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import { debounce } from 'lodash';
import ConfirmModal from '../../../app/components/modal/ConfirmModal';
import { showLoading } from '../../../helpers/Loading';

class AccountSettingsReceivable extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			checks: [],
			totalChecked: 0,
			data: [],
			isShowing: false,
			selectedData: {},
			dataIsLoaded: false,
			showModal: false,
			sortProperty: 'type asc',
		};

		this.changePageHandler = debounce(this.handleChangePage.bind(this), 200);
		this.api = new ApiService();
	}

	componentInit() {
		this.setTitle('Receivable  | Account Settings');
	}

	async componentDidMount() {
		const data = await this.fetchItems();
		this.renderData(data);
		this.enableSearchAndSortTable();
	}

	async fetchItems() {
		this.setState({
			dataIsLoaded: false,
		});

		let data = await this.api.get('pospaytypes', `?${this.buildFilters()}`);

		if (data) {
			this.setState({
				dataIsLoaded: true,
				data: data,
			});
		}

		return data;
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						self.setState(
							{
								sortProperty: `${_e.attributes['data-field'].value} ${
									hasClass('desc', _e) ? 'asc' : 'desc'
								}`,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	buildFilters() {
		return `&$orderby=${this.state.sortProperty}`;
	}

	handleSelectAllChecks = () => {
		let checks = [];
		if (this.state.checks.length !== this.state.data.length) {
			checks = this.state.data.map((item) => {
				return item.payType;
			});
		}

		this.setState({
			checks: checks,
		});
	};

	handleChangePage = async () => {
		this.fetchItems();
	};

	handleCheck = (payType) => {
		const checks = this.state.checks;
		const index = _.findIndex(checks, (type) => type === payType);

		index > -1 ? checks.splice(index, 1) : checks.push(payType);

		this.setState({
			checks: checks,
		});
	};

	uncheckAll() {
		const checks = document.querySelectorAll(
			'.chk-receivable-items-item input'
		);
		if (checks) {
			checks.forEach((e) => {
				e.checked = false;
			});
		}
	}

	handleDelete = () => {
		const records = this.state.checks.length;
		this.state.checks.map((type, i) => {
			this.api
				.deletePosPaymentType({
					type: type,
				})
				.then((response) => {
					if (i === records - 1) {
						let msg = this.state.totalChecked > 1 ? 'records' : 'record';

						displayAlert('success', `Deleted ${records} ${msg} successfully`);

						setTimeout(async () => {
							this.setState({
								dataIsLoaded: false,
								checks: [],
								showModal: false,
							});

							this.fetchItems();
						});
					}
				})
				.catch((error) => {
					displayAlert('danger', error.response.data.userError);
				});
		});
	};

	handleModalData = async () => {
		this.setState({
			isShowing: false,
			dataIsLoaded: false,
			selectedData: {},
		});

		let payments = await this.api.getPaymentTypes();
		await this.renderData(payments);
	};

	toggleModal = (e) => {
		this.setState({
			showModal: !this.state.showModal,
		});
	};

	// Refresh data
	refreshDataAfterDelete = (e) => {
		const headingCheck = document.getElementById('inline-check-th-0');

		this.setState({
			checks: [],
			totalChecked: 0,
			showModal: false,
			deleteResponses: [],
		});
		headingCheck.checked = false;

		this.fetchItems();

		this.uncheckAll();
	};

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	render() {
		return (
			<>
				<div className="bg-secondary-grey px-4 roundedx-4">
					<Row
						xs={'auto'}
						className="py-4 justify-content-between align-items-center"
					>
						<Col>
							<Row className="align-items-center px-3">
								<Col className="px-3">
									<h6 className="fw-bold mb-0">Cash Receipt Payment Types</h6>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row>
								<Col>
									<Button
										as={Link}
										to="#"
										variant="ivory"
										size="sm"
										className={`btn-icon btn-action ${
											this.state.checks.length === 0 ? 'disabled' : ''
										}`}
										disabled={this.state.checks.length === 0}
										onClick={this.toggleModal}
									>
										<i className="ri-close-line"></i> Delete
									</Button>
								</Col>
								<Col>
									<Button
										variant="primary"
										size="sm"
										className={`btn-icon btn-action`}
										onClick={() => {
											this.setState({
												isShowing: true,
												selectedData: {},
											});
										}}
									>
										<i className="ri-add-line"></i> Add
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>

				<Row xs={1} className="mb-4 pb-2">
					<Col>
						<div className="table-gradient">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading">
										<th align="middle" className="mw-70px">
											<div className="d-flex justify-content-center">
												<Form.Check
													label=""
													type="checkbox"
													name={`inline-check-th-0`}
													checked={
														this.state.checks.length === this.state.data.length
													}
													className={
														this.state.checks.length === this.state.data?.length
															? 'line'
															: ''
													}
													onChange={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th>
											<span className="sort active asc" data-field="type">
												Name
											</span>
										</th>
										<th>
											<span className="sort" data-field="acct">
												Cash Account No.
											</span>
										</th>
										<th>
											<span className="sort" data-field="accountn">
												Cash Account Name
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{!this.state.dataIsLoaded
										? showLoading()
										: this.state.data.map((item, i) => (
												<tr
													key={i}
													data-key={i}
													className={this.state.checks[i] ? `active` : ''}
												>
													<td>
														<div className="d-flex justify-content-center">
															<Form.Check
																label=""
																type="checkbox"
																id={`chk-receivable-items-${i}`}
																className="chk-receivable-items-item"
																// checked={this.state.checks.includes(
																//     item.type
																// )}
																onChange={() => {
																	this.handleCheck(item.type);
																}}
															/>
														</div>
													</td>
													<td>
														<Link
															onClick={() => {
																this.setState({
																	isShowing: true,
																	selectedData: item,
																});
															}}
															to="#"
															className="text-charcoal hover-view-icon"
														>
															{item.type}
														</Link>
													</td>
													<td>{item.acct}</td>
													<td>{item.accountn}</td>
												</tr>
										  ))}
								</tbody>
							</Table>
							<ConfirmModal
								refreshData={this.refreshDataAfterDelete.bind(this)}
								show={this.state.showModal}
								toggleModal={this.toggleModal}
								confirmAction={this.handleDelete}
							/>
						</div>
					</Col>
				</Row>
				{this.state.isShowing && (
					<AccountSettingsReceivableDialog
						selectedData={this.state.selectedData}
						isShowing={this.state.isShowing}
						onOkay={this.handleModalData}
						onCancel={() => {
							this.setState({
								isShowing: false,
								selectedData: {},
							});
						}}
					/>
				)}
			</>
		);
	}
}

export default WithRouter(AccountSettingsReceivable);
