import { apiClient } from 'api/apiClient';
import type { TCompany2 } from 'legacy/lib/api/types/Company2';

const RESOURCE = '/company2';

export const patchCompanyTwo = async (
	params: TCompany2
): Promise<TCompany2> => {
	const { data } = await apiClient.patch(RESOURCE, params);
	return data;
};

export const getCompanyTwo = async (): Promise<TCompany2> => {
	const { data } = await apiClient.get(RESOURCE);
	return data;
};
