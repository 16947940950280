import React from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';

interface Props {
	message: string;
	primaryButtonTitle?: string;
	secondaryButtonTitle?: string;
	onPrimary: () => {};
	onSecondary?: () => {};
	hasSecondary: boolean;
}

interface State {}

export class BasicModal extends React.Component<Props, State> {
	hasSecondary: boolean;

	constructor(props: Props) {
		super(props);

		this.hasSecondary = this.props.hasSecondary ?? true;
	}

	render() {
		return (
			<Modal
				size="sm"
				show={true}
				aria-labelledby="action-modal"
				className="a-modal mx-sm-5 mx-lg-0"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Container>
						<Col>
							<Row className="px-sm-0 px-lg-1">
								<Col>
									<Row className="text-center mx-auto fw-bold">
										<p>{this.props.message}</p>
									</Row>
								</Col>
							</Row>
						</Col>
					</Container>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4">
					<Col>
						<Row className="mb-3">
							<Col>
								<Button
									variant="primary"
									onClick={this.props.onPrimary}
									className="w-100"
								>
									{this.props.primaryButtonTitle ?? 'OK'}
								</Button>
							</Col>
						</Row>
						{this.hasSecondary && (
							<Row>
								<Col>
									<Button
										variant="trans-light border-secondary-ash"
										onClick={this.props.onSecondary}
										className="w-100"
									>
										{this.props.secondaryButtonTitle ?? 'Cancel'}
									</Button>
								</Col>
							</Row>
						)}
					</Col>
				</Modal.Footer>
			</Modal>
		);
	}
}
