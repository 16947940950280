import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import { FormEvent } from '../../../../utilities/FormEvent';
import ThreadComponent from '../../ThreadComponent';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { InputModal } from '../../../../app/components/modal/InputModal';
import { isNumeric } from '../../../../helpers/Util';
import { displayAlert } from '../../../../utilities/Response';
import Spinner from '../../../../app/components/help/Spinner';
import { stringToDate } from '../../../../helpers/Date';

class ProjectProposalAddAdditionalDeposit extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			proposal: {},
			requiredDate: null,
			showModal: false,
			totalEstimated: 0,
		};

		this.formEvent = new FormEvent(`project_proposal_edit`);
	}

	componentDidMount() {
		this.setFormAction(true);
		console.log(this.props.proposal);
		if (Object.keys(this.props.proposal).length > 0) {
			let proposal = this.props.proposal || {};
			this.setState({
				proposal,
				requiredDate: proposal.adddepdt
					? stringToDate(proposal.adddepdt)
					: null,
			});
			this.setPrices();
		}
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.proposal !== this.props.proposal) {
			let proposal = this.props.proposal || {};
			this.setState({
				proposal,
				requiredDate: proposal.adddepdt
					? stringToDate(proposal.adddepdt)
					: null,
			});
			this.setPrices();
		}
	}

	handleDateChange = (name, date) => {
		this.setState({
			[name]: date,
		});

		this.formEvent.handleDateChange('YYYY-MM-DD', 'adddepdt', date);
	};

	setDefaultDate() {
		const defaultDate = new Date();
		this.setState({
			requiredDate: defaultDate,
		});

		this.formEvent.handleDateChange('YYYY-MM-DD', 'adddepdt', defaultDate);
	}

	setPrices() {
		const items = this.props.activeItems;
		const totalPrice = items.reduce(
			(total, item) => total + item.proposalPrice,
			0
		);

		const salesTax = items.reduce((total, item) => total + item.propsTax, 0);
		const freight = items.reduce((total, item) => total + item.estdprice, 0);
		const requestedDeposit = items.reduce(
			(total, item) => total + item.estdep,
			0
		);
		this.setState({
			totalEstimated: (totalPrice + freight + salesTax).toFixed(2),
		});
	}

	toggleModal = (e) => {
		this.setState({
			showModal: !this.state.showModal,
		});
	};

	handleCalculate = (input) => {
		let value = 0;

		if (isNumeric(input)) {
			value = parseFloat(input) < 0 ? 0 : parseFloat(input).toFixed(2);
			const addDepAmt = value * this.state.totalEstimated * 0.01;

			this.setState({
				proposal: {
					...this.state.proposal,
					addDepAmt: parseFloat(addDepAmt).toFixed(2),
				},
			});

			setTimeout(function () {
				const addDepAmtFld = document.getElementById('addDepAmt');
				const e = new Event('input', { bubbles: true });
				addDepAmtFld.dispatchEvent(e);
			}, 100);

			this.toggleModal();
		} else {
			displayAlert('danger', 'Please enter a number');
		}
	};

	render() {
		if (this.props.dataIsLoaded) {
			return (
				<>
					<Form.Group>
						<Row xs={1} lg={2} className="mb-5">
							<Col>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											Additional Deposit Required Amount
										</Form.Label>
									</Col>
									<Col>
										<Row xs={1} lg={2}>
											<Col className="mb-2 mb-lg-0">
												<Form.Control
													type="number"
													placeholder="Please enter"
													name="addDepAmt"
													id="addDepAmt"
													data-number={true}
													onFocus={this.setDefaultDate.bind(this)}
													defaultValue={this.state.proposal.addDepAmt}
													onChange={this.formEvent.handleTextChange.bind(this)}
												/>
											</Col>
											<Col>
												<Button
													variant="primary"
													size="md"
													style={{
														minWidth: '120px',
													}}
													onClick={this.toggleModal}
												>
													Calculate
												</Button>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="align-items-start mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0 mt-0 mt-lg-2 pt-0 pt-lg-1">
											Required Date
										</Form.Label>
									</Col>
									<Col>
										<Row lg={2}>
											<Col>
												<DatePicker
													selected={this.state.requiredDate}
													// minDate={this.state.minDate}
													onChange={this.handleDateChange.bind(
														this,
														'requiredDate'
													)}
													showMonthDropdown="true"
													showYearDropdown="true"
													className="form-control form-control-md"
													placeholderText="Select"
												/>

												{/* <Form.Check
                                                    inline
                                                    label="Show Actual Deposits"
                                                    name="showActDep"
                                                    type="checkbox"
                                                    id={`check-group-payment`}
                                                    className="vtop mt-2"
                                                    defaultChecked={
                                                        this.state.proposal
                                                            .showActDep
                                                    }
                                                    onClick={this.formEvent.handleCheckChange.bind(
                                                        this
                                                    )}
                                                /> */}
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Form.Group>
					<InputModal
						size={'md'}
						message={'Enter Additional Deposit Percent'}
						placeholder={
							'Enter a percentage of the total proposal amount (i.e. 25 for 25%)'
						}
						show={this.state.showModal}
						onCancel={this.toggleModal}
						onConfirm={this.handleCalculate.bind(this)}
					/>
				</>
			);
		} else {
			return <Spinner isChild={true} />;
		}
	}
}

export default WithRouter(ProjectProposalAddAdditionalDeposit);
