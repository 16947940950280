import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CheckbookReconcileHeader } from 'legacy/templates/modules/accounts-payable/checkbook/CheckbookReconcile/CheckbookReconcileHeader';
import { CheckbookReconcileFilters } from './CheckbookReconcileFilters';
import { CheckbookReconcileTotals } from './CheckbookReconcileTotals';
import { CheckbookReconcileChecksTotals } from './CheckbookReconcileChecksTotals';
import { CheckbookReconcileDepositsTotals } from './CheckbookReconcileDepositsTotals';
import { CheckbookReconcileChecksTable } from './CheckbookReconcileChecksTable';
import { CheckbookReconcileDepositsTable } from './CheckbookReconcileDepositsTable';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import NotFoundPage from 'legacy/app/pages/Home/NotFoundPage';

const CheckbookReconcile = () => {
	const { started } = useCheckbookReconcile();

	const showFeature = useFeature(FeatureFlags.CheckbookReconcile);

	if (!showFeature) {
		return <NotFoundPage />;
	}

	return (
		<>
			<CheckbookReconcileHeader />

			<Container fluid className="tw-py-2 tw-px-4">
				<Row>
					<Col sm="12">
						<CheckbookReconcileFilters />
						{started && <CheckbookReconcileTotals />}
					</Col>
				</Row>

				{started && (
					<>
						<Row className="py-2">
							<CheckbookReconcileChecksTable />
							<CheckbookReconcileDepositsTable />
						</Row>

						<Row>
							<CheckbookReconcileChecksTotals />
							<CheckbookReconcileDepositsTotals />
						</Row>
					</>
				)}
			</Container>
		</>
	);
};

CheckbookReconcile.displayName = 'CheckbookReconcile';

export { CheckbookReconcile };
