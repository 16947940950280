import { makeAutoObservable } from 'mobx';
import { ProjectsService, AddProjectParams } from './api/ProjectsService';

export default class ProjectsAddViewModel {
	private projectsService: ProjectsService;

	constructor(projectsService: ProjectsService) {
		this.projectsService = projectsService;

		makeAutoObservable(this);
	}

	private submitAddProject(params: AddProjectParams) {
		// 1. Make the necessary validations
		// 2. Submit the request
		this.projectsService
			.addProject(params)
			.then((response) => {})
			.catch((e) => {});
	}
}
