import { ApiService } from '../../../lib/api/HttpService';
import dayjs from 'dayjs';
import { isValidUrl } from '../../../utilities/Validation';

const fetchDocuments = async (objectId, objectType) => {
	const filter = `?$filter=ObjectIdStr eq '${objectId}' and ObjectType eq '${objectType}' and FileContext eq 'ObjectAttachment' and Status eq 'Active'`;
	const documents = await new ApiService().get('objectfiles', filter);

	return documents.map((document) => {
		const { file } = document;
		return {
			id: file.id,
			filetype: isValidUrl(file.location)
				? 'url'
				: file.label?.split('.').length > 1
				? file.label?.split('.').pop().toLowerCase()
				: '',
			filename: file.label ?? file.location,
			uploadedBy: file.createdByUserName,
			dateUploaded: file.createdDateTimeUtc,
			location: file.location,
			isChecked: false,
			thumbnail: undefined,
			formattedDateUploaded: file.createdDateTimeUtc
				? dayjs(new Date(file.createdDateTimeUtc)).format('MM/DD/YYYY')
				: '',
		};
	});
};

const uploadDocuments = async (files, objectType, objectId, onProgress) => {
	let progress = 0;
	onProgress(progress);
	for (const file of files) {
		await new ApiService().saveFile(
			{
				file: file,
				objectType: objectType,
				objectId: objectId,
				fileContext: 'ObjectAttachment',
				fileStore: 1,
				fileType: file.type.includes('image') ? 1 : 0,
			},
			(progressEvent) => {
				const percentCompleted = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);
				if (percentCompleted === 100) {
					progress++;
					onProgress((progress / files.length) * 100);
				}
			}
		);
	}
};

const uploadLink = async (objectType, objectId, url, label) => {
	await new ApiService().saveFile({
		objectType: objectType,
		objectId: objectId,
		fileContext: 'ObjectAttachment',
		fileStore: 1,
		fileType: 'Link',
		downloadUrl: url,
		label: label,
	});
};

const deleteFiles = async (fileIds) => {
	const promises = [];

	for (const id of fileIds) {
		promises.push(await new ApiService().deleteFile(id));
	}

	await Promise.all(promises);
};

const fetchThumbnails = async (fileIds, onLoopFinish) => {
	const promises = [];

	for (const id of fileIds) {
		const response = await new ApiService().getFile(
			`${id}?maxWidth=200&maxHeight=200`
		);
		promises.push(response);
		onLoopFinish(id, response);
	}

	await Promise.all(promises);
};

const fetchFile = async (id) => {
	return await new ApiService().getFile(id);
};

const editFile = async (id, params) => {
	return await new ApiService().editFile(id, params);
};

const getDocumentUrl = (documentId) => {
	return `${new ApiService().getApiBaseUrl()}/api/v1/files/${documentId}`;
};

const getMaxFileSize = () => {
	return new ApiService().getMaxFileSize();
};

export {
	fetchDocuments,
	uploadDocuments,
	deleteFiles,
	fetchThumbnails,
	uploadLink,
	fetchFile,
	editFile,
	getDocumentUrl,
	getMaxFileSize,
};
