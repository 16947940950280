import { apiClient } from 'api/apiClient';

const RESOURCE = '/statuscodes';

export interface IStatusCode {
	statusName: string;
	priority: number;
	setStatus: number;
	setWhen: number;
	orWhen: boolean;
	setWhen2: number;
	statusNumber: number;
	color: number;
	textWhite: boolean;
	locked: boolean;
	passwordProtect: boolean;
	lockPos: boolean;
	ssmaTimeStamp: string;
	lockEdits: boolean;
	hexColor: string;
}

export const getStatusCodes = async (): Promise<IStatusCode[]> => {
	const { data } = await apiClient.get(`${RESOURCE}`);

	return data;
};
