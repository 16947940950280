import React, { useEffect } from 'react';
import { Table, Form, Spinner } from 'react-bootstrap';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import { usePostTemporaryReconciliationsTotals } from 'api/hooks/usePostTemporaryReconciliationsTotals';
import { type ITemporaryReconciliationsTotalsParams } from 'api/resources/temporaryReconciliations';
import { formatCurrency } from 'legacy/utilities/formatCurrency';
import { formatDate } from 'legacy/utilities/formatDate';

const CheckbookReconcileTotals = () => {
	const { started, checkbook, updateTotals, setUpdateTotals } =
		useCheckbookReconcile();

	const {
		mutate: postReconciliationTotals,
		isSuccess: isPostReconciliationTotalsSuccess,
		isLoading,
		data: totals,
	} = usePostTemporaryReconciliationsTotals();

	useEffect(() => {
		if (started && checkbook && updateTotals) {
			const payload: ITemporaryReconciliationsTotalsParams = {
				account: checkbook.cashAccount,
				statementBalance: checkbook.endingBalance,
				statementEndingDate: checkbook.statementDate,
				doClearFlagsChecks: true,
				doClearFlagsDeps: true,
				doSumFlagsChecks: true,
				doSumFlagsDeps: true,
			};
			postReconciliationTotals(payload);
		}
	}, [started, checkbook, updateTotals, postReconciliationTotals]);

	useEffect(() => {
		if (isPostReconciliationTotalsSuccess) {
			setUpdateTotals(false);
		}
	}, [setUpdateTotals, isPostReconciliationTotalsSuccess, totals]);

	return (
		<div className="table-gradient">
			<Table borderless>
				<thead>
					<tr>
						<th className="fs-lg">
							Statement Balance as
							<br />
							of {formatDate(checkbook?.statementDate ?? '')}
						</th>
						<th className="fs-lg"></th>
						<th className="fs-lg">Deposits in Transit</th>
						<th className="fs-lg"></th>
						<th className="fs-lg">Outstanding Checks</th>
						<th className="fs-lg"></th>
						<th className="fs-lg">Balance</th>
						<th className="fs-lg">
							Computer Balance as
							<br />
							of {formatDate(checkbook?.statementDate ?? '')}
						</th>
						<th className="fs-lg">Difference</th>
					</tr>
				</thead>
				<tbody>
					{isLoading ? (
						<tr>
							<td colSpan={9}>
								<div className="tw-h-48 tw-flex tw-justify-center tw-items-center">
									<Spinner animation="grow" size="sm" variant="primary" />
								</div>
							</td>
						</tr>
					) : (
						<tr>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={formatCurrency(totals?.balance ?? 0)}
									disabled
								/>
							</td>
							<td className="align-middle text-secondary-ash">
								<i className="ri-add-line ri-lg"></i>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={formatCurrency(totals?.depositsInTransit ?? 0)}
									disabled
								/>
							</td>
							<td className="align-middle text-secondary-ash">
								<span className="fsx-24">-</span>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={formatCurrency(totals?.outstandingChecks ?? 0)}
									disabled
								/>
							</td>
							<td className="align-middle text-secondary-ash">
								<span className="fsx-24">=</span>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={formatCurrency(totals?.balance ?? 0)}
									disabled
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={formatCurrency(totals?.computerBalance ?? 0)}
									disabled
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									size="sm"
									value={formatCurrency(totals?.difference ?? 0)}
									disabled
								/>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
};

CheckbookReconcileTotals.displayName = 'CheckbookReconcileTotals';

export { CheckbookReconcileTotals };
