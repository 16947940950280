import { useQuery } from '@tanstack/react-query';
import { getReconciliations } from 'api/resources/reconciliations';
import type { IReconciliation } from 'api/resources/reconciliations';
import { ECacheKeys } from 'cache/CacheKeys';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetReconciliations = (
	options?: UseQueryOptions<IReconciliation[]>
) => {
	const query = useQuery<IReconciliation[]>({
		queryKey: [ECacheKeys.Reconciliations],
		queryFn: getReconciliations,
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
