export const ReportRequiredFields: any = {
	'Client Status': ['client'],
	Budget: ['project_/_order'],
	'Deposit Analysis': ['project_/_order'],
	'Aged Accounts Receivable': ['fiscal_month', 'aging_date'],
	'Open Client Deposit': ['fiscal_month', 'aging_date'],
	Statements: ['statement_date'],
	'Deposit Slips': ['cash_account'],
	'Sales Journal': ['starting_fiscal_mo.', 'ending_fiscal_mo.'],
	'Aged Accounts Payable': ['fiscal_month', 'aging_date'],
	'Open Vendor Deposit': ['fiscal_month'],
	'Work In Process': ['fiscal_month'],
	'Check Stub': ['cash_account', 'cash_no.'],
	'Purchases Journal': ['starting_fiscal_mo.', 'ending_fiscal_mo.'],
	'Disbursements Journal (Check Register)': [
		'starting_fiscal_mo.',
		'ending_fiscal_mo.',
	],
	'Monthly Income Statement (P&L)': ['month_ending', 'year'],
	'Balance Sheet': ['month_ending', 'year'],
	'Trial Balance': ['fiscal_mo._ending'],
	'Transaction Register': ['starting_fiscal_mo.', 'ending_fiscal_mo.'],
	'Account Inquiry': ['starting_fiscal_mo.', 'ending_fiscal_mo.'],
};
