import URI from 'legacy/defaults/RoutesDefault';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';

export type TTabOption = 'Payments' | 'Deposits';

type TTabsProps = {
	toggleTab: (tab: TTabOption) => void;
	activeTab: TTabOption;
};

const DesignPayTabs = ({ toggleTab, activeTab }: TTabsProps): JSX.Element => {
	return (
		<ul className="tw-flex tw-gap-4 tw-items-center tw-p-0 tw-pt-4 tw-list-none">
			<li>
				<BaseButton
					buttonClass={`tw-text-white tw-cursor-pointer tw-p-3 tw-rounded-lg ${
						activeTab === 'Payments'
							? 'tw-bg-[#211F211A]'
							: 'tw-bg-[transparent]'
					}`}
					onClick={() => toggleTab('Payments')}
					text="Payments"
				/>
			</li>
			<li>
				<BaseButton
					buttonClass={`tw-text-white tw-cursor-pointer tw-p-3 tw-rounded-lg ${
						activeTab === 'Deposits'
							? 'tw-bg-[#211F211A]'
							: 'tw-bg-[transparent]'
					}`}
					onClick={() => toggleTab('Deposits')}
					text="Deposits"
				/>
			</li>
			<li>
				<Link
					className="tw-text-white tw-text-sm tw-no-underline"
					to={URI.settings.paymentSettings.connected}
				>
					Settings
				</Link>
			</li>
		</ul>
	);
};
DesignPayTabs.displayName = 'DesignPayTabs';

export default DesignPayTabs;
