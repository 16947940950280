import React from 'react';

import { Button } from 'react-bootstrap';

import { useHasAccess } from '../../../context/security';

export default function SecureBootstrapButton({
	attributeNo,
	attributeType,
	children,
	...props
}) {
	const hasAccess = useHasAccess(attributeNo, attributeType);
	const isDisabled = !hasAccess ? true : props.disabled;
	const { to } = props;
	return (
		<Button
			{...props}
			// Need to do this because the button will continue to work otherwise even if disabled when rendered as a React Router Link component
			to={isDisabled ? undefined : to}
			disabled={isDisabled}
			onClick={isDisabled ? undefined : props.onClick}
			style={{
				...props.style,
				...(isDisabled && { pointerEvents: 'none' }),
			}}
		>
			{children}
		</Button>
	);
}
