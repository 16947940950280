import { Dropzone } from './Dropzone';
import { DocumentsGrid } from './DocumentsGrid';
import { DocumentsTable } from './DocumentsTable';
import { Col } from 'react-bootstrap';
import React from 'react';

export const Content = ({
	data,
	documents,
	viewType,
	gridColumns,
	uploadingProgress,
	isSearching,
	isLoading,
	onSelect,
	onSort,
	onDrop,
	onView,
	onEdit,
	onDownload,
	onDelete,
	onSearch,
	onSelectedTypesChange,
	onDateRangeChange,
	dropzoneRef,
}) => {
	return (
		<div>
			<Dropzone ref={dropzoneRef} onDrop={onDrop}>
				{data.documents?.length || isSearching || isLoading ? (
					<div>
						<div
							className={`${
								viewType === 'grid' ? 'd-block' : 'd-none'
							} position-absolute w-100`}
							style={{
								maxHeight: '1000px',
								overflow: 'scroll',
							}}
						>
							<DocumentsGrid
								data={data}
								columns={gridColumns}
								onView={onView}
								onEdit={onEdit}
								onDownload={onDownload}
								onDelete={onDelete}
							/>
						</div>
						<div
							className={`${
								viewType !== 'grid' ? 'd-block' : 'd-none'
							} position-absolute w-100`}
						>
							<DocumentsTable
								data={data}
								documents={documents}
								isSearching={isSearching}
								isLoading={isLoading}
								onSelect={onSelect}
								onSort={onSort}
								onView={onView}
								onEdit={onEdit}
								onDownload={onDownload}
								onSearch={onSearch}
								onSelectedTypesChange={onSelectedTypesChange}
								onDateRangeChange={onDateRangeChange}
							/>
						</div>
					</div>
				) : null}
			</Dropzone>
			{uploadingProgress !== undefined && (
				<Col
					xs={12}
					sm={6}
					className="d-flex documents-progressbar sticky-bottom bg-black p-4 text-white"
				>
					<div className="d-flex flex-grow-1 align-items-center overflow-hidden">
						<span>Uploading file </span>
						<div className="d-flex flex-grow-1 bg-white ms-3">
							<div
								className="bg-secondary-green"
								style={{
									'--progress': `${uploadingProgress}%`,
								}}
							></div>
						</div>
					</div>
				</Col>
			)}
		</div>
	);
};
