import UnitsOfMeasureDropdown from 'legacy/app/components/dropdowns/UnitsOfMeasuresDropdown';
import FormCurrencyInput from 'legacy/app/components/form/FormCurrencyInput';
import Label from 'legacy/app/components/label/Label';
import { TWhatChangedComponent } from 'legacy/lib/api/types/WhatChangedComponent';
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

const FeeInputs = ({
	disableInputs,
	handleRecalculate,
}: {
	disableInputs: boolean;
	handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void;
}) => {
	const methods = useFormContext();

	return (
		<div className="tw-mt-8 tw-flex tw-gap-4">
			<div>
				<Label label="Unit Base Cost" className="!tw-text-left tw-mb-2" />
				<div className="tw-flex tw-gap-4 tw-pr-8">
					<div className="tw-max-w-36">
						<Controller
							control={methods.control}
							name="feeunitcost"
							render={({ field }) => (
								<FormCurrencyInput
									allowNegativeValue
									id="Unit Base Cost"
									disabled={disableInputs}
									value={field.value}
									maxLength={15}
									onValueChange={field.onChange}
									onBlur={() => {
										handleRecalculate(
											TWhatChangedComponent.cwcEstimatedUnitCost
										);
									}}
								/>
							)}
						/>
					</div>
					<span className="tw-self-center">X</span>
					<div className="tw-relative">
						<Label
							label="Qty"
							className="tw-absolute tw-bottom-0 tw-right-2 tw-top-2 tw-text-[#84878a]"
						/>
						<Controller
							name="quantity"
							render={({ field: { value, onChange } }) => (
								<FormControl
									id="Qty"
									onChange={onChange}
									size="sm"
									disabled={disableInputs}
									value={value}
									maxLength={15}
									onBlur={(e) => {
										const isNotAValidNumber = isNaN(Number(e.target.value));
										if (
											!e.target.value ||
											isNotAValidNumber ||
											e.target.value === '-0.'
										) {
											methods.setValue('quantity', '0');
										} else {
											methods.setValue(
												'quantity',
												String(Number(e.target.value))
											);
										}
										handleRecalculate(TWhatChangedComponent.cwcQuantity);
									}}
								/>
							)}
						/>
					</div>
				</div>
			</div>
			<div>
				<Label label="Units" className="tw-mb-2" />
				<div className="tw-flex tw-items-center tw-gap-4">
					<Controller
						control={methods.control}
						name="unitmeasure"
						render={({ field }) => (
							<div>
								<UnitsOfMeasureDropdown
									inputId="Units"
									height={32}
									isClearable={!!field.value}
									value={{
										label: '',
										value: field.value,
									}}
									onChange={(value) => field.onChange(value?.value || '')}
								/>
							</div>
						)}
					/>
					<span className="tw-justify-self-center tw-text-2xl">=</span>
				</div>
			</div>
			<div>
				<Label label="Ext. Base Cost" className="!tw-text-left tw-mb-2" />
				<div className="tw-flex tw-gap-4">
					<div className="tw-max-w-36">
						<Controller
							control={methods.control}
							name="feecost"
							render={({ field }) => (
								<FormCurrencyInput
									allowNegativeValue
									disabled={disableInputs}
									id="Ext. Base Cost"
									value={field.value}
									maxLength={15}
									onValueChange={field.onChange}
									onBlur={() =>
										handleRecalculate(TWhatChangedComponent.cwcEstimatedCost)
									}
								/>
							)}
						/>
					</div>
				</div>
			</div>
			<div>
				<Label label="Sell Price" className="!tw-text-left tw-mb-2" />
				<div className="tw-flex tw-gap-4">
					<div className="tw-max-w-36">
						<Controller
							control={methods.control}
							name="estprice"
							render={({ field }) => (
								<FormCurrencyInput
									allowNegativeValue
									id="Sell Price"
									disabled={disableInputs}
									value={field.value}
									maxLength={15}
									onValueChange={field.onChange}
									onBlur={() =>
										handleRecalculate(TWhatChangedComponent.cwcEstimatedPrice)
									}
								/>
							)}
						/>
					</div>
					<div className="tw-flex tw-items-center">
						<i
							className="ri-lock-fill px-2"
							onClick={() => {
								methods.setValue('usedisc', 'dmDiscount');
								methods.setValue('markup', '0');
								handleRecalculate(TWhatChangedComponent.cwcPercentType);
							}}
						/>
						<div className="tw-max-w-36">
							<Controller
								control={methods.control}
								name="taxable"
								render={({ field }) => (
									<Form.Check
										disabled={disableInputs}
										size={30}
										type="checkbox"
										label="Taxable"
										className="tw-bg-color-[]"
										checked={field.value}
										maxLength={15}
										onChange={field.onChange}
										onBlur={() =>
											handleRecalculate(TWhatChangedComponent.cwcTaxable)
										}
									/>
								)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
FeeInputs.displayName = ' FeeInputs';
export default FeeInputs;
