import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Modal, Row, Col } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import { StringCompare } from '../../../helpers/String';
import { HeaderLight } from '../../components/Header';
import ProjectAddMarkupGeneral from './ProjectAddMarkupGeneral';
import ProjectAddMarkupProposal from './ProjectAddMarkupProposal';
import ProjectAddMarkupInvoice from './ProjectAddMarkupInvoice';
import ProjectAddMarkupPOWO from './ProjectAddMarkupPOWO';
import ProjectAddMarkupTime from './ProjectAddMarkupTime';
import { ApiService } from '../../../lib/api/HttpService';

class ProjectAddMarkup extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeMenu: 'general',
		};
		this.updatedData = { ...this.props.markupData };
		this.str = new StringCompare(this.state.activeMenu)
			.set('trueValue', 'active')
			.set('falseValue', '');

		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.api = new ApiService();
	}

	componentDidMount() {
		const title = this.updatedData.id
			? `${this.updatedData.projn} [${this.updatedData.proj}] - ${this.updatedData.clientName}`
			: 'Markup | New Project';
		this.setTitle(title);
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.markupData !== this.props.markupData) {
			this.updatedData = { ...this.props.markupData };
			const title = this.updatedData.id
				? `${this.updatedData.projn} [${this.updatedData.proj}] - ${this.updatedData.clientName}`
				: 'Markup | New Project';
			this.setTitle(title);
		}
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.str.theString = e.target.getAttribute('data-menu');
		this.setState({
			activeMenu: e.target.getAttribute('data-menu'),
		});
	};

	handleSaveClick = (e) => {
		this.props.onContinue(this.updatedData);
	};

	handleDataChange = (data) => {
		this.updatedData[data.key] = data.value;
	};

	handleCancel = () => {
		this.updatedData = { ...this.props.markupData };
		this.props.onCancel();
	};

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.title}</HeaderLight.Title>
					<div className="d-flex flex-column-reverse flex-lg-row">
						<Button
							variant="trans-light border-secondary-ash"
							className="me-0 me-lg-2"
							onClick={this.handleCancel}
						>
							Cancel
						</Button>
						{/* Submit Button */}
						<Button
							variant="primary"
							className="mb-2 mb-lg-0"
							name="save"
							onClick={this.handleSaveClick.bind(this)}
						>
							{this.props.params.id ? 'Save' : 'Continue'}
						</Button>
					</div>
				</HeaderLight.Content>
				<HeaderLight.Actions className="text-charcoal">
					<li>
						<Button
							as={Link}
							data-menu="general"
							className={`${this.str.compareReturn('general')}`}
							onClick={this.handleMenuClick}
						>
							General
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							data-menu="proposal"
							className={`${this.str.compareReturn('proposal')}`}
							onClick={this.handleMenuClick}
						>
							Proposal
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							data-menu="invoice"
							className={`${this.str.compareReturn('invoice')}`}
							onClick={this.handleMenuClick}
						>
							Invoice
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							data-menu="powo"
							className={`${this.str.compareReturn('powo')}`}
							onClick={this.handleMenuClick}
						>
							PO/WO
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							data-menu="time"
							className={`${this.str.compareReturn('time')}`}
							onClick={this.handleMenuClick}
						>
							Time
						</Button>
					</li>
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	renderContent() {
		// Get the local storage first if available.

		if (this.str.compare('general')) {
			return (
				<ProjectAddMarkupGeneral
					project={this.props.project}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					company={this.props.company}
				/>
			);
		}

		if (this.str.compare('proposal')) {
			return (
				<ProjectAddMarkupProposal
					project={this.props.project}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					company={this.props.company}
				/>
			);
		}

		if (this.str.compare('invoice')) {
			return (
				<ProjectAddMarkupInvoice
					project={this.props.project}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					company={this.props.company}
				/>
			);
		}

		if (this.str.compare('powo')) {
			return (
				<ProjectAddMarkupPOWO
					project={this.props.project}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					company={this.props.company}
				/>
			);
		}

		if (this.str.compare('time')) {
			return (
				<ProjectAddMarkupTime
					project={this.props.project}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
					company={this.props.company}
				/>
			);
		}
	}

	render() {
		return (
			<Modal
				size="xl"
				show={this.props.isShowing}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					{this.renderHeader()}
					{/* Content */}
					<div className="content-padding min-height">
						<Container fluid className="mt-4">
							{this.renderContent()}
						</Container>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

export default WithRouter(ProjectAddMarkup);
