/**
 * Generate random string.
 *
 * @param {number} len Total string length.
 * @returns string
 */
function makeid(len) {
	let result = '';
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < len; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
}

class StringCompare {
	constructor(theString) {
		this.theString = theString || '';
		this.trueValue = true;
		this.falseValue = false;
	}

	set(name, val) {
		this[name] = val;

		return this;
	}

	setTrue(val) {
		this.trueValue = val;

		return this;
	}

	setFalse(val) {
		this.falseValue = val;

		return this;
	}

	compare(strCompare) {
		// eslint-disable-next-line
		return this.theString == strCompare;
		// eslint-enable-next-line
	}

	compareStrict(strCompare) {
		return this.theString === strCompare;
	}

	compareReturn(strCompare, isStrict) {
		// eslint-disable-next-line
		return (
			isStrict ? this.compareStrict(strCompare) : this.compare(strCompare)
		)
			? this.trueValue || true
			: this.falseValue || false;
		// eslint-enable-next-line
	}

	contains(strCompare) {
		return (this.theString || '').indexOf(strCompare) > -1;
	}
}

function containStr(str1, strCompare) {
	return (str1 || '').indexOf(strCompare) > -1;
}

function compareStr(str1, str2, _true, _false) {
	return str1 === str2 ? _true || true : _false || false;
}

function titleCase(str) {
	var splitStr = str.toLowerCase().split(' ');
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(' ');
}

function StringReplaceArray(val, find, replace) {
	let replaceString = val;
	let regex;

	for (let i = 0; i < find.length; i++) {
		regex = new RegExp(find[i], 'g');
		replaceString = replaceString.replace(regex, replace[i]);
	}

	return replaceString;
}

function stringToArrayBuffer(string) {
	const buffer = new ArrayBuffer(string.length);
	const bufferView = new Uint8Array(buffer);
	for (let i = 0; i < string.length; i++) {
		bufferView[i] = string.charCodeAt(i);
	}
	return buffer;
}

function truncateCharCount(text, max) {
	if (text && text.length > max) {
		let result = '';
		const words = text.split(' ');
		for (let i = 0; i < words.length; i++) {
			result += ' ' + words[i];
			if (result.length >= max) {
				break;
			}
		}
		if (result.length < text.length) {
			result += '...';
		}
		return result;
	}
	return text;
}

export {
	makeid,
	StringCompare,
	titleCase,
	compareStr,
	StringReplaceArray,
	containStr,
	stringToArrayBuffer,
	truncateCharCount,
};
