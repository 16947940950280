import { useController } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../../../lib/api/HttpService';
import ImageDragAndDrop from 'legacy/app/components/ImageDragAndDrop/ImageDragAndDrop';

const LogoControl = ({ name, control, ...props }) => {
	const api = new ApiService();

	const {
		field: { value, onChange },
	} = useController({ name, control });

	const { isLoading, data } = useQuery({
		queryKey: ['settings', 'company', 'logo', value],
		queryFn: ({ queryKey: [, , , value] }) => {
			if (!value) return '';
			if (typeof value !== 'string') return value;

			return api.getFile(value);
		},
	});

	const handleAcceptedFiles = (file) => onChange(file);

	const handleRemoveFiles = () => onChange('');

	if (isLoading) return null;

	return (
		<ImageDragAndDrop
			image={data}
			handleFile={handleAcceptedFiles}
			handleDelete={handleRemoveFiles}
			deleteModalTitle="Delete Confirm"
			deleteModalMessage="Are you sure you want to delete the image? This action cannot be reversed."
		/>
	);
};

export default LogoControl;
