import React from 'react';
import ImagesFanFrame from '../ImagesFanFrame/ImagesFanFrame';
import { DashboardUrls } from 'legacy/app/constants/dashboard/dashboardUrls';
import { useNavigate } from 'react-router-dom';

const PlaceholderCard = () => {
	const navigate = useNavigate();

	return (
		<div
			onClick={() => navigate(DashboardUrls.addUrl)}
			className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-w-[200px] tw-min-w-[200px] md:tw-min-w-[300px] md:tw-w-[300px] tw-mr-4 tw-h-[400px] tw-opacity-75 tw-cursor-pointer"
		>
			<div className="tw-flex tw-justify-center tw-overflow-hidden tw-h-[140px]">
				<ImagesFanFrame />
			</div>
			<div className="tw-p-4 tw-flex tw-flex-col tw-items-center tw-h-[250px]">
				<span className="tw-text-gray-400"> Add Project</span>
			</div>
		</div>
	);
};

PlaceholderCard.displayName = 'PlaceholderCard';

export default PlaceholderCard;
