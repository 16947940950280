import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { ITransactions } from 'api/resources/transactions';
import { getTransactions } from 'api/resources/transactions';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetTransactions = (
	accountId: number,
	filter?: string,
	options?: UseQueryOptions<ITransactions[]>
) => {
	return useQuery<ITransactions[]>({
		queryKey: [ECacheKeys.Transactions, accountId, filter],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getTransactions(accountId, filter),
		...(options || {}),
	});
};
