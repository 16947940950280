import { apiClient } from 'api/apiClient';
import type { TTypeLocation } from 'legacy/lib/api/types/Location';

const RESOURCE = '/locations';

export const getLocations = async (filter = ''): Promise<TTypeLocation[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());

	return data;
};
