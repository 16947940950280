import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { TForm as Model, Field, IFormField } from '../components/Components';
import {
	fetchSalesCategories,
	fetchSuppliers,
	fetchUnitsOfMeasures,
} from '../../StockItemsService';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';

export const StockItemsEditItem: FC<{
	data: Map<string, any>;
	image?: File;
	onDataUpdate: (data: any) => void;
	onImageUpdate: (image: File) => void;
}> = ({ data, image, onDataUpdate, onImageUpdate }) => {
	const [unitsOfMeasures, setUnitsOfMeasures] = useState<TTypeOption[]>([]);
	const [salesCategories, setSalesCategories] = useState<TTypeOption[]>([]);
	const [suppliers, setSuppliers] = useState<TTypeOption[]>([]);

	const refs: any = {};
	const handleMarkup = (data: Map<string, any>, value: number) => {
		const unitCost = Number(data.get('unitestcost'));
		if (!unitCost || unitCost == 0) {
			return data;
		}

		const unitSellPrice = Number(unitCost + unitCost * value * 0.01).toFixed(2);
		data.set('unitprice', unitSellPrice);

		refs.unitprice.updateValue(unitSellPrice);

		return data;
	};

	const handleUnitSellPrice = (data: Map<string, any>, value: number) => {
		if (!value) {
			data.set('markup', 0);
			refs.markup.updateValue(0);
			return data;
		}

		const unitCost = Number(data.get('unitestcost'));
		if (!unitCost || unitCost == 0) {
			return data;
		}

		const markup = Number(((value - unitCost) / unitCost) * 100).toFixed(2);
		data.set('markup', markup);

		refs.markup.updateValue(markup);

		return data;
	};

	const handleUnitCost = (data: Map<string, any>, value: number) => {
		const unitCost = Number(value ?? 0);
		if (unitCost == 0) {
			data.set('unitprice', 0);
			refs.unitprice.updateValue(0);
			return data;
		}

		const existingMarkup = Number(data.get('markup') ?? 0);
		const unitSellPrice = Number(
			unitCost + unitCost * existingMarkup * 0.01
		).toFixed(2);
		data.set('unitprice', unitSellPrice);
		refs.unitprice.updateValue(unitSellPrice);

		return data;
	};

	const model: Model = {
		sections: [
			{
				key: 's0',
				columns: [
					{
						key: 'c0',
						fields: [
							{
								type: 'text',
								title: 'Name',
								ids: ['itemName'],
								value: data.get('itemName'),
								maxLength: 45,
							},
							{
								type: 'wysiwyg',
								title: 'Description',
								ids: ['desc'],
								value: data.get('descrtf'),
							},
						],
						sm: 12,
						lg: 6,
					},
					{
						key: 'c1',
						fields: [
							{
								type: 'dropzone',
								ids: ['primaryImageId'],
								value: data.get('primaryImageId'),
								file: image,
							},
						],
						sm: 12,
						lg: 4,
					},
				],
			},
			{
				key: 's1',
				columns: [
					{
						key: 'c0',
						fields: [
							{
								type: 'text',
								title: 'Stock No.',
								ids: ['stockno'],
								value: data.get('stockno'),
								readOnly: true,
							},
							{
								type: 'text',
								title: 'Style/Size',
								ids: ['category1'],
								value: data.get('category1'),
							},
							{
								type: 'text',
								title: 'Color/Finish',
								ids: ['category2'],
								value: data.get('category2'),
							},
							{
								type: 'text',
								title: 'Storage Loc.',
								ids: ['locbin'],
								value: data.get('locbin'),
							},
							{
								type: 'select',
								subtype: 'custom',
								title: 'Unit of Measure',
								ids: ['unitofmeasure'],
								options: unitsOfMeasures,
								value: data.get('unitofmeasure'),
							},
							{
								type: 'select',
								subtype: 'salesCategory',
								title: 'Sales Category',
								ids: ['scat'],
								value: data.get('scat'),
								options: salesCategories,
							},
							{
								type: 'currency',
								title: 'Est. Unit Cost',
								ids: ['unitestcost'],
								value: data.get('unitestcost'),
								handler: handleUnitCost,
							},
							{
								type: 'text',
								title: 'Reorder Point',
								ids: ['reorderpt'],
								value: data.get('reorderpt'),
							},
							{
								type: 'text',
								title: 'Reorder Qty.',
								ids: ['reorderqty'],
								value: data.get('reorderqty'),
							},
							{
								type: 'text',
								title: 'PO Sidemark',
								ids: ['sidemark'],
								value: data.get('sidemark'),
							},
							{
								type: 'wysiwyg',
								title: 'Notes',
								ids: ['notes'],
								value: data.get('notesrtf'),
							},
						],
						sm: 12,
						lg: 6,
					},
					{
						key: 'c1',
						fields: [
							{
								type: 'text',
								title: 'Markup %',
								ids: ['markup'],
								inputRegex: /^\d*\.?\d{0,2}$/,
								value: data.get('markup'),
								handler: handleMarkup,
							},
							{
								type: 'currency',
								title: 'Unit Sell Price',
								ids: ['unitprice'],
								value: data.get('unitprice'),
								handler: handleUnitSellPrice,
							},
							{
								type: 'select',
								subtype: 'supplier',
								title: 'Vendor',
								ids: ['supplier'],
								value: data.get('supplier'),
								options: suppliers,
							},
							{
								type: 'text',
								title: 'MFG Cat. No.',
								ids: ['mfgcat'],
								value: data.get('mfgcat'),
							},
							{
								type: 'text',
								title: 'Bar Code',
								ids: ['barcode'],
								readOnly: true,
								value: data.get('barcode'),
							},
							{
								type: 'checkbox',
								title: 'Inactive',
								ids: ['inactive'],
								value: data.get('inactive'),
							},
						],
						sm: 12,
						lg: 6,
					},
				],
			},
		],
	};

	useEffect(() => {
		const fetchData = async () => {
			setUnitsOfMeasures(await fetchUnitsOfMeasures());
			setSalesCategories(await fetchSalesCategories());
			setSuppliers(await fetchSuppliers());
		};
		fetchData();
	}, []);

	const htmlToRtfConverter = new HtmlToRtfBrowser();

	const handleChange = (field: IFormField, value: any) => {
		const newData = field.handler?.(new Map(data), value) ?? new Map(data);

		switch (field.type) {
			case 'text':
			case 'currency':
			case 'select':
			case 'checkbox':
				field.ids.forEach((id) => {
					newData.set(id, value);
				});
				break;
			case 'wysiwyg':
				field.ids.forEach((id) => {
					newData.set(id, value.value);
					newData.set(
						`${id}rtf`,
						htmlToRtfConverter
							.convertHtmlToRtf(value.html)
							.replaceAll('\\~', ' ')
					);
				});
				break;
			case 'dropzone':
				field.ids.forEach(() => {
					onImageUpdate(value);
				});
				return;
		}

		onDataUpdate(newData);
	};

	return (
		<Container className="ms-0">
			{model.sections.map((section) => {
				return (
					<Row key={section.key} className="mb-5">
						{section.columns.map((column) => {
							return (
								<Col key={column.key} sm={column.sm} lg={column.lg}>
									{column.fields.map((field) => {
										return (
											<Row key={field.ids[0]} className="mb-2">
												<Field
													ref={(ref) => {
														refs[`${field.ids[0]}`] = ref;
													}}
													model={field}
													onChange={(value) => {
														handleChange(field, value);
													}}
												/>
											</Row>
										);
									})}
								</Col>
							);
						})}
					</Row>
				);
			})}
		</Container>
	);
};
StockItemsEditItem.displayName = 'StockItemsEditItem';
