import React from 'react';
import {
	Col,
	Row,
	Form,
	Container,
	Breadcrumb,
	Table,
	Button,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import URI from '../../../../defaults/RoutesDefault';
import { Link } from 'react-router-dom';
import { ApiService } from '../../../../lib/api/HttpService';
import Header from '../../../components/Header';
import { currencyFormat } from '../../../../helpers/Number';
import CheckbookDepositDetailDialog from './CheckbookDepositDetailDialog';
import { getLocalStorage } from '../../../../utilities/LocalStorage';
import dayjs from 'dayjs';
import { showLoading } from '../../../../helpers/Loading';
import {
	displayAlert,
	displayAlertError,
	getErrorMessage,
	handleResponseAlert,
} from '../../../../utilities/Response';
import { getCookie } from '../../../../utilities/Auth';
import CreateDepositSlipListExistingModal from '../../modal/CreateDepositSlipListExistingModal';

class CheckbookDepositDetail extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			dataIsLoaded: false,
			isLoading: false,
			showDialogModal: false,
			showDepositModal: false,
			crgroupnum: null,
		};
		this.api = new ApiService();
	}

	async componentDidMount() {
		const checkbookData = getLocalStorage('checkbook_detail', true);
		const account = getLocalStorage('account_data');
		const accountnData = JSON.parse(getLocalStorage('accountn_data'));
		const depositDate = dayjs(checkbookData.date).format('YYYY-MM-DD');
		const filter = `?$filter=userdate eq ${depositDate} and account eq '${account}' and depositslip eq '${checkbookData.depositslip}'`;
		const data = await this.api.getDepositCheckbookDetail(filter);

		this.setState({
			data,
			dataIsLoaded: true,
			accountNumber: account,
			depositSlipNumber: checkbookData.depositslip,
			depositDate,
			accountnData,
		});
	}

	showPayDistModal(crgroupnum) {
		this.setState({
			crgroupnum,
			showDialogModal: true,
		});
	}

	getAccountLabel() {
		const accountnData = this.state.accountnData;
		if (Array.isArray(accountnData)) {
			const account = accountnData.find(
				(acc) => acc.value === this.state.accountNumber
			);
			return account ? account.label : this.state.accountNumber;
		} else {
			// Handle the case where accountnData is not an array
			return this.state.accountNumber;
		}
	}

	handleRunReports = async (data) => {
		const range = this.state.depositDate;
		// Define the parameters for the report
		const params = {
			instanceId: getCookie('dmInstanceId'),
			userId: Number(getCookie('dmUsercode')),
			reportId: 1055,
			reportFormatId: 1137,
			reportObjectType: 'Report',
			runReportParameters: [
				{
					parameterId: 297,
					value: `{"cash_account": "${this.state.accountNumber}"}`,
				},
				{
					parameterId: 298,
					value: `{"text": "${data.slipNumber}"}`,
				},
				{
					parameterId: 299,
					value: `{"range1": "${range}", "range2":"${range}"}`,
				},
				{
					parameterId: 300,
					value: '{"text": ""}',
				},
			],
			objectId: null,
		};

		// Run the report
		const saveRequest = this.api.postJson('runreports', params);
		const windowReport = window.open('', '_blank');
		windowReport.document.write(this.loadingWindowHTML());

		handleResponseAlert(saveRequest, (res) => {
			displayAlert('success', 'Please wait for your report to be generated.');
			const reportId = res.id;
			const maxRequest = 5;
			let requestCount = 0;
			let interval = window.setInterval(async () => {
				try {
					const response = await this.api.get(
						'runreports',
						`?$filter=id eq ${reportId}`
					);
					const fileId = response[0].completedFileId;
					if (fileId) {
						clearInterval(interval);
						await this.api.getFile(fileId).then((blob) => {
							windowReport.location.href = window.URL.createObjectURL(blob);
						});
					} else if (requestCount === maxRequest) {
						clearInterval(interval);
						displayAlertError('Report is on pending status. Try again later.');
					}
				} catch (error) {
					displayAlertError(getErrorMessage(error));
					windowReport.close();
				}
				requestCount++;
			}, 5000);
			this.props.hideModal();
		});
		window.localStorage.removeItem('checkbook_detail');
		window.localStorage.removeItem('account');
		window.localStorage.removeItem('accountn_data');
	};

	loadingWindowHTML() {
		return `<div style="position: absolute; display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; padding-left: 0px;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                <div>
                <img src="${window.location.origin}/logo-dark.svg?t=08022023" width="180" height="auto" class="d-inline-block align-top" alt="React Bootstrap logo">
                <br>
                <img src="${window.location.origin}/loader.svg?t=08022023" width="180" height="130" class="d-inline-block align-top" alt="React Bootstrap logo" style="margin-top: -40px;">
                </div>
            </div>
            </div>
        </div>`;
	}

	renderHeader() {
		const { depositSlipNumber, depositDate } = this.state;
		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{
										to: URI.accountsPayable.checkbook.list,
									}}
									linkAs={Link}
								>
									Accounts Payable
								</Breadcrumb.Item>
								<Breadcrumb.Item
									linkProps={{
										to: URI.accountsPayable.checkbook.list,
									}}
									linkAs={Link}
								>
									Checkbook
								</Breadcrumb.Item>
								<Breadcrumb.Item>Deposit Detail</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Deposit Detail</Header.Title>
						<Header.Subtitle>
							{this.getAccountLabel()}
							<span className="mx-5">Slip# {depositSlipNumber}</span>
							{dayjs(depositDate).format('MM/DD/YYYY')}
						</Header.Subtitle>
					</Header.LeftContent>
					<Header.Actions list={true}>
						<li>
							<Button
								onClick={() =>
									this.setState((prev) => (prev.showDepositModal = true))
								}
								variant="light"
								size="lg"
								className="btn-icon text-secondary-green"
							>
								<i className="ri-printer-line ri-lg"></i> Print Deposit Slip
							</Button>
						</li>
					</Header.Actions>
				</Header.TitleContent>
			</Header>
		);
	}

	renderContent() {
		const { data } = this.state;

		return (
			<Container className="ms-0">
				<div className="table-gradient">
					<Table striped responsive className="a-table">
						<thead>
							<tr className="a-table-heading">
								<th>Client's Check No.</th>
								<th>Client Code</th>
								<th>Client Name</th>
								<th>Fiscal Month</th>
								<th>Ref. #</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{!this.state.dataIsLoaded
								? showLoading()
								: this.state.data.map((item, i) => (
										<tr key={i} data-key={i}>
											<td>
												<Link
													to=""
													className="text-charcoal hover-view-icon"
													onClickCapture={() =>
														this.showPayDistModal(item.crgroupnum)
													}
												>
													{item.checknum}
												</Link>
											</td>
											<td>{item.client}</td>
											<td>{item.clientn}</td>
											<td>
												{item.fiscalmonth
													? dayjs(item.fiscalmonth).format('MM/YYYY')
													: item.fiscalmonth}
											</td>
											<td>{item.crgroupnum}</td>
											<td>{currencyFormat(item.amount, '$')}</td>
										</tr>
								  ))}
						</tbody>
					</Table>
				</div>
			</Container>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
					<CheckbookDepositDetailDialog
						crgroupnum={this.state.crgroupnum}
						show={this.state.showDialogModal}
						hideModal={() =>
							this.setState((prev) => (prev.showDialogModal = false))
						}
					/>
					<CreateDepositSlipListExistingModal
						cashAccount={this.getAccountLabel()}
						slipNumber={this.state.depositSlipNumber}
						depositDate={this.state.depositDate}
						show={this.state.showDepositModal}
						hideModal={() =>
							this.setState((prev) => (prev.showDepositModal = false))
						}
						onPostDepositSlip={this.handleRunReports}
					/>
				</div>
			</>
		);
	}
}

export default WithRouter(CheckbookDepositDetail);
