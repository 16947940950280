import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../../../templates/components/Header';

export const InventoryHeader = () => {
	return (
		<Header>
			<Header.TitleContent>
				<Header.LeftContent>
					<Header.Breadcumbs>
						<Breadcrumb className="breadcrumb-light">
							<Breadcrumb.Item linkProps={{ to: '/settings' }} linkAs={Link}>
								Settings
							</Breadcrumb.Item>
							<Breadcrumb.Item>Inventory Settings</Breadcrumb.Item>
						</Breadcrumb>
					</Header.Breadcumbs>
					<Header.Title>Inventory Settings</Header.Title>
				</Header.LeftContent>
			</Header.TitleContent>
		</Header>
	);
};

InventoryHeader.displayName = 'InventoryHeader';
