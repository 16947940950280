import React from 'react';
import { Col, Container, Row, Table, Form, Button } from 'react-bootstrap';
import { generatePath, Link } from 'react-router-dom';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import URI from '../../../defaults/RoutesDefault';
import Header from '../../components/Header';
import ListFilter from '../../components/ListFilter';
import { ApiService } from '../../../lib/api/HttpService';
import { currencyFormat } from '../../../helpers/Number';
import Select from 'react-select';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import debounce from 'lodash/debounce';
import DeleteModal from '../../../app/components/modal/DeleteModal';
import HandleDeleteWorker from '../../../utilities/DeleteWorker';
import { formatDate, formatFilterDate } from '../../../helpers/Date';

import noItems from '../../../assets/images/icons/room.svg';
import FilterNumberModal from '../../../app/components/modal/FilterNumberModal';
import { DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { isFiltered } from '../../../helpers/Util';
import { showEmpty, showLoading } from '../../../helpers/Loading';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	hideAlertLoader,
} from '../../../utilities/Response';
import MSG from '../../../defaults/Message';
import Spinner from '../../../app/components/help/Spinner';
import FilterSort from '../../../utilities/modules/FilterSort';
import AsyncClientsDropdown from '../../../app/components/dropdowns/AsyncClientsDropdown';
import AsyncEmployeesDropdown from '../../../app/components/dropdowns/AsyncEmployeesDropdown';
import { isEmpty } from 'lodash';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import SecureContent from '../../../app/components/security/SecureContent';
import {
	renderSecureContent,
	renderSecureHideContent,
} from '../../../utilities/modules/SecureContent';
import { isUserValid } from 'legacy/lib/api/utils/utils';

class ProjectList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('project_list');
		this.state = {
			checks: [],
			data: [],
			showRetainerModal: false,
			showTableSearch: this.fs.isSearchActive(),
			dataIsLoaded: false,
			status: [
				{ value: '', label: 'All' },
				{ value: 'closed', label: 'Closed' },
				{ value: 'open', label: 'Open' },
			],
			statusFilter: this.fs.getFilter('statusFilter') ?? {
				value: 'open',
				label: 'Open',
			},
			clientFilter: this.fs.getFilter('clientFilter') ?? {
				value: '',
				label: 'All',
			},
			managerFilter: this.fs.getFilter('managerFilter') ?? {
				value: '',
				label: 'All',
			},
			page: 1,
			pageSize: { value: 20, label: '20' },
			sortProperty: this.fs.getSort() || 'proj asc',
			searchProperties: this.fs.getSearches(),
			showModal: false,
			deleteResponses: [],
			modalTitle: '',
			activeNumberModal: '',
		};
		this.api = new ApiService();
		this.availablePageSize = [
			{ value: 10, label: '10' },
			{ value: 20, label: '20' },
			{ value: 50, label: '50' },
			{ value: 75, label: '75' },
			{ value: 100, label: '100' },
		];
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	componentInit() {
		this.setTitle('Projects').setActionBar(true);
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;
		// Add change event
		if (sort) {
			this.setState({
				enableSortTable: true,
			});
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const newSortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(newSortProperty);

						self.setState(
							{ sortProperty: newSortProperty },
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	doTableSearch(data) {}

	buildFilters(currentPage) {
		let filters = [];

		if (localStorage.getItem('filters')) {
			filters = JSON.parse(localStorage.getItem('filters'));
		}

		if (this.state.clientFilter.value !== '') {
			filters.push(
				`client eq '${encodeURIComponent(this.state.clientFilter.value)}'`
			);
		}

		if (this.state.managerFilter.value !== '') {
			filters.push(
				`manager eq '${encodeURIComponent(this.state.managerFilter.value)}'`
			);
		}

		if (this.state.statusFilter.value !== '') {
			filters.push(
				`closeddt ${
					this.state.statusFilter.value === 'open' ? 'eq' : 'ne'
				} null`
			);
		}

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.max) {
				if (property.type === 'number' || property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		const filtersQuery =
			filters.length > 0 ? `and ${filters.join(' and ')}` : '';
		let queryString = `$top=${this.state.pageSize.value + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize.value
		} &$filter=order eq false ${filtersQuery}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	async componentDidMount() {
		// Use sortProperty state when fetching data
		try {
			await this.handleChangePage(1);
		} catch (error) {
			this.setState({
				dataIsLoaded: true,
			});
			displayAlert('danger', 'Something went wrong!');
			if (!isUserValid(error)) {
				window.location.href = URI.invalidInstance;
			}
		}
	}

	componentDidUpdate() {
		const { sortProperty } = this.state;
		localStorage.setItem('sortProperty', sortProperty);
	}

	handleChangePage = async (page) => {
		this.setState({
			dataIsLoaded: false,
		});
		let projects = await this.api.getProjects(`?${this.buildFilters(page)}`);
		const notes = await this.api.getNotifications();

		projects = await this.appendActualBudget(projects);

		for (let proj of projects) {
			if (proj.lastRetainerRequestNotificationId > 0) {
				let note = notes.find(
					(o) => o.id === proj.lastRetainerRequestNotificationId
				);
				proj.sentDateTimeUtc = formatDate(note.sentDateTimeUtc);
			} else {
				proj.sentDateTimeUtc = '';
			}
		}

		this.setState({
			page: page,
		});

		this.renderData(projects);
		this.setRawData(projects);
	};

	renderData(projects) {
		this.setState(
			{
				data: projects,
				dataIsLoaded: true,
			},
			() => {
				if (!this.state.enableSortTable) {
					this.enableSortTable();
				}
			}
		);
	}

	computeBudgetTotal = (budgets) => {
		let total = 0;
		for (let key in budgets) {
			total += budgets[key].budgett;
		}

		return total;
	};

	async appendActualBudget(projects) {
		try {
			if (!isEmpty(projects)) {
				const projectCodes = projects
					.filter((i) => !isEmpty(i.proj) && !i.budgettoverride)
					.map((i) => `'${encodeURIComponent(i.proj)}'`);

				const filterParam =
					projectCodes.length === 1
						? `?$filter=proj eq ${projectCodes[0]}`
						: projectCodes.length > 1
						? `?$filter=proj in (${projectCodes.join(',')})`
						: '';
				// Get budjects related to the project with budgettoverride is false or null.
				const budgets = await this.api.getBudgets(filterParam);

				for (const project of projects) {
					if (project.budgettoverride) {
						project.totalBudget = project.budgett;
					} else {
						const projectBudget = budgets.filter(
							(i) => i.proj === project.proj
						);

						const total = this.computeBudgetTotal(projectBudget);
						project.totalBudget = total;
					}
				}
			}
		} catch (error) {
			// Ignore error.
		}

		return projects;
	}

	updateSearch = (min, max) => {
		const key = this.state.activeNumberModal;

		let obj = { type: 'number' };

		if (min !== '') {
			obj.min = min;
		}
		if (max !== '') {
			obj.max = max;
		}

		const field = document.querySelectorAll(
			'.a-table-search-fields input[data-field="' + key + '"]'
		);

		field[0].value = min !== '' || max !== '' ? min + '-' + max : '';

		let tmp = this.state.searchProperties;

		tmp[key] = obj;
		localStorage.setItem('searchProperties', JSON.stringify(tmp));

		this.setState(
			{
				searchProperties: tmp,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	triggerNumberModal = (e) => {
		const idx = Array.from(e.target.parentNode.parentNode.children).indexOf(
			e.target.parentNode
		);
		const key = e.target.attributes['data-field'].value;

		const labels = e.target.parentNode.parentNode.previousSibling.children;

		this.setState({
			activeNumberModal: key,
			defaultNumberVal: e.target.value,
			modalTitle: labels[idx].children[0].textContent + ' Range',
		});
		this.toggleNumberModal();
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}

		this.setState(
			{
				searchProperties: tmp,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);

		if (name !== 'pageSize') {
			this.fs.setFilter(name, e);
		}
	};

	toggleNumberModal = (e) => {
		this.setState({
			showNumberModal: !this.state.showNumberModal,
		});
	};

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.fs.setIsActiveSearch(newTableSearch);
		this.fs.setSearches({});
		this.setState({
			showTableSearch: newTableSearch,
			searchProperties: {},
		});

		if (!newTableSearch) {
			this.setState({
				dataIsLoaded: false,
			});
			this.changePageHandler();
		}

		if (this.hasSearchFilter(this.state.searchProperties)) {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	handleCancel = async () => {
		this.handleTestDeleteObjects(true);
	};

	async handleClose() {
		if (!this.state.checks.length) {
			return;
		}

		displayAlertLoader(MSG.loading.prepare.item);

		try {
			for (const id of this.state.checks) {
				const params = {
					closeddt: new Date(),
				};
				await this.api.editProject(id, params);
			}

			hideAlertLoader();

			setTimeout(() => {
				let itemMsg = this.state.checks.length > 1 ? 'items' : 'item';
				displayAlertSuccess('Successfully closed an ' + itemMsg);

				this.refreshDataAfterDelete();
			});
		} catch (error) {
			displayAlertError(error.response.data.userError);
		}
	}

	async handleTestDeleteObjects(deactivateOnly) {
		if (!this.state.checks.length) {
			return;
		}

		let workers = [];

		displayAlertLoader(MSG.loading.prepare.item);

		for (const id of this.state.checks) {
			const params = {
				deleteType: 'dmriTestOnly',
				objectType: 'objProject',
				objectCodeOrId: id,
			};
			workers.push(await this.api.postJson('deleteobjects', params));
		}

		hideAlertLoader();
		if (workers) {
			HandleDeleteWorker(
				workers,
				{
					moduleSingular: 'Project',
					modulePlural: 'Projects',
					reference: '',
					objectType: 'objProject',
				},
				deactivateOnly,
				(modalState) => {
					this.setState(modalState);
				}
			);
		}
	}

	refreshDataAfterDelete = (e) => {
		this.setState({
			checks: [],
			showModal: false,
			deleteResponses: [],
		});

		this.handleChangePage(this.state.page);
	};

	handleSelectAllChecks = (e) => {
		let checks = [];
		if (
			e.target.checked &&
			this.state.checks.length !== this.state.data.length
		) {
			checks = this.state.data.map((item) => {
				return item.id;
			});
		}

		this.setState({
			checks: checks,
		});
	};

	handleCheck = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);

		this.setState({
			checks: checks,
		});
	};

	async handleDocument(e, id) {
		e.preventDefault();

		let document = await this.api.postJson('publicmappingbatches/retainers', {
			objectId: id,
		});
		window.open(document.link, '_blank').focus();
	}

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	renderHeader() {
		return (
			<Header>
				<Header.Title>Projects</Header.Title>
				<Header.Actions list={true}>
					<li>
						<SecureBootstrapButton
							attributeNo={13}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
							as={Link}
							to={URI.project.add}
							variant="light"
							size="lg"
							className="btn-icon text-secondary-green"
							data-testid="btn-add-project"
						>
							<i className="ri-add-line ri-lg"></i> Add
						</SecureBootstrapButton>
					</li>
				</Header.Actions>
			</Header>
		);
	}

	renderFilters() {
		return renderSecureHideContent(
			<>
				<ListFilter className="project-list-filter">
					<ListFilter.Fields md={12} lg={8} xl={9} className="width-30">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Client</strong>
							</Form.Label>
							<AsyncClientsDropdown
								onChange={this.handleFilter('clientFilter')}
								styles={{
									container: (provided) => ({
										...provided,
										width: '100%',
									}),
								}}
								defaultValue={this.state.clientFilter}
								className="react-select"
								placeholder="Please select"
								prependOptions={[
									{
										label: 'All',
										value: '',
									},
								]}
							/>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Salesperson</strong>
							</Form.Label>
							<AsyncEmployeesDropdown
								onChange={this.handleFilter('managerFilter')}
								styles={{
									container: (provided) => ({
										...provided,
										width: '100%',
									}),
								}}
								defaultValue={this.state.managerFilter}
								className="react-select"
								placeholder="Please select"
								prependOptions={[
									{
										label: 'All',
										value: '',
									},
								]}
							/>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Show</strong>
							</Form.Label>
							<Select
								onChange={this.handleFilter('statusFilter')}
								styles={{
									container: (provided) => ({
										...provided,
										width: '100%',
									}),
								}}
								key={`${Math.floor(Math.random() * 1000)}-min`}
								options={this.state.status}
								defaultValue={this.state.statusFilter}
								className="react-select"
								placeholder="Please select"
							/>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						md={12}
						lg={4}
						xl={3}
						className="justify-content-center"
					>
						<ListFilter.Action className="">
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action me-3 ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
								data-testid="search-project-button"
							>
								<i className="ri-search-line"></i> Search
							</Button>
							<SecureBootstrapButton
								attributeNo={13}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
								href="#"
								to="#"
								variant="ivory"
								size="sm"
								className="btn-icon btn-action me-3"
								disabled={!this.state.checks.length}
								onClick={this.handleClose.bind(this)}
							>
								<i className="ri-door-closed-fill"></i> Close
							</SecureBootstrapButton>
							<SecureBootstrapButton
								attributeNo={13}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
								data-testid="delete-project-button"
								href="#"
								to="#"
								variant="ivory"
								size="sm"
								className="btn-icon btn-action"
								disabled={!this.state.checks.length}
								onClick={this.handleTestDeleteObjects.bind(this, false)}
							>
								<i className="ri-close-line"></i> Delete
							</SecureBootstrapButton>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		)(13, SECURITY_ATTRIBUTE_TYPES.DenyAccess);
	}

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.data.length <= this.state.pageSize.value}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key={`${Math.floor(Math.random() * 1000)}-min`}
					options={this.availablePageSize}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	render() {
		return renderSecureContent(
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar sticky-container">
					<Container fluid className="px-0">
						<Row>
							<Col sm="12">
								{/* Filter */}
								{this.renderFilters()}

								{this.state.data.length ||
								isFiltered(this.state.searchProperties) ? (
									<div className="table-gradient">
										<Table
											striped
											responsive
											className="a-table"
											id="projects"
											data-testid="projects-table"
										>
											<thead>
												<tr className="a-table-heading">
													<th align="middle" className="mw-70px">
														<div className="d-flex justify-content-center">
															<Form.Check
																label=""
																type="checkbox"
																checked={this.state.checks.length > 0}
																className={
																	this.state.checks.length > 0 &&
																	this.state.checks.length <
																		this.state.data.length
																		? 'line'
																		: ''
																}
																onChange={this.handleSelectAllChecks}
															/>
														</div>
													</th>
													<th className="mw-90px">
														<span
															className={this.sortClass('proj')}
															data-field="proj"
														>
															Project Code
														</span>
													</th>
													<th className="mw-130px">
														<span
															className={this.sortClass('projn')}
															data-field="projn"
														>
															Project Name
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('client')}
															data-field="client"
														>
															Client Code
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('clientName')}
															data-field="clientName"
														>
															Client Name
														</span>
													</th>
													<th>
														<span
															className={this.sortClass('manager')}
															data-field="manager"
														>
															Salesperson Code
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('dateopened')}
															data-field="dateopened"
														>
															Date Opened
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('closeddt')}
															data-field="closeddt"
														>
															Date Closed
														</span>
													</th>
													<th className="mw-120px">
														<span
															className={this.sortClass('clientorder')}
															data-field="clientorder"
														>
															Client Order No.
														</span>
													</th>
													<SecureContent
														attributeNo={200}
														attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
													>
														<SecureContent.HasAccess>
															<th>
																<span
																	className={this.sortClass('budgett')}
																	data-field="budgett"
																>
																	Budget
																</span>
															</th>
														</SecureContent.HasAccess>
													</SecureContent>
													<th className="mw-120px">
														<span
															className={this.sortClass(
																'requestedRetainerAmount'
															)}
															data-field="requestedRetainerAmount"
														>
															Requested Retainer
														</span>
													</th>
													<th className="mw-180px">
														<span data-field="sentDateTimeUtc">
															Retainer requests
															<br />
															sent status
														</span>
													</th>
												</tr>
												<tr
													className={`a-table-search-fields ${
														this.state.showTableSearch ? '' : 'd-none'
													}`}
													data-testid="search-fields-table"
												>
													<th></th>
													<th>
														<Form.Control
															type="text"
															data-field="proj"
															data-testid="search-project-code-input"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('proj')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="projn"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('projn')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="client"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('client')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="clientName"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('clientName')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="manager"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('manager')}
														/>
													</th>
													<th>
														<DateRangePicker
															style={{
																minWidth: '200px',
															}}
															placement="auto"
															placeholder="Select date"
															format="MM/dd/yyyy"
															defaultValue={this.fs.getValue('dateopened')}
															onChange={this.handleDateChange.bind(
																this,
																'dateopened'
															)}
															onClean={this.handleDateChange.bind(
																this,
																'dateopened'
															)}
															ranges={[
																{
																	label: 'today',
																	value: [
																		startOfDay(new Date()),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'yesterday',
																	value: [
																		startOfDay(addDays(new Date(), -1)),
																		endOfDay(addDays(new Date(), -1)),
																	],
																},
																{
																	label: 'last7Days',
																	value: [
																		startOfDay(subDays(new Date(), 6)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'Last 30 Days',
																	value: [
																		startOfDay(subDays(new Date(), 30)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'This month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()),
																				1
																			)
																		),
																		endOfDay(lastDayOfMonth(new Date())),
																	],
																},
																{
																	label: 'Last month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()) - 1,
																				1
																			)
																		),
																		endOfDay(
																			lastDayOfMonth(
																				new Date(
																					getYear(new Date()),
																					getMonth(new Date()) - 1,
																					1
																				)
																			)
																		),
																	],
																},
															]}
														/>
													</th>
													<th>
														<DateRangePicker
															style={{
																minWidth: '200px',
															}}
															placement="auto"
															placeholder="Select date"
															format="MM/dd/yyyy"
															onChange={this.handleDateChange.bind(
																this,
																'closeddt'
															)}
															defaultValue={this.fs.getValue('closeddt')}
															ranges={[
																{
																	label: 'today',
																	value: [
																		startOfDay(new Date()),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'yesterday',
																	value: [
																		startOfDay(addDays(new Date(), -1)),
																		endOfDay(addDays(new Date(), -1)),
																	],
																},
																{
																	label: 'last7Days',
																	value: [
																		startOfDay(subDays(new Date(), 6)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'Last 30 Days',
																	value: [
																		startOfDay(subDays(new Date(), 30)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'This month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()),
																				1
																			)
																		),
																		endOfDay(lastDayOfMonth(new Date())),
																	],
																},
																{
																	label: 'Last month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()) - 1,
																				1
																			)
																		),
																		endOfDay(
																			lastDayOfMonth(
																				new Date(
																					getYear(new Date()),
																					getMonth(new Date()) - 1,
																					1
																				)
																			)
																		),
																	],
																},
															]}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="clientorder"
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('clientorder')}
														/>
													</th>
													<th>
														<Form.Control
															type="text"
															data-field="budgett"
															data-type="number"
															readOnly
															onClick={this.triggerNumberModal.bind(this)}
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue('budgett')}
														/>
													</th>
													<SecureContent
														attributeNo={200}
														attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
													>
														<SecureContent.HasAccess>
															<th>
																<Form.Control
																	type="text"
																	data-field="budgett"
																	data-type="number"
																	readOnly
																	onClick={this.triggerNumberModal.bind(this)}
																	onChange={this.handleSearch}
																	defaultValue={this.fs.getValue('budgett')}
																/>
															</th>
														</SecureContent.HasAccess>
													</SecureContent>
													<th>
														<Form.Control
															type="text"
															data-field="requestedRetainerAmount"
															data-type="number"
															readOnly
															onClick={this.triggerNumberModal.bind(this)}
															onChange={this.handleSearch}
															defaultValue={this.fs.getValue(
																'requestedRetainerAmount'
															)}
														/>
													</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{!this.state.dataIsLoaded
													? showLoading()
													: this.state.data.length === 0 &&
													  isFiltered(
															this.state.searchProperties ||
																this.state.sortProperty
													  )
													? showEmpty()
													: this.state.data
															.slice(0, this.state.pageSize.value)
															.map((item, i) => (
																<tr
																	key={i}
																	data-key={i}
																	data-status={
																		item.closeddt
																			? `status-closed`
																			: 'status-open'
																	}
																	data-client={item.client}
																	data-manager={item.manager}
																	className={`project ${
																		this.state.checks[i] ? `active` : ''
																	}`}
																>
																	<td>
																		<div className="d-flex justify-content-center">
																			<Form.Check
																				label=""
																				type="checkbox"
																				checked={this.state.checks.includes(
																					item.id
																				)}
																				onChange={() => {
																					this.handleCheck(item.id);
																				}}
																				data-testid={`projects-table-row-${i}-check`}
																			/>
																		</div>
																	</td>
																	<td>
																		<div className="d-flex flex-row align-content-center align-items-center">
																			<Link
																				to={'/projects/' + item.id + '/items'}
																				className="text-charcoal hover-view-icon"
																				data-testid={`projects-table-row-${i}-code`}
																			>
																				{item.proj}
																			</Link>
																			{item.numAttachments > 0 && (
																				<Button
																					as={Link}
																					to={generatePath(
																						URI.project.viewPage,
																						{
																							id: item.id,
																							page: 'documents',
																						}
																					)}
																					variant="ivory"
																					size="sm"
																					className="border-0 fsx-16 ri-attachment-2 square ms-1"
																					style={{
																						textDecoration: 'none',
																					}}
																				/>
																			)}
																		</div>
																	</td>
																	<td>
																		<Link
																			to={'/projects/' + item.id + '/items'}
																			className="text-charcoal hover-view-icon"
																		>
																			{item.projn}
																		</Link>
																	</td>
																	<td>{item.client}</td>
																	<td>{item.clientName}</td>
																	<td>{item.manager}</td>
																	<td>
																		{item.dateopened
																			? formatDate(item.dateopened)
																			: ''}
																	</td>
																	<td>
																		{item.closeddt
																			? formatDate(item.closeddt)
																			: ''}
																	</td>
																	<td>{item.clientorder}</td>
																	<SecureContent
																		attributeNo={200}
																		attributeType={
																			SECURITY_ATTRIBUTE_TYPES.DenyAccess
																		}
																	>
																		<SecureContent.HasAccess>
																			<td className="align-right">
																				{currencyFormat(
																					item.totalBudget ?? 0,
																					'$'
																				)}
																			</td>
																		</SecureContent.HasAccess>
																	</SecureContent>
																	<td
																		className="align-right form-control-color text-decoration-underline"
																		style={{
																			cursor: 'pointer',
																		}}
																	>
																		<span
																			onClick={(e) => {
																				this.handleDocument(e, item.id);
																			}}
																		>
																			{currencyFormat(
																				item.requestedRetainerAmount,
																				'$'
																			)}
																		</span>
																	</td>

																	<td>{item.sentDateTimeUtc}</td>
																</tr>
															))}
											</tbody>
										</Table>
									</div>
								) : this.state.dataIsLoaded ? (
									<div className="row justify-content-center text-center py-5">
										<div className="col-md-3">
											<img src={noItems} className="mw-100 mb-4" alt="" />

											<h6>Create your first project</h6>
											<p>
												This is where you will organize your specifications,
												proposals and purchase orders.
											</p>

											<Button
												as={Link}
												to={URI.project.add}
												variant="primary"
												size="md"
												className="btn-icon mt-4"
											>
												<i className="ri-add-line ri-lg"></i> Add Project
											</Button>
										</div>
									</div>
								) : (
									<div className="row justify-content-center text-center py-5">
										<Spinner isChild={true} />
									</div>
								)}
							</Col>
						</Row>
					</Container>

					{(() => {
						if (this.state.data.length) {
							return this.renderPagination();
						}
					})()}
				</div>

				<DeleteModal
					refreshData={this.refreshDataAfterDelete.bind(this)}
					show={this.state.showModal}
					hideModal={() => this.setState((prev) => (prev.showModal = false))}
					responses={this.state.deleteResponses}
				/>
				<FilterNumberModal
					defaultVal={this.state.defaultNumberVal}
					defaultTitle={this.state.modalTitle}
					show={this.state.showNumberModal}
					toggleModal={this.toggleNumberModal}
					updateSearch={this.updateSearch.bind(this)}
				></FilterNumberModal>
			</>
		)(13, SECURITY_ATTRIBUTE_TYPES.DenyAccess);
	}
}

export default WithRouter(ProjectList);
