import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IInvoices } from 'api/resources/invoices';
import { getInvoices } from 'api/resources/invoices';

import { ECacheKeys } from 'cache/CacheKeys';

export const useGetInvoices = (
	filter?: string,
	options?: UseQueryOptions<IInvoices[]>
) => {
	const query = useQuery<IInvoices[]>({
		queryKey: [ECacheKeys.Invoices, filter],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getInvoices(filter),
		...(options || {}),
	});

	return query;
};
