import React from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	FloatingLabel,
	Button,
	NavLink,
	Spinner,
} from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';

import { ApiService } from '../../../lib/api/HttpService';
import URI from '../../../defaults/RoutesDefault';
import { generatePath } from 'react-router-dom';
import {
	displayAlert,
	displayAlertError,
	getErrorMessage,
} from '../../../utilities/Response';

import logo from '../../../assets/images/logo.svg';
import logoDark from '../../../assets/images/logo-dark.svg';

import briaHammel from '../../../assets/images/bria-hammel.png';
import thomFilicia from '../../../assets/images/thom-filicia.png';
import libbyLangdon from '../../../assets/images/libby-langdon.png';

class AcceptInvitation extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: '',
			showErrorMessage: false,
			allowRegister: false,
			isVerifying: true,
			isValidForm: false,
			validations: {
				username: [
					{
						name: 'MinMax',
						value: [5, 15],
						desc: 'Your username must be between 5 and 15 characters long',
						valid: false,
					},
					{
						name: 'Match',
						value: /^[A-Za-z0-9.!_\-#&]{5,}$/,
						desc: 'It can contain letters, numbers, and the following special characters: ! . # & - _',
						valid: false,
					},
				],
				password: [
					{
						name: 'MinMax',
						value: [8, 255],
						desc: 'Your password must be at least 8 characters long',
						valid: false,
					},
					{
						name: 'Match',
						value:
							/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
						desc: 'It must contain at least one uppercase letter, one lowercase letter, one digit, one special character (acceptable characters in password are !@$%&*)',
						valid: false,
					},
				],
				confirmPassword: [
					{
						name: 'MatchPassword',
						value: 0,
						desc: 'Confirm password must match the password',
						valid: false,
					},
				],
			},
		};

		this.api = new ApiService();
		this.registerData = {};
	}

	async componentDidMount() {
		const searchParams = new URLSearchParams(document.location.search);
		const token = searchParams.get('token');

		await this.api
			.getCompleteInvitation(token)
			.then((resp) => {
				this.setState({
					allowRegister: true,
					isVerifying: false,
				});
				this.registerData = resp;
			})
			.catch((err) => {
				this.setState({
					allowRegister: false,
					isVerifying: false,
				});

				window.onbeforeunload = null;
				setTimeout(() => {
					window.location.href = generatePath(URI.login.base);
				}, 2000);
			});
	}

	setLoading = (isLoading) =>
		this.setState({
			isLoading,
		});

	handleRegisterClick = async (e) => {
		const { isValidForm } = this.state;
		const { password, confirmPassword } = this.registerData;

		if (!isValidForm) {
			displayAlertError('Please enter valid data before submitting.');
			return;
		}

		if (password !== confirmPassword) {
			displayAlertError('Passwords do not match');
		} else {
			this.setLoading(true);
			this.registerData.success = true;
			this.registerData.tokenExpired = true;
			this.registerData.usernameTaken = true;

			await this.api
				.postCompleteInvitation(this.registerData)
				.then((resp) => {
					displayAlert('success', 'Registration successful');
					window.onbeforeunload = null;
					setTimeout(() => {
						window.location.href = generatePath(URI.login.base);
					}, 2000);
				})
				.catch((error) => {
					displayAlertError(getErrorMessage(error));
					this.setLoading(false);
				});
		}
	};

	validate(validate, val) {
		const { length } = val;
		const { value } = validate;

		switch (validate.name) {
			case 'MinMax':
				return length >= value[0] && length <= value[1];
			case 'Match':
				return length && Boolean(val.match(value));
			case 'MatchPassword':
				return this.registerData['password'] === val;

			default:
				return false;
		}
	}

	validateForm() {
		let isValidForm = true;
		Object.values(this.state.validations).map((validate) => {
			validate.map((validate) => {
				if (!validate.valid) {
					isValidForm = false;
				}
			});
		});

		this.setState({
			isValidForm,
		});
	}

	handleChange = (e) => {
		const {
			validations: { username, password, confirmPassword },
		} = this.state;
		let key, value;
		if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			}

			const validationsObj =
				key === 'username'
					? username
					: key === 'password'
					? password
					: confirmPassword;

			validationsObj.map((validate, index) => {
				if (!this.validate(validate, value)) {
					validationsObj[index].valid = false;
				} else {
					validationsObj[index].valid = true;
				}
			});

			this.state.validations[key] = validationsObj;

			this.setState(
				{
					validations: this.state.validations,
				},
				this.validateForm
			);
		}
		this.registerData[key] = value;
	};

	renderSpinner() {
		return (
			<div className="flex-grow-1 loginRight-form">
				<div>
					<h1 className="fsx-40 mb-4 text-center">Verification in Progress</h1>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: '10vh' }}
					>
						<div
							className="spinner-border"
							style={{ width: '3rem', height: '3rem' }}
							role="status"
						></div>
					</div>
					<p className="text-secondary-ash fsx-16 mb-4 text-center">
						Please wait while we verify your invitation.
					</p>
				</div>
			</div>
		);
	}

	renderExpiredToken() {
		return (
			<div className="flex-grow-1 loginRight-form">
				<div>
					<h1 className="fsx-40 mb-4 text-center">
						Invitation is no longer valid
					</h1>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: '10vh' }}
					>
						<div
							className="spinner-border"
							style={{ width: '3rem', height: '3rem' }}
							role="status"
						></div>
					</div>
					<p className="text-secondary-ash fsx-16 mb-4 text-center">
						Redirecting to login page.
					</p>
				</div>
			</div>
		);
	}

	renderValidation(validations, name) {
		const displayText = (validate) => {
			if (
				name === 'confirmPassword' &&
				!validate.valid &&
				this.registerData['confirmPassword']
			) {
				return <span className="text-danger fw-bold">{validate.desc}</span>;
			}

			return validate.desc;
		};
		return validations.map((validate) => (
			<Row className="px-2 mb-2">
				<Col>
					<p className="mb-0 strikethrough text-secondary-ash">
						{!validate.valid || name === 'confirmPassword' ? (
							displayText(validate)
						) : (
							<del>{validate.desc}</del>
						)}
					</p>
				</Col>
				<Col
					style={{
						maxWidth: '50px',
					}}
				>
					<strong
						className={`dot ${name === 'confirmPassword' ? 'd-none' : ''}`}
						style={{
							'--color': validate.valid ? '#008B6D' : 'lightgrey',
						}}
					></strong>
				</Col>
			</Row>
		));
	}

	render() {
		const {
			validations: { username, password, confirmPassword },
			isLoading,
			isValidForm,
			allowRegister,
		} = this.state;
		return (
			<>
				<Container fluid className="loginContainer d-lg-flex">
					<div className="p-4 mb-5 d-lg-none">
						<img
							src={logoDark}
							width="100%"
							height="auto"
							className="logo"
							alt=""
						/>
					</div>
					<Row className="w-100 justify-content-center">
						<Col lg={4} className="d-none d-lg-flex loginLeft flex-column">
							<div className="p-4 flex-grow-1">
								<img
									src={logo}
									width="100%"
									height="auto"
									className="logo"
									alt=""
								/>
							</div>
							<div className="loginLeft-footer">
								<div className="mb-3">
									TRUSTED BY THOUSANDS OF INTERIOR DESIGNERS
								</div>

								<div className="logos px-4 pb-4">
									<img src={briaHammel} alt="" />

									<img src={thomFilicia} alt="" />

									<img src={libbyLangdon} alt="" />
								</div>
							</div>
						</Col>

						<Col lg={8} className="d-flex loginRight flex-column">
							<div className="flex-grow-1 loginRight-form">
								{(() => {
									if (!this.state.isVerifying && this.state.allowRegister) {
										return (
											<div>
												<h1 className="fsx-40 mb-3 text-center">
													Welcome to Design Manager
												</h1>

												<p className="text-primary-ash fsx-20 mb-5 text-center">
													Let's set up your new account.
												</p>
												<div className="d-grid gap-2 mb-3">
													<Form.Label className="fw-bold mb-0">
														Username
													</Form.Label>
													<Form.Control
														type="text"
														placeholder="Username"
														size="lg"
														name="username"
														onChange={this.handleChange}
													/>

													<div>
														{this.renderValidation(username, 'username')}
													</div>
												</div>

												<div className="d-grid gap-2 mb-5">
													<Form.Label className="fw-bold mb-0">
														Password
													</Form.Label>
													<Form.Control
														type="password"
														placeholder="Password"
														size="lg"
														name="password"
														onChange={this.handleChange}
													/>
													<div>
														{this.renderValidation(password, 'password')}
													</div>
													<Form.Control
														type="password"
														placeholder="Confirm password"
														size="lg"
														name="confirmPassword"
														onChange={this.handleChange}
													/>
													<div>
														{this.renderValidation(
															confirmPassword,
															'confirmPassword'
														)}
													</div>
												</div>
												<div className="d-block">
													<Button
														variant="primary"
														className="d-block w-100"
														size="lg"
														disabled={
															!allowRegister || !isValidForm || isLoading
														}
														onClick={this.handleRegisterClick}
													>
														{isLoading ? (
															<>
																<Spinner size="sm" />
																<span
																	style={{
																		position: 'relative',
																		top: '-2px',
																	}}
																>
																	&nbsp;Register
																</span>
															</>
														) : (
															'Register'
														)}
													</Button>
												</div>
											</div>
										);
									} else if (
										!this.state.isVerifying &&
										!this.state.allowRegister
									) {
										return this.renderExpiredToken();
									} else {
										return this.renderSpinner();
									}
								})()}
							</div>
						</Col>
					</Row>
				</Container>
				<div id="response-alert-div"></div>
			</>
		);
	}
}

export default AcceptInvitation;
