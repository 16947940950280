import ThreadComponent from '../../ThreadComponent';
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router';
import { HeaderLight } from '../../../components/Header';
import { Link, NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Row,
	Table,
} from 'react-bootstrap';
import { getSaveButtonName } from '../../../../utilities/String';
import { compareStr } from '../../../../helpers/String';
import React from 'react';
import { ApiService } from '../../../../lib/api/HttpService';
import InputBar from '../../../components/InputBar';
import { generatePath } from 'react-router';
import ListFilter from '../../../components/ListFilter';
import Select from 'react-select';
import MiniZoomer from '../../../components/MiniZoomer';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { currencyFormat } from '../../../../helpers/Number';
import debounce from 'lodash/debounce';
import FilterSort from '../../../../utilities/modules/FilterSort';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import ProjectProposalEditModal from './ProjectProposalEditModal';
import ProjectProposalResetDepositAllocModal from './ProjectProposalResetDepositAllocModal';

class ProjectProposalStatus extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('project_proposal_status');
		this.fs.setDefaultSort('loc desc');

		this.state = {
			project: {},
			proposal: {},
			projectName: '',
			showTableSearch: false,
			dataIsLoaded: false,
			statusTotals: {
				totalReceived: 0,
				totalUnallocated: 0,
				totallAllocated: 0,
			},
			statusItems: [],
			data: {},
			sortProperty: this.fs.getSort() ?? 'loc desc',
			searchProperties: {},
			page: 1,
			pageSize: { value: 10, label: '10' },
			showEditProposalModal: false,
			showResetDepositAllocModal: false,
		};

		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];

		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);

		this.api = new ApiService();
	}

	componentInit() {
		this.title = 'Set Proposal Status';
	}

	async componentDidMount() {
		const { id, pid } = this.props.params;

		let filter = `?$filter=id eq ${id}`;
		const project = await this.api.getProjects(filter);
		const proposal = await this.api.getProjectProposals(
			`?$filter=id eq ${pid}`
		);

		if (project) {
			const projectName = `${project[0].projn} [${project[0].proj}] - ${project[0].clientName}`;
			this.setState(
				{
					projectName: projectName,
					project: project[0],
					proposal: proposal[0],
				},
				() => {
					this.fetchData();
				}
			);
		}
	}

	async fetchData() {
		const statusTotals = await this.api.getProposalItems(
			`/status/totals?$filters=proj eq '${this.state.project.proj}' and propnum='${this.state.proposal.propnum}'`
		);

		let queryString = `$top=${this.state.pageSize.value + 1}&$skip=${
			(this.state.page - 1) * this.state.pageSize.value
		}&$filter=proj eq '${this.state.project.proj}' and propnum eq '${
			this.state.proposal.propnum
		}'`;

		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		queryString += filters.length > 0 ? ` and ${filters.join(' and ')}` : '';

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		const statusItems = await this.api.getProposalItems(
			`/status?${queryString}`
		);

		this.setState({
			statusTotals: statusTotals,
			statusItems: statusItems,
			dataIsLoaded: true,
		});

		this.enableSortTable();
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);
						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	handleChangePage = async (page) => {
		this.setState({
			page: page,
		});

		this.fetchData();
	};

	openEditProposalModal = async (item) => {
		this.setState({
			showEditProposalModal: true,
			data: item,
		});
	};

	closeProposalModal = async () => {
		this.setState({
			showEditProposalModal: false,
			data: {},
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			this.changePageHandler
		);
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	openResetDepositAlloc = () => {
		this.setState({
			showResetDepositAllocModal: true,
		});
	};

	closeResetDepositAlloc = () => {
		this.setState({
			showResetDepositAllocModal: false,
			dataIsLoaded: false,
		});

		this.fetchData();
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	rendeHeader() {
		const projectId = this.props.params.id;
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={getRouteWithParam(URI.project.viewPage, {
							id: projectId,
							page: 'proposals',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: projectId,
									page: 'items',
								}),
							}}
							linkAs={Link}
						>
							{this.state.projectName}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: projectId,
									page: 'proposals',
								}),
							}}
							linkAs={Link}
						>
							Proposals
						</Breadcrumb.Item>
						<Breadcrumb.Item>Set Proposal Status</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>Proposal Status</HeaderLight.Title>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderInputBar() {
		return (
			<Col lg="12">
				<InputBar className="toggle-input full budget-filter">
					<InputBar.Links className={`me-auto`}>
						<InputBar.Link
							className="single-link"
							childprops={{
								className: 'justify-content-center justify-content-md-start',
							}}
						>
							<div style={{ width: 'auto' }}>
								<Form.Check
									inline
									label="Deposit View"
									name="inputbar-manual-1"
									type="checkbox"
									className="pe-3"
								/>
							</div>
						</InputBar.Link>
						<InputBar.Link>
							<Form.Label htmlFor="inputPassword5" className="ilabel">
								Total
								<br />
								Received
							</Form.Label>
							<div className="form-group-extra reversed">
								<Form.Control
									type="text"
									placeholder="0.00"
									size="sm w-100px"
									disabled
									style={{
										paddingLeft: '12px',
									}}
									value={this.state.statusTotals.totalReceived.toFixed(2)}
								/>
							</div>
						</InputBar.Link>
						<InputBar.Link>
							<Form.Label htmlFor="inputPassword5" className="ilabel">
								Total
								<br />
								Allocated
							</Form.Label>
							<div className="form-group-extra reversed">
								<Form.Control
									type="text"
									placeholder="0.00"
									size="sm w-100px"
									disabled
									style={{
										paddingLeft: '12px',
									}}
									value={this.state.statusTotals.totallAllocated.toFixed(2)}
								/>
							</div>
						</InputBar.Link>
						<InputBar.Link>
							<Form.Label htmlFor="inputPassword5" className="ilabel">
								Total
								<br />
								Unallocated
							</Form.Label>
							<div className="form-group-extra reversed">
								<Form.Control
									type="text"
									placeholder="0.00"
									disabled
									size="sm w-100px"
									style={{
										paddingLeft: '12px',
									}}
									value={this.state.statusTotals.totalUnallocated.toFixed(2)}
								/>
							</div>
						</InputBar.Link>
					</InputBar.Links>
					<InputBar.Links>
						<ListFilter.Field>
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action fw-bold me-2 ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
							<Button
								as={Link}
								to="#"
								variant="primary"
								size="sm"
								className="btn-icon"
								disabled={!this.state.proposal.manualdep}
								onClick={this.openResetDepositAlloc}
							>
								Reset Deposit Allocation
							</Button>
						</ListFilter.Field>
					</InputBar.Links>
				</InputBar>
				<ListFilter.Spacer />
			</Col>
		);
	}

	renderPagination() {
		if (this.state.statusItems.length) {
			return (
				<div className={'d-flex flex-wrap justify-content-center'}>
					<div className={'d-flex flex-row align-items-center pagination'}>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={this.state.page === 1}
							onClick={() => this.handleChangePage(this.state.page - 1)}
						>
							<i className="ri-arrow-left-s-line"></i> Previous
						</Button>
						<span className={'pagination-span'}>{this.state.page}</span>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={
								this.state.statusItems.length <= this.state.pageSize.value
							}
							onClick={() => this.handleChangePage(this.state.page + 1)}
						>
							Next <i className="ri-arrow-right-s-line ms-1"></i>
						</Button>
					</div>
					<Select
						onChange={this.handleFilter('pageSize')}
						key={`${Math.floor(Math.random() * 1000)}-min`}
						options={this.availablePageSize}
						defaultValue={this.state.pageSize}
						className="react-select pagination-select mx-3"
						placeholder="Please select"
					/>
				</div>
			);
		}
	}

	renderContent() {
		return (
			<>
				<div className="table-gradient">
					<Table striped responsive className="a-table">
						<thead>
							<tr key="0" className="a-table-heading">
								<th className="mw-100px">
									<span className={this.sortClass('loc')} data-field="loc">
										Loc. Guide
									</span>
								</th>
								<th className="mw-150px">
									<span className={this.sortClass('locn')} data-field="locn">
										Location Name
									</span>
								</th>
								<th className="mw-100px">
									<span className={this.sortClass('item')} data-field="item">
										Ref. No.
									</span>
								</th>
								<th className="mw-260px">
									<span className={this.sortClass('desc')} data-field="desc">
										Description
									</span>
								</th>
								<th className="mw-200px">
									<span
										className={this.sortClass('statusName')}
										data-field="statusName"
									>
										Status
									</span>
								</th>
								<th className="mw-100px">
									<span
										className={this.sortClass('quantity')}
										data-field="quantity"
									>
										Qty
									</span>
								</th>
								<th>
									<span
										className={this.sortClass('esttprice')}
										data-field="esttprice"
									>
										Estimated Price
									</span>
								</th>
								<th>
									<span
										className={this.sortClass('esttstax')}
										data-field="esttstax"
									>
										Estimated Sales Tax
									</span>
								</th>
								<th>
									<span
										className={this.sortClass('totest')}
										data-field="totest"
									>
										Estimated Total
									</span>
								</th>
								<th>
									<span
										className={this.sortClass('estdep')}
										data-field="estdep"
									>
										Requested Deposit
									</span>
								</th>
								<th>
									<span
										className={this.sortClass('actdep')}
										data-field="actdep"
									>
										Received Deposit
									</span>
								</th>
							</tr>
							<tr
								className={`a-table-search-fields ${
									this.state.showTableSearch ? '' : 'd-none'
								}`}
							>
								<th>
									<Form.Control
										type="text"
										data-field="loc"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="locn"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="item"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="desc"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="statusName"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="quantity"
										data-type="number"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="esttprice"
										data-type="number"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="esttstax"
										data-type="number"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="totest"
										data-type="number"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="estdep"
										data-type="number"
										onChange={this.handleSearch}
									/>
								</th>
								<th>
									<Form.Control
										type="text"
										data-field="actdep"
										data-type="number"
										onChange={this.handleSearch}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{!this.state.dataIsLoaded
								? showLoading()
								: this.state.statusItems.length === 0
								? showEmpty()
								: this.state.statusItems
										.slice(0, this.state.pageSize.value)
										.map((item, i) => (
											<tr key={i}>
												<td>{item.loc}</td>
												<td>{item.locn}</td>
												<td>{item.item}</td>
												<td>{item.desc}</td>
												<td>
													<strong className={`dot-primary`}></strong>
													{item.statusName}
												</td>
												<td>{item.quantity}</td>
												<td>{currencyFormat(item.esttprice, '$')}</td>
												<td>{currencyFormat(item.esttstax, '$')}</td>
												<td>{currencyFormat(item.totest, '$')}</td>
												<td>{currencyFormat(item.estdep, '$')}</td>
												<td>
													<Link
														className="text-charcoal hover-view-icon"
														onClick={() => this.openEditProposalModal(item)}
													>
														{currencyFormat(item.actdep, '$')}
													</Link>
												</td>
											</tr>
										))}
						</tbody>
					</Table>
					{this.renderPagination()}
				</div>
			</>
		);
	}

	render() {
		return (
			<>
				{this.rendeHeader()}
				<div className="content-padding min-height">
					<Container fluid>
						{this.renderInputBar()}
						{this.renderContent()}

						<ProjectProposalEditModal
							show={this.state.showEditProposalModal}
							hideModal={this.closeProposalModal}
							data={this.state.data}
							proposal={this.state.proposal}
							project={this.state.project}
						/>
						<ProjectProposalResetDepositAllocModal
							show={this.state.showResetDepositAllocModal}
							hideModal={this.closeResetDepositAlloc}
							proj={this.state.project.proj}
							propnum={this.state.proposal.propnum}
						/>
						<ProjectProposalResetDepositAllocModal
							show={this.state.showResetDepositAllocModal}
							hideModal={this.closeResetDepositAlloc}
							proj={this.state.project.proj}
							propnum={this.state.proposal.propnum}
						/>
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(ProjectProposalStatus);
