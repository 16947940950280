import React from 'react';
import {
	Breadcrumb,
	Dropdown,
	Col,
	Container,
	Row,
	Table,
	Form,
	Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import URI from '../../../../defaults/RoutesDefault';
import Header from '../../../components/Header';
import ListFilter from '../../../components/ListFilter';
import DatePicker from 'react-datepicker';
import { formatDate, formatFilterDate } from '../../../../helpers/Date';
import { ApiService } from '../../../../lib/api/HttpService';
import Select from 'react-select';
import dayjs from 'dayjs';
import { displayAlert } from '../../../../utilities/Response';
import { currencyFormat } from '../../../../helpers/Number';
import { getCookie } from '../../../../utilities/Auth';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { isFiltered } from '../../../../helpers/Util';
import { debounce } from 'lodash';
import { DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import { renderSecureContent } from '../../../../utilities/modules/SecureContent';
import { CreditCardOverviewHeader } from './CreditCardOverviewHeader';

class CreditCardList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			checks: {},
			totalChecked: 0,
			data: [],
			startDate: new Date(),
			minDate: new Date(),
			dataIsLoaded: false,
			accounts: [],
			creditCardData: {
				creditCardAccount: '',
				date: dayjs(new Date().toString()).format('MM/YYYY'),
				showUnclearedOnly: false,
			},
			page: 1,
			pageSize: { value: 10, label: '10' },
			searchProperties: {},
			sortProperty: 'date asc',
		};

		this.api = new ApiService();
		this.availablePageSize = [
			{ value: 10, label: '10' },
			{ value: 20, label: '20' },
			{ value: 50, label: '50' },
			{ value: 75, label: '75' },
			{ value: 100, label: '100' },
		];
		this.userCode = getCookie('dmUsercode');
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	componentInit() {
		this.setTitle('Credit Card').setActionBar(true);
	}

	async componentDidMount() {
		const accounts = await this.api.getGLAccounts(`?$filter=fixedtype eq 202`);
		let { creditCardData } = this.state;
		creditCardData.creditCardAccount = accounts[0].account;

		this.setState(
			{
				accounts: accounts.map((account) => {
					return {
						value: account.account,
						label: `[${account.account}] ${account.accountn}`,
					};
				}),
				dataIsLoaded: false,
				creditCardData: creditCardData,
			},
			this.getCreditCardList
		);
	}

	async getCreditCardList() {
		const { creditCardData } = this.state;
		await this.api.createCreditCardGrids(creditCardData).catch((error) => {
			if (error.response.data && error.response.data.userError) {
				displayAlert('danger', error.response.data.userError);
			} else {
				displayAlert('danger', 'Failed fetching data.');
			}
			this.setState({
				dataIsLoaded: true,
			});
		});

		const filter = `?${this.buildFilters()}`;
		const creditCards = await this.api.getCreditCards(filter);
		this.renderData(creditCards);
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		let { creditCardData } = this.state;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name ?? e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		creditCardData[key] = value;
		this.setState(
			{
				page: 1,
				creditCardData,
				...{ [key]: value },
				dataIsLoaded: false,
			},
			this.getCreditCardList
		);
	};

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
		let { creditCardData } = this.state;
		creditCardData.date = dayjs(date.toString()).format('MM/YYYY');
		this.setState(
			{
				creditCardData,
			},
			this.getCreditCardList
		);
	};

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	setDefaultAccount = (e) => {
		let defaultAccount = this.state.accounts.filter((account) => {
			return account.value === this.state.creditCardData.creditCardAccount;
		});
		defaultAccount = defaultAccount.length
			? defaultAccount
			: this.state.accounts[0];

		return defaultAccount;
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			let totalChecked = 0;
			prevState.checks[indx] = e.target.checked;

			if (prevState.checks) {
				/* eslint-disable no-unused-vars */
				for (const [index, value] of Object.entries(prevState.checks)) {
					if (value) {
						totalChecked++;
					}
				}
				/* eslint-enable no-unused-vars */
			}

			prevState.totalChecked = totalChecked;

			if (totalChecked > 0) {
				document.getElementById('inline-check-th-0').checked = true;
			} else {
				document.getElementById('inline-check-th-0').checked = false;
			}

			return prevState;
		});
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);

		if (name !== 'pageSize') {
			this.fs.setFilter(name, e);
		}
	};

	handleSelectAllChecks = (e) => {
		// Get the target menu.
		const isChecked = e.target.checked;

		this.setState((prevState) => {
			if (isChecked) {
				if (this.state.totalChecked < this.state.data.length) {
					this.state.data.map((item, i) => {
						prevState.checks[i] = true;
						document.getElementById(
							'chk-projectview-items-' + i
						).checked = true;
						return null;
					});
				}
			} else {
				prevState.checks = {};
				prevState.totalChecked = 0;

				const checks = document.querySelectorAll(
					'.chk-projectview-items-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}
			}

			return prevState;
		});
	};

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	buildFilters(currentPage) {
		let { creditCardData } = this.state;
		let filters = [];
		let queryString = `$top=${this.state.pageSize.value + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize.value
		}`;
		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.ma) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		let preFilter = `$filter=usercode eq ${this.userCode} and (payment gt 0 OR charge gt 0)`;
		const filtersQuery =
			filters.length > 0
				? `${preFilter} and ${filters.join(' and ')}`
				: `${preFilter}`;
		queryString += `&${filtersQuery}`;

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.setState(
			{
				page: page,
				dataIsLoaded: false,
			},
			() => {
				this.getCreditCardList();
			}
		);
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			this.changePageHandler
		);
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						self.setState(
							{
								sortProperty: `${_e.attributes['data-field'].value} ${
									hasClass('desc', _e) ? 'asc' : 'desc'
								}`,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	handleSearchDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			this.changePageHandler
		);
	};

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.data.length <= this.state.pageSize.value}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key={`${Math.floor(Math.random() * 1000)}-min`}
					options={this.availablePageSize}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	renderBalanceTH() {
		return !this.state?.creditCardData?.showUnclearedOnly ? (
			<th>Balance</th>
		) : null;
	}

	renderBalanceTD(balance) {
		return (
			!this.state?.creditCardData?.showUnclearedOnly && (
				<td>{currencyFormat(balance, '$')}</td>
			)
		);
	}

	renderFilters() {
		return (
			<>
				<ListFilter className="credit-card-list-filter">
					<ListFilter.Fields md={12} lg={12} xl={9} className="width-25">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Account</strong>
							</Form.Label>
							<Select
								key={`${Math.floor(Math.random() * 10000)}-min`}
								menuPortalTarget={document.body}
								styles={{
									menuPortal: (base) => ({
										...base,
										zIndex: 9999,
									}),
									container: (provided) => ({
										...provided,
										width: '100%',
									}),
								}}
								options={this.state.accounts}
								placeholder="Please select"
								name="creditCardAccount"
								defaultValue={this.setDefaultAccount}
								onChange={this.handleChange}
							/>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Date</strong>
							</Form.Label>
							<div className="react-select-header">
								<DatePicker
									selected={this.state.startDate}
									onChange={this.handleDateChange}
									className="form-control form-control-sm"
									dateFormat="MM/yyyy"
									placeholderText="Select"
									showMonthYearPicker
								/>
							</div>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="d-block d-md-none">
								<strong></strong>
							</Form.Label>
							<Form.Check
								inline
								label="Show Uncleared Only"
								name="showUnclearedOnly"
								type="checkbox"
								id={`inline-chk-show-all`}
								onChange={this.handleChange}
							/>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						md={12}
						lg={12}
						xl={3}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-start justify-content-md-end"
					>
						{/* <ListFilter.Action className="">
                            <Button
                                as={Link}
                                to="#"
                                variant="ivory"
                                size="sm"
                                className={`btn-icon btn-action ${
                                    this.state.showTableSearch
                                        ? 'bg-primary-ash text-white'
                                        : ''
                                }`}
                                onClick={this.handleShowTableSearch}
                            >
                                <i className="ri-search-line"></i> Search
                            </Button> */}
						{/* <Button as={Link} to={URI.accountsPayable.creditCard.reconcile} variant="primary" size='sm' className='btn-icon ms-3'>Reconcile</Button> */}
						{/* </ListFilter.Action> */}
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		return (
			<>
				<CreditCardOverviewHeader />

				<div className="content-padding has-action-bar sticky-container">
					<Container fluid className="px-0">
						{renderSecureContent(
							<Row>
								<Col sm="12">
									{/* Filter */}
									{this.renderFilters()}

									<div className="table-gradient">
										<Table striped responsive className="a-table">
											<thead>
												<tr className="a-table-heading">
													<th className="ps-4 tw-min-w-32">Date</th>
													<th className="mw-110px">Check No.</th>
													<th className="text-center">Cleared</th>
													<th>Vendor Code</th>
													<th className="mw-290px">Payee / Description</th>
													<th className="mw-80px">PO No.</th>
													<th>Invoice No.</th>
													<th className="mw-120px">Payment (-)</th>
													<th className="mw-120px">Charge (+)</th>
													{this.renderBalanceTH()}
												</tr>
												<tr
													className={`a-table-search-fields ${
														this.state.showTableSearch ? '' : 'd-none'
													}`}
												>
													<th>
														<DateRangePicker
															style={{
																minWidth: '200px',
															}}
															placement="auto"
															placeholder="Select date"
															format="MM/dd/yyyy"
															onChange={this.handleSearchDateChange.bind(
																this,
																'date'
															)}
															onClean={this.handleSearchDateChange.bind(
																this,
																'date'
															)}
															ranges={[
																{
																	label: 'today',
																	value: [
																		startOfDay(new Date()),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'yesterday',
																	value: [
																		startOfDay(addDays(new Date(), -1)),
																		endOfDay(addDays(new Date(), -1)),
																	],
																},
																{
																	label: 'last7Days',
																	value: [
																		startOfDay(subDays(new Date(), 6)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'Last 30 Days',
																	value: [
																		startOfDay(subDays(new Date(), 30)),
																		endOfDay(new Date()),
																	],
																},
																{
																	label: 'This month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()),
																				1
																			)
																		),
																		endOfDay(lastDayOfMonth(new Date())),
																	],
																},
																{
																	label: 'Last month',
																	value: [
																		startOfDay(
																			new Date(
																				getYear(new Date()),
																				getMonth(new Date()) - 1,
																				1
																			)
																		),
																		endOfDay(
																			lastDayOfMonth(
																				new Date(
																					getYear(new Date()),
																					getMonth(new Date()) - 1,
																					1
																				)
																			)
																		),
																	],
																},
															]}
														/>
													</th>
													<th>
														<Form.Control
															data-type="text"
															data-field="checknum"
															onChange={this.handleSearch}
														/>
													</th>
													<th></th>
													<th className="text-center">
														<Form.Control
															data-type="text"
															data-field="supplier"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															data-type="text"
															data-field="payeen"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															data-type="text"
															data-field="ponum"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															data-type="text"
															data-field="sinvnum"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															data-type="text"
															data-field="payment"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															data-type="text"
															data-field="charge"
															onChange={this.handleSearch}
														/>
													</th>
													<th>
														<Form.Control
															data-type="text"
															data-field="balance"
															onChange={this.handleSearch}
														/>
													</th>
												</tr>
											</thead>
											<tbody>
												{!this.state.dataIsLoaded
													? showLoading()
													: this.state.data.length === 0 &&
													  isFiltered(this.state.searchProperties)
													? showEmpty()
													: this.state.data
															.slice(0, this.state.pageSize.value)
															.map((item, i) => (
																<tr
																	key={i}
																	data-key={i}
																	className={
																		this.state.checks[i] ? `active` : ''
																	}
																>
																	<td className="ps-4">
																		{formatDate(item.date)}
																	</td>
																	<td>{item.checknum}</td>
																	<td className="text-center">
																		<strong
																			className={
																				item.cleared
																					? `dot-primary`
																					: `dot-primary-red`
																			}
																		></strong>
																	</td>
																	<td>{item.supplier}</td>
																	<td>{item.payeen}</td>
																	<td>{item.ponum}</td>
																	<td>{item.sinvnum}</td>
																	<td>{currencyFormat(item.payment, '$')}</td>
																	<td>{currencyFormat(item.charge, '$')}</td>
																	{this.renderBalanceTD(item.balance)}
																</tr>
															))}
											</tbody>
										</Table>
										{this.state.data.length > 0 ? this.renderPagination() : ''}
									</div>
								</Col>
							</Row>
						)(61, SECURITY_ATTRIBUTE_TYPES.DenyAccess)}
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(CreditCardList);
