import React, { Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import InventoryItemForm from '../forms/InventoryItemForm/InventoryItemForm';
import { InventoryTypes } from '../../enums/inventoryTypes/inventoryTypes';
import { displayAlert } from '../../../utilities/Response';
import { EAlertTypes } from '../../enums/alertTypes/alertTypes';
import { useQueryClient } from '@tanstack/react-query';
import usePageReload from 'legacy/lib/api/hooks/usePageReload';

interface IAddDropdownProps {
	locationQuery: string;
	projectId: string;
	type: InventoryTypes;
	itemId?: string;
	resetQueries?: boolean;
}

interface IResponseMessage {
	message?: string;
	type?: EAlertTypes;
}

const modalItemTitle = 'Add Item from Inventory';
const modalComponentTitle = 'Add new Component from Inventory';

const AddDropdown = ({
	locationQuery,
	projectId,
	type,
	resetQueries,
	itemId,
}: IAddDropdownProps) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const queryClient = useQueryClient();
	const reloadPage = usePageReload();
	const title =
		type === InventoryTypes.Item ? modalItemTitle : modalComponentTitle;

	const handleCloseModal = async (response?: IResponseMessage) => {
		setShowConfirmationModal(false);
		if (response?.message) {
			displayAlert(response?.type, response?.message, 7000);
			if (response?.type === EAlertTypes.Success) {
				resetQueries
					? await queryClient.resetQueries([itemId, projectId, 'components'])
					: reloadPage();
			}
		}
	};

	return (
		<Fragment>
			<Dropdown className="d-inline">
				<Dropdown.Toggle id="dropdown-autoclose-true" variant="light" size="sm">
					Add From
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<Dropdown.Item onClick={() => setShowConfirmationModal(true)}>
						Inventory
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<ConfirmationModal
				show={showConfirmationModal}
				title={title}
				okBtnStyle="primary"
				footer={false}
				size="lg"
			>
				<InventoryItemForm
					closeModal={handleCloseModal}
					locationQuery={locationQuery}
					projectId={projectId}
					type={type}
					itemId={itemId}
				/>
			</ConfirmationModal>
		</Fragment>
	);
};

AddDropdown.displayName = 'AddDropdown';

export default AddDropdown;
