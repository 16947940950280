import React from 'react';
import { observer } from 'mobx-react';
import {
	Breadcrumb,
	Container,
	Col,
	Row,
	Form,
	Alert,
	Table,
	Button,
} from 'react-bootstrap';
import Select from 'react-select';
import ThreadComponent from '../../../ThreadComponent';
import EmployeeAddEditViewModel from '../EmployeeAddEditViewModel';
import { EmployeeService } from '../api/EmployeeService';
import { Link, NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import { FooterFormAction } from '../../../../templates/components/Form';
import { HeaderLight } from '../../../../templates/components/Header';
import { routeParam, WithRouter } from '../../../../helpers/Router';

interface Props {
	data?: any;
}

interface State {
	data: any;
}

interface SelectOptions {
	label?: string;
	value?: string;
}

class EmployeeAddEditTimeActivity extends ThreadComponent<Props, State> {
	readonly employeeAddEditModel: EmployeeAddEditViewModel;

	constructor(props: Props) {
		super(props);

		this.employeeAddEditModel = new EmployeeAddEditViewModel(
			new EmployeeService()
		);

		this.state = {
			data: {},
		};
	}

	componentDidMount(): void {
		this.setTitle('Time Activity - Add Employees').hasFormAction(true);

		const { data } = this.props;

		this.employeeAddEditModel.componentDidMount();

		this.setState({
			data: data,
		});
	}

	componentDidUpdate(previousProps: Props, previousState: State): void {
		if (previousProps.data !== this.props.data) {
			const { data } = this.props;

			this.setState({
				data: data,
			});
		}
	}

	handleEmployeeChange = (e: any, select?: any) => {
		if (!select) {
			// If checkbox
			if (
				e.target.tagName.toLowerCase() === 'input' &&
				e.target.getAttribute('type') === 'checkbox'
			) {
				this.employeeAddEditModel.setEmployeeData(
					e.target.name,
					e.target.checked ? true : false
				);
				return;
			}

			this.employeeAddEditModel.setEmployeeData(e.target.name, e.target.value);
		}

		// If select
		if (select && e.name) {
			this.employeeAddEditModel.setEmployeeData(e.name, select.value);
		}
	};

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.accountsPayable.payment.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{ to: URI.contact.employee }}
							linkAs={Link}
						>
							Employees
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.contact.employee }}
							linkAs={Link}
						>
							Employee Name
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.contact.employeeAddPage, {
									page: 'time',
								}),
							}}
							linkAs={Link}
						>
							Time
						</Breadcrumb.Item>
						<Breadcrumb.Item>Employee Time Activity</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={false}>
					<HeaderLight.Title>Employee Time Activity</HeaderLight.Title>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		const timeActivities = [];

		for (const i of this.employeeAddEditModel.timeActivities) {
			const tmp = { value: i.activity, label: i.activityn };
			if (
				i.activity !== null &&
				i.activityn !== null &&
				i.activity !== '' &&
				i.activityn !== ''
			)
				timeActivities.push(tmp);
			//if (i.activity == data.postaxc) this.defaultSalesTaxCode = tmp
		}

		return (
			<Form.Group as={Container} className="ms-0">
				<Row xs={1} lg={2} className="mb-3">
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Activity</Form.Label>
							</Col>
							<Col>
								<Select
									options={timeActivities}
									className="react-select"
									name="activity"
									placeholder="Please select"
								/>
							</Col>
						</Row>

						<Row className="align-items-center mb-3">
							<Col
								lg={3}
								className="text-lg-end mb-2 mb-lg-0 d-none d-lg-block"
							></Col>
							<Col>
								<Form.Check
									inline
									label="Always override the rate/cost with the values entered below no matter what the setting in the activity"
									name="check-group-override"
									type="checkbox"
									id={`check-group-override`}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col>
						<Row xs={1} lg={3} className="mb-0 mb-lg-3">
							<Col className="mb-2 mb-lg-0 d-flex d-lg-block">
								<Row className="align-items-center mb-3 pe-2 pe-lg-0">
									<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Tier 1 Rate</Form.Label>
									</Col>
									<Col className="d-flex align-items-center">
										<Form.Control type="text" placeholder="0.00" />
									</Col>
								</Row>
								<Row className="align-items-center mb-3 ps-2 ps-lg-0">
									<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Tier 1 Cost</Form.Label>
									</Col>
									<Col className="d-flex align-items-center">
										<Form.Control type="text" placeholder="0.00" />
									</Col>
								</Row>
							</Col>
							<Col className="mb-2 mb-lg-0 d-flex d-lg-block">
								<Row className="align-items-center mb-3 pe-2 pe-lg-0">
									<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Tier 2 Rate</Form.Label>
									</Col>
									<Col className="d-flex align-items-center">
										<Form.Control type="text" placeholder="0.00" />
									</Col>
								</Row>
								<Row className="align-items-center mb-3 ps-2 ps-lg-0">
									<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Tier 2 Cost</Form.Label>
									</Col>
									<Col className="d-flex align-items-center">
										<Form.Control type="text" placeholder="0.00" />
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0 d-flex d-lg-block">
								<Row className="align-items-center mb-3 pe-2 pe-lg-0">
									<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Tier 3 Rate</Form.Label>
									</Col>
									<Col className="d-flex align-items-center">
										<Form.Control type="text" placeholder="0.00" />
									</Col>
								</Row>
								<Row className="align-items-center mb-3 ps-2 ps-lg-0">
									<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Tier 3 Cost</Form.Label>
									</Col>
									<Col className="d-flex align-items-center">
										<Form.Control type="text" placeholder="0.00" />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	render(): JSX.Element {
		const { data } = this.state;

		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height">
					<Container fluid className="ms-0">
						{this.renderContent()}
					</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default observer(EmployeeAddEditTimeActivity);
