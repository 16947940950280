export function currencyFormat(num, currency = '$') {
	// conver to number
	num = isNaN(Number(num)) ? 0 : Number(num);
	// Check if negative
	const negative = num < 0;

	// The reason for multuplying in negative is to remove the negative
	num = negative ? num * -1 : num;
	// Then, format to two (2) decimal places.
	// Ex. 10.096 => 10.10, 10.009 => 10.01
	const snum = num.toLocaleString('en', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	return (
		(negative ? '–' : '') +
		(currency.length ? currency : '') +
		(snum || '0.00').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
}

export function amountFormat(num) {
	// conver to number
	num = isNaN(Number(num)) ? 0 : Number(num);
	// Check if negative
	const negative = num < 0;

	// The reason for multuplying in negative is to remove the negative
	num = negative ? num * -1 : num;
	// Then, format to two (2) decimal places.
	// Ex. 10.096 => 10.10, 10.009 => 10.01
	const snum = num.toLocaleString('en', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});

	return (
		(negative ? '–' : '') +
		(snum || '0.00').toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
}

export function currencyFormatNoWrap(num, currency = '$') {
	return (
		<span className="text-nowrap">
			{currencyFormat(num, currency).replace('–', '– ')}
		</span>
	);
}
