import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSession } from '../../context/session';
import CryptoJS from 'crypto-js';

async function encrypt(uid) {
	const secret = process.env.REACT_APP_CHAMELEON_HASH;
	const now = Math.floor(Date.now() / 1000);

	var hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
	hmac.update(`${uid}-${now}`);
	return hmac.finalize();
}

const debug = (...args) => {
	if (localStorage.debug === 'true') {
		return console.log(...args);
	}
};

export default function Chameleon() {
	debug('<Chameleon /> component loaded');

	const [chmln, setChmln] = useState(null);

	const { email, username, fullName } = useSession();

	useEffect(() => {
		debug('REACT_APP_CHAMELEON_HASH', process.env.REACT_APP_CHAMELEON_HASH);
		debug('REACT_APP_CHAMELEON_ID', process.env.REACT_APP_CHAMELEON_ID);
	}, [
		process.env.REACT_APP_CHAMELEON_HASH,
		process.env.REACT_APP_CHAMELEON_ID,
	]);

	useEffect(() => {
		if (!process.env.REACT_APP_CHAMELEON_ID || !chmln || !username) {
			debug('env var REACT_APP_CHAMELEON_ID not found. Aborting...');
			return;
		}

		async function identifyUser() {
			const now = Math.floor(Date.now() / 1000);
			const hash = [await encrypt(username), now].join('-');

			debug('calling window.chmln.identify()...');

			window.chmln.identify(username, {
				uid_hash: hash,
				email,
				name: fullName,
			});
		}

		identifyUser();
	}, [username, email, fullName, chmln]);

	useEffect(() => {
		if (
			!process.env.REACT_APP_CHAMELEON_HASH ||
			!process.env.REACT_APP_CHAMELEON_ID ||
			!window.chmln ||
			chmln
		) {
			debug(
				'Either REACT_APP_CHAMELEON_HASH or REACT_APP_CHAMELEON_ID or window.chmln not found. Aborting...'
			);
			return;
		}

		setChmln(window.chmln);
	}, [window.chmln]);

	if (
		!process.env.REACT_APP_CHAMELEON_ID ||
		!process.env.REACT_APP_CHAMELEON_HASH
	) {
		debug(
			'Either REACT_APP_CHAMELEON_HASH or REACT_APP_CHAMELEON_ID not found. Aborting...'
		);
		return null;
	}

	debug('Chameleon criteria met. Inserting script...');

	return (
		<Helmet>
			<script>{`!function(d,w){var t="${process.env.REACT_APP_CHAMELEON_ID}",c="chmln",i=d.createElement("script");if(w[c]||(w[c]={}),!w[c].root){w[c].accountToken=t,w[c].location=w.location.href.toString(),w[c].now=new Date,w[c].fastUrl='https://fast.chameleon.io/';var m="identify alias track clear set show on off custom help _data".split(" ");for(var s=0;s<m.length;s++){!function(){var t=w[c][m[s]+"_a"]=[];w[c][m[s]]=function(){t.push(arguments);};}();}i.src=w[c].fastUrl+"messo/"+t+"/messo.min.js",i.async=!0,d.head.appendChild(i);}}(document,window);`}</script>
		</Helmet>
	);
}
