import { useMutation } from '@tanstack/react-query';
import {
	patchTemporaryReconciliations,
	type ITemporaryReconciliationsParams,
} from 'api/resources/temporaryReconciliations';

export const usePatchTemporaryReconciliations = () => {
	return useMutation((params: ITemporaryReconciliationsParams) =>
		patchTemporaryReconciliations(params)
	);
};
