import React, { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useSession } from '../../context/session';

export default function Datadog(): null {
	const {
		email,
		userId,
		username,
		companyName,
		clientSerialCode,
		fullName,
		isTrial,
		isWebOnly,
	} = useSession();

	const canDatadog = () => {
		return (
			process.env.REACT_APP_DATADOG_APPLICATION_ID &&
			process.env.REACT_APP_DATADOG_CLIENT_TOKEN
		);
	};

	useEffect(() => {
		if (!canDatadog()) return;

		const config = {
			applicationId: String(process.env.REACT_APP_DATADOG_APPLICATION_ID),
			clientToken: String(process.env.REACT_APP_DATADOG_CLIENT_TOKEN),
			site: process.env.REACT_APP_DATADOG_SITE,
			service: process.env.REACT_APP_DATADOG_SERVICE,
			env: process.env.REACT_APP_ENVIRONMENT,
			version: process.env.REACT_APP_VERSION,
			sessionSampleRate: 100,
			sessionReplaySampleRate: 100,
			trackResources: true,
			trackLongTasks: true,
			trackUserInteractions: true,
		};

		datadogRum.init(config);
	}, []);

	useEffect(() => {
		if (!canDatadog()) return;

		const user = {
			...(email && { email }),
			...(userId && { id: userId }),
			...(username && { username }),
			...(fullName && { name: fullName }),
			...(companyName && { companyName }),
			...(clientSerialCode && { clientSerialCode }),
			isTrial,
			isWebOnly,
		};

		datadogRum.setUser(user);
	}, [
		email,
		userId,
		username,
		fullName,
		companyName,
		clientSerialCode,
		isTrial,
		isWebOnly,
	]);

	return null;
}

Datadog.displayName = 'Datadog';
