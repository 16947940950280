import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Switch from '../../../components/form/Switch';
import { FilterComponent } from '../../../components/listing/FilterComponent';
import SecureContent from '../../../components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security';

interface Props {
	onShowSearch?: any;
	onDeleteClick?: any;
	disabledDelete?: boolean;
	onSwitch: React.ChangeEventHandler<HTMLInputElement>;
	inactive?: boolean;
	showTableSearch?: boolean;
}
interface State {
	showTableSearch?: boolean;
	inactive?: boolean;
}

export default class VendorListFilter extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			showTableSearch: this.props.showTableSearch,
			inactive: this.props.inactive ?? false,
		};
	}

	handleShowTableSearch: any = (e: JSX.Element) => {
		this.setState({
			showTableSearch: !this.state.showTableSearch,
		});

		if (this.props.onShowSearch) {
			this.props.onShowSearch(this.state);
		}
	};

	handleDelete: any = (e: JSX.Element) => {
		if (this.props.onDeleteClick) {
			this.props.onDeleteClick(e);
		}
	};

	render(): JSX.Element {
		return (
			<SecureContent
				attributeNo={49}
				attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
			>
				<SecureContent.HasAccess>
					<FilterComponent>
						<FilterComponent.Fields lg={8} xl={8} className="width-20">
							<FilterComponent.Field>
								<Form.Label style={{ maxWidth: '100%' }}>
									<strong>Show Inactive</strong>
								</Form.Label>
								<span>
									<Switch
										onChange={this.props.onSwitch}
										checked={this.state.inactive}
									></Switch>
								</span>
							</FilterComponent.Field>
						</FilterComponent.Fields>

						<FilterComponent.Actions lg={4} xl={4}>
							<FilterComponent.Action className="ms-auto">
								<Button
									href="#"
									variant="ivory"
									size="sm"
									className={`btn-icon btn-action ${
										this.state.showTableSearch
											? 'bg-primary-ash text-white'
											: ''
									}`}
									onClick={this.handleShowTableSearch}
								>
									<i className="ri-search-line"></i> Search
								</Button>
							</FilterComponent.Action>
						</FilterComponent.Actions>
					</FilterComponent>
				</SecureContent.HasAccess>
			</SecureContent>
		);
	}
}
