import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import SampleNavigation2 from './SampleNavigatio2';
import SampleNavigation from './SampleNavigation';

class Menu extends React.Component {
	render() {
		return (
			<>
				<div className="bg-secondary-grey pt-5 pb-4 mt-5">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Menu</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4">
					<Row>
						<Col>
							<div>
								<SampleNavigation></SampleNavigation>
							</div>
						</Col>
						<Col>
							<div>
								<SampleNavigation2></SampleNavigation2>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Menu;
