import React, { Fragment, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ConfirmationModal from '../modal/ConfirmationModal';
import classNames from 'classnames';

interface IImageDragAndDropProps {
	image?: File | Blob | null;
	title?: string;
	iconClass?: string;
	subtitle?: string;
	isThumbnail?: boolean;
	handleFile: (file: File) => void;
	onClick?: () => void;
	handleDelete: (index: number) => void;
	imageIndex: number;
	deleteModalTitle?: string;
	deleteModalMessage?: string;
}

const ImageDragAndDrop = ({
	image = null,
	title = 'Add Photo',
	iconClass = '',
	subtitle = 'Or Drag and Drop',
	isThumbnail = false,
	handleFile,
	handleDelete,
	imageIndex = 0,
	deleteModalTitle = 'Are you sure you want to delete this image?',
	deleteModalMessage = 'The action cannot be undone.',
}: IImageDragAndDropProps) => {
	const [displayedImage, setDisplayedImage] = useState<string | null>(null);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [newImage, setNewImage] = useState<File>();

	useEffect(() => {
		if (image) {
			const displayImage = URL.createObjectURL(image);
			setDisplayedImage(displayImage);
		} else {
			setDisplayedImage(null);
		}
	}, [image]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: { 'image/*': [] },
		onDrop: (acceptedFiles: File[]) => {
			if (acceptedFiles.length > 0) {
				if (image) {
					setShowConfirmationModal(true);
					setNewImage(acceptedFiles[0]);
				} else {
					setDisplayedImage(URL.createObjectURL(acceptedFiles[0]));
					handleFile(acceptedFiles[0]);
				}
			}
		},
		noClick: isThumbnail && !!image,
	});

	const handleReplaceOrDeleteImage = () => {
		if (newImage) {
			setDisplayedImage(URL.createObjectURL(newImage));
			handleFile(newImage);
			setShowConfirmationModal(false);
			setNewImage(undefined);
		} else {
			handleDelete(imageIndex);
			setDisplayedImage(null);
			setShowConfirmationModal(false);
		}
	};

	return (
		<div
			className={classNames(
				'tw-flex tw-flex-col tw-justify-between tw-relative',
				{
					'tw-h-full tw-w-full tw-min-h-[52px] md:tw-min-h-[60px]': isThumbnail,
					'tw-h-[80%]': !isThumbnail,
				}
			)}
			data-testid="image-drag-and-drop"
		>
			<div
				className={`tw-border-gray-300 tw-rounded tw-h-full ${
					isThumbnail ? '' : 'tw-p-5 tw-border '
				}`}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(!image ? getRootProps() : {})}
			>
				<div
					className={` ${
						isThumbnail
							? 'tw-border-none'
							: 'tw-border tw-border-dashed tw-border-gray-300 tw-rounded tw-p-5 tw-min-h-[300px] tw-text-gray-500'
					}  tw-flex tw-flex-col tw-items-center tw-justify-center tw-font-semibold tw-text-lg tw-h-full`}
				>
					{!displayedImage && (
						<input
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...getInputProps()}
						/>
					)}
					{displayedImage ? (
						<div className="tw-w-full tw-h-full tw-flex ">
							<div
								className={`tw-w-full tw-h-full tw-bg-contain tw-bg-no-repeat tw-bg-center tw-bg-white ${
									isThumbnail
										? 'tw-min-h-[50px] md:tw-min-h-[58px]'
										: 'tw-min-h-[258px]'
								}`}
								style={{
									backgroundImage: `url('${displayedImage}')`,
								}}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...(isThumbnail ? {} : { ...getRootProps() })}
							/>
							{!isThumbnail && (
								<button
									className={`tw-flex tw-h-max`}
									onClick={() => setShowConfirmationModal(true)}
									type="button"
								>
									<i className="ri-delete-bin-7-line tw-text-[14px] tw-pt-0 tw-pb-0 tw-pl-[7px] tw-pr-[7px] tw-bg-zinc-100 tw-rounded-full" />
								</button>
							)}
						</div>
					) : (
						<Fragment>
							<div
								className={`${
									isThumbnail ? 'tw-p-1' : 'tw-p-3'
								} tw-flex tw-bg-gray-100 tw-rounded-full `}
							>
								<i
									className={`ri-image-add-fill ${iconClass} tw-leading-none`}
								/>
							</div>

							{title && (
								<span
									className={`${
										isThumbnail
											? 'tw-text-xs md:tw-text-[10px] lg:tw-text-[8px]'
											: 'tw-text-base'
									}  tw-font-bold tw-text-black tw-text-center tw-leading-none`}
								>
									{title}
								</span>
							)}
							{subtitle && (
								<span
									className={`${
										isThumbnail
											? 'tw-text-xs md:tw-text-[10px] lg:tw-text-[8px]'
											: 'tw-text-sm'
									}  tw-text-gray-500 tw-text-center tw-leading-none`}
								>
									{subtitle}
								</span>
							)}
						</Fragment>
					)}
				</div>
			</div>
			<ConfirmationModal
				show={showConfirmationModal}
				title={deleteModalTitle}
				okBtnStyle="danger"
				size="lg"
				confirmAction={handleReplaceOrDeleteImage}
				message={deleteModalMessage}
				toggleModal={() => setShowConfirmationModal(false)}
			/>
		</div>
	);
};

ImageDragAndDrop.displayName = 'ImageDragAndDrop';

export default ImageDragAndDrop;
