import React from 'react';
import { Container, Col, Row, Form, Table } from 'react-bootstrap';

class InputTable extends React.Component {
	render() {
		const List = [
			'Merchandise',
			'Freight',
			'DesF',
			'Install/Label',
			'Crating',
			'Time',
		];

		return (
			<>
				<div className="bg-secondary-grey pt-5 pb-4 mt-5">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Input Table</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4">
					<Row>
						<Col>
							<div className="table-gradient">
								<Table hover responsive borderless className="">
									<thead>
										<tr key="0">
											<th className="fs-xl">Type</th>
											<th className="fs-xl">Cost</th>
											<th className="fs-xl"></th>
											<th className="fs-xl">Profit</th>
											<th className="fs-xl"></th>
											<th className="fs-xl">Price</th>
											<th className="fs-xl"></th>
											<th className="fs-xl">Sales Tax</th>
											<th className="fs-xl"></th>
											<th className="fs-xl">Total</th>
										</tr>
									</thead>
									<tbody>
										{List.map((item, i) => (
											<tr key={i}>
												<td className="align-middle">{item}</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td className="align-middle text-secondary-ash">
													<i className="ri-add-line ri-lg"></i>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td className="align-middle text-secondary-ash">
													<i className="ri-add-line ri-lg"></i>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td className="align-middle text-secondary-ash">
													<i className="ri-add-line ri-lg"></i>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
												<td className="align-middle text-secondary-ash">
													<span className="fsx-24">=</span>
												</td>
												<td>
													<Form.Control
														type="text"
														placeholder="0.00"
														size="sm"
													/>
												</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr className="bg-beige" key="0">
											<td className="align-middle">Total</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
											<td className="align-middle text-secondary-ash">
												<i className="ri-add-line ri-lg"></i>
											</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
											<td className="align-middle text-secondary-ash">
												<i className="ri-add-line ri-lg"></i>
											</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
											<td className="align-middle text-secondary-ash">
												<i className="ri-add-line ri-lg"></i>
											</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
											<td className="align-middle text-secondary-ash">
												<span className="fsx-24">=</span>
											</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default InputTable;
