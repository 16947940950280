import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getPlaidToken, type IPlaidToken } from 'api/resources/plaidToken';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetLinkToken = (
	accountId?: number,
	options?: UseQueryOptions<IPlaidToken>
) => {
	return useQuery<IPlaidToken>({
		queryKey: [ECacheKeys.PlaidToken, accountId],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getPlaidToken(accountId),
		...(options || {}),
	});
};
