import React from 'react';
import {
	Col,
	Row,
	Form,
	Table,
	Button,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import ListFilter from '../../../components/ListFilter';
import { ApiService } from '../../../../lib/api/HttpService';
import ClientInvoiceAddInvoiceAddItemModal from './ClientInvoiceAddInvoiceAddItemModal';
import {
	setLocalStorage,
	getLocalStorage,
} from '../../../../utilities/LocalStorage';
import {
	FormEvent,
	setLocalStorageKeyValueObject,
} from '../../../../utilities/FormEvent';
import dayjs from 'dayjs';
import noItems from '../../../../assets/images/icons/item.svg';
import { getCookie } from '../../../../utilities/Auth';
import Select from '../../../../app/components/form/Select';
import { Is } from '../../../../helpers/Util';
import {
	displayAlert,
	displayAlertError,
	getErrorMessage,
} from '../../../../utilities/Response';
import MSG from '../../../../defaults/Message';
import {
	getItemInvoiceToDate,
	getItemPrice,
	getItemQuantity,
} from '../../../../utilities/modules/ClientInvoiceItem';
import { pF } from '../../../../utilities/Number';
import { debounce, delay, isEmpty, round, sortBy, toLower } from 'lodash';
import { makeid } from '../../../../utilities/String';
import { showLoading } from '../../../../helpers/Loading';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import { amountFormat, currencyFormat } from '../../../../helpers/Number';
import AsyncProjectsDropdown from '../../../../app/components/dropdowns/AsyncProjectsDropdown';
import AsyncEmployeesDropdown from '../../../../app/components/dropdowns/AsyncEmployeesDropdown';
import AsyncProposalsDropdown from '../../../../app/components/dropdowns/AsyncProposalsDropdown';
import FilterSort from '../../../../utilities/modules/FilterSort';
import { getStatusColor } from '../../../../helpers/Util';
import {
	sortLocalData,
	tableSortingEnableSort,
	tableSortingSetData,
} from '../../../../utilities/modules/TableSorting';
import { dateToPayload, stringToDate } from '../../../../helpers/Date';

class ClientInvoiceAddInvoice extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('ar_client_invoice_add');
		this.fs.setDefaultSort('item asc');
		this.state = {
			startDate: new Date(),
			minDate: new Date(),
			data: [],
			checks: [],
			totalChecked: 0,
			selectedProject: {
				proposals: [],
				items: [],
				proj: '',
			},
			selectedPropId: '',
			selectedItems: [],
			unselectedItems: [],
			updatedSelectedItems: [],
			doShowAllItems: false,
			totalInvoicePrice: 0,
			totalSalesTax: 0,
			activeInvoice: {
				projects: [],
				statusCodes: [],
			},
			selectedProposal: null,
			imagesLoaded: false,
			showItemModal: false,
			dataIsLoaded: false,
			defaultProjectValue: null,
			totalInvPrice: 0,
			totalSalesTaxVal: 0,
			totalDeposit: 0,
			totalRetainer: 0,
			totalBalanceDue: 0,
			totalAvailRetainer: 0,
			defaultManager: '',
			defaultManagerValue: null,
			isImagesLoaded: true,
			page: 1,
			pageSize: { value: 10, label: '10' },
		};

		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];

		this.updatedData = props.updatedData;
		this.api = new ApiService();
		this.formEvent = new FormEvent(`client_invoice_edit`);
		this.userCode = getCookie('dmUsercode');
		this.setIsChildSpinner(true);

		this.isEditing = this.props.params.invoiceId;
		this.invoiceId = this.props.params.invoiceId;

		this.saveProjectDateToLocalStorage = function (date) {
			const storedProj = localStorage.getItem('projObj');
			if (storedProj) {
				const id = JSON.parse(storedProj).id;
				return localStorage.setItem(`${id}_project_date`, JSON.stringify(date));
			}
		};

		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);

		this.preData = this.isEditing ? [] : getLocalStorage('preData', true) || [];

		this.sortPattern = (key) => [
			(d) => {
				if (key === '_itemprice') {
					return getItemPrice(d);
				}

				if (key === '_invqty') {
					return getItemQuantity(d);
				}

				if (key === '_invtodate') {
					return getItemInvoiceToDate(d);
				}

				if (typeof d[key] === 'string') {
					return toLower(d[key]);
				}

				return d[key] ?? '';
			},
		];
		this.fromQuickCreate = new URLSearchParams(window.location.search).get(
			'isQuickCreate'
		);
	}

	componentInit() {
		this.title = 'Add Invoice';
		this.setFormAction(true);
	}

	async componentDidMount() {
		this.mounted = true;
		window.enableSortTableModal = false;
		window.enableSortTable = false;
		setLocalStorage('client_invoice_selected_item', [], true);

		let { data } = this.props;

		if (this.isEditing && !isEmpty(data?.proj)) {
			const projData = await this.setInvoiceData(data, {
				loadImage: false,
			});
			await this.paginateLocalData(this.props.updatedData.selectedItems);

			await this.handleProjectSelect({
				hideModal: true,
				value: data?.proj,
				projectRealId: data?.projectId,
				id: data?.projectId,
				label: projData ? `${projData.projn} [${projData.proj}]` : '',
				propNum: this.updatedData?.propNum,
				dataIsLoaded: true,
				isImagesLoaded: true,
			});
		} else if (
			this.fromQuickCreate &&
			this.preData !== null &&
			this.preData.length
		) {
			setTimeout(async () => {
				const projObj = getLocalStorage('projObj', true);
				if (Object.entries(projObj).length) {
					this.handleProjectSelect(projObj);
				}
			}, 2000);
		} else {
			let data = this.props.data || {};
			this.setState({
				activeInvoice: data,
				dataIsLoaded: true,
			});

			this.updatedData.invDate = new Date();
		}
		const invoiceDate = this.updatedData.invDate
			? new Date(this.updatedData.invDate)
			: this.state.startDate;
		this.saveProjectDateToLocalStorage(invoiceDate);
		this.enableIfSortAvailable();
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data || {};

			if (this.isEditing) {
				await this.handleProjectSelect({
					hideModal: true,
					value: data?.proj,
					projectRealId: data?.projectId,
				});
				this.setState(
					{
						activeInvoice: data,
					},
					async () => {
						await this.setInvoiceData(data);
						await this.paginateLocalData(this.props.updatedData.selectedItems);
					}
				);
			} else {
				this.setState({
					activeInvoice: data,
					dataIsLoaded: true,
				});
			}
		} else if (
			this.updatedData.projectCode &&
			this.state.selectedProject.proj === '' &&
			this.props.switchTab
		) {
			let data = this.state.activeInvoice;
			data.proj = this.updatedData.projectCode;
			data.txdesc = this.updatedData.txDesc;
			data.posemployee = this.updatedData.mgrCode;
			data.invdt = this.updatedData.invDate;
			this.saveProjectDateToLocalStorage(this.updatedData.invDate);
			this.setInvoiceData(data, { loadImage: false });
		}

		this.enableIfSortAvailable();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	enableIfSortAvailable() {
		delay(() => {
			const sort = document.querySelectorAll('.a-table-heading.default .sort');
			if (sort?.length && !window.enableSortTable) {
				window.enableSortTable = true;
				this.enableSortTable();
			}
		}, 500);
	}

	async loadImages(processId) {
		const promises = [];
		const itemIds = [];
		// Let's save the images in local storage
		const imageData = {};
		// Custom checking processId === this.state.processId is placed
		// to abort other images loading and change of selectedProject and unselectedItems states

		const { selectedProject, unselectedItems } = this.state;
		if (selectedProject.items && selectedProject.items.length) {
			for (let item of selectedProject.items) {
				// Check if state current process is still this processId
				if (processId === this.state.processId && this.mounted) {
					if (item.primaryImageId) {
						const promise = this.api.getFile(
							item.primaryImageId + '/thumb?maxWidth=300'
						);
						promises.push(promise);
						itemIds.push(item.id);
					}
				}
			}
		}

		if (unselectedItems && unselectedItems.length) {
			for (let item of unselectedItems) {
				// Check if state current process is still this processId
				if (processId === this.state.processId && this.mounted) {
					// imageData[item.id] = fileThumb
					if (item.primaryImageId) {
						const promise = this.api.getFile(
							item.primaryImageId + '/thumb?maxWidth=300'
						);
						promises.push(promise);
						itemIds.push(item.id);
					}
				}
			}
		}

		function reflect(promise, index) {
			return promise.then(
				function (data) {
					return { data, status: 'fulfilled' };
				},
				function (error) {
					return { error, status: 'rejected' };
				}
			);
		}

		Promise.all(promises.map(reflect)).then((results) => {
			results.map((d, i) => {
				if (d.status === 'fulfilled') {
					imageData[itemIds[i]] = d.data;
				}
			});

			this.props.setImageData(imageData);

			delay(() => {
				this.setState({
					imagesLoaded: true,
					isImagesLoaded: true,
				});
			}, 800);
		});
	}

	getCurrentProjectCode(data) {
		const localData = getLocalStorage('client_invoice_edit', true);
		return localData?.projectCode ?? data?.proj;
	}

	async setInvoiceData(data, params = {}) {
		const processId = makeid(20);
		const projCode = this.getCurrentProjectCode(data);

		const proj = await this.api.getProjectByCode(projCode);
		const [employee] = await this.api.getClientEmployees(
			`?$filter=vendor eq '${this.updatedData.mgrCode ?? data.posemployee}'`
		);
		const projData = await this.getAdditionalData(projCode);
		Object.assign(proj, projData);

		// Load the retainer
		let projAvailableRetainer = null;
		if (proj?.id || data?.projectId) {
			projAvailableRetainer = await this.api.getProjectAvailableRetainer(
				proj?.id ?? data?.projectId
			);
			this.props.onDataChange({
				key: 'availableRetainer',
				value: projAvailableRetainer,
			});
		}

		const storedInv = getLocalStorage('client_invoice_edit', true);
		let currentItems = storedInv.items ?? [];
		const invoiceDate = data.invdt ? data.invdt : storedInv.invDate;
		this.saveProjectDateToLocalStorage(invoiceDate);
		this.updatedData.invDate = invoiceDate;
		if (
			data.currentItems &&
			data.currentItems.length > 0 &&
			currentItems.length === 0
		) {
			currentItems = data.currentItems.map((i) => i.item);
		}

		const selectedItems = projData.items.filter(
			(item) => item.tag && currentItems.includes(item.item)
		);

		const filteredItems = projData.items.filter(
			(item) => !item.tag && !currentItems.includes(item.item)
		);

		const ids = selectedItems.map((item) => item.id);
		setLocalStorageKeyValueObject('client_invoice_edit', 'items', currentItems);
		setLocalStorage('client_invoice_selected_item', ids, true);
		let selectedProposal = null;
		if (data.propnum || this.updatedData?.propNum)
			selectedProposal = await this.api.getProjectProposalByPropNum(
				data.propnum ?? this.updatedData?.propNum,
				projCode,
				true
			);

		const defaultProjectValue = isEmpty(proj)
			? null
			: {
					label: `${proj.projn} [${proj.proj}]`,
					value: proj.proj,
			  };
		this.setState(
			{
				processId,
				activeInvoice: data,
				selectedProject: proj,
				selectedProposal,
				startDate: invoiceDate,
				dataIsLoaded: params?.dataIsLoaded ?? true,
				unselectedItems: filteredItems,
				selectedItems,
				isImagesLoaded: params?.loadImage === false,
				imagesLoaded: params?.loadImage === false,
				defaultProjectValue,
				totalAvailRetainer: isEmpty(projAvailableRetainer)
					? 0
					: projAvailableRetainer?.amount || 0,
				defaultManagerValue: isEmpty(employee)
					? null
					: {
							label: `${employee.vendorn} [${employee.vendor}]`,
							value: employee.vendor,
					  },
			},
			() => {
				tableSortingSetData(selectedItems, 'client_invoice_selected');
				if (params?.loadImage !== false) {
					this.loadImages(processId);
				}
				this.calculateTotals(selectedItems);
			}
		);

		this.props.onDataChange({ key: 'invDate', value: data.invdt });
		this.props.onDataChange({ key: 'projectCode', value: projCode });
		this.props.onDataChange({
			key: 'propNum',
			value: storedInv.propNum ?? data.propnum,
		});
		this.props.onDataChange({ key: 'selectedItems', value: selectedItems });
		this.props.onDataChange({ key: 'txDesc', value: data.txdesc });
		this.props.onDataChange({ key: 'mgrCode', value: data.posemployee });
		await this.paginateLocalData(
			sortLocalData(this, selectedItems, this.sortPattern)
		);

		return proj;
	}

	handleDateChange = (date) => {
		let isoDateString = '';
		if (date) {
			const selectedDate = new Date(date);
			isoDateString = selectedDate.toISOString();
		}

		this.setState((prevState) => (prevState.startDate = isoDateString));
		this.props.onDataChange({
			key: 'invDate',
			value: isoDateString,
		});
		this.saveProjectDateToLocalStorage(date);
		this.updatedData.invDate = isoDateString;
	};

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			prev.showTableSearch = !prev.showTableSearch;
			return prev;
		});
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}
		this.props.onDataChange({ key: key, value: value });
	};

	handleMgrChange = async (e) => {
		let mngr = e?.value;
		if (!e) {
			mngr = null;
		} else if (!e?.label) {
			e = await this.api.getClientEmployeeByCode(mngr, true);
		}

		this.setState({
			defaultManagerValue: e,
			defaultManager: mngr,
		});
		this.props.onDataChange({ key: 'mgrCode', value: mngr });
	};

	handleChecks = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);
		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});
	};

	handleSelectAllChecks = ({ target }) =>
		this.setState({
			checks: target.checked
				? this.updatedData.selectedItems.map((item) => {
						return item.id;
				  })
				: [],
			totalChecked: target.checked ? this.updatedData.selectedItems.length : 0,
		});

	handleProposalSelect = async (e) => {
		const proposalId = e?.value;
		setLocalStorageKeyValueObject('client_invoice_edit', 'propNum', proposalId);
		this.props.onDataChange({ key: 'propNum', value: proposalId });
		this.setState(
			{
				selectedProposal: e,
				selectedPropId: proposalId,
			},
			async () => {
				await this.handleProjectSelect({
					value: this.state.defaultProjectValue?.value,
					propNum: proposalId,
				});
			}
		);

		// this.setActiveItems(proposalId)
	};

	handleProjectSelect = async (e) => {
		this.setState({
			dataIsLoaded: false,
		});

		if (e?.eventRef === 'dropdown') {
			this.props.setImageData({});
			this.updatedData.selectedItems = [];
			setLocalStorageKeyValueObject('client_invoice_edit', 'items', []);
		}

		const projectId = e?.value;
		const oldProjectId = this.state.defaultProjectValue?.value;
		setLocalStorageKeyValueObject(
			'client_invoice_edit',
			'projectCode',
			projectId
		);
		this.props.onDataChange({ key: 'projectCode', value: projectId });

		try {
			// Set default project state if changes is from project itself not on proposal select.
			if (!e?.propNum) {
				const selectedProposal =
					e?.value === oldProjectId ? this.state.selectedProposal : null;

				this.setState({
					defaultProjectValue: e,
					selectedProposal,
				});
			}

			let processId = makeid(20);
			const proj = await this.api.getProjectByCode(projectId);

			this.props.onDataChange({
				key: 'remarksrtf',
				value:
					this.props.updatedData?.remarksrtf ??
					this.props.data?.remarksrtf ??
					proj?.invremarksrtf,
			});

			// Set default project state.
			let defaultManagerValue = this.state.defaultManagerValue;
			if (proj?.manager && !defaultManagerValue) {
				defaultManagerValue = await this.api.getClientEmployeeByCode(
					proj?.manager,
					true
				);
			}

			this.setState({
				defaultManager: proj?.manager,
				defaultManagerValue,
			});

			const resp = await this.props.onPostGrid({
				proj: projectId,
				propnum: e?.propNum ?? '',
			});

			const projData = await this.getAdditionalData(projectId);
			Object.assign(proj, projData);

			const projAvailableRetainer = await this.api.getProjectAvailableRetainer(
				proj?.id ?? e.projectRealId
			);
			this.props.onDataChange({
				key: 'availableRetainer',
				value: isEmpty(projAvailableRetainer)
					? 0
					: projAvailableRetainer?.amount ?? 0,
			});

			const storedInv = getLocalStorage('client_invoice_edit', true);
			let currentItems = storedInv.items ?? [];

			const filteredItems = projData.items.filter(
				(item) => !currentItems.includes(item.item)
			);
			this.setState(
				{
					processId,
					selectedProject: proj,
					selectedPropId: '',
					updatedSelectedItems:
						e?.eventRef === 'dropdown' ? [] : this.state.updatedSelectedItems,
					dataIsLoaded:
						e?.dataIsLoaded ??
						(!this.isEditing || (this.isEditing && !e?.projectRealId)),
					unselectedItems: filteredItems,
					isImagesLoaded: e?.isImagesLoaded ?? false,
					totalAvailRetainer: isEmpty(projAvailableRetainer)
						? 0
						: projAvailableRetainer?.amount ?? 0,
				},
				() => {
					if (e?.isImagesLoaded !== true) {
						this.loadImages(processId);
					}

					if (this.fromQuickCreate) {
						this.handleAddItem(this.preData.length > 0 ? this.preData : []);
					}
				}
			);
		} catch (error) {
			this.setState({
				dataIsLoaded: true,
			});
			displayAlertError(getErrorMessage(error));
		}
	};

	handleChangePage = async (page) => {
		this.setState(
			{
				page,
			},
			() => {
				this.paginateLocalData(
					sortLocalData(this, this.updatedData.selectedItems, this.sortPattern)
				);
			}
		);
	};

	paginateLocalData = async (data) => {
		const newData = (data ?? []).slice(
			(this.state.page - 1) * this.state.pageSize.value,
			this.state.page * this.state.pageSize.value
		);

		newData.map((item) => {
			item.statusColor = getStatusColor(
				item.statusName,
				this.props.statusCodes
			);
		});

		this.setState({
			updatedSelectedItems: newData,
			processedData: data ?? [],
		});
	};

	getAdditionalData = async (projId) => {
		let filter = `?$filter=proj eq '${projId}'`;
		const proposals = await this.api
			.getProjectProposals(filter)
			.catch((err) => []);
		filter = `${filter} and usercode eq ${this.userCode}&$orderby=loc asc, item asc`;
		let items = await this.api.getClientInvoiceItems(filter);

		if (this.state.activeInvoice.statusCodes.length > 0) {
			items = this.getItemStatusCodes(items);
		}

		return {
			proposals,
			items,
		};
	};

	getItemStatusCodes(items) {
		const { statusCodes } = this.state.activeInvoice;

		for (let item of items) {
			const statusNumber = item.statusnumber;
			if (statusNumber) {
				const statusItem = statusCodes.find(
					(i) => i.statusNumber === statusNumber
				);
				item.statusName = statusItem?.statusName || 'Undefined';
			}
		}

		return items;
	}

	displayModalItems = (e) => {
		this.setState({
			showItemModal: true,
		});

		e.preventDefault();
	};

	hideModalItems() {
		window.enableSortTableModal = false;
		this.setState({
			showItemModal: false,
		});
	}

	handleAddItem = (ids) => {
		this.hideModalItems();

		let selectedIds = getLocalStorage('client_invoice_selected_item', true);
		selectedIds = [...selectedIds, ...ids];
		setLocalStorage('client_invoice_selected_item', selectedIds, true);
		this.updateItems(selectedIds);

		this.enableIfSortAvailable();
	};

	handleRemoveItem = (e) => {
		let ids = [];
		if (Is.empty(this.state.checks)) {
			displayAlert('danger', MSG.error.noSelected);
			return;
		}
		/* eslint-disable no-unused-vars */
		for (const [key, value] of Object.entries(this.state.checks)) {
			if (value) {
				ids.push(parseInt(value));
			}
		}

		const checks = document.querySelectorAll(
			'.chk-projectview-proposal-item input'
		);
		if (checks) {
			checks.forEach((e) => {
				e.checked = false;
			});
		}
		document.getElementById('inline-check-th-0').checked = false;
		this.setState({
			totalChecked: 0,
		});

		let selectedIds = getLocalStorage('client_invoice_selected_item', true);
		selectedIds = selectedIds.filter((id) => !ids.includes(id));
		setLocalStorage('client_invoice_selected_item', selectedIds, true);
		this.updateItems(selectedIds);

		if (!selectedIds?.length) {
			window.enableSortTable = false;
		}
	};

	updateItems(ids) {
		const selectedItems = this.state.selectedProject.items.filter((item) =>
			ids.includes(item.id)
		);
		const filteredItems = this.state.selectedProject.items.filter(
			(item) => !ids.includes(item.id)
		);

		this.setState({
			unselectedItems: filteredItems,
			checks: [],
		});

		this.calculateTotals(selectedItems);
		tableSortingSetData(selectedItems, 'client_invoice_selected');

		this.props.onDataChange({ key: 'selectedItems', value: selectedItems });

		this.paginateLocalData(
			sortLocalData(this, selectedItems, this.sortPattern)
		);

		const items = selectedItems.map((item) => item.item);
		setLocalStorageKeyValueObject('client_invoice_edit', 'items', items);
	}

	calculateTotals(selectedItems) {
		const totalRetainer =
			this.updatedData?.retAmount ??
			this.state.activeInvoice.enteredretainer ??
			0;
		let totalInvPrice = 0;
		let totalSalesTaxVal = 0;
		let totalDeposit = 0;
		if (selectedItems.length) {
			for (let i = 0; i < selectedItems.length; i++) {
				const item = selectedItems[i];
				totalInvPrice += parseFloat(getItemPrice(item));

				totalSalesTaxVal += round(item.invstax, 2);
				totalDeposit += parseFloat(item.invdep.toFixed(2));
			}
		}

		const totalBalanceDue =
			totalInvPrice + totalSalesTaxVal - totalDeposit - totalRetainer;

		this.setState({
			totalInvPrice: parseFloat(totalInvPrice).toFixed(2),
			totalSalesTaxVal: round(totalSalesTaxVal, 2),
			totalDeposit: parseFloat(totalDeposit).toFixed(2),
			totalBalanceDue: parseFloat(totalBalanceDue).toFixed(2),
		});
	}

	handleRetainerChange = (e) => {
		const totalRetainer = parseFloat(e.target.value || 0);
		const { totalInvPrice, totalSalesTaxVal, totalDeposit } = this.state;
		const totalBalanceDue =
			parseFloat(totalInvPrice) +
			parseFloat(totalSalesTaxVal) -
			parseFloat(totalDeposit) -
			parseFloat(totalRetainer);

		this.props.onDataChange({ key: 'retAmount', value: totalRetainer });

		this.setState({
			totalRetainer,
			totalBalanceDue: parseFloat(totalBalanceDue).toFixed(2),
		});
	};

	setPrices(index, isChecked) {
		let { acttprice, acttstax } = this.state.items[index];
		const { totalInvoicePrice, totalSalesTax } = this.state;

		if (!isChecked) {
			acttprice, (acttstax *= -1);
		}

		this.setState({
			totalInvoicePrice: totalInvoicePrice + acttprice,
			totalSalesTax: totalSalesTax + acttstax,
		});
	}

	sortData({ key, dir, data }) {
		if (!isEmpty(data)) {
			let ndata = sortBy(data, [
				(d) => {
					switch (key) {
						case '_invqty':
							return pF(d.invqty) + pF(d.tdinvqty);
						case '_invtodate':
							return pF(d.tdinvprice) + pF(d.tdotherinvprice);
						case '_itemprice':
							return (
								pF(d.invprice) +
								pF(d.otherinvprice) +
								pF(d.tdinvprice) +
								pF(d.tdotherinvprice)
							);
						default:
							return d[key] ?? '';
					}
				},
			]);

			if (dir === 'desc') {
				ndata = ndata.reverse();
			}

			return ndata;
		}
	}

	enableSortTable = (data) =>
		tableSortingEnableSort({
			dataKey: 'client_invoice_selected',
			classRef: this,
			targetTable: '.a-table-heading.default .sort',
			pattern: this.sortPattern,
			callback: (data, sort) => {
				this.paginateLocalData(data);
				this.setState({
					sortProperty: sort,
				});

				// Save sortProperty to localStorage
				this.fs.setSort(sort);
			},
		});

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	renderFilters() {
		return (
			<ListFilter className="filter-client-invoices-add">
				{/* className='item-search-filter' */}
				<ListFilter.Fields
					lg={12}
					xl={12}
					className="width-20"
					separator={`d-block d-lg-none`}
				>
					<ListFilter.Field className="col-lg-3">
						<Form.Label className="text-end">
							<strong>Project</strong>
						</Form.Label>
						<AsyncProjectsDropdown
							defaultValue={this.state.defaultProjectValue}
							className="react-select react-select-sm w-100"
							placeholder="Select Project"
							name="proj"
							onChange={(e) => {
								e.eventRef = 'dropdown';
								this.handleProjectSelect(e);
							}}
							isDisabled={this.isEditing}
						/>
					</ListFilter.Field>
					<ListFilter.Field className="col-lg-3">
						<Form.Label className="text-end">
							<strong>
								Proposal
								<br />
								No.
							</strong>
						</Form.Label>
						{/* <Form.Select
                            size="sm"
                            value={
                                this.updatedData?.propNum ??
                                this.state.selectedProposal
                            }
                            onChange={this.handleProposalSelect}
                        >
                            <option value={''}>Select Proposal</option>
                            {this.state.selectedProject.proposals.map(
                                (proposal, i) => (
                                    <option
                                        key={proposal.id}
                                        value={proposal.propnum}
                                    >
                                        {proposal.propnum} {proposal.propname}
                                    </option>
                                )
                            )}
                        </Form.Select> */}
						<AsyncProposalsDropdown
							key={`${Math.floor(Math.random() * 1000)}-min`}
							defaultValue={this.state.selectedProposal}
							className="react-select react-select-sm w-100"
							placeholder="Select Project"
							name="proj"
							onChange={this.handleProposalSelect}
							formatLabel="%KEY% [%VALUE%]"
							urlQuery={`?$filter=proj eq '${this.state.defaultProjectValue?.value}'`}
							valueKey="key"
							isClearable={true}
						/>
					</ListFilter.Field>

					{/* <Dropdown className="d-flex justify-content-end ms-auto" align="end">
                        <Dropdown.Toggle id="dropdown-autoclose-true" variant='ivory' size='sm'>
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href={URI.accountsReceivable.clientInvoices.addOverridePrice}><i className="ri-file-edit-line"></i> Override</Dropdown.Item>
                            <Dropdown.Item href="#"><i className="ri-layout-line"></i> Layout</Dropdown.Item>
                            <Dropdown.Item href="#"><i className="ri-delete-bin-5-line"></i> Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}
				</ListFilter.Fields>

				<ListFilter.Fields lg={12} xl={12} className="width-20 mb-3">
					<ListFilter.Field className="col-lg-3">
						<Form.Label className="text-end">
							<strong>Date</strong>
						</Form.Label>
						<div className="react-select-header">
							<DatePicker
								selected={
									this.updatedData.invDate
										? new Date(this.updatedData.invDate)
										: this.state.startDate != ''
										? new Date(this.state.startDate)
										: ''
								}
								onChange={this.handleDateChange}
								showMonthDropdown="true"
								showYearDropdown="true"
								className="form-control form-control-sm"
								placeholderText="Select"
							/>
						</div>
					</ListFilter.Field>

					<ListFilter.Field className="col-lg-3">
						<Form.Label className="text-end">
							<strong>
								TX
								<br />
								Description
							</strong>
						</Form.Label>
						<Form.Control
							size="sm"
							type="text"
							maxLength={30}
							placeholder=""
							name="txDesc"
							onChange={this.handleChange}
							defaultValue={
								this.updatedData?.txDesc ?? this.state.activeInvoice.txdesc
							}
						/>
					</ListFilter.Field>

					<ListFilter.Field className="col-lg-3">
						<Form.Label className="text-end">
							<strong>Mgr/SP</strong>
						</Form.Label>
						<AsyncEmployeesDropdown
							defaultValue={this.state.defaultManagerValue}
							className="react-select react-select-sm w-100"
							placeholder="Select employee"
							name="mgrCode"
							onChange={this.handleMgrChange}
							isClearable={true}
						/>
					</ListFilter.Field>
				</ListFilter.Fields>

				<ListFilter.Actions lg={12} className="justify-content-lg-end pt-3">
					<ListFilter.Action>
						{(() => {
							if (this.props.params.invoiceId) {
								return (
									<Button
										as={Link}
										to="#"
										variant="ivory"
										size="sm"
										className={`btn-icon btn-action ${
											this.state.showTableSearch
												? 'bg-primary-ash text-white'
												: ''
										}`}
										onClick={this.handleShowTableSearch}
									>
										<i className="ri-search-line"></i> Search
									</Button>
								);
							}
						})()}
						<Button
							variant="ivory"
							size="sm"
							className="btn-action ms-3"
							onClick={this.handleRemoveItem}
						>
							Remove Item
						</Button>
						{this.state.defaultProjectValue ? (
							<div>
								<Button
									onClick={this.displayModalItems.bind(this)}
									variant="primary"
									size="sm"
									className={`ms-3 ${
										this.state.defaultProjectValue ? '' : 'disabled'
									}`}
								>
									Add Item
								</Button>
							</div>
						) : (
							<OverlayTrigger
								id="otr"
								overlay={
									<Tooltip id="selprop">
										You must select a project first
									</Tooltip>
								}
							>
								<div>
									<Button
										onClick={this.displayModalItems.bind(this)}
										variant="primary"
										size="sm"
										className={`ms-3 ${
											this.state.defaultProjectValue ? '' : 'disabled'
										}`}
									>
										Add Item
									</Button>
								</div>
							</OverlayTrigger>
						)}
					</ListFilter.Action>
				</ListFilter.Actions>
			</ListFilter>
		);
	}

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center mb-5'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={
							this.state.page * this.state.pageSize.value >=
							this.updatedData.selectedItems.length
						}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key={`${Math.floor(Math.random() * 1000)}-min`}
					options={this.availablePageSize}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	renderContent() {
		const { checks, totalChecked, dataIsLoaded, isImagesLoaded } = this.state;
		const { statusCodes } = this.props;
		this.setIsLoaded(dataIsLoaded);
		return this.renderView(
			<>
				{!dataIsLoaded ||
				(this.updatedData?.selectedItems &&
					this.updatedData.selectedItems.length > 0) ? (
					<Col>
						<div className="table-gradient mb-4">
							<Table striped responsive className="a-table mt-4">
								<thead>
									<tr className="a-table-heading default" key="0">
										<th align="middle" className="mw-80px">
											<div className="d-flex justify-content-center">
												<Form.Check
													inline
													label=""
													name={`inline-check-th-0`}
													type="checkbox"
													data-id={`th-0`}
													id={`inline-check-th-0`}
													checked={totalChecked > 0}
													className={getCheckAllClass(
														totalChecked,
														this.updatedData.selectedItems
													)}
													onChange={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th className="mw-100px">
											<span className={this.sortClass('loc')} data-field="loc">
												Location
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('item')}
												data-field="item"
											>
												Ref. No.
											</span>
										</th>
										<th className="mw-180px">
											<span
												className={this.sortClass('latestprpno')}
												data-field="latestprpno"
											>
												Last Prop#
											</span>
										</th>
										<th>Image</th>
										<th>
											<span
												className={this.sortClass('shortdesc')}
												data-field="shortdesc"
											>
												Description
											</span>
										</th>
										<th className="mw-140px">
											<span
												className={this.sortClass('statusName')}
												data-field="statusName"
											>
												Status
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('_invqty')}
												data-field="_invqty"
											>
												Quantity
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('_itemprice')}
												data-field="_itemprice"
											>
												Price
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('tdinvqty')}
												data-field="tdinvqty"
											>
												Qty. to Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('_invtodate')}
												data-field="_invtodate"
											>
												Invoiced to Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('invqty')}
												data-field="invqty"
											>
												Inv. Qty.
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('invprice')}
												data-field="invprice"
											>
												Invoice Price
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('esttcost')}
												data-field="esttcost"
											>
												Est (PO) Cost
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('acttcost')}
												data-field="acttcost"
											>
												Actual (Vendor Inv) Cost
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('esttprice')}
												data-field="esttprice"
											>
												Est (Proposal) Price
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{(() => {
										if (this.state.dataIsLoaded) {
											return this.state.updatedSelectedItems.map((item, i) => (
												<tr
													key={i}
													className={`tr-loading ${
														checks.includes(item.id) ? `active` : ''
													}`}
												>
													<td>
														<div className="d-flex justify-content-center">
															<Form.Check
																inline
																label=""
																name={`group-` + i}
																type="checkbox"
																data-id={i}
																id={`chk-projectview-proposal-` + i}
																onChange={this.handleChecks.bind(this, item.id)}
																className="chk-projectview-proposal-item"
																checked={checks.includes(item.id)}
															/>
														</div>
													</td>
													<td>{item.loc}</td>
													<td>{item.item}</td>
													<td>{item.latestprpno}</td>
													<td className={`${!isImagesLoaded ? 'td-1' : ''}`}>
														{this.props.renderImage(isImagesLoaded, item)}
													</td>
													<td>{item.shortdesc}</td>
													<td>
														<strong
															className={`dot`}
															style={{
																'--color': item.statusColor,
																fontStyle:
																	item.statusName === 'Undefined'
																		? 'italic'
																		: 'normal',
															}}
														>
															{item.statusName}
														</strong>
													</td>
													<td>{getItemQuantity(item)}</td>
													<td>{currencyFormat(getItemPrice(item))}</td>
													<td>{item.tdinvqty}</td>
													<td>{currencyFormat(getItemInvoiceToDate(item))}</td>
													<td>{item.invqty}</td>
													<td>{currencyFormat(item.invBal)}</td>
													<td>{currencyFormat(item.esttcost)}</td>
													<td>{currencyFormat(item.acttcost)}</td>
													<td>{currencyFormat(item.esttprice)}</td>
												</tr>
											));
										} else {
											return showLoading();
										}
									})()}
								</tbody>
							</Table>
						</div>
						{this.renderPagination()}
						<div className="table-gradient">
							<Table responsive borderless className="">
								<thead>
									<tr key="0">
										<th className="fs-md">Total Inv. Price</th>
										<th className="fs-md"></th>
										<th className="fs-md">Sales tax</th>
										<th className="fs-md"></th>
										<th className="fs-md">Deposit</th>
										<th className="fs-md"></th>
										<th className="fs-md">Retainer</th>
										<th className="fs-md"></th>
										<th className="fs-md">Balance Due</th>
										<th className="fs-md">Avail. Retainer</th>
									</tr>
								</thead>
								<tbody>
									<tr key={0}>
										<td className="align-middle">
											<div className="form-group-extra dollar">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
													style={{ minWidth: '60px' }}
													value={amountFormat(this.state.totalInvPrice)}
													readOnly
													disabled={true}
												/>
											</div>
										</td>
										<td className="align-middle text-secondary-ash px-1">
											<i className="ri-add-line ri-lg"></i>
										</td>
										<td className="align-middle">
											<div className="form-group-extra dollar">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
													style={{ minWidth: '60px' }}
													value={round(this.state.totalSalesTaxVal, 2)}
													readOnly
													disabled={true}
												/>
											</div>
										</td>
										<td className="align-middle text-secondary-ash px-1">
											<i className="ri-subtract-fill ri-lg"></i>
										</td>
										<td>
											<div className="form-group-extra dollar">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
													style={{ minWidth: '60px' }}
													value={amountFormat(this.state.totalDeposit)}
													readOnly
													disabled={true}
												/>
											</div>
										</td>
										<td className="align-middle text-secondary-ash px-1">
											<i className="ri-subtract-fill ri-lg"></i>
										</td>
										<td>
											<div className="form-group-extra dollar">
												<Form.Control
													type="number"
													placeholder="0.00"
													size="sm"
													style={{ minWidth: '60px' }}
													onChange={this.handleRetainerChange.bind(this)}
													defaultValue={amountFormat(
														this.updatedData?.retAmount ??
															this.state.activeInvoice.enteredretainer
													)}
												/>
											</div>
										</td>
										<td className="align-middle text-secondary-ash px-1">
											<span className="fsx-24">=</span>
										</td>
										<td className="align-middle">
											<div className="form-group-extra dollar">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
													style={{ minWidth: '60px' }}
													value={amountFormat(this.state.totalBalanceDue)}
													readOnly
													disabled={true}
												/>
											</div>
										</td>
										<td>
											<div className="form-group-extra dollar">
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
													style={{ minWidth: '60px' }}
													value={amountFormat(this.state.totalAvailRetainer)}
													readOnly
													disabled={true}
												/>
											</div>
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
					</Col>
				) : (
					<div className="row justify-content-center text-center py-5">
						<div className="col-md-3">
							<img src={noItems} className="mw-100 mb-4" alt="" />

							<h6>Add Items</h6>
							<p>
								Use the "Add Item" button to begin to associate items with this
								client invoice.
							</p>

							{this.state.defaultProjectValue ? (
								<div>
									<Button
										as={Link}
										onClick={this.displayModalItems.bind(this)}
										variant="primary"
										size="md"
										className="btn-icon mt-4"
									>
										<i className="ri-add-line ri-lg"></i> Add Items
									</Button>
								</div>
							) : (
								<OverlayTrigger
									id="otr"
									overlay={
										<Tooltip id="selprop">
											You must select a project first
										</Tooltip>
									}
								>
									<div>
										<Button
											as={Link}
											onClick={this.displayModalItems.bind(this)}
											variant="primary"
											size="md"
											className="btn-icon mt-4 disabled"
										>
											<i className="ri-add-line ri-lg"></i> Add Items
										</Button>
									</div>
								</OverlayTrigger>
							)}
						</div>
					</div>
				)}
			</>
		);
	}

	render() {
		const { isImagesLoaded, dataIsLoaded } = this.state;
		return (
			<>
				{dataIsLoaded && (
					<Row>
						<Col className="mb-4">{this.renderFilters()}</Col>
					</Row>
				)}

				<Row>{this.renderContent()}</Row>
				<ClientInvoiceAddInvoiceAddItemModal
					statusCodes={this.props.statusCodes}
					data={this.state.unselectedItems}
					addItemCall={this.handleAddItem.bind(this)}
					show={this.state.showItemModal}
					hideModal={() => this.hideModalItems()}
					dataIsLoaded={this.state.dataIsLoaded}
					isImagesLoaded={isImagesLoaded}
					sortData={this.sortData.bind(this)}
					renderImage={this.props.renderImage}
				/>
			</>
		);
	}
}

export default WithRouter(ClientInvoiceAddInvoice);
