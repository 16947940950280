import React from 'react';
import { Col, Row, Form, Container, Breadcrumb, Button } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import {
	getRouteWithParam,
	routeParam,
	WithRouter,
} from '../../../helpers/Router';
import { FooterFormAction } from '../../components/Form';
import URI from '../../../defaults/RoutesDefault';
import { Link, NavLink } from 'react-router-dom';
import { HeaderLight } from '../../components/Header';
import WysiwygEditor from '../../ui-kit/WysiwygEditor';
import { ApiService } from '../../../lib/api/HttpService';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../utilities/LocalStorage';
import {
	getBreadcrumbTitle,
	getSaveButtonName,
} from '../../../utilities/String';
import { handleResponseAlert } from '../../../utilities/Response';
import { FormEvent } from '../../../utilities/FormEvent';
import { RTFJS } from 'rtf.js';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';

class GlossaryAddRemarks extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			dataIsLoaded: false,
			isLoading: false,
			showButtons: false,
			isDraft: false,
			remarkshtml: '',
		};
		this.api = new ApiService();
		this.formEvent = new FormEvent('glossaryRemarks', true);
		this.htmlToRtfCoverter = new HtmlToRtfBrowser();
		this.isEditing = Boolean(this.props.params.code);
	}

	async componentDidMount() {
		this.setTitle('Add Remarks | Glossary');
		this.setFormAction(true);

		let data = {};
		if (this.props.params.code) {
			this.dMloader(true);
			const data = await this.api.get(
				'remarks',
				`?$filter=code eq '${this.props.params.code}'`
			);

			if (data.length) {
				this.setTitle('Edit Remarks | Glossary');
				this.mapRtfToDraft(data[0].remarksrtf);

				this.setState({
					dataIsLoaded: true,
					data: data[0],
				});
			}
		}

		setLocalStorage('glossaryRemarks', data, true);
		setTimeout(() => {
			this.setState({
				showButtons: true,
			});

			this.dMloader(false);
		}, 250);
	}

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	setDraft() {
		setLocalStorage('isDraft', 'true');
		this.setState({
			isDraft: true,
		});
	}

	handleTextChange = (e) => {
		this.setDraft();
		this.formEvent.handleTextChange(e);
	};

	handleWysiwygChange = (e) => {
		const plain = JSON.parse(JSON.stringify(e));

		const rtf = JSON.parse(JSON.stringify(e));
		rtf.target.value = this.htmlToRtfCoverter.convertHtmlToRtf(e.target.html);

		this.setDraft();

		this.formEvent.handleWysiwygChange('remarks1', plain);
		this.formEvent.handleWysiwygChange('remarksrtf', rtf);
	};

	handleClickSave = (e) => {
		this.setIsLoading(true);
		setLocalStorage('isDraft', 'false');
		window.onbeforeunload = null;

		this.state.data.code ? this.handleUpdate(e) : this.handleCreate(e);
	};

	handleUpdate(e) {
		if (this.state.data && this.state.data.code) {
			const data = getLocalStorage('glossaryRemarks', true) || `{}`;

			const request = this.api.patch('remarks', this.state.data.code, data);
			handleResponseAlert(
				request,
				(res) => {
					this.setIsLoading(false);

					window.location.href = getRouteWithParam(URI.glossary.list, {
						path: 'remarks',
					});
				},
				null,
				'code'
			);
		}
	}

	handleCreate(e) {
		const data = getLocalStorage('glossaryRemarks', true) || `{}`;

		const request = this.api.postJson('remarks', data);
		handleResponseAlert(
			request,
			(res) => {
				this.setIsLoading(false);

				if (!res?.stack) {
					window.location.href =
						e.target.name === 'saveNew'
							? URI.glossary.add.remarks
							: getRouteWithParam(URI.glossary.list, {
									path: 'remarks',
							  });
				}
			},
			null,
			'code'
		);
	}

	renderHeader() {
		const { data, isLoading, isDraft } = this.state;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={routeParam(URI.glossary.list, { path: 'remarks ' })}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							linkProps={{ to: URI.glossary.base }}
							linkAs={Link}
						>
							Settings
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.glossary.base }}
							linkAs={Link}
						>
							Glossary
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.glossary.list, {
									path: 'remarks',
								}),
							}}
							linkAs={Link}
						>
							Remarks
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.glossary.add.locations }}
							linkAs={Link}
						>
							{getBreadcrumbTitle(data, 'code', 'Remark')}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>
						{getBreadcrumbTitle(data, 'code', 'Remark')}
					</HeaderLight.Title>

					<div className={this.state.showButtons ? 'd-block' : 'd-none'}>
						{/* Submit Button */}
						<SecureBootstrapButton
							attributeNo={43}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							className={this.props.params.code ? 'd-none' : 'd-inline-block'}
							variant="primary me-2"
							onClick={this.handleClickSave}
							name="saveNew"
							disabled={!isDraft || isLoading}
						>
							Save & New
						</SecureBootstrapButton>

						<SecureBootstrapButton
							attributeNo={43}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							onClick={this.handleClickSave}
							name="save"
							disabled={!isDraft || isLoading}
						>
							Save
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		const { data } = this.state;

		return (
			<Container className="ms-0">
				<Row xs={1} lg={2} className="py-4">
					<Col className="mb-3 mb-lg-0">
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Remark Code</Form.Label>
							</Col>
							<Col>
								<Row xs={1} lg={2}>
									<Col>
										<Form.Control
											type="text"
											maxLength={5}
											name="code"
											placeholder="Please enter"
											defaultValue={data?.code}
											readOnly={this.isEditing}
											disabled={this.isEditing}
											onChange={(e) => {
												e.target.value = e.target.value
													.substring(0, 5)
													.toUpperCase()
													.replace(/\s/gi, '');
												this.handleTextChange(e);
											}}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="align-items-start">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0 mt-2">Description</Form.Label>
							</Col>
							<Col>
								<WysiwygEditor onChange={this.handleWysiwygChange}>
									{this.state.remarkshtml}
								</WysiwygEditor>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	stringToArrayBuffer(string) {
		const buffer = new ArrayBuffer(string.length);
		const bufferView = new Uint8Array(buffer);
		for (let i = 0; i < string.length; i++) {
			bufferView[i] = string.charCodeAt(i);
		}
		return buffer;
	}

	mapRtfToDraft(rtf) {
		const self = this;

		if (rtf) {
			const doc = new RTFJS.Document(this.stringToArrayBuffer(rtf), {});

			doc
				.render()
				.then(function (htmlElements) {
					const html = htmlElements
						.map((element) => {
							return element.outerHTML;
						})
						.join(' ');
					self.setState({
						remarkshtml: `${html}`,
					});
				})
				.catch((error) => console.error(error));
		}
	}

	render() {
		const { isLoading, isDraft } = this.state;

		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
				</div>

				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={43}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
						variant="primary"
						size="lg"
						name="save"
						onClick={this.handleClickSave.bind(this)}
						disabled={!isDraft || isLoading}
					>
						{getSaveButtonName(isLoading, 'Save')}
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(GlossaryAddRemarks);
