export enum ECacheKeys {
	JournalEntries = 'JournalEntries',
	CreditCard = 'CreditCard',
	FinanceCharges = 'FinanceCharges',
	Clients = 'Clients',
	StatusCodes = 'StatusCodes',
	Proposals = 'Proposals',
	Invoices = 'Invoices',
	ItemTotals = 'ItemTotals',
	PurchaseOrders = 'PurchaseOrders',
	VendorInvoices = 'VendorInvoices',
	PlaidToken = 'PlaidToken',
	PayTypes = 'PayTypes',
	Transactions = 'Transactions',
	PlaidAccounts = 'PlaidAccounts',
	Reconciliations = 'Reconciliations',
	TemporaryReconciliations = 'TemporaryReconciliations',
	CompanyTwo = 'CompanyTwo',
	Company = 'Company',
	Warehouses = 'Warehouses',
}
