import React from 'react';
import TimezoneSelect from 'legacy/pages/settings/company/TimezoneSelect';
import { Modal, Row, Container, Col, Button } from 'react-bootstrap';
import './TimeZoneModal.css';

type TimeZoneModalProps = {
	show: boolean;
	value: number;
	handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
	handleSubmit: () => void;
};
const TimeZoneModal = ({
	show,
	value,
	handleChange,
	handleSubmit,
}: TimeZoneModalProps) => (
	<Modal
		size="lg"
		backdrop="static"
		centered
		show={show}
		dialogClassName="modal-dialog-timezone-width-override"
	>
		<Modal.Header className="modal-header border-0">
			<Modal.Title className="mx-auto">
				<p className="text-center">Hello...</p>
				<p className="text-center m-0">
					Please select your company&#39;s timezone
				</p>
			</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Row>
				<Container className="p-0 d-flex justify-content-center select-container">
					<TimezoneSelect
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						/*
                        // @ts-ignore */
						value={value !== null ? value : ''}
						onChange={handleChange}
					/>
				</Container>
			</Row>
		</Modal.Body>
		<Modal.Footer className="bg-ivory py-4 px-5">
			<Row className="w-100">
				<Col className="text-center">
					<Button onClick={handleSubmit} variant="primary">
						Confirm
					</Button>
				</Col>
			</Row>
		</Modal.Footer>
	</Modal>
);

TimeZoneModal.displayName = 'TimeZoneModal';

export default TimeZoneModal;
