import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Switch from 'legacy/templates/components/Switch';
import { useGetTemporaryReconciliationsChecks } from 'api/hooks/useGetTemporaryReconciliationsChecks';
import type { ITemporaryReconciliationCheck } from 'api/resources/temporaryReconciliations';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import { ColumnDef, Row as TTableRow } from '@tanstack/react-table';
import Table from 'legacy/app/components/newTable/Table';
import { formatDate } from 'legacy/utilities/formatDate';
import { formatCurrency } from 'legacy/utilities/formatCurrency';
import { CheckbookReconcileCheckboxCell } from './CheckbookReconcileCheckboxCell';
import { useSession } from 'legacy/app/context/session';
import { usePatchTemporaryReconciliations } from 'api/hooks/usePatchTemporaryReconciliations';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { displayAlert } from 'legacy/utilities/Response';

const CheckbookReconcileChecksTable = () => {
	const { started, setUpdateTotals } = useCheckbookReconcile();
	const { usercode } = useSession();

	const { data: checks = [], isLoading } = useGetTemporaryReconciliationsChecks(
		`?$filter=usercode eq ${usercode}`,
		{
			enabled: started,
		}
	);

	const {
		mutate: patchReconciliation,
		data: patchReponse,
		isSuccess: isPatchSuccess,
		isError: isPatchError,
	} = usePatchTemporaryReconciliations();

	const [supressCleared, setSuppresCleared] = useState(false);

	const { clearedChecks, setClearedChecks, sumChecks, setSumChecks } =
		useCheckbookReconcile();

	useEffect(() => {
		if (isPatchSuccess) {
			setUpdateTotals(true);
			const { recnum, cleared, sumtag } = patchReponse;
			const check = checks.find((x) => x.recnum === recnum);
			if (check) {
				check.cleared = cleared;
				check.sumtag = sumtag;
			}
		}
		if (isPatchError) {
			displayAlert(EAlertTypes.Danger, 'Error: could not submit changes');
		}
	}, [isPatchSuccess, isPatchError, setUpdateTotals, checks, patchReponse]);

	const handleOnClearedChange = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			row?: TTableRow<ITemporaryReconciliationCheck>
		) => {
			if (!row || !row.id) return;

			const { checked } = e.target;

			setClearedChecks({
				...clearedChecks,
				[row.id]: checked,
			});

			const payload = {
				usercode: Number(usercode),
				recnum: Number(row.id),
				cleared: checked,
			};

			patchReconciliation(payload);
		},
		[setClearedChecks, patchReconciliation, usercode, clearedChecks]
	);

	const handleOnSumChange = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			row?: TTableRow<ITemporaryReconciliationCheck>
		) => {
			if (!row || !row.id) return;
			const { checked } = e.target;

			setSumChecks({
				...sumChecks,
				[row.id]: checked,
			});

			const payload = {
				usercode: Number(usercode),
				recnum: Number(row.id),
				sumtag: checked,
			};

			patchReconciliation(payload);
		},
		[setSumChecks, patchReconciliation, usercode, sumChecks]
	);

	const handleOnSwitchChange = () => {
		setSuppresCleared((prevState) => !prevState);
	};

	const columns = useMemo<ColumnDef<ITemporaryReconciliationCheck>[]>(
		() => [
			{
				accessorKey: 'cleared',
				header: 'Clr',
				size: 25,
				cell: ({ row }) =>
					CheckbookReconcileCheckboxCell({
						row,
						handleOnChange: handleOnClearedChange,
						checked: row.getValue('cleared') || clearedChecks[row.id],
					}),
			},
			{
				accessorKey: 'sumtag',
				header: 'Sum',
				size: 25,
				enableSorting: false,
				cell: ({ row }) =>
					CheckbookReconcileCheckboxCell({
						row,
						handleOnChange: handleOnSumChange,
						checked: row.getValue('sumtag') || sumChecks[row.id],
					}),
			},
			{
				accessorKey: 'checknum',
				header: 'Check #',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},

			{
				accessorFn: (col) => formatDate(col.userdate ?? ''),
				header: 'Date',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatCurrency(col.amount ?? 0),
				header: 'Amount',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'supplier',
				header: 'Payee',
				enableSorting: false,
				size: 50,
				cell: (info) => info.getValue(),
			},
		],
		[clearedChecks, sumChecks, handleOnClearedChange, handleOnSumChange]
	);

	return (
		<Col xs={12} lg={6} className="position-relative table-action-bar">
			<div className="bg-secondary-grey tw-py-6 tw-mb-6 roundedx-4">
				<Row className="tw-items-center px-3">
					<Col className="px-3">
						<h6 className="fw-bold mb-0">Checks</h6>
					</Col>
					<Col>
						<div className="tw-flex tw-items-center tw-justify-end">
							<Form.Label className="mb-0 mx-2">
								<strong>Suppress Cleared</strong>
							</Form.Label>
							<span>
								<Switch
									checked={supressCleared}
									onChange={handleOnSwitchChange}
								></Switch>
							</span>
						</div>
					</Col>
				</Row>
			</div>

			<div className="tw-h-96 tw-overflow-auto">
				<Table
					columns={columns}
					isLoading={isLoading}
					data={
						(supressCleared
							? checks?.filter((check) => !check.cleared)
							: checks) as ITemporaryReconciliationCheck[]
					}
					getRowId={(check) => check.recnum.toString()}
				/>
			</div>
		</Col>
	);
};

CheckbookReconcileChecksTable.displayName = 'CheckbookReconcileChecksTable';

export { CheckbookReconcileChecksTable };
