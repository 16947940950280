import AddressesDropdown from 'legacy/app/components/dropdowns/ShipToDropdown';
import VendorDropdown from 'legacy/app/components/dropdowns/VendorDropdown';
import Label from 'legacy/app/components/label/Label';
import { Controller, useFormContext } from 'react-hook-form';
import { ItemFormValues } from '../types/FormValues';
import { useEffect } from 'react';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { useParams } from 'react-router-dom';
import { Item } from 'legacy/lib/api/types/Item';
import TextEditor from 'legacy/app/components/textEditor/TextEditor';

const WorkRoomTab = ({
	disableInputs,
	itemToEdit,
}: {
	disableInputs: boolean;
	itemToEdit: Item;
}) => {
	const { id: projectId } = useParams();

	const { data: project, isLoading } = useGetProject(projectId as string);

	const methods = useFormContext<ItemFormValues>();
	const { watch, setValue } = methods;

	useEffect(() => {
		return () => {
			setValue('initialWorkroomDescription', watch('workroomDescription').html);
		};
	}, [setValue, watch]);

	useEffect(() => {
		if (!itemToEdit.wrshipto && project) {
			setValue('wrshipto', project.shipto);
		}
	}, [project, setValue, itemToEdit.wrshipto]);

	return (
		<div className="tw-min-h-[calc(100vh-360px)]">
			<div className="tw-mt-8 tw-pl-20 tw-items-center tw-gap-4 tw-h-96 tw-gap-y-0 tw-grid tw-grid-cols-[100px_600px] tw-max-w-[1200px]">
				<Label label="Workroom Vendor" />
				<Controller
					control={methods.control}
					name="workroom"
					render={({ field }) => (
						<div className="tw-min-w-96">
							<VendorDropdown
								inputId="Vendor"
								blurInputOnSelect
								isClearable
								isDisabled={disableInputs}
								value={{
									label: '',
									value: field.value || '',
								}}
								onChange={(value) => {
									field.onChange(
										value?.value === '' || value?.value === undefined
											? null
											: value?.value
									);
								}}
							/>
						</div>
					)}
				/>

				<Label label="Ship Finished Product To" />
				<Controller
					control={methods.control}
					name="wrshipto"
					render={({ field }) => (
						<div className="tw-min-w-96">
							<AddressesDropdown
								inputId="Ship Finished Product To"
								blurInputOnSelect
								defaultValue={
									itemToEdit.wrshipto
										? {
												label: itemToEdit.wrshipto,
												value: itemToEdit.wrshipto,
										  }
										: undefined
								}
								isDisabled={disableInputs || isLoading}
								value={{
									label: '',
									value: field.value || '',
								}}
								onChange={(value) => field.onChange(value?.value)}
							/>
						</div>
					)}
				/>
				<Label label="Workroom Instructions" />
				<Controller
					control={methods.control}
					name="workroomDescription"
					render={({ field }) => (
						<TextEditor
							initialData={methods.watch('initialWorkroomDescription')}
							onChange={(e) => field.onChange(e.target)}
						/>
					)}
				/>
			</div>
		</div>
	);
};

WorkRoomTab.displayName = 'WorkRoomTab';

export default WorkRoomTab;
