import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';

export default function CurrencyInput({
	control,
	name,
	rules,
	defaultValue,
	...props
}) {
	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, value, onBlur } }) => (
				<NumericFormat
					{...(defaultValue !== undefined ? { defaultValue } : {})}
					customInput={Form.Control}
					thousandSeparator={true}
					prefix={'$'}
					onValueChange={(e) => {
						// Set to empty string if the input is cleared, otherwise set to numeric value.
						if (e.value === '') {
							onChange('');
						} else {
							onChange(Number(e.value));
						}
					}}
					value={value ?? ''}
					decimalScale={2}
					onBlur={onBlur}
					fixedDecimalScale={true}
					{...props}
				/>
			)}
		/>
	);
}
