import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
	getPurchaseOrders,
	type IPurchaseOrder,
} from 'api/resources/purchaseOrders';

import { ECacheKeys } from 'cache/CacheKeys';

export const useGetPurchaseOrders = (
	filter?: string,
	options?: UseQueryOptions<IPurchaseOrder[]>
) => {
	const query = useQuery<IPurchaseOrder[]>({
		queryKey: [ECacheKeys.PurchaseOrders, filter],
		queryFn: () => getPurchaseOrders(filter),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
