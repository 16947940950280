import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import { useHasAccess } from '../../../context/security';

export default function SecureDropdownItem({
	attributeNo,
	attributeType,
	children,
	disabled = false,
	...props
}) {
	const hasAccess = useHasAccess(attributeNo, attributeType);

	const isDisabled = !hasAccess ? true : disabled;

	return (
		<Dropdown.Item {...props} disabled={isDisabled}>
			{children}
		</Dropdown.Item>
	);
}
