import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import bold from '../../../assets/images/bold.svg';
import italic from '../../../assets/images/italic.svg';
import underline from '../../../assets/images/underline.svg';
import colour from '../../../assets/images/colour.svg';
import './TextEditorStyles.css';
import { useEffect, useRef, useState } from 'react';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

const TextEditor = ({
	initialData,
	onChange,
}: {
	initialData?: string;
	onChange?: (e: {
		target: {
			html: string;
			value: string;
		};
	}) => void;
}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const onChangeRef = useRef(onChange);

	useEffect(() => {
		if (initialData) {
			const formattedInitialData = htmlToDraft(initialData);
			const newState = EditorState.createWithContent(
				ContentState.createFromBlockArray(
					formattedInitialData.contentBlocks,
					formattedInitialData.entityMap
				)
			);

			setEditorState(newState);

			const html = draftToHtml(convertToRaw(newState.getCurrentContent()));

			onChangeRef.current?.({
				target: {
					value: newState.getCurrentContent().getPlainText(),
					html,
				},
			});
		}
	}, [initialData]);

	const onEditorStateChange = (editorState: EditorState) => {
		setEditorState(editorState);
		const plainContent = editorState.getCurrentContent().getPlainText();

		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

		onChange?.({
			target: {
				value: plainContent,
				html: plainContent === '' ? '' : html,
			},
		});
	};

	return (
		<Editor
			editorState={editorState}
			onEditorStateChange={onEditorStateChange}
			spellCheck
			handlePastedText={() => false}
			placeholder="Start typing..."
			toolbar={{
				options: ['inline', 'colorPicker'],
				inline: {
					inDropdown: false,
					className: undefined,
					options: ['bold', 'italic', 'underline'],
					bold: { icon: bold, className: undefined },
					italic: { icon: italic, className: undefined },
					underline: { icon: underline, className: undefined },
				},
				colorPicker: {
					icon: colour,
					className: undefined,
					component: undefined,
					popupClassName: undefined,
					colors: [
						'rgb(97,189,109)',
						'rgb(26,188,156)',
						'rgb(84,172,210)',
						'rgb(44,130,201)',
						'rgb(147,101,184)',
						'rgb(71,85,119)',
						'rgb(204,204,204)',
						'rgb(65,168,95)',
						'rgb(0,168,133)',
						'rgb(61,142,185)',
						'rgb(41,105,176)',
						'rgb(85,57,130)',
						'rgb(40,50,78)',
						'rgb(0,0,0)',
						'rgb(247,218,100)',
						'rgb(251,160,38)',
						'rgb(235,107,86)',
						'rgb(226,80,65)',
						'rgb(163,143,132)',
						'rgb(239,239,239)',
						'rgb(255,255,255)',
						'rgb(250,197,28)',
						'rgb(243,121,52)',
						'rgb(209,72,65)',
						'rgb(184,49,47)',
						'rgb(124,112,107)',
						'rgb(209,213,216)',
					],
				},
			}}
		/>
	);
};

TextEditor.displayName = 'TextEditor';

export default TextEditor;
