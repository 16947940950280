import { Col, Row, Form, Container, Table, Button } from 'react-bootstrap';
import Select from 'react-select';
import { WithRouter } from '../../../../helpers/Router';
import { Is, reload } from '../../../../helpers/Util';
import { FormEvent } from '../../../../utilities/FormEvent';
import { getLocalStorage } from '../../../../utilities/LocalStorage';
import ThreadComponent from '../../ThreadComponent';
import { FooterFormAction } from '../../../components/Form';
import { ApiService } from '../../../../lib/api/HttpService';
import { handleResponseAlert } from '../../../../utilities/Response';
import AsyncSalesCategoriesDropdown from '../../../../app/components/dropdowns/AsyncSalesCategoriesDropdown';
import AsyncLocationsDropdown from '../../../../app/components/dropdowns/AsyncLocationsDropdown';

class WorkflowSettingsTime extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				company1: {},
				company2: {},
			},
		};

		this.formEventCompany1 = new FormEvent(`workflowCompanySettings`);
		this.formEventCompany2 = new FormEvent(`workflowCompany2Settings`);

		this.api = new ApiService();
	}

	componentDidMount() {
		if (Object.keys(this.props.data).length > 0) {
			let data = this.props.data || {};
			this.setState({
				data,
			});

			this.checkboxOnCheck();
		}
		this.dMloader(false, true);
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data || {};
			this.setState({
				data,
			});

			this.checkboxOnCheck();
		}
		this.dMloader(false, true);
	}

	checkboxOnCheck() {
		let company1 = this.state.data.company1;
		let company2 = this.state.data.company2;

		// Get localstorage if state is empty
		if (Is.empty(company1))
			company1 = getLocalStorage('workflowCompanySettings', true);

		// Get localstorage if state is empty
		if (Is.empty(company2))
			company2 = getLocalStorage('workflowCompany2Settings', true);

		if (company1.timeaddtoscat) {
			const chk = document.getElementById('chk-allowed-joined-sales-category');
			if (chk) chk.checked = true;
		}

		if (company2.deftbtime) {
			const chk = document.getElementById('chk-default-enter-start-end-time');
			if (chk) chk.checked = true;
		}

		if (company2.timeupdateitemqty) {
			const chk = document.getElementById(
				'chk-update-item-qty-total-comp-hours'
			);
			if (chk) chk.checked = true;
		}

		if (company2.timeonlydated) {
			const chk = document.getElementById(
				'chk-only-latest-uninvoiced-dated-item'
			);
			if (chk) chk.checked = true;
		}

		if (company2.timeautocreate) {
			const chk = document.getElementById('chk-auto-create-monthly-item');
			if (chk) chk.checked = true;
		}
	}

	handleClickSave = async (e) => {
		e.preventDefault();

		const company1 = getLocalStorage('workflowCompanySettings', true);
		const company2 = getLocalStorage('workflowCompany2Settings', true);

		const request1 = this.api.patchCompany(company1);
		const request2 = this.api.patchCompany2(company2);

		handleResponseAlert(request1, () => {
			handleResponseAlert(request2);
		});

		reload();
	};

	render() {
		return (
			<>
				<div className="content-padding min-height">
					<Container>
						<Row className="mb-5 px-2" xs={1}>
							<Col lg={8} className="px-4">
								<Row className="mb-3 align-items-center">
									<Col
										xs={12}
										sm={4}
										className="mb-2 mb-sm-0 pe-0 text-lg-start"
									>
										Default Location for Time Entries
									</Col>
									<Col>
										<AsyncLocationsDropdown
											urlQuery={"?$filter=(proj eq '$$$$$')"}
											name={'usediscount-1'}
											defaultValue={this.state.data.selectedLocation}
											onChange={(e) => {
												this.formEventCompany1.handleSelectChange(
													'timeloccode',
													e
												);
											}}
											placeholder="Please select"
											className="react-select select-default-time-location"
										/>
									</Col>
								</Row>
								<Row className="mb-3 align-items-center">
									<Col
										xs={12}
										sm={4}
										className="mb-2 mb-sm-0 pe-0 text-lg-start"
									>
										Default Sales Category for Time Entries
									</Col>
									<Col>
										<AsyncSalesCategoriesDropdown
											name={'usediscount-1'}
											defaultValue={this.state.data?.selectedSalesCategory}
											onChange={(e, select) => {
												this.setState({
													data: {
														...this.state.data,
														selectedSalesCategory: e,
													},
												});
												this.formEventCompany1.handleSelectChange(
													'timescatcode',
													e
												);
											}}
											placeholder="Please select"
											className="react-select"
											valueKey="key"
										/>
									</Col>
								</Row>
								<Row className="mb-3 align-items-center">
									<Col className="mb-2 mb-sm-0 pe-0">
										<Form.Check
											inline
											label="Only Allowed Entries to be Joined to Items with Default Sales Category"
											name="timeaddtoscat"
											type="checkbox"
											id={`chk-allowed-joined-sales-category`}
											onClick={this.formEventCompany1.handleCheckChange.bind(
												this
											)}
										/>
									</Col>
								</Row>
								<Row className="mb-3 align-items-center">
									<Col xs={12} sm={6} className="mb-3 mb-sm-0 pe-0">
										<Form.Check
											inline
											label="Default Enter Start and End Time"
											name="deftbtime"
											type="checkbox"
											id={`chk-default-enter-start-end-time`}
											onClick={this.formEventCompany2.handleCheckChange.bind(
												this
											)}
										/>
									</Col>
									<Col xs={12} sm={6} className="mb-2 mb-sm-0 pe-0">
										<Form.Check
											inline
											label="Update Item Qty with Total Comp. Hours"
											name="timeupdateitemqty"
											type="checkbox"
											id={`chk-update-item-qty-total-comp-hours`}
											onClick={this.formEventCompany2.handleCheckChange.bind(
												this
											)}
										/>
									</Col>
								</Row>
								<div className="bg-white mb-3 roundedx-4 d-none">
									<Row xs={1}>
										<Col className="mt-3">
											<h6 className="fw-bold mb-0">Join to Time Item:</h6>
										</Col>
									</Row>
								</div>
								<Row className="mb-3 align-items-center d-none">
									<Col xs={12} sm={6} className="mb-3 mb-sm-0 pe-0">
										<Form.Check
											inline
											label="Only to latest Un-invoiced Dated Item"
											name="timeonlydated"
											type="checkbox"
											id={`chk-only-latest-uninvoiced-dated-item`}
											onClick={this.formEventCompany2.handleCheckChange.bind(
												this
											)}
										/>
									</Col>
									<Col xs={12} sm={6} className="mb-2 mb-sm-0 pe-0">
										<Form.Check
											inline
											label="Automatically Create Monthly Item"
											name="timeautocreate"
											type="checkbox"
											id={`chk-auto-create-monthly-item`}
											onClick={this.formEventCompany2.handleCheckChange.bind(
												this
											)}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>

				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleClickSave}>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(WorkflowSettingsTime);
