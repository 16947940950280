import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import ThreadComponent from '../../ThreadComponent';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

class ItemAddComponentAddSpecification extends ThreadComponent {
	constructor(props) {
		super(props);

		this.updatedData = this.props.updatedData;

		this.state = {
			selectData: [
				{ value: 'option-1', label: 'Option 1' },
				{ value: 'option-2', label: 'Option 2' },
				{ value: 'option-3', label: 'Option 3' },
			],
			data: {},
			dataIsLoaded: false,
			cfarequired: false,
			woheader: false,
			specinsHtml: '',
			wrspecinsHtml: '',
			startDate: new Date(),
		};

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	async componentDidMount() {
		if (Object.keys(this.props.data).length > 0) {
			let data = this.props.data || {};

			const shipby = this.updatedData.shipby ?? data.shipby;
			this.setState({
				data: data,
				dataIsLoaded: true,
				cfarequired: data.cfarequired,
				woheader: data.woheader,
				specinsHtml: await mapRtfToDraft(
					this.updatedData?.specinsrtf ?? data?.specinsrtf ?? ''
				),
				wrspecinsHtml: await mapRtfToDraft(
					this.updatedData?.wrspecinsrtf ?? data?.wrspecinsrtf ?? ''
				),
				startDate: shipby ? new Date(shipby) : null,
			});
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data || {};

			const shipby = this.updatedData.shipby ?? data.shipby;
			this.setState({
				data: data,
				dataIsLoaded: true,
				cfarequired: data.cfarequired,
				woheader: data.woheader,
				specinsHtml: await mapRtfToDraft(
					this.updatedData?.specinsrtf ?? data?.specinsrtf ?? ''
				),
				wrspecinsHtml: await mapRtfToDraft(
					this.updatedData?.wrspecinsrtf ?? data?.wrspecinsrtf ?? ''
				),
				startDate: shipby ? new Date(shipby) : new Date(),
			});
		}
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}

			let state = this.state;
			state[key] = value;
			this.setState(state);
		}
		this.props.onDataChange({ key: key, value: value });

		if (key === 'specins' || key === 'wrspecins') {
			const valueRtf = this.htmlToRtfConverter
				.convertHtmlToRtf(e.target.html)
				.replaceAll('\\~', ' ');

			this.props.onDataChange({ key: `${key}rtf`, value: valueRtf });
		}
	};

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
		this.props.onDataChange({
			key: 'shipby',
			value: dayjs(date).format('YYYY-MM-DD'),
		});
	};

	render() {
		const { data, cfarequired, woheader } = this.state;

		return (
			<>
				<Row className="mb-4">
					<Col className="mb-3 mb-lg-0">
						<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
							<Row xs={1} lg={2} className="py-4">
								<Col>
									<h6 className="fw-bold mb-0">Special Instructions</h6>
								</Col>
							</Row>
						</div>
						<Row xs={1} lg={2}>
							<Col>
								<Form.Group>
									<Row className="mb-4">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 pt-2">
												Instructions
											</Form.Label>
										</Col>
										<Col>
											<WysiwygEditor id="specins" onChange={this.handleChange}>
												{this.state.specinsHtml}
											</WysiwygEditor>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">PO Sidemark</Form.Label>
										</Col>
										<Col>
											<Form.Control
												type="text"
												placeholder="Please enter"
												id="sidemark"
												defaultValue={
													this.updatedData.sidemark ?? data.sidemark
												}
												onChange={this.handleChange}
											/>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
										<Col lg={5}>
											<Form.Check
												inline
												label="CFA Required"
												name="group2"
												type="checkbox"
												id="cfarequired"
												checked={this.updatedData.cfarequired ?? cfarequired}
												onChange={this.handleChange}
											/>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">
												Ship On or Before
											</Form.Label>
										</Col>
										<Col lg={3}>
											<DatePicker
												selected={this.state.startDate}
												onChange={this.handleDateChange}
												showMonthDropdown="true"
												showYearDropdown="true"
												className="form-control form-control-sm"
												placeholderText="Select"
											/>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>

						<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
							<Row xs={1} lg={2} className="py-4">
								<Col>
									<h6 className="fw-bold mb-0">
										Workroom Special Instructions
									</h6>
								</Col>
							</Row>
						</div>
						<Row xs={1} lg={2}>
							<Col>
								<Form.Group>
									<Row className="mb-4">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 pt-2">
												Instructions
											</Form.Label>
										</Col>
										<Col>
											<WysiwygEditor
												id="wrspecins"
												onChange={this.handleChange}
											>
												{this.state.wrspecinsHtml}
											</WysiwygEditor>
										</Col>
									</Row>

									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
										<Col lg={5}>
											<Form.Check
												inline
												label="Part of Work Order Heading"
												name="group3"
												type="checkbox"
												id="woheader"
												checked={this.updatedData.woheader ?? woheader}
												onChange={this.handleChange}
											/>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	}
}

export default WithRouter(ItemAddComponentAddSpecification);
