import React, { useState } from 'react';
import { displayAlert } from '../../../utilities/Response';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { isValidUrl } from '../../../utilities/Validation';

interface IEditModalProps {
	name: string;
	url: string;
	fileType: string;
	onConfirm: (newName: string, newLink: string | null) => void;
	onCancel: () => void;
}

const EditModal = ({
	name,
	url,
	fileType,
	onConfirm,
	onCancel,
}: IEditModalProps) => {
	const isFileTypeURL = fileType === 'url';
	const fileExtensionIndex = name.lastIndexOf('.');
	const fileExtension = isFileTypeURL
		? ''
		: fileExtensionIndex !== -1
		? name.slice(fileExtensionIndex)
		: '';

	const getBaseName = (isLink: boolean, index: number, name: string) => {
		if (isLink) return name;
		return index !== -1 ? name.slice(0, index) : '';
	};
	const [newName, setNewName] = useState(
		getBaseName(isFileTypeURL, fileExtensionIndex, name)
	);
	const [newURL, setNewURL] = useState(url);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		switch (name) {
			case 'name':
				setNewName(value);
				break;
			case 'url':
				setNewURL(value);
				break;
			default:
				break;
		}
	};

	const handleConfirm = () => {
		const trimName = newName.trim();
		if (!trimName.length) {
			displayAlert('danger', 'Invalid File/URL Name');
			return;
		} else if (isFileTypeURL && !isValidUrl(newURL)) {
			displayAlert('danger', 'Invalid URL');
			return;
		}

		onConfirm(`${trimName}${fileExtension}`, newURL);
	};

	return (
		<Modal
			show={true}
			aria-labelledby="action-modal"
			className="a-modal"
			backdrop="static"
			centered
		>
			<Modal.Body>
				<Row className="mb-3">
					<Col className="px-5">
						<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
							Edit Name
						</h5>
						<div className="text-center mx-auto fw-bold pt-4">
							<Col>
								<Row className="align-items-center mb-3">
									<Col xs={4} className="text-end">
										<Form.Label className="mb-0">File/URL Name</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											name="name"
											value={newName}
											onChange={handleChange}
											maxLength={250}
										/>
									</Col>
								</Row>
								{isFileTypeURL && (
									<Row className="align-items-center mt-3">
										<Col xs={4} className="text-end">
											<Form.Label className="mb-0">URL</Form.Label>
										</Col>
										<Col>
											<Form.Control
												type="text"
												name="url"
												value={newURL}
												onChange={handleChange}
											/>
										</Col>
									</Row>
								)}
							</Col>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-3 px-5">
				<Row className="w-100">
					<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
						<Button
							variant="primary"
							onClick={handleConfirm}
							className="w-100 m-2"
						>
							OK
						</Button>

						<Button
							variant="trans-light border-secondary-ash"
							onClick={onCancel}
							className="w-100 m-2"
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};

EditModal.displayName = 'EditModal';

export default EditModal;
