import React from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import BudgetBanner, { BudgetBannerContext } from './BudgetBanner';

interface IBudgetModalProps {
	title: string;
	onClose: () => void;
}
const BudgetModal = ({ title, onClose }: IBudgetModalProps) => {
	return (
		<Modal
			show={true}
			onHide={onClose}
			className="a-modal"
			backdrop="static"
			centered
			// For displaying the banner at the same level as the #response-alert-div element (See src/assets/styles/app-branding.scss)
			style={{ zIndex: 9999999999999 }}
		>
			<Modal.Header>
				<Modal.Title as="h5">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<BudgetBanner
						context={BudgetBannerContext.Modal}
						dataTestId="budget-banner-modal"
					/>
				</Row>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-3 px-5">
				<Button onClick={onClose}>Ok</Button>
			</Modal.Footer>
		</Modal>
	);
};

BudgetModal.displayName = 'BudgetModal';

export default BudgetModal;
