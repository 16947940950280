import { ApiService } from '../../../../lib/networking/HttpService';

export class AccountService extends ApiService {
	getGLAccounts<T = IAccountResponse>(params?: string): Promise<T> {
		return this.httpWorker.get(
			`/generalledgeraccounts/summaries${params || ''}`,
			{}
		);
	}
}

export interface IAccountResponse {
	key: string;
	value: string;
	id: string;
}
