import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import { ApiService } from '../../../lib/api/HttpService';
import {
	AlertProps,
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
} from '../../../utilities/Response';
import MSG from '../../../defaults/Message';
import { capitalize } from 'lodash';

interface Responses {
	objIds: [];
	objActions: [];
	message: string;
	objectType: string;
	deleteType: string;
	params: {
		moduleSingular: string;
		modulePlural: string;
	};
}

interface State {
	show: boolean;
	actionCompleted: boolean;
	responses: any;
	errIds: [];
}

interface Props {
	show: boolean;
	responses: Responses;
	refreshData: any;
	hideModal: any;
	title?: string;
}

class DeleteModal extends React.Component<Props, State> {
	readonly api: ApiService;
	public deleteObjectParams;

	constructor(props: Props) {
		super(props);

		this.state = {
			show: false,
			actionCompleted: false,
			responses: {},
			errIds: [],
		};

		this.api = new ApiService();

		this.deleteObjectParams = {
			deleteType: '',
			objectType: '',
			objectCodeOrId: '',
		};
	}

	componentDidUpdate(previousProps: Props, previousState: State) {
		if (previousProps.show !== this.props.show && this.props.responses) {
			this.setState({
				show: this.props.show,
				responses: this.props.responses,
			});

			this.deleteObjectParams = {
				deleteType: this.props.responses.deleteType,
				objectType: this.props.responses.objectType,
				objectCodeOrId: '',
			};
		}
	}

	async handleAction(e: any) {
		const { responses } = this.props;

		if (
			responses.message !=
			'This Proposal cannot be deleted because it either has a deposit received or one or more items have been purchased or invoiced.'
		) {
			displayAlertLoader(MSG.loading.delete.msg);

			try {
				const errIds: any = [];

				const completeResponses = [
					'dmriFullyDeleted',
					'dmriMadeInactive',
					'dmriMarkedComplete',
				];

				for (let i = 0; i < responses.objIds.length; i++) {
					this.deleteObjectParams.objectCodeOrId = responses.objIds[i];
					switch (responses.objActions[i]) {
						case 'delete':
							this.deleteObjectParams.deleteType =
								'dmriTestAndFullDeleteIfPossible';
							break;
						case 'deactivate':
							this.deleteObjectParams.deleteType = 'dmriTestAndDeactivate';
							break;
						case 'complete':
							this.deleteObjectParams.deleteType = 'dmriTestAndMakeComplete';
							break;
						default:
							this.deleteObjectParams.deleteType = '';
							break;
					}

					if (this.deleteObjectParams.deleteType !== '') {
						const res: any = await this.api.postJson(
							'deleteobjects',
							this.deleteObjectParams
						);

						if (!completeResponses.includes(res.result))
							errIds.push(responses.objIds[i]);
					}
				}

				const variant: AlertProps['variant'] = errIds.length
					? 'danger'
					: 'success';

				displayAlert(variant, this.makeErrorMessage(errIds));

				this.props.refreshData(e);
			} catch (error: any) {
				displayAlertError(getErrorMessage(error));
			}
		}

		this.props.hideModal(e);
	}

	makeErrorMessage(errIds: []): string {
		const name =
			this.props.responses.objIds.length > 1
				? this.props.responses.params.modulePlural
				: this.props.responses.params.moduleSingular;

		if (errIds.length) {
			return `Oops, something went wrong while deleting some ${name}.`;
		}

		switch (this.deleteObjectParams.deleteType) {
			case 'dmriTestAndMakeComplete':
				return `${name} cannot be deleted/deactivated, marked as completed instead.`;
			case 'dmriTestAndDeactivate':
				return `Successfully deactivated ${name}.`;
			case 'dmriTestAndFullDeleteIfPossible':
				return `Successfully deleted ${name}.`;
			default:
				return `Successfully deleted/deactivated ${name}.`;
		}
	}

	checkActionsIsAvailable() {
		let isAction = false;

		for (const action of this.state.responses.objActions) {
			if (action !== 'none') {
				isAction = true;
			}
		}

		return isAction;
	}

	render() {
		const hasAction =
			((this.state.responses.objIds || []).length === 1 &&
				this.checkActionsIsAvailable()) ||
			((this.state.responses.objIds || []).length &&
				this.checkActionsIsAvailable());

		return (
			<Modal
				show={this.state.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3" data-testid="delete-modal">
						<Col className="px-5">
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								{this.props.title ?? 'Delete Confirm'}
							</h5>
							<div className="text-center mx-auto fw-bold pt-4">
								<h6>{this.state.responses.message}</h6>
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							{hasAction ? (
								<Button
									variant="danger"
									onClick={this.handleAction.bind(this)}
									className="w-100 m-2"
									data-testid="delete-modal-ok-button"
								>
									OK
								</Button>
							) : (
								<Button
									variant="trans-light border-secondary-ash"
									onClick={this.props.hideModal.bind(this)}
									className="w-100 m-2"
								>
									OK
								</Button>
							)}

							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal.bind(this)}
								className={`w-100 m-2 ${hasAction ? 'd-block' : 'd-none'}`}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(DeleteModal);
