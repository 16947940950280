import classNames from 'classnames';
import React from 'react';

type TLabelProps = {
	label: string;
	className?: string;
};

const Label = ({ label, className = 'xl:tw-text-right' }: TLabelProps) => (
	<label className={classNames(className)} htmlFor={label}>
		{label}
	</label>
);

Label.displayName = 'Label';

export default Label;
