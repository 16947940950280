import React from 'react';
import { Form } from 'react-bootstrap';

function getTableSortClass(
	sortable: boolean,
	name: string,
	activeName: string,
	dir: string
) {
	return sortable ? `sort ${name === activeName ? `active ${dir}` : ''}` : '';
}

type TCell = {
	label: string;
	field: string;
	sortable?: boolean;
	searchable?: boolean;
	override?: (a: any, b: string) => void;
	tdClass?: string;
	className?: string;
};

export function displaySortableTableHeader(
	tbHeaders: any[],
	activeSort: string,
	dir: string
) {
	if (tbHeaders) {
		return tbHeaders.map((th: TCell, i: number) => {
			return (
				<th key={`th-${th.field}`} className={th.className}>
					<span
						className={getTableSortClass(
							th.sortable as boolean,
							th.field,
							activeSort,
							dir
						)}
						data-field={th.field}
					>
						{th.label}
					</span>
				</th>
			);
		});
	}
}

export function displaySearchableTableHeader(
	tbHeaders: any[],
	handleSearch: any
) {
	if (tbHeaders) {
		return tbHeaders.map((th: any) => {
			return (
				<th>
					{th.searchable && (
						<Form.Control
							type="text"
							data-field={th.label}
							onChange={handleSearch}
						/>
					)}
				</th>
			);
		});
	}
}

export function displaySortableTableData(
	tbHeaders: any[],
	tableData: any[],
	imageLoaded?: boolean
) {
	if (tableData) {
		return tableData.map((data: any, i: number) => {
			return (
				<tr
					className={`${imageLoaded === false ? 'tr-loading-1' : ''}`}
					key={i}
				>
					{tbHeaders.map((th: TCell, j: number) => {
						return (
							<td key={`td-${th.field}-${i}-${j}`} className={th.tdClass}>
								{th.override ? th.override(data, th.field) : data[th.field]}
							</td>
						);
					})}
				</tr>
			);
		});
	}
}
