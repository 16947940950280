import { currencyFormat } from 'legacy/helpers/Number';
import { Form } from 'react-bootstrap';

const PricingTabComponentValue = ({
	value,
	symbol,
}: {
	value: number;
	symbol?: string;
}) => (
	<div className="tw-flex tw-gap-4 tw-items-center">
		<div className="form-group-extra reversed">
			<Form.Control
				type="text"
				className="tw-text-right"
				placeholder="0.00"
				size="sm"
				disabled
				readOnly
				value={currencyFormat(value)}
			/>
			<span>$</span>
		</div>
		{symbol && <span className="tw-text-base tw-text-[#7c7c7c]">{symbol}</span>}
	</div>
);

PricingTabComponentValue.displayName = 'PricingTabComponentValue';

export default PricingTabComponentValue;
