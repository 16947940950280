import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import { Gantt } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { ApiService } from '../../../lib/api/HttpService';
import { isEmpty } from 'lodash';
import React from 'react';
import DatePicker from 'react-datepicker';
import { displayAlert } from '../../../utilities/Response';
import { dateToPayload } from '../../../helpers/Date';

class ProjectViewSchedule extends ThreadComponent {
	constructor(props) {
		super(props);

		this.colors = [
			'#078454',
			'#0F4DA0',
			'#C1432E',
			'#CCAA31',
			'#156DE6',
			'#FED43F',
			'#F15437',
			'#010B6B',
			'#078454',
			'#B30B00',
		];
		this.api = new ApiService();
		this.entriesCount = 10;

		this.state = {
			tasks: [],
			updatedData: {},
			isNew: false,
		};
	}

	async componentDidMount() {
		await this.fetchProjectSchedules();
	}

	async makeDefaultPayload() {
		const company = await this.api.getCompany2();

		const projectSchedule = {};
		for (let i = 0; i < this.entriesCount; i++) {
			const index = i + 1 < this.entriesCount ? `0${i + 1}` : i + 1;
			const phase = `sphase${index}`;
			const duration = `sduration${index}`;
			projectSchedule[phase] = company[phase];
			projectSchedule[duration] = company[duration];
		}

		return projectSchedule;
	}

	makeTasks(projectSchedule) {
		const tasks = [];
		for (let i = 0; i < this.entriesCount; i++) {
			const index = i + 1 < this.entriesCount ? `0${i + 1}` : i + 1;
			let start = projectSchedule[`sdate${index}`];
			let end = projectSchedule[`edate${index}`];
			const isValid = !isEmpty(start) && !isEmpty(end);

			if (!isValid) {
				continue;
			}

			start = new Date(start);
			end = new Date(end);

			tasks.push({
				start: start,
				end: end,
				name: '',
				id: Math.floor(Math.random() * 99999),
				type: 'task',
				progress: 100,
				isDisabled: true,
				styles: {
					progressColor: this.colors[i],
					progressSelectedColor: this.colors[i],
				},
				phase: projectSchedule[`sphase${index}`],
				formattedStart: dayjs(start).format('MM/DD/YYYY'),
				duration: dayjs(end).diff(start, 'day'),
				formattedEnd: dayjs(end).format('MM/DD/YYYY'),
			});
		}

		return tasks;
	}

	addDays(date, days) {
		date.setDate(date.getDate() + days);
		return date;
	}

	async fetchProjectSchedule() {
		const [project] = await this.api.getProjects(
			`?$filter=id eq ${this.props.params.id}`
		);
		this.proj = project.proj;

		const [projectSchedule] = await this.api.getProjectSchedules(
			`?$filter=proj eq '${this.proj}'`
		);

		return projectSchedule;
	}

	async fetchProjectSchedules() {
		let projectSchedule = await this.fetchProjectSchedule();

		if (!projectSchedule) {
			this.setState({
				isNew: true,
			});
			projectSchedule = await this.makeDefaultPayload();
		}

		const tasks = this.makeTasks(projectSchedule);

		this.setState({
			tasks: tasks,
			updatedData: projectSchedule,
		});
	}

	handleChange = (e) => {
		this.setState({
			updatedData: {
				...this.state.updatedData,
				[e.target.id]: e.target.value,
			},
		});
	};

	filterData(data) {
		const updatedData = { ...data };
		for (let i = 0; i < this.entriesCount; i++) {
			const index = i + 1 < this.entriesCount ? `0${i + 1}` : i + 1;
			const phase = updatedData[`sphase${index}`];
			const start = updatedData[`sdate${index}`];

			if (!isEmpty(phase) && isEmpty(start)) {
				delete updatedData[`sphase${index}`];
				delete updatedData[`sdate${index}`];
				delete updatedData[`edate${index}`];
			}
		}

		return updatedData;
	}

	handleSave = async () => {
		try {
			const data = this.filterData({
				...this.state.updatedData,
				proj: this.proj,
			});
			await this.api.patchProjectSchedule(this.proj, data);

			displayAlert('success', 'Successfully saved changes.');

			await this.fetchProjectSchedules();
		} catch (error) {
			displayAlert('danger', error.response.data.userError);
		}
	};

	handleClear = (index) => {
		const updatedData = this.state.updatedData;
		updatedData[`sphase${index}`] = '';
		updatedData[`sdate${index}`] = null;
		updatedData[`edate${index}`] = null;

		this.setState({
			updatedData: updatedData,
		});
	};

	renderForm() {
		const rows = [];
		for (let index = 1; index <= this.entriesCount; index++) {
			const _index = index < this.entriesCount ? `0${index}` : index;
			const phase = `sphase${_index}`;
			const start = `sdate${_index}`;
			const end = `edate${_index}`;
			const duration = `sduration${_index}`;

			rows.push(
				<Row key={index} className="mb-2">
					<Col xs={1} className="text-lg-start mb-2 mb-lg-0">
						<Form.Label className="mb-0 mt-2 pt-1">{`${index}.`}</Form.Label>
					</Col>
					<Col xs={3}>
						<Form.Control
							type="text"
							id={phase}
							value={this.state.updatedData[phase] ?? ''}
							onChange={this.handleChange}
						/>
					</Col>
					<Col xs={3} lg={2}>
						<DatePicker
							className="form-control"
							format="MM/yyyy"
							selected={
								isEmpty(this.state.updatedData[start])
									? null
									: new Date(this.state.updatedData[start])
							}
							onChange={(date) => {
								let endDate = this.state.updatedData[end];
								if (
									date &&
									this.state.isNew &&
									isEmpty(this.state.updatedData[end])
								) {
									endDate = dateToPayload(
										this.addDays(
											new Date(date),
											this.state.updatedData[duration] ?? 0
										).toISOString()
									);
								}
								this.setState({
									updatedData: {
										...this.state.updatedData,
										[start]: date
											? dateToPayload(new Date(date).toISOString())
											: null,
										[end]: endDate,
									},
								});
							}}
						/>
					</Col>
					<Col xs={3} lg={2}>
						<DatePicker
							className="form-control"
							format="MM/yyyy"
							selected={
								isEmpty(this.state.updatedData[end])
									? null
									: new Date(this.state.updatedData[end])
							}
							onChange={(date) => {
								this.setState({
									updatedData: {
										...this.state.updatedData,
										[end]: date
											? dateToPayload(new Date(date).toISOString())
											: null,
									},
								});
							}}
						/>
					</Col>
					<Col className="d-flex align-items-center">
						<Button
							variant="outline-secondary"
							size="sm"
							disabled={
								isEmpty(this.state.updatedData[phase]) &&
								isEmpty(this.state.updatedData[start]) &&
								isEmpty(this.state.updatedData[end])
							}
							className="btn-icon btn-action"
							onClick={() => {
								this.handleClear(_index);
							}}
						>
							<i className="ri-subtract-line"></i> Clear
						</Button>
					</Col>
				</Row>
			);
		}
		return <>{rows}</>;
	}

	renderSave() {
		return (
			<div
				style={{
					padding: '16px',
				}}
				className="d-flex roundedx-6 bg-ivory border-1 border-sand justify-content-end"
			>
				<Button
					variant="primary"
					size="sm"
					className={`btn-icon btn-action`}
					onClick={this.handleSave}
				>
					Save
				</Button>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="min-height">
					<Container className="ms-0 px-0 min-height">
						<Row className="mb-4">
							{this.state.tasks.length > 0 ? (
								<div>
									<Gantt
										todayColor={'#ffffff00'}
										viewMode="Week"
										rowHeight={24}
										tasks={this.state.tasks}
										TaskListHeader={(props) => {
											return (
												<TaskListHeader
													rowWidth={'120px'}
													headerHeight={props.headerHeight}
												/>
											);
										}}
										TaskListTable={(props) => {
											return (
												<TaskListTable
													rowWidth={'120px'}
													rowHeight={props.rowHeight}
													tasks={props.tasks}
												/>
											);
										}}
										TooltipContent={(props) => {
											return <Tooltip props={props} />;
										}}
									/>
								</div>
							) : (
								<div className="fw-bold text-center">No data to display</div>
							)}
						</Row>
						<Row className="mb-4">{this.renderSave()}</Row>
						<Row>{this.renderForm()}</Row>
					</Container>
				</div>
			</>
		);
	}
}

const TaskListHeader = ({ headerHeight, fontFamily, fontSize, rowWidth }) => {
	const titles = ['Phase', 'Start Date', 'Days', 'End Date'];
	return (
		<table className="ganttTaskListTableHeader">
			<thead>
				<tr
					style={{
						fontFamily: fontFamily,
						fontSize: fontSize,
						height: headerHeight,
					}}
				>
					{titles.map((title) => {
						return (
							<th
								key={Math.floor(Math.random() * 99999)}
								className="align-middle ps-1 "
								style={{
									minWidth: rowWidth,
									maxWidth: rowWidth,
								}}
							>
								{title}
							</th>
						);
					})}
				</tr>
			</thead>
		</table>
	);
};

const TaskListTable = ({
	rowHeight,
	fontFamily,
	fontSize,
	rowWidth,
	tasks,
}) => {
	return (
		<table className="gantt-task-list-table-body">
			<tbody>
				{tasks.map((task) => {
					return (
						<tr
							key={task.phase + Math.floor(Math.random() * 99999)}
							style={{
								fontFamily: fontFamily,
								fontSize: fontSize,
								height: rowHeight,
							}}
						>
							<td
								style={{
									minWidth: rowWidth,
									maxWidth: rowWidth,
								}}
							>
								{task.phase}
							</td>
							<td
								style={{
									minWidth: rowWidth,
									maxWidth: rowWidth,
								}}
							>
								{task.formattedStart}
							</td>
							<td
								style={{
									minWidth: rowWidth,
									maxWidth: rowWidth,
								}}
							>
								{task.duration}
							</td>
							<td
								style={{
									minWidth: rowWidth,
									maxWidth: rowWidth,
								}}
							>
								{task.formattedEnd}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

const Tooltip = ({ props }) => {
	return <></>;
};

export default WithRouter(ProjectViewSchedule);
