import { useFormContext, Controller } from 'react-hook-form';
import { TFormValues } from '../types/TFormValues';
import Label from 'legacy/app/components/label/Label';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import TextEditor from 'legacy/app/components/textEditor/TextEditor';

const WorkroomSpecialInstructionsForm = () => {
	const methods = useFormContext<TFormValues>();
	const { setValue, watch } = methods;
	const woHeaderInput = methods.register('woheader');

	useEffect(() => {
		return () => {
			setValue(
				'initialWorkroomSpecialInstructionsDescription',
				watch('wrspecinsrtf').html
			);
		};
	}, [setValue, watch]);

	return (
		<>
			<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
				<p className="tw-text-base tw-font-bold tw-m-0">
					Workroom Special Instructions
				</p>
			</div>
			<div className="tw-gap-8 tw-gap-y-6 tw-py-6 tw-pl-10 tw-grid tw-grid-cols-[150px_500px] tw-max-w-[1200px]">
				<Label label="Instructions" className="tw-pt-4 tw-justify-self-end" />
				<Controller
					control={methods.control}
					name="wrspecinsrtf"
					render={({ field }) => (
						<TextEditor
							initialData={methods.watch(
								'initialWorkroomSpecialInstructionsDescription'
							)}
							onChange={(e) => field.onChange(e.target)}
						/>
					)}
				/>
				<div className="tw-col-end-[-1] tw-flex tw-items-center">
					<Form.Check
						label="Part of Work Order Heading"
						ref={woHeaderInput.ref}
						onChange={woHeaderInput.onChange}
						name={woHeaderInput.name}
						type="checkbox"
					/>
				</div>
			</div>
		</>
	);
};

WorkroomSpecialInstructionsForm.displayName = 'WorkroomSpecialInstructionsForm';
export default WorkroomSpecialInstructionsForm;
