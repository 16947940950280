import { apiClient } from 'api/apiClient';
import type { TStockItem } from 'legacy/lib/api/types/StockItem';

const RESOURCE = '/inventoryStocks';

export const getInventoryStocks = async (
	filter = ''
): Promise<TStockItem[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());

	return data;
};
