import React from 'react';

interface IIcon {
	path: string;
	alt?: string;
	iconClass?: string;
	onClickIcon?: (e?: React.MouseEvent<HTMLImageElement>) => void;
	title?: string;
}

const Icon = ({ path, alt, iconClass, onClickIcon, title }: IIcon) => (
	<img
		className={iconClass}
		src={path}
		alt={alt}
		onClick={onClickIcon}
		title={title}
	/>
);

Icon.displayName = 'Icon';

export default Icon;
