import React, { useState } from 'react';
import useGetFile from 'legacy/lib/api/hooks/useGetFile';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Item } from 'legacy/lib/api/types/Item';
import { TDisplayImage, TItemImage } from 'legacy/lib/api/types/ItemImages';
import ThumbnailGallery from 'legacy/app/components/ThumbnailGallery/ThumbnailGallery';
import ImageDragAndDrop from 'legacy/app/components/ImageDragAndDrop/ImageDragAndDrop';
import { IItemImages } from 'legacy/lib/api/types/ItemImages';

interface IImageGalleryProps {
	itemToEdit?: Item;
	initialImagesData: IItemImages[];
}

const ImageGallery = ({
	itemToEdit,
	initialImagesData = [],
}: IImageGalleryProps) => {
	const methods = useFormContext();
	const [selectedImage, setSelectedImage] = useState<TDisplayImage>({
		file: undefined,
		index: 0,
	});
	const { setValue, watch } = methods;
	const allImages = watch('images');
	const { data: editItemImage } = useGetFile(
		itemToEdit?.primaryImageId as string,
		{
			enabled: !!(itemToEdit && itemToEdit?.primaryImageId?.length > 0),
		}
	);
	useEffect(() => {
		if (initialImagesData) {
			setValue('images', initialImagesData);
		}
	}, [initialImagesData, setValue]);

	useEffect(() => {
		if (editItemImage) {
			const displayImage = { file: editItemImage, index: 0 };
			setSelectedImage(displayImage);
		} else {
			const displayImage = { file: undefined, index: 0 };
			setSelectedImage(displayImage);
		}
	}, [editItemImage]);

	const handleDrop = (file: File) => {
		if (selectedImage?.index !== null) {
			const currentImages = (watch('images') as TItemImage[]) || [];

			const updatedImages = currentImages?.map((image, index) => {
				if (index === selectedImage?.index) {
					return { imageFile: file, fileId: null };
				}

				return image;
			});

			if (selectedImage.index >= currentImages.length) {
				updatedImages.push({ imageFile: file, fileId: null });
			}
			setValue('images', updatedImages);
			setSelectedImage({ file, index: selectedImage?.index || 0 });
		}
	};

	const handleDeleteImage = (index: number) => {
		allImages?.splice(index, 1);
		const selectedImageFile = allImages?.[index]?.imageFile;
		setSelectedImage({ file: selectedImageFile, index: index });
	};

	return (
		<div className="tw-flex-1 lg:tw-flex-[0.6] xl:tw-flex[0.5]">
			<div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
				<ImageDragAndDrop
					image={selectedImage?.file || null}
					title="Add Photo"
					subtitle="or Drag and Drop"
					iconClass="tw-text-5xl tw-font-normal"
					handleFile={(file: File) => {
						handleDrop(file);
					}}
					handleDelete={handleDeleteImage}
					imageIndex={selectedImage?.index || 0}
				/>
				<ThumbnailGallery
					galleryImages={allImages as TItemImage[]}
					setSelectedImage={setSelectedImage}
					selectedImageIndex={selectedImage?.index || 0}
				/>
			</div>
		</div>
	);
};

ImageGallery.displayName = 'ImageGallery';

export default ImageGallery;
