import React from 'react';
import { useGetAccountName } from 'legacy/pages/accounts-payable/credit-card/reconcile/hooks';
import {
	formatDate,
	formatMoney,
	searchData,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/utils';
import { EAccountType } from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { TReconciliation } from 'legacy/lib/api/HttpService';
import { UseQueryResult } from '@tanstack/react-query';
import {
	filterAccounts,
	flagLatest,
	sortData,
} from 'legacy/templates/modules/accounts-payable/checkbook/CheckbookHistory/CheckbookHistoryUtils';

type TCheckbookHistoryTableBodyProps = {
	query: UseQueryResult<TReconciliation[], unknown>;
	searches: TReconciliation;
	selectedAccount: string;
	sort: unknown[];
};

export const CheckbookHistoryTableBody = ({
	query,
	searches,
	selectedAccount,
	sort,
}: TCheckbookHistoryTableBodyProps) => {
	const { isLoading, data } = query;

	const getAccountName = useGetAccountName(EAccountType.ALL);

	const search = (data: TReconciliation[]): TReconciliation[] => {
		return Object.keys(searches).reduce(
			(prev: TReconciliation[], cur: string): TReconciliation[] => {
				const needle = searches[cur as keyof TReconciliation];
				return searchData(needle, cur, prev);
			},
			filterAccounts(selectedAccount, data)
		);
	};

	const [sortField, sortDirection] = sort;

	return (
		<tbody>
			{isLoading ? (
				<>
					<tr className="tr-loading">
						<td className="td-1" colSpan={6}>
							<span>&nbsp;</span>
						</td>
					</tr>
					<tr className="tr-loading">
						<td className="td-1" colSpan={6}>
							<span>&nbsp;</span>
						</td>
					</tr>
				</>
			) : (
				sortData(
					sortField,
					sortDirection,
					search(flagLatest(data as TReconciliation[]))
				).map(
					({
						computerdate,
						statementdate,
						statementbalance,
						userName,
						cashaccount,
						bookbalance,
					}: TReconciliation) => {
						return (
							<tr key={`${cashaccount}-${statementdate}`}>
								<td data-cashaccount={cashaccount}>
									{getAccountName(cashaccount)}
								</td>
								<td>{formatDate(statementdate)}</td>
								<td>{formatMoney(statementbalance)}</td>
								<td>{formatMoney(bookbalance)}</td>
								<td>{formatDate(computerdate)}</td>
								<td>{userName}</td>
							</tr>
						);
					}
				)
			)}
		</tbody>
	);
};

CheckbookHistoryTableBody.displayName = 'CheckbookHistoryTableBody';
