import Stack from 'react-bootstrap/Stack';
import cn from 'classnames';

import { formatMoney } from './utils';

function BorderedBox({ type, children }) {
	const classes = cn(
		'a-box-basis border-1 rounded-1 px-2 py-3 mt-2 mt-sm-0 col',
		{
			...(type && { [`border-${type}`]: type }),
			'border-sand': !type,
		}
	);

	return <div className={classes}>{children}</div>;
}

function BoxLabel({ children }) {
	return <div className="fs-sm">{children}</div>;
}

function BoxValue({ children }) {
	return <div className="fs-xl ff-type-bold">{children}</div>;
}

function AsyncBoxValue({ query, selector }) {
	const { isLoading, data } = query;

	if (isLoading) {
		return (
			<BoxValue>
				<div className="text-center">
					<div
						className="spinner-grow spinner-grow-sm text-primary"
						role="status"
					>
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			</BoxValue>
		);
	}

	return <BoxValue>{selector(data)}</BoxValue>;
}

export default function ReconcileStatus({ totalsQuery }) {
	const balanceSelector = ({ balance }) => formatMoney(balance);
	const computerBalanceSelector = ({ computerBalance }) =>
		formatMoney(computerBalance);
	const differenceSelector = ({ difference }) => formatMoney(difference);
	const outstandingChargesSelector = ({ outstandingCharges }) =>
		formatMoney(outstandingCharges);
	const paymentInTransitSelector = ({ paymentsInTransit }) =>
		formatMoney(paymentsInTransit);

	const differenceType = totalsQuery.isLoading
		? ''
		: totalsQuery.data.difference === 0
		? 'success'
		: 'danger';

	return (
		<div className="bg-ivory light a-header pb-3 pt-0">
			<Stack direction="horizontal" gap={3}>
				<BorderedBox>
					<BoxLabel>Outstanding Charges</BoxLabel>
					<AsyncBoxValue
						query={totalsQuery}
						selector={outstandingChargesSelector}
					/>
				</BorderedBox>
				<div>
					<i className="ri-subtract-line ri-xl"></i>
				</div>
				<BorderedBox>
					<BoxLabel>Payment In Transit</BoxLabel>
					<AsyncBoxValue
						query={totalsQuery}
						selector={paymentInTransitSelector}
					/>
				</BorderedBox>
				<div>
					<i className="ri-equal-line"></i>
				</div>
				<BorderedBox>
					<BoxLabel>Balance</BoxLabel>
					<AsyncBoxValue query={totalsQuery} selector={balanceSelector} />
				</BorderedBox>
				<BorderedBox>
					<BoxLabel>Computer Balance</BoxLabel>
					<AsyncBoxValue
						query={totalsQuery}
						selector={computerBalanceSelector}
					/>
				</BorderedBox>
				<BorderedBox type={differenceType}>
					<BoxLabel>Difference</BoxLabel>
					<AsyncBoxValue query={totalsQuery} selector={differenceSelector} />
				</BorderedBox>
			</Stack>
		</div>
	);
}
