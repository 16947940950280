import React from 'react';
import { Col, Row, Table, Form, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import { routeParam, WithRouter } from '../../../../helpers/Router';
import ListFilter from '../../../components/ListFilter';
import { fakeData } from '../../../factory/fakeFactory';
import ThreadComponent from '../../ThreadComponent';
import { ActionBarVertical } from '../../../components/ActionBar';

class CatalogItemComponentAddOrderStatus extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: fakeData(10),
			dataIsLoaded: false,
		};
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			prev.showTableSearch = !prev.showTableSearch;
			return prev;
		});
	};

	renderFilter() {
		return (
			<>
				<ListFilter className="justify-content-end">
					<ListFilter.Actions lg={3} xl={2}>
						<ListFilter.Action className="ms-auto">
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action fw-bold ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderContent1() {
		return (
			<Table striped responsive className="a-table">
				<thead>
					<tr className="a-table-heading">
						<th>
							<span className="sort active asc" data-field="excerpt">
								PO/WO NO.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Qty.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Order
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Vendor
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Deposit
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Dep. Ck. No.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								CK. DT.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Ack. DT.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Ack No.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Ship Via
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Expected
							</span>
						</th>
					</tr>
					<tr
						className={`a-table-search-fields ${
							this.state.showTableSearch ? '' : 'd-none'
						}`}
					>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.state.data.map((item, i) => (
						<tr key={i} data-key={i}>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	}

	renderContent2() {
		return (
			<Table striped responsive className="a-table">
				<thead>
					<tr className="a-table-heading">
						<th>
							<span className="sort active asc" data-field="excerpt">
								TX No.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Vendor
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Invoice No.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Invoice Date
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								PO/WO No.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Qty
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Cost
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Check No.
							</span>
						</th>
						<th>
							<span className="sort" data-field="excerpt">
								Check Date
							</span>
						</th>
					</tr>
					<tr
						className={`a-table-search-fields ${
							this.state.showTableSearch ? '' : 'd-none'
						}`}
					>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
						<th>
							<Form.Control type="text" data-field="excerpt" />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.state.data.map((item, i) => (
						<tr key={i} data-key={i}>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
							<td>{item.excerpt}</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	}

	renderVerticalActionBar() {
		return (
			<ActionBarVertical>
				<ActionBarVertical.Links>
					<li>
						<NavLink
							to={routeParam(URI.item.catalog.addComponentEditOrderStatusPO)}
							className="d-flex align-items-center"
						>
							<i className="ri-edit-line mx-0"></i>
						</NavLink>
					</li>
				</ActionBarVertical.Links>
			</ActionBarVertical>
		);
	}

	renderVerticalActionBar2() {
		return (
			<ActionBarVertical>
				<ActionBarVertical.Links>
					<li>
						<NavLink
							to={routeParam(
								URI.item.catalog.addComponentEditOrderStatusInvoice
							)}
							className="d-flex align-items-center"
						>
							<i className="ri-edit-line mx-0"></i>
						</NavLink>
					</li>
				</ActionBarVertical.Links>
			</ActionBarVertical>
		);
	}

	render() {
		return (
			<>
				<Row>
					<Col sm="12">
						<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
							<Row xs={1} lg={2} className="py-4">
								<Col>
									<h6 className="fw-bold mb-0">Purchase and Work Orders</h6>
								</Col>
							</Row>
						</div>

						{this.renderFilter()}

						<Row>
							<Col
								lg="12"
								className="mt-2 mb-5 pb-5 position-relative table-action-bar"
							>
								{this.renderVerticalActionBar()}
								{this.renderContent1()}
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2} className="mb-4">
					<Col className="mb-3 mb-lg-0">
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">CFA Date</Form.Label>
							</Col>
							<Col>
								<Row>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
									<Col></Col>
								</Row>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Install Date</Form.Label>
							</Col>
							<Col>
								<Row>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
									<Col></Col>
								</Row>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Status/Storage Loc.</Form.Label>
							</Col>
							<Col>
								<Row>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
									<Col></Col>
								</Row>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Inspection</Form.Label>
							</Col>
							<Col>
								<Row>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
									<Col></Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col sm="12">
						<div className="bg-secondary-grey ps-4 roundedx-4 mb-4">
							<Row xs={1} lg={2} className="py-4">
								<Col>
									<h6 className="fw-bold mb-0">Vendor Invoices</h6>
								</Col>
							</Row>
						</div>

						{this.renderFilter()}

						<Row>
							<Col
								lg="12"
								className="mt-2 mb-5 pb-5 position-relative table-action-bar"
							>
								{this.renderVerticalActionBar2()}
								{this.renderContent2()}
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	}
}

export default WithRouter(CatalogItemComponentAddOrderStatus);
