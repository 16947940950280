import { apiClient } from 'api/apiClient';

const RESOURCE = (itemId: string) => `items/${itemId}/status/totals`;

export interface IItemsTotals {
	invoicedToDate: number;
	paymentsToDate: number;
	balanceDue: number;
}

export const getItemTotals = async (itemId: string): Promise<IItemsTotals> => {
	const { data } = await apiClient.get(`${RESOURCE(itemId)}`.trim());

	return data;
};
