import { Dropdown, Form } from 'react-bootstrap';
import React, { FC } from 'react';
import { Resource } from 'legacy/resources/Resources';
import { routeParam } from 'legacy/helpers/Router';
import URI from 'legacy/defaults/RoutesDefault';
import { TStockItem } from 'legacy/lib/api/types/StockItem';

export const StockItemsListGrid: FC<{
	data: TStockItem[];
	thumbnails: Map<number, any>;
	selectedIds: number[];
	columns: number;
	onDelete: (item: TStockItem) => void;
	onDuplicate: (item: TStockItem) => void;
	onCheck: (id: number) => void;
}> = ({
	data,
	thumbnails,
	selectedIds,
	columns,
	onDelete,
	onDuplicate,
	onCheck,
}) => {
	const style = { '--columns': columns } as React.CSSProperties;
	return (
		<div className="stock-items-grid a-clipper-media w-100" style={style}>
			{data.map((item, index) => {
				return (
					<div
						key={index}
						className="a-clipper-media-item-handle border rounded m-0"
						onClick={() => {
							window.location.href = routeParam(URI.stockItem.edit, {
								id: item.id,
							});
						}}
					>
						<div className="d-flex justify-content-between mb-2">
							<Form.Check
								label=""
								type="checkbox"
								checked={selectedIds.includes(item.id)}
								onChange={() => {
									onCheck(item.id);
								}}
								onClick={(e) => {
									e.stopPropagation();
								}}
							/>
							<Dropdown
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<Dropdown.Toggle
									variant="light"
									className="no-outline no-min-height"
								>
									<i className="ri-more-2-fill ri-lg"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu align="end">
									<Dropdown.Item
										onClick={() => {
											onDelete(item);
										}}
									>
										Delete
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											onDuplicate(item);
										}}
									>
										Duplicate
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div className="d-flex flex-column align-items-center">
							<div className="w-100">
								{thumbnails.has(item.id) ? (
									<img
										className="img-sm object-fit-contain"
										style={{ aspectRatio: 1 }}
										src={URL.createObjectURL(thumbnails.get(item.id))}
										alt={item.itemName}
									/>
								) : (
									<img
										className="img-sm"
										src={Resource.Image.ClipperFallback.default}
										alt={item.itemName}
									/>
								)}
							</div>
							<div className="text-center">
								<h6
									className="fs-md px-2 text-decoration-underline text-break"
									style={{ cursor: 'default' }}
								>
									{item.itemName}
								</h6>
								<div className="text-secondary-ash">{item.supplier}</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
StockItemsListGrid.displayName = 'StockItemsListGrid';
