import React, { useEffect, useRef } from 'react';
import Label from '../../../components/label/Label';
import FormCurrencyInput from 'legacy/app/components/form/FormCurrencyInput';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl } from 'react-bootstrap';
import { WhatChanged } from 'legacy/lib/api/types/WhatChangedItems';
import { VALID_PERCENTAGE_NUMBER } from 'legacy/resources/regex/validPercentageNumber';
import { useParams } from 'react-router-dom';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { Item } from 'legacy/lib/api/types/Item';

const CurrencyInputStyleOverride = {
	height: '2rem',
	maxWidth: '7.5rem',
};

const Deposit = ({
	disableDepositSelect,
	handleRecalculate,
	itemToEdit,
}: {
	itemToEdit?: Item;
	disableDepositSelect: boolean;
	handleRecalculate: (whatPropertyChanged: `${WhatChanged}`) => void;
}) => {
	const { control, setValue, watch } = useFormContext();

	const { id: projectId } = useParams();

	const { data: project } = useGetProject(projectId as string);

	const shouldEraseClientDepositInput = useRef(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const isValidNumber = VALID_PERCENTAGE_NUMBER.test(e.target.value);
		if (isValidNumber || !e.target.value || e.target.value === '-') {
			setValue('cldeppct', e.target.value);
		}
	};

	useEffect(() => {
		if (project) {
			setValue('cldeppct', String(project.clientdep));
		}
	}, [setValue, project]);

	const estDep = watch('estdep');

	return (
		<div className="tw-p-4 tw-mb-4 tw-mx-auto tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#dedcd7] tw-bg-[#faf9f7] tw-max-w-6xl">
			<div className="tw-flex tw-gap-4 tw-flex-wrap">
				<div className="tw-flex tw-items-center tw-gap-6">
					<Label label="Client Deposit %" className="tw-text-xs" />
					<Controller
						control={control}
						name={'cldeppct'}
						render={({ field: { value } }) => (
							<FormControl
								className="tw-!min-h-8 tw-max-w-[120px] tw-!pt-[3px] tw-!pb-[3px]"
								onChange={handleChange}
								value={value}
								maxLength={15}
								onBlur={(e) => {
									const isNotAValidNumber = isNaN(Number(e.target.value));
									if (
										!e.target.value ||
										isNotAValidNumber ||
										e.target.value === '-0.'
									) {
										setValue('cldeppct', '0');
									} else {
										setValue('cldeppct', String(Number(e.target.value)));
									}

									handleRecalculate(WhatChanged.DEPOSIT);
								}}
								placeholder="0"
								disabled={disableDepositSelect}
							/>
						)}
					/>
				</div>
				<div className="tw-flex tw-items-center tw-gap-4">
					<Label label="Deposit Requested" className="tw-text-xs" />
					<Controller
						control={control}
						name="estdep"
						render={({ field }) => (
							<FormCurrencyInput
								id="Deposit Requested"
								maxLength={15}
								disabled={disableDepositSelect}
								style={CurrencyInputStyleOverride}
								value={field.value}
								onValueChange={field.onChange}
								onBlur={(e) => {
									if (!e.target.value) {
										return field.onChange('0.00');
									}

									if (
										Number(estDep) !== 0 &&
										Number(estDep) !== itemToEdit?.estdep
									) {
										shouldEraseClientDepositInput.current = true;
									}
									if (shouldEraseClientDepositInput.current) {
										setValue('cldeppct', '0');
									}
								}}
							/>
						)}
					/>
				</div>
				<div className="tw-flex tw-items-center tw-gap-4">
					<Label label="Total Est. Cost" className="tw-text-xs" />
					<Controller
						control={control}
						name="esttcost"
						render={({ field }) => (
							<FormCurrencyInput
								maxLength={15}
								id="Total Est. Cost"
								style={CurrencyInputStyleOverride}
								value={field.value}
								disabled
							/>
						)}
					/>
				</div>
				<div className="tw-flex tw-items-center tw-gap-4">
					<Label label="Total Est. Price" className="tw-text-xs" />
					<Controller
						control={control}
						name="esttprice"
						render={({ field }) => (
							<FormCurrencyInput
								id="Total Est. Price"
								maxLength={15}
								disabled
								style={CurrencyInputStyleOverride}
								value={field.value}
							/>
						)}
					/>
				</div>
			</div>
		</div>
	);
};

Deposit.displayName = 'Deposit';

export default Deposit;
