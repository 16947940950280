import { ApiService } from '../../../lib/api/HttpService';

const fetchBudget = (filter) => {
	return new ApiService().getBudgets(filter);
};

const deleteBudget = async (items) => {
	const promises = [];
	const failedItems = [];

	for (const item of items) {
		try {
			promises.push(await new ApiService().deleteBudget(JSON.parse(item)));
		} catch {
			failedItems.push(item);
		}
	}

	await Promise.all(promises);
	return failedItems;
};

const createBudget = async (data) => {
	return await new ApiService().createBudget(data);
};

const updateBudget = async (data) => {
	return await new ApiService().updateBudget(data);
};

const updateBudgetToolbar = async (id, data) => {
	return await new ApiService().editProject(id, data);
};

const fetchBudgetToolbarInfo = (filter) => {
	return new ApiService().getProjects(filter);
};

export {
	fetchBudget,
	deleteBudget,
	createBudget,
	updateBudget,
	updateBudgetToolbar,
	fetchBudgetToolbarInfo,
};
