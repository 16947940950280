import { getCookie } from './Auth';
import { setLocalStorage } from './LocalStorage';

export const updateTimeEntryFilter = (
	projectName: string,
	projectCode: string,
	projectId: number
) => {
	const instanceId = getCookie('dmInstanceId');
	const userCode = getCookie('dmUsercode');
	setLocalStorage(
		`filter_dm_${instanceId + userCode}_timeEntryFilter`,
		{
			proj: {
				label: `${projectName} [${projectCode}]`,
				value: projectCode,
				name: projectName,
				id: projectId,
			},
		},
		true
	);
};
