import React from 'react';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import SecureContent from 'legacy/app/components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';

export type TTabOption =
	| 'pricing'
	| 'specification'
	| 'info'
	| 'status'
	| 'workroom'
	| 'documents';

type TTabsProps = {
	numDocuments: number;
	toggleTab: (tab: TTabOption) => void;
	activeTab: TTabOption;
};

const ItemTabs = ({
	toggleTab,
	activeTab,
	numDocuments,
}: TTabsProps): JSX.Element => {
	const enabled = useFeature(FeatureFlags.SpecificationTemplates);
	const options: TTabOption[] = [
		'info',
		'specification',
		'pricing',
		'status',
		'workroom',
		'documents',
	];

	const getOptionValue = (option: TTabOption) => {
		if (option === 'specification') {
			return enabled ? (
				<BaseButton
					buttonClass={`tw-capitalize tw-cursor-pointer tw-px-8 tw-py-2 tw-rounded-lg ${
						activeTab === option ? 'tw-bg-[#211F211A]' : 'tw-bg-[transparent]'
					}`}
					onClick={() => toggleTab(option as TTabOption)}
					text={option}
				/>
			) : null;
		}
		if (option === 'pricing') {
			return (
				<li>
					<SecureContent
						attributeNo={14}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec7}
					>
						<SecureContent.HasAccess>
							<BaseButton
								buttonClass={`tw-capitalize tw-cursor-pointer tw-px-8 tw-py-2 tw-rounded-lg ${
									activeTab === option
										? 'tw-bg-[#211F211A]'
										: 'tw-bg-[transparent]'
								}`}
								onClick={() => toggleTab(option as TTabOption)}
								text={option}
							/>
						</SecureContent.HasAccess>
					</SecureContent>
				</li>
			);
		}
		return (
			<li key={option}>
				<BaseButton
					buttonClass={`tw-capitalize tw-cursor-pointer tw-px-8 tw-py-2 tw-rounded-lg ${
						activeTab === option ? 'tw-bg-[#211F211A]' : 'tw-bg-[transparent]'
					}`}
					onClick={() => toggleTab(option as TTabOption)}
					text={option}
				/>
				{option === 'documents' && numDocuments > 0 && (
					<i className="ri-attachment-2 ms-1"></i>
				)}
			</li>
		);
	};
	return (
		<ul className="tw-flex tw-text-sm tw-pl-10 tw-pb-6 tw-gap-4 tw-items-center tw-p-0 tw-pt-4 tw-list-none bg-ivory">
			{options.map((option) => (
				<React.Fragment key={option}>{getOptionValue(option)} </React.Fragment>
			))}
		</ul>
	);
};
ItemTabs.displayName = 'ItemTabs';

export default ItemTabs;
