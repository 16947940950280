import Spinner from 'legacy/app/components/help/Spinner';
import useStartPaymentProcessorSession from 'legacy/lib/api/hooks/useStartPaymentProcessorSession';
import { PaymentSessionType } from 'legacy/lib/api/types/PaymentSessionType';
import React from 'react';

const DepositsReport = () => {
	const {
		data: sessionInfo,
		isLoading,
		error,
	} = useStartPaymentProcessorSession(PaymentSessionType.DEPOSITREPORT);

	if (isLoading) {
		return <Spinner isChild />;
	}

	if (error) {
		return null;
	}

	return (
		<rainforest-deposit-report
			show-export-button
			session-key={sessionInfo?.sessionKey}
			data-filters={JSON.stringify({
				merchant_id: sessionInfo?.merchantId,
			})}
		/>
	);
};

DepositsReport.displayName = 'DepositsReport';

export default DepositsReport;
