import { HeaderContext } from 'legacy/app/layouts/SuperHeader/SuperHeader';
import { THeaderOptions } from 'legacy/app/layouts/SuperHeader/SuperHeaderTypes';
import React, { useState, ReactNode } from 'react';

export const AppWrapper = ({ children }: { children: ReactNode }) => {
	const [header, setHeader] = useState<THeaderOptions | null>(null);

	return (
		<HeaderContext.Provider value={[header, setHeader]}>
			<div className="app-wrapper">{children}</div>
		</HeaderContext.Provider>
	);
};

AppWrapper.displayName = 'AppWrapper';
