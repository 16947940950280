import React from 'react';
import { Col, Row, Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import { ApiService } from '../../../lib/api/HttpService';
import AccountSettingsPayableDialog from './AccountSettingsPayableDialog';
import _ from 'lodash';
import { displayAlert } from '../../../utilities/Response';
import { showLoading } from '../../../helpers/Loading';
import FilterSort from '../../../utilities/modules/FilterSort';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import debounce from 'lodash/debounce';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { FooterFormAction } from '../../components/Form';

class AccountSettingsPayment extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('settings_ap_list');
		this.fs.setDefaultSort('payType asc');

		this.state = {
			checks: [],
			data: [],
			isShowing: false,
			dataIsLoaded: false,
			isComponentLoaded: false,
			selectedData: {},
			pageSize: 20,
			page: 1,
			sortProperty: this.fs.getSort() ?? 'payType asc',
			checkssorttag: false,
		};

		this.api = new ApiService();
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	componentInit() {
		this.setTitle('A/P Payment | Account Settings');
	}

	async componentDidMount() {
		this.dMloader(true);
		await this.renderData();
		this.dMloader(false);

		if (!this.state.isComponentLoaded) {
			this.enableSortTable();
		}

		this.setState({
			isComponentLoaded: true,
		});
	}

	handleChangePage = async (page) => {
		this.renderData(page);

		this.setState({
			page: page,
		});
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	buildFilters(currentPage) {
		let queryString = `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	async renderData(page) {
		const { checkssorttag } = await this.api.getCompany2();
		const data = await this.api.getPayTypes(`?${this.buildFilters(page)}`);

		this.setState({
			dataIsLoaded: true,
			data,
			checkssorttag,
		});
	}

	handleSelectAllChecks = () => {
		let checks = [];
		if (this.state.checks.length !== this.state.data.length) {
			checks = this.state.data.map((item) => {
				return item.payType;
			});
		}

		this.setState({
			checks: checks,
		});
	};

	handleCheck = (payType) => {
		const checks = this.state.checks;
		const index = _.findIndex(checks, (type) => type === payType);

		index > -1 ? checks.splice(index, 1) : checks.push(payType);

		this.setState({
			checks: checks,
		});
	};

	handleDelete = () => {
		const records = this.state.checks.length;
		this.state.checks.map((payType, i) => {
			this.api
				.deletePayType({
					PayType: payType,
				})
				.then((response) => {
					if (i === records - 1) {
						displayAlert('success', `Deleted ${records} records successfully`);
						setTimeout(() => {
							this.setState({
								dataIsLoaded: false,
								checks: [],
							});
							this.renderData();
						}, 2000);
					}
				})
				.catch((error) => {
					displayAlert('danger', error.response.data.userError);
				});
		});
	};

	handleModalData = async () => {
		this.setState({
			isShowing: false,
			dataIsLoaded: false,
			selectedData: {},
		});

		await this.renderData();
	};

	handleOnCheck = (e) => {
		this.setState({
			checkssorttag: e.target.checked,
		});
	};

	handleOnSave = async () => {
		try {
			await this.api.patchCompany2({ checkssorttag: this.state.checkssorttag });
			displayAlert(EAlertTypes.Success, 'Successfully saved changes.');
		} catch (error) {
			displayAlert(EAlertTypes.Danger, error.response.data.userError);
		}
	};

	render() {
		return (
			<>
				<div className="bg-secondary-grey px-4">
					<Row
						xs={'auto'}
						className="py-4 justify-content-between align-items-center"
					>
						<Col>
							<h6 className="fw-bold mb-0">A/P Pay With Types</h6>
						</Col>
						<Col>
							<Row>
								<Col>
									<Button
										as={Link}
										to="#"
										variant="ivory"
										size="sm"
										className={`btn-icon btn-action ${
											this.state.checks.length === 0 ? 'disabled' : ''
										}`}
										disabled={this.state.checks.length === 0}
										onClick={this.handleDelete}
									>
										<i className="ri-close-line"></i> Delete
									</Button>
								</Col>
								<Col>
									<Button
										variant="primary"
										size="sm"
										className={`btn-icon btn-action`}
										onClick={() => {
											this.setState({ isShowing: true });
										}}
									>
										<i className="ri-add-line"></i> Add
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>

				<Row xs={1} className="mb-4 pb-2">
					<Col className="mb-5">
						<div className="table-gradient table-actions position-relative table-action-bar">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading">
										<th align="middle" className="mw-70px">
											<div className="d-flex justify-content-center">
												<Form.Check
													label=""
													type="checkbox"
													checked={
														this.state.checks.length === this.state.data.length
													}
													className={
														this.state.checks.length === this.state.data?.length
															? 'line'
															: ''
													}
													onChange={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th>
											<span
												className={this.sortClass('payType')}
												data-field="payType"
											>
												Payment Type Description
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('defAcct')}
												data-field="defAcct"
											>
												Payment Account No.
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('accountn')}
												data-field="accountn"
											>
												Payment Account Names
											</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{!this.state.dataIsLoaded
										? showLoading()
										: this.state.data.map((item, i) => (
												<tr
													key={i}
													data-key={i}
													className={this.state.checks[i] ? `active` : ''}
												>
													<td>
														<div className="d-flex justify-content-center">
															<Form.Check
																label=""
																type="checkbox"
																checked={this.state.checks.includes(
																	item.payType
																)}
																onChange={() => {
																	this.handleCheck(item.payType);
																}}
															/>
														</div>
													</td>
													<td>
														<Link
															onClick={() => {
																this.setState({
																	isShowing: true,
																	selectedData: item,
																});
															}}
															to="#"
															className="text-charcoal hover-view-icon"
														>
															{item.payType}
														</Link>
													</td>
													<td>{item.defAcct}</td>
													<td>{item.accountn}</td>
												</tr>
										  ))}
								</tbody>
							</Table>
						</div>
					</Col>

					<Col>
						<Form.Check
							inline
							label="Checks are numbered (printed) in the order tagged (instead of vendor code order)"
							id="checkssorttag"
							name="checkssorttag"
							type="checkbox"
							checked={this.state.checkssorttag}
							onChange={this.handleOnCheck}
						/>
					</Col>
				</Row>

				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleOnSave}>
						Save
					</Button>
				</FooterFormAction>

				{this.state.isShowing && (
					<AccountSettingsPayableDialog
						selectedData={this.state.selectedData}
						isShowing={this.state.isShowing}
						onOkay={this.handleModalData}
						onCancel={() => {
							this.setState({
								isShowing: false,
								selectedData: {},
							});
						}}
					/>
				)}
			</>
		);
	}
}

export default WithRouter(AccountSettingsPayment);
