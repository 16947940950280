import React from 'react';
import { Table } from 'react-bootstrap';
import { formatNumber } from '../utils';

export interface IVendorInvoiceDetailProjectPO {
	totalCost?: number;
	refNum?: string;
	description?: string;
	poCost?: number;
	costToDate?: number;
	poQuantity?: number;
	componentType?: number;
	componentTypeDesc?: string;
	quanpaidfor?: number;
}

interface IVendorInvoiceDetailProjectPOProps {
	vendorInvoiceDetailsProject: IVendorInvoiceDetailProjectPO[];
}

const VendorInvoiceDetailProjectPO = ({
	vendorInvoiceDetailsProject,
}: IVendorInvoiceDetailProjectPOProps) => {
	return (
		<>
			<p className="tw-italic">
				* Amounts shown are for the entire invoice and do not reflect partial
				payments.
			</p>

			<div className="tw-overflow-x-auto tw-overflow-y-auto">
				<Table striped bordered responsive>
					<thead>
						<tr>
							<th>Ref No.</th>
							<th>Type</th>
							<th>Description</th>
							<th>PO Qty.</th>
							<th>PO (est.) cost</th>
							<th>Cost-to-date</th>
							<th>Qty. on inv.</th>
							<th>Cost. on inv.</th>
						</tr>
					</thead>
					<tbody>
						{vendorInvoiceDetailsProject.map((item) => {
							const {
								refNum,
								componentTypeDesc,
								description,
								poQuantity,
								poCost,
								costToDate,
								quanpaidfor,
								totalCost,
							} = item;

							return (
								<tr key={refNum}>
									<td>{refNum}</td>
									<td>{componentTypeDesc}</td>
									<td>{description}</td>
									<td className="tw-text-center">{poQuantity}</td>
									<td className="tw-text-right">{poCost}</td>
									<td className="tw-text-right">{formatNumber(costToDate)}</td>
									<td className="tw-text-center">{quanpaidfor}</td>
									<td className="tw-text-right">{formatNumber(totalCost)}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		</>
	);
};

VendorInvoiceDetailProjectPO.displayName = 'VendorInvoiceDetailProjectPO';

export { VendorInvoiceDetailProjectPO };
