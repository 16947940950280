import React from 'react';
import { Col, Row, Form, Button, Container } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../lib/api/HttpService';

import { toSortName } from '../../../utilities/String';
import { isNumeric } from '../../../helpers/Util';
import { setDraft } from '../../../utilities/FormEvent';
import InputMasked from '../../../app/components/form/MaskedInput';
import { displayAlertError } from '../../../utilities/Response';
import AsyncClientsDropdown from '../../../app/components/dropdowns/AsyncClientsDropdown';
import AsyncEmployeesDropdown from '../../../app/components/dropdowns/AsyncEmployeesDropdown';
import AsyncSalesTaxCodesDropdown from '../../../app/components/dropdowns/AsyncSalesTaxCodesDropdown';
import AsyncAddressesDropdown from '../../../app/components/dropdowns/AsyncAddressesDropdown';

class ProjectAddInfo extends ThreadComponent {
	constructor(props) {
		super(props);

		this.api = new ApiService();
		this.updatedData = props.updatedData;

		this.state = {
			data: {
				billingAddress: {},
			},
			selectedClient: null,
			dataLoaded: false,
			startDate: new Date(),
			sitesameasbill: true,
			shipTo: null,
			useSite: true,
			shipToOptions: [],
			sortType: { value: 2, label: 'Manual' },
			showModal: false,
			clientNameNew: '',
			shiptoQuery: '?$filter=addresstype eq 1',
		};
		this.api = new ApiService();
		this.sortTypes = [
			{ value: 0, label: 'Name' },
			{ value: 1, label: 'Company' },
			{ value: 2, label: 'Manual' },
		];
	}

	componentInit() {
		this.hasFormAction = true;
	}

	async componentDidMount() {
		if (Object.keys(this.props.data).length > 0) {
			this.setState({
				data: this.props.data || {},
			});
		}
		const company = await this.api.getCompany2();
		const addresses = await this.api.getAddresses(
			'?$filter=addresstype eq 1 and inactive eq false'
		);
		const shipToAddresses = addresses
			.filter((address) => {
				return address.addressTypeEnum === 'ProjectShipTo';
			})
			.map((address) => {
				return {
					value: address.code,
					label: `${address.attention} [${address.code}]`,
				};
			});

		let defaultShipTo = shipToAddresses.filter((address) => {
			return address.value === company.defshipto;
		})[0];

		if (this.updatedData && this.updatedData.shipto) {
			defaultShipTo = shipToAddresses.filter((address) => {
				return address.value === this.updatedData.shipto;
			})[0];
		}

		if (defaultShipTo) {
			this.props.onDataChange({
				key: 'shipto',
				value: defaultShipTo.value,
			});
		}

		this.setState({
			shipto: defaultShipTo?.value,
			shipToOptions: shipToAddresses,
		});
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			this.setupSalesTaxCode();

			this.setState({
				data: this.props.data || {},
				dataIsLoaded: true,
			});
		}
	}

	setupSalesTaxCode() {
		if (this.props.data.selectedTaxCode) {
			const tx = this.props.data.selectedTaxCode;

			this.updatedData.taxc = tx?.taxc ?? this.updatedData.taxc;

			tx && !tx.inactive
				? (this.updatedData.selectedTaxCode = {
						label: `${tx?.taxcn ? tx?.taxcn : ''} [${tx?.taxc}]`,
						value: tx?.taxcn,
				  })
				: null;
		}
	}

	handleChange = (e, meta = {}) => {
		if (!e) {
			return;
		}

		let data = this.state.data;

		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;

			this.setState({
				data: {
					...this.state.data,
					[key]: value,
				},
			});

			this.updatedData[key] = value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;

				this.setState({
					data: {
						...this.state.data,
						[key]: value,
					},
					useSite: value,
				});

				this.updatedData.useSite = value;
			}
		}

		if (key === 'sitesameasbill') {
			data.sitesameasbill = value;
			this.updatedData.sitesameasbill = value;
			this.setState({
				sitesameasbill: value,
			});
		} else if (key === 'manager') {
			this.props.onDataChange({ key: 'selectedManager', value: e });
			this.updatedData.selectedManager = e;
		} else if (key === 'taxc') {
			this.props.onDataChange({ key: 'selectedTaxCode', value: e });
			this.updatedData.selectedTaxCode = e;
		}

		// Check if billing address
		const isBillingAdd = (e.target?.id || '').indexOf('billingAddress') > -1;
		const isSiteAdd = (e.target?.id || '').indexOf('siteAddress') > -1;
		if (isBillingAdd || isSiteAdd) {
			const _key = e.target.id.split('.')[1];
			if (isBillingAdd) {
				data = this.state.data.billingAddress
					? this.state.data
					: { ...data, billingAddress: {} };
				data.billingAddress[_key] = value;
			} else {
				data = this.state.data.siteAddress
					? this.state.data
					: { ...data, siteAddress: {} };
				data.siteAddress[_key] = value;
			}
		}

		this.setState({ data });

		this.props.onDataChange({ key: key, value: value });

		if (!this.updatedData.hasOwnProperty('dateopened')) {
			const dateopened =
				this.state.startDate.getFullYear() +
				'-' +
				('0' + (this.state.startDate.getMonth() + 1)).slice(-2) +
				'-' +
				('0' + this.state.startDate.getDate()).slice(-2) +
				'T00:00:00';
			this.props.onDataChange({ key: 'dateopened', value: dateopened });
		}

		setDraft(true);

		return { key: key, value: value };
	};

	findProjectCode(takenCodes) {
		takenCodes = takenCodes.map((item) => parseInt(item, 10));
		takenCodes.sort((a, b) => a - b);
		let nextId = 1;

		for (const num of takenCodes) {
			if (num === nextId) {
				nextId++;
			} else if (num > nextId) {
				return nextId;
			}
		}

		return Math.max(...takenCodes) + 1;
	}

	async generateProjectCode(client) {
		const projectsResponse = await this.api.getProjects(
			`?$filter=startswith(proj, '${client.slice(0, 3)}')`
		);

		const codes = projectsResponse
			.map((project) => {
				const code = project.proj.slice(3);
				return code.charAt(0) === '0' ? code.slice(1) : code;
			})
			.filter((code) => isNumeric(code))
			.sort();

		if (codes.length === 0) {
			return '01';
		}

		const code = this.findProjectCode(codes);

		return code < 10 ? `0${code}` : code;
	}

	handleClientChange = async (e) => {
		this.updatedData.client = e.value;
		this.props.onDataChange({ key: 'selectedClientInvoice', value: null });
		this.props.onDataChange({ key: 'clientinvterms', value: null });

		const [client] = await this.api.getClients(
			`?$filter=client eq '${e.value}' and inactive eq false`
		);

		if (!client) {
			displayAlertError("Can't find the selected client!");
			return;
		}

		this.dMloader(true);

		const projectCode = await this.generateProjectCode(e.value);
		const proj = `${e.value.slice(0, 3)}${projectCode}`;
		const manager = await this.api.getClientEmployeeByCode(client.manager);
		const selectedManager = manager
			? {
					label: `${manager.vendorn} [${manager.vendor}]`,
					value: client.manager,
			  }
			: null;

		this.updatedData.manager = client.manager;

		this.updatedData.taxc = client.deftaxc
			? client.deftaxc
			: this.updatedData.selectedTaxCode;

		const tx = await this.api.getSalesTaxCodeByCode(this.updatedData.taxc);

		if (tx && !tx.inactive) {
			this.updatedData.selectedTaxCode = {
				label: `${tx?.taxcn ? tx?.taxcn : ''} [${tx?.taxc}]`,
				value: tx?.taxcn,
			};
			this.updatedData.taxd = tx.td ?? false;
			this.updatedData.taxf = tx.tf ?? false;
			this.updatedData.taxi = tx.ti ?? false;
			this.updatedData.taxl = tx.tl ?? false;
			this.updatedData.taxm = tx.tm ?? false;
			this.updatedData.taxo = tx.to ?? false;
		}

		this.updatedData.clientinvterms = client.defclientinvterms;
		this.updatedData.useSite = this.state.useSite;

		this.props.onDataChange({
			key: 'selectedManager',
			value: selectedManager,
		});
		this.updatedData.selectedManager = selectedManager;

		for (const manager of this.state.data.managers) {
			if (client.manager === manager.value) {
				this.updatedData.designer = manager.designer;
				break;
			}
		}

		const billingAddressesResponse = await this.api.getAddresses(
			`?$filter=code eq '${client.client}'`
		);

		this.dMloader(false);
		let billingAddress = [];
		if (billingAddressesResponse.length) {
			billingAddress = billingAddressesResponse.map((address) => {
				return {
					city: address.city,
					contact: address.contact,
					contactphone: address.contactphone,
					addr: address.addr,
					state: address.state,
					zip: address.zip,
					fax: address.fax,
					email: address.email,
					website: address.website,
					phonedesc1: address.phonedesc1,
					phone1: address.phone1,
					phonedesc2: address.phonedesc2,
					phone2: address.phone2,
				};
			})[billingAddressesResponse.length - 1];
		}

		this.updatedData = {
			...this.updatedData,
			proj: proj,
			billingAddress: {
				...this.updatedData.billingAddress,
				...billingAddress,
			},
		};

		this.setState({
			data: {
				...this.state.data,
				proj: proj,
				billingAddress: {
					...this.state.data.billingAddress,
					...billingAddress,
				},
			},
		});

		this.updatedData.selectedClientInvoice = client.defclientinvterms;
		this.updatedData.selectedClient = e;

		setDraft(true);
		this.props.onDataChange(this.updatedData);
	};

	handleDateChange = (e) => {
		const newDate = e ? new Date(e) : new Date();

		let startDate =
			newDate.getFullYear() +
			'-' +
			('0' + (newDate.getMonth() + 1)).slice(-2) +
			'-' +
			('0' + newDate.getDate()).slice(-2) +
			'T00:00:00';

		this.setState({
			startDate: newDate,
		});
		this.props.onDataChange({ key: 'dateopened', value: startDate });

		setDraft(true);
	};

	handleSortChange = (e, meta = {}) => {
		const keyValue = this.handleChange(e, meta);

		this.setState({
			sortType: this.sortTypes.filter((type) => {
				return type.value === keyValue.value;
			})[0],
		});

		const contact = document.getElementById('siteAddress.contact');
		const sortName = document.getElementById('siteAddress.sortname');

		if (keyValue.value === 0) {
			sortName.value = toSortName(contact.value);
		} else {
			sortName.value = contact.value ?? '';
		}
	};

	render() {
		const { data, showModal } = this.state;
		return (
			<>
				<Container className="ms-0" data-testid="add-project-info-form">
					<Row xs={1} lg={2}>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-0 mb-lg-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Client*</Form.Label>
								</Col>
								<Col>
									<AsyncClientsDropdown
										name="client"
										className="react-select"
										id="project-client-input"
										placeholder="Select client"
										onChange={this.handleClientChange}
										defaultValue={this.updatedData.selectedClient}
										creatable={true}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-0 mb-lg-5">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Project code*</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={5}
										placeholder="Project Code"
										data-testid="project-code-input"
										id="proj"
										defaultValue={this.updatedData?.proj ?? data?.proj}
										onChange={(e) => {
											let updatedEvent = e;
											updatedEvent.target.value = e.target.value
												.substring(0, 5)
												.toUpperCase();
											this.handleChange(updatedEvent);
										}}
									/>
								</Col>
							</Row>
							<Row className="align-items-center">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Project Name*</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={40}
										placeholder="Project Name"
										data-testid="project-name-input"
										id="projn"
										onChange={this.handleChange}
										defaultValue={this.updatedData?.projn ?? data?.projn}
										autoComplete="off"
									/>
								</Col>
							</Row>
						</Col>
						<Col className="mb-2 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Opened*</Form.Label>
								</Col>
								<Col>
									<DatePicker
										selected={this.state.startDate}
										id="dateopened"
										onChange={this.handleDateChange}
										defaultValue={
											this.updatedData?.dateopened ?? data?.dateopened
										}
										className="form-control"
										placeholderText="Opened"
										popperPlacement="auto"
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Client Order #</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={16}
										placeholder="Client Order #"
										id="clientorder"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.clientorder ?? data?.clientorder
										}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col className="mb-2 mb-lg-0">
							<Row className="mb-lg-3">
								<Col lg={3} className="text-start">
									<Form.Label className="mb-0">
										<strong>Billing Address</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-0 mb-lg-5">
						<Col className="">
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0 mt-1">
									<Form.Label className="mb-0">Address</Form.Label>
								</Col>
								<Col>
									<Form.Control
										as="textarea"
										maxLength={255}
										placeholder="Address"
										rows={3}
										id="billingAddress.addr"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.billingAddress?.addr ??
											data?.billingAddress?.addr
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">City</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={30}
										placeholder="City"
										id="billingAddress.city"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.billingAddress?.city ??
											data?.billingAddress?.city
										}
									/>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">State</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col>
											<Form.Control
												maxLength={2}
												type="text"
												placeholder="State"
												id="billingAddress.state"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.billingAddress?.state ??
													data?.billingAddress?.state
												}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Zip</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col>
											<Form.Control
												type="text"
												maxLength={20}
												placeholder="Zip"
												id="billingAddress.zip"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.billingAddress?.zip ??
													data?.billingAddress?.zip
												}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col>
							<Row className="mb-3">
								<Col lg={4} className="text-start">
									<Form.Label className="mb-0">
										<strong>Contact Information</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-1 mb-lg-5">
						<Col>
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Contact</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={40}
										placeholder="Contact"
										id="billingAddress.contact"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.billingAddress?.contact ??
											data?.billingAddress?.contact
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone</Form.Label>
								</Col>
								<Col>
									<InputMasked
										type="text"
										placeholder="Phone"
										id="billingAddress.contactphone"
										value={
											this.updatedData?.billingAddress?.contactphone ??
											data?.billingAddress?.contactphone
										}
										onChange={this.handleChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Fax</Form.Label>
								</Col>
								<Col>
									<InputMasked
										type="text"
										placeholder="Fax"
										id="billingAddress.fax"
										onChange={this.handleChange}
										value={
											this.updatedData?.billingAddress?.fax ??
											data?.billingAddress?.fax
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Email</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={255}
										placeholder="Email"
										id="billingAddress.email"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.billingAddress?.email ??
											data?.billingAddress?.email
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Website</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={50}
										placeholder="Website"
										id="billingAddress.website"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.billingAddress?.website ??
											data?.billingAddress?.website
										}
									/>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 2</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col className="mb-2 mb-lg-0">
											<Form.Control
												type="text"
												maxLength={40}
												placeholder="Phone 2 Description"
												id="billingAddress.phonedesc1"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.billingAddress?.phonedesc1 ??
													data?.billingAddress?.phonedesc1
												}
											/>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Phone 2 Number"
												id="billingAddress.phone1"
												onChange={this.handleChange}
												value={data?.billingAddress?.phone1}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 3</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col className="mb-2 mb-lg-0">
											<Form.Control
												type="text"
												maxLength={40}
												placeholder="Phone 3 Description"
												id="billingAddress.phonedesc2"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.billingAddress?.phonedesc2 ??
													data?.billingAddress?.phonedesc2
												}
											/>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Phone 2 Number"
												id="billingAddress.phone2"
												onChange={this.handleChange}
												value={data?.billingAddress?.phone2}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Sales Tax Code*</Form.Label>
								</Col>
								<Col>
									<AsyncSalesTaxCodesDropdown
										className="react-select"
										id="project-sales-tax-code-input"
										placeholder="Select Sales Tax Code"
										name="taxc"
										onChange={this.handleChange}
										value={this.updatedData.selectedTaxCode}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										Salesperson / Manager
									</Form.Label>
								</Col>
								<Col>
									<AsyncEmployeesDropdown
										className="react-select"
										placeholder="Select Salesperson / Manager"
										name="manager"
										onChange={this.handleChange}
										defaultValue={
											// this.updatedData.selectedManager ??
											this.updatedData.selectedManager
										}
										isClearable={true}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col>
							<Row className="mb-0 mb-lg-3">
								<Col lg={4} className="text-start">
									<Form.Label className="mb-0">
										<strong>Site Address</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="">
						<Col>
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-lg-0">
									<Form.Label className="mb-0"></Form.Label>
								</Col>
								<Col>
									<Form.Check
										inline
										label="Use billing address as site address"
										type="checkbox"
										id="sitesameasbill"
										onClick={this.handleChange}
										defaultChecked={
											this.updatedData?.sitesameasbill ??
											data?.sitesameasbill ??
											this.state.sitesameasbill
										}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row
						xs={1}
						lg={2}
						className={
							this.updatedData?.sitesameasbill ||
							data?.sitesameasbill ||
							this.state.sitesameasbill
								? 'd-none'
								: 'mb-0 mb-lg-5'
						}
					>
						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Attention</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={40}
										placeholder="Attention"
										id="siteAddress.attention"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.siteAddress?.attention ??
											data?.siteAddress?.attention
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-4 mb-lg-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Sort</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={40}
										placeholder="Sort"
										id="siteAddress.sortname"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.siteAddress?.sortname ??
											data?.siteAddress?.sortname
										}
										readOnly={
											this.state.sortType.value === 0 ||
											this.state.sortType.value === 1
										}
									/>
								</Col>
								<Col>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										options={this.sortTypes}
										defaultValue={this.state.sortType}
										className="react-select"
										placeholder="Please select"
										onChange={this.handleSortChange}
										name="parsetype"
									/>
								</Col>
							</Row>
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0 mt-1">
									<Form.Label className="mb-0">Address</Form.Label>
								</Col>
								<Col>
									<Form.Control
										as="textarea"
										maxLength={255}
										placeholder="Address"
										rows={3}
										id="siteAddress.addr"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.siteAddress?.addr ??
											data?.siteAddress?.addr
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">City</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={30}
										placeholder="City"
										id="siteAddress.city"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.siteAddress?.city ??
											data?.siteAddress?.city
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">State</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col>
											<Form.Control
												maxLength={2}
												type="text"
												placeholder="State"
												id="siteAddress.state"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.siteAddress?.state ??
													data?.siteAddress?.state
												}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-0 mb-lg-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Zip</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col>
											<Form.Control
												type="text"
												maxLength={20}
												placeholder="Zip"
												id="siteAddress.zip"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.siteAddress?.zip ??
													data?.siteAddress?.zip
												}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-first mb-0 mb-lg-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0"></Form.Label>
								</Col>
							</Row>
							<Row className="align-items-center mb-2">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										<strong>Contact Information</strong>
									</Form.Label>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Contact</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={40}
										placeholder="Contact"
										id="siteAddress.contact"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.siteAddress?.contact ??
											data?.siteAddress?.contact
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone</Form.Label>
								</Col>
								<Col>
									<InputMasked
										type="text"
										placeholder="Phone"
										id="siteAddress.contactphone"
										onChange={this.handleChange}
										value={data?.siteAddress?.contactphone}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Fax</Form.Label>
								</Col>
								<Col>
									<InputMasked
										type="text"
										placeholder="Fax"
										id="siteAddress.fax"
										onChange={this.handleChange}
										value={data?.siteAddress?.fax}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Email</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={255}
										placeholder="Email"
										id="siteAddress.email"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.siteAddress?.email ??
											data?.siteAddress?.email
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 2</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col className="mb-2 mb-lg-0">
											<Form.Control
												type="text"
												maxLength={40}
												placeholder="Phone 2 Description"
												id="siteAddress.phonedesc1"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.siteAddress?.phonedesc1 ??
													data?.siteAddress?.phonedesc1
												}
											/>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Phone 2 Number"
												id="siteAddress.phone1"
												onChange={this.handleChange}
												value={data?.siteAddress?.phone1}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 3</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col className="mb-2 mb-lg-0">
											<Form.Control
												type="text"
												maxLength={40}
												placeholder="Phone 3 Description"
												id="siteAddress.phonedesc2"
												onChange={this.handleChange}
												defaultValue={
													this.updatedData?.siteAddress?.phonedesc2 ??
													data?.siteAddress?.phonedesc2
												}
											/>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Phone 3 Number"
												id="siteAddress.phone2"
												onChange={this.handleChange}
												value={data?.siteAddress?.phone2}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col>
							<Row className="mb-3">
								<Col lg={4} className="text-start">
									<Form.Label className="mb-0">
										<strong>Shipping</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-1 mb-lg-5">
						<Col>
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Ship to</Form.Label>
								</Col>
								<Col>
									<Form.Check
										inline
										label="Use site"
										name="group4"
										type="checkbox"
										id="useSite"
										onClick={this.handleChange}
										defaultChecked={
											this.updatedData?.useSite ?? this.state.useSite
										}
									/>
								</Col>
							</Row>

							<Row className="align-items-first mb-3">
								<Col
									lg={3}
									className="text-lg-end mb-2 mb-lg-0 d-none d-lg-block"
								></Col>
								<Col>
									<AsyncAddressesDropdown
										name="shipto"
										defaultValue={
											this.state.shipToOptions.filter((address) => {
												return (
													address.value ===
													(this.updatedData?.shipto ?? this.state.data.shipto)
												);
											})[0]
										}
										isDisabled={!!this.updatedData.useSite}
										className="react-select"
										placeholder="Select Shipping"
										onChange={this.handleChange}
										urlQuery={this.state.shiptoQuery}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default WithRouter(ProjectAddInfo);
