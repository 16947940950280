import { useFeature } from 'use-feature';
import { FeatureFlags } from '../app/enums/featureFlags/featureFlags';
import { FC } from 'react';

export const renderBooleanToYesOrNo = (value: boolean): string => {
	return value ? 'Yes' : 'No';
};

export const FeatureFlagComponentResolver: FC<{
	featureFlag: FeatureFlags;
	componentEnabled: JSX.Element;
	componentDisabled: JSX.Element;
}> = ({ featureFlag, componentEnabled, componentDisabled }) => {
	const isReportingEnabled = useFeature(featureFlag);

	return isReportingEnabled ? componentEnabled : componentDisabled;
};
