/**
 * Polyfill browser's Promise
 * Author: JayFastFwd
 */
export default class XPromise {
	onResolve: any;
	onReject: any;
	isFulfilled = false;
	isRejected = false;
	isCalled = false;
	value: any;
	error: any;

	constructor(executor: any) {
		executor(this.resolve.bind(this), this.reject.bind(this));

		return this;
	}

	resolve(val: any) {
		this.isFulfilled = true;
		this.value = val;

		if (typeof this.onResolve === 'function' && !this.isCalled) {
			this.onResolve(val);
			this.isCalled = true;
		}
	}

	reject(err: any) {
		this.isRejected = true;
		this.error = err;

		if (typeof this.onReject === 'function' && !this.isCalled) {
			this.onReject(err);
			this.isCalled = true;
		}
	}

	then(thenHandler: any) {
		this.onResolve = thenHandler;

		if (!this.isCalled && this.isFulfilled) {
			this.onResolve(this.value);
			this.isCalled = true;
		}

		this;
	}

	catch(catchHandler: any) {
		this.onReject = catchHandler;

		if (!this.isCalled && this.isRejected) {
			this.onReject(this.error);
			this.isCalled = true;
		}

		this;
	}

	static resolve(val: any) {
		return new XPromise((resolve: any, reject: any) => {
			resolve(val);
		});
	}

	static reject(reason: any) {
		return new XPromise((resolve: any, reject: any) => {
			reject(reason);
		});
	}

	static all(promises: any[]) {
		return new XPromise((resolve: any, reject: any) => {
			let cnt = 0;
			const res: any = [];
			if (promises.length === 0) {
				resolve(promises);
				return;
			}

			for (let i = 0; i < promises.length; i++) {
				promises[i]
					.then((val: any) => {
						done(val, i);
					})
					.catch((err: any) => reject(err));
			}

			function done(val: any, i: number) {
				res[i] = val;
				++cnt;

				if (promises.length === cnt) {
					resolve(res);
				}
			}
		});
	}
}
