const ICON = {
	a: {
		show: 'ri-eye-fill',
		delete: 'ri-delete-bin-5-line',
		report: 'ri-file-chart-line',
		listu: 'ri-list-unordered',
		listo: 'ri-list-ordered',
		post: 'ri-upload-fill',
		void: 'ri-eye-off-line',
	},
};

export default ICON;
