import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { CreateComponentPayload } from '../types/CreateComponentPayload';
import { Component } from '../types/Component';
import { displayAlert } from 'legacy/utilities/Response';
import { UserError } from '../types/UserError';
import { AxiosError } from 'axios';
import getDescriptionForSubComponent from 'legacy/utilities/getDescriptionForSubComponent';
import useGetCompanyInfo from './useGetCompanyInfo';
import { Company } from '../types/Company';
import normalizeRecalculateDataResponse from 'legacy/app/pages/Component/normalizeRecalculateDataResponse';
import { TWhatChangedComponent } from '../types/WhatChangedComponent';
import normalizeRecalculateDataPayload from 'legacy/app/pages/Component/utils/normalizeRecalculateDataPayload';
import normaliseComponentPayload from 'legacy/utilities/normalizeComponentPayload';

const api = new ApiService();

type UseCreateComponentsPayload = {
	createComponentPayload: CreateComponentPayload;
	updatePriceAndCost?: boolean;
	addDefaultDescription?: boolean;
	unitMeasure?: string;
	supplier?: string | null;
	createAutoComponents?: boolean;
};

const useCreateComponent = (
	projectId: number,
	options?: UseMutationOptions<
		Component,
		unknown,
		UseCreateComponentsPayload,
		unknown
	>
) => {
	const { data: company } = useGetCompanyInfo();
	return useMutation({
		mutationFn: async ({
			createComponentPayload,
			supplier,
			unitMeasure,
			createAutoComponents,
			addDefaultDescription,
		}: UseCreateComponentsPayload) => {
			let newComponent = (await api.createComponent(
				createComponentPayload
			)) as Component;

			let compData = {
				...newComponent,
				unitmeasure: unitMeasure || 'each',
				supplier: null,
			} as Component;

			if (supplier) {
				const recalculatedData = await api.componentRecalculate(
					newComponent.id,
					{
						...normalizeRecalculateDataPayload({
							...newComponent,
							supplier: supplier as string,
						}),
						WhatChanged: TWhatChangedComponent.cwcVendor,
					}
				);
				const normalizedRecalculateData =
					normalizeRecalculateDataResponse(recalculatedData);

				compData = {
					...compData,
					supplier,
					supdep: normalizedRecalculateData.supDep,
					bterm1: normalizedRecalculateData.bTerm1,
					bterm2: normalizedRecalculateData.bTerm2,
					bterm3: normalizedRecalculateData.bTerm3,
					comptype: normalizedRecalculateData.compType,
					markup: normalizedRecalculateData.markup,
					useterms: normalizedRecalculateData.useTerms,
				};
			}

			if (addDefaultDescription) {
				// Using this function because the subcomp description is the default description for a component
				compData = {
					...compData,
					...getDescriptionForSubComponent(
						createComponentPayload.associatedComponentNumber as string,
						newComponent,
						company as Company
					),
				};
			}

			newComponent = (await api
				.editComponent(newComponent.id, normaliseComponentPayload(compData))
				.catch((error: AxiosError<UserError>) => {
					displayAlert('danger', error?.response?.data?.userError);
				})) as Component;

			if (createAutoComponents) {
				const subcomps = (await api.get(
					'components',
					`?$filter=projectid eq ${projectId} and itemid eq ${createComponentPayload.itemId} and assocwithcomp eq '${newComponent.comp}' and comp ne '${newComponent.comp}'`
				)) as Component[];
				await Promise.all(
					subcomps.map(async (subcomp) => {
						const compData = {
							unitmeasure: newComponent.unitmeasure || 'each',
							...getDescriptionForSubComponent(
								newComponent.comp,
								subcomp,
								company as Company
							),
						};
						await api
							.editComponent(subcomp.id, compData)
							.catch((error: AxiosError<UserError>) => {
								displayAlert('danger', error?.response?.data?.userError);
							});
					})
				);
			}

			return newComponent;
		},
		...(options || {}),
	});
};
export default useCreateComponent;
