import React from 'react';
import SummaryCard from './SummaryCard';
import useBusinessSummary from 'legacy/lib/api/hooks/useGetBusinessSummary';
import OpenProposalsIcon from '../../../assets/images/icons/Open-Proposals-Icon.svg';
import OpenInvoicesIcon from '../../../assets/images/icons/Open_Invoices-Icon.svg';
import OverdueInvoicesIcon from '../../../assets/images/icons/Calendar-Icon.svg';

const BusinessSummary = () => {
	const { data } = useBusinessSummary();
	return (
		<div className="tw-bg-[#006a53] tw-p-2 tw-rounded-lg">
			<h1 className="tw-text-white tw-text-2xl tw-mb-4">Business Summary</h1>
			<div className="tw-grid tw-grid-cols-2 tw-gap-4 md:tw-grid-cols-4 tw-gap-4">
				<SummaryCard
					iconPath={OpenProposalsIcon}
					count={data?.proposalCount}
					title="Open Proposals"
					amount={data?.totalProposed}
					iconAlt="proposals-icon"
					tooltip="Total price proposed for all active proposals that have not received a deposit"
				/>
				<SummaryCard
					iconPath={OpenInvoicesIcon}
					count={data?.countOpenInvoices}
					title="Open Invoices"
					amount={data?.totalOpenInvoices}
					iconAlt="invoices-icon"
					tooltip="All client invoices due (aged accounts receivable)"
				/>
				<SummaryCard
					iconPath={OverdueInvoicesIcon}
					count={data?.countOverdueInvoices}
					title="Overdue Invoices"
					amount={data?.totalOverdueInvoices}
					iconAlt="overdue-icon"
					tooltip="Client invoices past due over 30 days"
				/>
				<SummaryCard
					iconPath={OverdueInvoicesIcon}
					count={data?.countOverdueBills}
					title="Overdue Bills"
					amount={data?.totalBills}
					iconAlt="bills-icon"
					tooltip="Vendor bills/deposits past due over 30 days (aged accounts payable)"
				/>
			</div>
		</div>
	);
};

BusinessSummary.displayName = 'BusinessSummary';

export default BusinessSummary;
