import { apiClient } from 'api/apiClient';

const RESOURCE = '/timeEntries';

export interface ITimeEntry {
	timeEmployee: string;
	timeProject: string;
	refnum: string;
	timeLocation: string;
	timeCategory: string;
	latestInvDt: string;
	txnum: number;
	proj: string;
	item: string;
	comp: string;
	employee: string;
	activity: string;
	loc: string;
	scat: string;
	taxable: boolean;
	nonbillable: boolean;
	desc: string;
	descrtf: string;
	entrydate: string;
	hours: number;
	rateperhour: number;
	rate: number;
	costperhour: number;
	cost: number;
	newitem: boolean;
	hastimedata: boolean;
	startdatetime: string;
	enddatetime: string;
}

export const getTimeEntries = async (
	filter?: string
): Promise<ITimeEntry[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());
	return data;
};
