import { useMutation } from '@tanstack/react-query';
import {
	postTemporaryReconciliationsStart,
	type ITemporaryReconciliationsStartParams,
} from 'api/resources/temporaryReconciliations';

export const usePostTemporaryReconciliationsStart = () => {
	return useMutation((params: ITemporaryReconciliationsStartParams) =>
		postTemporaryReconciliationsStart(params)
	);
};
