import React from 'react';
import { Link, NavLink, generatePath } from 'react-router-dom';
import {
	Container,
	Breadcrumb,
	Button,
	Table,
	Form,
	Col,
	Row,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import ListFilter from '../../../components/ListFilter';
import { FooterFormAction } from '../../../components/Form';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../../lib/api/HttpService';
import ProjectVendorOrdersAddPurchaseOrderAddComponentModal from './ProjectVendorOrdersAddPurchaseOrderAddComponentModal';
import ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal from './ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import {
	FormEvent,
	setDraft,
	setLocalStorageKeyValueObject,
} from '../../../../utilities/FormEvent';
import {
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	getErrorMessage,
} from '../../../../utilities/Response';
import dayjs from 'dayjs';
import noItems from '../../../../assets/images/icons/item.svg';
import { currencyFormat } from '../../../../helpers/Number';
import { getSaveButtonName } from '../../../../utilities/String';
import HandleDeleteWorker from '../../../../utilities/DeleteWorker';
import DeleteModal from '../../../../app/components/modal/DeleteModal';
import _, { sortBy } from 'underscore';
import {
	sortLocalData,
	tableSortingEnableSort,
	tableSortingSetData,
} from '../../../../utilities/modules/TableSorting';
import { debounce, delay, isEmpty, toLower } from 'lodash';
import Select from 'react-select';
import { getComponentName } from '../../../../utilities/ComponentTypes';
import { Event } from '../../../../utilities/DOM';
import { tableSearch } from '../../../../utilities/modules/TableSearch';
import { AlertLoader } from '../../../../app/components/bootstrap/BAlerts';
import MSG from '../../../../defaults/Message';
import { isFiltered } from '../../../../helpers/Util';
import FilterSort from '../../../../utilities/modules/FilterSort';
import { ErrorObjects } from '../../../../defaults/Error';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton';
import SecureLink from '../../../../app/components/security/SecureLink';

class ProjectVendorOrdersAddWorkOrder extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('project_vpwoorder_add_item');
		this.fs.setDefaultSort('refNum asc');
		this.state = {
			checks: [],
			data: { components: [], allComponents: [] },
			dataIsLoaded: false,
			imagesLoaded: true,
			projectComponents: [],
			project: {},
			showComponentModal: false,
			showPOModal: false,
			showModal: false,
			unselectedComponents: [],
			selectedComponents: [],
			isLoading: false,
			changeOrder: true,
			showTableSearch: false,
			page: 1,
			pageSize: { value: 10, label: '10' },
			searchProperties: {},
			sortProperty: '',
			isShowAll: false,
		};

		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];

		this.api = new ApiService();
		this.formEvent = new FormEvent(`purchase_order_edit`);
		this.isEditing = false;
		this.objName = 'Project Purchase Order';
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
		this.searchHandler = debounce(this.handleDoSearch.bind(this, 1), 200);
		this.setComponentProp();
	}

	setComponentProp() {
		this.saveSuccessMessage = this.isEditing
			? MSG.success.msg
			: MSG.success.create.ProjectPO;
		this.saveLoadingMessage = this.isEditing
			? MSG.loading.update.msg
			: MSG.loading.create.ProjectPO;
	}

	componentInit() {
		this.setFormAction(true);
		this.setTitle(
			this.props.params.orderId
				? `Edit Work Order [${this.props.params.orderId}]`
				: 'Add Work Order'
		);
	}

	async componentDidMount() {
		try {
			window.enableSortTableModal = false;
			setLocalStorage('purchase_order_selected_item', [], true);
			this.isEditing = this.props.params.orderId != null;

			const projectId = this.props.params.id;
			const orderId = this.props.params.orderId;

			let projectFilter = `?$filter=id eq ${projectId}`;
			const project = await this.api.getProjects(projectFilter);

			if (isEmpty(project)) {
				throw ErrorObjects.Notfound;
			}

			this.setInitialData(project[0]);

			this.setState({
				project: project[0],
			});

			let purchaseOrder = { components: [] };
			let projectComponents = [];

			let orderComponents, componentFilter;
			if (typeof orderId !== 'undefined') {
				let filter = `?$filter=proj eq '${project[0].proj}' and ponum eq '${orderId}'`;
				purchaseOrder = await this.api.getProjectPurchaseOrders(filter);
				purchaseOrder = purchaseOrder[0];

				orderComponents = await this.api.getComponents(
					`?$filter=proj eq '${project[0].proj}' and latestwonum eq '${orderId}'&$orderby=completeVendor asc, refNum asc`
				);
				purchaseOrder.components = orderComponents;

				this.updateInitialData(purchaseOrder);

				let projectComponentFilter = `?$filter=proj eq '${project[0].proj}' and (comptype le 0 or workroom eq supplier) and createdbysinv eq false and createdbymisc eq false and workroom gt '!' and comptype ne 5`;
				// if (orderComponents.length > 0) {
				//     const { supplier, shipto } = orderComponents[0]
				//     projectComponentFilter += ` and supplier eq '${supplier}' and shipto eq '${shipto}'`
				// }
				// projectComponentFilter + `&$orderby=completeVendor asc, refNum asc`
				componentFilter = projectComponentFilter;
				projectComponents = await this.api.getComponents(
					projectComponentFilter
				);
			} else {
				let filter = `?$filter=proj eq '${project[0].proj}' and (comptype le 0 or workroom eq supplier) and createdbysinv eq false and createdbymisc eq false and workroom gt '!' and inactive eq false and completed eq false and comptype ne 5 and latestwodt eq null`;
				projectComponents = await this.api.getComponents(filter);
				componentFilter = filter;
			}

			this.setState(
				{
					dataIsLoaded: true,
					data: purchaseOrder,
					projectComponents,
					unselectedComponents: projectComponents,
					userAction: orderId && orderComponents?.length ? 'add' : '',
					componentFilter,
				},
				() => {
					this.paginateLocalData(
						sortLocalData(this, purchaseOrder.components ?? [])
					);
					this.loadImages();
				}
			);

			const fromQuickCreate = new URLSearchParams(window.location.search).get(
				'isQuickCreate'
			);

			if (fromQuickCreate) {
				setTimeout(async () => {
					const preData = getLocalStorage('preData', true);

					let compIds = [];
					if (preData !== null && preData.length) {
						for (const id of preData) {
							const components = await this.api.getComponents(
								`?$filter=itemid eq ${id} AND projectId eq ${this.props.params.id}&$orderby=comp asc`
							);

							const ids = components.map((comp) => {
								return comp.id;
							});

							compIds = compIds.concat(ids);
						}
						this.handleAddItem(compIds);
					}
				}, 2000);
			}
		} catch (error) {
			this.setIsFound(false).setError(error, true);
			this.setState({
				__updateAt: new Date(),
			});
		}
		this.setIsLoaded(true);
	}

	paginateLocalData = async (data, toReturn) => {
		const newData = (data ?? []).slice(
			(this.state.page - 1) * this.state.pageSize.value,
			this.state.page * this.state.pageSize.value
		);

		if (toReturn) {
			return newData;
		} else {
			let dataComponents = this.state.data;
			dataComponents.components = newData;
			dataComponents.allComponents = data;
			this.setState({
				data: dataComponents,
				processedData: data,
			});
		}
	};

	paginateSearchSortData = (dataArr) =>
		tableSearch({
			data: dataArr ?? this.state.data.allComponents,
			searchProps: this.state.searchProperties,
			callback: async (data) => {
				await this.paginateLocalData(sortLocalData(this, data));
			},
		});

	setInitialData(project) {
		const data = {
			curProj: project.proj,
			orderDt: dayjs(new Date()).format('YYYY-MM-DD'),
			itemCompNoArray: [],
			replacePONum: '',
			revdt: null,
			showPictures: true,
			isWO: true,
		};

		setLocalStorage(`purchase_order_edit`, data, true);
	}

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	updateInitialData(purchaseOrder) {
		const ids = purchaseOrder.components.map((c) => c.id);
		setLocalStorage('purchase_order_selected_item', ids, true);
		const items = purchaseOrder.components.map(
			(comp) => `${comp.item}/${comp.comp}`
		);

		const data = getLocalStorage(`purchase_order_edit`, true);

		const initialData = {
			...data,
			...purchaseOrder,
			itemCompNoArray: items,
			revdt: purchaseOrder.revdt ?? dayjs(new Date()).format('YYYY-MM-DD'),
		};

		setLocalStorage('purchase_order_edit', initialData, true);
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	async loadImages() {
		this.setState({
			imageIsLoaded: false,
		});

		const selectedIds = getLocalStorage('purchase_order_selected_item', true);
		this.updateComponents(selectedIds);

		let { data, projectComponents } = this.state;
		if (data.components && data.components.length) {
			for (let item of data.components) {
				let fileThumb = item.primaryImageId
					? await this.api.getFile(item.primaryImageId + '/thumb')
					: '';
				item.imageThumb = fileThumb;
				let file = item.primaryImageId
					? await this.api.getFile(item.primaryImageId)
					: '';
				item.image = file;
			}
		}

		if (projectComponents && projectComponents.length) {
			for (let item of projectComponents) {
				let fileThumb = item.primaryImageId
					? await this.api.getFile(item.primaryImageId + '/thumb')
					: '';
				item.imageThumb = fileThumb;
				let file = item.primaryImageId
					? await this.api.getFile(item.primaryImageId)
					: '';
				item.image = file;
			}
		}

		this.setState({
			dataIsLoaded: true,
			imageIsLoaded: true,
			imagesLoaded: true,
		});
	}

	getDateValue(key) {
		return this.state.data !== undefined &&
			this.state.data[key] !== null &&
			this.state.data[key] !== undefined
			? new Date(this.state.data[key])
			: new Date();
	}

	displayModalItems = (e) => {
		this.setState({
			showComponentModal: true,
		});

		e.preventDefault();
	};

	handleAddItem = (ids) => {
		this.setState(
			{
				showComponentModal: false,
				userAction: 'add',
			},
			() => {
				let selectedIds = getLocalStorage('purchase_order_selected_item', true);
				const localIds = selectedIds;
				selectedIds = [...new Set([...selectedIds, ...ids])];

				setLocalStorage('purchase_order_selected_item', selectedIds, true);
				this.setState(
					{
						enabledSortLevel: !localIds || !localIds?.length ? 0 : 2,
					},
					() => this.updateComponents(selectedIds)
				);
			}
		);
	};

	handleRemoveItem = (e) => {
		let selectedIds = getLocalStorage('purchase_order_selected_item', true);
		selectedIds = selectedIds.filter((id) => !this.state.checks.includes(id));
		setLocalStorage('purchase_order_selected_item', selectedIds, true);
		this.setState({
			totalChecked: 0,
			checks: [],
		});
		this.updateComponents(selectedIds);
	};

	handleSelectAllChecks = () => {
		let checks = [];
		if (this.state.checks.length !== this.state.data.components.length) {
			checks = this.state.data.components.map((item) => {
				return item.id;
			});
		}

		this.setState({
			checks: checks,
		});
	};

	handleCheck = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);

		this.setState({
			checks: checks,
		});
	};

	handleDateChange = (name, date) => {
		let { data } = this.state;
		data[name] = date;
		this.renderData(data);
		this.formEvent.handleDateChange('YYYY-MM-DD', name, date);
		setDraft('true');
	};

	updateComponents(ids) {
		this.setState({
			dataIsLoaded: false,
		});

		let selectedComponents = this.state.projectComponents.filter((comp) =>
			ids.includes(comp.id)
		);

		const sortProperty = this.state.sortProperty;
		if (sortProperty) {
			const sortPropertyKey = sortProperty.split(' ')[0];
			const isDescending = sortProperty.split(' ')[1] === 'desc';

			selectedComponents = _.sortBy(selectedComponents, sortPropertyKey);

			if (isDescending) {
				selectedComponents = selectedComponents.reverse();
			}
		}

		const unselectedComponents = this.state.projectComponents.filter(
			(comp) => !ids.includes(comp.id)
		);

		this.paginateSearchSortData(selectedComponents);

		const updatedChecks = this.state.checks.filter(
			(check) => !ids.includes(check)
		);

		this.setState({
			selectedComponents,
			dataIsLoaded: true,
			unselectedComponents: unselectedComponents,
			checks: updatedChecks,
		});
		// Important for sorting...
		tableSortingSetData(selectedComponents, 'wo_selected_main');

		const items = selectedComponents.map((comp) => `${comp.item}/${comp.comp}`);
		setLocalStorageKeyValueObject(
			'purchase_order_edit',
			'itemCompNoArray',
			items
		);

		if (!this.state.enabledSortLevel && this.state.userAction === 'add') {
			delay(() => {
				this.enableSortTable();
				this.setState({
					enabledSortLevel: 2,
				});
			}, 1000);
		}
	}

	handleHeaderCheck = (e) => {
		const key = e.target.name;
		this.setState({
			[key]: e.target.checked,
		});
	};

	handleShowAll = async (e) => {
		this.setState({
			isShowAll: e.target.checked,
			dataIsLoaded: false,
		});

		let filter = this.state.componentFilter;
		if (e.target.checked) {
			//filter = `?$filter=proj eq '${this.state.project.proj}' and comptype ne 5 and createdbysinv eq false and createdbymisc eq false and stockno lt '!' and inactive eq false and completed eq false`
			filter = `?$filter=proj eq '${this.state.project.proj}' and (comptype le 0 or workroom eq supplier) and createdbysinv eq false and createdbymisc eq false and workroom gt '!' and inactive eq false and completed eq false and comptype ne 5`;
		}

		const components = await this.api.getComponents(filter);
		// Lets check if we have to remove the items that is hidden
		let selectedIds = getLocalStorage('purchase_order_selected_item', true);
		let newSelectedIds = [];
		if (selectedIds && selectedIds.length) {
			if (components?.length) {
				for (let id of selectedIds) {
					const citem = components.find((item) => item.id === id);
					if (!isEmpty(citem)) {
						newSelectedIds.push(id);
					}
				}
				setLocalStorage('purchase_order_selected_item', newSelectedIds, true);
			}
		}

		this.setState(
			{
				projectComponents: components,
				dataIsLoaded: true,
			},
			this.loadImages
		);
	};

	async handleTestDeleteObjects(e) {
		let workers = [];
		workers.push(
			await this.api.postJson('deleteobjects', {
				deleteType: 'dmriTestOnly',
				objectType: 'objPO',
				objectCodeOrId: this.state.data.id,
			})
		);
		if (workers) {
			HandleDeleteWorker(
				workers,
				{
					moduleSingular: this.objName,
					modulePlural: this.objName + 's',
					reference: 'Projects',
					objectType: 'objPO',
				},
				false,
				(modalState) => {
					this.setState(modalState);
				}
			);
		}
	}

	refreshDataAfterDelete = (e) => {
		this.hideModal();
		setTimeout(() => {
			window.onbeforeunload = null;
			window.location.href = getRouteWithParam(URI.project.viewPage, {
				id: this.props.params.id,
				page: 'vendor-orders',
			});
		}, 500);
	};

	hideModal = (e) => {
		this.setState({
			showModal: false,
		});
	};

	handleSave = async (e) => {
		setDraft('false');
		this.setIsLoading(true);
		displayAlertLoader(this.saveLoadingMessage);
		let purchaseOrder = getLocalStorage(`purchase_order_edit`, true);

		if (this.isEditing) {
			purchaseOrder.replacePONum = this.props.params.orderId;
			delete purchaseOrder.components;
			purchaseOrder.revdt = this.state.changeOrder ? purchaseOrder.revdt : null;
		}

		this.api
			.postJson('temporarypurchaseorders', purchaseOrder)
			.then((res) => {
				const preData = getLocalStorage('preData');
				if (preData !== null) {
					window.localStorage.removeItem('preData');
				}

				this.setIsLoading(false);
				displayAlertSuccess(this.saveSuccessMessage);
				this.setState({
					showPOModal: true,
				});
			})
			.catch((err) => {
				displayAlertError(getErrorMessage(err));
				this.setIsLoading(false);
			});
	};

	handleShowTableSearch = (e) => {
		this.setState(
			{
				showTableSearch: !this.state.showTableSearch,
			},
			() => {
				if (!this.state.showTableSearch) {
					Event('.a-table-search-fields.selected .form-control').value('');
					this.setState(
						{
							searchProperties: {},
						},
						this.paginateSearchSortData
					);
				}
			}
		);
	};

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.setState(
			{
				page,
			},
			this.paginateSearchSortData
		);
	};

	handleDoSearch = () =>
		tableSearch({
			data: this.state.selectedComponents,
			searchProps: this.state.searchProperties,
			callback: async (data) => {
				await this.paginateLocalData(sortLocalData(this, data));
			},
			customFilter: (item, key) => {
				if (key === 'comptype') {
					return getComponentName(item[key]);
				}
				return item[key];
			},
		});

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';
		const exp = e.target.attributes['placeholder']?.value ?? '>=';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value, type, exp },
				},
			},
			this.searchHandler
		);
	};

	enableSortTable = () =>
		tableSortingEnableSort({
			// data: this.state.data.components,
			classRef: this,
			targetTable: '.a-table-heading.main',
			dataKey: 'wo_selected_main',
			callback: (data, sort) => {
				tableSearch({
					data,
					searchProps: this.state.searchProperties,
					callback: async (data) => {
						this.paginateLocalData(data);
					},
				});

				this.setState({
					sortProperty: sort,
				});

				// Save sortProperty to localStorage
				this.fs.setSort(sort);
			},
			pattern: (key) => [
				(d) => {
					if (typeof d[key] === 'string') {
						return toLower(d[key]);
					}

					if (key === 'comptype') {
						return toLower(getComponentName(d[key]));
					}

					return d[key] ?? '';
				},
			],
		});

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	isNextDisabled() {
		const { processedData, page, pageSize } = this.state;
		const total = page * pageSize.value;

		return Number(processedData?.length) <= total;
	}

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	renderHeader() {
		const { isLoading } = this.state;
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={getRouteWithParam(URI.project.viewPage, {
							id: 1,
							page: 'proposal',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: this.props.params.id,
									page: 'items',
								}),
							}}
							linkAs={Link}
						>
							{this.state.project
								? `${this.state.project.projn} [${this.state.project.proj}]`
								: ''}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: this.props.params.id,
									page: 'vendor-orders',
								}),
							}}
							linkAs={Link}
						>
							Vendor Orders
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							{this.isEditing ? this.props.params.orderId : this.title}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.title}</HeaderLight.Title>
					<div>
						<Button
							as={Link}
							variant="ivory"
							name="cancel"
							to={getRouteWithParam(URI.project.viewPage, {
								id: this.props.params.id,
								page: 'vendor-orders',
							})}
							className="mx-3"
						>
							Cancel
						</Button>
						<SecureBootstrapButton
							attributeNo={18}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							name="save"
							onClick={this.handleSave.bind(this)}
							disabled={isLoading}
						>
							{getSaveButtonName(isLoading, 'Save')}
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderListFilter() {
		return (
			<>
				<ListFilter breakpoint={1280}>
					<ListFilter.Fields md={12} lg={8} xl={8} xxl={7} className="width-33">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Date</strong>
							</Form.Label>
							<div className="react-select-header">
								<DatePicker
									selected={this.getDateValue('orderdt')}
									minDate={this.state.minDate}
									onChange={this.handleDateChange.bind(this, 'orderdt')}
									showMonthDropdown="true"
									showYearDropdown="true"
									className="form-control form-control-sm"
									placeholderText="11/17/2020"
								/>
							</div>
						</ListFilter.Field>
						<ListFilter.Field>
							{this.isEditing && (
								<Form.Check
									inline
									label="Change Order"
									name="changeOrder"
									type="checkbox"
									id={`group-check-show-all`}
									className="ms-3"
									checked={this.state.changeOrder}
									onClick={this.handleHeaderCheck}
								/>
							)}
						</ListFilter.Field>
						{this.isEditing && this.state.changeOrder && (
							<ListFilter.Field>
								<Form.Label className="text-end">
									<strong>Revision Date</strong>
								</Form.Label>
								<div className="react-select-header">
									<DatePicker
										selected={this.getDateValue('revdt')}
										minDate={this.state.minDate}
										onChange={this.handleDateChange.bind(this, 'revdt')}
										showMonthDropdown="true"
										showYearDropdown="true"
										className="form-control form-control-sm"
										placeholderText="11/17/2020"
									/>
								</div>
							</ListFilter.Field>
						)}
					</ListFilter.Fields>
					<ListFilter.Actions md={12} lg={4} xl={4} xxl={5}>
						<ListFilter.Action>
							{this.state.selectedComponents.length > 0 && (
								<>
									<Button
										as={Link}
										to="#"
										variant="ivory"
										size="sm"
										className={`btn-icon btn-action ${
											this.state.showTableSearch
												? 'bg-primary-ash text-white'
												: ''
										}`}
										onClick={this.handleShowTableSearch}
									>
										<i className="ri-search-line"></i> Search
									</Button>
									<Button
										variant="ivory"
										size="sm"
										className="btn-icon ms-3"
										onClick={this.handleRemoveItem}
									>
										Remove Component
									</Button>
								</>
							)}
							<Button
								variant="primary"
								size="sm"
								className="btn-icon ms-3"
								onClick={this.displayModalItems.bind(this)}
							>
								Add Component
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderPagination() {
		return (
			<div className={'d-flex flex-wrap justify-content-center'}>
				<div className={'d-flex flex-row align-items-center pagination'}>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.state.page === 1}
						onClick={() => this.handleChangePage(this.state.page - 1)}
					>
						<i className="ri-arrow-left-s-line"></i> Previous
					</Button>
					<span className={'pagination-span'}>{this.state.page}</span>
					<Button
						variant="ivory"
						size="sm"
						className={'btn-icon pagination-btn'}
						disabled={this.isNextDisabled()}
						onClick={() => this.handleChangePage(this.state.page + 1)}
					>
						Next <i className="ri-arrow-right-s-line ms-1"></i>
					</Button>
				</div>
				<Select
					onChange={this.handleFilter('pageSize')}
					key={`${Math.floor(Math.random() * 1000)}-min`}
					options={this.availablePageSize}
					defaultValue={this.state.pageSize}
					className="react-select pagination-select mx-3"
					placeholder="Please select"
				/>
			</div>
		);
	}

	renderContent() {
		return (
			<>
				{!this.state.imageIsLoaded && (
					<Col sm={12}>
						<AlertLoader message={MSG.loading.images} />
					</Col>
				)}
				{this.state.data.components.length > 0 ||
				isFiltered(this.state.searchProperties) ||
				this.isEditing ? (
					<div className="table-gradient mb-5">
						<Table striped responsive className="a-table">
							<thead>
								<tr key="0" className="a-table-heading main">
									<th className="ps-4">
										<Form.Check
											inline
											label=""
											type="checkbox"
											defaultChecked={this.state.checks.length > 0}
											className={`${
												this.state.checks.length > 0 &&
												this.state.checks.length <
													this.state.data.components.length
													? 'line'
													: ''
											}`}
											onClick={this.handleSelectAllChecks}
										/>
									</th>

									<th>Image</th>
									<th className="mw-100px">
										<span
											className={this.sortClass('refNum')}
											data-field="refNum"
										>
											Ref No.
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('vendorName')}
											data-field="vendorName"
										>
											Workroom
										</span>
									</th>
									<th className="mw-180px">
										<span className={this.sortClass('desc')} data-field="desc">
											Description
										</span>
									</th>
									<th className="mw-110px">
										<span
											className={this.sortClass('quantity')}
											data-field="quantity"
										>
											Quantity
										</span>
									</th>
									<th className="mw-140px">
										<span
											className={this.sortClass('estcost')}
											data-field="estcost"
										>
											Estimated Cost
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('comptype')}
											data-field="comptype"
										>
											Type
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('completeLocation')}
											data-field="completeLocation"
										>
											Location
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('completeVendor')}
											data-field="completeVendor"
										>
											Ordered From
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('completeShipTo')}
											data-field="completeShipTo"
										>
											Ship To
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('catalog')}
											data-field="catalog"
										>
											Catalog No.
										</span>
									</th>
									<th>
										<span
											className={this.sortClass('latestProposalNo')}
											data-field="latestProposalNo"
										>
											Last Proposal No
										</span>
									</th>
								</tr>
								<tr
									className={`a-table-search-fields selected ${
										this.state.showTableSearch ? '' : 'd-none'
									}`}
								>
									<th></th>
									<th></th>
									<th>
										<Form.Control
											data-type="text"
											data-field="refNum"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="vendorName"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="desc"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<div className="position-relative">
											<Form.Control
												type="number"
												data-field="quantity"
												placeholder={this.state.expQty || '>='}
												data-type="number"
												onChange={this.handleSearch}
											/>
											<Button
												variant="Link"
												size="sm"
												style={{
													position: 'absolute',
													padding: 1,
													border: 'none',
													width: '18px',
													minWidth: '18px',
													right: 5,
													bottom: 0,
													opacity: 0.8,
												}}
												onClick={() => {
													const exp = this.state.expQty;
													const expQty =
														exp === '=' ? '>=' : exp === '>=' ? '<=' : '=';
													this.setState({
														expQty,
													});
												}}
											>
												<i className="ri-restart-fill ri-lg"></i>
											</Button>
										</div>
									</th>
									<th>
										<div className="position-relative">
											<Form.Control
												type="number"
												data-field="estcost"
												placeholder={this.state.expEstCost || '>='}
												data-type="number"
												onChange={this.handleSearch}
											/>
											<Button
												variant="Link"
												size="sm"
												style={{
													position: 'absolute',
													padding: 1,
													border: 'none',
													width: '18px',
													minWidth: '18px',
													right: 5,
													bottom: 0,
													opacity: 0.8,
												}}
												onClick={() => {
													const exp = this.state.expEstCost;
													const expEstCost =
														exp === '=' ? '>=' : exp === '>=' ? '<=' : '=';
													this.setState({
														expEstCost,
													});
												}}
											>
												<i className="ri-restart-fill ri-lg"></i>
											</Button>
										</div>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="comptype"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="completeLocation"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="completeVendor"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="completeShipTo"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="catalog"
											onChange={this.handleSearch}
										/>
									</th>
									<th>
										<Form.Control
											data-type="text"
											data-field="latestProposalNo"
											onChange={this.handleSearch}
										/>
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.data.components.map((item, i) => (
									<tr
										key={i}
										className={
											this.state.checks[i]
												? `active tr-loading-1`
												: 'tr-loading-1'
										}
									>
										<td className="ps-4">
											<Form.Check
												label=""
												type="checkbox"
												checked={this.state.checks.includes(item.id)}
												onChange={() => {
													this.handleCheck(item.id);
												}}
											/>
										</td>

										<td className="td-1">
											{item.imageThumb && this.state.imagesLoaded && (
												<img
													src={URL.createObjectURL(item.imageThumb)}
													alt={item.itemName}
													className="img-sm"
												/>
											)}
										</td>
										<td>
											<SecureLink
												attributeNo={14}
												attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
												to={generatePath(URI.project.newComponentEdit, {
													id: this.props.params.id,
													itemId: item.itemId,
													componentId: item.id,
												})}
												className="text-charcoal hover-view-icon"
											>
												{item.refNum}
											</SecureLink>
										</td>
										<td>{item.vendorName}</td>
										<td>{item.desc}</td>
										<td>{`${item.quantity}  ${item.unitmeasure ?? ''}`}</td>
										<td>{currencyFormat(item.estcost, '$')}</td>
										<td>{getComponentName(item.comptype)}</td>
										<td>{item.completeLocation}</td>
										<td>{item.completeVendor}</td>
										<td>{item.completeShipTo}</td>
										<td>{item.catalog}</td>
										<td>{item.latestProposalNo}</td>
									</tr>
								))}
							</tbody>
						</Table>
						{this.renderPagination()}
					</div>
				) : (
					<div className="row justify-content-center text-center py-5">
						<div className="col-md-3">
							<img src={noItems} className="mw-100 mb-4" alt="" />

							<h6>Add Components</h6>
							<p>
								Use the "Add Components" button to begin to associate components
								with this work order.
							</p>
							<Button
								as={Link}
								onClick={this.displayModalItems.bind(this)}
								variant="primary"
								size="md"
								className="btn-icon mt-4"
							>
								<i className="ri-add-line ri-lg"></i> Add Components
							</Button>
						</div>
					</div>
				)}
			</>
		);
	}

	render() {
		return this.renderView(
			<>
				{this.renderHeader()}

				<div className="content-padding min-height mh-270">
					<Container fluid>
						{this.renderListFilter()}
						{this.renderContent()}

						<ProjectVendorOrdersAddPurchaseOrderAddComponentModal
							data={this.state.unselectedComponents}
							addItemCall={this.handleAddItem.bind(this)}
							show={this.state.showComponentModal}
							hideModal={() =>
								this.setState((prev) => (prev.showComponentModal = false))
							}
							showAll={this.handleShowAll.bind(this)}
							isShowAll={this.state.isShowAll}
							dataIsLoaded={this.state.dataIsLoaded}
							orderType={'wo'}
							imageIsLoaded={this.state.imageIsLoaded}
						/>
						{this.state.showPOModal && (
							<ProjectVendorOrdersAddPurchaseOrderGeneratedPOModal
								show={this.state.showPOModal}
								hideModal={() =>
									this.setState((prev) => (prev.showPOModal = false))
								}
							/>
						)}
					</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Row className="w-100">
						<Col lg={4} className="text-left mb-3">
							<Button
								as={Link}
								onClick={this.handleTestDeleteObjects.bind(this)}
								variant="trans-light border-secondary-ash w-30"
								size="lg"
							>
								Delete
							</Button>
						</Col>
						<Col lg={8} className="align-right mb-3">
							<div className="d-flex justify-content-end">
								<Button
									as={Link}
									to={getRouteWithParam(URI.project.viewPage, {
										id: this.props.params.id,
										page: 'vendor-orders',
									})}
									variant="ivory"
									size="lg"
									className="mx-3"
									name="cancel"
								>
									Cancel
								</Button>
								<SecureBootstrapButton
									attributeNo={18}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
									variant="primary"
									size="lg"
									onClick={this.handleSave}
								>
									Save
								</SecureBootstrapButton>
							</div>
						</Col>
					</Row>
				</FooterFormAction>
				<DeleteModal
					refreshData={this.refreshDataAfterDelete.bind(this)}
					show={this.state.showModal}
					hideModal={this.hideModal}
					responses={this.state.deleteResponses}
				/>
			</>
		);
	}
}

export default WithRouter(ProjectVendorOrdersAddWorkOrder);
