import { makeAutoObservable } from 'mobx';
import { AddressListResponse, AddressService } from './api/AddressService';
import { getLocalStorage } from '../../../utilities/LocalStorage';

export default class PayeeAddressAddEditViewModel {
	private addressService: AddressService;
	private _address: any = {};
	private _addressData: any = {};
	private _isLoaded = false;
	public _clientCode: any = '';
	private _addressType = 0;

	get address(): AddressListResponse {
		return this._address;
	}

	get addressData(): AddressListResponse {
		return this._addressData;
	}

	get isLoaded(): boolean {
		return this._isLoaded;
	}

	get addressType(): number {
		return this._addressType;
	}

	setAddress(address: any): PayeeAddressAddEditViewModel {
		this._address = address;
		this._addressData = address;
		window.localStorage.setItem('payeeAddressData', JSON.stringify(address));
		return this;
	}

	setAddressData(key: any, value: any): PayeeAddressAddEditViewModel {
		const addressData: any = getLocalStorage('payeeAddressData', true);

		addressData[key] = value;

		window.localStorage.setItem(
			'payeeAddressData',
			JSON.stringify(addressData)
		);

		this._addressData = addressData;

		return this;
	}

	setIsLoaded(isLoaded: boolean): PayeeAddressAddEditViewModel {
		this._isLoaded = isLoaded;
		return this;
	}

	setAddressType(addressType: number): PayeeAddressAddEditViewModel {
		this._addressType = addressType;
		return this;
	}

	constructor(addressService: AddressService) {
		this.addressService = addressService;

		makeAutoObservable(this);
	}

	async componentDidMount() {
		await this.fetchAddress();
	}

	setClientCode(code: any): PayeeAddressAddEditViewModel {
		this._clientCode = code;
		return this;
	}

	async fetchAddress() {
		// Set loading state
		this.setIsLoaded(false);

		if (!this._clientCode) {
			const data: any = {};
			this.setAddress(data);
			return;
		}

		return this.addressService
			.getAddressByClientCode(this._clientCode, this._addressType)
			.then((response: any) => {
				this.setIsLoaded(true);

				if (response && response[0]) {
					this.setAddress(response[0]);
				}
			})
			.catch((e: any) => {
				this.setIsLoaded(true);
			});
	}

	async modifyAddress(id?: number, params?: any, callback?: any) {
		if (!id) {
			if (this._clientCode) {
				const response = await this.addressService.getAddressByClientCode(
					this._clientCode,
					this._addressType
				);
				return this.updateAddress(response.id, params, callback);
			} else {
				return this.insertAddress(params, callback);
			}
		} else {
			delete params.addresstype;
			delete params.addressTypeEnum;
			return this.updateAddress(id, params, callback);
		}
	}

	insertAddress(params?: any, callback?: any) {
		this.addressService
			.insert(params)
			.then((response: any) => {
				if (callback) {
					callback(response);
				}
			})
			.catch((e) => {
				if (callback) {
					callback(e);
				}
			});
	}

	updateAddress(id: number, params?: any, callback?: any) {
		this.addressService
			.update(id, params)
			.then((response: any) => {
				if (callback) {
					callback(response);
				}
			})
			.catch((e: any) => {
				if (callback) {
					callback(e);
				}
			});
	}
}
