import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ApiService } from '../HttpService';

const api = new ApiService();

const useDeleteFile = (
	options?: UseMutationOptions<unknown, unknown, string, unknown>
) => {
	return useMutation({
		mutationFn: (id: string) => {
			return api.deleteFile(id);
		},
		...(options || {}),
	});
};
export default useDeleteFile;
