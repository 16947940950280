import TRLoading from '../app/components/table/TableLoader';

function showLoading(
	{ rows, cols, layout, style, rowClass } = {
		rows: 2,
		cols: 20,
		layout: [],
		style: 'colspan',
		rowClass: '',
	}
) {
	style = style ?? 'colspan';

	return (
		<>
			{style === 'colspan' ? (
				<TRLoading rows={rows ?? 2} colSpan={cols ?? 20} className={rowClass} />
			) : (
				<TRLoading
					rows={rows ?? 2}
					cols={cols ?? 5}
					layout={layout ?? [[1, 'checkbox']]}
					className={rowClass}
				/>
			)}
		</>
	);
}

function showEmpty(colspan = 12) {
	return (
		<tr key={0} data-testid="no-record-found-message">
			<td colSpan={colspan} className="fw-bold">
				No record found.
			</td>
		</tr>
	);
}

export { showLoading, showEmpty };
