import React from 'react';

import '../../../assets/styles/modules/tr-loading.scss';
import { isArray } from 'lodash';

interface Props {
	rows?: number;
	cols?: number;
	colSpan?: number;
	startAt?: number;
	layout?: Array<[number, 'image' | 'checkbox']>;
	className?: string;
}
interface State {}

/**
 * Class TRLoading
 *
 * This component displays the loading table rows.
 * It accepts the following properties:
 * {rows: number} - total rows to display
 * {cols: number} - if set to greater than 0, the total <td> will equal to this without using the colSpan attribute.
 * {colSpan: number} - if `cols` is not defined or equal to zero, the total <td> is one (1) and colSpan will be added to the attribute. This is the table total columns.
 * {startAt: number} - set the starting point of the loading <span>. Set to the desired position.
 * {layout: number} - set the layout for the specific <td<span class="{layout}">> ex. [[1, 'image']] which means that at column 1, the image loading style will be displayed. It accepts the following format: array[[position, style], [position, style]]
 */
export default class TRLoading extends React.Component<Props, State> {
	totalRows: number;
	totalCols: number;
	colSpan: number;
	startAt: number;
	layout: Array<[number, 'image' | 'checkbox']>;

	constructor(props: Props) {
		super(props);

		this.totalRows = props.rows ?? 1;
		this.totalCols = props.cols ?? 0;
		this.colSpan = props.colSpan ?? 8;
		this.startAt = props.startAt ?? 1;
		this.layout = props.layout ?? [];
	}

	getLayout(pos: number, _type = '') {
		if (isArray(this.layout)) {
			this.layout.map(([n, type], i) => {
				if (pos === n) {
					_type = type;
				}
			});

			return _type;
		}

		return '';
	}

	renderTD() {
		if (this.totalCols > 0) {
			return [...Array(this.totalCols)].map((x, i) => (
				<td className="td-1" key={i}>
					{this.startAt <= i + 1 && (
						<span className={this.getLayout(i + 1)}></span>
					)}
				</td>
			));
		} else {
			return (
				<td className="td-1" key={1} colSpan={this.colSpan}>
					<span></span>
				</td>
			);
		}
	}

	render() {
		return (
			<>
				{[...Array(this.totalRows)].map((x, i) => (
					<tr className={`tr-loading ${this.props.className}`} key={i}>
						{this.renderTD()}
					</tr>
				))}
			</>
		);
	}
}
