import React from 'react';
import ThreadComponent from '../ThreadComponent';
import DatePicker from 'react-datepicker';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { fakeReactSelectOptions } from '../../factory/fakeFactory';
import { ApiService } from '../../../lib/api/HttpService';
import { isEmpty } from 'lodash';
import { Is } from '../../../helpers/Util';
import { getCookie } from '../../../utilities/Auth';

class TransferClippedModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			user: {},
			users: [],
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		let activeUsers = [];
		let users = await this.api.getManageAccount('?$orderby=fullName');
		let usersAcc = await this.api.getUserAccounts();

		if (!isEmpty(usersAcc) && !isEmpty(users?.activeUsers)) {
			for (const user of usersAcc) {
				// Find users except self and disabled.
				const muser = _.find(users.activeUsers, (auser) => {
					return (
						(user.password || '').toUpperCase() ===
							(auser.username || '').toUpperCase() &&
						user.disabled === false &&
						user.userId != getCookie('dmUsercode')
					);
				});

				if (!isEmpty(muser)) {
					activeUsers.push({
						label: `${user.username} [${muser.username}]`,
						value: user.userId,
					});
				}
			}
		}

		this.setState({
			users: activeUsers,
		});
	}

	componentDidUpdate() {
		if (!this.props.show && !isEmpty(this.state.user)) {
			this.setState({
				user: {},
			});
		}
	}

	render() {
		return (
			<Modal
				size="md"
				show={this.props.show}
				onHide={() => this.hide()}
				aria-labelledby="example-modal-sizes-title-lg"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3">
						<Col className="px-5">
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								Transfer to Another User
							</h5>
							<div className="mx-auto text-center pt-3">
								<h6>Would you like to transfer this item to another user?</h6>
								<h6 className="fw-bold pb-3">{this.props.data?.itemTitle}</h6>
							</div>
							<Row className="align-items-center mb-3">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Seelct User:</Form.Label>
								</Col>
								<Col>
									<Select
										options={this.state.users}
										className="react-select"
										placeholder="Select client"
										value={this.state.user}
										onChange={(option) => {
											this.setState({
												user: option,
											});
										}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={() => this.props.handleTransfer(this.state.user)}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal}
								className="w-100 m-2"
							>
								Close
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default TransferClippedModal;
