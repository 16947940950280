import { Col, Row, Form, Container, Table } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import Select from 'react-select';
import WysiwygEditor from '../../ui-kit/WysiwygEditor';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../utilities/Rtf';

const defaultSelectOptions = {
	menuPlacement: 'auto',
	menuPortalTarget: document.body,
	styles: {
		menuPortal: (base) => ({
			...base,
			zIndex: 99991,
		}),
	},
};

class ProjectAddMarkupInvoice extends ThreadComponent {
	constructor(props) {
		super(props);
		this.updatedData = this.props.updatedData ?? {};
		this.styleOptions = [
			{
				value: 0,
				label: 'Combine',
			},
			{
				value: 1,
				label: 'List',
			},
			{
				value: 2,
				label: 'Total',
			},
			{
				value: 3,
				label: 'Ignore',
			},
		];
		this.pricingOptions = [
			{
				value: 0,
				label: 'Always Proposal',
			},
			{
				value: 1,
				label: 'Always Actual',
			},
			{
				value: 2,
				label: 'Automatic',
			},
		];

		this.state = {
			invremarksHtml: '',
		};

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	async componentDidMount() {
		this.setFormAction(true);
		this.setState({
			invremarksHtml: await mapRtfToDraft(
				this.updatedData?.invremarksrtf ?? this.props.company?.invremarksrtf
			),
		});
	}

	getDefaultStyleValue(key) {
		return this.styleOptions.find((style) => {
			return (
				style.value === this.updatedData[key] ||
				style.value === this.props.project?.[key]
			);
		});
	}

	getDefaultPricingValue(key) {
		return this.pricingOptions.find(
			(price) =>
				price.value === this.updatedData[key] ||
				price.value === this.props.project?.[key]
		);
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name ?? e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}
		this.props.onDataChange({ key: key, value: value });

		if (key === 'invremarks') {
			const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(e.target.html);
			this.props.onDataChange({ key: `${key}rtf`, value: valueHtml });
		}
	};

	render() {
		return (
			<>
				<Container className="ms-0">
					<Row xs={1} lg={2} className="mb-4">
						<Col lg={7} className="mb-3 mb-lg-0">
							<Row>
								<Col>
									<div className="table-gradient">
										<Table responsive borderless className="">
											<thead>
												<tr key="0">
													<th className="fs-lg w-120px"></th>
													<th className="fs-lg">
														<Row
															style={{
																minWidth: '400px',
															}}
														>
															<Col>Title</Col>
															<Col>Style</Col>
															<Col>Pricing</Col>
														</Row>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr key={0}>
													<td className="align-middle text-end">Merchandise</td>
													<td className="pe-0">
														<Row
															style={{
																minWidth: '400px',
															}}
														>
															<Col
																md={{
																	span: 4,
																	offset: 8,
																}}
															>
																<Select
																	name="invpricemodem"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.pricingOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultPricingValue(
																		'invpricemodem'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
														</Row>
													</td>
												</tr>
												<tr key={1}>
													<td className="align-middle text-end">
														{this.props.company?.compviewd}
													</td>
													<td className="pe-0">
														<Row
															style={{
																minWidth: '400px',
															}}
														>
															<Col>
																<Form.Control
																	type="text"
																	maxLength="30"
																	placeholder="Please enter"
																	name="invdesigndesc"
																	defaultValue={
																		this.updatedData.invdesigndesc ??
																		this.props.company?.invdesigndesc
																	}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	name="invdesignstyle"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.styleOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultStyleValue(
																		'invdesignstyle'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	name="invpricemoded"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.pricingOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultPricingValue(
																		'invpricemoded'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
														</Row>
													</td>
												</tr>
												<tr key={2}>
													<td className="align-middle text-end">
														{this.props.company?.compviewf}
													</td>
													<td className="pe-0">
														<Row
															style={{
																minWidth: '400px',
															}}
														>
															<Col>
																<Form.Control
																	type="text"
																	maxLength="30"
																	placeholder="Please enter"
																	name="invfreightdesc"
																	defaultValue={
																		this.updatedData.invfreightdesc ??
																		this.props.company?.invfreightdesc
																	}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	name="invfreightstyle"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.styleOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultStyleValue(
																		'invfreightstyle'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	name="invpricemodef"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.pricingOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultPricingValue(
																		'invpricemodef'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
														</Row>
													</td>
												</tr>
												<tr key={3}>
													<td className="align-middle text-end">
														{this.props.company?.compviewi}
													</td>
													<td className="pe-0">
														<Row
															style={{
																minWidth: '400px',
															}}
														>
															<Col>
																<Form.Control
																	type="text"
																	maxLength="30"
																	placeholder="Please enter"
																	name="invinstalldesc"
																	defaultValue={
																		this.updatedData.invinstalldesc ??
																		this.props.company?.invinstalldesc
																	}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	{...defaultSelectOptions}
																	name="invinstallstyle"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.styleOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultStyleValue(
																		'invinstallstyle'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	name="invpricemodei"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.pricingOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultPricingValue(
																		'invpricemodei'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
														</Row>
													</td>
												</tr>
												<tr key={4}>
													<td className="align-middle text-end">
														{this.props.company?.compviewl}
													</td>
													<td className="pe-0">
														<Row
															style={{
																minWidth: '400px',
															}}
														>
															<Col>
																<Form.Control
																	type="text"
																	maxLength="30"
																	placeholder="Please enter"
																	name="invlabordesc"
																	defaultValue={
																		this.updatedData.invlabordesc ??
																		this.props.company?.invlabordesc
																	}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	{...defaultSelectOptions}
																	name="invlaborstyle"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.styleOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultStyleValue(
																		'invlaborstyle'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	{...defaultSelectOptions}
																	name="invpricemodel"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.pricingOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultPricingValue(
																		'invpricemodel'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
														</Row>
													</td>
												</tr>
												<tr key={5}>
													<td className="align-middle text-end">
														{this.props.company?.compviewo}
													</td>
													<td className="pe-0">
														<Row
															style={{
																minWidth: '400px',
															}}
														>
															<Col>
																<Form.Control
																	type="text"
																	maxLength="30"
																	placeholder="Please enter"
																	name="invotherdesc"
																	defaultValue={
																		this.updatedData.invotherdesc ??
																		this.props.company?.invothersdesc
																	}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	{...defaultSelectOptions}
																	name="invotherstyle"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.styleOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.styleOptions.find(
																		(style) => {
																			return (
																				style.value ===
																				(this.updatedData.invotherstyle ??
																					this.props.company?.invothersstyle)
																			);
																		}
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
															<Col>
																<Select
																	{...defaultSelectOptions}
																	name="invpricemodeo"
																	key={`${Math.floor(
																		Math.random() * 1000
																	)}-min`}
																	options={this.pricingOptions}
																	className="react-select"
																	placeholder="Please select"
																	defaultValue={this.getDefaultPricingValue(
																		'invpricemodeo'
																	)}
																	onChange={this.handleChange}
																/>
															</Col>
														</Row>
													</td>
												</tr>
											</tbody>
										</Table>
									</div>
								</Col>
							</Row>
						</Col>

						<Col lg={5} className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-5 d-none d-lg-flex">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0"></Form.Label>
								</Col>
							</Row>
							<Row className="align-items-start mb-3">
								<Col className="ps-2 ps-lg-5">
									<div className="mb-3">
										<Form.Check
											inline
											label="Suppress Location Heading"
											name="invsuploc"
											type="checkbox"
											id={`chk-option-invsuploc`}
											className="vtop"
											defaultChecked={
												this.updatedData.invsuploc ??
												this.props.company?.invsuploc
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Suppress Unit Prices"
											name="invsupunit"
											type="checkbox"
											id={`chk-option-invsupunit`}
											className="vtop"
											defaultChecked={
												this.updatedData.invsupunit ??
												this.props.company?.invsupunit
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Ship To Address"
											name="invshowshipto"
											type="checkbox"
											id={`chk-option-invshowshipto`}
											className="vtop"
											defaultChecked={
												this.updatedData.invshowshipto ??
												this.props.company?.invshowshipto
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Site Address"
											name="invshowsite"
											type="checkbox"
											id={`chk-option-invshowsite`}
											className="vtop"
											defaultChecked={
												this.updatedData.invshowsite ??
												this.props.company?.invshowsite
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Project Code"
											name="invprojcode"
											type="checkbox"
											id={`chk-option-invprojcode`}
											className="vtop"
											defaultChecked={
												this.updatedData.invprojcode ??
												this.props.company?.invprojcode
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Unit to 4 Decimals"
											name="invunit2dec"
											type="checkbox"
											id={`chk-option-invunit2dec`}
											className="vtop"
											defaultChecked={
												this.updatedData.invunit2dec ??
												this.props.company?.invunit2dec
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Remarks in New Page"
											name="invremsep"
											type="checkbox"
											id={`chk-option-invremsep`}
											className="vtop"
											defaultChecked={
												this.updatedData.invremsep ??
												this.props.company?.invremsep
											}
											onClick={this.handleChange}
										/>
									</div>
									<div>
										<Form.Check
											inline
											label="Show Email & Website"
											name="invemail"
											type="checkbox"
											id={`chk-option-invemail`}
											className="vtop"
											defaultChecked={
												this.updatedData.invemail ??
												this.props.company?.invemail
											}
											onClick={this.handleChange}
										/>
									</div>
								</Col>
								<Col>
									{/* <div className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Single Item Invoice"
                                            name="singleiteminv"
                                            type="checkbox"
                                            id={`chk-option-singleiteminv`}
                                            className="vtop"
                                            defaultChecked={
                                                this.updatedData
                                                    .singleiteminv ??
                                                this.props.company
                                                    ?.singleiteminv
                                            }
                                            onClick={this.handleChange}
                                        />
                                    </div> */}
									<div className="mb-3">
										<Form.Check
											inline
											label="Time Supplement"
											name="invsupplement"
											type="checkbox"
											id={`chk-option-invsupplement`}
											className="vtop"
											defaultChecked={
												this.updatedData.invsupplement ??
												this.props.company?.invsupplement
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Time Details"
											name="invshowtimedetails"
											type="checkbox"
											id={`chk-option-invshowtimedetails`}
											className="vtop"
											defaultChecked={
												this.updatedData.invshowtimedetails ??
												this.props.company?.invshowtimedetails
											}
											onClick={this.handleChange}
										/>
									</div>
									<div className="mb-3">
										<Form.Check
											inline
											label="Show Start/End Time"
											name="invshowtbtime"
											type="checkbox"
											id={`chk-option-invshowtbtime`}
											className="vtop"
											defaultChecked={
												this.updatedData.invshowtbtime ??
												this.props.company?.invshowtbtime
											}
											onClick={this.handleChange}
										/>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>

					<div className="bg-secondary-grey mb-4 roundedx-4">
						<Row xs={1} className="py-3">
							<Col>
								<Row className="align-items-center px-3">
									<Col className="px-3">
										<h6 className="fw-bold mb-0">Remarks</h6>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>

					<Row xs={1} lg={2} className="mb-5">
						<Col className="mb-3 mb-lg-0">
							<WysiwygEditor
								id="invremarks"
								onChange={this.handleChange}
								children={this.state.invremarksHtml}
							/>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default WithRouter(ProjectAddMarkupInvoice);
