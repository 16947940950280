import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Container,
	Button,
	Form,
	Row,
	Col,
	Table,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import { FooterFormAction } from '../../../components/Form';

class ClientInvoiceAddOverridePrice extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			startDate: '',
			minDate: new Date(),
			isDisabled: true,
		};
	}

	componentInit() {
		this.setTitle('Override Invoice Price | Accounts').setFormAction(true);
	}

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
	};

	handleRadioChange = (e) => {
		this.setState(
			(prevState) =>
				(prevState.isDisabled = e.target.value === '1' ? true : false)
		);
	};

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.accountsReceivable.clientInvoices.listNew}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsReceivable.clientInvoices.listNew,
							}}
							linkAs={Link}
						>
							Accounts Receivable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsReceivable.clientInvoices.listNew,
							}}
							linkAs={Link}
						>
							Client Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsReceivable.clientInvoices.add,
							}}
							linkAs={Link}
						>
							Add Invoice
						</Breadcrumb.Item>
						<Breadcrumb.Item>Override Invoice Price</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={false}>
					<HeaderLight.Title>Override Invoice Price</HeaderLight.Title>
					{/* Submit Button */}
					<Button variant="primary">Save</Button>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		const List = [
			'Merchandise',
			'Freight',
			'Surcharge',
			'Install/Labor',
			'Design Fee',
			'Time',
			'Total Item Price',
			'Deposit to Apply',
		];

		return (
			<Form.Group>
				<Row xs={1} lg={2} className="mb-3 mb-lg-4">
					<Col>
						<Row lg={2}>
							<Col lg={3} className="d-none d-lg-block"></Col>
							<Col>
								<Form.Check
									inline
									label="Override"
									name="check-group-override"
									type="checkbox"
									id={`check-group-override`}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Qty. to Invoice</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
									<Col>
										<Button
											variant="primary"
											size="md"
											style={{ minWidth: '100%' }}
										>
											Recalculate
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Item Quantity</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2} className="mb-lg-2">
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Qty. Inv. to Date</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>

				<div className="table-gradient">
					<Table hover responsive borderless className="">
						<thead>
							<tr key="0">
								<th className="fs-lg" style={{ width: '12.2%' }}></th>
								<th className="fs-lg mw-100px">Price To Invoice</th>
								<th className="fs-lg mw-100px">Taxable Amount</th>
								<th className="fs-lg mw-100px">Est. Cost</th>
								<th className="fs-lg mw-100px">Est. Price</th>
								<th className="fs-lg mw-100px">Act. Price</th>
								<th className="fs-lg mw-100px">Inv To Date</th>
							</tr>
						</thead>
						<tbody>
							{List.map((item, i) => (
								<tr key={i}>
									<td className="align-middle text-end pe-3">{item}</td>
									<td>
										<Form.Control type="text" placeholder="0.00" />
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" />
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" />
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" />
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" />
									</td>
									<td>
										<Form.Control type="text" placeholder="0.00" />
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>

				<Row xs={1} lg={2} className="mt-3 mt-lg-0">
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Deposit to Apply</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col lg={5} className="pe-lg-3">
										<Form.Control type="text" placeholder="0.00" />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height">
					<Container className="ms-0">{this.renderContent()}</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(ClientInvoiceAddOverridePrice);
