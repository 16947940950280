import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Field, TForm as Model, IFormField } from '../components/Components';
import dayjs from 'dayjs';
import { fetchProjectItems } from '../../StockItemsService';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';

interface IInventoryAdjustmentModalProps {
	data: Map<string, any>;
	projects: TTypeOption[];
	addresses: TTypeOption[];
	warehouse?: string;
	onCancel: () => void;
	onSave: (data: Map<string, any>) => void;
}
export const InventoryAdjustmentModal: FC<IInventoryAdjustmentModalProps> = ({
	data,
	projects,
	addresses,
	warehouse,
	onCancel,
	onSave,
}) => {
	const [formData, setFormData] = useState<Map<string, any>>(data);
	const [items, setItems] = useState<TTypeOption[]>([]);
	const [isLoading, setLoading] = useState(false);

	const model: () => Model = () => {
		const formatDate = (value: string) => {
			return dayjs(value).format('MM/DD/YYYY');
		};

		return {
			sections: [
				{
					key: 's0',
					columns: [
						{
							key: 'c0',
							fields: [
								{
									type: 'text',
									title: 'On Hand Adjustment',
									ids: ['onhandadj'],
									value: formData.get('onhandadj'),
									titleClass: 'fw-bold',
									inputRegex: /^\d*\.?\d{0,2}$/,
								},
								{
									type: 'text',
									title: 'Paid For Adjustment (of the qty. on hand)',
									ids: ['qtypaidforadj'],
									value: formData.get('qtypaidforadj'),
									titleClass: 'fw-bold',
									inputRegex: /^\d*\.?\d{0,2}$/,
								},
								{
									type: 'text',
									title: 'Committed Adjustment',
									ids: ['commitadj'],
									value: formData.get('commitadj'),
									titleClass: 'fw-bold',
									inputRegex: /^\d*\.?\d{0,2}$/,
								},
								{
									type: 'text',
									title: 'On Order Adjustment',
									ids: ['onorderadj'],
									value: formData.get('onorderadj'),
									titleClass: 'fw-bold',
									inputRegex: /^\d*\.?\d{0,2}$/,
								},
								{
									type: 'text',
									title: 'Cost Adjustment',
									ids: ['costadj'],
									value: formData.get('costadj'),
									titleClass: 'fw-bold',
									inputRegex: /^\d*\.?\d{0,2}$/,
								},
								{
									type: 'select',
									subtype: 'project',
									title: 'Project',
									ids: ['proj'],
									titleClass: 'fw-bold',
									handler: handleProject,
									options: projects,
									value: formData.get('proj'),
								},
								{
									type: 'select',
									subtype: 'custom',
									title: 'Item No.',
									ids: ['item'],
									value: formData.get('item'),
									titleClass: 'fw-bold',
									options: items,
									readOnly: !formData.get('proj'),
									isLoading: isLoading,
								},
								{
									type: 'text',
									title: 'Component No.',
									ids: ['comp'],
									value: formData.get('comp'),
									titleClass: 'fw-bold',
								},
								{
									type: 'select',
									subtype: 'custom',
									title: 'Warehouse',
									ids: ['wh'],
									value: formData.get('wh') ?? warehouse,
									titleClass: 'fw-bold',
									options: addresses,
								},
								{
									type: 'checkbox',
									title: 'Transfer to Warehouse',
									ids: ['isTransferWarehouse'],
									value: formData.get('isTransferWarehouse'),
									isHidden: data.size > 0,
								},
								{
									type: 'select',
									subtype: 'custom',
									title: 'Warehouse To Transfer',
									ids: ['transferWhCode'],
									value: formData.get('transferWhCode'),
									titleClass: 'fw-bold',
									options: addresses,
									readOnly: !formData.get('isTransferWarehouse'),
									isHidden: data.size > 0,
								},
								{
									type: 'text',
									title: 'Transaction Description',
									ids: ['txdesc'],
									value: formData.get('txdesc'),
									titleClass: 'fw-bold',
								},
								{
									type: 'datepicker',
									title: 'Adjustment Date',
									ids: ['userdate'],
									value: formatDate(formData.get('userdate')),
									titleClass: 'fw-bold',
								},
							],
							sm: 12,
							lg: 12,
						},
					],
				},
			],
		};
	};

	useEffect(() => {
		const fetchData = async () => {
			if (!formData.get('proj')) {
				return;
			}

			setLoading(true);
			setItems(await fetchProjectItems(formData.get('proj')));
			setLoading(false);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleProject = (data: Map<string, any>, value: string) => {
		const fetchItems = async () => {
			setLoading(true);
			setItems(await fetchProjectItems(value));
			setLoading(false);
		};
		data.set('proj', value);
		data.set('item', null);
		fetchItems();

		return data;
	};

	const handleChange = (field: IFormField, value: any) => {
		const newData =
			field.handler?.(new Map(formData), value) ?? new Map(formData);

		field.ids.forEach((id) => {
			newData.set(id, value);
		});

		setFormData(newData);
	};

	return (
		<Modal
			size="xl"
			show={true}
			onHide={onCancel}
			className="right a-modal modal-40 fixed-footer"
			backdrop="static"
		>
			<Modal.Body className="bg-ivory h-100">
				<div className="d-flex flex-column h-100 p-5">
					<h3 className="mb-2">Inventory Adjustment</h3>
					<span className="mb-3">
						NOTE: Use positive values to increase and negative values to
						decrease. When entering cost and quantity it is usually necessary to
						increase both the on hand quantity and the paid for quantity. The
						cost adjustment is an extended figure - not the unit cost.
					</span>
					<Col>
						{model().sections.map((section) => {
							return (
								<Row key={section.key} className="mb-5">
									{section.columns.map((column) => {
										return (
											<Col
												key={column.key}
												sm={column.sm}
												md={column.md}
												lg={column.lg}
											>
												{column.fields
													.filter((field) => !field.isHidden)
													.map((field) => {
														return (
															<Row key={field.ids[0]} className="mb-2">
																<Field
																	model={field}
																	onChange={(value) => {
																		handleChange(field, value);
																	}}
																/>
															</Row>
														);
													})}
											</Col>
										);
									})}
								</Row>
							);
						})}
					</Col>
				</div>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-4 px-5">
				<Row className="w-100">
					<Col lg={{ span: 10, offset: 1 }}>
						<Button
							variant="primary"
							onClick={() => {
								onSave(formData);
							}}
							className="w-100 mb-2"
						>
							Save
						</Button>
						<Button
							variant="trans-light border-secondary-ash"
							onClick={onCancel}
							className="w-100"
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};
InventoryAdjustmentModal.displayName = 'InventoryAdjustmentModal';
