import React from 'react';
import { Col, Row, Form, Container } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import Select from 'react-select';
import { fakeReactSelectOptions } from '../../factory/fakeFactory';

class EmployeesAddPOS extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectData: [
				{ value: 'option-1', label: 'Option 1' },
				{ value: 'option-2', label: 'Option 2' },
				{ value: 'option-3', label: 'Option 3' },
			],
			startDate: '',
			minDate: new Date(),
		};
	}

	componentInit() {
		this.hasFormAction = true;
	}

	handleChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
	};

	render() {
		return (
			<>
				<Container className="ms-0">
					<Row xs={1} lg={2} className="mb-0 mb-lg-5">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={5} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Commision %</Form.Label>
								</Col>
								<Col>
									<Row lg={2}>
										<Col>
											<Form.Control type="text" placeholder="0" />
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={5} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										Default Showroom (Warehouse Code)
									</Form.Label>
								</Col>
								<Col>
									<Select
										options={fakeReactSelectOptions()}
										className="react-select"
										placeholder=""
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={5} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										Default Sales Tax Code
									</Form.Label>
								</Col>
								<Col>
									<Select
										options={fakeReactSelectOptions()}
										className="react-select"
										placeholder=""
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col>
									<Form.Check
										inline
										label="Allow Receipt/Invoice Date Change"
										name="check-group-date"
										type="checkbox"
										id={`check-group-date`}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col>
									<Form.Check
										inline
										label="Allow Manual Items and Price Edits"
										name="check-group-price"
										type="checkbox"
										id={`check-group-price`}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default WithRouter(EmployeesAddPOS);
