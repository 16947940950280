import CONFIG from '../defaults/Config';
import { StringReplaceArray } from './String';

function setDocumentTitle(title) {
	document.title = (title ? title + ' | ' : '') + CONFIG.documentTitle;
}

function addBodyClass(name, el) {
	el = el || document.body;
	// Add additional class to `body` on change.
	setTimeout(() => el.classList.add(`${name}`), 50);
}

function removeBodyClass(name, el) {
	el = el || document.body;
	// Remove additional class to `body` on change.
	setTimeout(() => el.classList.remove(`${name}`), 50);
}

function addDomClass(name, el) {
	el = el || document.body;
	// Add additional class to `body` on change.
	setTimeout(() => el.classList.add(`${name}`), 50);
}

function removeDomClass(name, el) {
	el = el || document.body;
	// Remove additional class to `body` on change.
	setTimeout(() => el.classList.remove(`${name}`), 50);
}

function hasClass(name, el) {
	return el.classList.contains(name);
}

function replaceClassMatch(props, classNames) {
	const arrayString = props.replaceclassmatch.split('/');
	const arrayStringReplace = props.replaceclassmatchwith.split('/');

	return StringReplaceArray(classNames, arrayString, arrayStringReplace);
}

function getClassNames(props, defaultClass) {
	return props.replaceClass
		? props.className
		: props.replaceclassmatch
		? replaceClassMatch(props, `${defaultClass} ${props.className || ''}`)
		: `${defaultClass} ${props.className || ''}`;
}

function WindowScroll(callback) {
	const handleScroll = (event) => {
		callback(event);
	};

	window.addEventListener('scroll', handleScroll);
}

export {
	setDocumentTitle,
	addBodyClass,
	removeBodyClass,
	addDomClass,
	removeDomClass,
	hasClass,
	getClassNames,
	WindowScroll,
};
