import React from 'react';
import useGetClippedItems from 'legacy/lib/api/hooks/useGetClippedItems';
import { IClippedItem } from 'legacy/lib/api/types/ClippedItems';
import Icon from 'legacy/app/components/Icon/Icon';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import CameraIcon from '../../../../assets/images/icons/Recently-Clipped-Icon.svg';
import TileBackground from '../../../../assets/images/Card_Single-BG.png';
import InstallClipperBackground from '../../../../assets/images/Recently_Clipped-BG-[No_Arrow].png';
import BaseBanner from 'legacy/app/components/banners/BaseBanner';
import LoadingPlaceholder from 'legacy/app/components/LoadingPlaceholder/LoadingPlaceholder';

const ADD_ON_URL =
	'https://chrome.google.com/webstore/detail/design-manager-product-cl/kloodacdcdmefdelbanijdonpljlbjnp';

const ClipperGrid = () => {
	const filter = '?pageNumber=1&pageSize=4';
	const { data, isLoading } = useGetClippedItems(filter);

	if (isLoading) {
		return (
			<LoadingPlaceholder
				placeholderClass="tw-border tw-rounded-lg tw-p-4 tw-overflow-hidden tw-bg-white animate-pulse"
				rows={3}
				color="[#faf9f7]"
			/>
		);
	}

	return (
		<div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
			<div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
				<div className="tw-App tw-flex tw-items-center">
					<Icon path={CameraIcon} alt="camera-icon" />
					<h1 className="tw-text-2xl tw-ml-1.5">Recently Clipped</h1>
				</div>
				<BaseButton
					text="View all"
					onClick={() => (window.location.href = '/items/clipped-items')}
					buttonClass="tw-px-4 tw-py-2 tw-border tw-border-[#006a53] tw-text-black tw-rounded tw-transition hover:tw-cursor-pointer tw-text-center"
				/>
			</div>
			{data?.totalItems ? (
				<div className="tw-grid tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-4">
					{data?.list.map((item: IClippedItem, index: number) => (
						<div
							className="tw-border tw-rounded-lg tw-overflow-hidden"
							key={`grid-item-${index}`}
						>
							<div
								key={`grid-item-background-${index}`}
								className="tw-w-full tw-h-48 tw-bg-cover tw-bg-center tw-p-4"
								style={{
									backgroundImage: `url(${TileBackground})`,
								}}
							>
								<div className="tw-flex tw-justify-center tw-items-center tw-h-full">
									<img
										src={item?.imageUrl}
										alt={item.itemTitle}
										className="tw-object-contain tw-rounded-lg tw-w-3/4 tw-h-3/4"
									/>
								</div>
							</div>
							<div className="tw-p-2.5">
								<h3 className="tw-text-lg tw-text-xs tw-text-wrap">
									{item?.itemTitle}
								</h3>
								<p className="tw-text-gray-500 tw-text-xs tw-text-wrap">
									{item?.project}
								</p>
								{item?.totalCost && (
									<p className="tw-text-black tw-text-xs tw-text-wrap">
										{`$${item?.totalCost?.toFixed(2)}`}
									</p>
								)}
							</div>
						</div>
					))}
				</div>
			) : (
				<BaseBanner
					imageClass="tw-hidden sm:tw-block"
					backgroundImage={InstallClipperBackground}
				>
					<div className="tw-relative sm:tw-absolute tw-inset-0 tw-flex tw-items-start tw-justify-center tw-flex tw-flex-col">
						<div className="tw-flex tw-flex-col  tw-items-start tw-bg-white  tw-m-4 tw-rounded-lg tw-bg-opacity-75 tw-max-w-full sm:tw-max-w-[40%]">
							<h2 className="tw-text-lg">Install The Clipper Tool</h2>
							<p className="tw-mt-2 tw-text-gray-400 tw-mb-0">
								The clipper tool is used to quickly import images and
								descriptions from vendor websites directly into Design Manager.
							</p>
							<BaseButton
								text="Get Clipper"
								buttonClass={`tw-mt-4 tw-px-4 tw-py-2 tw-bg-[#008B6D] tw-text-white tw-rounded `}
								onClick={() => (window.location.href = ADD_ON_URL)}
							/>
						</div>
					</div>
				</BaseBanner>
			)}
		</div>
	);
};

ClipperGrid.displayName = 'ClipperGrid';

export default ClipperGrid;
