import React from 'react';
import { DateRangePicker } from 'rsuite';
import { Form, Button } from 'react-bootstrap';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { FilterComponent } from '../../../components/listing/FilterComponent';
import TimeEntryAddEditViewModel from '../view-models/TimeEntryAddEditViewModel';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import dayjs from 'dayjs';
import { cloneDeep, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import FilterSort from '../../../../utilities/modules/FilterSort';
import AsyncProjectsDropdown from '../../../components/dropdowns/AsyncProjectsDropdown';
import AsyncEmployeesDropdown from '../../../components/dropdowns/AsyncEmployeesDropdown';
import SecureBootstrapButton from '../../../components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security';
import { renderSecureHideContent } from '../../../../utilities/modules/SecureContent';

interface SelectOptions {
	label?: string;
	value?: any;
}
interface Props {
	onShowSearch?: any;
	onDeleteClick?: any;
	disabledDelete?: boolean;
	billable: number;
	nonBillable: number;
	total: number;
	model: TimeEntryAddEditViewModel;
	onFilterChange: any;
	entrydate: Date[];
	proj?: string;
	employee?: string;
	showTableSearch?: boolean;
}

interface State {
	showTableSearch?: boolean;
	selectedEmployee: SelectOptions | null;
	selectedProject: SelectOptions | null;
}

class TimeEntryFilter extends React.Component<Props, State> {
	readonly model: TimeEntryAddEditViewModel;
	readonly fs: FilterSort;

	constructor(props: Props) {
		super(props);

		this.fs = new FilterSort('timeEntryFilter');
		this.model = props.model;
		this.state = {
			showTableSearch: this.props.showTableSearch,
			selectedProject: this.fs.getFilter('proj') ?? null,
			selectedEmployee:
				this.fs.getFilter('defaultEmployee') ?? this.fs.getFilter('employee'),
		};
	}

	hasFilter() {
		return getLocalStorage('resetTimeEntryFilter') === 'no';
	}

	handleShowTableSearch: any = (e: JSX.Element) => {
		this.setState({
			showTableSearch: !this.state.showTableSearch,
		});

		if (this.props.onShowSearch) {
			this.props.onShowSearch(this.state);
		}
	};

	handleDelete: any = (e: JSX.Element) => {
		if (this.props.onDeleteClick) {
			this.props.onDeleteClick(e);
		}
	};

	setFilter(name: string, value: any) {
		let filter: any = getLocalStorage('timeEntryFilter', true);
		const values: any = cloneDeep(value);

		if (!filter) {
			filter = {};
		}

		if (name === 'entrydate' && !isEmpty(values)) {
			values[0] = dayjs(values[0]).format('YYYY-MM-DD');
			values[1] = dayjs(values[1]).format('YYYY-MM-DD');
		}

		filter[name] = values;
		setLocalStorage('timeEntryFilter', filter, true);
	}

	handleDateChange(e: any) {
		this.setFilter('entrydate', e);
		this.props.onFilterChange('entrydate', e);
	}

	handleProjectChange(e: any) {
		this.setState({
			selectedProject: e,
		});
		this.fs.setFilter('proj', e);
		this.props.onFilterChange('proj', e.value);
	}

	handleEmployeeChange(e: any) {
		this.setState({
			selectedEmployee: e,
		});
		this.fs.setFilter('employee', e);
		this.props.onFilterChange('employee', e.value);
	}

	employees(): SelectOptions[] {
		const employeessData = this.model.employees;
		const employees: SelectOptions[] = [];
		employees.push({ value: '', label: 'All Employees' });
		for (const e of employeessData) {
			if (e.vendorn !== '') {
				const tmp = {
					value: e.vendor,
					label: `${e.vendorn} [${e.vendor}]`,
				};
				employees.push(tmp);
			}
		}

		return employees;
	}

	getDefaultEmployee() {
		const employeesData = this.employees();
		const lsFilter = getLocalStorage('timeEntryFilter', true);
		let employee: SelectOptions | null = null;

		if (!isEmpty(employeesData)) {
			for (const e of employeesData) {
				if (lsFilter?.employee?.value === e.value) {
					employee = e;
				}
			}
		}

		return employee;
	}

	render(): JSX.Element {
		return (
			<FilterComponent className="time-entry-list-filter">
				<FilterComponent.Fields md={12} lg={9} xl={10}>
					<FilterComponent.Field
						className="mb-3 mb-md-0"
						style={{ maxWidth: '280px' }}
					>
						<div className="d-flex w-100 justify-content-center justify-content-md-start">
							<div className="me-4">
								Billable
								<br />
								<strong>{this.props.billable} hours</strong>
							</div>
							<div className="me-4">
								Non-billable
								<br />
								<strong>{this.props.nonBillable} hours</strong>
							</div>
							<div>
								Total
								<br />
								<strong>{this.props.total} hours</strong>
							</div>
						</div>
					</FilterComponent.Field>
					<FilterComponent.Field style={{ maxWidth: '260px' }}>
						<Form.Label className="text-end">
							<strong>Project</strong>
						</Form.Label>
						<AsyncProjectsDropdown
							defaultValue={this.state.selectedProject}
							className="react-select flex-grow-1"
							placeholder="Please select"
							onChange={this.handleProjectChange.bind(this)}
							prependOptions={[
								{
									label: 'All Projects',
									value: '',
								},
							]}
						/>
					</FilterComponent.Field>
					{renderSecureHideContent(
						<FilterComponent.Field style={{ maxWidth: '260px' }}>
							<Form.Label className="text-end">
								<strong>Employee</strong>
							</Form.Label>
							<AsyncEmployeesDropdown
								defaultValue={this.state.selectedEmployee}
								className="react-select flex-grow-1"
								placeholder="Please select"
								onChange={this.handleEmployeeChange.bind(this)}
								prependOptions={[
									{
										label: 'All Employees',
										value: '',
									},
								]}
							/>
						</FilterComponent.Field>
					)(15, SECURITY_ATTRIBUTE_TYPES.DenySpec2)}
					<FilterComponent.Field className="rs-widget">
						<Form.Label className="text-end">
							<strong>
								Date
								<br />
								Range
							</strong>
						</Form.Label>
						<DateRangePicker
							style={{ minWidth: '200px' }}
							placement="auto"
							placeholder="Select date"
							format="MM/dd/yyyy"
							onChange={this.handleDateChange.bind(this)}
							defaultValue={
								this.props.entrydate?.length
									? [this.props.entrydate[0], this.props.entrydate[1]]
									: null
							}
							ranges={[
								{
									label: 'today',
									value: [startOfDay(new Date()), endOfDay(new Date())],
								},
								{
									label: 'yesterday',
									value: [
										startOfDay(addDays(new Date(), -1)),
										endOfDay(addDays(new Date(), -1)),
									],
								},
								{
									label: 'last7Days',
									value: [
										startOfDay(subDays(new Date(), 6)),
										endOfDay(new Date()),
									],
								},
								{
									label: 'Last 30 Days',
									value: [
										startOfDay(subDays(new Date(), 30)),
										endOfDay(new Date()),
									],
								},
								{
									label: 'This month',
									value: [
										startOfDay(
											new Date(getYear(new Date()), getMonth(new Date()), 1)
										),
										endOfDay(lastDayOfMonth(new Date())),
									],
								},
								{
									label: 'Last month',
									value: [
										startOfDay(
											new Date(getYear(new Date()), getMonth(new Date()) - 1, 1)
										),
										endOfDay(
											lastDayOfMonth(
												new Date(
													getYear(new Date()),
													getMonth(new Date()) - 1,
													1
												)
											)
										),
									],
								},
							]}
						/>
					</FilterComponent.Field>
				</FilterComponent.Fields>
				<FilterComponent.Actions md={12} lg={3} xl={2} className="mt-2 mt-lg-0">
					<FilterComponent.Action className="ms-auto">
						<Button
							variant="ivory"
							size="sm"
							className={`btn-icon btn-action ${
								this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
							}`}
							onClick={this.handleShowTableSearch}
						>
							<i className="ri-search-line"></i> Search
						</Button>
						<SecureBootstrapButton
							attributeNo={15}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
							disabled={this.props.disabledDelete}
							href="#"
							variant="danger"
							size="sm"
							className="btn-icon btn-action ms-3"
							onClick={this.handleDelete.bind(this)}
						>
							<i className="ri-close-line"></i> Delete
						</SecureBootstrapButton>
					</FilterComponent.Action>
				</FilterComponent.Actions>
			</FilterComponent>
		);
	}
}

export default observer(TimeEntryFilter);
