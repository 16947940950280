import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';
import { TPaymentProcessorOnboardingSession } from '../types/PaymentProcessorOnboardingSession';
import { PaymentSessionType } from '../types/PaymentSessionType';

const api = new ApiService();

const getPaymentProcessorSession = async (
	sessionType: `${PaymentSessionType}`
): Promise<TPaymentProcessorOnboardingSession> => {
	const session = await api.startPaymentProcessorOnboardingSession(sessionType);

	return session;
};

const useStartPaymentProcessorSession = (
	sessionType: `${PaymentSessionType}`,
	options?: UseQueryOptions<
		TPaymentProcessorOnboardingSession,
		unknown,
		TPaymentProcessorOnboardingSession
	>
) => {
	const paymentSessionQuery = useQuery({
		refetchOnWindowFocus: false,
		queryKey: [sessionType, 'session', 'payments'],
		queryFn: () => getPaymentProcessorSession(sessionType),
		...(options || {}),
	});

	useEffect(() => {
		if (paymentSessionQuery.error) {
			displayAlertError(
				'An error occurred starting the merchant onboarding session, please try again'
			);
		}
	}, [paymentSessionQuery.error]);

	return paymentSessionQuery;
};
export default useStartPaymentProcessorSession;
