export default function ConfirmationMessage() {
	return (
		<div className="d-flex align-items-center">
			<div>
				<i className="ri-question-fill ri-3x text-info"></i>
			</div>
			<div className="px-3">
				<div>
					Are you sure that you want to delete the highlighted reconciliation
					and un-clear all transactions in the batch?
				</div>
			</div>
		</div>
	);
}
