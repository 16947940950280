import React from 'react';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import { Container, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { ApiService } from '../../../lib/api/HttpService';
import { FooterFormAction } from '../../components/Form';
import { displayAlert } from '../../../utilities/Response';

class AccountSettingsPayableDialog extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			paytype: this.props.selectedData.payType || '',
			newPayType: '',
			selectedAccount: {
				value: this.props.selectedData.defAcct || '',
				label: Object.values(this.props.selectedData).length
					? `${this.props.selectedData.defAcct}-${this.props.selectedData.accountn}`
					: 'Please select',
			},
			accounts: [],
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		await this.getGeneralLedgerAccounts();
	}

	async getGeneralLedgerAccounts() {
		const gEAccounts = await this.api.get(
			'generalledgeraccounts',
			'?$filter=specacct eq 1 or specacct eq 2'
		);

		if (gEAccounts) {
			let acc = gEAccounts.map((a) => {
				return {
					value: a.account,
					label: `${a.account}-${a.accountn}`,
				};
			});

			this.setState({
				accounts: acc,
			});
		}
	}

	handleChange = (e, meta = {}) => {
		let target, value;
		if (meta && meta.hasOwnProperty('name')) {
			target = meta.name;
			value = e;
		} else {
			target = e.target.name;
			value = e.target.value;
		}

		if (target === 'paytype') {
			this.setState((prevState) => ({
				...prevState,
				newPayType: value,
			}));
		} else {
			this.setState((prevState) => ({
				...prevState,
				[target]: value,
			}));
		}
	};

	handleSubmit = (e) => {
		const postData = {
			payType: this.state.paytype || this.state.newPayType,
			defAcct: this.state.selectedAccount.value,
		};

		if (Object.values(this.props.selectedData).length) {
			postData.newPayType = this.state.newPayType;
			this.api
				.updatePayType(postData)
				.then((data) => {
					if (Object.values(data).length) {
						displayAlert('success', 'Successfully updated paytype');
						this.setState({
							paytype: '',
							selectedAccount: {
								value: '',
								label: 'Please select',
							},
						});
						this.props.onOkay();
					}
				})
				.catch((error) => {
					displayAlert('danger', error.response.data.userError);
				});
		} else {
			this.api
				.createPayType(postData)
				.then((data) => {
					if (Object.values(data).length) {
						displayAlert('success', 'Successfully created new paytype');
						this.setState({
							paytype: '',
							selectedAccount: {
								value: '',
								label: 'Please select',
							},
						});
						this.props.onOkay();
					}
				})
				.catch((error) => {
					displayAlert('danger', error.response.data.userError);
				});
		}
	};

	handleModalClose = () => {
		this.props.onCancel();
		this.setState({
			paytype: '',
			selectedAccount: { value: '', label: 'Please select' },
		});
	};

	content() {
		return (
			<Form.Group className="m-4">
				<Row className="align-items-center mb-3">
					<Col lg={4} className="mb-2 mb-lg-0">
						<Form.Label className="mb-0">Type Description</Form.Label>
					</Col>
					<Col>
						<Form.Control
							type="text"
							maxLength={40}
							name="paytype"
							onChange={this.handleChange}
							defaultValue={this.state.paytype}
						/>
					</Col>
				</Row>
				<Row className="align-items-center mb-3">
					<Col lg={4} className="mb-2 mb-lg-0">
						<Form.Label className="mb-0">Account</Form.Label>
					</Col>
					<Col>
						<Select
							key={`${Math.floor(Math.random() * 1000)}-min`}
							name="selectedAccount"
							options={this.state.accounts}
							className="react-select"
							onChange={this.handleChange}
							defaultValue={this.state.selectedAccount}
							menuPosition={'fixed'}
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
								container: (provided) => ({
									...provided,
									width: '100%',
								}),
							}}
						/>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						<FooterFormAction>
							<a
								className="btn btn-primary btn-icon btn-action me-0 me-lg-2 align-items-center"
								name="ok"
								onClick={this.handleSubmit}
							>
								<i className="ri-check-line ri-lg"></i>OK
							</a>
							<a
								onClick={this.handleModalClose}
								className="btn btn-trans-light btn-icon btn-action mb-2 mb-lg-0 align-items-center"
							>
								<i className="ri-close-line ri-lg text-primary-red"></i>
								Cancel
							</a>
						</FooterFormAction>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	render() {
		return (
			<>
				<Modal
					size="md"
					show={this.props.isShowing}
					aria-labelledby="action-modal"
					className="a-modal"
					backdrop="static"
					centered
				>
					<div className="content-padding min-height">
						<Container fluid className="mt-4">
							{this.content()}
						</Container>
					</div>
				</Modal>
			</>
		);
	}
}

export default WithRouter(AccountSettingsPayableDialog);
