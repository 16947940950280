import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import {
	TFinanceChargeAccount,
	TOnCreateArgs,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeTypes';

import AccountsDropdown, {
	EAccountType,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { IJournalEntry } from 'api/resources/financeCharges';

type TCharge = {
	account: TFinanceChargeAccount | null;
	damt: number;
};

type TFinanceChargeCreateProps = {
	showModal: boolean;
	onToggleModal: () => void;
	account: TFinanceChargeAccount;
	charge: TCharge | null;
	journalEntries: IJournalEntry[];
	onCreate: (values: TOnCreateArgs) => void;
};

const defaultValues: TCharge = {
	account: null,
	damt: 0,
};

export const FinanceChargeCreate = ({
	showModal,
	onToggleModal,
	account,
	charge,
	journalEntries,
	onCreate,
}: TFinanceChargeCreateProps) => {
	const { register, control, handleSubmit, reset } = useForm<TCharge>({
		defaultValues,
	});

	const omitAccounts = [
		...(charge?.account ? [] : journalEntries.map((entry) => entry.account)),
		account.value,
	];

	const onSubmit = (data: TCharge) => {
		onCreate(data);

		reset();

		onToggleModal();
	};

	return (
		<ConfirmationModal
			title="Charge Entry"
			show={showModal}
			toggleModal={onToggleModal}
			footer={false}
			size="lg"
			labelCancel="Cancel"
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="tw-mb-3">
					<div className="tw-inline-block tw-mr-3">
						<Form.Label className="tw-mb-0">Account:</Form.Label>
					</div>
					<div className="tw-inline-block">
						<Controller
							name="account"
							control={control}
							render={({ field }) => (
								<AccountsDropdown
									type={EAccountType.ALL}
									omitAccounts={omitAccounts}
									isDisabled={typeof charge?.account === 'string' ?? false}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...field}
								/>
							)}
						/>
					</div>
				</div>
				<div className="tw-mb-3">
					<div className="tw-inline-block tw-mr-3">
						<Form.Label className="tw-mb-0">Amount:</Form.Label>
					</div>
					<div className="tw-inline-block">
						<Form.Control
							type="number"
							step={0.01}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...register('damt', { required: true, valueAsNumber: true })}
						/>
					</div>
				</div>
				<Modal.Footer className="bg-ivory tw-py-3 tw-px-5">
					<Row>
						<Col className="tw-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="success"
								type="submit"
								className="tw-w-100 tw-m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={onToggleModal}
								className="tw-w-100 tw-m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</form>
		</ConfirmationModal>
	);
};

FinanceChargeCreate.displayName = 'FinanceChargeCreate';
