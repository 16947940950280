import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import { FooterFormAction } from '../../../components/Form';
import Select from 'react-select';
import { fakeReactSelectOptions } from '../../../factory/fakeFactory';

class PaymentFormCheckWirePayment extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			startDate: '',
			minDate: new Date(),
			isDisabled: true,
		};
	}

	componentInit() {
		this.setTitle('Check/Wire Payment').setFormAction(true);
	}

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
	};

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.accountsPayable.payment.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{ to: URI.accountsPayable.payment.base }}
							linkAs={Link}
						>
							Accounts Payable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.accountsPayable.payment.base }}
							linkAs={Link}
						>
							Payments
						</Breadcrumb.Item>
						<Breadcrumb.Item>Check/Wire Payment</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={false}>
					<HeaderLight.Title>Check/Wire Payment</HeaderLight.Title>
					{/* Submit Button */}
					<Button variant="primary">Save</Button>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		return (
			<Form.Group>
				<Row xs={1} lg={2} className="">
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Select Type</Form.Label>
							</Col>
							<Col>
								<Form.Check
									inline
									label="Hand Check"
									name="check-group-type"
									type="radio"
									id={`check-group-type-1`}
									className="me-3"
									defaultChecked={true}
								/>
								<Form.Check
									inline
									label="Wire Transfer"
									name="check-group-type"
									type="radio"
									id={`check-group-type-2`}
									className="me-3"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2} className="">
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Check Number</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2} className="">
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Check Date</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Fiscal Month</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Select
											options={fakeReactSelectOptions()}
											className="react-select"
											placeholder="Please select"
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2} className="mb-5">
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Checking Account</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Select
											options={fakeReactSelectOptions()}
											className="react-select"
											placeholder="Please select"
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Trans. Desc</Form.Label>
							</Col>
							<Col>
								<Row lg={2}>
									<Col>
										<Form.Control type="text" placeholder="Please enter" />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height">
					<Container fluid className="ms-0">
						{this.renderContent()}
					</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(PaymentFormCheckWirePayment);
