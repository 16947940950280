import dayjs from 'dayjs';
import { Is, isNumeric } from '../helpers/Util';
import { getLocalStorage, setLocalStorage } from './LocalStorage';
// @ts-ignore
import HtmlToRtfBrowser from 'html-to-rtf-browser';

/**
 * Set local storage specific property's value.
 *
 * @param {string} objName Local storage object name/key.
 * @param {string} name Property name.
 * @param {mixed} value Property value.
 * @param {mixed} createProp Set to true to create object if doesn't exists.
 * @return {void}
 */
export function setLocalStorageKeyValueObject(
	objName: string,
	name: any,
	value: any,
	createProp?: boolean
): void {
	let object = getLocalStorage(objName, true);

	if (!Is.empty(object) || createProp === true) {
		object = !Is.empty(object) ? object : {};
		object[name] = value;

		setLocalStorage(objName, object, true);
	}
}

/**
 * Set draft status
 *
 * @param {boolean} state Draft state.
 * @return void
 */
export function setDraft(state: boolean): void {
	setLocalStorage('isDraft', state.toString());
}

/**
 * FormEvent class
 *
 * This class contains the method to handle form update.
 */
export class FormEvent {
	/**
	 * @var objKey The local storage object name.
	 */
	public objKey: string;

	/**
	 * @var createProp Create property if does not exists.
	 */
	public createProp?: boolean;

	/**
	 * @var htmlToRtfConverter Converter for properties that has rtf values
	 */
	htmlToRtfConverter: any;

	constructor(objKey: string, createProp?: boolean) {
		this.objKey = objKey;
		this.createProp = createProp;
		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	/**
	 * Handle text field change event.
	 *
	 * @param {any} event The event handler.
	 * @return {void}
	 */
	handleTextChange = (event: any): void => {
		const { name, value, dataType } = event.target;
		setLocalStorageKeyValueObject(
			this.objKey,
			name,
			isNumeric(value) && dataType !== 'string' ? parseFloat(value) : value,
			this.createProp
		);
		setDraft(true);
	};

	/**
	 * Handle date field change event.
	 *
	 * @param {string} format The date format.
	 * @param {string} date The date string.
	 * @return {void}
	 */
	handleDateChange = (format: string, name: string, date: string): void => {
		setLocalStorageKeyValueObject(
			this.objKey,
			name,
			dayjs(date).format(format),
			this.createProp
		);
		setDraft(true);
	};

	/**
	 * Handle select field change event.
	 *
	 * @param {any} name Field name.
	 * @param {any} select The select name and value.
	 * @return {void}
	 */
	handleSelectChange = (name: any, select: any): void => {
		setLocalStorageKeyValueObject(
			this.objKey,
			name,
			select.value,
			this.createProp
		);
		setDraft(true);
	};

	/**
	 * Handle checkbox field change event.
	 *
	 * @param {any} event The event handler.
	 * @return {void}
	 */
	handleCheckChange = (event: any): void => {
		const { name, checked } = event.target;
		setLocalStorageKeyValueObject(this.objKey, name, checked, this.createProp);
		setDraft(true);
	};

	/**
	 * Handle Wysiwyg change event.
	 *
	 * @param {any} name Field name.
	 * @param {any} object Wysiwyg return object with value.
	 * @return {void}
	 */
	handleWysiwygChange = (name: any, object: any, rtfName?: any): void => {
		setLocalStorageKeyValueObject(
			this.objKey,
			name,
			object.target.value,
			this.createProp
		);
		if (rtfName) {
			const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(
				object.target.html
			);
			setLocalStorageKeyValueObject(
				this.objKey,
				rtfName,
				valueHtml,
				this.createProp
			);
		}
		setDraft(true);
	};
}
