export namespace Resource {
	export namespace Image {
		export namespace Logo {
			export const NavigationLogo = require('./assets/images/logo.png');
			export const WarningLogo = require('./assets/images/404.gif');
		}
		export namespace Util {
			export const ErrorSeparator = require('./assets/images/separator-error.png');
		}
		export namespace Editor {
			export const Bold = require('./assets/images/bold.svg');
			export const Italic = require('./assets/images/italic.svg');
			export const Underline = require('./assets/images/underline.svg');
			export const Colour = require('./assets/images/colour.svg');
		}
		export const ClipperFallback = require('./assets/images/clipper-fallback.svg');
	}
}
