import React from 'react';
import { Form } from 'react-bootstrap';
import { delay } from 'lodash';
import MultiSearchComponents, {
	MultiSearchComponentsProps,
	MultiSearchComponentsState,
} from './MultiSearchComponents';

export default class MultiSearchComponentsNumberRange extends MultiSearchComponents {
	timeout: any;

	constructor(props: MultiSearchComponentsProps) {
		super(props);
		this.state = {
			props,
			numberRange: props.defaultValue ?? {},
			cleared: false,
		};

		this.timeout = null;
	}

	componentDidUpdate(
		prevProps: Readonly<MultiSearchComponentsProps>,
		prevState: Readonly<MultiSearchComponentsState>
	): void {
		const { isClear } = this.props;
		const { numberRange } = this.state;

		if (prevProps !== this.props) {
			this.setState({
				props: this.props,
				cleared: isClear,
				numberRange: isClear ? '' : numberRange,
			});
		}
	}

	render() {
		const { props, numberRange } = this.state;

		return (
			<div className="position-relative mb-2 me-2 bg-white px-2 py-1 rounded-1 border border-sand">
				<div className="d-flex align-items-center justify-content-between">
					<label className="me-2">
						<small>{props.label}</small>
					</label>
					<Form.Control
						type="number"
						value={numberRange?.from}
						placeholder={`From`}
						className={`form-control-sm me-2 w-80px input-no-arrows`}
						onChange={(e) =>
							props.handleChangeField(e.target.value, 'from', props)
						}
					/>
					<Form.Control
						type="number"
						value={numberRange?.to}
						placeholder={`To`}
						className={`form-control-sm ${
							this.isFixed ? '' : 'me-2'
						} w-80px input-no-arrows `}
						onChange={(e) =>
							props.handleChangeField(e.target.value, 'to', props)
						}
					/>
					{!this.isFixed && (
						<a
							className="ff-close-dropdown"
							onClick={() => props.handleDetach(props.id)}
						>
							<i className="ri-close-fill"></i>
						</a>
					)}
				</div>
			</div>
		);
	}
}
