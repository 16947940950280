import React from 'react';
import { Link, NavLink, generatePath } from 'react-router-dom';
import { Breadcrumb, Button, Container } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import ItemAddComponentAddInfo from './ItemAddComponentAddInfo';
import ItemAddComponentAddSpecification from './ItemAddComponentAddSpecification';
import ItemAddComponentAddOrderStatus from './ItemAddComponentAddOrderStatus';
import URI from '../../../../defaults/RoutesDefault';
import {
	getActiveMenuUri,
	getRouteWithParam,
	WithRouter,
} from '../../../../helpers/Router';
import { compareStr } from '../../../../helpers/String';
import { FooterFormAction } from '../../../components/Form';
import { Is, getSubComponent } from '../../../../helpers/Util';
import { ApiService } from '../../../../lib/api/HttpService';
import {
	displayAlert,
	displayAlertError,
	getErrorMessage,
} from '../../../../utilities/Response';
import { delay } from 'lodash';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import { renderSecureHideContent } from '../../../../utilities/modules/SecureContent';
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton';
import Documents from '../../documents/Documents';

class ItemAddComponentAdd extends ThreadComponent {
	constructor(props) {
		super(props);
		this.discValues = [
			{ value: 0, label: 'Markup', calcValue: 'dmMarkup' },
			{ value: -1, label: 'Discount', calcValue: 'dmDiscount' },
			{ value: 1, label: 'Fee', calcValue: 'DmFee' },
		];
		this.api = new ApiService();
		this.state = {
			newCompId: '',
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['info', 'specification', 'order-status', 'documents'],
				'info'
			),
			data: {},
			isChanged: false,
			projectName: '',
			project: [],
			associatedComponents: [],
			removedAssocIds: [],
			isCancel: false,
		};

		this.compTypePTypeMap = [
			{
				markup: 'markupmerc',
				disc: 'usediscountmerc',
				tax: 'taxm',
				key: 'm',
			},
			{
				markup: 'markupdesign',
				disc: 'usediscountdesign',
				tax: 'taxd',
				key: 'd',
			},
			{
				markup: 'markupfreight',
				disc: 'usediscountfreight',
				tax: 'taxf',
				key: 'f',
			},
			{
				markup: 'markupinstall',
				disc: 'usediscountinstall',
				tax: 'taxi',
				key: 'i',
			},
			{
				markup: 'markuplabor',
				disc: 'usediscountlabor',
				tax: 'taxl',
				key: 'l',
			},
			{
				markup: 'markupother',
				disc: 'usediscountother',
				tax: 'taxo',
				key: 'o',
			},
		];

		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.htmlToRtfConverter = new HtmlToRtfBrowser();
		this.infoRef = React.createRef();
		window.clearedSelect = [];
	}

	componentInit() {
		this.updatedData = {
			itemId: this.props.params.itemId,
			associatedComponentNumber: null,
			componentType: 0,
			createAutoComponents: false,
			setDefaultsFromItem: true,
		};
		this.uploadedImage = null;
		this.projectId = this.props.params.id;
		this.itemId = this.props.params.itemId;
		this.id = this.props.params.compId;
		this.isEditing = this.props.params.compId != null;
		this.isSaved = false;
		this.orderId = this.props.params.orderId;
		this.newAssocCompIds = [];

		this.setTitle(
			`${this.isEditing ? 'Edit' : 'Add'} Component`
		).setDocumentTitle();
	}

	async componentDidMount() {
		window.addEventListener('beforeunload', this.handleBeforeUnload);
		await this.fetchComponentIfNeeded();

		if (!this.isEditing) {
			const components = await this.api.getComponents(
				`?$filter=itemid eq ${this.itemId} AND projectid eq ${this.projectId}`
			);

			let stubData = {
				itemId: this.itemId,
				componentType: 0,
				associatedComponentNumber: null,
				createAutoComponents: false,
				setDefaultsFromItem: !components.length ? true : false,
			};

			let data = this.state.data;
			data = await this.getAdditionalData(data);
			data.shipto = this.state.projects[0].shipto;
			data.markup = this.state.projects[0].markupmerc;
			data.usedisc = this.state.projects[0].usediscountmerc;

			stubData.createAutoComponents =
				this.state.projects[0].autom ||
				this.state.projects[0].autod ||
				this.state.projects[0].autof ||
				this.state.projects[0].autoi ||
				this.state.projects[0].autol ||
				this.state.projects[0].autoo;

			let created;
			try {
				created = await this.api.createComponent(stubData);
			} catch {
				created = null;
			}

			if (created) {
				// This solved the issue on wrong component being retrieved.
				// Also, to fix the issue on local comment or remove the React.StrictMode on index.tsx
				const [component] = await this.api.getComponents(
					`?$filter=projectid eq ${this.projectId} and itemid eq ${this.itemId} and comp eq '${created.comp}'`
				);
				data.markup = component.markup;

				await this.getAdditionalData(component);

				const acomps = await this.api.getComponents(
					`?$filter=projectid eq ${this.projectId} and itemid eq ${this.itemId} and assocwithcomp eq '${component.comp}' and comp ne '${created.comp}'`
				);

				this.newAssocCompIds = acomps.map((obj) => obj.id);

				const associatedComponents = acomps.map((associatedComponent) => {
					const desc = `..... ${
						this.compTypePTypeMap[associatedComponent.comptype].desc
					} for component ${component.comp}`;
					return {
						id: associatedComponent.id,
						comptype: associatedComponent.comptype,
						usedisc: associatedComponent.usedisc,
						estcost: associatedComponent.estcost,
						markup: associatedComponent.markup,
						estprice: associatedComponent.estprice,
						taxable: associatedComponent.taxable,
						desc,
						descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
					};
				});

				if (!component.length) {
					data.itemName = component.itemName;
					data.descOnlyRtf = component.descOnlyRtf;
					data.taxable = component.taxable;
					data.quantity = component.quantity;
					data.unitmeasure = component.unitmeasure;
					data.primaryImage = component.primaryImage;
				}
				this.id = component.id;
				this.setState({
					comp: component.comp,
					newCompId: component.id,
					data: data,
					associatedComponents,
				});
			}
		}

		this.setIsLoaded(true);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		this.handleBeforeUnload();
	}

	handleBeforeUnload = () => {
		if (!this.isEditing && !this.isSaved && !this.state.isCancel) {
			let existingForDelete =
				JSON.parse(window.localStorage.getItem('forDelete')) || [];

			if (this.state.associatedComponents.length) {
				const newObjects = this.state.associatedComponents.map((obj) => ({
					deleteType: 2,
					objectType: 3,
					objectCodeOrId: obj.id,
				}));

				existingForDelete = existingForDelete.concat(newObjects);
			}

			const deleteObject = {
				deleteType: 2,
				objectType: 3,
				objectCodeOrId: this.id,
			};

			if (Array.isArray(existingForDelete)) {
				existingForDelete.push(deleteObject);
			} else {
				existingForDelete = [existingForDelete, deleteObject];
			}

			window.localStorage.setItem(
				'forDelete',
				JSON.stringify(existingForDelete)
			);
		}
	};

	async fetchComponentIfNeeded() {
		const projects = await this.api.getProjects(
			`?$filter=id eq ${this.projectId}`
		);

		this.setState({
			projectName: `${projects[0].projn} [${projects[0].proj}]`,
			projects,
			data: {
				...this.state.data,
				...{
					markupmerc: projects[0].markupmerc,
					markupdesign: projects[0].markupdesign,
					markupfreight: projects[0].markupfreight,
					markupinstall: projects[0].markupinstall,
					markuplabor: projects[0].markuplabor,
					markupother: projects[0].markupother,
					usediscountmerc: projects[0].usediscountmerc,
					usediscountdesign: projects[0].usediscountdesign,
					usediscountfreight: projects[0].usediscountfreight,
					usediscountinstall: projects[0].usediscountinstall,
					usediscountlabor: projects[0].usediscountlabor,
					usediscountother: projects[0].usediscountother,
					sidemark: projects[0].posidemark,
				},
			},
		});

		const company = await this.api.get('company');
		this.compTypePTypeMap = this.compTypePTypeMap.map((i) => ({
			...i,
			desc: company[`compview${i.key}`],
		}));

		if (this.isEditing) {
			let data;

			try {
				let items = await this.api.getComponents(`?$filter=id eq ${this.id}`);

				data = items[0] ?? {};
				if (data.usedisc === 1) {
					data.estcost = data.feecost;
				}

				if (this.id && Is.empty(data)) {
					this.setIsFound(false);
				}

				data = await this.getAdditionalData(data);
			} catch (error) {
				this.setState({
					stateUpdated: Math.random() * 1000,
				});
				this.setIsFound(false);
				this.setError(error, true);
			}

			this.setIsLoaded(true);
			this.renderData(data);
		}
	}

	async getAdditionalData(data) {
		data = await this.addComponentsData(data);
		data = await this.addImageData(data);

		return data;
	}

	async addImageData(data) {
		if (!data.primaryImageId) {
			return data;
		}

		data.primaryImage = await this.api.getFile(
			data.primaryImageId + '?maxHeight=300'
		);

		return data;
	}

	async addComponentsData(data) {
		if (data.comp !== data.assocwithcomp) {
			this.setState({
				associatedComponents: [],
			});
			return data;
		}

		// Vendors
		const acomps = await this.api.get(
			'components',
			"?$filter=proj eq '" +
				data.proj +
				"' and item eq '" +
				data.item +
				"' and assocwithcomp eq '" +
				data.assocwithcomp +
				"' and comp ne '" +
				data.comp +
				"'"
		);

		const associatedComponents = acomps.map((associatedComponent) => {
			return {
				id: associatedComponent.id,
				comptype: associatedComponent.comptype,
				estcost:
					associatedComponent.usedisc === 1
						? associatedComponent.feecost
						: associatedComponent.estcost,
				markup: associatedComponent.markup,
				estprice: associatedComponent.estprice,
				taxable: associatedComponent.taxable,
				usedisc: associatedComponent.usedisc,
				supplier: associatedComponent.supplier,
			};
		});

		this.setState({
			associatedComponents,
		});

		return data;
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: {
				...this.state.data,
				...(data ?? {}),
			},
		});
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.getAttribute('data-menu'))
		);
	};

	async setDescription() {
		const itemName =
			this.updatedData.itemName ?? this.state.data.itemName ?? '';
		const company = await this.api.get('company2');
		const descOnlyRtf =
			this.updatedData.descOnlyRtf ?? this.state.data.descOnlyRtf;
		if (descOnlyRtf) {
			const descOnly = this.updatedData.descOnly ?? this.state.data.descOnly;
			const itemNameFormatted = company.boldItemName
				? '{\\b ' + itemName + ' }\\sb70'
				: itemName;
			this.updatedData.descrtf = descOnlyRtf.replace(
				'{\\pard',
				`{\\pard ${itemNameFormatted.replace(' ', '\\~')}\\line`
			);

			this.updatedData.desc = itemName + '\r\n' + descOnly;
		}
	}

	async saveAssociatedComponents() {
		if (
			this.state.associatedComponents?.length >= 1 &&
			this.state.associatedComponents[0].id
		) {
			for await (let associatedComponent of this.state.associatedComponents) {
				if (associatedComponent.usedisc === 1) {
					associatedComponent.feecost = associatedComponent.estcost;
					delete associatedComponent.estcost;
					delete associatedComponent.feeunitcost;
					delete associatedComponent.estunitcost;
				}

				if (associatedComponent.usedisc === -1) {
					const recalculated = await this.api.componentRecalculate(
						associatedComponent.id,
						{
							whatChanged: 'cwcListPrice',
							estCost: associatedComponent.estcost,
							list: associatedComponent.estcost,
						}
					);
					associatedComponent.estunitcost = associatedComponent.estcost;
					associatedComponent.list = associatedComponent.estcost;
					associatedComponent.estprice = recalculated.estPrice;
					associatedComponent.estunitprice = recalculated.estUnitPrice;
				}

				associatedComponent.supplier =
					(this.updatedData.supplier ?? this.state.data.supplier) || null;
				associatedComponent.vendorName =
					this.updatedData.selectedVendor?.label ?? this.state.data.vendorName;

				if (associatedComponent.estcost === null) {
					associatedComponent.estcost = 0;
				}
				await this.api.editComponent(
					associatedComponent.id,
					associatedComponent
				);
			}
		} else if (!this.isEditing && this.state.associatedComponents?.length > 0) {
			const associatedComponent = this.state.associatedComponents[0];
			await this.api.createComponent({
				itemId: this.itemId,
				componentType: associatedComponent.comptype,
				associatedComponentNumber: this.state.comp ?? this.state.data.comp,
				createAutoComponents: false,
				setDefaultsFromItem: false,
			});
		}
	}

	handleSaveClick = async (e) => {
		e.preventDefault();
		this.dMloader(true);

		if (
			this.updatedData.hasOwnProperty('descOnlyRtf') ||
			this.updatedData.hasOwnProperty('itemName')
		) {
			await this.setDescription();
		}

		this.updatedData = {
			...this.updatedData,
			assocwithcomp: this.state.comp,
		};

		if (this.latestWhatChanged) {
			await this.infoRef.current.recalculate(
				this.latestWhatChanged,
				this.state.activeMenu === 'info',
				async () => {
					await this.editComponent();
				}
			);
		} else {
			await this.editComponent();
		}
	};

	adjustData(updatedData) {
		let data = updatedData;

		data.supplier =
			(this.updatedData.supplier ?? this.state.data.supplier) || null;

		if (data.usedisc === 0) {
			delete data.estdisc;
			delete data.feeunitcost;
			delete data.feecost;

			if (data.useterms) {
				delete data.estunitcost;
				delete data.estcost;
			} else {
				delete data.unitlist;
				delete data.list;
				delete data.bterm1;
				delete data.bterm2;
				delete data.bterm3;
			}
		} else if (data.usedisc === 1) {
			delete data.feeunitcost;
			delete data.unitlist;
			delete data.list;
			delete data.estdisc;
			delete data.bterm1;
			delete data.bterm2;
			delete data.bterm3;
			delete data.estunitcost;
			delete data.estcost;
			delete data.estmarkup;
		} else if (data.usedisc === -1) {
			delete data.estmarkup;
			delete data.feeunitcost;
			delete data.feecost;
			delete data.bterm1;
			delete data.bterm2;
			delete data.bterm3;

			if (data.useterms) {
				delete data.estunitcost;
				delete data.estcost;
			}
		}

		delete data.selectedVendor;
		delete data.selectedShipto;

		return data;
	}

	editComponent = async () => {
		try {
			await this.saveAssociatedComponents();
			await this.uploadImage(this.id);

			await this.api
				.editComponent(this.id, this.adjustData(this.updatedData))
				.then(() => {
					window.removeEventListener('beforeunload', this.handleBeforeUnload);
					window.localStorage.removeItem('forDelete');
					this.isSaved = true;
					this.dMloader(false, true);
					displayAlert('success', 'Successfully updated component');
					if (this.orderId) {
						delay(() => {
							this.props.navigate(
								getRouteWithParam(
									window.location.pathname.indexOf('/add-purchase-order/') > -1
										? URI.project.vendorOrder.editPurchaseOrder
										: URI.project.vendorOrder.editWorkOrder,
									{
										id: this.props.params.id,
										orderId: this.orderId,
									}
								)
							);
						}, 1500);
					} else {
						delay(() => {
							this.props.navigate(
								generatePath(URI.project.itemEdit, {
									id: this.props.params.id,
									itemId: this.props.params.itemId,
								})
							);
						}, 1200);
					}
				});
		} catch (err) {
			const errorMessage =
				err.response?.data?.userError || 'Component could not be saved';
			this.dMloader(false, true);
			displayAlert('danger', errorMessage);
		} finally {
			const stubData = {
				whatChanged: 'iwcRecalcFromComponentsAndStatus',
				saveChanges: true,
			};
			await this.api.itemRecalculate(this.itemId, stubData);
		}
	};

	handleDataChange = (data) => {
		this.updatedData[data.key] = data.value;
		this.setState({ isChanged: true });
	};

	async uploadImage(componentId) {
		if (!this.uploadedImage) {
			return;
		}

		let uploadedImage = await this.api.saveFile({
			file: this.uploadedImage,
			ObjectType: 'component',
			ObjectId: componentId,
			FileType: 1,
		});

		this.updatedData.primaryImageId = uploadedImage.id;

		this.setState({ isChanged: true });

		if (this.state.data.primaryImageId) {
			this.api.deleteFile(this.state.data.primaryImageId);
		}
	}

	onAcceptedFiles = (file) => {
		this.uploadedImage = file;
		this.updatedData.copyitempict = false;
		this.setState({ isChanged: true });
	};

	onRemoveFiles = () => {
		this.setState({
			isChanged: true,
			data: {
				...this.state.data,
				primaryImage: null,
			},
		});
		this.uploadedImage = null;
		this.updatedData.primaryImageId = null;
		this.updatedData.copyitempict = false;
	};

	async deleteSubComponents() {
		if (this.state.associatedComponents.length) {
			for await (let associatedComponent of this.state.associatedComponents) {
				await this.api
					.deleteObjects({
						deleteType: 2,
						objectType: 3,
						objectCodeOrId: associatedComponent.id,
					})

					.then((res) => {})
					.catch((error) => {
						this.errIds.push(id);
					});
			}
			this.setState({
				associatedComponents: [],
			});
		}
	}

	async handleDelete() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
		window.localStorage.removeItem('forDelete');
		this.setState({
			isCancel: true,
		});
		await this.deleteSubComponents();
		await this.api
			.deleteObjects({
				deleteType: 2,
				objectType: 3,
				objectCodeOrId: this.id,
			})

			.then((res) => {
				if (res.message) {
					displayAlertError(res.message);
				}
			})
			.catch((error) => {
				this.errIds.push(id);
			});

		this.props.navigate(
			'/projects/' + this.projectId + '/item/edit/' + this.itemId
		);
	}

	handleClick = (e) => {
		if (!this.isEditing) {
			if (this.state.isChanged) {
				if (confirm('Leaving this screen will discard your changes.')) {
					this.handleCancel();
				}
			} else {
				this.handleCancel();
			}
		} else {
			window.removeEventListener('beforeunload', this.handleBeforeUnload);
			location.href =
				'/projects/' + this.projectId + '/item/edit/' + this.itemId;
		}
	};

	handleCancel = () => {
		this.handleDelete();
	};

	handleAddAssociatedComponent = async () => {
		const associatedComponents = this.state.associatedComponents;
		const comp = await this.api.createComponent({
			itemId: this.itemId,
			componentType: 0,
			associatedComponentNumber: this.state.comp ?? this.state.data.comp,
			createAutoComponents: false,
			setDefaultsFromItem: false,
		});

		const componentToAdd = {
			id: comp.id,
			comptype: 0,
			supplier: this.state.data.supplier,
			verndorName: this.state.data.vendorName,
			...this.getProjectDefault(0, true),
		};

		componentToAdd.markup = comp.markup;

		associatedComponents.push(componentToAdd);
		this.setState((prev) => {
			prev.associatedComponents = associatedComponents;
			return prev;
		});
	};

	handleRemoveAssociatedComponent = async (index) => {
		let { associatedComponents } = this.state;
		const selectedComp = associatedComponents[index];

		if (selectedComp.id) {
			await this.api
				.deleteObjects({
					deleteType: 2,
					objectType: 3,
					objectCodeOrId: selectedComp.id,
				})
				.catch((error) => {
					displayAlertError(getErrorMessage(error));
				});
		}
		associatedComponents.splice(index, 1);

		this.setState((prev) => {
			prev.associatedComponents = associatedComponents;
			return prev;
		});
	};

	getProjectDefault(value, useDefaults) {
		const project = this.state.projects[0];
		const pType = this.compTypePTypeMap[value];
		const desc = `..... ${this.compTypePTypeMap[value].desc} for component ${
			this.state.data.comp ?? this.state.comp
		}`;
		const descOnlyRtf = this.htmlToRtfConverter.convertHtmlToRtf(desc);
		const descOnly = desc;

		// %Type - Fee
		if (project[pType.disc] === 1) {
			const estcost = this.updatedData?.estprice ?? this.state.data.estprice;
			const estprice =
				estcost && project[pType.markup]
					? (estcost * project[pType.markup]) / 100
					: 0;
			return {
				estcost,
				markup: project[pType.markup],
				estprice,
				taxable: project[pType.tax],
				desc,
				descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
				descOnlyRtf,
				descOnly,
				usedisc: project[pType.disc],
			};
		} else if (useDefaults) {
			return {
				estcost: 0,
				markup: project[pType.markup],
				estprice: 0,
				taxable: project[pType.tax],
				desc,
				descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
				descOnlyRtf,
				descOnly,
				usedisc: project[pType.disc],
			};
		}
		return {
			markup: project[pType.markup],
			taxable: project[pType.tax],
			desc,
			descrtf: this.htmlToRtfConverter.convertHtmlToRtf(desc),
			descOnlyRtf,
			descOnly,
			usedisc: project[pType.disc],
		};
	}

	updateChildComponents = (associatedComponents) => {
		this.setState({ associatedComponents });
	};

	handleAssociatedComponentChange = async (
		index,
		key,
		value,
		doNotOverwriteMarkup,
		doNotOverwriteDiscounts
	) => {
		let associatedComponents = [...this.state.associatedComponents];
		const itemCopy = { ...associatedComponents[index] };
		associatedComponents[index][key] = value;

		if (key === 'estcost') {
			associatedComponents[index]['estunitcost'] = value;
		}

		if (key === 'comptype') {
			const recalculated = await this.api.componentRecalculate(
				associatedComponents[index].id,
				{
					...associatedComponents[index],
					compType: value,
					whatChanged: 'cwcComponentType',
				}
			);

			itemCopy.usedisc = this.discValues.find(
				(discValue) => discValue.calcValue === recalculated.useDisc
			).value;

			itemCopy.estcost = recalculated.estCost;
			itemCopy.estprice = recalculated.estPrice;
			itemCopy.comptype = value;
			itemCopy.markup = recalculated.markup;
			const desc = `..... ${this.compTypePTypeMap[value].desc} for component ${
				this.state.data.comp ?? this.state.comp
			}`;
			const descOnlyRtf = this.htmlToRtfConverter.convertHtmlToRtf(desc);

			associatedComponents[index] = {
				...associatedComponents[index],
				...itemCopy,
				desc,
				descOnly: desc,
				descOnlyRtf,
			};
		}

		if (doNotOverwriteMarkup && associatedComponents[index]) {
			associatedComponents[index].markup = itemCopy.markup;
		}

		if (doNotOverwriteDiscounts && associatedComponents[index]) {
			associatedComponents[index].usedisc = itemCopy.usedisc;
		}

		this.setState({
			associatedComponents: associatedComponents,
		});
	};

	handleAssociatedComponentBlur = async () => {
		let associatedComponents = this.state.associatedComponents;
		for (let i = 0; i < associatedComponents.length; i++) {
			if (Number(associatedComponents[i].estcost) <= 0) {
				continue;
			}
			// The component has a discount
			if (associatedComponents[i].usedisc === -1) {
				const currentPrice = Number(associatedComponents[i].estcost);
				const discount = (currentPrice * associatedComponents[i].markup) / 100;

				associatedComponents[i]['estprice'] = currentPrice - discount;
			} else if (associatedComponents[i].usedisc === 1) {
				const recalculated = await this.recalculate(associatedComponents[i]);
				associatedComponents[i]['estprice'] = recalculated.estPrice;
			} else {
				const cost = this.handleNaN(Number(associatedComponents[i]['estcost']));
				const markup =
					this.handleNaN(Number(associatedComponents[i]['markup'])) / 100;
				associatedComponents[i]['estprice'] = cost + cost * markup;
			}
		}

		this.setState({
			associatedComponents: associatedComponents,
		});
	};

	async recalculate(associatedComponent, whatChanged = 'estcost') {
		const whatChangedMapping = {
			estcost: 'cwcEstimatedCost',
			taxable: 'cwcTaxable',
			markup: 'cwcPercent',
			estprice: 'cwcEstimatedPrice',
		};

		const useDiscMapping = { '-1': 'dmDiscount', 0: 'dmMarkup', 1: 'dmFee' };

		return await this.api.componentRecalculate(associatedComponent.id, {
			whatChanged: whatChangedMapping[whatChanged],
			markup: associatedComponent.markup,
			useDisc: useDiscMapping[associatedComponent.usedisc],
			estCost:
				useDiscMapping[associatedComponent.usedisc] !== 'dmFee'
					? Number(associatedComponent.estcost)
					: 0,
			feeCost:
				useDiscMapping[associatedComponent.usedisc] === 'dmFee'
					? Number(associatedComponent.estcost)
					: 0,
			estDisc: 0,
			estPrice: associatedComponent.estprice,
			useTerms: associatedComponent.isBuyingTermsChecked ?? 0,
			taxable: associatedComponent.taxable,
		});
	}

	handleNaN(value) {
		return isNaN(value) ? 0 : value;
	}

	handleRef = (ref) => {
		this.infoRef.current = ref;
	};

	handleOnBlur = (whatChanged) => {
		this.latestWhatChanged = whatChanged;
	};

	updateComponentsDefaults = async (id) => {
		const vendorData = await this.api.getVendors(`?$filter=id eq ${id}`);

		if (!vendorData) {
			return;
		}

		const [vendor] = vendorData;
		if (!vendor) {
			return;
		}

		const vendorDefaults = [
			{
				comptype: 0,
				markup: vendor?.markupmerc,
			},
			{
				comptype: 1,
				markup: vendor?.markupdesign,
			},
			{
				comptype: 2,
				markup: vendor?.markupfreight,
			},
			{
				comptype: 3,
				markup: vendor?.markupinstall,
			},
			{
				comptype: 4,
				markup: vendor?.markuplabor,
			},
		];

		const isVendorBuyingTermsChecked =
			vendor.bterm1 !== 0 || vendor.bterm2 !== 0 || vendor.bterm3 !== 0
				? true
				: false;

		this.setState({
			useVendorDefaults: vendor?.usemarkupspecs,
			data: Object.assign({}, this.state.data, {
				vendorDefaults,
				isVendorBuyingTermsChecked,
				bterm1: vendor.bterm1,
				bterm2: vendor.bterm2,
				bterm3: vendor.bterm3,
			}),
		});

		if (vendor?.usemarkupspecs) {
			this.state.associatedComponents?.map((component) => {
				switch (component.comptype) {
					case 0:
						component.markup = vendor.markupmerc;
						break;

					case 1:
						component.markup = vendor.markupdesign;
						break;
					case 2:
						component.markup = vendor.markupfreight;
						break;
					case 3:
						component.markup = vendor.markupinstall;
						break;
					case 4:
						component.markup = vendor.markuplabor;
						break;
				}
			});
		}
		return isVendorBuyingTermsChecked;
	};

	header() {
		const { activeMenu } = this.state;
		const { data } = this.state;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						onClick={this.handleClick}
						// to="/projects"
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>

					{(() => {
						if (this.props.params.compId) {
							return (
								<Breadcrumb className="ms-4">
									<Breadcrumb.Item
										linkProps={{ to: '/projects' }}
										linkAs={Link}
									>
										Projects
									</Breadcrumb.Item>
									<Breadcrumb.Item
										linkProps={{
											to: getRouteWithParam(URI.project.viewPage, {
												id: this.props.params.id,
												page: 'items',
											}),
										}}
										linkAs={Link}
									>
										{this.state.projectName}
									</Breadcrumb.Item>
									{this.orderId ? (
										<>
											<Breadcrumb.Item
												linkProps={{
													to: getRouteWithParam(URI.project.viewPage, {
														id: this.props.params.id,
														page: 'vendor-orders',
													}),
												}}
												linkAs={Link}
											>
												{window.location.pathname.indexOf(
													'/add-purchase-order/'
												) > -1
													? 'Purchase Order'
													: 'Work Order'}
											</Breadcrumb.Item>
											<Breadcrumb.Item
												linkProps={{
													to: getRouteWithParam(
														window.location.pathname.indexOf(
															'/add-purchase-order/'
														) > -1
															? URI.project.vendorOrder.editPurchaseOrder
															: URI.project.vendorOrder.editWorkOrder,
														{
															id: this.props.params.id,
															orderId: this.orderId,
														}
													),
												}}
												linkAs={Link}
											>
												{this.orderId}
											</Breadcrumb.Item>
										</>
									) : (
										<>
											<Breadcrumb.Item
												linkProps={{
													to: getRouteWithParam(URI.project.viewPage, {
														id: this.props.params.id,
														page: 'items',
													}),
												}}
												linkAs={Link}
											>
												Items
											</Breadcrumb.Item>
											<Breadcrumb.Item
												linkProps={{
													to: getRouteWithParam(URI.project.itemEdit, {
														id: this.props.params.id,
														itemId: this.props.params.itemId,
													}),
												}}
												linkAs={Link}
											>
												{this.state.data.item}
											</Breadcrumb.Item>
										</>
									)}
									<Breadcrumb.Item>Edit Component</Breadcrumb.Item>
								</Breadcrumb>
							);
						} else {
							return (
								<Breadcrumb className="ms-4">
									<Breadcrumb.Item
										linkProps={{ to: '/projects' }}
										linkAs={Link}
									>
										Projects
									</Breadcrumb.Item>
									<Breadcrumb.Item
										linkProps={{
											to: getRouteWithParam(URI.project.viewPage, {
												id: this.props.params.id,
												page: 'items',
											}),
										}}
										linkAs={Link}
									>
										{this.state.projectName}
									</Breadcrumb.Item>
									<Breadcrumb.Item
										linkProps={{
											to: getRouteWithParam(URI.project.viewPage, {
												id: this.props.params.id,
												page: 'items',
											}),
										}}
										linkAs={Link}
									>
										Items
									</Breadcrumb.Item>
									<Breadcrumb.Item
										linkProps={{
											to: getRouteWithParam(URI.project.itemEdit, {
												id: this.props.params.id,
												itemId: this.props.params.itemId,
											}),
										}}
										linkAs={Link}
									>
										Edit Item
									</Breadcrumb.Item>
									<Breadcrumb.Item>Add Component</Breadcrumb.Item>
								</Breadcrumb>
							);
						}
					})()}
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>
						{this.state.data.itemName
							? `Edit Component: ${this.state.data.itemName}`
							: 'Edit Component:'}
					</HeaderLight.Title>
					<div>
						{!this.isEditing && (
							<Button
								className="me-2"
								variant="light"
								onClick={this.handleCancel}
							>
								Cancel
							</Button>
						)}

						<SecureBootstrapButton
							attributeNo={14}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							onClick={this.handleSaveClick}
						>
							Save
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
				{data?.item && data?.comp && (
					<h6 className="mb-4">{`Ref. No. ${data?.item}/${data?.comp}`}</h6>
				)}
				<HeaderLight.Actions className="text-charcoal">
					<li>
						<Button
							as={Link}
							to={getRouteWithParam(URI.project.itemAdd, {
								id: this.projectId,
							})}
							data-menu="info"
							className={`${compareStr(activeMenu, 'info', 'active', '')}`}
							onClick={this.handleMenuClick}
						>
							Info
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							to={getRouteWithParam(URI.project.itemAdd, {
								id: this.projectId,
							})}
							data-menu="specification"
							className={`${compareStr(
								activeMenu,
								'specification',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Special Instructions
						</Button>
					</li>
					<li>
						{renderSecureHideContent(
							<>
								<Button
									as={Link}
									to={getRouteWithParam(URI.project.itemAdd, {
										id: this.projectId,
									})}
									data-menu="order-status"
									className={`${compareStr(
										activeMenu,
										'order-status',
										'active',
										''
									)}`}
									onClick={this.handleMenuClick}
								>
									Order Status
								</Button>
							</>
						)(18, SECURITY_ATTRIBUTE_TYPES.DenySpec3)}
					</li>
					{this.isEditing && (
						<li>
							<Button
								as={Link}
								data-menu="documents"
								className={`${compareStr(
									activeMenu,
									'documents',
									'active',
									''
								)}`}
								onClick={this.handleMenuClick}
							>
								Documents{' '}
								{this.state.data.numAttachments > 0 && (
									<i className="ri-attachment-2 ms-1"></i>
								)}
							</Button>
						</li>
					)}
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	getContent() {
		const { activeMenu, data } = this.state;

		return getSubComponent(activeMenu, {
			info: (
				<ItemAddComponentAddInfo
					onRef={this.handleRef}
					compId={this.id ?? this.state.newCompId}
					data={data}
					updateChildComponents={this.updateChildComponents}
					uploadedImage={this.uploadedImage}
					updatedData={this.updatedData}
					project={this.state.projects?.[0]}
					onDataChange={this.handleDataChange}
					onAcceptedFiles={this.onAcceptedFiles}
					onRemoveFiles={this.onRemoveFiles}
					onRecalculate={(data) => {
						this.updatedData = {
							...this.updatedData,
							...data,
						};
					}}
					associatedComponents={this.state.associatedComponents}
					onAddAssociatedComponent={this.handleAddAssociatedComponent}
					onRemoveAssociatedComponent={this.handleRemoveAssociatedComponent}
					onAssociatedComponentChange={this.handleAssociatedComponentChange}
					onAssociatedComponentBlur={this.handleAssociatedComponentBlur}
					onBlur={this.handleOnBlur}
					updateComponentsDefaults={this.updateComponentsDefaults}
					useVendorDefaults={this.state?.useVendorDefaults}
				/>
			),
			specification: (
				<ItemAddComponentAddSpecification
					data={data}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			),
			'order-status': (
				<ItemAddComponentAddOrderStatus
					data={data}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			),
			documents: (
				<Documents
					objectType="Component"
					objectId={this.id}
					onRefresh={(numAttachments) => {
						this.setState({
							data: {
								...this.state.data,
								numAttachments: numAttachments,
							},
						});
					}}
				/>
			),
		});
	}

	render() {
		return this.renderView(
			<>
				{this.header()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid>{this.getContent()}</Container>
				</div>

				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={14}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
						variant="primary"
						size="lg"
						onClick={this.handleSaveClick}
					>
						Save
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(ItemAddComponentAdd);
