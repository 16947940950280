import React, { useEffect, useState } from 'react';
import SelectCreatableAsyncPaginate from './SelectCreatableAsyncPaginate';
import getAsyncReactSelectOptions from './utils/AsyncDropdownOptions';
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncDropdownLoadingIndicator from './utils/AsyncDropdownLoadingIndicator';
import {
	getServiceFn,
	overrideQueryProperty,
} from './utils/AsyncDropdownFunctions';

/**
 * AsyncEmployeesDropdown
 *
 * @param props                             - the `react-select` properties
 * @param string     `props.urlQuery`       - set the default query parameters
 * @param boolean    `props.creatable`      - set true to enable create option
 * @param string     `props.fieldKey`       - used for search and sort if sort is not added to urlQuery
 * @param object     `props.value`          - default value with `label` and `value` properties
 * @param object     `props.defaultValue`   - similar with value with `label` and `value` as properties
 * @param string     `props.valueKey`       - set the property name/key to be used as the select value: 'id', 'value', 'key'
 * @param any        `(...rest)`            - the rest of the `react-select` properties
 *
 * @returns `React.Component`
 */
export default function AsyncEmployeesDropdown(props: any) {
	const type = 'employee';
	const [value, setValue] = useState(
		(props.value ?? props.defaultValue) as any
	);

	const { loadOptions } = getAsyncReactSelectOptions({
		props: overrideQueryProperty(type, props),
		getDataFn: getServiceFn(type),
		field: props.fieldKey ?? 'vendorn',
		searchKeys: ['vendor'],
		valueKey: props.valueKey ?? 'key',
	});

	/* *****************
	 * DID MOUNT/UPDATE
	 * ***************** */
	useEffect(() => {
		setValue(props.value ?? props.defaultValue);
	}, [props.defaultValue, props.value]);

	const Component =
		props.creatable === true ? SelectCreatableAsyncPaginate : AsyncPaginate;

	return (
		<Component
			{...props}
			value={value}
			loadOptions={loadOptions}
			additional={{
				page: 1,
			}}
			cacheOptions
			components={{ LoadingIndicator: AsyncDropdownLoadingIndicator }}
		/>
	);
}

AsyncEmployeesDropdown.displayName = 'AsyncEmployeesDropdown';
