import React from 'react';
import ImagesFanFrame from '../ImagesFanFrame/ImagesFanFrame';
import { formatDate } from 'legacy/utilities/formatDate';
import { formatCurrency } from 'legacy/utilities/formatCurrency';
import Icon from '../Icon/Icon';
import CreateItemIcon from '../../../assets/images/icons/create_item_icon.svg';
import CreateTimeEntryIcon from '../../../assets/images/icons/Create_Time_Entry-Icon.svg';
import { updateTimeEntryFilter } from 'legacy/utilities/updateTimeEntryFilter';
import { useNavigate } from 'react-router-dom';

interface IProjectCardProps {
	date: string;
	budget: number;
	projectName: string;
	clientName: string;
	hasAccessToFinancialInfo: boolean;
	amountReceived: number;
	totalAmount: number;
	primaryImages?: [];
	projectId?: number;
	projectCode: string;
}

const RecentProjectCard = ({
	date,
	budget,
	projectName,
	clientName,
	hasAccessToFinancialInfo,
	amountReceived,
	totalAmount,
	primaryImages = [],
	projectId,
	projectCode,
}: IProjectCardProps) => {
	const navigate = useNavigate();
	const receivedPercentage = (amountReceived / totalAmount) * 100;

	const handleButtonClick = (
		type: string,
		e?: React.MouseEvent<HTMLDivElement>
	) => {
		e?.stopPropagation();
		if (type === 'timeEntry' && projectId) {
			updateTimeEntryFilter(projectName, projectCode, projectId);
			navigate('/time-entry');
		} else {
			navigate(`/projects/${projectId}/item/add`);
		}
	};

	return (
		<div
			className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-lg tw-w-[200px] tw-min-w-[200px] md:tw-min-w-[300px] md:tw-w-[300px] tw-mr-4 tw-h-[400px] tw-overflow-hidden tw-cursor-pointer"
			onClick={() => projectId && navigate(`/projects/${projectId}/items`)}
		>
			<div className="tw-flex tw-justify-center tw-overflow-hidden tw-h-[140px]">
				{projectId && (
					<div className="tw-absolute tw-z-10	tw-top-[10px] tw-right-[4px]">
						<Icon
							path={CreateItemIcon}
							alt="creaate-item-icon"
							iconClass="tw-mr-[4px] tw-rounded-full tw-bg-black tw-p-1.5 tw-h-[24px] tw-w-[24px] tw-cursor-pointer"
							title="Create Item"
							onClickIcon={(e?: React.MouseEvent<HTMLDivElement>) =>
								handleButtonClick('item', e)
							}
						/>
						<Icon
							path={CreateTimeEntryIcon}
							alt="creaate-timeentry-icon"
							iconClass="tw-rounded-full tw-bg-black tw-p-1.5 tw-h-[24px] tw-w-[24px] tw-cursor-pointer"
							title="Create Time Entry"
							onClickIcon={(e?: React.MouseEvent<HTMLDivElement>) =>
								handleButtonClick('timeEntry', e)
							}
						/>
					</div>
				)}
				<ImagesFanFrame images={primaryImages} />
			</div>
			<div className="tw-p-4 tw-flex tw-flex-col tw-justify-around tw-h-[250px]">
				<div className="tw-flex tw-justify-between tw-text-gray-500 tw-text-sm">
					<span className="tw-mr-3">{formatDate(date)}</span>
					{hasAccessToFinancialInfo && budget !== 0 && (
						<span className="tw-text-right">
							{formatCurrency(budget)} Budget
						</span>
					)}
				</div>
				<h3 className="tw-text-base tw-mt-2">{projectName}</h3>
				<p className="tw-text-gray-500">{clientName}</p>
				{hasAccessToFinancialInfo && (
					<div className="tw-flex tw-flex-col tw-mt-4">
						<span>{formatCurrency(amountReceived)} Received</span>
						<div className="tw-bg-gray-200 tw-rounded-full tw-h-2.5 tw-w-full tw-ml-2">
							<div
								className="tw-bg-[#006a53] tw-h-2.5 tw-rounded-full"
								style={{ width: `${receivedPercentage}%` }}
							></div>
						</div>
					</div>
				)}
				{hasAccessToFinancialInfo && (
					<div className="tw-text-right tw-text-gray-500 tw-text-sm tw-mt-2">
						{formatCurrency(totalAmount)} Total
					</div>
				)}
			</div>
		</div>
	);
};

RecentProjectCard.displayName = 'RecentProjectCard';

export default RecentProjectCard;
