import React from 'react';
import { Form } from 'react-bootstrap';
import { delay } from 'lodash';
import MultiSearchComponents, {
	MultiSearchComponentsProps,
	MultiSearchComponentsState,
} from './MultiSearchComponents';

export default class MultiSearchComponentsText extends MultiSearchComponents {
	timeout: any;

	constructor(props: MultiSearchComponentsProps) {
		super(props);
		this.state = {
			props,
			textValue: props.defaultValue ?? '',
			cleared: false,
		};

		this.timeout = null;
	}

	componentDidUpdate(
		prevProps: Readonly<MultiSearchComponentsProps>,
		prevState: Readonly<MultiSearchComponentsState>
	): void {
		const { isClear } = this.props;
		const { textValue } = this.state;

		if (prevProps !== this.props) {
			this.setState({
				props: this.props,
				cleared: isClear,
				textValue: isClear ? '' : textValue,
			});
		}
	}

	isNumber(val: string) {
		const re = /^-?\d*\.?\d{0,6}$/;
		const strVal = val.toString();
		const { length } = strVal;
		const { max, min } = this.state.props;

		if (min !== undefined && parseFloat(val) < min) return false;
		if (max !== undefined && parseFloat(val) > max) return false;
		if (val === '') return true;
		if (length === 1 && strVal[0] === '-') return true;
		if (isNaN(parseFloat(val))) return false;

		return val.match(re) !== null;
	}

	render() {
		const { props, textValue } = this.state;

		return (
			<div
				className={`position-relative mb-2 me-2 rounded-1 ${
					this.isFixed ? '' : 'border border-sand pe-2 bg-white '
				}`}
				style={{
					...props.style,
					zIndex: 700 - (props?.zIndex ?? 0),
				}}
			>
				<div className="d-flex align-items-center justify-content-between">
					<div className="daterange-labeled-group position-relative">
						{this.renderLabel('textValue')}
						<Form.Control
							value={textValue}
							type={'text'}
							placeholder={props.label}
							className={`me-1 w-100 form-control-sm py-1 input-no-arrows ${
								this.isFixed ? '' : 'no-border'
							}`}
							onChange={(e) => {
								const val = e.target.value;

								if (props.type === 'number' && !this.isNumber(val)) {
									return;
								}

								this.setValue('textValue', val);
								clearTimeout(this.timeout);
								this.timeout = delay(() => {
									props.handleChangeField(val, props);
								}, 700);
							}}
						/>
					</div>
					{!this.isFixed && (
						<a
							className="ff-close-dropdown"
							onClick={() => {
								props.handleDetach(props.id);
							}}
						>
							<i className="ri-close-fill"></i>
						</a>
					)}
				</div>
			</div>
		);
	}
}
