import { Company } from 'legacy/lib/api/types/Company';
import { Component } from 'legacy/lib/api/types/Component';
import { CompViews } from 'legacy/lib/api/types/CompView';

// Need to do a require because this module doesn´t have any types
/* eslint-disable @typescript-eslint/no-var-requires */

const HtmlToRtfBrowser = require('html-to-rtf-browser').default;

const getDescriptionForSubComponent = (
	mainComponentComp: string,
	component: Component,
	company: Company
) => {
	const formatter = new HtmlToRtfBrowser();
	const type = CompViews[component.comptype];
	const desc = `..... ${
		company[type as keyof Company]
	} for component ${mainComponentComp}`;
	const descOnlyRtf = formatter.convertHtmlToRtf(desc).replaceAll('\\~', ' ');
	return {
		desc,
		descOnly: desc,
		descOnlyRtf,
		descrtf: formatter.convertHtmlToRtf(desc),
	};
};

export default getDescriptionForSubComponent;
