import { useMutation } from '@tanstack/react-query';
import {
	postTemporaryReconciliationsTotals,
	type ITemporaryReconciliationsTotalsParams,
} from 'api/resources/temporaryReconciliations';

export const usePostTemporaryReconciliationsTotals = () => {
	return useMutation((params: ITemporaryReconciliationsTotalsParams) =>
		postTemporaryReconciliationsTotals(params)
	);
};
