import React from 'react';
import { Col, Row, Form, Container, Breadcrumb } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { getRouteWithParam, WithRouter } from '../../../helpers/Router';
import { FooterFormAction } from '../../components/Form';
import URI from '../../../defaults/RoutesDefault';
import { generatePath, Link, NavLink } from 'react-router-dom';
import { HeaderLight } from '../../components/Header';
import WysiwygEditor from '../../ui-kit/WysiwygEditor';
import Select from 'react-select';
import { ApiService } from '../../../lib/api/HttpService';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../utilities/LocalStorage';
import { getSaveButtonName } from '../../../utilities/String';
import {
	displayAlertLoader,
	handleResponseAlert,
} from '../../../utilities/Response';
import { Is } from '../../../helpers/Util';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../utilities/Rtf';
import MSG from '../../../defaults/Message';
import { delay } from 'lodash';
import { setDraft } from '../../../utilities/FormEvent';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import { nameToCode, formatCode } from 'legacy/app/utils/codes';

const TAXABLE_OPTIONS = [
	{
		label: 'Use Project',
		value: 0,
	},
	{
		label: 'Taxable',
		value: 1,
	},
	{
		label: 'Non Taxable',
		value: 2,
	},
];

class GlossaryAddTimeActivities extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			dataIsLoaded: false,
			defaultTaxable: TAXABLE_OPTIONS[0],
			isLoading: false,
			showButtons: false,
			isDraft: false,
			nonbillableDefault: false,
			inactive: false,
			descHtml: '',
		};

		this.api = new ApiService();

		this.newTimeAc = {
			activity: '',
			activityn: '',
			desc: '',
			shortdesc: '',
			descrtf: '',
			inactive: false,
			basecostperhr: 0,
			baserateperhr: 0,
			tier2costperhr: 0,
			tier2rateperhr: 0,
			tier3costperhr: 0,
			tier3rateperhr: 0,
			billingoption: 0,
			ssmaTimeStamp: '',
			nonbillableDefault: false,
			taxableDefault: 0,
		};

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
		this.isEditing = Boolean(this.props.params.id);
	}

	async componentDidMount() {
		this.setTitle('Add Time Activity');
		this.setFormAction(true);
		this.resetLocalStorage();

		setLocalStorage('glossaryTimeActivity', this.newTimeAc, true);

		if (this.isEditing) {
			this.dMloader(true);
			const [timeActivity] = await this.api.get(
				'timeactivities',
				`?$filter=id eq ${this.props.params.id}`
			);

			if (timeActivity) {
				this.setState({
					dataIsLoaded: true,
					data: timeActivity,
					nonbillableDefault: timeActivity.nonbillableDefault,
					inactive: timeActivity.inactive,
					descHtml: await mapRtfToDraft(timeActivity.descrtf ?? ''),
				});

				const defaultTaxable =
					TAXABLE_OPTIONS[parseInt(timeActivity.taxableDefault ?? 0)];
				this.setState({
					defaultTaxable,
				});

				const timeRatesRadio = document.getElementById(
					`radio-rates-${timeActivity.billingoption}`
				);
				if (timeRatesRadio) {
					timeRatesRadio.checked = true;
				}

				this.setTitle(`Edit ${timeActivity.activityn}`);
				setLocalStorage('glossaryTimeActivity', timeActivity, true);
			}
		}

		setTimeout(() => {
			this.setState({
				showButtons: true,
			});
			this.dMloader(false);
		}, 250);
	}

	setIsDraft(state) {
		window.onbeforeunload = null;
		setLocalStorage('isDraft', state.toString());
		this.setState({
			isDraft: state,
		});
	}

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	setDraft() {
		setLocalStorage('isDraft', 'true');
		this.setState({
			isDraft: true,
		});
	}

	setLocalStorageValue(name, val) {
		const data = getLocalStorage('glossaryTimeActivity', true) || `{}`;

		data[name] = val;
		setLocalStorage('glossaryTimeActivity', data, true);
	}

	handleChange = (e) => {
		const { name } = e.target;
		let { value } = e.target;

		if (name === 'billingoption') {
			let { data } = this.state;
			data.billingoption = value;

			this.setState({
				data,
			});
		}

		if (!this.isEditing && name === 'activityn') {
			const activityCode = nameToCode(value);

			this.setState((prevState) => ({
				data: {
					...prevState.data,
					activity: activityCode,
				},
			}));
			this.setLocalStorageValue('activity', activityCode);
		}

		if (name === 'activity') {
			value = formatCode(value);
		}

		if (
			[
				'baserateperhr',
				'basecostperhr',
				'tier2rateperhr',
				'tier2costperhr',
				'tier3rateperhr',
				'tier3costperhr',
			].includes(name)
		) {
			value = Number(value);
		}

		this.setState((prevState) => ({
			data: {
				...prevState.data,
				[name]: value,
			},
		}));

		this.setLocalStorageValue(name, value);
		this.setDraft();
	};

	handleSelectChange = (select) => {
		const { value, label } = select;
		this.setLocalStorageValue('taxableDefault', value);
		this.setDraft();

		setLocalStorage(
			'timeActivityDefaultTaxable',
			{
				value,
				label,
			},
			true
		);
	};

	handleEditorChange = (val) => {
		const valueHtml = this.htmlToRtfConverter.convertHtmlToRtf(val.target.html);
		this.setLocalStorageValue('desc', val.target.value);
		this.setLocalStorageValue('descrtf', valueHtml);
		this.setDraft();
	};

	handleCheck = (e) => {
		let state = this.state;
		state[e.target.name] = e.target.checked;
		this.setState(state);
		this.setLocalStorageValue(e.target.name, e.target.checked);
		this.setDraft();
	};

	handleClickSave = (e) => {
		this.setIsLoading(true);

		if (this.isEditing) {
			this.handleUpdate();
		} else {
			this.handleCreate(e);
		}
	};

	handleUpdate() {
		const data = getLocalStorage('glossaryTimeActivity', true) || `{}`;

		const request = this.api.patch(
			'timeactivities',
			this.state.data?.activity,
			data
		);
		handleResponseAlert(request, () => {
			this.setIsLoading(false);
			setDraft(false);

			delay(() => {
				this.navigate(
					generatePath(URI.glossary.list, {
						path: 'time-activities',
					})
				);
			}, 1500);
		});
	}

	handleCreate(e) {
		const data = getLocalStorage('glossaryTimeActivity', true) || `{}`;

		displayAlertLoader(MSG.loading.create.GlossaryTA);
		const request = this.api.postJson('timeactivities', data);
		handleResponseAlert(
			request,
			(res) => {
				this.setIsLoading(false);
				setDraft(false);

				if (res && res.id) {
					delay(() => {
						e.target.name === 'saveNew'
							? window.location.reload(false)
							: this.navigate(
									generatePath(URI.glossary.list, {
										path: 'time-activities',
									})
							  );
					}, 1500);
				}
			},
			null,
			null,
			MSG.success.create.GlossaryTA,
			null,
			MSG.loading.create.GlossaryTA
		);
	}

	handleClick = (e) => {
		const isDraft = getLocalStorage('isDraft');

		if (isDraft === 'true') {
			e.preventDefault();

			setLocalStorage('isDraft', 'false');
			if (e.currentTarget.href !== undefined) {
				location.href = e.currentTarget.href;
			} else if (e.target.href !== undefined) {
				location.href = e.target.href;
			} else {
				location.href = e.currentTarget.firstChild.href;
			}
		}
	};

	resetLocalStorage() {
		setLocalStorage('timeActivityDefaultTaxable', null);
		setLocalStorage('glossaryTimeActivity', {}, true);
	}

	tierHourlyValue = (name) => {
		const { data } = this.state;
		const value =
			data?.[name] !== Math.floor(data?.[name])
				? data?.[name]?.toFixed(2)
				: data?.[name];
		return value;
	};

	renderHeader() {
		const { isLoading, isDraft } = this.state;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						onClick={this.handleClick}
						to={getRouteWithParam(URI.glossary.list, {
							path: 'time-activities',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							onClickCapture={this.handleClick}
							linkProps={{ to: URI.glossary.base }}
							linkAs={Link}
						>
							Settings
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClickCapture={this.handleClick}
							linkProps={{ to: URI.glossary.base }}
							linkAs={Link}
						>
							Glossary
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClickCapture={this.handleClick}
							linkProps={{
								to: getRouteWithParam(URI.glossary.list, {
									path: 'time-activities',
								}),
							}}
							linkAs={Link}
						>
							Time Activities
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClickCapture={this.handleClick}
							linkProps={{ to: '#' }}
							linkAs={Link}
						>
							{this.title}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>{this.title}</HeaderLight.Title>

					<div className={this.state.showButtons ? 'd-block' : 'd-none'}>
						{/* Submit Button */}
						<SecureBootstrapButton
							attributeNo={48}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							className={this.isEditing ? 'd-none' : 'd-inline-block'}
							variant="primary me-2"
							onClick={this.handleClickSave}
							name="saveNew"
							disabled={!isDraft || isLoading}
						>
							Save & New
						</SecureBootstrapButton>

						<SecureBootstrapButton
							attributeNo={48}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							onClick={this.handleClickSave}
							name="save"
							disabled={!isDraft || isLoading}
						>
							Save
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		const { data, nonbillableDefault, inactive } = this.state;

		let defaultTaxable = getLocalStorage('timeActivityDefaultTaxable', true);

		if (Is.empty(defaultTaxable)) {
			defaultTaxable = this.state.defaultTaxable;
		}

		return (
			<Container className="ms-0">
				<Row xs={1} lg={2} className="py-4">
					<Col className="mb-3 mb-lg-3">
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Name</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									maxLength={30}
									placeholder="Please enter"
									value={data?.activityn}
									name="activityn"
									onChange={this.handleChange}
								/>
							</Col>
						</Row>

						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Activity Code</Form.Label>
							</Col>
							<Col>
								<Row xs={1} lg={2}>
									<Col>
										<Form.Control
											type="text"
											maxLength={5}
											placeholder="Please enter"
											value={data?.activity}
											readOnly={this.isEditing}
											disabled={this.isEditing}
											name="activity"
											onChange={this.handleChange}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="align-items-start mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0 mt-2">Description</Form.Label>
							</Col>
							<Col>
								<WysiwygEditor onChange={this.handleEditorChange}>
									{this.state.descHtml}
								</WysiwygEditor>
							</Col>
						</Row>
					</Col>
				</Row>

				{/* ---------- section */}
				<div className="bg-secondary-grey roundedx-4">
					<Row xs={1} className="py-3">
						<Col>
							<Row className="align-items-center px-4">
								<Col className="mb-2 mb-lg-0 px-2">
									<h6 className="fw-bold mb-0">Time Billing Rates</h6>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>

				<Row xs={1} lg={1} className="py-4">
					<Col className="mb-3 mb-lg-0">
						<Row className="align-items-start mb-3">
							<Col
								md={12}
								lg={2}
								className="text-lg-end mb-2 mb-lg-0"
								style={{ width: '12%' }}
							>
								<Form.Label className="mb-0">Time Billing Rates</Form.Label>
							</Col>
							<Col>
								<div className="mb-2">
									<Form.Check
										inline
										label="Use cost/rate of the employee"
										name="billingoption"
										type="radio"
										id={`radio-rates-0`}
										value={0}
										defaultChecked={data?.billingoption != '1'}
										onClick={this.handleChange}
									/>
								</div>
								<div className="mb-2">
									<Form.Check
										inline
										label="Always use the cost/rate entered below"
										name="billingoption"
										type="radio"
										id={`radio-rates-1`}
										value={1}
										defaultChecked={data?.billingoption == '1'}
										onClick={this.handleChange}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>

				{data?.billingoption == '1' && (
					<Row
						xs={1}
						lg={3}
						className="mb-0 mb-lg-3 roundedx-6 bg-ivory border-1 border-sand mx-auto d-flex flex-wrap flex-md-nowrap align-items-center justify-content-md-center"
					>
						<Col lg={3} className="mb-2 mt-2 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 1 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										name="baserateperhr"
										defaultValue={this.tierHourlyValue('baserateperhr')}
										onChange={this.handleChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-lg-0">
									<Form.Label className="mb-0">Tier 1 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										name="basecostperhr"
										defaultValue={this.tierHourlyValue('basecostperhr')}
										onChange={this.handleChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
						<Col lg={3} className="mb-2 mt-2 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 2 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										name="tier2rateperhr"
										defaultValue={this.tierHourlyValue('tier2rateperhr')}
										onChange={this.handleChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 2 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										name="tier2costperhr"
										defaultValue={this.tierHourlyValue('tier2costperhr')}
										onChange={this.handleChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
						<Col lg={3} className="mb-3 mt-2 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-lg-0">
									<Form.Label className="mb-0">Tier 3 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										name="tier3rateperhr"
										defaultValue={this.tierHourlyValue('tier3rateperhr')}
										onChange={this.handleChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-lg-0">
									<Form.Label className="mb-0">Tier 3 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										name="tier3costperhr"
										defaultValue={this.tierHourlyValue('tier3costperhr')}
										onChange={this.handleChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
					</Row>
				)}

				{/* ---------- section */}
				<div className="bg-secondary-grey roundedx-4">
					<Row xs={1} className="py-3">
						<Col>
							<Row className="align-items-center px-4">
								<Col className="mb-2 mb-lg-0 px-2">
									<h6 className="fw-bold mb-0">Defaults</h6>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>

				<Row xs={1} lg={2} className="py-4">
					<Col className="mb-3 mb-lg-3">
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Taxable</Form.Label>
							</Col>
							<Col>
								<Row xs={1} lg={2}>
									<Col>
										<Select
											key="taxableDefault"
											options={TAXABLE_OPTIONS}
											defaultValue={defaultTaxable}
											className="react-select"
											placeholder="Please select"
											onChange={this.handleSelectChange}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
							<Col>
								<Form.Check
									inline
									label="Non-Billable"
									name="nonbillableDefault"
									type="checkbox"
									id={`checkbox-nonbillable`}
									className="me-3"
									checked={nonbillableDefault}
									onChange={this.handleCheck}
								/>
								<Form.Check
									inline
									label="Inactive"
									name="inactive"
									type="checkbox"
									id={`checkbox-inactive`}
									checked={inactive}
									onChange={this.handleCheck}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	render() {
		const { isLoading, isDraft } = this.state;

		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
				</div>

				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={48}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
						variant="primary"
						size="lg"
						name="save"
						onClick={this.handleClickSave}
						disabled={!isDraft || isLoading}
					>
						{getSaveButtonName(isLoading, 'Save')}
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(GlossaryAddTimeActivities);
