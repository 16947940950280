import React from 'react';

class ProgressBarCicle extends React.Component {
	percentageToDegree(percentage) {
		return (percentage / 100) * 360;
	}

	render() {
		const { value, variant } = this.props;

		return (
			<div
				className={'progress-circle ' + (variant || 'primary')}
				data-value={value}
			>
				<span className="progress-circle-left">
					<span
						className={'progress-circle-bar border-' + (variant || 'primary')}
						style={{
							transform:
								'rotate(' +
								(value <= 50 ? 0 : this.percentageToDegree(value - 50)) +
								'deg)',
						}}
					></span>
				</span>
				<span className="progress-circle-right">
					<span
						className={'progress-circle-bar border-' + (variant || 'primary')}
						style={{
							transform:
								'rotate(' +
								(value <= 50 ? this.percentageToDegree(value) : 180) +
								'deg)',
						}}
					></span>
				</span>
			</div>
		);
	}
}

export default ProgressBarCicle;
