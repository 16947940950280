import Label from 'legacy/app/components/label/Label';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { Item } from 'legacy/lib/api/types/Item';
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useFeature } from 'use-feature';

export type TSelectedChoice = 'est' | 'act' | 'bill';

const PricingTabChoiceSelector = ({
	selectedChoice,
	itemToEdit,
	changeSelectedChoice,
}: {
	itemToEdit: Item;
	selectedChoice: TSelectedChoice;
	changeSelectedChoice: (newChoice: TSelectedChoice) => void;
}) => {
	const methods = useFormContext();
	const areBudgetInputsEnabled = useFeature(FeatureFlags.BudgetInputs);

	return (
		<div className="tw-p-4 tw-mx-auto tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#dedcd7] tw-bg-[#faf9f7] tw-w-11/12">
			<div className="tw-flex tw-gap-4 tw-items-center tw-justify-between tw-flex-wrap">
				<div className="tw-flex tw-gap-4">
					<div className="tw-flex tw-gap-2">
						<Form.Check
							label=""
							id="Estimate"
							checked={selectedChoice === 'est'}
							type="radio"
							onChange={() => changeSelectedChoice('est')}
						/>
						<Label label="Estimate" className="tw-text-xs" />
					</div>
					<div className="tw-flex tw-gap-2 tw-flex-wrap">
						<Form.Check
							label=""
							checked={selectedChoice === 'act'}
							id="Actual"
							type="radio"
							onChange={() => changeSelectedChoice('act')}
						/>
						<Label label="Actual" className="tw-text-xs" />
					</div>
					<div className="tw-flex tw-gap-2 tw-flex-wrap">
						<Form.Check
							label=""
							checked={selectedChoice === 'bill'}
							id="Billing"
							type="radio"
							onChange={() => changeSelectedChoice('bill')}
						/>
						<Label label="Billing" className="tw-text-xs" />
					</div>
				</div>
				{areBudgetInputsEnabled && (
					<div className="tw-flex tw-gap-8 tw-flex-wrap">
						<div className="tw-flex tw-items-center tw-gap-4 tw-flex-wrap">
							<Label label="Budget Amount" className="tw-text-xs" />
							<div className="form-group-extra reversed tw-max-w-48">
								<Form.Control
									readOnly
									id="Budget Amount"
									type="number"
									size="sm"
									value={itemToEdit.budget}
								/>
								<span>$</span>
							</div>
						</div>
						<div className="tw-flex tw-items-center tw-gap-4 tw-flex-wrap">
							<Label
								label="Budget Qty/Hours"
								className="tw-text-xs tw-text-right"
							/>
							<div className="tw-max-w-32">
								<Controller
									control={methods.control}
									name="budgethrs"
									render={({ field: { value, onChange } }) => (
										<FormControl
											type="number"
											value={value}
											onChange={onChange}
											onBlur={(e) => {
												const isNotAValidNumber = isNaN(Number(e.target.value));

												if (
													!e.target.value ||
													isNotAValidNumber ||
													e.target.value === '-0.'
												) {
													methods.setValue('budgethrs', 0);
												} else {
													methods.setValue('budgethrs', Number(e.target.value));
												}
											}}
										/>
									)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

PricingTabChoiceSelector.displayName = 'PricingTabChoiceSelector';

export default PricingTabChoiceSelector;
