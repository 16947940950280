import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import SecureDropdownItem from 'legacy/app/components/security/SecureDropdownItem';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import { CheckbookReconcileForm } from './CheckbookReconcileForm';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';

const CheckbookReconcileFilters = () => {
	const { started } = useCheckbookReconcile();
	const [showTableSearch, setShowTableSearch] = useState(false);

	const handleShowTableSearch = () => {
		setShowTableSearch((prevState) => !prevState);
	};

	return (
		<div className="tw-grid tw-grid-flow-row tw-my-2">
			<div className="tw-bg-[#faf9f7] tw-py-6 tw-px-4 tw-border-[1px] tw-border-solid tw-border-[#dedcd7] tw-rounded-sm">
				<CheckbookReconcileForm />
			</div>
			{started && (
				<div className="tw-grid tw-grid-flow-col tw-justify-end tw-gap-2 tw-bg-[#faf9f7] tw-py-6 tw-px-4 tw-border-[1px] tw-border-t-[0px] tw-border-solid tw-border-[#dedcd7] tw-rounded-sm">
					<Button
						variant="ivory"
						size="sm"
						className={`btn-icon btn-action ${
							showTableSearch ? 'bg-primary-ash text-white' : ''
						}`}
						onClick={handleShowTableSearch}
					>
						<i className="ri-search-line"></i> Search
					</Button>
					<Dropdown className="d-flex justify-content-end" align="end">
						<Dropdown.Toggle
							size="sm"
							id="dropdown-autoclose-true"
							variant="ivory"
						>
							Actions
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<SecureDropdownItem
								attributeNo={59}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec2}
							>
								<i className="ri-file-transfer-line"></i> Transfer
							</SecureDropdownItem>
							<SecureDropdownItem
								attributeNo={59}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
							>
								<i className="ri-eye-off-line"></i> Void
							</SecureDropdownItem>
							<Dropdown.Item href="">
								<i className="ri-eye-fill"></i> Show Details
							</Dropdown.Item>
							<Dropdown.Item href="">
								<i className="ri-file-chart-line"></i> Create Report
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			)}
		</div>
	);
};

CheckbookReconcileFilters.displayName = 'CheckbookReconcileFilters';

export { CheckbookReconcileFilters };
