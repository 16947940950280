import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Container, Col, Row, Breadcrumb, Button } from 'react-bootstrap';
import Header, { HeaderLight } from '../components/Header';
import URI from '../../defaults/RoutesDefault';

class Headers extends React.Component {
	HeaderActsion() {
		return (
			<Header.Buttons>
				<li>
					<NavLink to="/" className="btn text-white active">
						Tabbed Content
					</NavLink>
				</li>
				<li>
					<NavLink to="/" className="btn text-white">
						Tabbed Content
					</NavLink>
				</li>
				<li>
					<NavLink to="/" className="btn text-white">
						Tabbed Content
					</NavLink>
				</li>
				<li>
					<NavLink to="/" className="btn text-white">
						Tabbed Content
					</NavLink>
				</li>
			</Header.Buttons>
		);
	}

	render() {
		return (
			<>
				<div className="bg-secondary-grey pt-5 pb-4 mt-5">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Headers</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-5">
					<Row>
						<Col lg="12" className="mb-5">
							<Header>
								<Header.LeftContent>
									<Header.Breadcumbs>
										<Breadcrumb className="breadcrumb-light">
											<Breadcrumb.Item
												linkProps={{ to: URI.home }}
												linkAs={Link}
											>
												Contacts
											</Breadcrumb.Item>
											<Breadcrumb.Item>Vendors</Breadcrumb.Item>
										</Breadcrumb>
									</Header.Breadcumbs>
									<Header.Title>Projects</Header.Title>
								</Header.LeftContent>
								<Header.Actions list={true}>
									<li>
										<Button
											as={Link}
											to={URI.home}
											variant="light"
											size="lg"
											className="btn-icon text-secondary-green"
										>
											<i className="ri-add-line ri-lg"></i> Add
										</Button>
									</li>
									<li>
										<Button
											as={Link}
											to={URI.home}
											variant="light"
											size="lg"
											className="btn-icon text-secondary-green"
										>
											<i className="ri-add-line ri-lg"></i> List
										</Button>
									</li>
								</Header.Actions>
							</Header>
						</Col>

						<Col lg="12" className="mb-5">
							<Header
								childrenActions={this.HeaderActsion}
								childProps={{ className: 'mb-4' }}
							>
								<Header.LeftContent>
									<Header.Title>Projects</Header.Title>
								</Header.LeftContent>
								<Header.Actions list={true}>
									<li>
										<Button
											as={Link}
											to={URI.home}
											variant="light"
											size="lg"
											className="btn-icon text-secondary-green"
										>
											<i className="ri-add-line ri-lg"></i> Add
										</Button>
									</li>
									<li>
										<Button
											as={Link}
											to={URI.home}
											variant="light"
											size="lg"
											className="btn-icon text-secondary-green"
										>
											<i className="ri-add-line ri-lg"></i> List
										</Button>
									</li>
								</Header.Actions>
							</Header>
						</Col>

						<Col lg="12">
							<HeaderLight>
								<HeaderLight.Breadcumbs>
									<NavLink
										to={URI.timeManagement.appointment}
										className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
									>
										<i className="ri-arrow-left-s-line"></i> Back
									</NavLink>
									<Breadcrumb className="ms-4">
										<Breadcrumb.Item linkProps={{ to: URI.home }} linkAs={Link}>
											Contacts
										</Breadcrumb.Item>
										<Breadcrumb.Item linkProps={{ to: URI.home }} linkAs={Link}>
											Clients
										</Breadcrumb.Item>
										<Breadcrumb.Item>Vendor Name</Breadcrumb.Item>
									</Breadcrumb>
								</HeaderLight.Breadcumbs>
								<HeaderLight.Content actions={true}>
									<HeaderLight.Title>Vendor Name</HeaderLight.Title>
								</HeaderLight.Content>
								<HeaderLight.Actions>
									<li>
										<Link
											to={URI.home}
											data-menu="info"
											className={`btn text-charcoal active`}
											onClick={this.handleMenuClick}
										>
											Info
										</Link>
									</li>
									<li>
										<Link
											to={URI.home}
											data-menu="notes"
											className={`btn text-charcoal `}
											onClick={this.handleMenuClick}
										>
											Notes
										</Link>
									</li>
									<li>
										<Link
											to={URI.home}
											data-menu="notes"
											className={`btn text-charcoal `}
											onClick={this.handleMenuClick}
										>
											Budget
										</Link>
									</li>
								</HeaderLight.Actions>
							</HeaderLight>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Headers;
