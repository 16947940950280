import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import dayjs from 'dayjs';
import { ApiService } from '../../../lib/api/HttpService';
import {
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
} from '../../../utilities/Response';
import MSG from '../../../defaults/Message';

class PaymentActionCreditCardPaymentModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			showPayDate: true,
			payDate: new Date(),
			fiscalMonth: null,
		};

		this.updatedData = {
			payDate: new Date(),
		};

		this.api = new ApiService();
		this.errIds = [];
	}

	async componentDidMount() {
		const company = await this.api.getCompany();
		const company2 = await this.api.getCompany2();

		let fiscalMonth = new Date();
		if (company2.autodeffmo) {
			fiscalMonth = new Date(
				fiscalMonth.setMonth(fiscalMonth.getMonth() + company.fiscaloffset)
			);
		} else {
			fiscalMonth = new Date(company2.closedapfmo);
			fiscalMonth.setMonth(fiscalMonth.getMonth() + 1);
		}

		this.updatedData = {
			...this.updatedData,
			fiscalMonth: dayjs(fiscalMonth).format('MM/YYYY'),
		};

		this.setState({
			fiscalMonth: fiscalMonth,
		});
	}

	componentWillUnmount() {
		this.setState({
			fiscalMonth: new Date(),
		});
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.checkedData !== this.props.checkedData) {
			this.updatedData = {
				payDate: new Date(),
			};
			this.errIds = [];

			this.setState({
				showPayDate: true,
				payDate: new Date(),
			});
		}
	}

	getDateValue(key) {
		return this.updatedData.hasOwnProperty(key) &&
			this.updatedData[key] !== null
			? new Date(this.updatedData[key])
			: new Date();
	}

	handleShowPayDate = (e) => {
		let state = this.state;
		state.showPayDate = !e.target.checked;
		if (e.target.checked) {
			delete this.updatedData.payDate;
			delete this.state.payDate;
		}

		this.setState(state);
	};

	handleSelectPayWith = (e) => {
		this.updatedData.payWithAccount = e.value;
	};

	handleDateChange = (name, date) => {
		this.setState({
			[name]: date,
		});

		date = name === 'fiscalMonth' ? dayjs(date).format('MM/YYYY') : date;
		this.updatedData[name] = date;
	};

	async updateTransactionTag(txnum) {
		const data = {
			txnum: parseInt(txnum),
			tag: true,
		};

		await this.api.editVendorPayments(data).catch((error) => {
			this.errIds.push(txnum);
		});
	}

	handleAction = async (e) => {
		displayAlertLoader(MSG.loading.update.msg);

		for (let txnum of this.props.checkedData) {
			await this.updateTransactionTag(txnum);
		}

		if (this.errIds.length) {
			displayAlert(
				'danger',
				'The data with the ids [' + this.errIds.join() + "] couldn't be updated"
			);
		}

		await this.api
			.postVendorPaymentsCC(this.updatedData)
			.then((resp) => {
				displayAlert('success', MSG.success.update.APPayment);
			})
			.catch((err) => {
				displayAlert(
					'danger',
					getErrorMessage(err, MSG.error.update.APPayment)
				);
			});

		this.props.hideModal(true);
		this.props.resetData();
	};

	render() {
		return (
			<Modal
				show={true}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3">
						<Col className="px-5">
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								Credit Card Payment
							</h5>
							<Form.Group>
								<Row className="pt-3">
									<Col>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Fiscal Month</Form.Label>
											</Col>
											<Col>
												<Row lg={1}>
													<Col>
														<DatePicker
															selected={this.state.fiscalMonth}
															className="form-control"
															dateFormat="MM/yyyy"
															placeholderText="Select"
															showMonthYearPicker
															name="fiscalMonth"
															onChange={this.handleDateChange.bind(
																this,
																'fiscalMonth'
															)}
														/>
													</Col>
												</Row>
											</Col>
										</Row>

										<Row className="align-items-start mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0 mt-0 mt-lg-2 pt-0 pt-lg-1">
													Pay With
												</Form.Label>
											</Col>
											<Col>
												<Select
													options={this.props.glAccounts}
													className="react-select"
													placeholder="Please select"
													onChange={this.handleSelectPayWith}
												/>
												<Form.Check
													inline
													label="Use the Pay/Due Date of each Transaction"
													name="showPayDate"
													type="checkbox"
													id={`check-group-payment`}
													className="vtop mt-3"
													defaultChecked={false}
													onChange={this.handleShowPayDate}
												/>
											</Col>
										</Row>

										{this.state.showPayDate && (
											<Row className="align-items-center mb-3">
												<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
													<Form.Label className="mb-0">Payment Date</Form.Label>
												</Col>
												<Col>
													<Row lg={1}>
														<Col>
															<DatePicker
																selected={this.state.payDate}
																showMonthDropdown="true"
																showYearDropdown="true"
																className="form-control"
																placeholderText="Select"
																name="payDate"
																onChange={this.handleDateChange.bind(
																	this,
																	'payDate'
																)}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										)}
									</Col>
								</Row>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={() => this.props.hideModal(false)}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(PaymentActionCreditCardPaymentModal);
