import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import Header from '../../components/Header';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import URI from '../../../defaults/RoutesDefault';
import React from 'react';
import noItems from '../../../assets/images/gear.png';

class ComingSoon extends ThreadComponent {
	constructor(props) {
		super(props);
	}

	renderHeader() {
		return (
			<Header>
				<Header.Title>Coming Soon</Header.Title>
			</Header>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding vh-100 align-items-center d-flex">
					<Container fluid className="px-0">
						<div className="row justify-content-center text-center py-5">
							<div className="col-md-4">
								<img src={noItems} className="mw-100 mb-4 w-100px" alt="" />

								<h5 className={'fw-bold mb-3'}>Coming Soon</h5>
								<p>
									This feature is currently under construction, but we're
									working hard
									<br />
									to get it ready for you soon.
								</p>
							</div>
						</div>
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(ComingSoon);
