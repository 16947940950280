import { apiClient } from 'api/apiClient';

const RESOURCE = '/items/invoices';

export interface IInvoices {
	invoiceNumber: number;
	invoiceDate: string;
	quantity: number;
	depositApplied: number;
	salesTax: number;
	amount: number;
	payment: number;
	clientCheckNumber: number | null;
	total: number;
	balanceDue: number;
	itemNumber: string;
	projectCode: string;
	compType0Amount: number;
	compType1Amount: number;
	compType2Amount: number;
	compType3Amount: number;
	compType4Amount: number;
	compType5Amount: number;
}

export const getInvoices = async (filter?: string): Promise<IInvoices[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());

	return data;
};
