import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

// Include Css Files
import 'bootstrap/dist/css/bootstrap.min.css';
import './legacy/assets/styles/app-branding.scss';
import 'remixicon/fonts/remixicon.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
