import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import { ApiService } from '../../../lib/api/HttpService';
import { replace } from 'lodash';

interface State {
	show: boolean;
}

interface Props {
	show: boolean;
	refreshData?: any;
	toggleModal: any;
	confirmAction: any;
	message?: string;
	children?: JSX.Element;
	title?: string;
	labelOK?: string;
	labelCancel?: string;
	okBtnStyle?: string;
}

class ConfirmModal extends React.Component<Props, State> {
	readonly api: ApiService;

	constructor(props: Props) {
		super(props);

		this.state = {
			show: false,
		};

		this.api = new ApiService();
	}

	componentDidUpdate(previousProps: Props, previousState: State) {
		if (previousProps.show !== this.props.show) {
			this.setState({
				show: this.props.show,
			});
		}
	}

	render() {
		return (
			<Modal
				show={this.state.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3">
						<Col className="px-5">
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								{this.props.title ?? 'Delete Confirm'}
							</h5>
							<div className="text-center mx-auto fw-bold pt-4">
								<h6
									dangerouslySetInnerHTML={{
										__html:
											this.props.message ??
											`The selected item(s) will be permanently
                                        deleted. <br/>Do you wish to continue?`,
									}}
								></h6>
							</div>
						</Col>
					</Row>
					{this.props.children}
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant={this.props.okBtnStyle ?? 'danger'}
								onClick={this.props.confirmAction.bind(this)}
								className="w-100 m-2"
								id="modal-ok-button"
							>
								{this.props.labelOK ?? 'OK'}
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.toggleModal.bind(this)}
								className="w-100 m-2"
							>
								{this.props.labelCancel ?? 'Close'}
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(ConfirmModal);
