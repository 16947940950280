import { ReconcileContext } from '../ReconcileContext';
import { useContext } from 'react';

export const useReconcile = () => {
	const context = useContext(ReconcileContext);
	if (context === undefined) {
		throw new Error('useReconcile must be used within a ReconcileProvider');
	}
	return context;
};
