import { Badge } from 'react-bootstrap';

export const TransactionTableBadge = () => (
	<Badge
		pill
		className="!tw-text-sm !tw-pl-6 !tw-pr-6 !tw-font-normal tw-cursor-pointer tw-select-none !tw-bg-[#006A53]"
	>
		Assign
	</Badge>
);
TransactionTableBadge.displayName = 'TransactionTableBadge';
