import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Container,
	Button,
	Row,
	Col,
	Table,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import ListFilter from '../../../components/ListFilter';
import URI from '../../../../defaults/RoutesDefault';
import { getRouteWithParam, WithRouter } from '../../../../helpers/Router';

class ClientInvoiceExistingInvoiceAdjust extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			checks: {},
		};
	}

	componentInit() {
		this.title = 'Invoice Adjustments';
		this.setActionBar(true);
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={getRouteWithParam(URI.accountsReceivable.clientInvoices.list, {
							page: 'existing',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsReceivable.clientInvoices.listNew,
							}}
							linkAs={Link}
						>
							Accounts Receivable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsReceivable.clientInvoices.listNew,
							}}
							linkAs={Link}
						>
							Client Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(
									URI.accountsReceivable.clientInvoices.list,
									{ page: 'existing' }
								),
							}}
							linkAs={Link}
						>
							Existing Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item>Invoice Adjustment</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={false}>
					<HeaderLight.Title>Invoice Adjustment</HeaderLight.Title>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderFilter() {
		return (
			<>
				<ListFilter>
					<ListFilter.Actions lg={4} xl={4} className="ms-md-auto">
						<ListFilter.Action className="ms-auto">
							<Button
								as={Link}
								to={
									URI.accountsReceivable.clientInvoices
										.existingInvoiceAdjustmentAdd
								}
								variant="primary"
								size="sm"
								className="btn-icon btn-action ms-3"
							>
								<i className="ri-add-line"></i> Add
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar">
					<Container fluid className="px-0">
						<Row>
							<Col sm="12">
								{this.renderFilter()}

								<Table striped responsive className="a-table">
									<thead>
										<tr className="a-table-heading">
											<th>
												<span className="sort active asc" data-field="date">
													Date
												</span>
											</th>
											<th>
												<span className="sort" data-field="fiscalMonth">
													Fiscal Month
												</span>
											</th>
											<th>
												<span className="sort" data-field="account">
													Account
												</span>
											</th>
											<th>
												<span className="sort" data-field="amount">
													Amount
												</span>
											</th>
											<th>
												<span
													className="sort description"
													data-field="description"
												>
													Description
												</span>
											</th>
											<th>
												<span className="sort" data-field="tx">
													TX #
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr onClick={this.handleRowClick}>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
										</tr>
										<tr onClick={this.handleRowClick}>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
										</tr>
										<tr onClick={this.handleRowClick}>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
										</tr>
										<tr onClick={this.handleRowClick}>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
										</tr>
										<tr onClick={this.handleRowClick}>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
											<td>Lorem</td>
										</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(ClientInvoiceExistingInvoiceAdjust);
