import { IPlaidAccount } from 'api/resources/plaidAccounts';
import classNames from 'classnames';

import BaseDropdown from 'legacy/app/components/dropdowns/BaseDropdown';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';
import { useMemo } from 'react';
import { Button } from 'react-bootstrap';

export const Filters = ({
	enableFilters,
	setEnableFilters,
	accounts,
	selectedAccount,
	onChangeAccount,
}: {
	onChangeAccount: (value: TTypeOption | null) => void;
	selectedAccount: TTypeOption | null;
	accounts: IPlaidAccount[];
	enableFilters: boolean;
	setEnableFilters: (value: boolean) => void;
}) => {
	const options = useMemo(
		() =>
			accounts.map((account) => ({
				value: account.id,
				label: `[${account.type}] ${account.maskedNumber}`,
			})),
		[accounts]
	);

	return (
		<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
			<div className="tw-flex tw-gap-4 tw-items-center">
				<div className="tw-flex tw-items-center tw-gap-4">
					<label className="tw-font-bold tw-m-0">Account</label>
					<BaseDropdown
						inputId="Account"
						onChange={onChangeAccount}
						options={options}
						value={selectedAccount}
					/>
				</div>
			</div>
			<Button
				className={classNames(
					'!tw-bg-transparent !tw-border-[#dedcd7] !tw-border-[1px] !tw-text-[#211f21] !tw-text-sm !tw-font-bold',
					enableFilters && '!tw-bg-black !tw-text-white'
				)}
				size="sm"
				onClick={() => {
					const value = !enableFilters;
					setEnableFilters(value);
				}}
			>
				<i className="ri-search-line"></i> Search
			</Button>
		</div>
	);
};
Filters.displayName = 'Filters';
