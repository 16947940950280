import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
} from '@tanstack/react-table';
import { useGetTransactions } from 'api/hooks/useGetTransactions';
import { ITransactions } from 'api/resources/transactions';
import Table from 'legacy/app/components/newTable/Table';
import { displayAlertError } from 'legacy/utilities/Response';
import { useEffect, useMemo, useState } from 'react';
import { TTabOption } from './TransactionTabs';
import { TransactionTableBadge } from './TransactionTableBadge';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { formatDate, formatFilterDate } from 'legacy/helpers/Date';
import { Pagination } from 'legacy/app/components/pagination/Pagination';

export const TransactionsTable = ({
	accountId,
	selectedTab,
	enableFilters,
}: {
	enableFilters: boolean;
	accountId: number;
	selectedTab: TTabOption;
}) => {
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [pageSize, setPageSize] = useState(20);
	const [page, setPage] = useState(1);

	const filter = useMemo(() => {
		let filterString = `$top=${pageSize + 1}&$skip=${
			(page - 1) * pageSize
		}&$filter=status eq ${selectedTab} and sourceTransactionId eq ${accountId}`;

		columnFilters.map((filter) => {
			if (filter.id === 'transactionDateUTC' && filter.value) {
				const startDate = formatFilterDate((filter.value as DateRange)[0]);
				const endDate = formatFilterDate((filter.value as DateRange)[1]);
				filterString = `${filterString} and transactionDateUTC ge ${startDate} and transactionDateUTC le ${endDate}`;
				return;
			} else if (filter.id === 'amount') {
				filterString = `${filterString} and amount eq ${filter.value}`;
				return;
			}
			filterString = `${filterString} and contains(${filter.id}, '${filter.value}')`;
		});

		sorting.map((sort) => {
			filterString = `${filterString} &$orderby=${sort.id} ${
				sort.desc ? 'desc' : 'asc'
			}`;
			return;
		});

		return filterString;
	}, [selectedTab, columnFilters, sorting, page, pageSize, accountId]);

	const {
		data: transactions,
		isLoading,
		error,
	} = useGetTransactions(accountId as number, filter);

	const columns = useMemo<ColumnDef<ITransactions>[]>(
		() => [
			{
				id: 'transactionDateUTC',
				accessorFn: (col) => formatDate(col.transactionDateUTC),
				header: 'Date',
				cell: (info) => info.getValue(),
				meta: {
					CustomFilterInputType: 'date',
				},
			},
			{
				accessorKey: 'vendorName',
				header: 'Name',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'category',
				header: 'Category',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'paymentChannel',
				header: 'Type',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'transactionSource',
				header: 'Contact Info',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'amount',
				header: 'Amount',
				cell: (info) => info.getValue(),
			},
			{
				header: 'Action',
				enableSorting: false,
				cell: () => TransactionTableBadge(),
			},
		],
		[]
	);

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error fetching the transactions, please try again'
			);
		}
	}, [error]);

	return (
		<div className="tw-flex tw-flex-col tw-gap-4">
			<Table
				manualSorting
				onSortingChange={setSorting}
				manualFiltering
				state={{ columnFilters, sorting }}
				onColumnFiltersChange={setColumnFilters}
				columns={columns}
				isLoading={isLoading}
				data={transactions || []}
				enableFilters={enableFilters}
			/>
			<Pagination
				onPageSizeChanged={setPageSize}
				onPageChanged={setPage}
				hasPreviousPage={page > 1 || (isLoading && false)}
				hasNextPage={
					(transactions && transactions.length > pageSize) ||
					(isLoading && false) ||
					false
				}
				page={page}
				pageSize={pageSize}
			/>
		</div>
	);
};

TransactionsTable.displayName = 'TransactionsTable';
