import { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';

export function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
}: {
	value: string | number;
	onChange: (value: string | number) => void;
	debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
	const [value, setValue] = useState(initialValue);
	const onChangeRef = useRef(onChange);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChangeRef.current(value);
		}, debounce);

		return () => clearTimeout(timeout);
	}, [debounce, value]);

	return (
		<Form.Control
			type="text"
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	);
}
DebouncedInput.displayName = 'DebouncedInput';
