import React from 'react';
import cn from 'classnames';
import { useBudgetBanner } from 'legacy/app/components/BudgetBanner/hooks/useBudgetBanner';
import './BudgetBanner.css';

export enum BudgetBannerContext {
	Header = 'header',
	Modal = 'modal',
}

type TBannerContext = BudgetBannerContext.Header | BudgetBannerContext.Modal;

interface IBudgetBannerProps {
	context: TBannerContext;
	dataTestId: string;
	className?: string;
}

const BudgetBanner = ({
	context,
	className,
	dataTestId,
}: IBudgetBannerProps) => {
	const { show, message, level } = useBudgetBanner();

	if (!show) return null;

	return (
		<div
			data-testid={dataTestId}
			className={cn(
				'budget-banner',
				{ 'budget-banner--error': level === 'error' },
				{ 'budget-banner--warning': level === 'warning' },
				{ 'px-0-md': context === BudgetBannerContext.Header },
				className
			)}
		>
			<i
				className={cn(
					'budget-banner--icon',
					{ 'ri-prohibited-line': level === 'error' },
					{ 'ri-alert-line': level === 'warning' }
				)}
			/>
			<p className="budget-banner--message">{message}</p>
		</div>
	);
};

BudgetBanner.displayName = 'BudgetBanner';

export default BudgetBanner;
