import React from 'react';
import { Row, Col, Form, Button, Container } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';

import { WithRouter } from '../../../helpers/Router';
import InputBar from '../../components/InputBar';
import ProjectAddBudget from './ProjectAddBudget';
import { FooterFormAction } from '../../components/Form';
import { Link } from 'react-router-dom';
import URI from '../../../defaults/RoutesDefault';
import { generatePath } from 'react-router';
import { currencyFormat } from '../../../helpers/Number';
import { entries, isEmpty, replace } from 'lodash';
import ConfirmModal from '../../../app/components/modal/ConfirmModal';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	displayErrors,
} from '../../../utilities/Response';
import MSG from '../../../defaults/Message';
import { makeid, plural } from '../../../utilities/String';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';

class ProjectViewBudget extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			project: this.props.project,
			checks: [],
			btypes: [],
			showConfirmModal: false,
			showConfirmUpdateBudgetModal: false,
			confirmUpdateBudgetMsg: '',
			totalBudget: '',
			projectTotalBudget: '',
			newTotalBudget: '',
			deleteState: null,
			isOverride: false,
			budgetTotalList: 0,
		};
	}

	initReadOnlyProps() {
		this.errors = [];
		this.errorIds = [];
	}

	async componentDidMount() {
		if (!isEmpty(this.props.project?.proj)) {
			this.setState(
				{
					project: this.props.project,
				},
				() => {
					this.setState({
						isOverride: this.state.project.budgettoverride,
						newTotalBudget: currencyFormat(this.state.project.budgett),
					});
				}
			);
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		let project = {};
		if (previousProps.project !== this.props.project) {
			project = this.props.project || {};
			this.setState({
				project,
				projectTotalBudget: currencyFormat(project.budgett),
				newBudgetTotal: currencyFormat(project.budgett),
				isOverride: project.budgettoverride,
				budgetTotalList: 0,
			});
		}
	}

	handleChecksChanged = (checks, btypes) => {
		this.setState({
			checks,
			btypes,
		});
	};

	toggleDeleteModal = (showConfirmModal) => {
		if (isEmpty(this.state.checks)) {
			displayAlertError(MSG.error.noSelected);
			return;
		}

		this.setState({
			showConfirmModal,
		});
	};

	handleDelete = async () => {
		const { checks, btypes } = this.state;
		const ctr = checks.length;
		this.initReadOnlyProps();

		displayAlertLoader(
			plural(MSG.loading.delete.ProjectBudget, 'budget item', ctr)
		);

		for (const [i, code] of entries(checks)) {
			await this.processDelete({
				proj: this.props.project?.proj,
				type: btypes[i],
				code,
			});
		}

		if (!isEmpty(this.errorIds)) {
			displayErrors(this.errors);
			displayAlertError(
				plural(
					replace(
						MSG.error.delete.ProjectBudget,
						'[IDS]',
						this.errorIds.join(', ')
					),
					'Budget item',
					ctr
				),
				7000
			);
		} else {
			displayAlertSuccess(
				plural(
					replace(MSG.success.delete.ProjectBudget, '[IDS]', checks.join(', ')),
					'Budget item',
					ctr
				)
			);
		}

		this.toggleDeleteModal(false);

		this.setState({
			checks: [],
			deleteState: makeid(18),
		});
	};

	handleOnLoadTotalBudget = (budgets) => {
		let total = 0;
		for (let key in budgets) {
			total += budgets[key].budgett;
		}

		this.setState({
			budgetTotalList: total,
			projectTotalBudget: currencyFormat(total),
		});
	};

	handleOverrideChange = async (e) => {
		const budgetOverride = e.target.checked;
		if (!budgetOverride) {
			const project = await this.api.editProject(this.state.project.id, {
				budgettoverride: budgetOverride,
			});

			this.props.updateProject(project);
		}

		this.setState({
			isOverride: budgetOverride,
			newTotalBudget: currencyFormat('0.00'),
		});
	};

	handleBudgetTotalChange = async (e) => {
		this.setState({
			newTotalBudget: e.target.value,
		});
	};

	handleBudgetTotalChangeBlur = async () => {
		const newTotalBudget = currencyFormat(
			this.state.newTotalBudget.replace(/[,$]/g, '')
		);
		this.setState({
			showConfirmUpdateBudgetModal: true,
			confirmUpdateBudgetMsg: `You are about to update the budget total to ${newTotalBudget}. Do you wish to continue?`,
			newTotalBudget,
		});
	};

	handleOnBudgetTotalConfirmation = async () => {
		try {
			const newBudgetTotal = this.state.newTotalBudget
				.replace('$', '')
				.replace(',', '');
			const projectData = {
				budgettoverride: true,
				budgett: parseFloat(newBudgetTotal),
			};

			const project = await this.api.editProject(
				this.state.project.id,
				projectData
			);

			this.props.updateProject(project);

			this.setState({
				showConfirmUpdateBudgetModal: false,
				project: project,
				isOverride: true,
				projectTotalBudget: currencyFormat(newBudgetTotal),
			});

			displayAlert('success', 'Successfully saved changes!');
		} catch (error) {
			if (
				error &&
				error.response &&
				error.response.data &&
				error.response.data.userError
			) {
				displayAlert('danger', error.response.data.userError);
			} else {
				displayAlert('danger', 'Failed saving project.');
			}
		}
	};

	toggleOnBudgetTotalConfirmationModal = (showConfirmUpdateBudgetModal) => {
		this.setState({
			showConfirmUpdateBudgetModal,
		});
	};

	async processDelete(data) {
		await this.api
			.deleteBudget(data)
			.then((res) => {
				if (res.status !== 200) this.errorIds.push(data.code);
			})
			.catch((error, err) => {
				this.errors.push({
					error,
					append: ` Budget code: ${data.code}`,
				});
				this.errorIds.push(data.code);
			});
	}

	renderInputBar() {
		return (
			<Col lg="12">
				<InputBar className="toggle-input full budget-filter">
					<InputBar.Links>
						<InputBar.Link
							className="single-link"
							childprops={{
								className: 'justify-content-center justify-content-md-start',
							}}
						>
							<div style={{ width: 'auto' }}>
								{this.props.closed ? (
									<Form.Check
										inline
										label="Override Total"
										name="inputbar-manual-1"
										type="checkbox"
										id="inputbar-manual-1"
										className="pe-3"
										disabled
										checked={this.state.isOverride}
									/>
								) : (
									<Form.Check
										inline
										label="Override Total"
										name="inputbar-manual-1"
										type="checkbox"
										id="inputbar-manual-1"
										className="pe-3"
										onChange={this.handleOverrideChange}
										checked={this.state.isOverride}
									/>
								)}
							</div>
						</InputBar.Link>
					</InputBar.Links>
					<InputBar.Links className={`me-auto`}>
						<InputBar.Link>
							<Form.Label htmlFor="inputPassword5" className="ilabel">
								Budget
								<br />
								Total
							</Form.Label>
							<div className="form-group-extra reversed">
								<Form.Control
									type="text"
									placeholder="0.00"
									size="sm"
									disabled={!this.state.isOverride}
									value={
										this.state.isOverride
											? this.state.newTotalBudget
											: this.state.projectTotalBudget
									}
									onChange={this.handleBudgetTotalChange}
									style={{
										paddingLeft: '12px',
									}}
								/>
							</div>
							{this.state.isOverride && (
								<Button
									onClick={this.handleBudgetTotalChangeBlur}
									size="sm"
									className="btn-icon btn-action btn-primary"
									style={{ marginLeft: '5px' }}
								>
									Save
								</Button>
							)}
						</InputBar.Link>
					</InputBar.Links>
					<InputBar.Links>
						<Row>
							<Col className="pe-3">
								{this.props.closed ? (
									<SecureBootstrapButton
										attributeNo={200}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
										size="sm"
										className={'btn-icon btn-action disabled'}
										disabled
									>
										<i className="ri-close-line"></i> Delete
									</SecureBootstrapButton>
								) : (
									<SecureBootstrapButton
										attributeNo={200}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
										as={Link}
										to="#"
										variant="danger"
										size="sm"
										className={`btn-icon btn-action ${
											!this.state.checks.length ? 'disabled' : ''
										}`}
										disabled={!this.state.checks.length}
										onClick={this.toggleDeleteModal.bind(this, true)}
									>
										<i className="ri-close-line"></i> Delete
									</SecureBootstrapButton>
								)}
							</Col>
							<Col className="px-0">
								{this.props.closed ? (
									<SecureBootstrapButton
										attributeNo={200}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
										disabled
										size="sm"
										className={`btn-icon btn-action`}
									>
										<i className="ri-add-line"></i> Add
									</SecureBootstrapButton>
								) : (
									<SecureBootstrapButton
										attributeNo={200}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
										as={Link}
										variant="primary"
										size="sm"
										className={`btn-icon btn-action`}
										to={generatePath(URI.project.budgetAdd, {
											id: this.props.project.id,
										})}
									>
										<i className="ri-add-line"></i> Add
									</SecureBootstrapButton>
								)}
							</Col>
						</Row>
					</InputBar.Links>
				</InputBar>
			</Col>
		);
	}

	render() {
		return (
			<>
				<Row>{this.renderInputBar()}</Row>

				<div className="content-padding">
					<Container fluid className="px-0">
						<Row>
							<Col sm="12">
								<div className="mb-4">
									<ProjectAddBudget
										tableOnly
										project={this.state.project}
										onLoadedBudgets={this.handleOnLoadTotalBudget}
										closed={this.props.closed}
										onChecksChanged={this.handleChecksChanged.bind(this)}
										deleteState={this.state.deleteState}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</div>

				{/* Submit Button */}
				{/* <FooterFormAction>
                    <Button variant="primary" size="lg">
                        Save
                    </Button>
                </FooterFormAction> */}
				<ConfirmModal
					show={this.state.showConfirmUpdateBudgetModal}
					message={this.state.confirmUpdateBudgetMsg}
					title={'Budget Total Update'}
					okBtnStyle={'success'}
					confirmAction={this.handleOnBudgetTotalConfirmation.bind(this)}
					toggleModal={this.toggleOnBudgetTotalConfirmationModal.bind(
						this,
						false
					)}
				/>
				<ConfirmModal
					show={this.state.showConfirmModal}
					confirmAction={this.handleDelete.bind(this)}
					toggleModal={this.toggleDeleteModal.bind(this, false)}
				/>
			</>
		);
	}
}

export default WithRouter(ProjectViewBudget);
