import React, { useEffect, useState } from 'react';
import { displayAlert } from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import usePaginatedQuery from 'legacy/lib/api/hooks/usePaginatedQuery';
import BaseSelect from 'legacy/app/components/Selects/BaseSelect';
import { TRecentProject } from 'legacy/lib/api/types/RecentProject';
import { getCashflowProjects } from 'api/resources/cashflows';

interface ICashflowProjectsSelect {
	handleSelect: (project: TRecentProject | null) => void;
	defaultSelected?: string;
}

export const CashflowProjectsSelect = ({
	handleSelect,
	defaultSelected,
}: ICashflowProjectsSelect) => {
	const defaultFilter = '';
	const [isDefaultSet, setIsDefaultSet] = useState(false);
	const [defaultProject, setDefaultProject] = useState<TRecentProject | null>(
		null
	);

	const {
		data: projectItems,
		updateFilter,
		loadMore,
		hasMore,
		isFetching,
	} = usePaginatedQuery<TRecentProject>(async (filter: string) => {
		return await getCashflowProjects(filter);
	}, defaultFilter);

	const optionFormatter = (project: TRecentProject) => ({
		value: project.proj,
		label: `${project.projectName} [${project.proj}]`,
		data: project,
	});

	const handleInputFilter = (input: string) => {
		return `?$filter=contains(projectName,'${input}') or contains(proj,'${input}')`;
	};

	const handleSelectedOption = (
		option: { value: string; data: TRecentProject } | null
	) => {
		return option?.data || null;
	};

	useEffect(() => {
		const selectFirstProject = () => {
			if (!isDefaultSet && projectItems && projectItems.length > 0) {
				const firstProject = projectItems[0];
				const formattedDefaultOption = optionFormatter(firstProject);
				setDefaultProject(firstProject);
				handleSelect(formattedDefaultOption.data);
				setIsDefaultSet(true);
			}
		};

		if (defaultSelected && !isDefaultSet) {
			const fetchDefaultProject = async () => {
				try {
					const filter = `?$filter=proj eq '${defaultSelected}'&$orderby=projectName asc`;
					const [fetchedDefaultProject] = await getCashflowProjects(filter);
					const formattedDefaultOption = optionFormatter(fetchedDefaultProject);

					if (fetchedDefaultProject) {
						setDefaultProject(fetchedDefaultProject);
						handleSelect(formattedDefaultOption.data);
						setIsDefaultSet(true);
					}
				} catch (error: unknown) {
					const responseError = error as {
						response?: { data?: { userError?: string } };
					};
					displayAlert(
						EAlertTypes.Danger,
						responseError.response?.data?.userError || 'Unknown error'
					);
				}
			};

			fetchDefaultProject();
		} else if (!defaultSelected && projectItems.length > 0) {
			selectFirstProject();
		}
	}, [defaultSelected, handleSelect, isDefaultSet, projectItems]);

	return (
		<BaseSelect<TRecentProject>
			handleSelect={handleSelect}
			placeholder="Select a Project"
			results={projectItems || []}
			updateFilter={updateFilter}
			loadMore={loadMore}
			handleInputFilter={handleInputFilter}
			handleSelectedOption={handleSelectedOption}
			optionFormatter={optionFormatter}
			hasMore={hasMore}
			isFetching={isFetching}
			defaultSelected={defaultProject}
		/>
	);
};

CashflowProjectsSelect.displayName = 'CashflowProjectsSelect';
