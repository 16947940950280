import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getPayTypes, type IMappedPayType } from 'api/resources/payTypes';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetPayTypes = (options?: UseQueryOptions<IMappedPayType[]>) => {
	return useQuery<IMappedPayType[]>({
		queryKey: [ECacheKeys.PayTypes],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: getPayTypes,
		...(options || {}),
	});
};
