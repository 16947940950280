import React, { forwardRef } from 'react';
import { useHasAccess } from '../../../context/security';

const SecureInput = forwardRef(
	({ attributeNo, attributeType, disabled, ...props }, ref) => {
		const hasAccess = useHasAccess(attributeNo, attributeType);

		const isDisabled = !hasAccess ? true : disabled;

		return (
			<input ref={ref} {...props} disabled={isDisabled}>
				{props.children}
			</input>
		);
	}
);

export default SecureInput;
