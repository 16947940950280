import { apiClient } from 'api/apiClient';

const RESOURCE = '/Components/purchaseorders';

export interface IPurchaseOrder {
	ponum: string;
	quantity: number;
	expectedDate: string;
	backorderQuantity: number;
	checkNumber: string;
	checkDate: string;
	deposit: number;
	projectCode: string;
	itemNumber: string;
	componentNumber: string;
	vendor: string;
	orderDate: string;
	shipVia: string;
	acknowledgementDate: string;
	acknowledgementNumber: string;
	receivedQuantity: number;
	receivedDate: string;
	poId: number;
}

export const getPurchaseOrders = async (
	filter?: string
): Promise<IPurchaseOrder[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());

	return data;
};
