import { makeAutoObservable } from 'mobx';
import {
	EmployeeListResponse,
	EmployeeService,
	ISalesTaxCodesResponse,
	ITimeActivitiesResponse,
} from './api/EmployeeService';
import { getLocalStorage } from '../../../utilities/LocalStorage';
import { toSortName } from '../../../utilities/String';

export default class EmployeeAddEditViewModel {
	private employeeService: EmployeeService;
	private _employee: any = {};
	private _employeeData: any = {};
	private _isLoaded = false;
	public _id = 0;
	private _response: any = null;
	private _salesTaxCodes: ISalesTaxCodesResponse[] = [];
	private _timeActivities: ITimeActivitiesResponse[] = [];

	get employee(): EmployeeListResponse {
		return this._employee;
	}

	get employeeData(): EmployeeListResponse {
		return this._employeeData;
	}

	get response(): boolean {
		return this._response;
	}

	get isLoaded(): boolean {
		return this._isLoaded;
	}

	get salesTaxCodes(): ISalesTaxCodesResponse[] {
		return this._salesTaxCodes;
	}

	get timeActivities(): ITimeActivitiesResponse[] {
		return this._timeActivities;
	}

	reset(): any {
		this._employee = {};
		this._employeeData = {};
		this._id = 0;
		return this;
	}

	setEmployee(employee: EmployeeListResponse): EmployeeAddEditViewModel {
		this._employee = employee;
		this._employeeData = employee;
		window.localStorage.setItem('employeeData', JSON.stringify(employee));
		return this;
	}

	setEmployeeData(key: any, value: any): EmployeeAddEditViewModel {
		const employeeData: any = getLocalStorage('employeeData', true);
		const addressData: any = getLocalStorage('addressData', true);

		employeeData[key] = value;

		if (key === 'vendorn') {
			const sortName = toSortName(value);
			employeeData.sortName = sortName;
			addressData.sortname = sortName;

			window.localStorage.setItem('addressData', JSON.stringify(addressData));
		}

		window.localStorage.setItem('employeeData', JSON.stringify(employeeData));

		this._employeeData = employeeData;

		return this;
	}

	setIsLoaded(isLoaded: boolean): EmployeeAddEditViewModel {
		this._isLoaded = isLoaded;
		return this;
	}

	setResponse(response: any): EmployeeAddEditViewModel {
		this._response = response;
		return this;
	}

	constructor(employeeService: EmployeeService) {
		this.employeeService = employeeService;

		makeAutoObservable(this);
	}

	async componentDidMount() {
		await this.fetchEmployee();

		this.setIsLoaded(true);
	}

	setId(id: number): EmployeeAddEditViewModel {
		this._id = id;
		return this;
	}

	setSalesTaxCodes(salesTaxCodes: any): EmployeeAddEditViewModel {
		this._salesTaxCodes = salesTaxCodes;
		return this;
	}

	setTimeActivities(timeActivities: any): EmployeeAddEditViewModel {
		this._timeActivities = timeActivities;
		return this;
	}

	async fetchSalesTaxCodes() {
		return await this.employeeService.getSalesTaxCodes();
	}

	async fetchTimeActivities() {
		return await this.employeeService.getTimeActivities();
	}

	async fetchEmployee() {
		// Set loading state
		this.setIsLoaded(false);

		if (!this._id) {
			this.setIsLoaded(true);
			const data: any = {};
			this.setEmployee(data);
			return;
		}

		await this.employeeService
			.getEmployeeById(this._id)
			.then((response) => {
				this.setIsLoaded(true);

				if (response && response[0]) {
					response.list = response[0];
					this.setEmployee(response.list);
				}
			})
			.catch((e: any) => {
				this.setIsLoaded(true);
			});
	}

	modifyEmployee(id?: number, params?: any, callback?: any) {
		delete params.address;
		delete params.addressData;

		if (!id) {
			return this.insertEmployee(params, callback);
		} else {
			return this.updateEmployee(id, params, callback);
		}
	}

	insertEmployee(params?: any, callback?: any) {
		return this.employeeService.insert(params);
	}

	updateEmployee(id: number, params?: any, callback?: any) {
		return this.employeeService.update(id, params);
	}

	async deleteEmployee(params?: any, callback?: any) {
		return this.employeeService
			.delete(params)
			.then((res) => Promise.resolve(res))
			.catch((e) => Promise.reject(e));
	}
}
