import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../lib/api/HttpService';
import {
	displayAlertSuccess,
	getErrorMessage,
} from '../../../utilities/Response';
import { removeSpaces } from '../../../utilities/String';
import { AlertError } from '../bootstrap/BAlerts';
import MSG from '../../../defaults/Message';
import { nameToCode, formatCode } from 'legacy/app/utils/codes';

interface Props {
	hideModal?: any;
	handleSuccess?: any;
	projCode?: string;
	locAddName?: string;
}

interface State {
	isLoading?: boolean;
	errorMessage?: string | null;
	loc: string;
	locn: string;
	isGlobal: boolean;
}

class LocationsQuickAddModal extends ThreadComponent<Props, State> {
	readonly api: ApiService;

	constructor(props: Props) {
		super(props);

		this.state = {
			isLoading: false,
			errorMessage: null,
			locn: this.props.locAddName ?? '',
			loc: nameToCode(this.props.locAddName ?? ''),
			isGlobal: false,
		};

		this.api = new ApiService();
	}

	setIsLoading(state: boolean) {
		this.setState({
			isLoading: state,
		});
	}

	resetData = () =>
		this.setState({
			loc: '',
			locn: '',
			isGlobal: false,
		});

	handleAction: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		const { loc, locn, isGlobal } = this.state;
		let errorMessage;

		if (!removeSpaces(locn)) errorMessage = 'Location name is required';
		else if (!removeSpaces(loc)) errorMessage = 'Location code is required';

		if (errorMessage) {
			this.setState({
				errorMessage,
			});

			return;
		}

		const data = {
			loc: this.state.loc,
			locn: this.state.locn,
			proj: this.props.projCode,
		};
		const request = this.api.postLocation(data);

		this.setIsLoading(true);
		this.setState({
			errorMessage: null,
		});

		request
			.then((res: any) => {
				if (res?.id) {
					// Check add to global is checked.
					if (isGlobal && data.proj) {
						// Create for global location as well.
						delete data.proj;
						this.api.postLocation(data);
					}

					this.resetData();
					this.props.hideModal();
					displayAlertSuccess(MSG.success.create.GlossaryLoc);
					if (this.props.handleSuccess) {
						this.props.handleSuccess(res);
					}
				}
			})
			.catch((res) =>
				this.setState({
					errorMessage: getErrorMessage(res),
				})
			)
			.finally(() =>
				this.setState({
					isLoading: false,
				})
			);
	};

	handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, checked } = e.target;

		switch (name) {
			case 'loc':
				this.setState({ loc: formatCode(value) });
				break;
			case 'isGlobal':
				this.setState({ isGlobal: checked });
				break;
			case 'locn':
				this.setState({
					locn: value,
					loc: nameToCode(value),
				});
				break;
			default:
				break;
		}
	};

	closeModal = () => {
		this.props.hideModal();
	};

	render() {
		const { isLoading, locn, loc, isGlobal, errorMessage } = this.state;

		return (
			<Modal
				show={true}
				onHide={this.closeModal}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row xs={1} lg={1} className="align-items-start my-2 px-5">
						<Col>
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								New Location
							</h5>
							<Row className="align-items-center mb-3 pt-4">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Location Name</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Location name"
										name="locn"
										value={locn}
										onChange={this.handleFormChange}
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Location Code</Form.Label>
								</Col>
								<Col>
									<Form.Control
										maxLength={5}
										type="text"
										placeholder="Location code"
										name="loc"
										value={loc}
										onChange={this.handleFormChange}
									/>
								</Col>
							</Row>
						</Col>

						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={4} className="text-lg-end d-none d-lg-flex">
									&nbsp;
								</Col>
								<Col>
									<Form.Check
										label="Add to Location Glossary"
										type="checkbox"
										name="isGlobal"
										id="isGlobal"
										checked={isGlobal}
										onChange={this.handleFormChange}
									/>
								</Col>
							</Row>
						</Col>
						{errorMessage && (
							<Col>
								<Row className="align-items-center">
									<Col>
										<AlertError className="compact mb-1" isClose={false}>
											{errorMessage}
										</AlertError>
									</Col>
								</Row>
							</Col>
						)}
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
								disabled={isLoading}
							>
								{isLoading ? 'Saving...' : 'OK'}
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.closeModal}
								className="w-100 m-2"
								disabled={isLoading}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default LocationsQuickAddModal;
