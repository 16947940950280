import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postVoidFinanceCharges } from 'api/resources/financeCharges';
import { ECacheKeys } from 'cache/CacheKeys';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { displayAlert } from 'legacy/utilities/Response';

export const usePostVoidFinanceCharges = () => {
	const queryClient = useQueryClient();

	return useMutation(postVoidFinanceCharges, {
		onSuccess: () => {
			displayAlert(EAlertTypes.Success, 'Finance charge voided successfully');
			queryClient.invalidateQueries([ECacheKeys.FinanceCharges]);
		},
		onError: () => {
			displayAlert(EAlertTypes.Danger, 'Error: could not void charge');
		},
	});
};
