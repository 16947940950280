import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { IStatusCode } from 'api/resources/statusCodes';
import { getStatusCodes } from 'api/resources/statusCodes';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetStatusCodes = (options?: UseQueryOptions<IStatusCode[]>) => {
	const query = useQuery<IStatusCode[]>({
		queryKey: [ECacheKeys.StatusCodes],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: getStatusCodes,
		...(options || {}),
	});

	return query;
};
