import { ApiService } from '../../../../lib/networking/HttpService';

export class TimeEntryService extends ApiService {
	private apiTimeEntries = 'timeentries';
	private apiProjects = 'projects';
	private apiItems = 'items';
	private apiComponents = 'components';
	private apiLocations = 'locations';
	private apiSalesCategories = 'salescategories';
	private apiEmployees = 'employees';
	private apiActivities = 'timeactivities';
	private apiTimeEntriesBilling = 'timeentries/billingtotals';

	getTimeEntries<T = ITimeEntryResponse[]>(params?: string): Promise<T> {
		return this.httpWorker.get(`${this.apiTimeEntries}${params || ''}`, {});
	}

	getBillingTotals<T = any>(body: any): Promise<T> {
		return this.httpWorker.post(this.apiTimeEntriesBilling, body);
	}

	async getProjects<T = IProjectsResponse[]>(params?: string): Promise<T> {
		return await this.httpWorker.get(`${this.apiProjects}${params || ''}`, {});
	}

	getItems<T = IItemsResponse[]>(params?: string): Promise<T> {
		return this.httpWorker.get(`${this.apiItems}${params || ''}`, {});
	}

	insertItem<T = IItemsResponse>(params: any): Promise<T> {
		return this.httpWorker.post(`${this.apiItems}`, params);
	}

	updateItem<T = IItemsResponse>(id: number, params: any): Promise<T> {
		return this.httpWorker.patch(`${this.apiItems}/${id}`, params);
	}

	recalculateComponent<T = IItemsResponse>(id: number): Promise<T> {
		return this.httpWorker.post(`${this.apiComponents}/${id}/recalculate`, '');
	}

	getComponents<T = IComponentsResponse[]>(params?: string): Promise<T> {
		return this.httpWorker.get(`${this.apiComponents}${params || ''}`, {});
	}

	getLocations<T = ILocationsResponse[]>(params?: string): Promise<T> {
		return this.httpWorker.get(`${this.apiLocations}${params || ''}`, {});
	}

	getSalesCategories<T = ISalesCategoriesResponse[]>(
		params?: string
	): Promise<T> {
		return this.httpWorker.get(`${this.apiSalesCategories}${params || ''}`, {});
	}

	getEmployees<T = IEmployeesResponse[]>(params?: string): Promise<T> {
		return this.httpWorker.get(`${this.apiEmployees}${params || ''}`, {});
	}

	getActivities<T = IActivitiesResponse[]>(params?: string): Promise<T> {
		return this.httpWorker.get(`${this.apiActivities}${params || ''}`, {});
	}

	insert<T = IComponentsResponse>(params: any): Promise<T> {
		return this.httpWorker.post(`${this.apiComponents}`, params);
	}

	update<T = ITimeEntryResponse>(id: number, params: any): Promise<T> {
		return this.httpWorker.patch(`${this.apiComponents}/${id}`, params);
	}

	updateTimeEntry<T = ITimeEntryResponse>(id: number, params: any): Promise<T> {
		return this.httpWorker.patch(`${this.apiTimeEntries}/${id}`, params);
	}

	delete<T = any>(params: any): Promise<T> {
		return this.httpWorker.post(`/DeleteObjects`, params);
	}
}

export interface ITimeEntryResponse {
	timeEmployee: string;
	timeProject: string;
	refnum: string;
	timeLocation: string | null;
	timeCategory: string | null;
	latestInvDt: string | null;
	txnum: number;
	proj?: string;
	item: string;
	comp: string;
	employee: string;
	activity: string;
	loc: string | null;
	scat: string | null;
	taxable: boolean;
	nonbillable: boolean;
	desc: string;
	descrtf: string;
	entrydate: string | null;
	hours: number;
	rateperhour: number;
	rate: number;
	costperhour: number;
	cost: number;
	newitem: boolean;
	hastimedata: boolean;
	startdatetime: string | null;
	enddatetime: string | null;
	comptype: number;
	itemId: number;
	id: number | 0;
	timetxnum: number;
}

export interface IProjectsResponse {
	proj: any;
	projn: string;
	id: number;
}

export interface IItemsResponse {
	item: any;
	proj: any;
	projectId: number;
	itemName: string;
	id: number;
	desc: string;
	descOnly: string;
	shortdesc: string;
	timebudget: boolean;
	timeedate: string;
	timesdate: string;
}

export interface IComponentsResponse {
	refNum: string;
	shortdesc: string;
	comptype: any;
	timetxnum: number;
	id: number;
	itemId: number;
	estcost: number;
	estdisc: number;
	estmarkup: number;
	estprice: number;
	estsalestax: number;
	estunitcost: number;
	estunitprice: number;
	feecost: number;
	feeunitcost: number;
	proj: any;
	nonbilltime: boolean;
	taxable: boolean;
}

export interface ILocationsResponse {
	loc: any;
	locn: string;
}

export interface ISalesCategoriesResponse {
	scat: any;
	scatn: string;
}

export interface IEmployeesResponse {
	id: number;
	vendor: string;
	vendorn?: string;
	baserateperhr?: number;
	basecostperhr?: number;
}

export interface IFilter {
	label: string;
	value: any;
}

export interface IActivitiesResponse {
	id: number;
	activity: string;
	activityn: string;
}
