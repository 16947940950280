import LocationDropdown from 'legacy/app/components/dropdowns/LocationsDropdown';
import React from 'react';
import Label from '../../../components/label/Label';
import { WhatChanged } from 'legacy/lib/api/types/WhatChangedItems';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const LocationSelect = ({
	disabled,
	handleRecalculate,
	onCreateOption,
}: {
	onCreateOption: (value: string) => void;
	disabled: boolean;
	handleRecalculate: (whatPropertyChanged: `${WhatChanged}`) => void;
}) => {
	const { id: projectId } = useParams();
	const methods = useFormContext();
	return (
		<>
			<Label label="Location" />
			<Controller
				control={methods.control}
				name="loc"
				render={({ field }) => (
					<div className="tw-col-span-2 tw-w-full">
						<LocationDropdown
							inputId="items-page-location-select"
							onCreateOption={onCreateOption}
							isCreatable
							projectId={projectId as string}
							blurInputOnSelect
							isDisabled={disabled}
							value={field.value}
							onChange={field.onChange}
							onBlur={() => {
								const selectedValue = methods.getValues('loc');
								if (selectedValue) {
									handleRecalculate(WhatChanged.LOCATION);
								}
							}}
						/>
					</div>
				)}
			/>
		</>
	);
};

LocationSelect.displayName = 'LocationSelect';

export default LocationSelect;
