import { useQuery } from '@tanstack/react-query';
import type { TCompany2 } from 'legacy/lib/api/types/Company2';
import { getCompanyTwo } from 'api/resources/companyTwo';
import { ECacheKeys } from 'cache/CacheKeys';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetCompanyTwo = (options?: UseQueryOptions<TCompany2>) => {
	const query = useQuery<TCompany2>({
		queryKey: [ECacheKeys.CompanyTwo],
		queryFn: getCompanyTwo,
		refetchInterval: false,
		refetchOnWindowFocus: false,
		staleTime: 0,
		cacheTime: 0,
		...(options || {}),
	});

	return query;
};
