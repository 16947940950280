import React from 'react';
import { Col, Row, Form, Container, Table } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import { ActionBarVertical } from '../../components/ActionBar';
import URI from '../../../defaults/RoutesDefault';
import { NavLink } from 'react-router-dom';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../utilities/LocalStorage';

class EmployeesAddTime extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectData: [
				{ value: 'option-1', label: 'Option 1' },
				{ value: 'option-2', label: 'Option 2' },
				{ value: 'option-3', label: 'Option 3' },
			],
			startDate: '',
			minDate: new Date(),
		};
	}

	componentInit() {
		this.hasFormAction = true;
	}

	checkChange = (type, id, e, select) => {
		let key = '';
		let value = '';

		if (select) {
			key = e.name;
			value = select.value;
		} else if (e.target.getAttribute('type') === 'checkbox') {
			key = e.target.name;
			value = e.target.checked ? true : false;
		} else {
			key = e.target.name;
			value = e.target.value;
		}

		this.syncToStorage(type, id, key, value);
	};

	handleEmployeeChange = (e, select) => {
		this.checkChange('employee', (this.props.data || {}).id, e, select);
	};

	syncToStorage = (type, id, key, value) => {
		let data = getLocalStorage(type, true);
		data['id'] = id;
		data[key] = value;
		setLocalStorage(type, data, true);
	};

	handleChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
	};

	renderActionBarVertical() {
		return (
			<ActionBarVertical>
				<ActionBarVertical.Links>
					<li>
						<NavLink
							to={URI.contact.employee.timeActivityEdit}
							className="d-flex align-items-center mb-2"
						>
							<i className="ri-add-box-fill mx-0"></i>
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.contact.employee.timeActivityEdit}
							className="d-flex align-items-center"
						>
							<i className="ri-edit-line mx-0"></i>
						</NavLink>
					</li>
				</ActionBarVertical.Links>
			</ActionBarVertical>
		);
	}

	render() {
		return (
			<>
				<Container className="ms-0">
					<Row xs={1} lg={3} className="mb-0 mb-lg-3">
						<Col className="mb-2 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 1 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										defaultValue={(this.props.data || {}).baserateperhr}
										onChange={this.handleEmployeeChange}
										name="baserateperhr"
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 1 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										defaultValue={(this.props.data || {}).basecostperhr}
										onChange={this.handleEmployeeChange}
										name="basecostperhr"
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
						<Col className="mb-2 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 2 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										defaultValue={(this.props.data || {}).tier2rateperhr}
										onChange={this.handleEmployeeChange}
										name="tier2rateperhr"
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 2 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										defaultValue={(this.props.data || {}).tier2costperhr}
										onChange={this.handleEmployeeChange}
										name="tier2costperhr"
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 3 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										defaultValue={(this.props.data || {}).tier3rateperhr}
										onChange={this.handleEmployeeChange}
										name="tier3rateperhr"
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 3 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="text"
										placeholder="0.00"
										defaultValue={(this.props.data || {}).tier3costperhr}
										onChange={this.handleEmployeeChange}
										name="tier3costperhr"
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row>
						<Col
							xs={12}
							className="table-actions position-relative table-action-bar"
						>
							{this.renderActionBarVertical()}

							<div className="table-gradient">
								<Table striped responsive className="a-table">
									<thead>
										<tr>
											<th>Activity</th>
											<th>Activity Name</th>
											<th>
												Tier 1<br />
												Rate/Hr.
											</th>
											<th>
												Tier 1<br />
												Cost/Hr.
											</th>
											<th>
												Tier 2<br />
												Rate/Hr.
											</th>
											<th>
												Tier 2<br />
												Cost/Hr.
											</th>
											<th>
												Tier 3<br />
												Rate/Hr.
											</th>
											<th>
												Tier 3<br />
												Cost/Hr.
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Activity</td>
											<td>Activity Name</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
										</tr>
										<tr>
											<td>Activity</td>
											<td>Activity Name</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
										</tr>
										<tr>
											<td>Activity</td>
											<td>Activity Name</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
											<td>$1000.00</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default WithRouter(EmployeesAddTime);
