import React from 'react';
import { generatePath, Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Button,
	Col,
	Container,
	Form,
	Row,
	Table,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { routeParam, WithRouter } from '../../../../helpers/Router';
import { FooterFormAction, FormInputAdOns } from '../../../components/Form';

import Select from 'react-select';
import DatePicker from 'react-datepicker';
import InputBar from '../../../components/InputBar';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import {
	ApiService,
	POSTING_PROCESS_TYPE,
} from '../../../../lib/api/HttpService';
import { getCookie } from '../../../../utilities/Auth';

//@ts-ignore
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import {
	displayAlertError,
	displayAlertLoader,
	displayAlertSuccess,
	getErrorMessage,
} from '../../../../utilities/Response';
import { currencyFormat } from '../../../../helpers/Number';
import VendorMiscAddCheckWireModal from './VendorMiscAddCheckWireModal';
import { delay, isEmpty } from 'lodash';
import MSG from '../../../../defaults/Message';
import { navigateOnSuccess } from '../../../../utilities/Router';
import { tableSortingEnableSort } from '../../../../utilities/modules/TableSorting';
import AsyncVendorsDropdown from '../../../../app/components/dropdowns/AsyncVendorsDropdown';
import AsyncPurchaseOrderDropdown from '../../../../app/components/dropdowns/AsyncPurchaseOrdersDropdown';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import { dateToPayload, stringToDate } from '../../../../helpers/Date';
import { BasicModal } from '../../../../app/components/modal/BasicModal';
import { round } from '../../../../utilities/Number';
import VendorInvoicesDistributionModal from './VendorInvoiceDistributionModal';
import SecureDatePicker from '../../../../app/components/security/SecureDatePicker';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

class VendorInvoiceAdd extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			vendorInvoiceData: {
				selectedPO: null,
				invoiceNumber: '',
				transDesc: '',
				vendor: null,
				invoiceDate: new Date(),
				dueDate: new Date(),
				payWith: {},
				subtotal: 0,
				lessDeposit: 0,
				amountDue: 0,
				discount: 0,
				daysToTake: 0,
				notes: '',
				notesRtf: '',
				notesHTML: '',
				handckdt: null,
				handcknum: null,
				handckcashacct: null,
				handcktxdesc: null,
				showingCheckInfo: false,
			},
			initLessDeposit: 0,
			activeDateIndex: 0,
			isDisabled: true,
			data: [],

			showDistributionModal: false,
			showWireModal: false,
			transferType: 'check',
			selectGLaccounts: [],
			selectVendors: [],
			projectPoListing: [],
			projectPurchaseOrders: [],
			poIndex: '',

			checks: {},
			totalChecked: 0,

			company: {},
			componentTypes: {},
			hasExpiredInsurance: false,
			showInsuranceModal: false,
			isSaveNew: false,
		};

		this.api = new ApiService();
		this.htmlToRtfConverter = new HtmlToRtfBrowser();
		this.isNew =
			this.props.paths.pathname ===
				generatePath(URI.accountsPayable.vendorInvoice.editNewInvoice, {
					id: this.props.params.id ?? '',
				}) ||
			this.props.paths.pathname === URI.accountsPayable.vendorInvoice.add;

		this.userCode = getCookie('dmUsercode');
		this.id = this.props.params.id;
		this.isEditing = !isEmpty(this.id);
		this.updatedData = { isWireTransfer: false };
		this.setComponentProp();
	}

	setComponentProp() {
		this.saveSuccessMessage = this.isEditing
			? MSG.success.msg
			: MSG.success.create.APVendorInv;
		this.saveLoadingMessage = this.isEditing
			? MSG.loading.update.msg
			: MSG.loading.create.APVendorInv;
	}

	componentInit() {
		this.title = 'Add Invoice | Accounts Payable';
		this.setFormAction(true);
	}

	async componentDidMount() {
		this.dMloader(true);

		const company = await this.api.getCompany();
		this.setState({
			company,
		});

		const componentTypes = {
			0: company?.compviewm ?? '0',
			1: company?.compviewd ?? '1',
			2: company?.compviewf ?? '2',
			3: company?.compviewi ?? '3',
			4: company?.compviewl ?? '4',
			5: company?.compviewo ?? '5',
		};

		const generalLedgerAccounts = await this.api.getSummaryGLAccounts(
			'?$filter=specacct eq 2'
		);

		const paytypes = await this.api.getPayTypes();

		const generalLedgerAccountsSelect = generalLedgerAccounts.map((account) => {
			return {
				label: `${account.value} (${account.key})`,
				value: account.key,
				isCC: true,
			};
		});

		const payTypeSelect = paytypes.map((paytype) => {
			return {
				label: `${paytype.payType} [${paytype.defAcct}]`,
				value: paytype.defAcct,
				isCheckWire: paytype.specAcct === 1,
			};
		});

		const payWithSelect = [...generalLedgerAccountsSelect, ...payTypeSelect];

		let propjectPos = [];
		let selectedPO;
		// Edit
		if (this.id) {
			try {
				const filter = `?$filter=txnum eq ${this.id}`;

				if (!this.isNew) {
					propjectPos = await this.api.getVendorInvoices(filter);
				} else {
					propjectPos = await this.api.getTemporaryInvoiceProjectPurchaseOrder(
						filter
					);
				}
			} catch (e) {
				/* empty */
			}

			if (!propjectPos.length) {
				displayAlertError(MSG.error.VendorInvoiceDetailsNotFound);
				delay(() => {
					this.props.navigate(
						routeParam(URI.accountsPayable.vendorInvoice.listNew)
					);
				}, 2500);
				this.dMloader(false);
				return;
			}

			let editData = [];
			// We need to create a search term since New and Existing paywith field returns
			// different values
			let payWithSearch;
			// Edit New Invoice
			if (this.isNew) {
				editData = await this.api.getTemporaryVendorInvoices(
					`?$filter=txnum eq ${this.props.params.id}`
				);
				payWithSearch = isEmpty(editData[0]?.ccacct)
					? editData[0]?.paywith
					: editData[0]?.ccacct;
				// Edit Existing Invoice
			} else {
				if (this.isEditing && this.isNew) {
					editData = await this.api.getTemporaryVendorInvoices(
						`?$filter=txnum eq ${this.props.params.id}`
					);
				} else {
					editData = await this.api.getVendorInvoices(
						`?$filter=txnum eq ${this.props.params.id}`
					);
				}
				payWithSearch = isEmpty(editData[0]?.ccacct)
					? editData[0]?.paywith
					: editData[0]?.ccacct;

				const details = await this.api.getExistingVendorInvoiceDetails(
					`?$filter=txnum eq ${this.props.params.id}`
				);

				if (editData.length && details.length) {
					editData.push({
						...editData[0],
						...details[0],
					});
				}
			}

			if (editData.length) {
				const editObj = editData[0];
				selectedPO = await this.api.getProjectPurchaseOrderByProjAndPO({
					project: editObj.proj,
					ponum: editObj.ponum,
					isOption: true,
				});

				// Get vendor default object value
				const vendor = editObj?.supplier
					? await this.api.getVendorByCode(editObj?.supplier, true)
					: null;

				if (editObj.checkInfo?.length) {
					this.setState({
						showingCheckInfo: true,
					});
				}

				const lessDep = this.calcLessDeposit(propjectPos);

				this.setState({
					vendorInvoiceData: {
						selectedPO,
						invoiceNumber: editObj.sinvnum,
						transDesc: editObj.txdesc,
						vendor,
						invoiceDate: editObj.sinvdt ? stringToDate(editObj.sinvdt) : null,
						dueDate: editObj.paydt ? stringToDate(editObj.paydt) : null,
						payWith: payWithSelect.find((row) => row.value === payWithSearch),
						subtotal: this.calcSubtotal(propjectPos),
						amountDue: editObj.netAmount,
						discount: editObj.discount,
						daysToTake: editObj.discdays,
						notes: editData.notes,
						notesRtf: editData.notesRtf,
						handckdt: stringToDate(editObj.handckdt),
						handcknum: editObj.handcknum,
						handckcashacct: editObj.handckcashacct,
						handcktxdesc: editObj.handcktxdesc,
						fiscalmonth: editObj.fiscalmonth
							? stringToDate(editObj.fiscalmonth)
							: null,
						checkInfo: editObj.checkInfo,
						lessDeposit: lessDep,
					},
					initLessDeposit: lessDep,
				});
			}

			const notesHTML = await mapRtfToDraft(editData?.[0]?.notesrtf || '');

			this.setState((prevState) => ({
				vendorInvoiceData: {
					...prevState.vendorInvoiceData,
					notesHTML,
				},
			}));
		}

		this.setState(
			{
				selectGLaccounts: payWithSelect,
				projectPoListing: propjectPos,
				componentTypes,
			},
			async () => {
				if (selectedPO) {
					await this.handlePoNumChange(selectedPO);
				}
				this.enableTableSort(propjectPos);
			}
		);

		this.dMloader(false);
	}

	calcLessDeposit(details, onChange) {
		const isChanged = typeof onChange == 'boolean' && onChange ? true : false;

		if (!isChanged && this.id) {
			return details.reduce(
				(total, item) => total + Number(item.depositapp),
				0
			);
		} else {
			return this.depositAvailable + Number(this.state.initLessDeposit);
		}
	}

	isNewEdit() {
		return this.isNew && this.isEditing;
	}

	isNewAdd() {
		return this.isNew && !this.isEditing;
	}

	calcSubtotal(data) {
		let subtotal = 0;
		for (const proj of data) {
			subtotal +=
				Number(proj.mcost) +
				Number(proj.fcost) +
				Number(proj.lcost) +
				Number(proj.icost) +
				Number(proj.dcost) +
				Number(proj.ocost);
		}

		return subtotal;
	}

	handleNotesChange = (e) => {
		this.setState((prevState) => ({
			vendorInvoiceData: {
				...prevState.vendorInvoiceData,
				notes: e.target.value,
				notesRtf: this.htmlToRtfConverter.convertHtmlToRtf(e.target.html),
			},
		}));
	};

	handlePoNumChange = async (option) => {
		this.dMloader(true);

		try {
			const [purchaseOrder] = await this.api.getProjectPurchaseOrders(
				`?$filter=id eq ${option?.value}`
			);

			let txNum;
			if (!this.isEditing) {
				const newTransaction = await this.api.getTransactionCounterNext(13);
				txNum = newTransaction.newTransactionNumber;
			}

			const params = {
				txNum: this.isEditing ? this.id : txNum,
				type: 1,
				poNumber: purchaseOrder?.ponum,
				projectCode: purchaseOrder?.proj,
			};

			if (!this.isNew) {
				params.reviseTxNum = this.props.params.id;
			}

			const postInvoice = await this.api.postTemporaryInvoiceDetailsGrid(
				params
			);

			const insurances = await this.api.getInsurancesByCode(
				postInvoice?.vendorCode
			);

			const hasExpiredInsurance = insurances?.some((i) => {
				const hasExpired = i.expirationdt
					? new Date(i.expirationdt) < new Date()
					: false;

				return i.active && hasExpired;
			});

			this.depositAvailable = postInvoice.depositAvailable;

			const filter = `?$filter=usercode eq ${this.userCode} and txnum eq ${
				this.id || txNum
			}`;

			const temporaryPurchaseOrders =
				await this.api.getTemporaryInvoiceProjectPurchaseOrder(filter);

			let lessDeposit = this.calcLessDeposit(temporaryPurchaseOrders, true);
			if (this.state.vendorInvoiceData.selectedPO?.value === option.value) {
				if (this.isNew) {
					lessDeposit = this.calcLessDeposit(temporaryPurchaseOrders);
				} else {
					const details = await this.api.getExistingVendorInvoiceDetails(
						`?$filter=txnum eq ${this.props.params.id}`
					);
					lessDeposit = this.calcLessDeposit(details);
				}
			}

			let vendor = this.state.vendorInvoiceData.vendor;
			if (!this.isEditing) {
				vendor = postInvoice?.vendorCode
					? await this.api.getVendorByCode(postInvoice?.vendorCode, true)
					: null;
			}

			// Calc subtotal
			const subtotal = this.calcSubtotal(temporaryPurchaseOrders);
			let amntDue = 0;
			let discount = 0;
			let discdays = 0;
			amntDue = subtotal - lessDeposit;

			const vendorInvoices = await this.api.getTemporaryVendorInvoices(
				`?$filter=usercode eq ${this.userCode}`
			);

			if (vendorInvoices) {
				const vendorInvoice = vendorInvoices.find(
					(row) =>
						row.vendorName === vendor?.name &&
						row.ponum === purchaseOrder?.ponum &&
						row.proj === purchaseOrder?.proj
				);
				if (vendorInvoice) {
					discount = vendorInvoice.discount;
					discdays = vendorInvoice.discdays;

					// Deduct `Discount` only if posted not NEW.
					amntDue =
						amntDue - (this.isNew ? 0 : amntDue * (discount / 100).toFixed(2));
				}
			}

			this.setState(
				(prevState) => ({
					purchaseOrderObj: purchaseOrder,
					vendorInvoiceData: {
						...prevState.vendorInvoiceData,
						selectedPO: option,
						vendor,
						amountDue: amntDue,
						newTransactionNumber: this.isEditing ? this.id : txNum,
						subtotal: subtotal,
						discount: discount || this.state.vendorInvoiceData.discount,
						daysToTake: discdays || this.state.vendorInvoiceData.discdays,
						lessDeposit: lessDeposit,
					},
					projectPoListing: temporaryPurchaseOrders,
					hasExpiredInsurance,
				}),
				() => {
					this.enableTableSort(temporaryPurchaseOrders);
				}
			);
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}

		this.dMloader(false);
	};

	handleSelect = (option, field) => {
		this.setState((prevState) => ({
			vendorInvoiceData: {
				...prevState.vendorInvoiceData,
				[field]: option,
			},
		}));
	};

	handleDateChange = (date, field) => {
		this.setState((prevState) => ({
			vendorInvoiceData: {
				...prevState.vendorInvoiceData,
				[field]: date,
			},
		}));
	};

	handleInputChange = (event) => {
		this.setState((prevState) => ({
			vendorInvoiceData: {
				...prevState.vendorInvoiceData,
				[event.target.name]: event.target.value,
			},
		}));
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			prevState.checks[indx] = e.target.checked;

			return prevState;
		});
	};

	handleSelectAllChecks = (e) => {
		// Get the target menu.
		const isChecked = e.target.checked;

		this.setState((prevState) => {
			if (isChecked) {
				if (this.state.totalChecked < this.state.projectPoListing.length) {
					this.state.projectPoListing.map((item, i) => {
						prevState.checks[i] = true;
						document.getElementById(
							'chk-projectview-items-' + i
						).checked = true;
						return null;
					});
				}
			} else {
				prevState.checks = {};
				prevState.totalChecked = 0;

				const checks = document.querySelectorAll(
					'.chk-projectview-items-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}
			}

			return prevState;
		});
	};

	showDistributionModal = (index) => {
		this.setState({
			poIndex: index,
			showDistributionModal: true,
		});
	};

	hideDistributionModal = () => {
		this.setState({
			poIndex: null,
			showDistributionModal: false,
		});
	};

	handleDataUpdate = (wireData) => {
		this.updatedData = { ...this.updatedData, ...wireData };
	};

	showWireModal = () => {
		this.setState({
			showWireModal: true,
		});
	};

	hideWireModal = () => {
		this.setState({
			showWireModal: false,
		});
	};

	handleWireChanges = (wireData) => {
		this.updatedData = { ...this.updatedData, ...wireData };
		this.setState({
			updatedData: { ...this.state.updatedData, ...wireData },
		});
		this.hideWireModal();
	};

	handleTransferChange = (e) => {
		this.setState({ transferType: e.target.value });
	};

	renderHeaderTitle() {
		return `${this.isEditing ? 'Edit' : 'Add'} Invoice`;
	}

	onSaveDistributionModal = (data) => {
		const arrayCopy = this.state.projectPoListing;
		arrayCopy[this.state.poIndex] = {
			...this.state.projectPoListing[this.state.poIndex],
			...data,
			...{ usewip: !data?.usewip },
		};

		const subtotal = arrayCopy.reduce(
			(total, item) => total + Number(item.totcost),
			0
		);

		this.setState(
			{
				projectPoListing: arrayCopy,
				vendorInvoiceData: {
					...this.state.vendorInvoiceData,
					subtotal: subtotal,
					amountDue:
						subtotal - Number(this.state.vendorInvoiceData.lessDeposit),
				},
			},
			() => {
				this.enableTableSort(arrayCopy);
			}
		);

		this.hideDistributionModal();
	};

	onChangeLessDeposit = (event) => {
		const subtotal = this.state.vendorInvoiceData.subtotal;
		const lessDeposit = event.target.value;

		if (lessDeposit > this.depositAvailable) {
			displayAlertError(
				`The deposit amount cannot exceed ${currencyFormat(
					this.depositAvailable
				)}. This is the total amount of monies available out what you have already given to the supplier.`
			);
			return;
		}

		this.setState((prevState) => ({
			vendorInvoiceData: {
				...prevState.vendorInvoiceData,
				amountDue: subtotal - Number(lessDeposit),
				lessDeposit: lessDeposit,
			},
			initLessDeposit: lessDeposit,
		}));
	};

	validate() {
		const limit =
			this.depositAvailable + this.calcLessDeposit(this.state.projectPoListing);
		if (this.state.vendorInvoiceData.lessDeposit > limit) {
			displayAlertError(
				`The deposit amount cannot exceed ${currencyFormat(
					limit
				)}. This is the total amount of monies available out what you have already given to the supplier.`
			);

			return false;
		}

		return true;
	}

	onSave = async (isNew) => {
		if (!this.validate()) {
			return;
		}

		displayAlertLoader(this.saveLoadingMessage);

		try {
			const newUrl = URI.accountsPayable.vendorInvoice.add;
			const listUrl = this.isNew
				? URI.accountsPayable.vendorInvoice.listNew
				: URI.accountsPayable.vendorInvoice.listExisting;

			for (let index = 0; index < this.state.projectPoListing.length; index++) {
				const poData = this.state.projectPoListing[index];

				await this.api.patchTemporaryInvoiceProjectPurchaseOrder({
					...poData,
				});

				await this.api.postTemporaryInvoiceCostToDate({
					proj: poData.proj,
					ponum: poData.ponum,
					item: poData.item,
					comp: poData.comp,
				});
			}

			const handckdt = this.updatedData.handckdt
				? dateToPayload(this.updatedData.handckdt)
				: dateToPayload(this.state.vendorInvoiceData.handckdt);

			const sinvdt = dateToPayload(this.state.vendorInvoiceData.invoiceDate);

			const paydt = dateToPayload(this.state.vendorInvoiceData.dueDate);

			const fiscalmonth = dateToPayload(
				this.state.vendorInvoiceData.fiscalmonth
			);

			const isCC =
				this.state.selectGLaccounts.find(
					(account) =>
						account.value === this.state.vendorInvoiceData.payWith?.value
				)?.isCC ?? false;

			let txnum = this.state.vendorInvoiceData.newTransactionNumber ?? this.id;

			let purchaseOrder;
			if (this.state.purchaseOrderObj) {
				purchaseOrder = this.state.purchaseOrderObj;
			} else if (this.state.vendorInvoiceData.selectedPO) {
				const [purchaseOrderObj] = await this.api.getProjectPurchaseOrders(
					`?$filter=id eq ${this.state.vendorInvoiceData.selectedPO?.value}`
				);
				purchaseOrder = purchaseOrderObj;
			}

			if (this.isEditing) {
				// Save New Invoice
				if (this.isNew) {
					await this.api.updateTemporaryVendorInvoice({
						isWireTransfer: this.updatedData.isWireTransfer ?? false,
						txnum: this.id,
						type: 1,
						supplier: this.state.vendorInvoiceData.vendor?.value,
						sinvnum: this.state.vendorInvoiceData.invoiceNumber,
						sinvdt: sinvdt,
						paydt: paydt,
						txdesc: this.state.vendorInvoiceData.transDesc,
						discount: this.state.vendorInvoiceData.discount,
						discdays: this.state.vendorInvoiceData.daysToTake,
						proj: purchaseOrder?.proj,
						ponum: purchaseOrder?.ponum,
						usercode: parseInt(this.userCode),
						notes: this.state.vendorInvoiceData.notes,
						notesrtf: this.state.vendorInvoiceData.notesRtf,
						paywith: this.state.vendorInvoiceData.payWith?.value ?? null,
						handcknum:
							this.updatedData.handcknum ??
							this.state.vendorInvoiceData.handcknum,
						handckdt: handckdt,
						handckcashacct:
							this.updatedData.handckcashacct ??
							this.state.vendorInvoiceData.handckcashacct,
						handcktxdesc:
							this.updatedData.handcktxdesc ??
							this.state.vendorInvoiceData.handcktxdesc,
						ccacct: isCC ? this.state.vendorInvoiceData.payWith?.value : null,
					});

					await this.api.postJson('temporaryvendorinvoices/distributedeposit', {
						txnum: this.id,
						depositappliedamount: Number(
							this.state.vendorInvoiceData.lessDeposit
						),
					});
					// Save Existing Invoice
				} else {
					await this.api.createTemporaryVendorInvoice({
						isWireTransfer: this.updatedData.isWireTransfer ?? false,
						txnum: this.id,
						type: 1,
						amount: this.state.vendorInvoiceData.amountDue,
						supplier: this.state.vendorInvoiceData.vendor?.value,
						sinvnum: this.state.vendorInvoiceData.invoiceNumber,
						sinvdt: sinvdt,
						paydt: paydt,
						txdesc: this.state.vendorInvoiceData.transDesc,
						discount: this.state.vendorInvoiceData.discount,
						discdays: this.state.vendorInvoiceData.daysToTake,
						proj: purchaseOrder?.proj,
						ponum: purchaseOrder?.ponum,
						usercode: parseInt(this.userCode),
						notes: this.state.vendorInvoiceData.notes,
						notesrtf: this.state.vendorInvoiceData.notesRtf,
						paywith: this.state.vendorInvoiceData.payWith?.value ?? null,
						handckdt: handckdt,
						handcknum:
							this.updatedData.handcknum ??
							this.state.vendorInvoiceData.handcknum,
						handckcashacct:
							this.updatedData.handckcashacct ??
							this.state.vendorInvoiceData.handckcashacct,
						handcktxdesc:
							this.updatedData.handcktxdesc ??
							this.state.vendorInvoiceData.handcktxdesc,
						revisestxnum: this.id,
						fiscalMonth: fiscalmonth,
						ccacct: isCC ? this.state.vendorInvoiceData.payWith?.value : null,
					});
					await this.api.postJson('temporaryvendorinvoices/distributedeposit', {
						txNum: this.id,
						depositAppliedAmount: Number(
							this.state.vendorInvoiceData.lessDeposit
						),
					});
					await this.api.postVendorInvoiceFiscalMonth({
						processType: POSTING_PROCESS_TYPE.REVISE_TRANSACTION,
						txnum: this.id,
						fiscalMonth: fiscalmonth,
					});
				}
			} else {
				if (!txnum) {
					const newTransaction = await this.api.getTransactionCounterNext(13);
					txnum = newTransaction.newTransactionNumber;
					this.setState({
						...this.state.vendorInvoiceData,
						newTransactionNumber: newTransaction.newTransactionNumber,
					});
				}

				await this.api.createTemporaryVendorInvoice({
					isWireTransfer: this.updatedData.isWireTransfer ?? false,
					txnum: this.state.vendorInvoiceData.newTransactionNumber,
					type: 1,
					supplier: this.state.vendorInvoiceData.vendor?.value,
					sinvnum: this.state.vendorInvoiceData.invoiceNumber,
					sinvdt: sinvdt,
					paydt: paydt,
					txdesc: this.state.vendorInvoiceData.transDesc,
					discount: this.state.vendorInvoiceData.discount,
					discdays: this.state.vendorInvoiceData.daysToTake,
					proj: purchaseOrder?.proj,
					ponum: purchaseOrder?.ponum,
					usercode: parseInt(this.userCode),
					notes: this.state.vendorInvoiceData.notes,
					notesrtf: this.state.vendorInvoiceData.notesRtf,
					paywith: this.state.vendorInvoiceData.payWith?.value ?? null,
					handckdt: handckdt,
					handcknum:
						this.updatedData.handcknum ??
						this.state.vendorInvoiceData.handcknum,
					handckcashacct:
						this.updatedData.handckcashacct ??
						this.state.vendorInvoiceData.handckcashacct,
					handcktxdesc:
						this.updatedData.handcktxdesc ??
						this.state.vendorInvoiceData.handcktxdesc,
					revisestxnum: this.id,
					ccacct: isCC ? this.state.vendorInvoiceData.payWith?.value : null,
				});

				await this.api.postJson('temporaryvendorinvoices/distributedeposit', {
					txnum: this.id ?? txnum,
					depositappliedamount: Number(
						this.state.vendorInvoiceData.lessDeposit
					),
				});
			}

			displayAlertSuccess(this.saveSuccessMessage);

			const _delay = isNew ? 1200 : 500;
			delay(() => navigateOnSuccess(this, isNew, newUrl, listUrl), _delay);
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}
	};

	enableTableSort(data) {
		tableSortingEnableSort({
			data,
			stateKey: 'projectPoListing',
			classRef: this,
			targetTable: '.a-table-heading.invoice',
		});
	}

	isCheckWire() {
		return (
			this.state.selectGLaccounts.find(
				(account) =>
					account.value === this.state.vendorInvoiceData.payWith?.value
			)?.isCheckWire === true
		);
	}

	showConfirmationModal(isNew) {
		this.setState({
			showInsuranceModal: true,
			isSaveNew: isNew,
		});
	}

	handleCloseModal() {
		this.setState({
			showInsuranceModal: false,
		});
	}

	handleConfirmSave() {
		this.onSave(this.state.isSaveNew);
		this.handleCloseModal();
	}

	renderHeader() {
		const listURL = this.isNew
			? URI.accountsPayable.vendorInvoice.listNew
			: URI.accountsPayable.vendorInvoice.listExisting;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={listURL}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: listURL,
							}}
							linkAs={Link}
						>
							Accounts Payable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: listURL,
							}}
							linkAs={Link}
						>
							Vendor Deposits & Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item>{this.renderHeaderTitle()}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.renderHeaderTitle()}</HeaderLight.Title>

					<div>
						<Button
							as={Link}
							variant="ivory"
							name="cancel"
							className="mx-3"
							to={listURL}
						>
							Cancel
						</Button>
						{/* Submit Button */}
						{!this.isEditing && (
							<Button
								variant="primary me-2"
								name="saveNew"
								onClick={() => {
									return this.state?.hasExpiredInsurance
										? this.showConfirmationModal(true)
										: this.onSave(true);
								}}
							>
								Save & New
							</Button>
						)}

						<Button
							variant="primary"
							name="save"
							onClick={() => {
								return this.state?.hasExpiredInsurance
									? this.showConfirmationModal(false)
									: this.onSave(false);
							}}
						>
							Save
						</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height">
					<Container fluid>{this.renderContent()}</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button
						variant="primary"
						size="lg"
						name="save"
						onClick={() => {
							return this.state?.hasExpiredInsurance
								? this.showConfirmationModal(false)
								: this.onSave(false);
						}}
					>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}

	renderInputBar() {
		return (
			<InputBar className="full multiple">
				<InputBar.Links>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Subtotal
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control
								type="text"
								placeholder="0.00"
								size="sm"
								value={round(this.state.vendorInvoiceData.subtotal)}
								readOnly
							/>
						</FormInputAdOns>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Less Deposit
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control
								type="text"
								placeholder="0.00"
								size="sm"
								value={this.state.vendorInvoiceData.lessDeposit}
								name="lessDeposit"
								onChange={this.onChangeLessDeposit}
							/>
						</FormInputAdOns>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Amount Due
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control
								type="text"
								placeholder="0.00"
								size="sm"
								value={round(this.state.vendorInvoiceData.amountDue)}
								readOnly
							/>
						</FormInputAdOns>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		);
	}

	renderContent() {
		const DatePickerComponent = this.isNew ? DatePicker : SecureDatePicker;
		return (
			<>
				<Form.Group>
					<Row xs={1} lg={2} className="py-4">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">PO/WO Number</Form.Label>
								</Col>
								<Col>
									<AsyncPurchaseOrderDropdown
										value={this.state.vendorInvoiceData.selectedPO}
										onChange={this.handlePoNumChange}
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
											}),
										}}
										className="react-select"
										placeholder="Please select"
										isDisabled={!this.isNew}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Invoice Number</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										value={this.state.vendorInvoiceData.invoiceNumber}
										name="invoiceNumber"
										onChange={this.handleInputChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Trans. Desc.</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										maxLength={30}
										placeholder="Please enter"
										value={this.state.vendorInvoiceData.transDesc}
										name="transDesc"
										onChange={this.handleInputChange}
									/>
								</Col>
							</Row>
						</Col>

						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor</Form.Label>
								</Col>
								<Col>
									<AsyncVendorsDropdown
										value={this.state.vendorInvoiceData?.vendor}
										onChange={(option) => {
											this.handleSelect(option, 'vendor');
										}}
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
											}),
										}}
										className="react-select"
										placeholder="Select vendor"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Invoice Date</Form.Label>
								</Col>
								<Col lg={3}>
									<div className="react-select-header">
										<DatePicker
											showMonthDropdown="true"
											showYearDropdown="true"
											selected={this.state.vendorInvoiceData.invoiceDate}
											onChange={(date) => {
												this.handleDateChange(date, 'invoiceDate');
											}}
											className="form-control"
											placeholderText="Select"
										/>
									</div>
								</Col>

								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Due Date</Form.Label>
								</Col>
								<Col lg={3}>
									<div className="react-select-header">
										<DatePicker
											showMonthDropdown="true"
											showYearDropdown="true"
											selected={this.state.vendorInvoiceData.dueDate}
											onChange={(date) => {
												this.handleDateChange(date, 'dueDate');
											}}
											className="form-control"
											placeholderText="Select"
										/>
									</div>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Pay With</Form.Label>
								</Col>
								<Col lg={3}>
									<Select
										options={this.state.selectGLaccounts}
										value={this.state.vendorInvoiceData.payWith}
										onChange={(option) => {
											this.handleSelect(option, 'payWith');
										}}
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 9999,
											}),
										}}
										className="react-select z-500"
										placeholder="Please select"
									/>
								</Col>

								{this.isNew || !this.isEditing ? (
									<Col lg={3}>
										<Button
											onClick={this.showWireModal}
											variant="primary"
											size="sm"
											className="btn-icon"
											style={{ fontSize: '11px' }}
											disabled={!this.isCheckWire()}
										>
											Enter Hand Check / Wire Details
										</Button>
									</Col>
								) : (
									<>
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Fiscal Month</Form.Label>
										</Col>
										<Col lg={3}>
											<div className="react-select-header">
												<DatePickerComponent
													selected={this.state.vendorInvoiceData.fiscalmonth}
													onChange={(date) => {
														this.handleDateChange(date, 'fiscalmonth');
													}}
													className="form-control"
													placeholderText="Please select"
													showMonthYearPicker
													dateFormat="MM/yyyy"
												/>
											</div>
										</Col>
									</>
								)}
							</Row>
						</Col>
					</Row>

					<Row className="py-2">
						<Col sm={12} className="mb-3 mb-lg-0 table-action-bar z-1">
							<div className="table-gradient ">
								<Table striped responsive className="a-table z-1">
									<thead>
										<tr key="0" className="a-table-heading invoice">
											<th>
												<span className="sort" data-field="refnum">
													Ref. #
												</span>
											</th>
											<th>
												<span className="sort" data-field="comptype">
													Type
												</span>
											</th>
											<th>
												<span className="sort" data-field="desc">
													Description
												</span>
											</th>
											<th className="mw-110px">
												<span className="sort" data-field="catalog">
													Mfg Cat.#
												</span>
											</th>
											<th className="mw-90px">
												<span className="sort" data-field="poquan">
													PO Qty.
												</span>
											</th>
											<th className="mw-130px">
												<span className="sort" data-field="pocost">
													PO (Est.) Cost
												</span>
											</th>
											<th className="mw-120px">
												<span className="sort" data-field="costtodate">
													Cost-to-date
												</span>
											</th>
											<th>
												<span className="sort" data-field="recqty">
													Received
												</span>
											</th>
											<th>
												<span className="sort" data-field="quanpaidfor">
													Qty. on Invoice
												</span>
											</th>
											<th>
												<span className="sort" data-field="totcost">
													Cost on Invoice
												</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{this.state.projectPoListing.map((item, i) => (
											<tr
												key={i}
												className={this.state.checks[i] ? `active` : ''}
											>
												<td>
													<Link
														className="text-black"
														onClick={() => {
															this.showDistributionModal(i);
														}}
													>
														{item.refnum}
													</Link>
												</td>
												<td>{this.state.componentTypes[`${item.comptype}`]}</td>
												<td>{item.desc}</td>
												<td>{item.catalog}</td>
												<td>{item.poquan}</td>
												<td>{currencyFormat(item.pocost)}</td>
												<td>{item.costtodate}</td>
												<td>{item.recqty}</td>
												<td>{item.quanpaidfor}</td>
												<td>{currencyFormat(item.totcost)}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</Col>
						<Col sm={12}>{this.renderInputBar()}</Col>
					</Row>

					<Row xs={1} lg={2} className="py-4">
						<Col className="mb-3 mb-lg-0">
							<Row>
								<Col lg={10}>
									<Row xs={1} lg={2}>
										<Col className="mb-3 mb-lg-0">
											<Row>
												<Col xs={12} className="mb-2">
													<Form.Label className="mb-0">Discount</Form.Label>
												</Col>
												<Col xs={12}>
													<Form.Control
														type="number"
														className="no-spinner"
														placeholder="Please enter"
														value={this.state.vendorInvoiceData.discount}
														name="discount"
														onChange={this.handleInputChange}
													/>
												</Col>
											</Row>
										</Col>
										<Col className="mb-3 mb-lg-0">
											<Row>
												<Col xs={12} className="mb-2">
													<Form.Label className="mb-0">Days to Take</Form.Label>
												</Col>
												<Col xs={12}>
													<Form.Control
														type="number"
														className="no-spinner"
														placeholder="Please enter"
														value={this.state.vendorInvoiceData.daysToTake}
														name="daysToTake"
														onChange={this.handleInputChange}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>

						<Col className="mb-3 mb-lg-0">
							<Row xs={12} lg={2}>
								<Col lg={{ span: 10, offset: 2 }}>
									<Row>
										<Col xs={12} className="mb-2">
											<Form.Label className="mb-0">Notes</Form.Label>
										</Col>
										<Col xs={12}>
											<WysiwygEditor onChange={this.handleNotesChange}>
												{this.state.vendorInvoiceData.notesHTML || ''}
											</WysiwygEditor>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form.Group>

				{this.state.showDistributionModal && (
					<VendorInvoicesDistributionModal
						showModal={this.state.showDistributionModal}
						hideModal={this.hideDistributionModal}
						data={this.state.projectPoListing[this.state.poIndex] || {}}
						company={this.state.company}
						onSave={this.onSaveDistributionModal}
					/>
				)}
				{this.state.showWireModal && (
					<VendorMiscAddCheckWireModal
						hideModal={this.hideWireModal.bind(this)}
						data={this.state.vendorInvoiceData}
						updatedData={this.state.updatedData}
						onDataUpdate={this.handleDataUpdate.bind(this)}
						onWireOkay={this.handleWireChanges}
					/>
				)}
				{this.state.showingCheckInfo && (
					<BasicModal
						message={`This invoice cannot be edited because it has a payment associated with it: ${this.state.vendorInvoiceData.checkInfo}`}
						onPrimary={() => {
							this.props.navigate(
								URI.accountsPayable.vendorInvoice.listExisting
							);
						}}
						hasSecondary={false}
					/>
				)}
				{this.state.showInsuranceModal && (
					<ConfirmationModal
						confirmAction={this.handleConfirmSave.bind(this)}
						show={true}
						toggleModal={this.handleCloseModal.bind(this)}
						labelCancel="Cancel"
						labelOK="Ok"
						message="WARNING: the vendor has an active Insurance Policy that has expired."
						title="Vendor deposit, Invoice or Operating Expense"
					/>
				)}
			</>
		);
	}
}

export default WithRouter(VendorInvoiceAdd);
