import React from 'react';
import { Button, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import { pathParam } from '../../../../utilities/Router';
import { compareStr } from '../../../../utilities/String';
import { HeaderLight } from '../../../components/header/HeaderComponent';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import SecureBootstrapButton from '../../../components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security';

interface Props {
	employeeName?: string;
	activeMenu?: string;
	onSave?: any;
	employeeId?: any;
	isLoading?: boolean;
	onSwitchTab?: any;
	isDraft?: boolean;
	numAttachments: number;
	isSaveHidden: boolean;
}
interface State {
	employeeName?: string;
	isLoading?: boolean;
	showButtons?: boolean;
}

interface HeaderLink {
	info: string;
	notes: string;
	time: string;
	showroom: string;
	documents: string;
}

export default class EmployeeAddEditHeader extends React.Component<
	Props,
	State
> {
	isEditing: boolean;

	constructor(props: Props) {
		super(props);

		this.state = {
			employeeName: 'Add Employee',
			isLoading: false,
			showButtons: false,
		};
		this.isEditing = Boolean(this.props.employeeId);
	}

	componentDidMount(): void {
		if (!this.props.employeeName && this.props.employeeId) {
			this.setState({
				employeeName: 'Edit Employee',
			});
		}
		if (this.props.employeeName) {
			const { employeeName, isLoading } = this.props;

			this.setState({
				employeeName: employeeName,
				isLoading: isLoading,
			});
		}

		window.localStorage.removeItem('isAdd');
		if (!this.props.employeeId) {
			setLocalStorage('isAdd', true);
		}

		setTimeout(() => {
			this.setState({
				showButtons: true,
			});
		}, 250);
	}

	componentDidUpdate(previousProps: Props, previousState: State): void {
		if (
			previousProps.employeeName !== this.props.employeeName ||
			previousProps.isLoading !== this.props.isLoading
		) {
			const { employeeName, isLoading } = this.props;

			this.setState({
				employeeName: employeeName || 'Add Employee',
				isLoading: isLoading,
			});
		}
	}

	handleClickSave: any = (e: any) => {
		window.localStorage.setItem('isDraft', 'false');
		window.onbeforeunload = null;
		if (this.props.onSave) {
			this.props.onSave(e);
		}
	};

	onSwitchTab: any = (e: any) => {
		if (this.props.onSwitchTab) {
			this.props.onSwitchTab(e);
		}
	};

	handleClick = (e: any) => {
		const isDraft = getLocalStorage('isDraft');

		if (isDraft === 'true') {
			e.preventDefault();

			if (confirm('Leaving this screen will discard your changes.')) {
				window.localStorage.setItem('isDraft', 'false');
				if (e.currentTarget.href !== undefined) {
					location.href = e.currentTarget.href;
				} else if (e.target.href !== undefined) {
					location.href = e.target.href;
				} else {
					location.href = e.currentTarget.firstChild.href;
				}
			}
		}
	};

	render(): JSX.Element {
		const { employeeName, isLoading } = this.state;
		const { activeMenu, employeeId, isDraft } = this.props;

		const link: HeaderLink = {
			info: pathParam(
				employeeId ? URI.contact.employeeEdit : URI.contact.employeeAdd,
				{ id: this.props.employeeId, tab: 'info' }
			),
			notes: pathParam(
				employeeId ? URI.contact.employeeEdit : URI.contact.employeeAdd,
				{ id: this.props.employeeId, tab: 'notes' }
			),
			time: pathParam(
				employeeId ? URI.contact.employeeEdit : URI.contact.employeeAdd,
				{ id: this.props.employeeId, tab: 'time' }
			),
			showroom: pathParam(
				employeeId ? URI.contact.employeeEdit : URI.contact.employeeAdd,
				{ id: this.props.employeeId, tab: 'showroom' }
			),
			documents: pathParam(
				employeeId ? URI.contact.employeeEdit : URI.contact.employeeEdit,
				{ id: this.props.employeeId, tab: 'documents' }
			),
		};

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<Breadcrumb>
						<Breadcrumb.Item
							onClickCapture={this.handleClick}
							linkProps={{ to: URI.contact.employee }}
							linkAs={Link}
						>
							Employees
						</Breadcrumb.Item>
						<Breadcrumb.Item>{employeeName}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{employeeName}</HeaderLight.Title>

					<div className={this.state.showButtons ? 'd-block' : 'd-none'}>
						{/* Submit Button */}
						<SecureBootstrapButton
							attributeNo={40}
							attributeType={
								this.isEditing
									? SECURITY_ATTRIBUTE_TYPES.DenyEdit
									: SECURITY_ATTRIBUTE_TYPES.DenyAdd
							}
							className={this.props.employeeId ? 'd-none' : 'd-inline-block'}
							variant="primary me-2"
							onClick={this.handleClickSave}
							name="saveNew"
							disabled={!isDraft || isLoading}
						>
							Save & New
						</SecureBootstrapButton>

						<SecureBootstrapButton
							attributeNo={40}
							attributeType={
								this.isEditing
									? SECURITY_ATTRIBUTE_TYPES.DenyEdit
									: SECURITY_ATTRIBUTE_TYPES.DenyAdd
							}
							variant="primary"
							onClick={this.handleClickSave}
							name="save"
							disabled={!isDraft || isLoading}
						>
							Save
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
				<HeaderLight.Actions>
					<li>
						<Link
							onClick={this.onSwitchTab}
							to={link.info}
							data-menu="info"
							className={
								`btn text-charcoal ` +
								compareStr(activeMenu || '', 'info', 'active', '')
							}
						>
							Info
						</Link>
					</li>
					<li>
						<Link
							onClick={this.onSwitchTab}
							to={link.notes}
							data-menu="notes"
							className={
								`btn text-charcoal ` +
								compareStr(activeMenu || '', 'notes', 'active', '')
							}
						>
							Notes
						</Link>
					</li>
					<li>
						<Link
							onClick={this.onSwitchTab}
							to={link.time}
							data-menu="time"
							className={
								`btn text-charcoal ` +
								compareStr(activeMenu || '', 'time', 'active', '')
							}
						>
							Time
						</Link>
					</li>
					{employeeId && (
						<li>
							<Link
								onClick={this.onSwitchTab}
								to={link.documents}
								data-menu="documents"
								className={
									`btn text-charcoal ` +
									compareStr(activeMenu || '', 'documents', 'active', '')
								}
							>
								Documents{' '}
								{this.props.numAttachments > 0 && (
									<i className="ri-attachment-2 ms-1"></i>
								)}
							</Link>
						</li>
					)}
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}
}
