import { apiClient } from 'api/apiClient';

export interface IPayType {
	accountn: string;
	specAcct: number;
	payType: string;
	defAcct: string;
	seqNum: number;
	confirmOnlinePay: boolean;
}

export interface IGeneralLedgerAccount {
	fixedType: number;
	account: string;
	accountn: string;
	dept: string;
	type: number;
	specacct: number;
	banknum: string;
	checknum: string;
	nextchecknum: string;
	supplier: string;
	payroll: boolean;
	cashcloseddt: string;
	closedfmo: string;
	ppbankname: string;
	ppfileloc: string;
	ppincludevoids: boolean;
	ppincludehc: boolean;
}

export interface IMappedPayType {
	value: number | string;
	label: string;
	accountNumber: string | number;
	payType: string;
}

export const getPayTypes = async (): Promise<IMappedPayType[]> => {
	const { data: accounts } = await apiClient.get(
		`/GeneralLedgerAccounts/?$filter=specacct eq 2`
	);

	const { data: payTypes } = await apiClient.get(`/PayTypes`);

	const mappedPayTypes = payTypes.map((payType: IPayType) => ({
		label: `${payType.payType} [${payType.defAcct}]`,
		value: `${payType.payType} ${payType.defAcct}`,
		accountNumber: payType.defAcct,
		payType: payType.payType,
	}));

	const mappedAccounts = accounts.map((account: IGeneralLedgerAccount) => ({
		label: `${account.accountn} [${account.account}]`,
		value: `${account.accountn} ${account.account}`,
		accountNumber: account.account,
	}));

	return [...mappedAccounts, ...mappedPayTypes];
};
