import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IProposal } from 'api/resources/proposals';
import { getProposals } from 'api/resources/proposals';

import { ECacheKeys } from 'cache/CacheKeys';

export const useGetProposals = (
	filter?: string,
	options?: UseQueryOptions<IProposal[]>
) => {
	const query = useQuery<IProposal[]>({
		queryKey: [ECacheKeys.Proposals, filter],
		queryFn: () => getProposals(filter),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
