import React from 'react';
import DatePicker from 'react-datepicker';

import { useHasAccess } from '../../../context/security';

export default function SecureDatePicker({
	attributeNo,
	attributeType,
	...props
}) {
	const hasAccess = useHasAccess(attributeNo, attributeType);
	const isDisabled = !hasAccess ? true : props.disabled;

	return <DatePicker {...props} disabled={isDisabled} />;
}
