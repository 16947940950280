import React, { useEffect, useState } from 'react';
import { TransactionTabs, TTabOption } from './partials/TransactionTabs';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import { TransactionsTable } from './partials/TransactionsTable';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import URI from 'legacy/defaults/RoutesDefault';
import { useGetPlaidAccounts } from 'api/hooks/useGetPlaidAccounts';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';
import { Filters } from './partials/Filters';

export const Transactions = () => {
	const navigate = useNavigate();
	const showFeature = useFeature(FeatureFlags.Transactions);
	const [activeTab, setActiveTab] = useState<TTabOption>('New');
	const { data: accounts, isLoading, error } = useGetPlaidAccounts();
	const [selectedAccount, setSelectedAccount] = useState<TTypeOption | null>(
		null
	);
	const [enableFilters, setEnableFilters] = useState(false);

	const toggleTab = (tab: TTabOption) => setActiveTab(tab);

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error getting the accounts information, please try again'
			);
		}
	}, [error]);

	if (isLoading) {
		return <Spinner />;
	}

	return !showFeature || !accounts ? null : (
		<div className="tw-flex tw-flex-col tw-gap-2">
			<div className="tw-bg-[#006A53] tw-px-8 tw-py-10 tw-pb-4">
				<div className="tw-mb-10">
					<Breadcrumb className="breadcrumb-light">
						<Breadcrumb.Item
							onClick={() => navigate(URI.accountsPayable.transactions)}
						>
							Accounts Payable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClick={() => navigate(URI.accountsPayable.transactions)}
						>
							Transactions
						</Breadcrumb.Item>
					</Breadcrumb>
				</div>
				<h2 className="tw-text-4xl tw-text-white">Transactions</h2>
				<TransactionTabs toggleTab={toggleTab} activeTab={activeTab} />
			</div>
			<div className="tw-mt-2">
				<Filters
					onChangeAccount={setSelectedAccount}
					selectedAccount={selectedAccount}
					accounts={accounts}
					enableFilters={enableFilters}
					setEnableFilters={setEnableFilters}
				/>
			</div>
			{selectedAccount && (
				<TransactionsTable
					key={selectedAccount.value}
					enableFilters={enableFilters}
					accountId={selectedAccount.value as number}
					selectedTab={activeTab}
				/>
			)}
		</div>
	);
};

Transactions.displayName = 'Transactions';
