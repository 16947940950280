import { useState } from 'react';
import { Alert } from 'react-bootstrap';

export function AlertLoader(props: any = {}) {
	return (
		<Alert variant="light" className="has-icon alert-body" id="response-alert">
			<span
				className="spinner-border spinner-border-sm alert-icon"
				role="status"
				aria-hidden="true"
			></span>{' '}
			{props.children ? (
				<strong className="fw-bold">{props.children}</strong>
			) : (
				<strong
					className="fw-bold"
					dangerouslySetInnerHTML={{
						__html: props.message || '&nbsp;',
					}}
				></strong>
			)}
		</Alert>
	);
}

AlertLoader.displayName = 'AlertLoader';

export function AlertMessage(props: any = {}) {
	const [show, setShow] = useState(true);
	const onClose = () => {
		setShow(false);
		props.onClose && props.onClose();
	};
	return (
		<Alert
			variant={`${props.variant || 'danger'}`}
			className={`${props.icon ? 'has-icon' : ''} alert-body ${
				show ? '' : 'd-none'
			} ${props.className ?? ''}`}
			id="response-alert"
		>
			{props.icon && <i className={`${props.icon} alert-icon`}></i>}

			{props.children ? (
				<strong className={`${props.fw ?? 'fw-bold'}`}>{props.children}</strong>
			) : (
				<strong
					className={`${props.fw ?? 'fw-bold'}`}
					dangerouslySetInnerHTML={{
						__html: props.message || '&nbsp;',
					}}
				></strong>
			)}

			{props.isClose && (
				<a className="close" title="close" onClick={() => onClose()}>
					×
				</a>
			)}
		</Alert>
	);
}

AlertMessage.displayName = 'AlertMessage';

function AlertMaker(props: any = {}) {
	props = {
		isClose: true,
		...props,
	};

	return <AlertMessage {...props} />;
}

AlertMaker.displayName = 'AlertMaker';

export function AlertError(props: any = {}) {
	return (
		<AlertMaker icon="ri-error-warning-line" {...props} variant="danger" />
	);
}

AlertError.displayName = 'AlertError';

export function AlertSuccess(props: any = {}) {
	return (
		<AlertMaker icon="ri-checkbox-circle-line" {...props} variant="success" />
	);
}

AlertSuccess.displayName = 'AlertSuccess';

export function AlertInfo(props: any = {}) {
	return <AlertMaker icon="ri-information-line" {...props} variant="info" />;
}

AlertInfo.displayName = 'AlertInfo';

export function AlertWarning(props: any = {}) {
	return (
		<AlertMaker icon="ri-error-warning-line" {...props} variant="warning" />
	);
}

AlertWarning.displayName = 'AlertWarning';

export function AlertPrimary(props: any = {}) {
	return <AlertMaker icon={false} {...props} variant="primary" />;
}

AlertPrimary.displayName = 'AlertPrimary';

export function AlertLight(props: any = {}) {
	return <AlertMaker icon={false} {...props} variant="light" />;
}

AlertLight.displayName = 'AlertLight';

export function AlertWhite(props: any = {}) {
	return <AlertMaker icon={false} {...props} variant="white" />;
}

AlertWhite.displayName = 'AlertWhite';
