import React from 'react';
import { Row, Col, Modal, Button, Table, Form } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';

class AccountBalancesModal extends ThreadComponent {
	render() {
		const labels = [
			'Opening',
			'Month 1',
			'Month 2',
			'Month 3',
			'Month 4',
			'Month 5',
			'Month 6',
			'Month 7',
			'Month 8',
			'Month 9',
			'Month 10',
			'Month 11',
			'Month 12',
			'Closing',
		];

		return (
			<Modal
				size="xl"
				show={this.props.show}
				onHide={() => this.hide()}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal modal-quarter fixed-footer"
				backdrop="static"
			>
				<Modal.Body className="bg-ivory">
					<Row className="w-100 px-5 mt-5">
						<Col sm={12}>
							<Table responsive borderless className="">
								<thead>
									<tr key="0">
										<th className="fs-md pb-3"></th>
										<th className="fs-md pb-3 text-center" colSpan={2}>
											<div className="bg-sand py-3 roundedx-4">
												Current Year (2022)
											</div>
										</th>
										<th className="fs-md pb-3"></th>
										<th className="fs-md pb-3 text-center" colSpan={2}>
											<div className="bg-sand py-3 roundedx-4">
												Prior Year(2021)
											</div>
										</th>
									</tr>
									<tr key="0">
										<th className="fs-md text-end mw-110px">Fiscal Month</th>
										<th className="fs-md text-center mw-150px">Debit</th>
										<th className="fs-md text-center mw-150px">Credit</th>
										<th className="fs-md"></th>
										<th className="fs-md text-center mw-150px">Debit</th>
										<th className="fs-md text-center mw-150px">Credit</th>
									</tr>
								</thead>
								<tbody>
									{labels.map((item, i) => (
										<tr key={0}>
											<td className="text-end" valign="middle">
												{item}
											</td>
											<td valign="middle">
												<Form.Control
													type="text"
													defaultValue={1200}
													className="bg-white"
													placeholder="Enter debit"
													disabled
													readOnly
												/>
											</td>
											<td valign="middle">
												<Form.Control
													type="text"
													defaultValue={1200}
													className="bg-white"
													placeholder="Enter credit"
													disabled
													readOnly
												/>
											</td>
											<td></td>
											<td valign="middle">
												<Form.Control
													type="text"
													defaultValue={1200}
													className="bg-white"
													placeholder="Enter debit"
													disabled
													readOnly
												/>
											</td>
											<td valign="middle">
												<Form.Control
													type="text"
													defaultValue={1200}
													className="bg-white"
													placeholder="Enter credit"
													disabled
													readOnly
												/>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="text-end">
							<Button
								variant="primary"
								onClick={this.props.hideModal.bind(null)}
								className="d-inline-block w-150px"
							>
								Save
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal.bind(null)}
								className="d-inline-block w-150px ms-3"
							>
								Close
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(AccountBalancesModal);
