import { mapRtfToDraft } from 'legacy/utilities/Rtf';

export const formatDescription = async (data: string) => {
	const initialDraftData = await mapRtfToDraft(data, true);
	let normalisedFormattedData = initialDraftData
		.replaceAll('div', 'p')
		.replaceAll(`style=""`, '')
		.replaceAll(`<span style="font-size: 12pt;"> </span></p>`, '');

	const lastEmptyPTag = normalisedFormattedData.lastIndexOf('<p ></p>');

	if (lastEmptyPTag !== -1) {
		normalisedFormattedData =
			normalisedFormattedData.slice(0, lastEmptyPTag) +
			normalisedFormattedData.slice(lastEmptyPTag + '<p ></p>'.length);
	}

	return normalisedFormattedData;
};
