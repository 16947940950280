import * as React from 'react';
import AppNavigation from './legacy/app/AppNavigation';
import { initDOMEvents, toggleDMPageLoader } from './legacy/utilities/DOM';
import { isDraft } from './legacy/utilities/ModuleHelper';
import { setDraft } from './legacy/utilities/FormEvent';
import { delay } from 'lodash';
import { AppWrapper } from 'AppWrapper';

interface IState {
	assetsLoaded: boolean;
}
interface IProps {}

class App extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			assetsLoaded: false,
		};
	}

	componentDidMount() {
		this.setState({ assetsLoaded: true });

		window.onpopstate = (e) => {
			this.onPopStateChange(e);
		};
	}

	componentDidUpdate() {
		initDOMEvents();
	}

	onPopStateChange = (e: any) => {
		// Let's remove some loaders.
		toggleDMPageLoader({ destroy: true });

		if (isDraft()) {
			setDraft(false);
			if (!confirm('Leaving this screen will discard your changes.')) {
				history.go(1);

				// Let's remove some loaders.
				delay(() => toggleDMPageLoader({ destroy: true }), 70);
			}
		}
	};

	render() {
		if (this.state.assetsLoaded) {
			return (
				<AppWrapper>
					<AppNavigation />
				</AppWrapper>
			);
		} else {
			return <></>;
		}
	}
}

export default App;
