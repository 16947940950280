import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { UploadedFilePayload } from '../types/UploadedFilePayload';
import { UploadedFileResponse } from '../types/UploadedFileResponse';

const api = new ApiService();

const useUploadFile = (
	options?: UseMutationOptions<
		UploadedFileResponse,
		unknown,
		UploadedFilePayload,
		unknown
	>
) => {
	return useMutation({
		mutationFn: (uploadedFilePayload: UploadedFilePayload) => {
			const file = api.saveFile(
				uploadedFilePayload
			) as Promise<UploadedFileResponse>;
			return file;
		},
		...(options || {}),
	});
};
export default useUploadFile;
