const MAX_CODE_LENGTH = 5;
const ALPHANUMERIC = /[^A-Z0-9]/g;
// The only characters not allowed are single quotes, double quotes, or whitespaces.
const VALID_CODE = /[\s'"]/g;

const generateCode = (value: string, regex: RegExp) => {
	const code = value.toUpperCase().replaceAll(regex, '');
	return code.substring(0, MAX_CODE_LENGTH);
};

const nameToCode = (name: string) => generateCode(name, ALPHANUMERIC);

const formatCode = (code: string) => generateCode(code, VALID_CODE);

export { nameToCode, formatCode };
