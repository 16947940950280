import React from 'react';
import Icon from '../Icon/Icon';
import { formatCurrency } from 'legacy/utilities/formatCurrency';

interface ISummaryCardProps {
	iconPath: string;
	count?: number;
	title: string;
	amount?: number;
	iconAlt: string;
	tooltip?: string;
}

const SummaryCard = ({
	iconPath,
	count = 0,
	title,
	amount = 0,
	iconAlt,
	tooltip,
}: ISummaryCardProps) => (
	<div
		className="tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6 tw-flex tw-items-center tw-w-full"
		title={tooltip}
	>
		<div className="tw-bg-[#e5e5e5] tw-rounded-full tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-mr-2">
			<Icon alt={iconAlt} path={iconPath} iconClass={'tw-h-4'} />
		</div>
		<div className="tw-flex tw-items-center tw-overflow-hidden">
			<div className="tw-text-base md:tw-text-3xl tw-font-bold tw-text-black tw-mr-4">
				{count}
			</div>
			<div className="tw-flex tw-flex-col">
				<div className="tw-text-black">{title}</div>
				<div className="tw-text-gray-500">{formatCurrency(amount)}</div>
			</div>
		</div>
	</div>
);

SummaryCard.displayName = 'SummaryCard';

export default SummaryCard;
