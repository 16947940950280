import { Component } from 'legacy/lib/api/types/Component';
import CostPricingSection from './CostPricingSection';
import MainSection from './MainSection';
import SubComponents from './SubComponents';
import { TWhatChangedComponent } from 'legacy/lib/api/types/WhatChangedComponent';

const Info = ({
	multipleCompsRecalculating,
	setMultipleCompsRecalculating,
	isRecalculating,
	handleRecalculate,
	component,
	updateComponents,
	subcomponents,
	onSubmit,
}: {
	multipleCompsRecalculating: boolean;
	setMultipleCompsRecalculating: (newValue: boolean) => void;
	handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void;
	updateComponents: (components: Component[]) => void;
	isRecalculating: boolean;
	component: Component;
	subcomponents: Component[];
	onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}) => (
	<form className="tw-flex tw-flex-col tw-gap-4" onSubmit={onSubmit}>
		<MainSection
			component={component}
			disableInputs={isRecalculating}
			handleRecalculate={handleRecalculate}
		/>
		<CostPricingSection
			disableInputs={isRecalculating}
			handleRecalculate={handleRecalculate}
		/>
		{component?.comp === component?.assocwithcomp && (
			<SubComponents
				multipleCompsRecalculating={multipleCompsRecalculating}
				setMultipleCompsRecalculating={setMultipleCompsRecalculating}
				isParentRecalculating={isRecalculating}
				mainComponentComp={component?.comp as string}
				components={subcomponents}
				updateComponents={updateComponents}
			/>
		)}
	</form>
);

Info.displayName = 'Info';
export default Info;
