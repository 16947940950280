import React, { useEffect, useState } from 'react';
import { TWarehouse } from 'legacy/lib/api/types/Warehouse';
import { displayAlert } from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import usePaginatedQuery from 'legacy/lib/api/hooks/usePaginatedQuery';
import BaseSelect from 'legacy/app/components/Selects/BaseSelect';
import { getWarehouses } from 'api/resources/warehouses';

interface IWarehouseSelect {
	handleSelect: (warehouse: TWarehouse | null) => void;
	defaultSelected?: string;
}

export const WarehouseSelect = ({
	handleSelect,
	defaultSelected,
}: IWarehouseSelect) => {
	const warehouseFilter =
		'?$filter=inactive eq false and addresstype eq 1 and warehouse eq true';
	const defaultFilter = `${warehouseFilter}&$orderby=sortname asc`;

	const [isDefaultSet, setIsDefaultSet] = useState(false);
	const [defaultWarehouse, setDefaultWarehouse] = useState<TWarehouse | null>(
		null
	);

	const {
		data: warehouseItems,
		updateFilter,
		loadMore,
		hasMore,
		isFetching,
	} = usePaginatedQuery<TWarehouse>(async (filterString: string) => {
		return await getWarehouses(filterString);
	}, defaultFilter);

	const optionFormatter = (warehouse: TWarehouse) => ({
		value: warehouse.code,
		label: `${warehouse.sortname} [${warehouse.code}]`,
		data: warehouse,
	});

	const handleInputFilter = (input: string) => {
		return `${warehouseFilter} and (contains(sortname,'${input}') or contains(code,'${input}'))`;
	};

	const handleSelectedOption = (
		option: { value: string; data: TWarehouse } | null
	) => {
		return option?.data || null;
	};

	useEffect(() => {
		const fetchDefaultWarehouse = async () => {
			try {
				if (defaultSelected && !isDefaultSet) {
					const filter = `${warehouseFilter} and code eq '${defaultSelected}'&$orderby=sortname asc`;
					const [fetchedDefaultWarehouse] = await getWarehouses(filter);

					if (fetchedDefaultWarehouse) {
						const formattedDefaultOption = optionFormatter(
							fetchedDefaultWarehouse
						);
						setDefaultWarehouse(fetchedDefaultWarehouse);
						handleSelect(formattedDefaultOption.data);
						setIsDefaultSet(true);
					}
				}
			} catch (error: unknown) {
				const responseError = error as {
					response?: { data?: { userError?: string } };
				};
				displayAlert(
					EAlertTypes.Danger,
					responseError.response?.data?.userError || 'Unknown error',
					7000
				);
			}
		};

		if (defaultSelected && !isDefaultSet) {
			fetchDefaultWarehouse();
		}
	}, [defaultSelected, handleSelect, isDefaultSet]);

	return (
		<BaseSelect<TWarehouse>
			handleSelect={handleSelect}
			placeholder="Select a Warehouse"
			results={warehouseItems || []}
			updateFilter={updateFilter}
			loadMore={loadMore}
			handleInputFilter={handleInputFilter}
			handleSelectedOption={handleSelectedOption}
			optionFormatter={optionFormatter}
			hasMore={hasMore}
			isFetching={isFetching}
			defaultSelected={defaultWarehouse}
		/>
	);
};

WarehouseSelect.displayName = 'WarehouseSelect';
