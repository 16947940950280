import * as yup from 'yup';

export const VendorValidation = yup.object().shape({
	email: yup
		.string()
		.nullable()
		.test({
			test: (value) => {
				if (!value || value.length === 0) {
					return true;
				}
				const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
				return emailRegex.test(value);
			},
			message: 'Please enter a valid email',
		}),
});
