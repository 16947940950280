import React from 'react';
import ThreadComponent from '../../ThreadComponent';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { ApiService } from '../../../../lib/api/HttpService';
import Spinner from '../../../../app/components/help/Spinner';

class EditRefundModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			project: {},
			company: {},
			item: {},
			grid: {},
			compItems: {},
			isToOverride: false,
			updatedData: {},
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		if (Object.keys(this.props.grid).length > 0) {
			const { item, grid, project } = this.props;
			const company = await this.api.getCompany();
			let gridItems = {
				invoverride: item.invoverride,
				itemNumber: item.item,
				projectCode: project.proj,
				isOverride: this.props.grid.override ?? false,
				invmercstyledisabled:
					project.invmercstyle == undefined ||
					project.invmercstyle == 'dmpsIgnore' ||
					project.invmercstyle == 3
						? true
						: false,
				invfreightstyledisabled:
					project.invfreightstyle == undefined ||
					project.invfreightstyle == 'dmpsIgnore' ||
					project.invfreightstyle == 3
						? true
						: false,
				invlaborstyledisabled:
					!project.invlaborstyle ||
					project.invlaborstyle == 'dmpsIgnore' ||
					project.invlaborstyle == 3
						? true
						: false,
				invinstallstyledisabled:
					project.invinstallstyle == undefined ||
					project.invinstallstyle == 'dmpsIgnore' ||
					project.invinstallstyle == 3
						? true
						: false,
				invdesignstyledisabled:
					project.invdesignstyle == undefined ||
					project.invdesignstyle == 'dmpsIgnore' ||
					project.invdesignstyle == 3
						? true
						: false,
				invotherstyledisabled:
					project.invotherstyle == undefined ||
					project.invotherstyle == 'dmpsIgnore' ||
					project.invotherstyle == 3
						? true
						: false,
				invdep: grid.invdep,
				invtotal: grid.invtotal,
				tdinvqty: grid.tdinvqty,
				estmcost: item.estmcost,
				estdcost: item.estdcost,
				estfcost: item.estfcost,
				esticost: item.esticost,
				estlcost: item.estlcost,
				estocost: item.estocost,
				esttcost: item.esttcost,

				estmprice: item.estmprice,
				estdprice: item.estdprice,
				estfprice: item.estfprice,
				estiprice: item.estiprice,
				estlprice: item.estlprice,
				estoprice: item.estoprice,
				esttprice: item.esttprice,

				actmcost: item.actmcost,
				actdcost: item.actdcost,
				actfcost: item.actfcost,
				acticost: item.acticost,
				actlcost: item.actlcost,
				actocost: item.actocost,
				acttcost: item.acttcost,

				actmprice: item.actmprice,
				actdprice: item.actdprice,
				actfprice: item.actfprice,
				actiprice: item.actiprice,
				actlprice: item.actlprice,
				actoprice: item.actoprice,
				acttprice: item.acttprice,
			};

			if (item.invoverride) {
				gridItems = {
					...gridItems,
					gridtoinv: item.invorquan,
					pricetoinvm: item.invorpricem,
					pricetoinvd: item.invorpriced,
					pricetoinvf: item.invorpricef,
					pricetoinvi: item.invorpricei,
					pricetoinvl: item.invorpricel,
					pricetoinvo: item.invorpriceo,
					deposittorefund: item.invordep,
					taxableamountm: item.invortaxablem,
					taxableamountd: item.invortaxabled,
					taxableamountf: item.invortaxablef,
					taxableamounti: item.invortaxablei,
					taxableamountl: item.invortaxablel,
					taxableamounto: item.invortaxableo,
					itemQty: item.quantity,
				};
			} else {
				gridItems = {
					...gridItems,
					gridtoinv: grid.orginvqty,
					pricetoinvm: grid.orginvmprice,
					pricetoinvd: grid.orginvdprice,
					pricetoinvf: grid.orginvfprice,
					pricetoinvi: grid.orginviprice,
					pricetoinvl: grid.orginvlprice,
					pricetoinvo: grid.orginvoprice,
					deposittorefund: grid.orginvdep,
					taxableamountm: grid.orginvmtaxable,
					taxableamountd: grid.orginvdtaxable,
					taxableamountf: grid.orginvftaxable,
					taxableamounti: grid.orginvitaxable,
					taxableamountl: grid.orginvltaxable,
					taxableamounto: grid.orginvotaxable,
					itemQty: grid.quantity,
				};
			}

			gridItems.totalItemPrice =
				parseFloat(gridItems.pricetoinvm) +
				parseFloat(gridItems.pricetoinvd) +
				parseFloat(gridItems.pricetoinvf) +
				parseFloat(gridItems.pricetoinvi) +
				parseFloat(gridItems.pricetoinvl) +
				parseFloat(gridItems.pricetoinvo);

			gridItems.totalItemPriceTaxAmt =
				parseFloat(gridItems.taxableamountm) +
				parseFloat(gridItems.taxableamountd) +
				parseFloat(gridItems.taxableamountf) +
				parseFloat(gridItems.taxableamounti) +
				parseFloat(gridItems.taxableamountl) +
				parseFloat(gridItems.taxableamounto);

			this.setState({
				item,
				grid,
				project,
				company,
				compItems: gridItems,
				updatedData: gridItems,
				isToOverride: this.props.grid.override,
			});
		}
	}

	handleSave = async () => {
		await this.props.handleSave(this.state.updatedData);
	};

	handleOverride = (e) => {
		const { grid } = this.props;
		const override = e.target.checked;
		let gridItems = {
			...this.state.updatedData,
			isOverride: override,
		};

		if (!override) {
			gridItems = {
				...gridItems,
				gridtoinv: grid.orginvqty,
				pricetoinvm: grid.orginvmprice,
				pricetoinvd: grid.orginvdprice,
				pricetoinvf: grid.orginvfprice,
				pricetoinvi: grid.orginviprice,
				pricetoinvl: grid.orginvlprice,
				pricetoinvo: grid.orginvoprice,
				deposittorefund: grid.orginvdep,
				taxableamountm: grid.orginvmtaxable,
				taxableamountd: grid.orginvdtaxable,
				taxableamountf: grid.orginvftaxable,
				taxableamounti: grid.orginvitaxable,
				taxableamountl: grid.orginvltaxable,
				taxableamounto: grid.orginvotaxable,
				itemQty: grid.quantity,
			};

			gridItems.totalItemPrice =
				parseFloat(gridItems.pricetoinvm) +
				parseFloat(gridItems.pricetoinvd) +
				parseFloat(gridItems.pricetoinvf) +
				parseFloat(gridItems.pricetoinvi) +
				parseFloat(gridItems.pricetoinvl) +
				parseFloat(gridItems.pricetoinvo);

			gridItems.totalItemPriceTaxAmt =
				parseFloat(gridItems.taxableamountm) +
				parseFloat(gridItems.taxableamountd) +
				parseFloat(gridItems.taxableamountf) +
				parseFloat(gridItems.taxableamounti) +
				parseFloat(gridItems.taxableamountl) +
				parseFloat(gridItems.taxableamounto);
		}

		this.setState({
			updatedData: gridItems,
			compItems: gridItems,
			isToOverride: override,
		});
	};

	handleDataChange = (field, value) => {
		let changedVal = value.length ? value : 0;
		let changes = {
			[field]: changedVal,
		};

		let pricetoinvm = this.state.compItems.pricetoinvm;
		let pricetoinvd = this.state.compItems.pricetoinvd;
		let pricetoinvf = this.state.compItems.pricetoinvf;
		let pricetoinvi = this.state.compItems.pricetoinvi;
		let pricetoinvl = this.state.compItems.pricetoinvl;
		let pricetoinvo = this.state.compItems.pricetoinvo;
		let taxableamountm = this.state.compItems.taxableamountm;
		let taxableamountd = this.state.compItems.taxableamountd;
		let taxableamountf = this.state.compItems.taxableamountf;
		let taxableamounti = this.state.compItems.taxableamounti;
		let taxableamountl = this.state.compItems.taxableamountl;
		let taxableamounto = this.state.compItems.taxableamounto;

		switch (field) {
			case 'pricetoinvm':
				taxableamountm = changedVal;
				pricetoinvm = changedVal;
				changes['taxableamountm'] = changedVal;
				changes['pricetoinvm'] = changedVal;
				break;
			case 'taxableamountm':
				taxableamountm = changedVal;
				changes['taxableamountm'] = changedVal;
				break;
			case 'pricetoinvd':
				taxableamountd = changedVal;
				pricetoinvd = changedVal;
				changes['taxableamountd'] = changedVal;
				changes['pricetoinvd'] = changedVal;
				break;
			case 'taxableamountd':
				taxableamountd = changedVal;
				changes['taxableamountd'] = changedVal;
				break;
			case 'pricetoinvf':
				taxableamountf = changedVal;
				pricetoinvf = changedVal;
				changes['taxableamountf'] = changedVal;
				changes['pricetoinvf'] = changedVal;
				break;
			case 'taxableamountf':
				taxableamountf = changedVal;
				changes['taxableamountf'] = changedVal;
				break;
			case 'pricetoinvi':
				taxableamounti = changedVal;
				pricetoinvi = changedVal;
				changes['taxableamounti'] = changedVal;
				changes['pricetoinvi'] = changedVal;
				break;
			case 'taxableamounti':
				taxableamounti = changedVal;
				changes['taxableamounti'] = changedVal;
				break;
			case 'pricetoinvl':
				taxableamountl = changedVal;
				pricetoinvl = changedVal;
				changes['taxableamountl'] = changedVal;
				changes['pricetoinvl'] = changedVal;
				break;
			case 'taxableamountl':
				taxableamountl = changedVal;
				changes['taxableamountl'] = changedVal;
				break;
			case 'pricetoinvo':
				taxableamounto = changedVal;
				pricetoinvo = changedVal;
				changes['taxableamounto'] = changedVal;
				changes['pricetoinvo'] = changedVal;
				break;
			case 'taxableamounto':
				taxableamounto = changedVal;
				changes['taxableamounto'] = changedVal;
				break;
		}

		const totalItemPrice =
			parseFloat(pricetoinvm) +
			parseFloat(pricetoinvd) +
			parseFloat(pricetoinvf) +
			parseFloat(pricetoinvi) +
			parseFloat(pricetoinvl) +
			parseFloat(pricetoinvo);

		const totalItemPriceTaxAmt =
			parseFloat(taxableamountm) +
			parseFloat(taxableamountd) +
			parseFloat(taxableamountf) +
			parseFloat(taxableamounti) +
			parseFloat(taxableamountl) +
			parseFloat(taxableamounto);

		changes['totalItemPrice'] = totalItemPrice;
		changes['totalItemPriceTaxAmt'] = totalItemPriceTaxAmt;

		this.setState({
			updatedData: {
				...this.state.updatedData,
				...changes,
			},
			compItems: {
				...this.state.compItems,
				...changes,
			},
		});
	};

	render() {
		return (
			<Modal
				size="xl"
				show={true}
				onHide={() => this.hide()}
				aria-labelledby="example-modal-sizes-title-lg"
				backdrop="static"
				centered
			>
				<Modal.Header>
					<Modal.Title>
						Override Refund Amount [Item #{this.props.item.item}]
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!this.props.isLoaded ? <Spinner /> : null}
					<Row className="pe-2">
						<Col className="border-end">
							<Form.Check
								inline
								checked={this.state.isToOverride}
								label="Override: **Enter POSITIVE Values**"
								type="checkbox"
								onClick={this.handleOverride}
								className="me-3 text-danger mb-5 ms-5"
							/>

							<Row className="align-items-center mb-3">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Qty. to Invoice</Form.Label>
								</Col>
								<Col lg={4}>
									<Form.Control
										type="text"
										onChange={(e) =>
											this.handleDataChange('gridtoinv', e.target.value)
										}
										defaultValue={this.state.compItems.gridtoinv}
										value={
											this.state.updatedData.gridtoinv ??
											this.state.compItems.gridtoinv
										}
										disabled={!this.state.isToOverride}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-2">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0"></Col>
								<Col lg={4} className="text-center">
									Price To Invoice
								</Col>
								<Col lg={4} className="text-center">
									Taxable Amount
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										{this.state.company?.compviewm}
									</Form.Label>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.pricetoinvm}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invmercstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('pricetoinvm', e.target.value)
										}
										value={
											this.state.updatedData.pricetoinvm ??
											this.state.compItems.pricetoinvm
										}
									/>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.taxableamountm}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invmercstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('taxableamountm', e.target.value)
										}
										value={
											this.state.updatedData.taxableamountm ??
											this.state.compItems.taxableamountm
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										{this.state.company?.compviewd}
									</Form.Label>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.pricetoinvd}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invdesignstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('pricetoinvd', e.target.value)
										}
										value={
											this.state.updatedData.pricetoinvd ??
											this.state.compItems.pricetoinvd
										}
									/>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.taxableamountd}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invdesignstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('taxableamountd', e.target.value)
										}
										value={
											this.state.updatedData.taxableamountd ??
											this.state.compItems.taxableamountd
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										{this.state.company?.compviewf}
									</Form.Label>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.pricetoinvf}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invfreightstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('pricetoinvf', e.target.value)
										}
										value={
											this.state.updatedData.pricetoinvf ??
											this.state.compItems.pricetoinvf
										}
									/>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.taxableamountf}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invfreightstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('taxableamountf', e.target.value)
										}
										value={
											this.state.updatedData.taxableamountf ??
											this.state.compItems.taxableamountf
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										{this.state.company?.compviewi}
									</Form.Label>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.pricetoinvi}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invinstallstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('pricetoinvi', e.target.value)
										}
										value={
											this.state.updatedData.pricetoinvi ??
											this.state.compItems.pricetoinvi
										}
									/>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.taxableamounti}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invinstallstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('taxableamounti', e.target.value)
										}
										value={
											this.state.updatedData.taxableamounti ??
											this.state.compItems.taxableamounti
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										{this.state.company?.compviewl}
									</Form.Label>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.pricetoinvl}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invlaborstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('pricetoinvl', e.target.value)
										}
										value={
											this.state.updatedData.pricetoinvl ??
											this.state.compItems.pricetoinvl
										}
									/>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.taxableamountl}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invlaborstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('taxableamountl', e.target.value)
										}
										value={
											this.state.updatedData.taxableamountl ??
											this.state.compItems.taxableamountl
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										{this.state.company?.compviewo}
									</Form.Label>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.pricetoinvo}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invotherstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('pricetoinvo', e.target.value)
										}
										value={
											this.state.updatedData.pricetoinvo ??
											this.state.compItems.pricetoinvo
										}
									/>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.taxableamounto}
										disabled={
											!this.state.isToOverride
												? true
												: this.state.compItems.invotherstyledisabled
										}
										onChange={(e) =>
											this.handleDataChange('taxableamounto', e.target.value)
										}
										value={
											this.state.updatedData.taxableamounto ??
											this.state.compItems.taxableamounto
										}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Total Item Price</Form.Label>
								</Col>
								<Col lg={4} className="text-center pt-1">
									<Form.Control
										type="text"
										defaultValue={
											this.state.compItems.totalItemPrice
												? this.state.compItems.totalItemPrice.toFixed(2)
												: this.state.compItems.totalItemPrice
										}
										disabled={true}
									/>
								</Col>
								<Col lg={4} className="text-center">
									<Form.Control
										type="text"
										defaultValue={
											this.state.compItems.totalItemPriceTaxAmt
												? this.state.compItems.totalItemPriceTaxAmt.toFixed(2)
												: this.state.compItems.totalItemPriceTaxAmt
										}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mt-4">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Deposit to Refund</Form.Label>
								</Col>
								<Col lg={4} className="text-center pt-1">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.deposittorefund}
										disabled={
											this.state.compItems.invdep != 0 &&
											this.state.compItems.invtotal == 0
										}
										onChange={(e) =>
											this.handleDataChange('deposittorefund', e.target.value)
										}
										value={
											this.state.updatedData.deposittorefund ??
											this.state.compItems.deposittorefund
										}
									/>
								</Col>
							</Row>
						</Col>
						<Col className="pe-5">
							<Row className="align-items-center pt-5 mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Item Quantity</Form.Label>
								</Col>
								<Col lg={3}>
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.itemQty}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Qty. Inv. to Date</Form.Label>
								</Col>
								<Col lg={3}>
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.tdinvqty}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center pt-3 mb-2">
								<Col lg={3} className="text-center">
									Est. Cost
								</Col>
								<Col lg={3} className="text-center">
									Est. Price
								</Col>
								<Col lg={3} className="text-center">
									Act. Cost
								</Col>
								<Col lg={3} className="text-center">
									Inv. to Date
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estmcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estmprice}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actmcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actmprice}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estdcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estdprice}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actdcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actdprice}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estfcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estfprice}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actfcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actfprice}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.esticost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estiprice}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.acticost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actiprice}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estlcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estlprice}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actlcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actlprice}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estocost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.estoprice}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actocost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.actoprice}
										disabled={true}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-1">
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.esttcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.esttprice}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.acttcost}
										disabled={true}
									/>
								</Col>
								<Col lg={3} className="text-center">
									<Form.Control
										type="text"
										defaultValue={this.state.compItems.acttprice}
										disabled={true}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mt-4">
								<Col lg={3} className="text-center"></Col>
								<Col lg={3} className="text-center pt-1"></Col>
								<Col lg={3} className="text-center"></Col>
								<Col lg={3} className="text-center"></Col>
							</Row>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.props.hideModal}>
						Close
					</Button>
					<Button variant="primary" onClick={this.handleSave}>
						Ok
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default EditRefundModal;
