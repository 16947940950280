import React from 'react';
import {
	Container,
	Col,
	Row,
	Form,
	Breadcrumb,
	Button,
	Table,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter, routeParam } from '../../../../helpers/Router';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { Link, NavLink } from 'react-router-dom';
import { ApiService } from '../../../../lib/api/HttpService';
import { currencyFormat } from '../../../../helpers/Number';
import EditCashReceiptModal from './EditCashReceiptModal';
import { formatDate, formatFiscalMonth } from '../../../../helpers/Date';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
	handleResponseAlert,
} from '../../../../utilities/Response';
import MSG from '../../../../defaults/Message';
import { delay } from 'lodash';
import CreateDepositSlipListExistingModal from '../../modal/CreateDepositSlipListExistingModal';
import { getCookie } from '../../../../utilities/Auth';
import dayjs from 'dayjs';
import ConfirmModal from 'legacy/app/components/modal/ConfirmModal';
import { apiClient } from 'api/apiClient';

class CashReceiptExistingView extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			sendToProcessor: false,
			distributions: [],
			showProcessorCheckbox: false,
			receipt: {},
			dataIsLoaded: false,
			showEditCashReceiptModal: false,
			showDepositSlipModal: false,
			selectedCRIdx: null,
			showVoidModal: false,
			transactionTypes: {
				2006: 'Pmt.',
				2004: 'Dep.',
				2002: 'Ret.',
				2000: 'Misc.',
			},
			selectedTransItem: null,
			selectedCashReceipt: null,
			selectedDistributions: [],
		};

		this.api = new ApiService();
	}

	componentInit() {
		this.title = 'Client Invoice';
		this.setFormAction(true);
	}

	async componentDidMount() {
		await this.fetchData();
	}

	async fetchData() {
		const filter = `?$filter=crgroupnum eq ${this.props.params.refno}`;
		const receipts = await this.api.getCashReceipts(filter);

		if (!receipts.length) {
			displayAlertError(MSG.error.CashReceiptNotFound);
			delay(() => {
				this.props.navigate(
					routeParam(URI.accountsReceivable.cashReceipt.listExisting)
				);
			}, 2500);
			return;
		}

		const transactions = await this.api.getCashReceiptDetail(filter);
		this.setState({
			receipt: receipts[0],
			distributions: transactions,
			selectedCashReceipt: receipts[0],
		});
	}

	handleSelectAllChecks = (e) => {
		if (!this.state.distributions.length) {
			return;
		}
		if (!e.target.checked) {
			return this.setState({
				selectedDistributions: [],
			});
		}

		this.setState({
			selectedDistributions: [...this.state.distributions],
		});
	};

	handleClickTransItem = async (item, idx) => {
		const response = await this.api.getCreditCardTransactions(
			'?$filter=txnum eq ' + item.txnum
		);

		if (response.length > 0) {
			displayAlertError(response?.message);
			return;
		}

		this.setState({
			selectedTransItem: item,
			showEditCashReceiptModal: !this.state.showEditCashReceiptModal,
			selectedCRIdx: idx,
		});
	};

	handleVoidClick = async () => {
		const receiptTransactions = this.state.selectedDistributions;

		for (const trans of receiptTransactions) {
			try {
				const isRefundable = await apiClient.get(
					`/CreditCardTransactions/${trans.txnum}/isrefundable`
				);
				if (isRefundable) {
					this.setState({
						showProcessorCheckbox: true,
					});
					break;
				}
			} catch {
				// Do nothing for now
			}
		}

		this.setState({
			showVoidModal: true,
		});
	};

	handleVoid = async () => {
		this.setState({ showProcessorCheckbox: false });
		let hasError = false;
		displayAlertLoader(MSG.loading.cashReceipts);
		const receiptTransactions = this.state.selectedDistributions;
		for (const trans of receiptTransactions) {
			const payload = {
				TransactionNumber: trans.txnum,
				PostingProcessType: 'voidTransaction',
			};

			payload.voidCcTransaction = this.state.sendToProcessor;

			await this.api.postTemporaryCashReceipt(payload).catch((error) => {
				hasError = true;
				displayAlert('danger', error.response.data.userError);
			});
		}

		if (!hasError) {
			displayAlert('success', `Successfully voided cash receipt entry/entries`);

			return delay(() => {
				this.props.navigate(
					routeParam(URI.accountsReceivable.cashReceipt.listExisting)
				);
			}, 500);
		}
		this.fetchData();
	};

	hideEditDistributionModal = () => {
		this.setState({
			showEditCashReceiptModal: false,
			selectedCRIdx: null,
		});
	};

	handleRunReports = async (data) => {
		const range = dayjs(this.state.receipt.fuserdate).format('YYYY-MM-DD');
		// Define the parameters for the report
		const params = {
			instanceId: getCookie('dmInstanceId'),
			userId: Number(getCookie('dmUsercode')),
			reportId: 1055,
			reportFormatId: 1137,
			reportObjectType: 'Report',
			runReportParameters: [
				{
					parameterId: 297,
					value: `{"cash_account": "${this.state.receipt.faccount}"}`,
				},
				{
					parameterId: 298,
					value: `{"text": "${data.slipNumber}"}`,
				},
				{
					parameterId: 299,
					value: `{"range1": "${range}", "range2":"${range}"}`,
				},
				{
					parameterId: 300,
					value: '{"text": ""}',
				},
			],
			objectId: null,
		};

		// Run the report
		const saveRequest = this.api.postJson('runreports', params);
		const windowReport = window.open('', '_blank');
		windowReport.document.write(this.loadingWindowHTML());

		handleResponseAlert(saveRequest, (res) => {
			displayAlert('success', 'Please wait for your report to be generated.');
			const reportId = res.id;
			const maxRequest = 5;
			let requestCount = 0;
			let interval = window.setInterval(async () => {
				try {
					const response = await this.api.get(
						'runreports',
						`?$filter=id eq ${reportId}`
					);
					const fileId = response[0].completedFileId;
					if (fileId) {
						clearInterval(interval);
						const label = 'Deposit Slips';
						const { link } = await this.api.getPublicMappings(fileId, label);
						windowReport.location.href = link;
					} else if (requestCount === maxRequest) {
						clearInterval(interval);
						displayAlertError('Report is on pending status. Try again later.');
					}
				} catch (error) {
					displayAlertError(getErrorMessage(error));
					windowReport.close();
				}
				requestCount++;
			}, 5000);
			this.props.hideModal();
		});
		window.localStorage.removeItem('checkbook_detail');
		window.localStorage.removeItem('account');
		window.localStorage.removeItem('accountn_data');
	};

	loadingWindowHTML() {
		return `<div style="position: absolute; display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; padding-left: 0px;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                <div>
                <img src="${window.location.origin}/logo-dark.svg?t=08022023" width="180" height="auto" className="d-inline-block align-top" alt="React Bootstrap logo">
                <br>
                <img src="${window.location.origin}/loader.svg?t=08022023" width="180" height="130" className="d-inline-block align-top" alt="React Bootstrap logo" style="margin-top: -40px;">
                </div>
            </div>
            </div>
        </div>`;
	}

	header() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={routeParam(URI.accountsReceivable.cashReceipt.list, {
							page: 'existing',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.accountsReceivable.cashReceipt.list, {
									page: 'existing',
								}),
							}}
							linkAs={Link}
						>
							Account Receivable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.accountsReceivable.cashReceipt.list, {
									page: 'existing',
								}),
							}}
							linkAs={Link}
						>
							Cash Receipts
						</Breadcrumb.Item>
						<Breadcrumb.Item>
							Receipt No. {this.props.params.refno}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={false}>
					<HeaderLight.Title>
						Receipt No. {this.props.params.refno}
					</HeaderLight.Title>

					<div className={'d-flex'}>
						{/* Submit Button */}
						<Button
							variant="primary btn-icon px-4 fw-bold fs-md"
							href=""
							onClick={(event) => {
								event.preventDefault();
								this.setState((prev) => (prev.showDepositSlipModal = true));
							}}
						>
							<i className="ri-file-add-line"></i> Create Dep. Slip
						</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		const { receipt } = this.state;
		return (
			<>
				<ConfirmModal
					okBtnStyle="success"
					title="Void entry / entries"
					message="Are you sure you want to void this/these entry/entries?"
					labelOK="Void"
					labelCancel="Cancel"
					show={this.state.showVoidModal}
					toggleModal={() => {
						this.setState({ showVoidModal: false });
						setTimeout(
							() =>
								this.setState({
									showProcessorCheckbox: false,
								}),
							500
						);
					}}
					confirmAction={() => {
						this.setState({ showVoidModal: false });
						this.handleVoid();
					}}
				>
					{this.state.showProcessorCheckbox && (
						<div className="tw-flex tw-justify-center">
							<Form.Check
								checked={this.state.sendToProcessor}
								onChange={(e) =>
									this.setState({
										sendToProcessor: e.target.checked,
									})
								}
								label="Send to payment processor"
							/>
						</div>
					)}
				</ConfirmModal>
				<Container className="px-0 ms-0">
					<Form.Group>
						<Row
							xs={1}
							lg={2}
							className="mt-4 align-items-center static-field-rows"
						>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Client Name</Form.Label>
									</Col>
									<Col>
										{Object.keys(receipt).length > 0 && (
											<strong>
												{receipt.fclientn} / {receipt.fclient}
											</strong>
										)}
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Cash Account</Form.Label>
									</Col>
									<Col>
										{Object.keys(receipt).length > 0 && (
											<strong>
												{receipt.faccountn} / {receipt.faccount}
											</strong>
										)}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row xs={1} lg={2} className="align-items-center static-field-rows">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Amount</Form.Label>
									</Col>
									<Col>
										{Object.keys(receipt).length > 0 && (
											<strong>
												{currencyFormat(
													receipt.totalAmount
														? receipt.totalAmount.toFixed(2)
														: ''
												)}
											</strong>
										)}
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Payment Type</Form.Label>
									</Col>
									<Col>
										<strong>{receipt.fpaytype}</strong>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2} className="align-items-center static-field-rows">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Date</Form.Label>
									</Col>
									<Col>
										<strong>{formatDate(receipt.fuserdate)}</strong>
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											Check No.
											<br />
											(Payment Status)
										</Form.Label>
									</Col>
									<Col>
										<strong>{receipt.fchecknum}</strong>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2} className="align-items-center static-field-rows">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Fiscal Month</Form.Label>
									</Col>
									<Col>
										<strong>{formatFiscalMonth(receipt.ffiscalmonth)}</strong>
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Deposit Slip</Form.Label>
									</Col>
									<Col>
										<strong>{receipt.fdepositslip}</strong>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2} className="align-items-center static-field-rows">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Keyed Date</Form.Label>
									</Col>
									<Col>
										<strong>{formatDate(receipt.fcomputerdate)}</strong>
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											Maximum Project Code
										</Form.Label>
									</Col>
									<Col>
										<strong>{receipt.fproj}</strong>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2} className="align-items-center static-field-rows">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-0 mb-lg-4">
									<Col xs={4} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Keyed By User</Form.Label>
									</Col>
									<Col>
										<strong>{receipt.fusername}</strong>
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0"></Col>
						</Row>

						<Row className="mt-4">
							<Col>
								<div className="bg-secondary-grey px-4">
									<Row
										xs={1}
										lg={2}
										className="py-3 justify-content-center align-items-center"
									>
										<Col>
											<h6 className="fw-bold mb-0">Payment Distribution</h6>
										</Col>
										<Col className="d-flex justify-content-end">
											<Button
												to="#"
												className="btn-icon btn-action ms-3"
												variant="ivory"
												onClick={this.handleVoidClick}
												disabled={this.state.selectedDistributions.length === 0}
											>
												<i className="ri-delete-bin-line"></i> Void
											</Button>
										</Col>
									</Row>
								</div>

								<div className="table-gradient">
									<Table striped responsive className="a-table">
										<thead>
											<tr className="a-table-heading">
												<th align="middle" className="mw-80px">
													<div className="d-flex justify-content-center">
														<Form.Check
															inline
															label=""
															name={`inline-check-th-1`}
															type="checkbox"
															data-id={`th-1`}
															id={`inline-check-th-1`}
															checked={
																this.state.selectedDistributions.length > 0
															}
															className={`${
																this.state.distributions.length > 0 &&
																this.state.selectedDistributions.length <
																	this.state.distributions.length
																	? 'line'
																	: ''
															}`}
															onChange={this.handleSelectAllChecks}
														/>
													</div>
												</th>
												<th>
													<span className="sort">Tx Ref No.</span>
												</th>
												<th>
													<span className="sort">Type</span>
												</th>
												<th>
													<span className="sort">Amount</span>
												</th>
												<th>
													<span className="sort">Project/Order Code</span>
												</th>
												<th>
													<span className="sort">Project/Order Name</span>
												</th>
												<th>
													<span className="sort">Prop. No.</span>
												</th>
												<th>
													<span className="sort">Invoice No.</span>
												</th>
												<th>
													<span className="sort">Transaction Description</span>
												</th>
												<th>
													<span className="sort">Offset Account No.</span>
												</th>
												<th>
													<span className="sort">Offset Account Name</span>
												</th>
											</tr>
										</thead>
										<tbody>
											{this.state.distributions.map((item, i) => (
												<tr key={i}>
													<td>
														<div className="d-flex justify-content-center">
															<Form.Check
																label=""
																name={`group-` + i}
																type="checkbox"
																data-id={i}
																checked={this.state.selectedDistributions.find(
																	(distribution) =>
																		distribution.txnum === item.txnum
																)}
																id={`chk-projectview-items-` + i}
																className="chk-projectview-items-item"
																defaultValue={i}
																onChange={(e) => {
																	this.setState({
																		selectedDistributions: e.target.checked
																			? [
																					...this.state.selectedDistributions,
																					item,
																			  ]
																			: this.state.selectedDistributions.filter(
																					(selectedDistribution) =>
																						selectedDistribution.txnum !==
																						item.txnum
																			  ),
																	});
																}}
															/>
														</div>
													</td>
													<td>
														<Link
															onClick={(e) =>
																this.handleClickTransItem(item, i)
															}
															to={'#'}
															className="text-charcoal hover-view-icon"
														>
															{item.txnum}
														</Link>
													</td>
													<td>{this.state.transactionTypes[item.type]}</td>
													<td> {currencyFormat(item.totalAmount, '$')}</td>
													<td>{item.proj}</td>
													<td>{item.projn}</td>
													<td>{item.propnum}</td>
													<td>{item.payingtxnum}</td>
													<td>{item.txdesc}</td>
													<td>{item.singleoffsetaccount}</td>
													<td>{item.accountn}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</Col>
						</Row>
					</Form.Group>
				</Container>
				<>
					{this.state.showEditCashReceiptModal && (
						<EditCashReceiptModal
							receipt={this.state.selectedCashReceipt}
							distribution={this.state.selectedTransItem}
							show={this.state.showEditCashReceiptModal}
							hideModal={this.hideEditDistributionModal}
							updateCashReceipt={() => this.fetchData()}
						/>
					)}
					{this.state.showDepositSlipModal && (
						<CreateDepositSlipListExistingModal
							cashAccount={`${this.state.receipt.faccount} - ${this.state.receipt.faccountn}`}
							depositDate={this.state.receipt.fuserdate}
							receipt={this.state.selectedCashReceipt}
							slipNumber={this.state.receipt.fdepositslip}
							show={this.state.showDepositSlipModal}
							hideModal={() =>
								this.setState((prev) => (prev.showDepositSlipModal = false))
							}
							onPostDepositSlip={this.handleRunReports}
						/>
					)}
				</>
			</>
		);
	}

	render() {
		return (
			<>
				{this.header()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid>{this.renderContent()}</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(CashReceiptExistingView);
