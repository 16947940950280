import Table from 'legacy/app/components/newTable/Table';
import { ColumnDef } from '@tanstack/react-table';
import { currencyFormat } from 'legacy/helpers/Number';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { Item } from 'legacy/lib/api/types/Item';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';
import { formatDate } from 'legacy/helpers/Date';
import { useGetInvoices } from 'api/hooks/useGetInvoices';
import { IInvoices } from 'api/resources/invoices';

const InvoicesTable = ({ itemToEdit }: { itemToEdit: Item }) => {
	const { id: projectId } = useParams();
	const { data: project } = useGetProject(projectId as string);
	const filter = `?$filter=projectCode eq '${encodeURIComponent(
		project?.proj || ''
	)}' AND itemNumber eq '${itemToEdit.item}'&$orderby=invoiceNumber asc`;

	const {
		data: invoices,
		isLoading,
		error,
	} = useGetInvoices(filter, { enabled: !!project });

	const columns = useMemo<ColumnDef<IInvoices>[]>(
		() => [
			{
				accessorKey: 'invoiceNumber',
				header: 'Inv. No.',
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDate((col.invoiceDate || '').split('T')[0]),
				header: 'Date',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'quantity',
				header: 'Qty.',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},

			{
				accessorFn: (col) => currencyFormat(col.amount || '', '$'),
				header: 'Amount',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.depositApplied, '$'),
				header: 'Deposit App',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.salesTax, '$'),
				header: 'Sales Tax.',
				enableSorting: false,
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.total, '$'),
				header: 'Total',
				enableSorting: false,
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.payment, '$'),
				header: 'Payment',
				enableSorting: false,
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'clientCheckNumber',
				header: 'Client CK No.',
				enableSorting: false,
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.balanceDue, '$'),
				header: 'Bal. Due',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.compType0Amount, '$'),
				header: 'Merchandise',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.compType1Amount, '$'),
				header: 'Freight',
				size: 50,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
		],
		[]
	);

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error getting the proposals information, please try again'
			);
		}
	}, [error]);

	if (isLoading) {
		return <Spinner isChild />;
	}
	return !error ? (
		<div className="tw-pl-8 tw-pr-8">
			<div className="tw-max-w-full tw-p-4 tw-flex tw-flex-col tw-gap-8">
				<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
					<p className="tw-text-base tw-font-bold tw-m-0">Client Invoice</p>
				</div>
				<div className="tw-pl-6 tw-pr-6">
					<div className=" tw-overflow-x-auto">
						<Table columns={columns} data={invoices as IInvoices[]} />
					</div>
				</div>
			</div>
		</div>
	) : null;
};

InvoicesTable.displayName = 'InvoicesTable';

export default InvoicesTable;
