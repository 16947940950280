import React, { useState } from 'react';
import CashflowBar from '../CashflowBar/CashflowBar';
import RecentProjectsSelect from '../Selects/RecentProjectsSelect';
import Icon from '../Icon/Icon';
import ResourcesIcon from '../../../assets/images/icons/ResourcesIcon.svg';
import { BarType } from 'legacy/app/enums/dashboard/barType';
import { TRecentProject } from 'legacy/lib/api/types/RecentProject';
import { formatChartCurrency } from 'legacy/utilities/formatChartCurrency';
import { CashflowProjectsSelect } from 'app/widgets/Selects/CashflowProjectsSelect';
import { useFeature } from 'use-feature';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';

const CashflowInformation = () => {
	const [selectedProject, setSelectedProject] = useState<TRecentProject | null>(
		null
	);
	const total = selectedProject?.totalEstimatedPriceToClient || 0;
	const moneyIn = selectedProject?.totalPaymentsInFromClient || 0;
	const moneyOut = selectedProject?.totalPaidOutToVendors || 0;
	const estimatedVendorCost =
		selectedProject?.totalEstimatedCostFromVendors || 0;
	const moneyInRemainingCash = total === 0 ? 0 : Math.max(0, total - moneyIn);
	const moneyOutRemainingCash =
		estimatedVendorCost === 0 ? 0 : Math.max(0, estimatedVendorCost - moneyOut);
	const netCash = selectedProject ? moneyIn - moneyOut : 0;
	const handleSelect = (project: TRecentProject | null) => {
		setSelectedProject(project);
	};

	const showPaginatedSelect = useFeature(FeatureFlags.PaginatedSelect);

	return (
		<>
			<div className="tw-p-4 tw-bg-white tw-rounded-t-lg tw-shadow-md">
				<div className="tw-flex tw-justify-between tw-mb-4">
					<div className="tw-flex tw-items-center">
						<Icon path={ResourcesIcon} iconClass="tw-mr-3" />
						<h1 className="tw-text-2xl tw-leading-none tw-m-0">Cash Flow</h1>
					</div>
					<div className="tw-w-2/4">
						{showPaginatedSelect ? (
							<CashflowProjectsSelect handleSelect={handleSelect} />
						) : (
							<RecentProjectsSelect handleSelect={handleSelect} />
						)}
					</div>
				</div>
				<div className="tw-text-2xl tw-font-bold tw-text-gray-900">
					{formatChartCurrency(netCash)}
				</div>
				<div className="tw-text-sm tw-text-gray-500">
					{selectedProject?.projectName
						? `Net Cash for ${selectedProject.projectName}`
						: 'Net Cash'}
				</div>
			</div>
			<div className="tw-p-4 tw-bg-white tw-rounded-b-lg tw-shadow-md">
				<CashflowBar
					barColor="tw-bg-[#EAF8F4]"
					progressBarColor="tw-bg-[#3b8158]"
					cashAmount={moneyIn}
					remainingCash={moneyInRemainingCash}
					total={total}
					cashAmountLabel="Money In"
					barType={selectedProject === null ? BarType.Empty : BarType.MoneyIn}
					placeholderWidth={'70%'}
					tooltipMoney="Total amount received to date for the project (cash receipts)"
					tooltipRemainingMoney="Total due based on the estimated item prices and amount already received for the project"
				/>
				<CashflowBar
					barColor="tw-bg-[#e7f0f9]"
					progressBarColor="tw-bg-[#224a9a]"
					cashAmount={moneyOut}
					remainingCash={moneyOutRemainingCash}
					total={total}
					cashAmountLabel="Money Out"
					barType={selectedProject === null ? BarType.Empty : BarType.MoneyOut}
					placeholderWidth={'40%'}
					tooltipMoney="Total amount paid out to date to vendors for the project (disbursements)"
					tooltipRemainingMoney="Total due to vendors based on estimated item costs and amount already paid out"
				/>
			</div>
		</>
	);
};

CashflowInformation.displayName = 'CashflowInformation';

export default CashflowInformation;
