import { Company } from 'legacy/lib/api/types/Company';

const getCompanyTypeMap = (company: Company): { [key: number]: string } => ({
	0: company.compviewm ?? '0',
	1: company.compviewd ?? '1',
	2: company.compviewf ?? '2',
	3: company.compviewi ?? '3',
	4: company.compviewl ?? '4',
	5: company.compviewo ?? '5',
});

export default getCompanyTypeMap;
