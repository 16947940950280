import { CompStringPercentType } from 'legacy/lib/api/types/CompPercentType';
import { CompTypes } from 'legacy/lib/api/types/CompTypes';
import { Component } from 'legacy/lib/api/types/Component';

const normaliseComponentPayload = (comp: Component) => {
	const compCopy = { ...comp };
	if (compCopy.comptype !== CompTypes.ctTime) {
		delete compCopy.billprice;
		delete compCopy.billsalestax;
		delete compCopy.billtaxable;
		delete compCopy.nonbilltime;
		delete compCopy.nonbillunitprice;
	}

	if (!comp.useterms) {
		delete compCopy.bterm1;
		delete compCopy.bterm2;
		delete compCopy.bterm3;
	}
	if (comp.usedisc === CompStringPercentType.dmMarkup) {
		delete compCopy.feeunitcost;
		delete compCopy.feecost;
		delete compCopy.estdisc;
		if (comp.useterms) {
			delete compCopy.estunitcost;
			delete compCopy.estcost;

			return compCopy;
		}
		if (!comp.useterms) {
			delete compCopy.list;
			delete compCopy.unitlist;
		}

		return compCopy;
	}
	if (comp.usedisc === CompStringPercentType.dmDiscount) {
		delete compCopy.feeunitcost;
		delete compCopy.feecost;
		delete compCopy.estmarkup;
		if (comp.useterms) {
			delete compCopy.bterm1;
			delete compCopy.bterm2;
			delete compCopy.bterm3;
			delete compCopy.estunitcost;
			delete compCopy.estcost;
		}
		return compCopy;
	}

	delete compCopy.unitlist;
	delete compCopy.list;
	delete compCopy.estdisc;
	delete compCopy.bterm1;
	delete compCopy.bterm2;
	delete compCopy.bterm3;
	delete compCopy.estunitcost;
	delete compCopy.estcost;
	delete compCopy.estmarkup;
	return compCopy;
};
export default normaliseComponentPayload;
