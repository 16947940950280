export function compareStr(
	str1: string,
	str2: string,
	_true: any,
	_false: any
): any {
	return str1 === str2 ? _true || true : _false || false;
}

export function containStr(str1: string, strCompare: string): boolean {
	return (str1 || '').indexOf(strCompare) > -1;
}

export function makeid(len: number): string {
	let result = '';
	const characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < len; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
}

export function stringReplaceArray(
	val: string,
	find: string,
	replace: string
): string {
	let replaceString = val;
	let regex;

	for (let i = 0; i < find.length; i++) {
		regex = new RegExp(find[i], 'g');
		replaceString = replaceString.replace(regex, replace[i]);
	}

	return replaceString;
}

export function titleCase(str: string): string {
	const splitStr = str.toLowerCase().split(' ');
	for (let i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(' ');
}

export function toSortName(name: string) {
	const prefixes = [
		'mr',
		'mrs',
		'miss',
		'dr',
		'prof',
		'pvt',
		'pfc',
		'lcpl',
		'cpl',
		'spc',
		'sgt',
		'ssgt',
		'gysgt',
		'msgt',
		'mgysgt',
		'lt',
		'capt',
		'col',
		'ltcol',
		'gen',
		'adm',
		'rdm',
		'mr.',
		'mrs.',
		'miss',
		'dr.',
		'prof.',
		'pvt.',
		'pfc.',
		'lcpl.',
		'cpl.',
		'spc.',
		'sgt.',
		'ssgt.',
		'gysgt.',
		'msgt.',
		'mgysgt.',
		'lt.',
		'capt.',
		'col.',
		'ltcol.',
		'gen.',
		'adm.',
		'rdm.',
		'mrz',
		'mrz.',
	];
	const suffixes = [
		'md',
		'i',
		'ii',
		'iid',
		'iii',
		'iv',
		'jr',
		'sr',
		'v',
		'vi',
		'vii',
		'do',
		'dds',
		'np',
		'pa',
		'phd',
		'ph.d.',
		'esq',
		'md',
		'm.d.',
		'jr.',
		'sr.',
		'd.o.',
		'd.d.s.',
		'n.p.',
		'p.a.',
		'p.h.d.',
		'esq.',
	];

	const words: string[] = name.trim().split(/\s+/);

	const searchPrefix = words[0].toLowerCase();
	const searchSuffix = words[words.length - 1].toLowerCase();

	let prefix = '';
	let suffix = '';

	if (prefixes.includes(searchPrefix)) {
		prefix = '(' + words.shift() + ')';
	}

	if (suffixes.includes(searchSuffix)) {
		suffix = words.pop() || '';
	}

	words.unshift(words.pop() || '');

	if (words.length > 1) {
		words[0] += ',';
	}

	return (words.join(' ') + ' ' + suffix + ' ' + prefix).trim();
}

export function getBreadcrumbTitle(data: any, key: string, title: string) {
	if (typeof data[key] !== undefined && data[key]) {
		return `Edit ${data[key]}`;
	}

	return `Add ${title}`;
}

export function getSaveButtonName(isLoading: boolean, title: string) {
	return isLoading ? `Saving...` : `${title}`;
}

export function removeSpaces(str: string) {
	return (str || '').replace(/\s/g, '');
}

export function plural(
	text: string,
	word: string,
	count: number,
	suffix = 's',
	isVerb = true
) {
	suffix = suffix || 's';
	let toReplace = word;
	const replaceWords = [
		[' is ', ' are '],
		[' was ', ' were '],
		[' has ', ' have '],
	];

	if (count > 1) {
		const newWord = 'ies' === suffix && count > 1 ? word.slice(0, -1) : word;
		toReplace = `${newWord}${count > 1 ? suffix : ''}`;
	}

	for (const arr of replaceWords) {
		const key = count > 1 ? 0 : 1;
		const key2 = count > 1 ? 1 : 0;
		text = isVerb ? text.replace(new RegExp(arr[key]), arr[key2]) : text;
	}

	text = text.replace(new RegExp(word), toReplace);

	return text;
}

export function removeHTMLTags(str: string) {
	if (typeof str !== 'string' || !str) {
		return '';
	}

	// Regular expression to identify HTML tags in
	// the input string. Replacing the identified
	// HTML tag with a null string.
	return str.replace(/(<([^>]+)>)/gi, '');
}

export function encodeURI(str: string) {
	return encodeURIComponent(str);
}
