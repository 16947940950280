import React from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import ThreadComponent from '../../../ThreadComponent';
import { observer } from 'mobx-react';
import AddressAddEditViewModel from '../../Address/AddressAddEditViewModel';
import { AddressService } from '../../Address/api/AddressService';
import AddressListViewModel from '../../Address/AddressListViewModel';
import EmployeeAddEditViewModel from '../EmployeeAddEditViewModel';
import { EmployeeService } from '../api/EmployeeService';
import EmployeeListViewModel from '../../Employee/EmployeeListViewModel';
import { toSortName } from '../../../../utilities/String';
import { getLocalStorage } from '../../../../utilities/LocalStorage';
import DeleteModal from '../../../components/modal/DeleteModal';
import { displayAlertLoader } from '../../../../utilities/Response';
import HandleDeleteWorker from '../../../../utilities/DeleteWorker';
import URI from '../../../../defaults/RoutesDefault';
import MSG from '../../../../defaults/Message';
import InputMasked from '../../../components/form/MaskedInput';
import SecureBootstrapButton from '../../../components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security';
import { nameToCode, formatCode } from 'legacy/app/utils/codes';
import { SortTypes } from 'legacy/app/enums/employee/employee';

const SORT_TYPE_OPTIONS = [
	{ value: SortTypes.Name, label: 'Name' },
	{ value: SortTypes.Company, label: 'Company' },
	{ value: SortTypes.Manual, label: 'Manual' },
] as const;

interface SelectOptions {
	label?: string;
	value?: any;
}

interface Props {
	data: any;
	addressesTypes?: string[];
	defaultAddressType?: SelectOptions;
	onDraft?: any;
	navigate?: any;
}

interface State {
	data: any;
	isDisabledSort?: boolean;
	showModal: boolean;
	deleteResponses: any;
	dataIsLoaded: boolean;
	isEditing?: boolean;
}

class EmployeesAddInfo extends ThreadComponent<Props, State> {
	readonly addressAddEditModel: AddressAddEditViewModel;
	readonly addressListModel: AddressListViewModel;
	readonly employeeAddEditModel: EmployeeAddEditViewModel;
	readonly employeeListModel: EmployeeListViewModel;
	addressesTypes: string[];
	defaultAddressType: SelectOptions | null;
	defaultSalesperson: SelectOptions | null;
	defaultSortType: SelectOptions | null;
	isDisabledSort: boolean;
	deleteObjectParams: any;

	constructor(props: Props) {
		super(props);

		this.addressAddEditModel = new AddressAddEditViewModel(
			new AddressService()
		);
		this.addressListModel = new AddressListViewModel(new AddressService());
		this.employeeAddEditModel = new EmployeeAddEditViewModel(
			new EmployeeService()
		);
		this.employeeListModel = new EmployeeListViewModel(new EmployeeService());

		this.state = {
			data: {},
			isDisabledSort: false,
			showModal: false,
			deleteResponses: {},
			dataIsLoaded: true,
			isEditing: false,
		};
		this.addressesTypes = [];
		this.defaultAddressType = null;
		this.defaultSalesperson = null;
		this.defaultSortType = SORT_TYPE_OPTIONS[0];
		this.isDisabledSort = false;

		this.deleteObjectParams = {
			deleteType: 'dmriTestOnly',
			objectType: 'objEmployee',
			objectCodeOrId: '',
		};
	}

	componentDidMount(): void {
		this.hasFormAction(true);

		const { data } = this.props;

		this.setState({
			data: data,
		});
		this.loadAdditionalData();
		this.setState({
			dataIsLoaded: true,
		});
	}

	componentDidUpdate(previousProps: Props, _: State): void {
		if (
			previousProps.data !== this.props.data &&
			JSON.stringify(this.props.data) !== '{}'
		) {
			const { data } = this.props;

			if (data?.id) {
				this.setState({
					isEditing: true,
				});
			}

			this.setState({
				data: data,
			});
		}
	}

	loadAdditionalData() {
		// Employee list
		this.employeeListModel.componentDidMount();
	}

	setSelectDefaults() {
		if (this.state.data && this.state.data.address?.typedesc) {
			this.defaultAddressType = {
				value: this.state.data.address?.typedesc,
				label: this.state.data.address?.typedesc,
			};
		}

		for (const x of SORT_TYPE_OPTIONS) {
			if (x.value === this.state.data?.address?.parsetype)
				this.defaultSortType = x;
		}

		const sortName = document.getElementById('sortName');

		if (this.defaultSortType?.value !== 2) {
			sortName?.setAttribute('readonly', 'readonly');
		} else {
			sortName?.removeAttribute('readonly');
		}
	}

	setAddressToEmployee() {
		const addressData: any = getLocalStorage('addressData', true);
		const employeeData: any = getLocalStorage('employeeData', true);

		employeeData['addressData'] = addressData;

		window.localStorage.setItem('employeeData', JSON.stringify(employeeData));
	}

	handleEmployeeChange = (e: any, select?: any) => {
		if (!select) {
			// If checkbox
			if (
				e.target.tagName.toLowerCase() === 'input' &&
				e.target.getAttribute('type') === 'checkbox'
			) {
				this.employeeAddEditModel.setEmployeeData(
					e.target.name,
					e.target.checked ? true : false
				);
				this.props.onDraft();
				return;
			}

			if (!this.state.isEditing && e.target.name === 'vendorn') {
				const employeeCode = nameToCode(e.target.value);
				this.employeeAddEditModel.setEmployeeData('vendor', employeeCode);
			}

			this.employeeAddEditModel.setEmployeeData(e.target.name, e.target.value);
		}

		// If select
		if (select && e.name) {
			this.employeeAddEditModel.setEmployeeData(e.name, select.value);
		}
		this.props.onDraft();
	};

	handleAddressChange = (e: any, select?: any) => {
		// If plain text
		if (!select) {
			this.addressAddEditModel.setAddressData(e.target.name, e.target.value);
		}

		// If select
		if (select && e.name) {
			this.addressAddEditModel.setAddressData(e.name, select.value);
		}
		this.setAddressToEmployee();
		this.props.onDraft();
	};

	handleSortTypeChange = (select: any) => {
		const vendorName: any = document.getElementById('vendorn');
		const sortName: any = document.getElementById('sortName');
		if (select.value === SortTypes.Name || select.value === SortTypes.Company) {
			if (this.state.data?.vendorn) {
				if (select.value === SortTypes.Name) {
					sortName.value = toSortName(vendorName.value);
				} else {
					sortName.value = vendorName.value;
				}
				sortName?.setAttribute('readonly', 'readonly');
			}
		} else {
			sortName?.removeAttribute('readonly');
		}
		this.addressAddEditModel.setAddressData('sortname', sortName.value);
		this.addressAddEditModel.setAddressData('parsetype', select.value);
		this.props.onDraft();
	};

	refreshDataAfterDelete = () => {
		this.props.navigate(URI.contact.employee);
	};

	hideModal = () => {
		this.setState({
			showModal: false,
		});
	};

	handleDelete = () => {
		const self = this;
		const workers: any = [];

		displayAlertLoader(MSG.loading.prepare.item);

		this.deleteObjectParams.objectCodeOrId = this.state.data.id;
		workers.push(
			this.employeeAddEditModel.deleteEmployee(this.deleteObjectParams)
		);

		if (workers) {
			HandleDeleteWorker(
				workers,
				{
					moduleSingular: 'Employee',
					modulePlural: 'Employees',
					reference: 'Projects',
					objectType: self.deleteObjectParams.objectType,
				},
				false,
				(modalState: any) => {
					this.setState(modalState);
				}
			);
		}
	};

	getSortString = () => {
		const { data } = this.state;
		const address = getLocalStorage('addressData');

		if (
			data.address?.parsetype === undefined &&
			address?.parsetype === undefined
		) {
			this.addressAddEditModel.setAddressData('parsetype', SortTypes.Name);
		}
		if (data.address?.parsetype === SortTypes.Name) {
			return toSortName(data?.vendorn || '');
		} else if (data.address?.parsetype === SortTypes.Company) {
			return data?.vendorn || '';
		}

		return data.address?.sortname;
	};

	render(): JSX.Element {
		const { data, isEditing } = this.state;
		this.setSelectDefaults();

		return (
			<>
				<Container className="px-0 ms-0">
					<Form.Group>
						<Row xs={1} lg={2}>
							<Col>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Name</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={40}
											placeholder="Please enter"
											name="vendorn"
											id="vendorn"
											onChange={this.handleEmployeeChange}
											defaultValue={data?.vendorn}
										/>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Code</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col lg={6}>
												{!isEditing ? (
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="vendor"
														id="vendor"
														data-add={1}
														maxLength={5}
														value={data?.vendor}
														onChange={(e) => {
															e.target.value = formatCode(e.target.value);
															this.handleEmployeeChange(e);
														}}
													/>
												) : (
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="vendor"
														id="vendor"
														maxLength={5}
														value={data?.vendor}
														disabled={data.vendorn !== undefined}
														readOnly={data.vendorn !== undefined}
														onChange={(e) => {
															e.target.value = formatCode(e.target.value);
															this.handleEmployeeChange(e);
														}}
													/>
												)}
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Sort</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={40}
											placeholder="Please enter"
											value={this.getSortString()}
											id="sortName"
											onChange={this.handleAddressChange}
											name="sortname"
											readOnly={
												data.address?.parsetype === SortTypes.Name ||
												data.address?.parsetype === SortTypes.Company
													? true
													: false
											}
										/>
									</Col>
									<Col>
										<Select
											options={SORT_TYPE_OPTIONS}
											value={
												SORT_TYPE_OPTIONS[
													data.address?.parsetype || SortTypes.Name
												]
											}
											defaultValue={SORT_TYPE_OPTIONS[0]}
											className="react-select"
											placeholder="Please select"
											onChange={this.handleSortTypeChange}
											id="parsetype"
											name="parsetype"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
									<Col lg={6}>
										<Form.Check
											inline
											label="Inactive"
											name="inactive"
											type="checkbox"
											id="inactive"
											defaultChecked={data?.inactive}
											onClick={this.handleEmployeeChange}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											<strong>Contact Information</strong>
										</Form.Label>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Address</Form.Label>
									</Col>
									<Col>
										<Form.Control
											as="textarea"
											maxLength={255}
											placeholder="Please enter"
											style={{ height: '100px' }}
											defaultValue={data.address?.addr}
											onChange={this.handleAddressChange}
											name="addr"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">City</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={30}
											placeholder="Please enter"
											defaultValue={data.address?.city}
											onChange={this.handleAddressChange}
											name="city"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">State</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											placeholder="Please enter"
											defaultValue={data.address?.state}
											onChange={this.handleAddressChange}
											name="state"
											maxLength={2}
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Zip Code</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={20}
											placeholder="Please enter"
											defaultValue={data.address?.zip}
											onChange={this.handleAddressChange}
											name="zip"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Phone</Form.Label>
									</Col>
									<Col>
										<InputMasked
											type="text"
											placeholder="Please enter"
											value={data.address?.contactphone}
											onChange={this.handleAddressChange}
											name="contactphone"
										/>
									</Col>
								</Row>
							</Col>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">SSN</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											placeholder="Please enter"
											defaultValue={data.ein}
											onChange={this.handleEmployeeChange}
											name="ein"
											maxLength={11}
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">PO Designer/Ext.</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={60}
											placeholder="Please enter"
											name="designer"
											defaultValue={data?.designer}
											onChange={this.handleEmployeeChange}
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Email</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={255}
											placeholder="Please enter"
											defaultValue={data.address?.email}
											value={data.address?.email}
											onChange={this.handleAddressChange}
											name="email"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Website</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={50}
											placeholder="Please enter"
											defaultValue={data.address?.website}
											onChange={this.handleAddressChange}
											name="website"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Phone 2</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col lg={6} className="mb-3 mb-lg-0">
												<Form.Control
													type="text"
													maxLength={40}
													placeholder="Phone 2 Description"
													defaultValue={data.address?.phonedesc1}
													onChange={this.handleAddressChange}
													name="phonedesc1"
												/>
											</Col>
											<Col lg={6}>
												<InputMasked
													type="text"
													placeholder="Phone 2 Number"
													value={data.address?.phone1}
													onChange={this.handleAddressChange}
													name="phone1"
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Phone 3</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col lg={6} className="mb-3 mb-lg-0">
												<Form.Control
													type="text"
													maxLength={40}
													placeholder="Phone 3 Description"
													defaultValue={data.address?.phonedesc2}
													onChange={this.handleAddressChange}
													name="phonedesc2"
												/>
											</Col>
											<Col lg={6}>
												<InputMasked
													type="text"
													placeholder="Phone 3 Number"
													value={data.address?.phone2}
													onChange={this.handleAddressChange}
													name="phone2"
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						{!this.state.data.id ? (
							''
						) : (
							<Row>
								<Col xs={1} className="mt-5">
									<SecureBootstrapButton
										attributeNo={40}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
										href="#"
										variant="danger"
										size="sm"
										className="btn-icon btn-action mt-3"
										onClick={this.handleDelete}
									>
										<i className="ri-close-line"></i> Delete
									</SecureBootstrapButton>
								</Col>
							</Row>
						)}
					</Form.Group>
				</Container>

				<DeleteModal
					refreshData={this.refreshDataAfterDelete}
					show={this.state.showModal}
					hideModal={this.hideModal}
					responses={this.state.deleteResponses}
				/>
			</>
		);
	}
}

export default observer(EmployeesAddInfo);
