import { useMutation } from '@tanstack/react-query';
import {
	connectPlaidAccount,
	type IPlaidConnectParams,
} from 'api/resources/plaidAccounts';

export const usePostConnectToPlaidAccount = () => {
	return useMutation((params: IPlaidConnectParams) =>
		connectPlaidAccount(params)
	);
};
