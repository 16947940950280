import { ApiService } from '../../../../lib/api/HttpService';
import { getCookie } from '../../../../utilities/Auth';
import { EAccountType } from './AccountsDropdown';
const api = new ApiService();

export const acceptReconciliation = async (data) =>
	await api.acceptTemporaryCreditCardReconciliation(data);

export const checkReconcile = async (account, date) =>
	await api.getCreditCardReconciliations(
		`?$filter=statementdate ge ${date} and ccaccount eq '${account}'`
	);

export const downloadCreditCardReconciliation = async (data) =>
	await api.getCreditCardReconciliationReport(data);

export const editCreditCardFinanceCharges = async (data) =>
	await api.editFinanceCharges({
		...data,
		IsCheckbook: false,
	});

export const getTemporaryJournalEntries = async (data) =>
	await api.getTempJournalEntryDetails(data);

export const getAccounts = async ({ type }) => {
	let filter = '';

	if (type === EAccountType.LIABILITY) {
		filter = `?$filter=fixedtype eq 202`;
	} else if (type === EAccountType.CHECKING) {
		filter = `?$filter=type eq 1 and specacct eq 1`;
	}

	return await api.getGLAccounts(filter);
};

export const getGridData = async () => {
	const usercode = getCookie('dmUsercode');
	return await api.getTemporarycreditcardreconciliations(
		`?$filter=usercode eq ${usercode}`
	);
};

export const getCreditCardReconciliations = async () =>
	await api.getCreditCardReconciliations();

export const getPayTypes = async () => await api.getPayTypes();

export const getPaymentTypes = async () => await api.getPaymentTypes();

export const getTemporarycreditcardreconciliationTotals = async (data) =>
	await api.getTemporaryCreditCardReconciliationTotals(data);

export const getVendorInvoiceDetails = async (filter) =>
	await api.getVendorInvoiceDetails(filter);

export const startReconcile = async (data) =>
	await api.startTemporaryCreditCardReconciliation(data);

export const updateTemporarycreditcardreconciliation = async (item) => {
	const { recnum } = item;
	return await api.updateTemporaryCreditCardReconciliation(recnum, item);
};

export const voidCreditCardReconciliation = async (data) =>
	await api.voidCreditCardReconciliation(data);

export const getVendorInvoices = async (filter) =>
	await api.getVendorInvoices(filter);

export const getVendorInvoiceDetailsProjectPO = async (filter) =>
	await api.getVendorInvoiceDetailsProjectPO(filter);

export const getJournalEntryCheckbookDetail = async (filter) =>
	await api.getJournalEntryCheckbookDetail(filter);

export const searchGLTransactions = async (filter) =>
	await api.searchGLTransactions(filter);

export const getCheckCheckbookDetail = async (filter) =>
	await api.getCheckCheckbookDetail(filter);

export const getDepositCheckbookDetail = async (filter) =>
	await api.getDepositCheckbookDetail(filter);

export const getPaydistDepositCheckbookDetail = async (filter) =>
	await api.getPaydistDepositCheckbookDetail(filter);
