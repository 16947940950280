import { Dispatch, ReactNode, SetStateAction } from 'react';

export type TCrumb = {
	text: string;
	to?: string;
};

export type THeaderTheme = 'light' | 'darkGreen';

export type TTab = {
	label: string;
	to: string;
	id: string;
	active: boolean;
};

export type THeaderOptions = {
	title?: string;
	breadcrumbs?: TCrumb[];
	enableBackButton?: boolean;
	theme?: EHeaderTheme;
	actions?: ReactNode[];
	tabs?: TTab[];
};

export type THeaderState = [
	THeaderOptions | null,
	Dispatch<SetStateAction<THeaderOptions | null>>
];

export enum EHeaderTheme {
	LIGHT = 'bg-light',
	DARK_GREEN = 'bg-secondary-green',
}
