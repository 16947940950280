import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { ClientService } from '../../pages/Client/api/ClientService';
import ClientAddEditViewModel from '../../pages/Client/ClientAddEditViewModel';
import {
	getErrorMessage,
	handleResponseAlert,
} from '../../../utilities/Response';
import { removeSpaces } from '../../../utilities/String';
import { AlertError } from '../bootstrap/BAlerts';
import AddressAddEditViewModel from '../../../app/pages/Address/AddressAddEditViewModel';
import { AddressService } from '../../pages/Address/api/AddressService';
import { nameToCode, formatCode } from 'legacy/app/utils/codes';

interface Props {
	hideModal?: any;
	handleSuccess?: any;
	clientNameNew?: string;
}

interface State {
	isLoading?: boolean;
	errorMessage?: string | null;
	client?: string;
	ClientName?: string;
}

class ClientQuickAddModal extends ThreadComponent<Props, State> {
	readonly clientAddEditModel: ClientAddEditViewModel;
	readonly addressAddEditModel: AddressAddEditViewModel;

	constructor(props: Props) {
		super(props);

		this.state = {
			isLoading: false,
			errorMessage: null,
			client: nameToCode(this.props.clientNameNew ?? ''),
			ClientName: this.props.clientNameNew ?? '',
		};

		this.clientAddEditModel = new ClientAddEditViewModel(new ClientService());
		this.addressAddEditModel = new AddressAddEditViewModel(
			new AddressService()
		);
	}

	setIsLoading(state: boolean) {
		this.setState({
			isLoading: state,
		});
	}

	resetData() {
		this.setState({
			client: '',
			ClientName: '',
		});
	}

	handleAction: React.MouseEventHandler<HTMLButtonElement> = () => {
		const clientData = {
			ClientName: this.state.ClientName ?? '',
			client: this.state.client ?? '',
		};
		let errorMessage;

		if (!removeSpaces(clientData.ClientName))
			errorMessage = 'Client name is required';
		else if (!removeSpaces(clientData.client))
			errorMessage = 'Client code is required';

		if (errorMessage) {
			this.setState({
				errorMessage,
			});

			return;
		}

		const request = this.clientAddEditModel.insertClient(clientData);

		this.setIsLoading(true);
		this.setState({
			errorMessage: null,
		});

		handleResponseAlert(request, (res: any) => {
			this.setIsLoading(false);
			if (res.id) {
				if (this.props.handleSuccess) {
					this.props.handleSuccess(res);
					this.addressAddEditModel.modifyAddress(undefined, {
						sortname: clientData.ClientName,
						code: clientData.client,
						addressTypeEnum: 'Client',
						addresstype: 4,
					});
					this.resetData();
				}
			} else {
				this.setState({
					errorMessage: getErrorMessage(res),
				});
			}
		});
	};

	handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === 'ClientName') {
			this.setState({
				ClientName: value,
				client: nameToCode(value),
			});
		} else if (name === 'client') {
			this.setState({
				client: formatCode(value),
			});
		}
	};

	render() {
		const { isLoading, ClientName, client, errorMessage } = this.state;

		return (
			<Modal
				show={true}
				onHide={this.props.hideModal}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row xs={1} lg={1} className="align-items-start my-2 px-5">
						<Col>
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								New Client
							</h5>
							<Row className="align-items-center mb-3 pt-4">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Client Name</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Client name"
										name="ClientName"
										onChange={this.handleInputChange}
										value={ClientName}
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Client Code</Form.Label>
								</Col>
								<Col>
									<Form.Control
										maxLength={5}
										type="text"
										placeholder="Client code"
										name="client"
										onChange={this.handleInputChange}
										value={client}
									/>
								</Col>
							</Row>
						</Col>
						{errorMessage && (
							<Col>
								<Row className="align-items-center">
									<Col>
										<AlertError isClose={false} className="compact mb-1">
											{errorMessage}
										</AlertError>
									</Col>
								</Row>
							</Col>
						)}
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
								disabled={isLoading}
								data-testid="client-quick-add-modal-ok-button"
							>
								{isLoading ? 'Saving...' : 'OK'}
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default ClientQuickAddModal;
