import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Button } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import {
	getActiveMenuUri,
	getRouteWithParam,
	WithRouter,
} from '../../../../helpers/Router';
import { compareStr } from '../../../../helpers/String';
import { FooterFormAction } from '../../../components/Form';
import { getSubComponent } from '../../../../helpers/Util';
import CatalogItemComponentAddInfo from './CatalogItemComponentAddInfo';
import CatalogItemComponentAddSpecification from './CatalogItemComponentAddSpecification';
import CatalogItemComponentAddOrderStatus from './CatalogItemComponentAddOrderStatus';

class CatalogItemComponentAdd extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['info', 'specification', 'order-status'],
				'info'
			),
		};

		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	componentInit() {
		this.setTitle('Add Item');
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.getAttribute('data-menu'))
		);
	};

	header() {
		const { activeMenu } = this.state;
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.item.catalog.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							linkProps={{ to: URI.item.catalog.base }}
							linkAs={Link}
						>
							Items
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.item.catalog.base }}
							linkAs={Link}
						>
							Catalogs
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.item.catalog.add }}
							linkAs={Link}
						>
							Info
						</Breadcrumb.Item>
						<Breadcrumb.Item>Component Name</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>Component Name</HeaderLight.Title>

					{/* Submit Button */}
					<Button variant="primary">Save</Button>
				</HeaderLight.Content>
				<HeaderLight.Actions className="text-charcoal">
					<li>
						<Button
							as={Link}
							to={getRouteWithParam(URI.project.itemAdd, {
								id: 1,
							})}
							data-menu="info"
							className={`${compareStr(activeMenu, 'info', 'active', '')}`}
							onClick={this.handleMenuClick}
						>
							Info
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							to={getRouteWithParam(URI.project.itemAdd, {
								id: 1,
							})}
							data-menu="specification"
							className={`${compareStr(
								activeMenu,
								'specification',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Special Instructions
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							to={getRouteWithParam(URI.project.itemAdd, {
								id: 1,
							})}
							data-menu="order-status"
							className={`${compareStr(
								activeMenu,
								'order-status',
								'active',
								''
							)}`}
							onClick={this.handleMenuClick}
						>
							Order Status
						</Button>
					</li>
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	getContent() {
		return getSubComponent(this.state.activeMenu, {
			info: <CatalogItemComponentAddInfo />,
			specification: <CatalogItemComponentAddSpecification />,
			'order-status': <CatalogItemComponentAddOrderStatus />,
		});
	}

	render() {
		return (
			<>
				{this.header()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid>{this.getContent()}</Container>
				</div>

				<FooterFormAction>
					<Button variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(CatalogItemComponentAdd);
