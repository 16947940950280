import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Header from '../../../templates/components/Header';
import { compareStr } from '../../../helpers/String';
import React from 'react';
import URI from '../../../defaults/RoutesDefault';

const PageHeader = ({ selectedTab, onTab, hasAttachments }) => {
	return (
		<Header buttons={1}>
			<Header.TitleContent>
				<Header.LeftContent>
					<Header.Breadcumbs>
						<Breadcrumb className="breadcrumb-light">
							<Breadcrumb.Item linkProps={{ to: '/settings' }} linkAs={Link}>
								Settings
							</Breadcrumb.Item>
							<Breadcrumb.Item>Company Information</Breadcrumb.Item>
						</Breadcrumb>
					</Header.Breadcumbs>
					<Header.Title>Company Information</Header.Title>
				</Header.LeftContent>
			</Header.TitleContent>
			<Header.Buttons>
				<li>
					<Link
						to={URI.settings.companySettings}
						data-menu="info"
						className={`btn text-white ${compareStr(
							selectedTab,
							'info',
							'active',
							''
						)}`}
						onClick={onTab}
					>
						Info
					</Link>
				</li>
				<li>
					<Link
						to={URI.settings.companySettings}
						data-menu="documents"
						className={`btn text-white ${compareStr(
							selectedTab,
							'documents',
							'active',
							''
						)}`}
						onClick={onTab}
					>
						Documents{' '}
						{hasAttachments && <i className="ri-attachment-2 ms-1"></i>}
					</Link>
				</li>
			</Header.Buttons>
		</Header>
	);
};

export default PageHeader;
