import SpecialIntructionsForm from './SpecialInstructionsForm';
import WorkroomSpecialInstructionsForm from './WorkroomSpecialInstructionsForm';

const SpecialInstructionsTab = () => (
	<div className="tw-min-h-[calc(100vh-360px)] tw-pt-10 tw-pb-10">
		<div className="tw-pl-8 tw-pr-8">
			<SpecialIntructionsForm />
			<WorkroomSpecialInstructionsForm />
		</div>
	</div>
);

SpecialInstructionsTab.displayName = 'SpecialInstructionsTab';

export default SpecialInstructionsTab;
