import { Col, Row, Form, Container, Table, Button } from 'react-bootstrap';
import Select from 'react-select';
import { WithRouter } from '../../../../helpers/Router';
import { FormEvent } from '../../../../utilities/FormEvent';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { FooterFormAction } from '../../../components/Form';
import {
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
	handleResponseAlert,
} from '../../../../utilities/Response';
import { WorkflowSettingsValidation } from './validations/WorkflowSettingsValidation';
import { validateInput } from '../../../../app/components/validators/Helpers';
import { displayAlert } from '../../../../utilities/Response';
import { pathParam } from '../../../../utilities/Router';
import URI from '../../../../defaults/RoutesDefault';
import { reload } from '../../../../helpers/Util';
import MSG from '../../../../defaults/Message';
import { VALID_PERCENTAGE_NUMBER } from '../../../../resources/regex/validPercentageNumber';

class WorkflowSettingsGeneral extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			startDate: '',
			minDate: new Date(),
			data: [],
			data2: [],
			typeOptions: [
				{
					value: -1,
					label: 'Discount',
				},
				{
					value: 0,
					label: 'Markup',
				},
				{
					value: 1,
					label: 'Fee',
				},
			],
			markupmerc: '',
			markupdesign: '',
			markupfreight: '',
			markupinstall: '',
			markuplabor: '',
			shippingOptions: [],
			shipToOptions: [],
		};

		this.formEvent = new FormEvent(`workflow_settings`);

		this.api = new ApiService();
		this.updatedData = {};
		this.updatedData2 = {};
	}

	setData(project) {
		this.setState({
			project,
			default: {
				usediscount: {
					value: 0,
					label: 'Markup',
				},
			},
		});
	}

	async componentDidMount() {
		this.dMloader(true);
		const companyRes = await this.api.getCompany();
		const company2Res = await this.api.getCompany2();

		await this.renderShipOptions();

		this.renderData(companyRes, company2Res);
		this.dMloader(false);
	}

	async renderShipOptions() {
		const shipTo = await this.api.get('addresses', '?$filter=addresstype eq 1');

		shipTo.map((code) => {
			this.state.shipToOptions.push({
				value: code.code,
				label: code.sortname + ' (' + code.code + ')',
			});
		});
	}

	renderData(data, data2) {
		this.setState({
			dataIsLoaded: true,
			data: data,
			data2: data2,
			markupmerc: data.markupmerc,
			markupdesign: data.markupdesign,
			markupfreight: data.markupfreight,
			markupinstall: data.markupinstall,
			markuplabor: data.markuplabor,
			shippingOptions: [
				{
					value: 0,
					label: data.compviewm,
				},
				{
					value: 1,
					label: data.compviewd,
				},
				{
					value: 2,
					label: data.compviewf,
				},
				{
					value: 3,
					label: data.compviewi,
				},
				{
					value: 4,
					label: data.compviewl,
				},
			],
		});
	}

	handlePercentageChange = (e) => {
		const isValidValue = VALID_PERCENTAGE_NUMBER.test(e.target.value);

		if (isValidValue || e.target.value === '') {
			this.setState({ [e.target.name]: e.target.value });
			this.handleChange(e);
		}
	};

	normalisePercentageValueOnBlur = (e) => {
		const value = this.state[e.target.name];
		if (value[value.length - 1] === '.') {
			this.setState({
				[e.target.name]: Number(this.state[e.target.name]).toString(),
			});
		}
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.attributes['data-field'].value;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.updatedData[key] = value;
	};

	handleChange2 = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.attributes['data-field'].value;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}
		this.updatedData2[key] = value;
	};

	handleClickSave = async (e) => {
		e.preventDefault();
		displayAlertLoader(MSG.loading.update.msg);
		const validated = await validateInput(
			WorkflowSettingsValidation,
			this.updatedData
		);
		if (!validated.status) {
			this.api
				.patchCompany(this.updatedData)
				.then((res) => {
					this.api
						.patchCompany2(this.updatedData2)
						.then((res) => {
							displayAlert('success', 'Successfully saved changes.');
							reload();
						})
						.catch((error) => {
							displayAlertError(getErrorMessage(error));
						});
				})
				.catch((error) => {
					displayAlertError(getErrorMessage(error));
				});
		} else {
			displayAlert('danger', validated.message);
		}
	};
	redirectPage() {
		setTimeout(() => {
			window.location.href = pathParam(URI.settings.list);
		}, 1500);
	}

	render() {
		const { data, data2 } = this.state;

		return (
			<>
				<div className="content-padding min-height">
					<Container>
						<Row className="mb-2">
							<Col xxl={10}>
								<div className="table-gradient">
									<Table responsive borderless className="">
										<thead>
											<tr key="0">
												<th className="fs-lg"></th>
												<th className="fs-lg mw-140px">Screen Title</th>
												<th className="fs-lg"></th>
												<th className="fs-lg mw-180px">%Type</th>
												<th className="fs-lg"></th>
												<th className="fs-lg w-80px">Percentage</th>
												<th className="fs-lg"></th>
												<th className="fs-lg mw-80px text-center">
													Inc. in Dep.
												</th>
												<th className="fs-lg"></th>
												<th className="fs-lg mw-80px text-center">Auto</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="align-middle text-end">Merchandise</td>
												<td>
													<Form.Control
														type="text"
														name="compviewm"
														defaultValue={data.compviewm}
														readOnly
														disabled
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Select
														key={`${Math.floor(Math.random() * 1000)}-min`}
														name="usediscountmerc"
														options={this.state.typeOptions}
														defaultValue={this.state.typeOptions.filter(
															(type) => {
																return type.value == data.usediscountmerc;
															}
														)}
														className="react-select"
														onChange={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Form.Control
														type="text"
														name="markupmerc"
														value={this.state.markupmerc}
														onChange={this.handlePercentageChange}
														onBlur={this.normalisePercentageValueOnBlur}
														data-field="markupmerc"
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="indepositmerc"
														data-field="indepositmerc"
														type="checkbox"
														id="indepositmerc"
														defaultChecked={data.indepositmerc}
														onClick={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center"></td>
											</tr>
											<tr>
												<td className="align-middle text-end">
													{data.compviewd}
												</td>
												<td>
													<Form.Control
														type="text"
														name="compviewd"
														data-field="compviewd"
														defaultValue={data.compviewd}
														onChange={this.handleChange}
														maxLength={30}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Select
														key={`${Math.floor(Math.random() * 1000)}-min`}
														name="usediscountdesign"
														options={this.state.typeOptions}
														defaultValue={this.state.typeOptions.filter(
															(type) => {
																return type.value == data.usediscountdesign;
															}
														)}
														className="react-select"
														onChange={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Form.Control
														type="text"
														maxLength={30}
														name="markupdesign"
														data-field="markupdesign"
														value={this.state.markupdesign}
														onChange={this.handlePercentageChange}
														onBlur={this.normalisePercentageValueOnBlur}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="indepositdesign"
														data-field="indepositdesign"
														type="checkbox"
														id="indepositdesign"
														defaultChecked={data.indepositdesign}
														onClick={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="autod"
														data-field="autod"
														type="checkbox"
														id="autod"
														defaultChecked={data.autod}
														onClick={this.handleChange}
													/>
												</td>
											</tr>
											<tr>
												<td className="align-middle text-end">
													{data.compviewf}
												</td>
												<td>
													<Form.Control
														type="text"
														name="compviewf"
														data-field="compviewf"
														defaultValue={data.compviewf}
														onChange={this.handleChange}
														maxLength={30}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Select
														key={`${Math.floor(Math.random() * 1000)}-min`}
														name="usediscountfreight"
														options={this.state.typeOptions}
														defaultValue={this.state.typeOptions.filter(
															(type) => {
																return type.value == data.usediscountfreight;
															}
														)}
														className="react-select"
														onChange={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Form.Control
														type="text"
														name="markupfreight"
														data-field="markupfreight"
														value={this.state.markupfreight}
														onChange={this.handlePercentageChange}
														onBlur={this.normalisePercentageValueOnBlur}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="indepositfreight"
														data-field="indepositfreight"
														type="checkbox"
														id="indepositfreight"
														defaultChecked={data.indepositfreight}
														onClick={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="autof"
														data-field="autof"
														type="checkbox"
														id="autof"
														defaultChecked={data.autof}
														onClick={this.handleChange}
													/>
												</td>
											</tr>
											<tr>
												<td className="align-middle text-end">
													{data.compviewi}
												</td>
												<td>
													<Form.Control
														type="text"
														name="compviewi"
														data-field="compviewi"
														defaultValue={data.compviewi}
														onChange={this.handleChange}
														maxLength={30}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Select
														key={`${Math.floor(Math.random() * 1000)}-min`}
														name="usediscountinstall"
														options={this.state.typeOptions}
														defaultValue={this.state.typeOptions.filter(
															(type) => {
																return type.value == data.usediscountinstall;
															}
														)}
														className="react-select"
														onChange={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Form.Control
														type="text"
														name="markupinstall"
														data-field="markupinstall"
														value={this.state.markupinstall}
														onChange={this.handlePercentageChange}
														onBlur={this.normalisePercentageValueOnBlur}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="indepositinstall"
														data-field="indepositinstall"
														type="checkbox"
														id="indepositinstall"
														defaultChecked={data.indepositinstall}
														onClick={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="autoi"
														data-field="autoi"
														type="checkbox"
														id="autoi"
														defaultChecked={data.autoi}
														onClick={this.handleChange}
													/>
												</td>
											</tr>
											<tr>
												<td className="align-middle text-end">
													{data.compviewl}
												</td>
												<td>
													<Form.Control
														type="text"
														name="compviewl"
														data-field="compviewl"
														defaultValue={data.compviewl}
														onChange={this.handleChange}
														maxLength={30}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Select
														key={`${Math.floor(Math.random() * 1000)}-min`}
														name="usediscountlabor"
														options={this.state.typeOptions}
														defaultValue={this.state.typeOptions.filter(
															(type) => {
																return type.value == data.usediscountlabor;
															}
														)}
														className="react-select"
														onChange={this.handleChange}
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 9999,
															}),
														}}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td>
													<Form.Control
														type="text"
														name="markuplabor"
														data-field="markuplabor"
														value={this.state.markuplabor}
														onChange={this.handlePercentageChange}
														onBlur={this.normalisePercentageValueOnBlur}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="indepositlabor"
														data-field="indepositlabor"
														type="checkbox"
														id="indepositlabor"
														defaultChecked={data.indepositlabor}
														onClick={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="autol"
														data-field="autol"
														type="checkbox"
														id="autol"
														defaultChecked={data.autol}
														onClick={this.handleChange}
													/>
												</td>
											</tr>
											<tr>
												<td className="align-middle text-end">Time</td>
												<td>
													<Form.Control
														type="text"
														name="compviewo"
														defaultValue={data.compviewo}
														readOnly
														disabled
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td></td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td></td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="indepositother"
														data-field="indepositother"
														type="checkbox"
														id="indepositother"
														defaultChecked={data.indepositother}
														onClick={this.handleChange}
													/>
												</td>
												<td className="align-middle text-center text-secondary-ash"></td>
												<td className="align-middle text-center">
													<Form.Check
														inline
														label=""
														name="autoo"
														data-field="autoo"
														type="checkbox"
														id="autoo"
														defaultChecked={data.autoo}
														onClick={this.handleChange}
													/>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</Col>
						</Row>

						<div className="bg-secondary-grey mb-4 roundedx-4">
							<Row xs={1} className="py-3">
								<Col>
									<Row className="align-items-center px-3">
										<Col className="px-3">
											<h6 className="fw-bold mb-0">Options</h6>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>

						<Row className="mb-5 px-2" xs={1} lg={2}>
							<Col className="px-4">
								<Row className="mb-3 align-items-center">
									<Col>
										<Form.Check
											inline
											label="Include Tax in Dep."
											name="cldpsalestax"
											data-field="cldpsalestax"
											type="checkbox"
											id="cldpsalestax"
											defaultChecked={data.cldpsalestax}
											onClick={this.handleChange}
										/>
									</Col>
								</Row>
								<Row className="mb-3 align-items-center">
									<Col>
										<Form.Check
											inline
											label="Loc in S/M"
											name="locinsidemark"
											data-field="locinsidemark"
											type="checkbox"
											id="locinsidemark"
											defaultChecked={data.locinsidemark}
											onClick={this.handleChange}
										/>
									</Col>
								</Row>
								<Row className="mb-3 align-items-center">
									<Col xs={12} sm={2} className="pe-0 text-lg-end mb-2 mb-sm-0">
										Shipping Cost Type
									</Col>
									<Col xs={12} sm={10} lg={6}>
										<Select
											key={`${Math.floor(Math.random() * 1000)}-min`}
											name="itemCaptureShippingCompType"
											options={this.state.shippingOptions}
											defaultValue={this.state.shippingOptions.filter(
												(shippingCost) => {
													return (
														shippingCost.value ==
														data2.itemCaptureShippingCompType
													);
												}
											)}
											className="react-select"
											onChange={this.handleChange2}
										/>
									</Col>
								</Row>
							</Col>
							<Col className="px-4">
								<Row className="mb-3 align-items-center">
									<Col
										xs={12}
										sm={2}
										className="pe-0 text-lg-start mb-2 mb-sm-0"
									>
										User Date 1 Title
									</Col>
									<Col className="mb-3 mb-lg-0">
										<Form.Control
											type="text"
											name="wildcardstatus1"
											data-field="wildcardstatus1"
											maxLength={20}
											defaultValue={data.wildcardstatus1}
											onChange={this.handleChange}
										/>
									</Col>
									<Col xs={12} lg={5}>
										<Row className="align-items-center">
											<Col
												xs={12}
												sm={2}
												lg={3}
												className="pe-0 text-lg-end mb-2 mb-sm-0"
											>
												CDep.%
											</Col>
											<Col>
												<Form.Control
													type="text"
													name="clientdep"
													data-field="clientdep"
													defaultValue={data.clientdep}
													onChange={this.handleChange}
													onKeyPress={(e) =>
														!/[0-9]/.test(e.key) && e.preventDefault()
													}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="mb-3 align-items-center">
									<Col
										xs={12}
										sm={2}
										className="pe-0 text-lg-start mb-2 mb-sm-0"
									>
										User Date 2 Title
									</Col>
									<Col xs={12} sm={10} lg={5}>
										<Form.Control
											type="text"
											name="wildcardstatus2"
											data-field="wildcardstatus2"
											maxLength={20}
											defaultValue={data.wildcardstatus2}
											onChange={this.handleChange}
										/>
									</Col>
									<Col></Col>
								</Row>
								<Row className="mb-3 align-items-center">
									<Col
										xs={12}
										sm={2}
										className="pe-0 text-lg-start mb-2 mb-sm-0"
									>
										Ship To
									</Col>
									<Col>
										<div>
											<Select
												key={`${Math.floor(Math.random() * 1000)}-min`}
												name="defshipto"
												options={this.state.shipToOptions}
												defaultValue={this.state.shipToOptions.filter(
													(shipToCode) => {
														return shipToCode.value == data2.defshipto;
													}
												)}
												placeholder="Please select"
												className="react-select"
												onChange={this.handleChange2}
											/>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>

				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleClickSave}>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(WorkflowSettingsGeneral);
