import React from 'react';
import { observer } from 'mobx-react';
import { Container, Col, Row, Form, Alert, Table } from 'react-bootstrap';
import ThreadComponent from '../../../../templates/modules/ThreadComponent';
import EmployeeAddEditViewModel from '../EmployeeAddEditViewModel';
import { EmployeeService } from '../api/EmployeeService';
import { ActionBarVertical } from '../../../../templates/components/ActionBar';
import { NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import { generatePath } from 'react-router';
import tableEvent from '../../../../utilities/Table';

interface Props {
	data: any;
	onDraft?: any;
}

interface State {
	data: any;
	activeId: number;
	timeActivities: any;
	isLoaded: boolean;
}

interface SelectOptions {
	label?: string;
	value?: string;
}

class EmployeeAddEditTime extends ThreadComponent {
	readonly employeeAddEditModel: EmployeeAddEditViewModel;

	constructor(props: Props) {
		super(props);

		this.employeeAddEditModel = new EmployeeAddEditViewModel(
			new EmployeeService()
		);

		this.state = {
			data: { id: 0 },
			activeId: 0,
			timeActivities: [],
			isLoaded: false,
		};
	}

	async componentDidMount() {
		this.setTitle('Time - Add Employees')
			.setActionBar(true)
			.setFormAction(true)
			.setBodyClass();

		const { data } = this.props;

		const timeActivities =
			await this.employeeAddEditModel.fetchTimeActivities();
		this.setState({
			data: data,
			timeActivities: timeActivities,
			isLoaded: true,
		});

		setTimeout(() => {
			tableEvent((tr: any) => {
				this.setState({
					activeId: tr.dataset.id,
				});
			});
		}, 1200);
	}

	componentDidUpdate(previousProps: Props, previousState: State): void {
		if (previousProps.data !== this.props.data) {
			const { data } = this.props;

			this.setState({
				data: data,
			});

			setTimeout(() => {
				tableEvent((tr: any) => {
					this.setState({
						activeId: tr.dataset.id,
					});
				});
			}, 1200);
		}
	}

	handleEmployeeChange = (e: any, select?: any) => {
		if (!select) {
			let val = e.target.value;
			if (e.target.tagName.toLowerCase() === 'input') {
				if (e.target.getAttribute('type') === 'number') {
					const inputValue = e.target.value;
					val = inputValue.replace(/[^0-9.]/g, '');
				} else if (e.target.getAttribute('type') === 'checked') {
					this.employeeAddEditModel.setEmployeeData(
						e.target.name,
						e.target.checked ? true : false
					);
					this.props.onDraft();
					return;
				}
			}

			this.employeeAddEditModel.setEmployeeData(e.target.name, val);
		}

		// If select
		if (select && e.name) {
			this.employeeAddEditModel.setEmployeeData(e.name, select.value);
		}
		this.props.onDraft();
	};

	getFormattedHours = (hours: string) => {
		if (!hours) return;
		const formattedHours = parseFloat(hours);
		return formattedHours !== Math.floor(formattedHours)
			? formattedHours?.toFixed(2)
			: formattedHours;
	};

	renderActionBarVertical() {
		const { activeId } = this.state;
		const { data } = this.state;

		if (data.id) {
			return (
				<ActionBarVertical>
					<ActionBarVertical.Links>
						<li>
							<NavLink
								to={generatePath(URI.contact.employeeTimeActivityAdd, {
									id: data.id,
								})}
								className="d-flex align-items-center mb-2"
							>
								<i className="ri-add-box-fill mx-0"></i>
							</NavLink>
						</li>
						<li>
							<NavLink
								to={generatePath(URI.contact.employeeTimeActivityEdit, {
									id: data.id,
									aid: activeId,
								})}
								className="d-flex align-items-center"
							>
								<i className="ri-edit-line mx-0"></i>
							</NavLink>
						</li>
					</ActionBarVertical.Links>
				</ActionBarVertical>
			);
		} else {
			return '';
		}
	}

	render(): JSX.Element {
		const { data } = this.state;

		return (
			<>
				<style>
					input[type="number"]::-webkit-outer-spin-button,
					input[type="number"]::-webkit-inner-spin-button{' '}
					{'{margin: 0; -webkit-appearance: none}'}
				</style>
				<Container className="ms-0">
					<Row xs={1} lg={3} className="mb-0 mb-lg-3">
						<Col className="mb-2 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 1 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="number"
										step="any"
										pattern="[0-9]*"
										placeholder="0.00"
										name="baserateperhr"
										defaultValue={
											data?.baserateperhr &&
											this.getFormattedHours(data?.baserateperhr)
										}
										onChange={this.handleEmployeeChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 1 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="number"
										step="any"
										pattern="[0-9]*"
										placeholder="0.00"
										name="basecostperhr"
										defaultValue={
											data?.basecostperhr &&
											this.getFormattedHours(data?.basecostperhr)
										}
										onChange={this.handleEmployeeChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
						<Col className="mb-2 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 2 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="number"
										step="any"
										pattern="[0-9]*"
										placeholder="0.00"
										name="tier2rateperhr"
										defaultValue={
											data?.tier2rateperhr &&
											this.getFormattedHours(data?.tier2rateperhr)
										}
										onChange={this.handleEmployeeChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 2 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="number"
										step="any"
										pattern="[0-9]*"
										placeholder="0.00"
										name="tier2costperhr"
										defaultValue={
											data?.tier2costperhr &&
											this.getFormattedHours(data?.tier2costperhr)
										}
										onChange={this.handleEmployeeChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0 d-flex d-lg-block">
							<Row className="align-items-center mb-3 pe-2 pe-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 3 Rate</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="number"
										step="any"
										pattern="[0-9]*"
										placeholder="0.00"
										name="tier3rateperhr"
										defaultValue={
											data?.tier3rateperhr &&
											this.getFormattedHours(data?.tier3rateperhr)
										}
										onChange={this.handleEmployeeChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
							<Row className="align-items-center mb-3 ps-2 ps-lg-0">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Tier 3 Cost</Form.Label>
								</Col>
								<Col className="d-flex align-items-center">
									<Form.Control
										type="number"
										step="any"
										pattern="[0-9]*"
										placeholder="0.00"
										name="tier3costperhr"
										defaultValue={
											data?.tier3costperhr &&
											this.getFormattedHours(data?.tier3costperhr)
										}
										onChange={this.handleEmployeeChange}
									/>
									<span className="ps-1"> /hr.</span>
								</Col>
							</Row>
						</Col>
					</Row>

					{/* show v1
                    <Row>
                        <Col xs={12} className='table-actions position-relative table-action-bar'>

                            {this.renderActionBarVertical()}

                            <div className='table-gradient'>
                                <Table striped responsive className='a-table'>
                                    <thead>
                                        <tr>
                                            <th>Activity</th>
                                            <th>Activity Name</th>
                                            <th>Tier 1<br />Rate/Hr.</th>
                                            <th>Tier 1<br />Cost/Hr.</th>
                                            <th>Tier 2<br />Rate/Hr.</th>
                                            <th>Tier 2<br />Cost/Hr.</th>
                                            <th>Tier 3<br />Rate/Hr.</th>
                                            <th>Tier 3<br />Cost/Hr.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (!this.state.isLoaded)
                                                ?
                                                <tr key={0}>
                                                    <td colSpan={8} className='fw-bold'>Fetching records, please wait...</td>
                                                </tr>
                                                :
                                                this.state.timeActivities.map((item: any, i: number) =>
                                                    <tr key={i} data-id={item.id}>

                                                        <td>{item.activity}</td>
                                                        <td>{item.activityn}</td>
                                                        <td>{item.baserateperhr}</td>
                                                        <td>{item.basecostperhr}</td>
                                                        <td>{item.tier2rateperhr}</td>
                                                        <td>{item.tier2costperhr}</td>
                                                        <td>{item.tier3rateperhr}</td>
                                                        <td>{item.tier3costperhr}</td>
                                                    </tr>
                                                )
                                        }

                                    </tbody>
                                </Table>
                            </div>

                        </Col>
                    </Row>
                    */}
				</Container>
			</>
		);
	}
}

export default observer(EmployeeAddEditTime);
