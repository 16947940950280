import React from 'react';
import { Dropdown, Col, Row, Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import ListFilter from '../../../components/ListFilter';
import URI from '../../../../defaults/RoutesDefault';
import { ApiService } from '../../../../lib/api/HttpService';
import { currencyFormat } from '../../../../helpers/Number';
import { Pagination } from '../../../../app/components/pagination/Pagination';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import {
	formatDate,
	formatFiscalMonth,
	formatFilterDate,
	formatFilterMonth,
	formatDateTime,
} from '../../../../helpers/Date';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	hideAlertLoader,
} from '../../../../utilities/Response';
import { generatePath } from 'react-router';
import ClientInvoiceModal from '../../modal/ClientInvoiceModal';
import _, { filter, find, findIndex, includes, isArray, uniq } from 'lodash';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { isFiltered } from '../../../../helpers/Util';
import { DatePicker, DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import MSG from '../../../../defaults/Message';
import { pathParam } from '../../../../utilities/Router';
import FilterSort from '../../../../utilities/modules/FilterSort';
import SecureDropdownItem from '../../../../app/components/security/SecureDropdownItem';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';

class ClientInvoicesListExisting extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('ar_clientinvoice_existing_list');
		this.fs.setDefaultSort('invnum desc');
		this.state = {
			checks: [],
			totalChecked: 0,
			data: [],
			pageSize: 20,
			page: 1,
			sortProperty: this.fs.getSort() ?? 'invnum desc',
			showClientInvoiceModal: false,
			project: [],
			clientName: '',
			reportFormats: [],
			clientInvoiceData: {},
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};

		this.api = new ApiService();
		this.changePageHandler = _.debounce(
			this.handleChangePage.bind(this, 1),
			200
		);
	}

	componentInit() {
		this.setTitle('Existing | Client Invoices').setActionBar(true);
	}

	async componentDidMount() {
		const checks = JSON.parse(localStorage.getItem('checks')) || [];

		this.setState({ checks, totalChecked: checks.length });
		this.fetchItems();
		this.enableSortTable();
	}

	async fetchItems(page) {
		this.setState({
			dataIsLoaded: false,
		});
		let inv = await this.api.getInvoices(`?${this.buildFilters(page)}`);
		const lastNotificationIds = inv
			?.map((i) => i.lastNotificationId)
			?.filter((val) => val !== null && val !== undefined);

		if (lastNotificationIds?.length) {
			const notifInfo = await this.api.get(
				`notifications`,
				`?$filter=id in (${lastNotificationIds.join()})`
			);

			for (let item of inv) {
				let notif = notifInfo.find((i) => i.id === item.lastNotificationId);

				const toInfo = this.getNotifPayload(notif?.notificationPayload, 'To');
				item.sentInfo =
					toInfo && isArray(toInfo) ? toInfo.join('\n') : toInfo ?? '';
				let sentStatus = '';
				const sentInfo = notif?.sentInfo ? `Sent to: ${notif.sentInfo}` : '';
				if (item.notificationSent) {
					item.sentDateTime = formatDateTime(item.sentDateTimeInUserTimeZone);

					sentStatus = sentInfo;
				}

				if (item.lastNotificationId !== null && !item.notificationSent) {
					item.sentInfo = 'Pending...';
				}

				item.sentStatus = sentStatus;
			}
		}

		const fetchReportFormats = await this.api.get(
			'reportformats/types/invoice'
		);

		const checks = JSON.parse(localStorage.getItem('checks')) || [];
		const totalChecked = checks.length;

		this.setState({
			reportFormats: fetchReportFormats.reportFormats,
			dataIsLoaded: true,
			data: inv,
			checks: checks,
			totalChecked: totalChecked,
		});
	}

	getNotifPayload(payload, key) {
		payload = payload?.replace(/[\n\r\t]/g, '');
		if (payload?.length) {
			try {
				payload = JSON.parse(payload);
				return payload[key];
			} catch (error) {
				// Ignore
				console.error(error);
			}
		}

		return '';
	}

	async handleDocument(e, id) {
		e.preventDefault();

		try {
			let document = await this.api.postJson('publicmappingbatches', {
				publicMappingObjects: [
					{
						objectId: id,
						objectType: 'Invoice',
					},
				],
			});

			window.open(document.link, '_blank').focus();
		} catch (error) {
			displayAlert('danger', 'No document for this invoice.');
		}
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});
		this.fs.setIsActiveSearch(newTableSearch);

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	getClientName() {
		const proj = this.state.project;
		if (proj[0] !== undefined) {
			this.setState({ clientName: proj[0].client });
		}
	}

	handleChecks = (entry) => {
		const { checks, project } = this.state;

		const index = findIndex(checks, (_id) => _id === entry.invnum);
		const exists = index > -1;

		const updatedChecks = exists
			? checks.slice(0, index).concat(checks.slice(index + 1))
			: checks.concat(entry.invnum);

		// Save the state of checkboxes to local storage
		localStorage.setItem('checks', JSON.stringify(updatedChecks));

		this.setState({
			checks: updatedChecks,
			totalChecked: updatedChecks.length,
		});

		const isProjectExist = _.some(project, {
			invnum: entry.invnum,
		});

		if (!exists) {
			if (!isProjectExist) {
				let newObj = {
					id: entry.id,
					proj: entry.proj,
					invnum: entry.invnum,
					due: currencyFormat(entry.amountDue),
					client: entry.client,
					desc: entry.txdesc,
				};
				project.push(newObj);

				this.setState({
					project,
				});
			}
		} else if (project.length) {
			const nproject = filter(project, (p) => p.invnum !== entry.invnum);
			this.setState({
				project: nproject,
			});
		}
		this.getClientName();
	};

	handleSelectAllChecks = (e) =>
		this.setState({
			checks: e.target.checked
				? this.state.data?.map((item) => {
						return item.invnum;
				  })
				: [],
			project: e.target.checked
				? this.state.data?.map((item) => {
						return {
							id: item.id,
							proj: item.proj,
							invnum: item.invnum,
							due: currencyFormat(item.amountDue),
							client: item.client,
							desc: item.txdesc,
						};
				  })
				: [],
			totalChecked: e.target.checked ? this.state.data.length : 0,
		});

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	doTableSearch(data) {}

	buildFilters(currentPage) {
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.ma) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else if (property.type === 'month') {
					if (property.value) filters.push(`${key} eq ${property.value}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		const filtersQuery =
			filters.length > 0 ? `&$filter=${filters.join(' and ')}` : '';
		let queryString = `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}${filtersQuery}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchItems(page);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	getDocName = (styleId) => {
		const getDocument = this.state.reportFormats.filter((row) => {
			return row.id === styleId;
		});

		if (getDocument.length) {
			return getDocument[0].name;
		}

		return '';
	};

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleSearchMonthChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				value: formatFilterMonth(e),
				type: 'month',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			this.changePageHandler
		);
	};

	handleCredit() {
		if (!this.state.checks.length) {
			displayAlertError(MSG.error.noSelected);
			return;
		}

		if (this.state.checks.length > 1) {
			displayAlertError(MSG.error.onlyOneSelected);
			return;
		}

		this.props.navigate(
			generatePath(
				URI.accountsReceivable.clientInvoices.existingreverseCreditInvoice,
				{
					invnum: this.state.checks[0],
				}
			)
		);
	}

	async handleEmailSend() {
		if (!this.state.checks.length) {
			displayAlertError(MSG.error.noSelected);
			return;
		}

		if (uniq(this.state.project.map((p) => p.client)).length > 1) {
			displayAlertError(MSG.error.InvoiceSelectSameClient);
			return;
		}

		try {
			displayAlertLoader(MSG.loading.info);
			const mappingBatches = Object.values(this.state.checks)
				.map((invnum) => {
					const obj = find(this.state.data, { invnum });
					if (obj) {
						return {
							objectId: obj.id,
							objectType: 'Invoice',
						};
					}
				})
				.filter(Boolean);

			const postData = await this.api.postJson('publicmappingbatches', {
				publicMappingObjects: mappingBatches,
			});

			this.setState((prevState) => ({
				showClientInvoiceModal: true,
				clientInvoiceData: {
					link: postData.link,
					token: postData.token,
				},
			}));
			hideAlertLoader();
		} catch (error) {
			displayAlert('danger', 'No document for this invoice.');
		}
	}

	handleUpdateHighlight() {
		const { checks } = this.state;
		if (!checks.length) {
			displayAlertError(MSG.error.noSelected);
			return;
		}

		if (checks.length > 1) {
			displayAlertError(MSG.error.onlyOneSelected);
			return;
		}

		this.props.navigate(
			pathParam(
				URI.accountsReceivable.clientInvoices.existingHighlightedInvoice,
				{ id: checks[0] }
			)
		);
	}

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	renderFilters() {
		return (
			<>
				<ListFilter>
					<ListFilter.Actions md={2} lg={2} xl={2} className="ms-md-auto">
						<ListFilter.Action>
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
						<ListFilter.Action className="ms-auto ms-md-3">
							<Dropdown
								className="d-flex justify-content-end ms-auto"
								align="end"
							>
								<Dropdown.Toggle
									id="dropdown-autoclose-true"
									variant="ivory"
									size="sm"
								>
									Actions
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<SecureDropdownItem
										attributeNo={52}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec3}
										as={Link}
										disabled={!this.state.totalChecked}
										onClick={(e) => {
											e.preventDefault();
											this.handleCredit();
										}}
									>
										<i className="ri-folder-received-line"></i> Credit
									</SecureDropdownItem>
									<Dropdown.Item
										disabled={!this.state.totalChecked}
										onClick={(e) => {
											e.preventDefault();
											this.handleEmailSend();
										}}
									>
										<i className="ri-mail-send-line"></i> Send
									</Dropdown.Item>
									<SecureDropdownItem
										attributeNo={52}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec4}
										disabled={!this.state.totalChecked}
										onClick={(e) => {
											e.preventDefault();
											this.handleUpdateHighlight();
										}}
									>
										<i className="ri-edit-2-fill"></i> Modify
									</SecureDropdownItem>
								</Dropdown.Menu>
							</Dropdown>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		const { data, checks, totalChecked } = this.state;
		return (
			<>
				<Row>
					<Col sm="12">
						{/* Filter */}
						{this.renderFilters()}

						<div className="table-gradient sticky-container">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading">
										<th align="middle" className="mw-80px">
											<div className="d-flex justify-content-center">
												<Form.Check
													inline
													label=""
													name={`inline-check-th-0`}
													type="checkbox"
													data-id={`th-0`}
													id={`inline-check-th-0`}
													checked={totalChecked > 0}
													className={getCheckAllClass(totalChecked, data)}
													onChange={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th className="mw-180px">
											<span data-field="">Sent Status</span>
										</th>
										<th>
											<span data-field="document">Document</span>
										</th>
										<th>
											<span
												className={this.sortClass('invnum')}
												data-field="invnum"
											>
												Inv. No.
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('invdt')}
												data-field="invdt"
											>
												Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('fiscalmonth')}
												data-field="fiscalmonth"
											>
												Fiscal Month
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('proj')}
												data-field="proj"
											>
												Project Code
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('projn')}
												data-field="projn"
											>
												Project Name
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('client')}
												data-field="client"
											>
												Client Code
											</span>
										</th>
										<th className="mw-120px">
											<span
												className={this.sortClass('clientName')}
												data-field="clientName"
											>
												Client Name
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('propnum')}
												data-field="propnum"
											>
												Prop. No.
											</span>
										</th>
										<th className="mw-150px">
											<span
												className={this.sortClass('totalPrice')}
												data-field="totalPrice"
											>
												Total Sale
											</span>
										</th>
										<th className="mw-150px">
											<span
												className={this.sortClass('totalTax')}
												data-field="totalTax"
											>
												Sales Tax
											</span>
										</th>
										<th className="mw-150px">
											<span
												className={this.sortClass('totalDepositApplied')}
												data-field="totalDepositApplied"
											>
												Deposit
												<br />
												Applied
											</span>
										</th>
										<th className="mw-150px">
											<span
												className={this.sortClass('invoicePrice')}
												data-field="invoicePrice"
											>
												Net Invoice
											</span>
										</th>
										<th className="mw-150px">
											<span
												className={this.sortClass('paymentsAmount')}
												data-field="paymentsAmount"
											>
												Payments
											</span>
										</th>
										<th className="mw-150px">
											<span
												className={this.sortClass('totalPayment')}
												data-field="totalPayment"
											>
												Adjustments
											</span>
										</th>
										<th className="mw-180px">
											<span
												className={this.sortClass('amountDue')}
												data-field="amountDue"
											>
												Balance Due
											</span>
										</th>
										<th className="mw-100px">
											<span
												className={this.sortClass('hasreversal')}
												data-field="hasreversal"
											>
												Reversed
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('reverseofinvnum')}
												data-field="reverseofinvnum"
											>
												Reverse of Inv. No.
											</span>
										</th>
										<th>
											<span className="sort active asc" data-field="txdesc">
												Transaction Description
											</span>
										</th>
										<th>
											<span className="sort active asc" data-field="style">
												Style
											</span>
										</th>
										<th>
											<span className="sort active asc" data-field="username">
												Keyed by User
											</span>
										</th>
										<th>
											<span
												className="sort active asc"
												data-field="generateddate"
											>
												Keyed Date
											</span>
										</th>
									</tr>
									<tr
										className={`a-table-search-fields ${
											this.state.showTableSearch ? '' : 'd-none'
										}`}
									>
										<th></th>
										<th></th>
										<th></th>
										<th>
											<Form.Control
												type="text"
												data-field="invnum"
												data-type="number"
												onChange={this.handleSearch}
												style={{
													minWidth: '85px',
												}}
												defaultValue={this.fs.getValue('invnum')}
											/>
										</th>
										<th>
											<DateRangePicker
												style={{
													minWidth: '200px',
												}}
												placement="auto"
												placeholder="Select date"
												format="MM/dd/yyyy"
												defaultValue={this.fs.getValue('invdt')}
												onChange={this.handleDateChange.bind(this, 'invdt')}
												onClean={this.handleDateChange.bind(this, 'invdt')}
												ranges={[
													{
														label: 'today',
														value: [
															startOfDay(new Date()),
															endOfDay(new Date()),
														],
													},
													{
														label: 'yesterday',
														value: [
															startOfDay(addDays(new Date(), -1)),
															endOfDay(addDays(new Date(), -1)),
														],
													},
													{
														label: 'last7Days',
														value: [
															startOfDay(subDays(new Date(), 6)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'Last 30 Days',
														value: [
															startOfDay(subDays(new Date(), 30)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'This month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()),
																	1
																)
															),
															endOfDay(lastDayOfMonth(new Date())),
														],
													},
													{
														label: 'Last month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															),
															endOfDay(
																lastDayOfMonth(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																)
															),
														],
													},
												]}
											/>
										</th>
										<th>
											<DatePicker
												style={{
													minWidth: '200px',
												}}
												placeholder="Select month"
												format="MM/yyyy"
												defaultValue={this.fs.getValue('fiscalmonth')}
												onChange={this.handleSearchMonthChange.bind(
													this,
													'fiscalmonth'
												)}
												onClean={this.handleSearchMonthChange.bind(
													this,
													'fiscalmonth'
												)}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="proj"
												onChange={this.handleSearch}
												style={{
													minWidth: '85px',
												}}
												defaultValue={this.fs.getValue('proj')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="projn"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('projn')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="client"
												onChange={this.handleSearch}
												style={{
													minWidth: '85px',
												}}
												defaultValue={this.fs.getValue('client')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="clientName"
												onChange={this.handleSearch}
												style={{
													minWidth: '150px',
												}}
												defaultValue={this.fs.getValue('clientName')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="propnum"
												onChange={this.handleSearch}
												style={{
													minWidth: '85px',
												}}
												defaultValue={this.fs.getValue('propnum')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="totalPrice"
												data-type="number"
												onChange={this.handleSearch}
												style={{
													minWidth: '95px',
												}}
												defaultValue={this.fs.getValue('totalPrice')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="totalTax"
												data-type="number"
												onChange={this.handleSearch}
												style={{
													minWidth: '95px',
												}}
												defaultValue={this.fs.getValue('totalTax')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="totalDepositApplied"
												data-type="number"
												onChange={this.handleSearch}
												style={{
													minWidth: '95px',
												}}
												defaultValue={this.fs.getValue('totalDepositApplied')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="invoicePrice"
												data-type="number"
												onChange={this.handleSearch}
												style={{
													minWidth: '95px',
												}}
												defaultValue={this.fs.getValue('invoicePrice')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="paymentsAmount"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('paymentsAmount')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="totalPayment"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('totalPayment')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="amountDue"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('amountDue')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="hasreversal"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('hasreversal')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="reverseofinvnum"
												onChange={this.handleSearch}
												style={{
													minWidth: '85px',
												}}
												defaultValue={this.fs.getValue('reverseofinvnum')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="txdesc"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('txdesc')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="username"
												onChange={this.handleSearch}
												style={{
													minWidth: '90px',
												}}
												defaultValue={this.fs.getValue('username')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="username"
												onChange={this.handleSearch}
												style={{
													minWidth: '100px',
												}}
												defaultValue={this.fs.getValue('username')}
											/>
										</th>
										<th>
											<DateRangePicker
												style={{
													minWidth: '200px',
												}}
												placement="auto"
												placeholder="Select date"
												format="MM/dd/yyyy"
												defaultValue={this.fs.getValue('generateddate')}
												onChange={this.handleDateChange.bind(
													this,
													'generateddate'
												)}
												onClean={this.handleDateChange.bind(
													this,
													'generateddate'
												)}
												ranges={[
													{
														label: 'today',
														value: [
															startOfDay(new Date()),
															endOfDay(new Date()),
														],
													},
													{
														label: 'yesterday',
														value: [
															startOfDay(addDays(new Date(), -1)),
															endOfDay(addDays(new Date(), -1)),
														],
													},
													{
														label: 'last7Days',
														value: [
															startOfDay(subDays(new Date(), 6)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'Last 30 Days',
														value: [
															startOfDay(subDays(new Date(), 30)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'This month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()),
																	1
																)
															),
															endOfDay(lastDayOfMonth(new Date())),
														],
													},
													{
														label: 'Last month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															),
															endOfDay(
																lastDayOfMonth(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																)
															),
														],
													},
												]}
											/>
										</th>
									</tr>
								</thead>
								<tbody>
									{!this.state.dataIsLoaded
										? showLoading()
										: this.state.data.length === 0 &&
										  isFiltered(this.state.searchProperties)
										? showEmpty()
										: this.state.data
												.slice(0, this.state.pageSize)
												.map((item, i) => (
													<tr
														key={i}
														data-key={i}
														className={
															includes(checks, item.invnum) ? `active ` : ''
														}
													>
														<td>
															<div className="d-flex justify-content-center">
																<Form.Check
																	label=""
																	name={`group-` + i}
																	type="checkbox"
																	data-id={i}
																	data-invoice-id={item.id}
																	data-proj={item.proj}
																	data-invnum={item.invnum}
																	data-due={item.amountDue}
																	id={`chk-projectview-items-` + i}
																	className="chk-projectview-items-item"
																	defaultValue={i}
																	checked={this.state.checks.includes(
																		item.invnum
																	)}
																	onChange={() => this.handleChecks(item)}
																/>
															</div>
														</td>
														<td>
															<div className="d-flex">
																<strong
																	className={
																		item.notificationSent
																			? `dot-primary`
																			: `dot-primary-red`
																	}
																></strong>
																<div>
																	{item.sentDateTime}
																	{item.sentStatus ? (
																		<React.Fragment>
																			<br />
																			{item.sentStatus}
																		</React.Fragment>
																	) : (
																		<>
																			<br />
																			{item.sentInfo}
																		</>
																	)}
																</div>
															</div>
														</td>
														<td>
															<a
																href=""
																onClick={(e) => {
																	this.handleDocument(e, item.id);
																}}
															>
																PDF
															</a>
														</td>
														<td>
															{' '}
															<Link
																to={pathParam(
																	URI.accountsReceivable.clientInvoices
																		.existingInvoiceView,
																	{
																		invnum: item.invnum,
																	}
																)}
																className="text-charcoal hover-view-icon"
															>
																{item.invnum}
															</Link>
														</td>
														<td>{formatDate(item.invdt)}</td>
														<td>{formatFiscalMonth(item.fiscalmonth)}</td>
														<td>{item.proj}</td>
														<td>{item.projn}</td>
														<td>{item.client}</td>
														<td>{item.clientName}</td>
														<td>{item.propnum}</td>
														<td>{currencyFormat(item.totalPrice)}</td>
														<td>{currencyFormat(item.totalTax)}</td>
														<td>{currencyFormat(item.totalDepositApplied)}</td>
														<td>{currencyFormat(item.invoicePrice)}</td>
														<td>{currencyFormat(item.paymentsAmount)}</td>
														<td>{currencyFormat(item.adjustmentsAmount)}</td>
														<td>{currencyFormat(item.amountDue)}</td>
														<td>{item.hasreversal ? 'Yes' : 'No'}</td>
														<td>{item.reverseofinvnum}</td>
														<td>{item.txdesc}</td>
														<td>{this.getDocName(item.style)}</td>
														<td>{item.username}</td>
														<td>{formatDateTime(item.generateddate)}</td>
													</tr>
												))}
								</tbody>
							</Table>
						</div>
						{this.state.data.length > 0 && (
							<Pagination
								onPageSizeChanged={this.onPageSizeChanged}
								onPageChanged={this.onPageChanged}
								hasPreviousPage={this.state.page > 1}
								hasNextPage={this.state.data.length > this.state.pageSize}
								page={this.state.page}
								pageSize={this.state.pageSize}
							/>
						)}
					</Col>
				</Row>
				{this.state.showClientInvoiceModal && (
					<ClientInvoiceModal
						selectedProjects={this.state.project}
						clientInvoiceData={this.state.clientInvoiceData}
						show={this.state.showClientInvoiceModal}
						hideModal={(isReload) =>
							this.setState(
								(prev) => {
									prev.showClientInvoiceModal = false;
									prev.clientInvoiceData = {};
								},
								() => (isReload ? this.fetchItems(this.state.page) : null)
							)
						}
						clientName={this.state.clientName}
					/>
				)}
			</>
		);
	}
}

export default WithRouter(ClientInvoicesListExisting);
