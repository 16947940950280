import React from 'react';
import { Container, Col, Row, Button, Form, Modal } from 'react-bootstrap';

class Modals extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showLgModal: false,
			showSmModal: false,
		};

		// This binding is necessary to make `this` work in the callback
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick = (e) => {
		if (e.target.getAttribute('data-target') === 'sm') {
			this.setState((prev) => {
				prev.showSmModal = true;
				return prev;
			});
		} else {
			this.setState((prev) => (prev.showLgModal = true));
		}
	};

	hide() {
		this.setState({
			showLgModal: false,
			showSmModal: false,
		});
	}

	render() {
		return (
			<>
				<div className="bg-secondary-grey pt-5 pb-4 mt-5">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Modals</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4">
					<Row>
						<Col>
							<Button
								onClick={this.handleClick}
								className="me-2"
								data-target="sm"
							>
								Request Email
							</Button>
							<Button onClick={this.handleClick} data-target="lg">
								Request Retainer
							</Button>

							<Modal
								size="lg"
								show={this.state.showSmModal}
								onHide={() => this.hide()}
								aria-labelledby="example-modal-sizes-title-lg"
								className="right a-modal"
								backdrop="static"
							>
								<Modal.Body>
									<div
										className="py-5 mt-5 mx-auto"
										style={{ maxWidth: '400px' }}
									>
										<h5 className="fw-bold">Email</h5>
										<div className="form-fields px-4 mt-4">
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label
													column
													sm="3"
													className="fw-bold text-end px-0"
												>
													To:
												</Form.Label>
												<Col sm="9">
													<Form.Control type="text" placeholder="" size="sm" />
												</Col>
											</Form.Group>
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label
													column
													sm="3"
													className="fw-bold text-end px-0"
												>
													Cc:
												</Form.Label>
												<Col sm="9">
													<Form.Control type="text" placeholder="" size="sm" />
												</Col>
											</Form.Group>
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label
													column
													sm="3"
													className="fw-bold text-end px-0"
												>
													Bcc:
												</Form.Label>
												<Col sm="9">
													<Form.Control type="text" placeholder="" size="sm" />
												</Col>
											</Form.Group>
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label
													column
													sm="3"
													className="fw-bold text-end px-0"
												>
													Subject:
												</Form.Label>
												<Col sm="9">
													<Form.Control type="text" placeholder="" size="sm" />
												</Col>
											</Form.Group>
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label
													column
													sm="3"
													className="fw-bold text-end px-0"
												>
													Attachment:
												</Form.Label>
												<Col sm="9">
													<Form.Control type="file" placeholder="" size="sm" />
												</Col>
											</Form.Group>
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label
													column
													sm="3"
													className="fw-bold text-end px-0"
												>
													Message:
												</Form.Label>
												<Col sm="9">
													<Form.Control
														as="textarea"
														rows={3}
														placeholder=""
														size="sm"
													/>
												</Col>
											</Form.Group>
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label
													column
													sm="3"
													className="fw-bold text-end px-0"
												></Form.Label>
												<Col sm="9">
													<Form.Check
														label="Include Signature from User Settings"
														name="inline-md-radio-4"
														type="checkbox"
														id={`inline-md-radio-4`}
													/>
												</Col>
											</Form.Group>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer className="bg-ivory py-4 px-5">
									<Button
										variant="primary"
										onClick={this.handleClick}
										className="w-100 mb-1"
									>
										Send
									</Button>
									<Button
										variant="trans-light border-secondary-ash"
										onClick={() => this.hide()}
										className="w-100"
									>
										Cancel
									</Button>
								</Modal.Footer>
							</Modal>

							<Modal
								size="lg"
								show={this.state.showLgModal}
								onHide={() => this.hide()}
								aria-labelledby="example-modal-sizes-title-lg"
								className="right a-modal"
								backdrop="static"
							>
								<Modal.Body>
									<div
										className="py-5 mt-5 mx-auto"
										style={{ maxWidth: '400px' }}
									>
										<h5 className="fw-bold">Request Retainer</h5>
										<div className="form-fields px-4 mt-4">
											<Form.Group
												as={Row}
												className="mb-3"
												controlId="formPlaintextEmail"
											>
												<Form.Label column sm="5" className="fw-bold text-end">
													Amount to Request
												</Form.Label>
												<Col sm="7">
													<div className="form-group-extra reversed">
														<Form.Control
															type="text"
															placeholder="0.00"
															size="sm"
														/>
														<span>$</span>
													</div>
												</Col>
											</Form.Group>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer className="bg-ivory py-4 px-5">
									<Button
										variant="primary"
										onClick={this.handleClick}
										className="w-100 mb-1"
									>
										Continue to Email
									</Button>
									<Button
										variant="trans-light border-secondary-ash"
										onClick={() => this.hide()}
										className="w-100"
									>
										Cancel
									</Button>
								</Modal.Footer>
							</Modal>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Modals;
