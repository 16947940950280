import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import TreeView from '../components/TreeView';

class ReportingTreeView extends React.Component {
	render() {
		const data = [
			{
				name: 'Tree Item',
				children: [
					{
						name: 'Tree Item',
					},
				],
			},
			{
				name: 'Tree Item',
				children: [
					{
						name: 'Tree Item',
						children: [
							{
								name: 'Tree Item',
								children: [
									{
										name: 'Tree Item',
									},
								],
							},
						],
					},
				],
			},
			{
				name: 'Tree Item',
				isActive: true,
				children: [
					{
						name: 'Tree Item',
						isActive: true,
						children: [
							{
								name: 'Tree Item',
							},
							{
								name: 'Tree Item',
							},
							{
								name: 'Tree Item',
							},
						],
					},
					{
						name: 'Tree Item',
						isActive: true,
						children: [
							{
								name: 'Tree Item',
							},
							{
								name: 'Tree Item',
							},
							{
								name: 'Tree Item',
							},
						],
					},
				],
			},
			{
				name: 'Tree Item',
				children: [
					{
						name: 'Tree Item',
					},
				],
			},
			{
				name: 'Tree Item',
				children: [
					{
						name: 'Tree Item',
					},
				],
			},
			{
				name: 'Tree Item',
				children: [
					{
						name: 'Tree Item',
					},
				],
			},
		];

		return (
			<>
				<div className="bg-secondary-grey pt-5 pb-4">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Reporting</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4">
					<Row>
						<Col className="">
							<div className="border p-4">
								<TreeView data={data}></TreeView>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default ReportingTreeView;
