import { CompNumericPercentType } from 'legacy/lib/api/types/CompPercentType';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import MarkupInputs from './MarkupInputs';
import FeeInputs from './FeeInputs';
import DiscountInputs from './DiscountInputs';
import { TWhatChangedComponent } from 'legacy/lib/api/types/WhatChangedComponent';

const CostPricingSection = ({
	disableInputs,
	handleRecalculate,
}: {
	disableInputs: boolean;
	handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void;
}) => {
	const methods = useFormContext();
	const compType = methods.watch('usedisc');
	const useTerms = methods.watch('useterms');

	return (
		<div className="tw-max-w-full tw-p-4">
			<div className="tw-flex tw-justify-between tw-items-center tw-p-5 tw-bg-[#EEEDE9]">
				<p className="tw-text-base tw-font-bold tw-m-0">Cost / Pricing</p>
			</div>
			{compType === CompNumericPercentType.Markup && !useTerms && (
				<MarkupInputs
					disableInputs={disableInputs}
					handleRecalculate={handleRecalculate}
				/>
			)}
			{compType === CompNumericPercentType.Fee && (
				<FeeInputs
					disableInputs={disableInputs}
					handleRecalculate={handleRecalculate}
				/>
			)}
			{(compType === CompNumericPercentType.Discount || useTerms) && (
				<DiscountInputs
					disableInputs={disableInputs}
					handleRecalculate={handleRecalculate}
				/>
			)}
		</div>
	);
};
CostPricingSection.displayName = 'CostPricingSection';
export default CostPricingSection;
