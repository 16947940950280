import React from 'react';
import { TFinanceChargeAccount } from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeTypes';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

type TFinanceChargeDeleteProps = {
	showModal: boolean;
	onToggleModal: () => void;
	selectedEntryAccount: TFinanceChargeAccount | null;
	onDelete: (account: TFinanceChargeAccount) => void;
};

export const FinanceChargeDelete = ({
	showModal,
	onToggleModal,
	selectedEntryAccount,
	onDelete,
}: TFinanceChargeDeleteProps) => {
	return (
		<>
			<ConfirmationModal
				title="Are you sure that you want to delete this entry?"
				show={showModal}
				toggleModal={onToggleModal}
				confirmAction={() => {
					if (selectedEntryAccount) {
						onDelete(selectedEntryAccount);
					}

					onToggleModal();
				}}
				labelCancel="No"
				labelOK="Yes"
			/>
		</>
	);
};

FinanceChargeDelete.displayName = 'FinanceChargeDelete';
