import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { WithRouter } from 'legacy/helpers/Router';
import Header from '../../../components/Header';
import {
	createStockItem,
	deleteStockItem,
	duplicateStockItem,
	fetchStockItems,
	fetchThumbnail,
} from '../StockItemsService';
import { StockItemsListToolbar } from './StockItemsListToolbar';
import { StockItemsListContent } from './StockItemsListContent';
import { debounce } from 'lodash';
import {
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
	hideAlertLoader,
} from 'legacy/utilities/Response';
import MSG from 'legacy/defaults/Message';
import HandleDeleteWorker from 'legacy/utilities/DeleteWorker';
import { StockItemsDuplicateModal } from './StockItemsDuplicateModal';
import { IResponses, StockItemsDeleteModal } from './StockItemsDeleteModal';
import { StockItemsAddModal } from './StockItemsAddModal';
import URI from 'legacy/defaults/RoutesDefault';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { pathParam } from 'legacy/utilities/Router';
import { TStockItem } from 'legacy/lib/api/types/StockItem';

export type TViewType = 'table' | 'grid';

export const StockItemsList: FC<{ navigate: NavigateFunction }> = ({
	navigate,
}) => {
	const [viewType, setViewType] = useState<TViewType>('table');
	const [stockItems, setStockItems] = useState<TStockItem[]>([]);
	const [isSearching, setSearching] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({ size: 20, page: 1 });
	const [gridColumns, setGridColumns] = useState(4);
	const [thumbnails, setThumbnails] = useState<Map<number, any>>(new Map());
	const [includeInactive, setIncludeInactive] = useState(false);
	const [searchParams, setSearchParams] = useState<Map<string, any>>(new Map());
	const [sort, setSort] = useState<{ key: string; direction: string }>({
		key: 'stockNo',
		direction: 'asc',
	});
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [deleteResponses, setDeleteResponses] = useState<IResponses>();
	const [presentingDuplicateModal, setPresentingDuplicateModal] =
		useState(false);
	const [presentingDeleteModal, setPresentingDeleteModal] = useState(false);
	const [activeItem, setActiveItem] = useState<TStockItem | undefined>(
		undefined
	);
	const [presentingAddModal, setPresentingAddModal] = useState(false);

	const Columns = {
		DEFAULT: 4,
		MIN: 3,
		MAX: 6,
	};
	const zoomLevels: any = {
		0: Columns.MAX,
		1: Columns.DEFAULT,
		2: Columns.MIN,
	};

	useEffect(() => {
		const getInitialStockItems = async () => {
			setLoading(true);
			await getStockItems();
			setLoading(false);
		};

		getInitialStockItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		debouncedGetStockItems();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams, sort]);

	const handleSwitchView = (viewType: TViewType) => {
		setViewType(viewType);
	};
	const toggleSearch = () => {
		const _isSearching = !isSearching;
		setSearching(_isSearching);

		if (!_isSearching) {
			setSearchParams(new Map());
		}
	};
	const handleZoomChange = (zoom: number) => {
		if (!zoomLevels[zoom]) {
			return;
		}

		setGridColumns(zoomLevels[zoom]);
	};
	const buildFilter = (
		page: number,
		size: number,
		includeInactive: boolean
	) => {
		const filters: string[] = [];

		filters.push(`inactive eq ${includeInactive ? 'true' : 'false'}`);

		searchParams.forEach((value, key) => {
			const param = value;
			if (param.type === 'number' && param.value.length) {
				filters.push(
					`(contains(cast(${key}, 'Edm.String'),'${encodeURI(param.value)}'))`
				);
			} else if (param.type === 'range') {
				if (param.min) filters.push(`${key} ge ${param.min}`);
				if (param.max) filters.push(`${key} le ${param.max}`);
			} else if (param.type === undefined) {
				filters.push(
					`(contains(cast(${key}, 'Edm.String'),'${encodeURI(param.value)}'))`
				);
			}
		});

		let filter = filters.length > 0 ? `$filter=${filters.join(' and ')}` : '';

		if (sort) {
			filter += `&$orderby=${sort.key} ${sort.direction}`;
		}

		filter += `&$top=${size + 1}&$skip=${(page - 1) * size}`;

		return filter.length ? `?${filter}` : '';
	};
	const getStockItems = async (
		page = 1,
		size = 20,
		includeInactive = false
	) => {
		const stockItems = await fetchStockItems(
			buildFilter(page, size, includeInactive)
		);
		setStockItems(stockItems);
		getThumbnails(stockItems);
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedGetStockItems = useCallback(
		debounce(() => {
			getStockItems(pagination.page, pagination.size, includeInactive);
		}, 500),
		[searchParams, sort]
	);
	const getThumbnails = async (stockItems: TStockItem[]) => {
		const filteredStockItems = stockItems.filter(
			(item) => item.primaryImageId !== null && !thumbnails.has(item.id)
		);

		const newThumbnails = new Map(thumbnails);

		for (const item of filteredStockItems) {
			const image = await fetchThumbnail(item.primaryImageId);
			newThumbnails.set(item.id, image);

			setThumbnails(
				new Map([
					...Array.from(thumbnails.entries()),
					...Array.from(newThumbnails.entries()),
				])
			);
		}
	};
	const handlePaginationChange = async (pagination: {
		size: number;
		page: number;
	}) => {
		setLoading(true);
		setPagination(pagination);
		await getStockItems(pagination.page, pagination.size, includeInactive);
		setLoading(false);
	};
	const handleToggleInactive = (includeInactive: boolean) => {
		setIncludeInactive(includeInactive);
		getStockItems(pagination.page, pagination.size, includeInactive);
	};
	const handleSearch = (key: string, value: string, type?: string) => {
		const newSearchParams = new Map(searchParams.entries());

		if (type === 'range') {
			const newKey = key.split('-')[0];
			let min = searchParams.get(newKey)?.min;
			let max = searchParams.get(newKey)?.max;
			if (key.includes('min')) {
				min = value;
			} else {
				max = value;
			}
			newSearchParams.set(newKey, {
				value: undefined,
				type: type,
				min: min,
				max: max,
			});
		} else {
			newSearchParams.set(key, { value: value, type: type });
		}
		setSearchParams(newSearchParams);
	};
	const handleSort = (sort: { key: string; direction: string }) => {
		setSort(sort);
	};
	const handleCheck = (ids: number[]) => {
		setSelectedIds(ids);
	};
	const handleTestDeleteObjects = async (item?: TStockItem) => {
		const workers = [];

		displayAlertLoader(MSG.loading.prepare.item);

		let ids = selectedIds;
		if (item) {
			ids = [item.id];
		}

		try {
			const items = await fetchStockItems(`?$filter=id in (${ids.join(',')})`);

			for (const item of items) {
				workers.push(await deleteStockItem(item.stockno));
			}

			if (workers) {
				HandleDeleteWorker(
					workers,
					{
						moduleSingular: 'Stock Item',
						modulePlural: 'Stock Items',
						reference: 'Projects',
						objectType: 'objInventoryItem',
					},
					false,
					(modalState: any) => {
						setPresentingDeleteModal(true);
						setDeleteResponses(modalState.deleteResponses);
					}
				);
			}
		} catch (error: any) {
			handleError(error);
		}
	};
	const handleRefreshAfterDelete = () => {
		setActiveItem(undefined);
		setSelectedIds([]);
		setDeleteResponses(undefined);
		setPresentingDeleteModal(false);
		getStockItems(pagination.page, pagination.size, includeInactive);
	};
	const handleError = (error: any) => {
		const message = error.backedError?.response
			? error.backedError.response.data.userError
			: getErrorMessage(error);
		displayAlert('danger', message);
	};
	const handlePresentDuplicate = (item?: TStockItem) => {
		setActiveItem(item);
		setPresentingDuplicateModal(true);
	};
	const handleConfirmDuplicate = async (stockNo: string) => {
		await handleDuplicate(stockNo);
		setPresentingDuplicateModal(false);
	};
	const handleDuplicate = async (stockNo: string) => {
		displayAlertLoader(MSG.loading.prepare.item);

		try {
			let ids = selectedIds;
			if (activeItem) {
				ids = [activeItem.id];
			}

			const items = await fetchStockItems(`?$filter=id in (${ids.join(',')})`);

			for (const item of items) {
				await duplicateStockItem(item, stockNo);
			}

			hideAlertLoader();
			displayAlert('success', 'Succesfully duplicated selected item(s)');
			setActiveItem(undefined);
			getStockItems(pagination.page, pagination.size, includeInactive);
		} catch (error: any) {
			handleError(error);
		}
	};
	const handleConfirmAdd = async (data: Map<string, any>) => {
		try {
			const itemName = data.get('itemName');
			if (itemName) {
				data.set('desc', itemName.trim());
			}

			const stockItem: TStockItem = await createStockItem(
				Object.fromEntries(data)
			);

			setPresentingAddModal(false);
			displayAlert('success', 'Successfully created a new Stock Item');

			navigate(
				pathParam(URI.stockItem.edit, {
					id: stockItem.id,
				}),
				{
					state: { id: stockItem.id },
				}
			);
		} catch (error: any) {
			handleError(error);
		}
	};
	const handleCancelAdd = () => {
		setPresentingAddModal(false);
	};
	const handleAdd = () => {
		setPresentingAddModal(true);
	};

	return (
		<>
			<StockItemsListHeader onAdd={handleAdd} />
			<div className="content-padding min-height">
				<Container fluid>
					<StockItemsListToolbar
						viewType={viewType}
						searchAvailable={viewType == 'table'}
						actionsAvailable={selectedIds.length > 0}
						duplicateAvailable={selectedIds.length == 1}
						onSwitchView={handleSwitchView}
						onDelete={handleTestDeleteObjects}
						onDuplicate={handlePresentDuplicate}
						onSearch={toggleSearch}
						onZoomChange={handleZoomChange}
						onToggleInactive={handleToggleInactive}
					/>
					<StockItemsListContent
						viewType={viewType}
						data={stockItems}
						thumbnails={thumbnails}
						sort={sort}
						gridColumns={gridColumns}
						selectedIds={selectedIds}
						isSearching={isSearching}
						isLoading={isLoading}
						onPaginationChange={handlePaginationChange}
						onDelete={handleTestDeleteObjects}
						onDuplicate={handlePresentDuplicate}
						onSearch={handleSearch}
						onSort={handleSort}
						onCheck={handleCheck}
					/>
				</Container>
			</div>

			{presentingDeleteModal && deleteResponses && (
				<StockItemsDeleteModal
					refreshData={handleRefreshAfterDelete}
					hideModal={() => {
						setDeleteResponses(undefined);
						setPresentingDeleteModal(false);
					}}
					responses={deleteResponses}
				/>
			)}

			{presentingDuplicateModal && (
				<StockItemsDuplicateModal
					onConfirm={handleConfirmDuplicate}
					onCancel={() => {
						setPresentingDuplicateModal(false);
					}}
				/>
			)}

			{presentingAddModal && (
				<StockItemsAddModal
					onConfirm={handleConfirmAdd}
					onCancel={handleCancelAdd}
				/>
			)}
		</>
	);
};
StockItemsList.displayName = 'StockItemsList';

export default WithRouter(StockItemsList);

const StockItemsListHeader: FC<{ onAdd: () => void }> = ({ onAdd }) => (
	<>
		<Header>
			<Header.Title>Stock Items</Header.Title>
			<Header.Actions list={true}>
				<li>
					<Button
						className="btn-icon text-secondary-green btn btn-light btn-lg"
						onClick={onAdd}
					>
						<i className="ri-add-line ri-lg"></i> Add
					</Button>
				</li>
			</Header.Actions>
		</Header>
	</>
);
StockItemsListHeader.displayName = 'StockItemsListHeader';
