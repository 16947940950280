import React from 'react';
import usePaginatedQuery from 'legacy/lib/api/hooks/usePaginatedQuery';
import BaseSelect from 'legacy/app/components/Selects/BaseSelect';
import { TStockItem } from 'legacy/lib/api/types/StockItem';
import { getInventoryStocks } from 'api/resources/inventoryStocks';

interface IInventorySelectProps {
	handleSelect: (item: TStockItem | null) => void;
}

export const InventorySelect = ({ handleSelect }: IInventorySelectProps) => {
	const defaultFilter = '?$filter=inactive eq false&$orderby=stockno desc';

	const {
		data: inventoryItems,
		updateFilter,
		loadMore,
		hasMore,
		isFetching,
	} = usePaginatedQuery<TStockItem>(async (filter: string) => {
		return await getInventoryStocks(filter);
	}, defaultFilter);

	const optionFormatter = (item: TStockItem) => ({
		value: item.stockno,
		label: `${item.itemName} [${item.stockno}]`,
		data: item,
	});

	const handleInputFilter = (input: string) => {
		return `?$filter=(inactive eq false) and (contains(itemName,'${input}') or contains(stockno,'${input}'))`;
	};

	const handleSelectedOption = (
		option: { value: string; data: TStockItem } | null
	) => {
		return option?.data || null;
	};

	return (
		<BaseSelect<TStockItem>
			handleSelect={handleSelect}
			placeholder="Select Stock No."
			results={inventoryItems || []}
			updateFilter={updateFilter}
			loadMore={loadMore}
			handleInputFilter={handleInputFilter}
			handleSelectedOption={handleSelectedOption}
			optionFormatter={optionFormatter}
			hasMore={hasMore}
			isFetching={isFetching}
			defaultSelected={null}
		/>
	);
};

InventorySelect.displayName = 'InventorySelect';
