import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Button,
	Container,
	Form,
	Row,
	Col,
	Table,
	Modal,
	Dropdown,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import { fakeData, fakeReactSelectOptions } from '../../../factory/fakeFactory';
import { ActionBarVertical } from '../../../components/ActionBar';
import ListFilter from '../../../components/ListFilter';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import DatePicker from 'react-datepicker';
import { InlineFormGroupField } from '../../../components/Form';
import Select from 'react-select';

class VendorInvoiceEditdExpense extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			dates: [
				{
					startDate: '',
					minDate: new Date(),
				},
				{
					startDate: '',
					minDate: new Date(),
				},
				{
					startDate: '',
					minDate: new Date(),
				},
			],
			activeDateIndex: 0,
			data: fakeData(2),
			checks: {},
			dataIsLoaded: false,
		};
	}

	componentInit() {
		this.setTitle('Add Invoice | Accounts Payable').setFormAction(true);

		// Set API calls.
		this.setApiURL('/data/products.json')
			.setApiParams({ responseKey: 'response' })
			.enableSearchAndSortTable()
			.fetchData();
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			prev.showTableSearch = !prev.showTableSearch;
			return prev;
		});
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			prevState.checks[indx] = e.target.checked;
			return prevState;
		});
	};

	handleDateChange = (date) => {
		this.setState(
			(prevState) =>
				(prevState.dates[prevState.activeDateIndex].startDate = date)
		);
	};

	handleSetActiveDateIndx = (id) => {
		this.setState((prevState) => (prevState.activeDateIndex = id));
	};

	renderHeaderTitle() {
		let title = 'Recurring Operating Expense';

		return title;
	}

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.accountsPayable.vendorInvoice.listNew}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsPayable.vendorInvoice.listNew,
							}}
							linkAs={Link}
						>
							Accounts Payable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsPayable.vendorInvoice.listNew,
							}}
							linkAs={Link}
						>
							Vendor Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item>{this.renderHeaderTitle()}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.renderHeaderTitle()}</HeaderLight.Title>

					<div>
						{/* Submit Button */}

						<Button variant="primary">Save</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderListFilter() {
		return (
			<ListFilter>
				<ListFilter.Fields sm={12} lg={11} className="full-width">
					<ListFilter.Field>
						<Form.Label className="col-sm-3">Pay with</Form.Label>
						<Form.Select size="sm">
							<option>Check</option>
							<option>Wire Transfer</option>
						</Form.Select>
					</ListFilter.Field>
					<ListFilter.Field>
						<Form.Label className="mb-0 col-sm-3">Date</Form.Label>
						<div className="react-select-header">
							<DatePicker
								selected={this.state.dates[0].startDate}
								minDate={this.state.dates[0].minDate}
								showMonthDropdown="true"
								showYearDropdown="true"
								onChange={this.handleDateChange}
								onFocus={() => {
									this.handleSetActiveDateIndx(0);
								}}
								className="form-control"
								placeholderText="Select"
							/>
						</div>
					</ListFilter.Field>
					<ListFilter.Field>
						<Form.Label className="mb-0 col-sm-3">Due Date</Form.Label>
						<div className="react-select-header">
							<DatePicker
								selected={this.state.dates[0].startDate}
								minDate={this.state.dates[0].minDate}
								showMonthDropdown="true"
								showYearDropdown="true"
								onChange={this.handleDateChange}
								onFocus={() => {
									this.handleSetActiveDateIndx(0);
								}}
								className="form-control"
								placeholderText="Select"
							/>
						</div>
					</ListFilter.Field>
				</ListFilter.Fields>
			</ListFilter>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar">
					<Container fluid className="px-0">
						<Row>
							<Col sm="12">
								<Row xs={1} lg={4}>
									<Col className="mb-3 mb-lg-0">
										<Row className="align-items-center mb-3">
											<Col lg={3} className="mb-2 mb-lg-0">
												<Form.Label className="mb-0">Vendor</Form.Label>
											</Col>
											<Col>
												<Select
													options={fakeReactSelectOptions()}
													className="react-select"
													placeholder="Please type"
												/>
											</Col>
										</Row>
									</Col>

									<Col className="mb-3 mb-lg-0">
										<Row className="align-items-center mb-3">
											<Col lg={3} className="mb-2 mb-lg-0">
												<Form.Label className="mb-0">Inv. #</Form.Label>
											</Col>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
										</Row>
									</Col>
								</Row>

								<Row xs={1} lg={4}>
									<Col className="mb-3 mb-lg-0">
										<Row className="align-items-center mb-3">
											<Col lg={3} className="mb-2 mb-lg-0">
												<Form.Label className="mb-0">Trans. Desc.</Form.Label>
											</Col>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
										</Row>
									</Col>
								</Row>

								<div className="table-gradient">
									<Table striped responsive className="a-table">
										<thead>
											<tr key="0" className="a-table-heading">
												<th></th>
												<th>
													<span className="sort active asc">Account No.</span>
												</th>
												<th>
													<span className="sort">Account Name</span>
												</th>
												<th>
													<span className="sort">Amount</span>
												</th>
											</tr>
											<tr
												className={`a-table-search-fields ${
													this.state.showTableSearch ? '' : 'd-none'
												}`}
											>
												<th></th>
												<th>
													<Form.Control
														type="text"
														data-field="projectCodeNo"
													/>
												</th>
												<th>
													<Form.Control type="text" data-field="clientName" />
												</th>
												<th>
													<Form.Control type="text" data-field="clientName" />
												</th>
											</tr>
										</thead>
										<tbody>
											{this.state.data.map((item, i) => (
												<tr
													key={i}
													className={this.state.checks[i] ? `active` : ''}
												>
													<td>
														<Form.Check
															inline
															label=""
															name={`group-` + i}
															type="checkbox"
															data-id={i}
															id={`inline-radio-` + i}
															onChange={this.handleChecks}
														/>
													</td>
													<td>Lorem ipsum name</td>
													<td>000001</td>
													<td>0000023</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>

								<Row xs={1} lg={4}>
									<Col className="mb-3 mb-lg-0">
										<Row className="align-items-center mb-3">
											<Col lg={3} className="mb-2 mb-lg-0">
												<Form.Label className="mb-0">Discount</Form.Label>
											</Col>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
										</Row>
									</Col>

									<Col className="mb-3 mb-lg-0">
										<Row className="align-items-center mb-3">
											<Col lg={3} className="mb-2 mb-lg-0">
												<Form.Label className="mb-0">Amount Due</Form.Label>
											</Col>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
										</Row>
									</Col>
								</Row>

								<Row xs={1} lg={4}>
									<Col className="mb-3 mb-lg-0">
										<Row className="align-items-center mb-3">
											<Col lg={3} className="mb-2 mb-lg-0">
												<Form.Label className="mb-0">Days To Take</Form.Label>
											</Col>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(VendorInvoiceEditdExpense);
