import React from 'react';

interface ILoadingPlaceholder {
	placeholderClass?: string;
	rows?: number;
	color?: string;
}

const LoadingPlaceholder = ({
	placeholderClass,
	rows = 1,
	color = 'tw-bg-green-300',
}: ILoadingPlaceholder) => (
	<div className={placeholderClass}>
		<div
			className={`tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-32 tw-bg-${color} tw-rounded mb-2`}
		/>
		<div className="tw-p-2.5">
			{Array.from({ length: rows }).map((_, index) => (
				<div
					className={`tw-h-4 tw-bg-${color} tw-rounded mb-2`}
					key={`placeholder-tem-${index}`}
				/>
			))}
		</div>
	</div>
);

LoadingPlaceholder.displayName = 'LoadingPlaceholder';

export default LoadingPlaceholder;
