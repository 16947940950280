import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import { renderSecureHideContent } from 'legacy/utilities/modules/SecureContent';
import React from 'react';

export type TTabOption =
	| 'info'
	| 'order status'
	| 'special instructions'
	| 'documents';

type TTabsProps = {
	toggleTab: (tab: TTabOption) => void;
	activeTab: TTabOption;
	numberOfDocuments: number;
};

const ComponentTabs = ({
	toggleTab,
	activeTab,
	numberOfDocuments,
}: TTabsProps): JSX.Element => {
	const options: TTabOption[] = [
		'info',
		'special instructions',
		'order status',
		'documents',
	];

	return (
		<ul className="tw-flex tw-text-sm tw-pl-10 tw-pb-6 tw-gap-4 tw-items-center tw-p-0 tw-pt-4 tw-list-none bg-ivory">
			{options.map((option) => (
				<React.Fragment key={option}>
					{option === 'order status' ? (
						renderSecureHideContent(
							<BaseButton
								buttonClass={`tw-capitalize tw-cursor-pointer tw-px-10 tw-py-2 tw-rounded-lg ${
									activeTab === option
										? 'tw-bg-[#211F211A]'
										: 'tw-bg-[transparent]'
								}`}
								onClick={() => toggleTab(option)}
								text={option}
							/>
						)(18, SECURITY_ATTRIBUTE_TYPES.DenySpec3)
					) : (
						<li>
							<BaseButton
								buttonClass={`tw-capitalize tw-cursor-pointer tw-px-10 tw-py-2 tw-rounded-lg ${
									activeTab === option
										? 'tw-bg-[#211F211A]'
										: 'tw-bg-[transparent]'
								}`}
								onClick={() => toggleTab(option)}
								text={option}
							/>
							{option === 'documents' && numberOfDocuments > 0 && (
								<i className="ri-attachment-2 ms-1"></i>
							)}
						</li>
					)}
				</React.Fragment>
			))}
		</ul>
	);
};
ComponentTabs.displayName = 'ComponentTabs';

export default ComponentTabs;
