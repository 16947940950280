import React from 'react';
import { Container, Col, Row, Image, Form, Dropdown } from 'react-bootstrap';

import solidImage from '../../assets/images/solid-color-image.png';

class Clipper extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			checks: {},
		};

		// This binding is necessary to make `this` work in the callback
		this.handleChecks = this.handleChecks.bind(this);
	}

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			prevState.checks[indx] = e.target.checked;

			return prevState;
		});
	};

	render() {
		return (
			<>
				<div className="bg-secondary-grey pt-5 pb-4 mt-5">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Clipper</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4 border roundedx-4 py-5">
					<Container
						className="a-clipper-instructions py-4 mx-auto"
						style={{ maxWidth: '1200px' }}
					>
						<Row className="justify-content-center">
							<Col sm="12" lg="3">
								<Image
									src={solidImage}
									alt="image"
									className="mx-auto d-block mb-4"
								></Image>
								<div className="text-center">
									<h6 className="fsx-20">1. Install browser extension</h6>
									<p className="mb-0">
										<a href="/">Click here</a> to download the clipper extension
										and install it.
									</p>
								</div>
							</Col>
							<Col
								sm="12"
								lg="1"
								className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
							>
								<div className="d-none d-lg-block">
									<i className="ri-arrow-right-line"></i>
								</div>
								<div className="d-block d-lg-none">
									<i className="ri-arrow-down-line"></i>
								</div>
							</Col>
							<Col sm="12" lg="3">
								<Image
									src={solidImage}
									alt="image"
									className="mx-auto d-block mb-4"
								></Image>
								<div className="text-center">
									<h6 className="fsx-20">2. Open any website</h6>
									<p className="mb-0">
										Go to a place where you want to clip items for your project.
									</p>
								</div>
							</Col>
							<Col
								sm="12"
								lg="1"
								className="a-clipper-arrow text-center fsx-24 text-secondary-ash"
							>
								<div className="d-none d-lg-block">
									<i className="ri-arrow-right-line"></i>
								</div>
								<div className="d-block d-lg-none">
									<i className="ri-arrow-down-line"></i>
								</div>
							</Col>
							<Col sm="12" lg="3">
								<Image
									src={solidImage}
									alt="image"
									className="mx-auto d-block mb-4"
								></Image>
								<div className="text-center">
									<h6 className="fsx-20">3. Source products</h6>
									<p className="mb-0">
										Fill in the information you need and save items for your
										project.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</Container>

				<Container className="mt-4 py-5">
					<Container className="a-clipper-media py-4 px-0">
						<Row className="justify-content-center">
							{Array(8)
								.fill(1)
								.map((el, i) => (
									<Col
										sm="6"
										md="6"
										lg="3"
										className="a-clipper-media-item"
										key={i}
									>
										<div
											className={
												`a-clipper-media-item-handle border ` +
												(this.state.checks[i] ? `active` : '')
											}
										>
											<div className="d-flex justify-content-between">
												<Form.Check
													inline
													label=""
													name={`a-clipper-chk-` + i}
													type="checkbox"
													id={`a-clipper-chk-` + i}
													data-id={i}
													onChange={this.handleChecks}
												/>
												<Dropdown>
													<Dropdown.Toggle variant="light">
														<i className="ri-more-2-fill ri-lg"></i>
													</Dropdown.Toggle>

													<Dropdown.Menu align="end">
														<Dropdown.Item href="#/action-1">
															Edit
														</Dropdown.Item>
														<Dropdown.Item href="#/action-2">
															Delete
														</Dropdown.Item>
														<Dropdown.Item href="#/action-3">
															<i className="ri-share-fill ri-sm"></i> Share
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<Image
												src={solidImage}
												alt="image"
												className="mx-auto d-block mb-3"
											></Image>
											<div className="text-center">
												<h6 className="fs-lg">Location Name {i + 1}</h6>
											</div>
										</div>
									</Col>
								))}
						</Row>
					</Container>
				</Container>
			</>
		);
	}
}

export default Clipper;
