import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import React from 'react';

const ComponentsDeleteConfirmationModal = ({
	show,
	toggleModal,
	confirmAction,
}: {
	show: boolean;
	toggleModal: () => void;
	confirmAction: () => void;
}) => (
	<ConfirmationModal
		title="Warning"
		show={show}
		toggleModal={toggleModal}
		confirmAction={confirmAction}
		message="This will permanently delete the selected component(s)."
		labelOK="OK"
		labelCancel='"Cancel'
	/>
);
ComponentsDeleteConfirmationModal.displayName =
	'ComponentsDeleteConfirmationModal';
export default ComponentsDeleteConfirmationModal;
