import { Item } from 'legacy/lib/api/types/Item';
import { ItemFormValues } from '../types/FormValues';
import { formatDescription } from './formatRTF';

const getPreloadValues = async (item: Item) => {
	const {
		budget,
		cldeppct,
		budgethrs,
		workroom,
		quantity,
		loc,
		inactive,
		bypasswip,
		completed,
		scat,
		wrshipto,
		unitmeasure,
		itemName,
		descOnly,
		estdep,
		esttcost,
		esttprice,
		descOnlyRtf,
		wrdescrtf,
		wrdesc,
	} = item;
	// We calculate unit budget client side because the API currently doesn´t have this field
	const unitbudget =
		item.quantity !== undefined && item.budget !== undefined
			? String(Number(item.budget / (item.quantity || 1)))
			: '0';

	const newValues = {
		itemName,
		unitbudget,
		inactive,
		completed,
		bypasswip,
		initialDescription: await formatDescription(descOnlyRtf),
		initialWorkroomDescription: await formatDescription(wrdescrtf),
		description: {
			value: descOnly || '',
			html: await formatDescription(descOnlyRtf),
		} as ItemFormValues['description'],
		workroomDescription: {
			value: wrdesc || '',
			html: await formatDescription(wrdescrtf),
		} as ItemFormValues['workroomDescription'],
		estdep: String(estdep),
		esttcost: String(esttcost),
		esttprice: String(esttprice),
		budget: String(budget),
		cldeppct: String(cldeppct),
		quantity: String(quantity),
		budgethrs,
		wrshipto,
		loc: loc
			? {
					label: '',
					value: loc,
			  }
			: null,
		scat: {
			label: '',
			value: scat,
		},
		workroom,
		unitmeasure: unitmeasure
			? {
					label: unitmeasure,
					value: unitmeasure,
			  }
			: null,
	};
	return newValues;
};
export default getPreloadValues;
