import { Col, Row, Form, Container, Table, Button } from 'react-bootstrap';
import Select from 'react-select';
import { WithRouter } from '../../../../helpers/Router';
import { FormEvent } from '../../../../utilities/FormEvent';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { FooterFormAction } from '../../../components/Form';
import { getLocalStorage } from '../../../../utilities/LocalStorage';
import { handleResponseAlert } from '../../../../utilities/Response';
import { reload } from '../../../../helpers/Util';

class WorkflowSettingsPrint extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			startDate: '',
			minDate: new Date(),
			data: {
				company1: {},
				company2: {},
			},
			typeOptions: {},
			project: {},
			default: {
				usediscount: null,
			},
		};

		this.markUpKeys = [
			{
				label: 'Proposals',
				key: 'prop',
				style: 'propstyle',
			},
			{
				label: 'Invoices',
				key: 'inv',
				style: 'invstyle',
			},
			{
				label: 'Purchase Orders',
				key: 'po',
				style: 'powostyle',
			},
			{
				label: 'Work Orders',
				key: 'wo',
				style: 'woFormatId',
			},
			{
				label: 'Statements',
				key: 'state',
				style: 'statementstyle',
			},
		];

		this.formEventCompany1 = new FormEvent(`workflowCompanySettings`);
		this.formEventCompany2 = new FormEvent(`workflowCompany2Settings`);

		this.api = new ApiService();
		this.statusOptions = [
			{ value: 0, label: '(None)' },
			{ value: 1, label: 'Ordered' },
			{ value: 2, label: 'Exp. Ship' },
			{ value: 3, label: 'Delivered' },
			{ value: 4, label: 'Install Date' },
			{ value: 5, label: 'User Date 2' },
			{ value: 6, label: 'Received' },
		];
		this.formatOptions = [
			{ value: 1290, label: 'Top' },
			{ value: 1304, label: 'Middle' },
			{ value: 1305, label: 'Bottom' },
		];
	}

	async componentDidMount() {
		this.dMloader(true);
		if (Object.keys(this.props.data).length > 0) {
			let data = this.props.data || {};
			this.setState({
				data: data,
			});
			//this.checkboxOnCheck()
		}
		const company1 = await this.api.getCompany();
		const company2 = await this.api.getCompany2();

		this.renderData(company1, company2);
		this.fetchStyles();
	}

	renderData(company1, company2) {
		this.setState({
			data: {
				company1: company1,
				company2: company2,
			},
		});
	}

	async fetchStyles() {
		let proposalTypes = await this.api.get('reportformats/types', 'proposal');
		let invoiceTypes = await this.api.get('reportformats/types', 'invoice');
		let purchaseOrderTypes = await this.api.get(
			'reportformats/types',
			'purchaseorder'
		);
		let workOrderTypes = await this.api.get('reportformats/types', 'workorder');
		this.setState({
			typeOptions: {
				prop: proposalTypes.reportFormats
					.filter((format) => format.allowInWeb)
					.map((format) => ({
						value: format.id,
						label: format.name,
					})),
				inv: invoiceTypes.reportFormats
					.filter((format) => format.allowInWeb)
					.map((format) => ({
						value: format.id,
						label: format.name,
					})),
				po: purchaseOrderTypes.reportFormats
					.filter((format) => format.allowInWeb)
					.map((format) => ({
						value: format.id,
						label: format.name,
					})),
				wo: workOrderTypes.reportFormats
					.filter((format) => format.allowInWeb)
					.map((format) => ({
						value: format.id,
						label: format.name,
					})),
				state: [
					{ value: 1243, label: 'One per Client (Modern)' },
					{ value: 1245, label: 'One per Project/Order (Modern)' },
				],
			},
		});
		this.dMloader(false);
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data || {};

			this.setState({
				data: data,
			});

			//this.checkboxOnCheck()
		}
	}

	checkboxOnCheck() {
		let company1 = this.state.data.company1;
		let company2 = this.state.data.company2;
		// Get localstorage if state is empty
		if (Is.empty(company1))
			company1 = getLocalStorage('workflowCompanySettings', true);

		// Get localstorage if state is empty
		if (Is.empty(company2))
			company2 = getLocalStorage('workflowCompany2Settings', true);

		if (company1.timeaddtoscat) {
			const chk = document.getElementById('chk-allowed-joined-sales-category');
			if (chk) chk.checked = true;
		}
	}

	getDefaultTypeValue(key, style) {
		return this.state.typeOptions[key]?.filter((option) => {
			return option.value === this.state.data.company2[style];
		})[0];
	}

	getDefaultFormatValue() {
		for (let x of this.formatOptions) {
			if (x.value === this.state?.data.company1.checkpos) return x;
		}
		return this.formatOptions[1];
	}

	getDefaultStatusValue(key) {
		return this.statusOptions.filter((status) => {
			return status.value === this.state.data.company2[key];
		})[0];
	}

	handleClickSave = async (e) => {
		e.preventDefault();

		const company1 = getLocalStorage('workflowCompanySettings', true);
		const company2 = getLocalStorage('workflowCompany2Settings', true);
		const request1 = this.api.patchCompany(company1);
		const request2 = this.api.patchCompany2(company2);

		handleResponseAlert(request1, () => {
			handleResponseAlert(request2);
			this.fetchStyles();
		});

		reload();
	};

	render() {
		return (
			<>
				<div className="content-padding min-height">
					<Container>
						<Row className="mb-2">
							<Col xl={8}>
								<div className="table-gradient">
									<Table responsive borderless className="">
										<thead>
											<tr key="0">
												<th className="fs-lg"></th>
												<th className="fs-lg mw-180px">Style</th>
												<th className="fs-lg"></th>
												<th className="fs-lg mw-80px text-center">
													Abr. Header
												</th>
												<th className="fs-lg"></th>
												<th className="fs-lg mw-80px text-center">
													Pg. X of Y
												</th>
												<th className="fs-lg"></th>
												<th className="fs-lg mw-80px text-center">Ref. No.</th>
											</tr>
										</thead>
										<tbody>
											{this.markUpKeys.map((markup) => (
												<tr key={markup.key}>
													<td className="align-middle text-end">
														{markup.label}
													</td>
													<td>
														<Select
															key={`${Math.floor(Math.random() * 1000)}-min`}
															name={markup.key + 'style'}
															options={this.state.typeOptions[markup.key]}
															className="react-select"
															defaultValue={this.getDefaultTypeValue(
																markup.key,
																markup.style
															)}
															onChange={this.formEventCompany2.handleSelectChange.bind(
																this,
																markup.style
															)}
															menuPosition={'fixed'}
															styles={{
																menuPortal: (base) => ({
																	...base,
																	zIndex: 9999,
																}),
																container: (provided) => ({
																	...provided,
																	width: '100%',
																}),
															}}
														/>
													</td>
													<td className="align-middle text-center text-secondary-ash"></td>
													<td className="align-middle text-center">
														<Form.Check
															inline
															label=""
															name={
																markup.key !== 'wo'
																	? 'useabhead' + markup.key
																	: 'useAbHeadWO'
															}
															type="checkbox"
															id={
																markup.key !== 'wo'
																	? 'useabhead' + markup.key
																	: 'useAbHeadWO'
															}
															defaultChecked={
																markup.key !== 'wo'
																	? this.state.data.company1[
																			'useabhead' + markup.key
																	  ]
																	: this.state.data.company2['useAbHeadWO']
															}
															onClick={
																markup.key !== 'wo'
																	? this.formEventCompany1.handleCheckChange.bind(
																			this
																	  )
																	: this.formEventCompany2.handleCheckChange.bind(
																			this
																	  )
															}
														/>
													</td>
													<td className="align-middle text-center text-secondary-ash"></td>
													<td className="align-middle text-center">
														<Form.Check
															inline
															label=""
															name={
																markup.key !== 'wo'
																	? `${markup.key}pagexofx`
																	: 'woPageXofX'
															}
															type="checkbox"
															id={
																markup.key !== 'wo'
																	? `${markup.key}pagexofx`
																	: 'woPageXofX'
															}
															defaultChecked={
																markup.key !== 'wo'
																	? this.state.data.company1[
																			markup.key + 'pagexofx'
																	  ]
																	: this.state.data.company2['woPageXofX']
															}
															onClick={
																markup.key !== 'wo'
																	? this.formEventCompany1.handleCheckChange.bind(
																			this
																	  )
																	: this.formEventCompany2.handleCheckChange.bind(
																			this
																	  )
															}
														/>
													</td>
													<td className="align-middle text-center text-secondary-ash"></td>
													<td className="align-middle text-center">
														{markup.label !== 'Statements' && (
															<Form.Check
																inline
																label=""
																name={
																	markup.key !== 'wo'
																		? `${markup.key}showrefnum`
																		: `${markup.key}ShowRefNum`
																}
																type="checkbox"
																id={
																	markup.key !== 'wo'
																		? `${markup.key}showrefnum`
																		: `${markup.key}ShowRefNum`
																}
																defaultChecked={
																	markup.key !== 'wo'
																		? this.state.data.company1[
																				markup.key + 'showrefnum'
																		  ]
																		: this.state.data.company2[
																				markup.key + 'ShowRefNum'
																		  ]
																}
																onClick={
																	markup.key !== 'wo'
																		? this.formEventCompany1.handleCheckChange.bind(
																				this
																		  )
																		: this.formEventCompany2.handleCheckChange.bind(
																				this
																		  )
																}
															/>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</Col>
							<Col xl={{ span: 2, offset: 2 }} md={4} className="mt-4 mt-xl-0">
								<div className="fs-lg fw-bold m-2">Document Margins</div>
								<Row className="mb-5 px-2 mt-3" xs={1}>
									<Col className="px-4">
										<Row className="mb-3 align-items-center">
											<Col xs={5} className="pe-0 text-lg-start">
												Top
											</Col>
											<Col>
												<Form.Control
													type="text"
													name={`lhrightmargin`}
													defaultValue={this.state.data.company1?.lhrightmargin}
													onChange={this.formEventCompany1.handleTextChange.bind(
														this
													)}
												/>
											</Col>
										</Row>
										<Row className="mb-3 align-items-center">
											<Col xs={5} className="pe-0 text-lg-start">
												Bottom
											</Col>
											<Col>
												<Form.Control
													type="text"
													name={`lhleftmargin`}
													defaultValue={this.state.data.company1?.lhleftmargin}
													onChange={this.formEventCompany1.handleTextChange.bind(
														this
													)}
												/>
											</Col>
										</Row>
										{/*
                                        <Row className='mb-3 align-items-center'>
                                            <Col className='pe-0 text-lg-start'>
                                                <Form.Check
                                                    inline
                                                    label='Bold Item Name'
                                                    name='pofulldesc'
                                                    type='checkbox'
                                                    id={`chk-option-pofulldesc`}
                                                    defaultChecked={this.state.project.pofulldesc}
                                                    onClick={this.formEventCompany1.handleCheckChange.bind(this)}
                                                />
                                            </Col>
                                        </Row>
                                                    */}
									</Col>
								</Row>
							</Col>
						</Row>

						<div className="bg-secondary-grey mb-4 roundedx-4">
							<Row xs={1} className="py-3">
								<Col>
									<Row className="align-items-center px-3">
										<Col className="px-3">
											<h6 className="fw-bold mb-0">Report Options</h6>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>

						<Row className="mb-5 px-2" xs={1} lg={3}>
							<Col lg={3} className="px-4">
								<Row className="mb-3 align-items-center">
									<Col sm={3} lg={4} className="mb-2 mb-sm-0 pe-0 text-lg-end">
										Trim Printer Alignment
									</Col>
									<Col>
										<Form.Control
											type="text"
											name={`checktrimalign`}
											defaultValue={this.state.data.company1?.checktrimalign}
											onChange={this.formEventCompany1.handleTextChange.bind(
												this
											)}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg={3} className="px-4">
								<Row className="mb-3 align-items-center">
									<Col sm={3} className="mb-2 mb-sm-0 pe-0 text-lg-end">
										Check Format
									</Col>
									<Col>
										<Select
											key={`${Math.floor(Math.random() * 1000)}-min`}
											name={'woFormatId'}
											options={this.formatOptions}
											placeholder="Please select"
											className="react-select"
											defaultValue={this.getDefaultFormatValue.bind(this)}
											onChange={this.formEventCompany1.handleSelectChange.bind(
												this,
												'checkpos'
											)}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg={6} className="px-4">
								<Row className="mb-3 align-items-center">
									<Col sm={3} className="mb-2 mb-sm-0 pe-0 text-lg-end">
										Client Status Dates
									</Col>
									<Col xs={12} sm={3} className="mb-2 mb-sm-0">
										<Select
											key={`${Math.floor(Math.random() * 1000)}-min`}
											name={'statusdatetype1'}
											options={this.statusOptions}
											placeholder="Please select"
											className="react-select"
											defaultValue={this.getDefaultStatusValue(
												'statusdatetype1'
											)}
											onChange={this.formEventCompany2.handleSelectChange.bind(
												this,
												'statusdatetype1'
											)}
										/>
									</Col>
									<Col xs={12} sm={3} className="mb-2 mb-sm-0">
										<Select
											key={`${Math.floor(Math.random() * 1000)}-min`}
											name={'statusdatetype2'}
											options={this.statusOptions}
											placeholder="Please select"
											className="react-select"
											defaultValue={this.getDefaultStatusValue(
												'statusdatetype2'
											)}
											onChange={this.formEventCompany2.handleSelectChange.bind(
												this,
												'statusdatetype2'
											)}
										/>
									</Col>
									<Col xs={12} sm={3} className="mb-2 mb-sm-0">
										<Select
											key={`${Math.floor(Math.random() * 1000)}-min`}
											name={'statusdatetype3'}
											options={this.statusOptions}
											placeholder="Please select"
											className="react-select"
											defaultValue={this.getDefaultStatusValue(
												'statusdatetype3'
											)}
											onChange={this.formEventCompany2.handleSelectChange.bind(
												this,
												'statusdatetype3'
											)}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleClickSave}>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(WorkflowSettingsPrint);
