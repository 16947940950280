import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSession } from '../../../context/session';
import { useHasAccess } from '../../../context/security';
import Spinner from '../../help/Spinner';

interface IProps {
	children: ReactNode;
	attributeNo?: number;
	attributeType?: number;
	isAdminRoute?: boolean;
}

const RouteGuard = ({
	children,
	attributeNo,
	attributeType,
	isAdminRoute,
}: IProps) => {
	const state = useSession();
	const hasAccess = useHasAccess(attributeNo, attributeType);

	if (state.isAdmin === null && isAdminRoute) {
		return <Spinner />;
	}
	if (attributeNo !== undefined && attributeType !== undefined) {
		return hasAccess ? <>{children}</> : <Navigate to="/" />;
	}

	return isAdminRoute && state.isAdmin === false ? (
		<Navigate to="/" />
	) : (
		<>{children}</>
	);
};

RouteGuard.displayName = 'RouteGuard';

export default RouteGuard;
