export default function ConfirmationMessage() {
	return (
		<div className="d-flex align-items-center">
			<div>
				<i className="ri-question-fill ri-3x text-info"></i>
			</div>
			<div className="px-3">
				<div>
					Are you sure that you want to accept this reconciliation? By pressing
					OK, all of the cleared transactions will drop from the reconciliation
					and it will be ready for next month's statement.
				</div>
			</div>
		</div>
	);
}
