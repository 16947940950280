import React from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export default class Select extends React.Component<
	StateManagerProps & {
		isCreatable?: boolean;
		onCreateOption?: (value: string) => void;
	}
> {
	constructor(props: StateManagerProps) {
		super(props);
	}

	render() {
		return this.props.isCreatable ? (
			<CreatableSelect {...this.props} />
		) : (
			<ReactSelect {...this.props} />
		);
	}
}
