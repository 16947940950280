import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import Select from 'react-select';
import { WithRouter } from '../../../helpers/Router';
import ProjectAddMarkup from './ProjectAddMarkup';
import { TIME_BILLING_TIERS } from './constants';

class ProjectAddDefaults extends ThreadComponent {
	constructor(props) {
		super(props);
		this.updatedData = props.updatedData;
		this.state = {
			timeBillingTiers: TIME_BILLING_TIERS,
			isMarkupShown: false,
		};
	}

	componentInit() {
		this.hasFormAction = true;
	}

	async componentDidMount() {
		if (Object.keys(this.props.data).length > 0) {
			let data = this.props.data || {};

			this.setState({
				data: data,
				dataIsLoaded: true,
			});
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data || {};

			this.setState({
				data: data,
				dataIsLoaded: true,
			});
		}
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.props.onDataChange({ key: key, value: value });
	};

	defaultTimeBillingTier() {
		const tiers = this.state.timeBillingTiers;
		const { data } = this.state;

		if (!(tiers && tiers.length > 0)) {
			return { value: '', label: 'Please select' };
		}

		let existingTier;
		const selectedTier = tiers.filter((tier) => {
			if (tier.value === this.updatedData.timebillingtier) {
				return true;
			}

			if (data?.timebillingtier === tier.value) {
				existingTier = tier;
			}
		})[0];

		return (
			selectedTier ?? existingTier ?? { value: '', label: 'Please select' }
		);
	}

	handleMarkupContinue = (markup) => {
		this.updatedData = { ...this.updatedData, ...markup };
		this.setState({ isMarkupShown: false });

		Object.keys(markup).forEach((key) => {
			this.props.onDataChange({ key: key, value: markup[key] });
		});
	};

	render() {
		const { data } = this.state;
		return (
			<>
				<Container className="ms-0">
					<Row xs={1} lg={2}>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-start mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0 mt-2 pt-1">
										PO Sidemark
									</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="PO Sidemark"
										id="posidemark"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.posidemark ?? data?.posidemark
										}
									/>
									<Form.Check
										inline
										label="Append Loc. to Sidemark"
										type="checkbox"
										className="mt-3"
										id="locinsidemark"
										onClick={this.handleChange}
										defaultChecked={
											this.updatedData?.locinsidemark ?? data?.locinsidemark
										}
									/>
									<p className="fs-sm mt-2 text-muted-primary">
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy eirmod tempor invidunt ut labore et dolore magna
										aliquyam erat, sed diam voluptua.
									</p>
								</Col>
							</Row>
							<Row className="align-items-start mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0 mt-2 pt-1">
										PO Designer/Ext.
									</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="PO Designer/Ext."
										id="podesigndesc"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.podesigndesc ?? data?.podesigndesc
										}
									/>
									<p className="fs-sm mt-2 text-muted-primary">
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy eirmod tempor invidunt ut labore et dolore magna
										aliquyam erat, sed diam voluptua.
									</p>
								</Col>
							</Row>
							<Row className="align-items-start mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0 mt-2 pt-1">
										Client Deposit
									</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="number"
										placeholder="Client Deposit"
										id="clientdep"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.clientdep ?? data?.clientdep
										}
									/>
									<Form.Check
										inline
										label="Include Sales Tax in Deposit"
										type="checkbox"
										className="mt-3"
										id="cldpsalestax"
										onClick={this.handleChange}
										defaultValue={
											this.updatedData?.cldpsalestax ?? data?.cldpsalestax
										}
									/>
									<p className="fs-sm mt-2 text-muted-primary">
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy eirmod tempor invidunt ut labore et dolore magna
										aliquyam erat, sed diam voluptua.
									</p>
								</Col>
							</Row>
							<Row className="align-items-start mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0 mt-2 pt-1">
										Client Invoice Terms
									</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Client Invoice Terms"
										id="clientinvterms"
										onChange={this.handleChange}
										defaultValue={
											this.updatedData?.clientinvterms ?? data?.clientinvterms
										}
									/>
									<p className="fs-sm mt-2 text-muted-primary">
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy eirmod tempor invidunt ut labore et dolore magna
										aliquyam erat, sed diam voluptua.
									</p>
								</Col>
							</Row>
							<Row className="align-items-start mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0 mt-2 pt-1">
										Time Billing Tier
									</Form.Label>
								</Col>
								<Col>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										options={this.state?.timeBillingTiers}
										className="react-select"
										placeholder=""
										name="timebillingtier"
										onChange={this.handleChange}
										defaultValue={this.defaultTimeBillingTier()}
									/>
									<p className="fs-sm mt-2 text-muted-primary">
										Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
										diam nonumy eirmod tempor invidunt ut labore et dolore magna
										aliquyam erat, sed diam voluptua.
									</p>
								</Col>
							</Row>
							<Row className="align-items-start mb-3">
								<Col
									lg={3}
									className="d-none d-lg-flex text-lg-end mb-2 mb-lg-0"
								></Col>
								<Col>
									<Button
										variant="primary"
										className=" w-100"
										onClick={() => {
											this.setState({
												isMarkupShown: true,
											});
										}}
									>
										Advanced / Mark-up
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>

				<ProjectAddMarkup
					markupData={this.updatedData}
					isShowing={this.state.isMarkupShown}
					onContinue={this.handleMarkupContinue}
					onCancel={() => {
						this.setState({ isMarkupShown: false });
					}}
				/>
			</>
		);
	}
}

export default WithRouter(ProjectAddDefaults);
