import { Helmet } from 'react-helmet';

import PageHeader from './PageHeader';
import SettingsForm from './SettingsForm';
import { useEffect, useState } from 'react';
import DocumentsForm from './DocumentsForm';
import { ApiService } from '../../../lib/api/HttpService';

export default function Company(tab) {
	const [selectedTab, setSelectedTab] = useState('info');
	const [hasAttachments, setHasAttachments] = useState(false);

	const renderSelectedTab = () => {
		let tabContent = <></>;
		if (selectedTab === 'info') {
			tabContent = <SettingsForm />;
		} else if (selectedTab === 'documents') {
			tabContent = (
				<DocumentsForm
					onRefresh={() => {
						checkIfHasAttachments();
					}}
				/>
			);
		}

		return tabContent;
	};
	const handleTabSelection = (e) => {
		setSelectedTab(e.target.getAttribute('data-menu'));
	};
	const checkIfHasAttachments = async () => {
		try {
			const filter = `?$filter=ObjectIdStr eq '0' and ObjectType eq 'Company' and FileContext eq 'ObjectAttachment' and Status eq 'Active'`;
			const attachments = await new ApiService().get('objectfiles', filter);
			setHasAttachments(attachments.length > 0);
		} catch {
			/* empty */
		}
	};

	useEffect(() => {
		checkIfHasAttachments();
	}, []);

	return (
		<>
			<Helmet>
				<title>Company Settings | Design Manager</title>
			</Helmet>
			<PageHeader
				selectedTab={selectedTab}
				onTab={handleTabSelection}
				hasAttachments={hasAttachments}
			/>
			{renderSelectedTab()}
		</>
	);
}
