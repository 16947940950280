import HomePageViewModel from './pages/Home/HomePageViewModel';
import TimeEntryListViewModel from './pages/TimeEntry/view-models/TimeEntryListViewModel';
import TimeEntryAddEditViewModel from './pages/TimeEntry/view-models/TimeEntryAddEditViewModel';
import { TimeEntryService } from './pages/TimeEntry/service/TimeEntryService';
import { ClientService } from './pages/Client/api/ClientService';
import { ProjectsService } from './pages/Projects/api/ProjectsService';

import ClientListViewModel from './pages/Client/ClientListViewModel';
import { VendorService } from './pages/Vendor/api/VendorService';
import VendorListViewModel from './pages/Vendor/VendorListViewModel';
import VendorAddEditViewModel from './pages/Vendor/VendorAddEditViewModel';
import ProjectsListViewModel from './pages/Projects/ProjectsListViewModel';
import ProjectsAddViewModel from './pages/Projects/ProjectsAddViewModel';
import ClientAddEditViewModel from './pages/Client/ClientAddEditViewModel';
import AddressListViewModel from './pages/Address/AddressListViewModel';
import { AddressService } from './pages/Address/api/AddressService';
import AddressAddEditViewModel from './pages/Address/AddressAddEditViewModel';
import PayeeAddressAddEditViewModel from './pages/Address/PayeeAddressAddEditViewModel';
import EmployeeListViewModel from './pages/Employee/EmployeeListViewModel';
import { EmployeeService } from './pages/Employee/api/EmployeeService';
import EmployeeAddEditViewModel from './pages/Employee/EmployeeAddEditViewModel';
import { InsuranceService } from './pages/Vendor/api/InsuranceService';

export default class AppDependencyContainer {
	readonly timeEntryService = new TimeEntryService();
	readonly clientService = new ClientService();
	readonly addressService = new AddressService();
	readonly vendorService = new VendorService();
	readonly employeeService = new EmployeeService();
	readonly projectsService = new ProjectsService();
	readonly insuranceService = new InsuranceService();

	readonly homePageViewModel = new HomePageViewModel();
	readonly timeEntryListViewModel = new TimeEntryListViewModel(
		this.timeEntryService
	);
	readonly timeEntryAddEditViewModel = new TimeEntryAddEditViewModel(
		this.timeEntryService
	);
	readonly clientListViewModel = new ClientListViewModel(this.clientService);
	readonly clientAddEditViewModel = new ClientAddEditViewModel(
		this.clientService
	);
	readonly addressListViewModel = new AddressListViewModel(this.addressService);
	readonly addressAddEditViewModel = new AddressAddEditViewModel(
		this.addressService
	);
	readonly payeeAddressAddEditViewModel = new PayeeAddressAddEditViewModel(
		this.addressService
	);
	readonly vendorListViewModel = new VendorListViewModel(this.vendorService);
	readonly vendorAddEditViewModel = new VendorAddEditViewModel(
		this.vendorService,
		this.insuranceService
	);
	readonly employeeListViewModel = new EmployeeListViewModel(
		this.employeeService
	);
	readonly employeeAddEditViewModel = new EmployeeAddEditViewModel(
		this.employeeService
	);
	readonly projectsListViewModel = new ProjectsListViewModel();
	readonly projectsAddViewModel = new ProjectsAddViewModel(
		this.projectsService
	);
}
