import React from 'react';
import DatePicker from 'react-datepicker';
import { Modal, Form, Row, Col, Button, Container } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { currencyFormat } from '../../../../helpers/Number';
import { fixNumberValue, round, roundStr } from '../../../../utilities/Number';
import InputCurrency from '../../../../app/components/form/InputCurrency';

class VendorInvoicesDistributionModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			minDate: new Date(),
			data: {
				recdt: new Date(),
				expdt: new Date(),
				userdt1: new Date(),
				userdt2: new Date(),
				mcost: 0,
				dcost: 0,
				fcost: 0,
				icost: 0,
				lcost: 0,
				totcost: 0,
				quanpaidfor: 0,
				boqty: 0,
				macct: 0,
				dacct: 0,
				facct: 0,
				iacct: 0,
				lacct: 0,
				costtodate: 0,
				usewip: false,
				recqty: 0,
				statusnote: '',
			},
			dataIsLoaded: false,
			merchandise: 'Merchandise',
			shipping: 'Shipping',
			design: 'Design',
			installLabor: 'Install/Labor',
			crating: 'Crating',
			dCostToDate: 0,
			fCostToDate: 0,
			iCostToDate: 0,
			lCostToDate: 0,
			mCostToDate: 0,
			totalCostToDate: 0,
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		this.setCompTitle();
		this.dMloader(true, false, false);

		if (!this.props.showModal && this.state.dataIsLoaded === true) {
			this.setState({
				dataIsLoaded: false,
				data: {
					recdt: new Date(),
					expdt: new Date(),
					userdt1: new Date(),
					userdt2: new Date(),
					mcost: 0.0,
					dcost: 0.0,
					fcost: 0.0,
					icost: 0.0,
					lcost: 0.0,
					totcost: 0.0,
					quanpaidfor: 0,
					boqty: 0,
					macct: 0,
					dacct: 0,
					facct: 0,
					iacct: 0,
					lacct: 0,
					costtodate: 0,
					usewip: false,
					recqty: 0,
					statusnote: '',
				},
			});
		}

		if (
			this.props.showModal &&
			this.state.dataIsLoaded === false &&
			!isEmpty(this.props.data)
		) {
			const { proj, ponum, item, comp } = this.props.data;
			const costToDateData = await this.api.postTemporaryInvoiceCostToDate({
				proj,
				poNum: ponum,
				item,
				comp,
			});

			const {
				recdt,
				expdt,
				userdt1,
				userdt2,
				mcost,
				dcost,
				fcost,
				icost,
				lcost,
				quanpaidfor,
				boqty,
				macct,
				dacct,
				facct,
				iacct,
				lacct,
				costtodate,
				usewip,
				recqty,
				statusnote,
			} = this.props.data;

			this.setState(
				{
					dataIsLoaded: true,
					data: {
						recdt: recdt ? new Date(recdt) : null,
						expdt: expdt ? new Date(expdt) : null,
						userdt1: userdt1 ? new Date(userdt1) : null,
						userdt2: userdt2 ? new Date(userdt2) : null,
						mcost: roundStr(mcost),
						dcost: roundStr(dcost),
						fcost: roundStr(fcost),
						icost: roundStr(icost),
						lcost: roundStr(lcost),
						quanpaidfor,
						boqty,
						macct,
						dacct,
						facct,
						iacct,
						lacct,
						costtodate,
						usewip: !usewip,
						recqty,
						statusnote,
					},
					...(costToDateData || {}),
				},
				() => {
					this.dMloader(false, true);
				}
			);

			this.company = this.props.company;
		}
	}

	setCompTitle() {
		const { compviewm, compviewd, compviewf, compviewi, compviewl } =
			this.props.company;
		this.setState({
			merchandise: compviewm,
			shipping: compviewf,
			design: compviewd,
			installLabor: compviewi,
			crating: compviewl,
		});
	}

	handleDateChange = (date, field) => {
		this.setState((prevState) => ({
			data: { ...prevState.data, [field]: date },
		}));
	};

	handleCheck = (e) => {
		this.setState((prevState) => ({
			data: {
				...prevState.data,
				[e.target.name]: e.target.checked,
			},
		}));
	};

	handleInputChange = (event) => {
		this.setState(
			(prevState) => ({
				data: {
					...prevState.data,
					[event.target.name]: event.target.value,
				},
			}),
			() => {
				this.setState((prevState) => ({
					data: {
						...prevState.data,
						totcost: this.calcTotal(),
					},
				}));
			}
		);
	};

	calcTotal() {
		const { mcost, dcost, fcost, icost, lcost } = this.state.data;

		return (
			Number(mcost) +
			Number(dcost) +
			Number(fcost) +
			Number(icost) +
			Number(lcost)
		);
	}

	render() {
		const { merchandise, shipping, crating, design, installLabor } = this.state;
		return (
			<Modal
				show={this.props.showModal}
				size="xl"
				aria-labelledby="action-modal"
				className="a-modal mx-sm-5 mx-lg-0"
				backdrop="static"
				centered
				onHide={this.props.hideModal}
			>
				<Modal.Header closeButton>
					<h4>Vendor Invoice Distribution [Project PO]</h4>
				</Modal.Header>
				<Modal.Body>
					<div className="content-padding min-height">
						<Container fluid className="ms-0">
							<Form.Group>
								<Row xs={1} lg={2}>
									<Col>
										<Row className="align-items-center fw-bold mb-3">
											<Col></Col>
											<Col>
												<Form.Label className="mb-0">
													Cost This Invoice
												</Form.Label>
											</Col>
											<Col>
												<Form.Label className="mb-0">Cost to Date</Form.Label>
											</Col>
										</Row>
									</Col>
									<Col></Col>
								</Row>

								<Row xs={1} lg={2} className="mb-5">
									<Col>
										<Row className="align-items-center">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													{merchandise}
												</Form.Label>
											</Col>
											<Col>
												<InputCurrency
													type="number"
													placeholder="0.00"
													name="mcost"
													onChange={this.handleInputChange}
													value={this.state.data.mcost}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0.00"
													name="macct"
													disabled={true}
													readOnly
													onChange={this.handleInputChange}
													value={currencyFormat(this.state.mCostToDate)}
												/>
											</Col>
										</Row>
										<Row className="align-items-center">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													{design}
												</Form.Label>
											</Col>
											<Col>
												<InputCurrency
													type="number"
													placeholder="0.00"
													name="dcost"
													onChange={this.handleInputChange}
													value={this.state.data.dcost}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0.00"
													name="dacct"
													disabled={true}
													readOnly
													onChange={this.handleInputChange}
													value={currencyFormat(this.state.dCostToDate)}
												/>
											</Col>
										</Row>
										<Row className="align-items-center">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													{shipping}
												</Form.Label>
											</Col>
											<Col>
												<InputCurrency
													type="number"
													placeholder="0.00"
													name="fcost"
													onChange={this.handleInputChange}
													value={this.state.data.fcost}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0.00"
													name="facct"
													disabled={true}
													readOnly
													onChange={this.handleInputChange}
													value={currencyFormat(this.state.fCostToDate)}
												/>
											</Col>
										</Row>
										<Row className="align-items-center">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													{installLabor}
												</Form.Label>
											</Col>
											<Col>
												<InputCurrency
													type="number"
													placeholder="0.00"
													name="icost"
													onChange={this.handleInputChange}
													value={this.state.data.icost}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0.00"
													name="iacct"
													disabled={true}
													readOnly
													onChange={this.handleInputChange}
													value={currencyFormat(this.state.iCostToDate)}
												/>
											</Col>
										</Row>
										<Row className="align-items-center">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													{crating}
												</Form.Label>
											</Col>
											<Col>
												<InputCurrency
													type="number"
													placeholder="0.00"
													name="lcost"
													onChange={this.handleInputChange}
													value={this.state.data.lcost}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0.00"
													name="lacct"
													disabled={true}
													readOnly
													onChange={this.handleInputChange}
													value={currencyFormat(this.state.lCostToDate)}
												/>
											</Col>
										</Row>
										<Row className="align-items-center mb-3">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													Total
												</Form.Label>
											</Col>
											<Col>
												<InputCurrency
													type="text"
													placeholder="0.00"
													readOnly
													value={this.calcTotal()}
												/>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="0.00"
													name="costtodate"
													disabled={true}
													readOnly
													onChange={this.handleInputChange}
													value={currencyFormat(this.state.totalCostToDate)}
												/>
											</Col>
										</Row>
										<Row className="align-items-center mb-3">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													Qty. Paid For
												</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="number"
													placeholder="0.00"
													name="quanpaidfor"
													onChange={this.handleInputChange}
													value={this.state.data.quanpaidfor}
												/>
											</Col>
											<Col>
												<Row className="align-items-center">
													<Col>
														<Form.Check
															inline
															label="Bypass WIP"
															name="usewip"
															type="checkbox"
															id="check-wip"
															className="fw-600 mt-1"
															checked={this.state.data.usewip}
															onChange={this.handleCheck}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row className="align-items-center">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													B/O Qty. Remaining
												</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="number"
													placeholder="0.00"
													name="boqty"
													onChange={this.handleInputChange}
													value={this.state.data.boqty}
												/>
											</Col>
											<Col></Col>
										</Row>
									</Col>

									<Col>
										<div className="fw-bold mb-3">Optional Tracking Info.</div>
										<Row className="align-items-center mb-3">
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													Received Qty.
												</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="number"
													placeholder="0.00"
													name="recqty"
													onChange={this.handleInputChange}
													value={this.state.data.recqty}
												/>
											</Col>
											<Col>
												<Form.Label className="mb-0 d-block text-end">
													Received Date
												</Form.Label>
											</Col>
											<Col>
												<div className="react-select-header">
													<DatePicker
														selected={this.state.data.recdt}
														minDate={this.state.data.minDate}
														onChange={(date) => {
															this.handleDateChange(date, 'recdt');
														}}
														showMonthDropdown="true"
														showYearDropdown="true"
														className="form-control"
														placeholderText="Select"
													/>
												</div>
											</Col>
										</Row>
										<Row className="align-items-center mb-3">
											<Col xs={3}>
												<Form.Label className="mb-0 d-block text-end">
													Exp. Ship Date
												</Form.Label>
											</Col>
											<Col xs={6}>
												<div className="react-select-header">
													<DatePicker
														selected={this.state.data.expdt}
														onChange={(date) => {
															this.handleDateChange(date, 'expdt');
														}}
														showMonthDropdown="true"
														showYearDropdown="true"
														className="form-control"
														placeholderText="Select"
													/>
												</div>
											</Col>
										</Row>
										<Row className="align-items-center mb-3">
											<Col xs={3}>
												<Form.Label className="mb-0 d-block text-end">
													Status
												</Form.Label>
											</Col>
											<Col xs={6}>
												<Form.Control
													type="text"
													name="statusnote"
													onChange={this.handleInputChange}
													value={this.state.data.statusnote}
												/>
											</Col>
										</Row>
										<Row className="align-items-center mb-3">
											<Col xs={3}>
												<Form.Label className="mb-0 d-block text-end">
													{this.company?.wildcardstatus1}
												</Form.Label>
											</Col>
											<Col xs={6}>
												<div className="react-select-header">
													<DatePicker
														selected={this.state.data.userdt1}
														minDate={this.state.minDate}
														onChange={(date) => {
															this.handleDateChange(date, 'userdt1');
														}}
														showMonthDropdown="true"
														showYearDropdown="true"
														className="form-control"
														placeholderText="Select"
													/>
												</div>
											</Col>
										</Row>
										<Row className="align-items-center mb-3">
											<Col xs={3}>
												<Form.Label className="mb-0 d-block text-end">
													{this.company?.wildcardstatus2}
												</Form.Label>
											</Col>
											<Col xs={6}>
												<div className="react-select-header">
													<DatePicker
														selected={this.state.data.userdt2}
														minDate={this.state.data.minDate}
														onChange={(date) => {
															this.handleDateChange(date, 'userdt2');
														}}
														showMonthDropdown="true"
														showYearDropdown="true"
														className="form-control"
														placeholderText="Select"
													/>
												</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</Form.Group>
						</Container>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.props.hideModal}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							this.props.onSave(this.state.data);
						}}
					>
						OK
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default VendorInvoicesDistributionModal;
