import React from 'react';
import { Col, Row, Form, Breadcrumb, Container, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import { routeParam, WithRouter } from '../../../../helpers/Router';
import { FooterFormAction } from '../../../components/Form';
import { HeaderLight } from '../../../components/Header';
import ThreadComponent from '../../ThreadComponent';

class ItemAddComponentEditOrderStatusInvoice extends ThreadComponent {
	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={routeParam(URI.project.vendorOrder.modifyStatus, {
							id: 1,
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.project.view, { id: 1 }),
							}}
							linkAs={Link}
						>
							JOH01
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.project.viewPage, {
									id: 1,
									page: 'items',
								}),
							}}
							linkAs={Link}
						>
							Items
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.project.itemAdd, {
									id: 1,
									page: 'items',
								}),
							}}
							linkAs={Link}
						>
							Add Item
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.project.itemAdd, { id: 1 }),
							}}
							linkAs={Link}
						>
							Info
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.project.item.componentAddPage, {
									id: 1,
									page: 'order-status',
								}),
							}}
							linkAs={Link}
						>
							Component
						</Breadcrumb.Item>
						<Breadcrumb.Item>Edit Component Status Invoice</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>Edit Component Status Invoice</HeaderLight.Title>

					{/* Submit Button */}
					<Button variant="primary">Save</Button>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	render() {
		return (
			<>
				{/* Header */}
				{this.renderHeader()}

				{/* Content */}
				<div className="content-padding min-height">
					<Container fluid>
						<Row xs={1} lg={2} className="mb-4">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">TX No.</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Vendor</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Invoice No.</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Invoice Date</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">PO/WO No.</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Qty</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Cost</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Check No.</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Check Date</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col>
												<Form.Control type="text" placeholder="Please enter" />
											</Col>
											<Col></Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(ItemAddComponentEditOrderStatusInvoice);
