import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';

const api = new ApiService();

const getFile = async (id: string) => {
	const file = (await api.getFile(id)) as Blob;

	return file;
};

const useGetFile = (
	id: string,
	options?: UseQueryOptions<Blob, unknown, Blob, string[]>
) => {
	const itemQuery = useQuery({
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryKey: [id, 'files'],
		queryFn: () => getFile(id),
		...(options || {}),
	});

	useEffect(() => {
		if (itemQuery.error) {
			displayAlertError(
				'An error occurred fetching the file, please try again'
			);
		}
	}, [itemQuery.error]);
	return itemQuery;
};
export default useGetFile;
