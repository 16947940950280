import React from 'react';
import { Container, Col, Row, Badge, Button } from 'react-bootstrap';

class Badges extends React.Component {
	render() {
		return (
			<div className="d-block mt-5">
				<div className="bg-secondary-grey pt-5 pb-4">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Badges</h2>
							</Col>
						</Row>
					</Container>
				</div>
				<Container className="mt-5">
					<Row style={{ maxWidth: '800px' }}>
						<Col>
							{/* Primary */}
							<Badge>Small tag</Badge>
							<br />
							<br />
							<Badge className="left">
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
								Small tag
							</Badge>
							<br />
							<br />
							<Badge className="right">
								Small tag
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
							</Badge>
						</Col>

						<Col>
							{/* Secondary */}
							<Badge bg="secondary">Small tag</Badge>
							<br />
							<br />
							<Badge bg="secondary" className="left">
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
								Small tag
							</Badge>
							<br />
							<br />
							<Badge bg="secondary" className="right">
								Small tag
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
							</Badge>
						</Col>

						<Col>
							{/* Gray */}
							<Badge bg="primary-grey">Small tag</Badge>
							<br />
							<br />
							<Badge bg="primary-grey" className="left">
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
								Small tag
							</Badge>
							<br />
							<br />
							<Badge bg="primary-grey" className="right">
								Small tag
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
							</Badge>
						</Col>

						<Col>
							{/* Light Outline */}
							<Badge bg="light-outline">Small tag</Badge>
							<br />
							<br />
							<Badge bg="light-outline" className="left">
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
								Small tag
							</Badge>
							<br />
							<br />
							<Badge bg="light-outline" className="right">
								Small tag
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
							</Badge>
						</Col>

						<Col>
							{/* Dark Outline */}
							<Badge bg="dark-outline">Small tag</Badge>
							<br />
							<br />
							<Badge bg="dark-outline" className="left">
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
								Small tag
							</Badge>
							<br />
							<br />
							<Badge bg="dark-outline" className="right">
								Small tag
								<Button
									variant="light"
									bsPrefix="btn-close"
									data-dismiss="alert"
									aria-label="Close"
								>
									{' '}
									<span
										aria-hidden="true"
										className="visually-hidden-focusable"
									>
										&times;
									</span>{' '}
								</Button>
							</Badge>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Badges;
