import { CellContext } from '@tanstack/react-table';
import { IPurchaseOrder } from 'api/resources/purchaseOrders';
import URI from 'legacy/defaults/RoutesDefault';
import { generatePath, Link, useParams } from 'react-router-dom';

const PurchaseOrderCell = ({
	info,
}: {
	info: CellContext<IPurchaseOrder, unknown>;
}) => {
	const { id: projectId } = useParams();

	const linkRoute = generatePath(URI.project.vendorOrder.modifyStatus, {
		id: projectId,
		poId: info.row.original.poId,
	});

	return (
		<Link to={linkRoute} className="!tw-text-[#211f21] tw-underline tw-text-xs">
			{info.getValue() as string}
		</Link>
	);
};

PurchaseOrderCell.displayName = 'PurchaseOrderCell';

export default PurchaseOrderCell;
