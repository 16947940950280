import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Button } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import CatalogItemInfo from './CatalogItemInfo';
import CatalogItemSpecification from './CatalogItemSpecification';
import CatalogItemPricing from './CatalogItemPricing';
import { HeaderLight } from '../../../components/Header';
import { FooterFormAction } from '../../../components/Form';

class ItemAdd extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeMenu: 'info',
		};

		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	componentInit() {
		this.setTitle('Catalog No. 1234 | Catalog');
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.getAttribute('data-menu'))
		);
	};

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.item.catalog.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							linkProps={{ to: URI.item.catalog.base }}
							linkAs={Link}
						>
							Items
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.item.catalog.base }}
							linkAs={Link}
						>
							Catalogs
						</Breadcrumb.Item>
						<Breadcrumb.Item>Item</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>Catalog No. 1234</HeaderLight.Title>

					<div>
						{/* Submit Button */}
						<Button variant="primary me-2">Save & New</Button>

						<Button variant="primary">Save</Button>
					</div>
				</HeaderLight.Content>
				<HeaderLight.Actions>
					<li>
						<Link
							to="/catalogs/item"
							data-menu="info"
							className={
								`btn text-charcoal ` +
								(this.state.activeMenu === 'info' ? 'active' : '')
							}
							onClick={this.handleMenuClick}
						>
							Info
						</Link>
					</li>
					<li>
						<Link
							to="/catalogs/item"
							data-menu="specification"
							className={
								`btn text-charcoal ` +
								(this.state.activeMenu === 'specification' ? 'active' : '')
							}
							onClick={this.handleMenuClick}
						>
							Specification
						</Link>
					</li>
					<li>
						<Link
							to="/catalogs/item"
							data-menu="pricing"
							className={
								`btn text-charcoal ` +
								(this.state.activeMenu === 'pricing' ? 'active' : '')
							}
							onClick={this.handleMenuClick}
						>
							Pricing Detail
						</Link>
					</li>
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	getContent() {
		if (this.state.activeMenu === 'info') return <CatalogItemInfo />;
		if (this.state.activeMenu === 'specification')
			return <CatalogItemSpecification />;
		if (this.state.activeMenu === 'pricing') return <CatalogItemPricing />;
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid>{this.getContent()}</Container>
				</div>

				<FooterFormAction>
					<Button variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(ItemAdd);
