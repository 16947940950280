import Table from 'legacy/app/components/newTable/Table';
import { ColumnDef } from '@tanstack/react-table';
import { currencyFormat } from 'legacy/helpers/Number';
import { useEffect, useMemo } from 'react';
import { useGetProposals } from 'api/hooks/useGetProposals';
import { useParams } from 'react-router-dom';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { Item } from 'legacy/lib/api/types/Item';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';
import { IProposal } from 'api/resources/proposals';
import { formatDate } from 'legacy/helpers/Date';

const ProposalTable = ({ itemToEdit }: { itemToEdit: Item }) => {
	const { id: projectId } = useParams();
	const { data: project } = useGetProject(projectId as string);
	const filter = `?$filter=projectCode eq '${encodeURIComponent(
		project?.proj || ''
	)}' AND itemNumber eq '${itemToEdit.item}'&$orderby=proposalNumber asc`;

	const {
		data: proposals,
		isLoading,
		error,
	} = useGetProposals(filter, { enabled: !!project });

	const columns = useMemo<ColumnDef<IProposal>[]>(
		() => [
			{
				accessorKey: 'proposalNumber',
				header: 'Proposal/Doc',
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'proposalName',
				header: 'Name',
				size: 300,
				enableSorting: false,
				cell: (info) => info.getValue(),
			},

			{
				accessorFn: (col) => formatDate(col.proposalDate || ''),
				header: 'Date',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.receivedDeposit, '$'),
				header: 'Rec. Deposit',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'checkNumber',
				header: 'Client Check no.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDate(col.checkDate || ''),
				header: 'Check date',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
		],
		[]
	);

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error getting the proposals information, please try again'
			);
		}
	}, [error]);

	if (isLoading) {
		return <Spinner isChild />;
	}
	return !error ? (
		<div className="tw-pl-8 tw-pr-8">
			<div className="tw-max-w-full tw-p-4 tw-flex tw-flex-col tw-gap-8">
				<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
					<p className="tw-text-base tw-font-bold tw-m-0">Proposal</p>
				</div>
				<div className="tw-pl-6 tw-pr-6">
					<Table columns={columns} data={proposals as IProposal[]} />
				</div>
			</div>
		</div>
	) : null;
};

ProposalTable.displayName = 'ProposalTable';

export default ProposalTable;
