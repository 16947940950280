import { Button, Col, Modal, Row } from 'react-bootstrap';
import React from 'react';

export const DeleteModal = ({ onConfirm, onCancel }) => {
	return (
		<Modal
			show={true}
			aria-labelledby="action-modal"
			className="a-modal"
			backdrop="static"
			centered
		>
			<Modal.Body>
				<Row className="mb-3">
					<Col className="px-5">
						<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
							Confirm Deletion
						</h5>
						<div className="text-center mx-auto fw-bold pt-4">
							<h6>
								Are you sure you want to delete the file(s)? Once you delete,
								it's gone for good.
							</h6>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-3 px-5">
				<Row className="w-100">
					<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
						<Button variant="danger" onClick={onConfirm} className="w-100 m-2">
							OK
						</Button>

						<Button
							variant="trans-light border-secondary-ash"
							onClick={onCancel}
							className="w-100 m-2"
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};
