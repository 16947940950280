import { uniqBy, uniqWith } from 'lodash';
import { ApiService } from '../../../../lib/api/HttpService';

type ModTypes =
	| 'employee'
	| 'vendor'
	| 'location'
	| 'project'
	| 'client'
	| 'address'
	| 'purchase-order'
	| 'proposal'
	| 'sales-category'
	| 'activity'
	| 'tax-code'
	| 'inventory'
	| 'warehouses';

const api = new ApiService();

/**
 * This function is used to get the service function based on the type.
 *
 * @param {string} type - The type of the service function. It can be 'employee', 'vendor', 'location', 'project', or 'client'.
 * @returns {function} - A function that takes a urlQuery as a parameter and returns a Promise.
 */
export function getServiceFn(
	type: ModTypes
): (urlQuery: string, params: any) => Promise<any> {
	switch (type) {
		case 'employee':
			return async (urlQuery: string) =>
				await api.getClientEmployeesSummaries(urlQuery);

		case 'vendor':
			return async (urlQuery: string) =>
				await api.getVendorsSummaries(urlQuery);

		case 'client':
			return async (urlQuery: string) =>
				await api.getClientsSummaries(urlQuery);

		case 'project':
			return async (urlQuery: string) =>
				await api.getProjectsSummaries(urlQuery);

		case 'address':
			return async (urlQuery: string) =>
				await api.getAddressesSummaries(urlQuery);

		case 'purchase-order':
			return async (urlQuery: string) =>
				await api.getProjectPurchaseOrdersSummaries(urlQuery);

		case 'proposal':
			return async (urlQuery: string) =>
				await api.getProjectProposalsSummaries(urlQuery);

		case 'sales-category':
			return async (urlQuery: string) =>
				await api.getSalesCategoriesSummaries(urlQuery);

		case 'activity':
			return async (urlQuery: string) =>
				await api.getTimeActivitiesSummaries(urlQuery);

		case 'tax-code':
			return async (urlQuery: string) =>
				await api.getSalesTaxCodesSummaries(urlQuery);

		case 'inventory':
			return async (urlQuery: string) => await api.getInventory(urlQuery);

		case 'warehouses':
			return async (urlQuery: string) => await api.getAddresses(urlQuery);

		default:
			// Locations.
			return async (urlQuery: string, params: any) => {
				return params?.isNext
					? await api.getLocations(urlQuery)
					: await api.getUniqueLocations(urlQuery);
			};
	}
}

/**
 * This function is used to get the default URL query based on the type.
 *
 * @param {string} type - The type of the service function. It can be 'employee', 'vendor', 'location', 'project', or 'client'.
 * @param {string} urlQuery - The URL query string.
 * @returns {string} - The default URL query.
 */
export function getDefaultUrlQuery(type: ModTypes, urlQuery = ''): string {
	switch (type) {
		case 'employee':
			return `${
				urlQuery ? urlQuery + ` and ` : '?$filter='
			}inactive eq false&$orderby=vendorn asc`;

		case 'vendor':
			return `${
				urlQuery ? urlQuery + ` and ` : '?$filter='
			}inactive eq false&$orderby=vendorn asc`;

		case 'client':
			return `${
				urlQuery ? urlQuery + ` and ` : '?$filter='
			}inactive eq false&$orderby=ClientName asc`;

		case 'project':
			return `${
				urlQuery ? urlQuery + ` and ` : '?$filter='
			}closeddt eq null&$orderby=projn asc`;

		case 'address':
			return `${
				urlQuery ? urlQuery + ` and ` : '?$filter='
			}inactive eq false&$orderby=attention asc`;

		case 'sales-category':
			return `${urlQuery ? urlQuery + `&` : '?'}$orderby=scatn asc`;

		case 'activity':
			return `${
				urlQuery ? urlQuery + ` and ` : '?$filter='
			}inactive eq false&$orderby=activityn asc`;

		case 'tax-code':
			return `${
				urlQuery ? urlQuery + ` and ` : '?$filter='
			}inactive eq false&$orderby=taxcn asc`;

		case 'inventory':
			return `${urlQuery ? urlQuery : ''}`;

		case 'warehouses':
			return `${urlQuery ? urlQuery : ''}`;

		default:
			return `${urlQuery ? urlQuery + `` : ''}`;
	}
}

/**
 * This function overrides the query property based on the type.
 *
 * @param {string} type - The type of the service function. It can be 'employee', 'vendor', 'location', 'project', or 'client'.
 * @param {any} props - The properties of the dropdown.
 * @returns {object} - The overridden properties.
 */

export function overrideQueryProperty(type: ModTypes, props: any): any {
	return {
		...props,
		...{
			urlQuery: getDefaultUrlQuery(type, props.urlQuery),
		},
	};
}
