import React, { memo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'legacy/lib/api/HttpService';
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown';

const api = new ApiService();

const getVendors = async () => {
	const vendors = await api.getVendors();

	return vendors
		.filter((v: any) => !v.inactive && !v.donotallowonspecs)
		.map((v) => ({
			id: v.id,
			key: v.vendor,
			value: v.vendorn,
		}));
};

const arePropsEqual = (
	prevProps: TBaseDropdownProps,
	nextProps: TBaseDropdownProps
) => {
	return (
		prevProps.blurInputOnSelect === nextProps.blurInputOnSelect &&
		prevProps.isDisabled === nextProps.isDisabled &&
		prevProps.inputId === nextProps.inputId &&
		JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value)
	);
};

const VendorDropdown = memo((props: TBaseDropdownProps) => {
	const { data, isLoading } = useQuery({
		queryKey: ['dropdowns', 'vendorSummaries'],
		queryFn: getVendors,
		refetchOnWindowFocus: false,
		refetchInterval: false,
	});

	const options =
		data?.map((option) => {
			return {
				label: `${option.value ?? ''} ${
					option.key ? `[${option.key}]` : ''
				}`.trim(),
				value: option.key,
			};
		}) || [];

	return (
		<BaseDropdown
			{...props}
			isCreatable
			isLoading={isLoading}
			options={options}
			isClearable
		/>
	);
}, arePropsEqual);

VendorDropdown.displayName = 'VendorDropdown';

export default VendorDropdown;
