import { CellContext } from '@tanstack/react-table';
import { IPlaidAccount } from 'api/resources/plaidAccounts';
import URI from 'legacy/defaults/RoutesDefault';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

export const TableLinkCell = ({
	info,
}: {
	info: CellContext<IPlaidAccount, unknown>;
}) => {
	const navigate = useNavigate();
	return (
		<Button
			variant="link"
			onClick={() =>
				navigate(`${URI.accountSettings.plaidAccounts}/${info.row.original.id}`)
			}
		>
			<Link
				to={`${URI.accountSettings.plaidAccounts}/${info.row.original.id}`}
				className="!tw-text-[#211f21] tw-underline tw-text-xs tw-pointer-events-none"
			>
				{info.getValue() as string}
			</Link>
		</Button>
	);
};
TableLinkCell.displayName = 'TableLinkCell';
