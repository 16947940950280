import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';

import { AxiosError } from 'axios';
import { INVALID_REQUESTS, UserError } from '../types/UserError';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';
import {
	TWhatChangedComponentPayload,
	TWhatChangedComponentResponse,
} from '../types/WhatChangedComponent';

const api = new ApiService();

const recalculate = async (
	id: number,
	payload: TWhatChangedComponentPayload
) => {
	return api.componentRecalculate(id, payload);
};

const useComponentRecalculate = (
	id: number,
	queryKey: string,
	options?: UseQueryOptions<
		TWhatChangedComponentResponse,
		unknown,
		TWhatChangedComponentResponse
	>
) => {
	const payload = JSON.parse(queryKey);
	const recalculateHook = useQuery({
		refetchOnWindowFocus: false,
		queryKey: [id, 'recalculateComponent', queryKey],
		queryFn: () => recalculate(id, payload),
		enabled: false,
		retry: (_, error) => {
			return (
				(error as AxiosError<UserError>)?.response?.data?.userError !==
				INVALID_REQUESTS.INVALID_INPUT
			);
		},
		...(options || {}),
	});

	useEffect(() => {
		if (
			(recalculateHook.error as AxiosError<UserError>)?.response?.data
				?.userError === INVALID_REQUESTS.INVALID_INPUT
		) {
			displayAlertError(INVALID_REQUESTS.INVALID_INPUT);
		}
	}, [recalculateHook.error]);
	return recalculateHook;
};

export default useComponentRecalculate;
