import { Component } from 'react';
import { Row, Col, Dropdown, Form, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import URI from '../../defaults/RoutesDefault';
import { routeParam } from '../../helpers/Router';
import { Resource } from '../../resources/Resources';
import { renderItemImage } from '../../utilities/Images';

class ImageView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			imageData: props.imageData,
			imagesLoaded: props.imagesLoaded,
		};
	}

	componentDidUpdate(prevProps, provState) {
		if (prevProps.imagesLoaded !== this.props.imagesLoaded) {
			this.setState({
				imageData: this.props.imageData,
				imagesLoaded: this.props.imagesLoaded,
			});
		}
	}

	static Media(props) {
		const data = props.data || {};
		const index = props.index || {};
		const handleSelectItem = props.handleSelectItem || null;
		const imageData = props.imageData ?? {};
		const imagesLoaded = props.imagesLoaded ?? false;

		return (
			<Col className="a-clipper-media-item">
				<div
					className={
						`a-clipper-media-item-handle border rounded ` +
						(data.checked ? `active` : '')
					}
				>
					<div className="d-flex justify-content-between">
						<div>
							{(() => {
								if (!props.noCheck) {
									return (
										<Form.Check
											inline
											label=""
											name={`a-clipper-chk-` + index}
											type="checkbox"
											id={`a-clipper-chk-` + index}
											data-id={data.id}
											onChange={handleSelectItem}
										/>
									);
								}
							})()}
						</div>
					</div>
					{(() => {
						if (data.primaryImageId) {
							return (
								<div className="image-container">
									<div className="inner d-flex align-items-center justify-content-center">
										{renderItemImage({
											style: {
												objectFit: 'contain',
												maxHeight: '100%',
												maxWidth: '100%',
												height: 'auto',
											},
											item: data,
											imagesLoaded,
											imageData,
											property: 'primaryImageId',
											altProperty: 'itemTitle',
											loaderType: 'skeleton',
										})}
									</div>
								</div>
							);
						} else {
							return (
								<div className="image-container">
									<div className="inner">
										<Image
											src={Resource.Image.ClipperFallback.default}
											alt={data.itemTitle}
										></Image>
									</div>
								</div>
							);
						}
					})()}

					<div className="text-center">
						<h6 className="fs-md  px-2">
							<Link
								to={routeParam(URI.project.newItemEdit, {
									id: data.projectId,
									itemId: data.id,
								})}
								className="text-charcoal hover-view-icon"
							>
								{data.itemName}
							</Link>
						</h6>
					</div>
				</div>
			</Col>
		);
	}

	render() {
		const data = this.props.data || [];
		const noCheck = this.props.noCheck || null;
		const showModal = this.props.showModal;
		const handleSelectItem = this.props.handleSelectItem;
		const { imageData, imagesLoaded } = this.props;

		return (
			<div className={`a-clipper-media px-0 ${this.props.className}`}>
				<Row className={`a-clipper-sizer a-clipper-${this.props.size || 'md'}`}>
					{data.map(function (object, i) {
						return (
							<ImageView.Media
								data={object}
								index={i}
								key={i}
								noCheck={noCheck}
								showModal={showModal}
								handleSelectItem={handleSelectItem}
								imageData={imageData}
								imagesLoaded={imagesLoaded}
							/>
						);
					})}
				</Row>
			</div>
		);
	}
}

export default ImageView;
