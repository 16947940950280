import React from 'react';
import { Row, Col, Form, Table, Button } from 'react-bootstrap';
import noItems from '../../../assets/images/icons/manualdeposit.svg';
import { Link } from 'react-router-dom';
import ThreadComponent from '../ThreadComponent';
import URI from '../../../defaults/RoutesDefault';
import { getRouteWithParam, WithRouter } from '../../../helpers/Router';
import InputBar from '../../components/InputBar';
import { ApiService } from '../../../lib/api/HttpService';
import { currencyFormat } from '../../../helpers/Number';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import { generatePath } from 'react-router';
import { delay, find, isEmpty } from 'lodash';
import { showLoading } from '../../../helpers/Loading';
import { getCheckAllClass } from '../../../utilities/ModuleHelper';
import FilterSort from '../../../utilities/modules/FilterSort';
import SecureLink from '../../../app/components/security/SecureLink';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';

class ProjectAddBudget extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort(
			'project_budget_list_' + this.int(this.props.tableOnly)
		);
		this.fs.setDefaultSort('code desc');
		this.state = {
			isEnabledInputBar1: true,
			dataIsLoaded: false,
			data: [],
			project: this.props.project,
			checks: [],
			totalChecked: 0,
			sortProperty: this.fs.getSort() ?? 'code desc',
		};

		// This binding is necessary to make `this` work in the callback
		this.handleInputBarChange = this.handleInputBarChange.bind(this);

		this.api = new ApiService();
	}

	int(prop) {
		return Number(Boolean(prop));
	}

	componentInit() {
		this.hasFormAction = true;
	}

	async componentDidMount() {
		if (!isEmpty(this.props.project?.proj)) {
			this.setState(
				{
					project: this.props.project,
					dataIsLoaded: false,
					checks: [],
					totalChecked: 0,
				},
				this.loadData
			);
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		let project = {};
		if (
			previousProps.project !== this.props.project ||
			previousProps.deleteState !== this.props.deleteState
		) {
			project = this.props.project || {};

			this.setState(
				{
					project,
					dataIsLoaded: false,
					checks: [],
					totalChecked: 0,
				},
				this.loadData
			);
		}
	}

	async loadData() {
		await this.fetchBudgets();
		delay(() => {
			this.enableSortTable();
		}, 500);
	}

	async fetchBudgets() {
		this.setState({
			dataIsLoaded: false,
		});
		let data = await this.api.getBudgets(`?${this.buildFilters()}`);
		this.props.onLoadedBudgets(data);
		this.setState({
			data: data,
			dataIsLoaded: true,
		});
	}

	buildFilters() {
		let filtersQuery = `$filter = proj eq '${this.props.project.proj}'`;

		if (this.state.sortProperty !== '') {
			filtersQuery += `&$orderby=${this.state.sortProperty}`;
		}

		return filtersQuery;
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.props.onDataChange({ key: key, value: value });
	};

	handleInputBarChange = (e) => {
		// Get the target menu.
		const id = e.target.getAttribute('data-state');

		// Set the new state.
		this.setState((prevState) => {
			let data = {};
			data[id] = e.target.checked;

			return data;
		});

		handleChange(e);
	};

	handleSelectAllChecks = (e) => {
		let checks = [];
		const isChecked = e.target.checked;
		if (isChecked) {
			checks = this.state.data.map((item) => {
				return item.code;
			});
		}

		this.setState({
			checks,
			totalChecked: checks.length,
		});

		const types = this.getCheckedBudgetTypes(checks);
		this.props.onChecksChanged(checks, types);
	};

	handleCheck = (id) => {
		const { checks } = this.state;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);

		this.setState({
			checks,
			totalChecked: checks.length,
		});

		const types = this.getCheckedBudgetTypes(checks);
		this.props.onChecksChanged(checks, types);
	};

	getCheckedBudgetTypes(checks) {
		const { data } = this.state;
		let budgetTypes = [];
		// Lets get all related budget types.
		if (!isEmpty(checks)) {
			for (const code of checks) {
				const budget = find(data, { code });
				budgetTypes.push(budget?.type ?? null);
			}
		}

		return budgetTypes;
	}

	renderInputBar() {
		return (
			<Col lg="12">
				<InputBar
					className="toggle-input full"
					childprops={{
						replaceclassmatch: 'justify-content-between',
						replaceclassmatchwith: 'justify-content-start',
					}}
				>
					<InputBar.Links style={{ minWidth: '190px' }}>
						<InputBar.Link
							className="single-link"
							childprops={{
								className: 'justify-content-center justify-content-md-start',
							}}
						>
							<div style={{ width: 'auto' }}>
								<Form.Check
									inline
									defaultChecked={this.state.isEnabledInputBar1}
									label="Override Total"
									type="checkbox"
									id="budgettoverride"
									className="pe-3"
									onChange={this.handleInputBarChange}
									data-state="isEnabledInputBar1"
								/>
							</div>
						</InputBar.Link>
					</InputBar.Links>
					<InputBar.Links
						className={`${
							this.state.isEnabledInputBar1 ? '' : 'inactive'
						} full-width`}
						childprops={{ className: 'justify-content-center' }}
					>
						<InputBar.Link>
							<Form.Label htmlFor="inputPassword5" className="ilabel">
								Budget
								<br />
								Total
							</Form.Label>
							<div className="form-group-extra reversed">
								<Form.Control
									type="text"
									placeholder="0.00"
									size="sm"
									disabled={!this.state.isEnabledInputBar1}
									id="projn"
									onChange={this.handleChange}
									defaultValue={this.updatedData?.projn ?? data?.projn}
								/>
								<span>$</span>
							</div>
						</InputBar.Link>
					</InputBar.Links>
				</InputBar>
			</Col>
		);
	}

	renderInputBarFooter() {
		return (
			<InputBar style={{ maxWidth: '480px' }}>
				<InputBar.Links>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Overall Warning
						</Form.Label>
						<Form.Select size="sm" disabled={!this.state.isEnabledInputBar1}>
							<option>None</option>
						</Form.Select>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							Overall Tolerance
						</Form.Label>
						<div className="form-group-extra">
							<Form.Control
								type="text"
								placeholder="0"
								size="sm"
								disabled={!this.state.isEnabledInputBar1}
								defaultValue={''}
							/>
							<span>
								<i className="ri-percent-line fs-xl"></i>
							</span>
						</div>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		);
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.fetchBudgets
						);
					},
					false
				);
			});
		}
	}

	handleChangePage = async (page) => {
		this.fetchItems(page);

		this.setState({
			page: page,
		});
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	render() {
		const { data, checks, totalChecked, dataIsLoaded } = this.state;
		return (
			<>
				{data.length > 0 || !dataIsLoaded ? (
					<Row>
						{(() => {
							return !this.props.tableOnly ? this.renderInputBar() : null;
						})()}

						<Col
							lg="12"
							className="mt-2 mb-5 pb-5 position-relative table-action-bar"
						>
							<div className="table-gradient">
								<Table striped responsive className="a-table">
									<thead>
										<tr key="0" className="a-table-heading">
											<th>
												<Form.Check
													label=""
													type="checkbox"
													checked={totalChecked > 0}
													className={getCheckAllClass(totalChecked, data)}
													onChange={this.handleSelectAllChecks.bind(this)}
												/>
											</th>
											<th>
												<span
													className={this.sortClass('typeName')}
													data-field="typeName"
												>
													Type
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('code')}
													data-field="code"
												>
													Code
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('codeName')}
													data-field="codeName"
												>
													Name
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('budgett')}
													data-field="budgett"
												>
													Budget
												</span>
											</th>
											{/* show v1
                                        <th>Override Dep.%</th>
                                        <th>Client Dep.%</th>
                                        <th>Override M/U%</th>
                                        <th>Merchandise M/U%</th>
                                        <th>Freight M/U%</th>
                                        <th>Crating M/U%</th>
                                        <th>Install Labor M/U%</th>
                                        <th>Design Fee M/U%</th>
                                        <th>Time M/U%</th>
                                        */}
										</tr>
									</thead>
									<tbody>
										{!this.state.dataIsLoaded
											? showLoading()
											: this.state.data.map((item, i) => (
													<tr
														key={i}
														data-key={i}
														className={
															this.state.checks.includes(item.code)
																? `active`
																: ''
														}
													>
														<td>
															<Form.Check
																label=""
																type="checkbox"
																data-key={i}
																id={`item-check-${i}`}
																checked={this.state.checks.includes(item.code)}
																onChange={this.handleCheck.bind(
																	this,
																	item.code
																)}
															/>
														</td>
														<td>
															{this.props.closed ? (
																item.typeName
															) : (
																<SecureLink
																	attributeNo={200}
																	attributeType={
																		SECURITY_ATTRIBUTE_TYPES.DenyEdit
																	}
																	to={getRouteWithParam(
																		URI.project.budgetEdit,
																		{
																			id: this.state.project.id,
																			bid: item.code,
																		}
																	)}
																	className="text-charcoal hover-view-icon"
																>
																	{item.typeName}
																</SecureLink>
															)}
														</td>
														<td>{item.code}</td>
														<td></td>
														<td>{currencyFormat(item.budgett, '$')}</td>
														{/* show v1
                                            <td>{item.overridedep}</td>
                                            <td>{item.clientdep}</td>
                                            <td>{item.overridemu}</td>
                                            <td>{item.markupmerc}</td>
                                            <td>{item.markupfreight}</td>
                                            <td>{item.markupdesign}</td>
                                            <td>{item.markupinstall}</td>
                                            <td>{item.markuplabor}</td>
                                            <td>{item.markupother}</td>
                                            */}
													</tr>
											  ))}
									</tbody>
								</Table>
							</div>
						</Col>

						{/* show v1
                    <Col sm="12">
                        {this.renderInputBarFooter()}
                    </Col>
                    */}
					</Row>
				) : dataIsLoaded ? (
					<div className="row justify-content-center text-center py-5">
						<div className="col-md-3">
							<img src={noItems} className="mw-100 mb-4" alt="" />

							<h6>Create a Budget</h6>
							<p>This is where you manage the budgets for this project.</p>

							<Button
								as={Link}
								to={generatePath(URI.project.budgetAdd, {
									id: this.props.project.id,
								})}
								variant="primary"
								size="md"
								className="btn-icon mt-4"
							>
								<i className="ri-add-line ri-lg"></i> Add a Budget Line
							</Button>
						</div>
					</div>
				) : null}
			</>
		);
	}
}

export default WithRouter(ProjectAddBudget);
