import React from 'react';
import { Container } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import { ApiService } from '../../../../lib/api/HttpService';
import { getSubComponent, Is } from '../../../../helpers/Util';
import { displayAlertError } from '../../../../utilities/Response';
import Documents from '../../documents/Documents';
import ItemAddSpecification from '../item-add/ItemAddSpecification';
import ItemAddStatus from '../item-add/ItemAddStatus';

class ItemAdd extends ThreadComponent {
	constructor(props) {
		super(props);
		this.api = new ApiService();

		this.state = {
			company: null,
			data: {},
			newId: '',
			project: null,
		};
	}

	componentInit() {
		this.updatedData = {};
		this.uploadedImage = null;
		this.id = this.props.params.itemId;
		this.projectId = parseInt(this.props.params.id);
		this.isEditing = this.props.params.itemId != null;

		this.setTitle(`${this.isEditing ? 'Edit' : 'Add'} Item`);
	}

	handleDataChange = (data) => {
		this.updatedData[data.key] = data.value;
		this.props.onChildrenDataChange(this.updatedData);
	};

	getContent() {
		const { data } = this.state;

		return getSubComponent(this.props.activeMenu, {
			specification: (
				<ItemAddSpecification
					data={data}
					item={this.props.item}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			),
			status: (
				<ItemAddStatus
					data={data}
					project={this.props.project}
					item={this.props.item}
					updatedData={this.updatedData}
					onDataChange={this.handleDataChange}
				/>
			),
			documents: (
				<Documents
					objectType="Item"
					objectId={this.id}
					onRefresh={(numAttachments) => {
						this.props.setNumberOfDocuments(numAttachments);
					}}
				/>
			),
		});
	}

	render() {
		const invalidTabs = ['info', 'workroom', 'pricing', 'status'];
		return !invalidTabs.includes(this.props.activeMenu) ? (
			<div className="content-padding min-height has-action-bar">
				<Container fluid>{this.getContent()}</Container>
			</div>
		) : null;
	}
}

export default WithRouter(ItemAdd);
