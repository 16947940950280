import React from 'react';
import { WithRouter } from '../../../helpers/Router';
import { InventoryHeader } from './InventoryHeader';
import { InventorySettingsForm } from 'legacy/app/components/forms/InventoryItemForm/InventorySettingsForm';

const InventorySettingss = () => {
	return (
		<>
			<InventoryHeader />
			<InventorySettingsForm />
		</>
	);
};

InventorySettingss.displayName = 'InventorySettings';

export const InventorySettings = WithRouter(InventorySettingss);
