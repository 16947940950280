import { apiClient } from 'api/apiClient';

const RESOURCE = '/Reconciliations';

export interface IReconciliation {
	userName?: string | null;
	statementDate: string;
	userCode?: number | null;
	computerDate?: string | null;
	statementBalance?: number | null;
	bookBalance?: number | null;
	computerBalance?: number | null;
	cashAccount?: string | null;
}

export interface IDeleteReconciliationsParams {
	cashAccount: string | null;
	statementDate: string;
}

export const getReconciliations = async (): Promise<IReconciliation[]> => {
	const { data } = await apiClient.get(RESOURCE, {});
	return data;
};

export const deleteReconciliations = async (
	params: IDeleteReconciliationsParams
) => {
	const { data } = await apiClient.post(RESOURCE, params);
	return data;
};
