import { ECompIdentifiers } from 'legacy/lib/api/types/CompView';
import { TSelectedChoice } from './PricingTabChoiceSelector';
import PricingTabcomponentValue from './PricingTabComponentValue';
import { Item } from 'legacy/lib/api/types/Item';

const PricingTabComponent = ({
	showHeader,
	component,
	selectedChoice,
	itemToEdit,
	type,
}: {
	type: string;
	itemToEdit: Item;
	component: `${ECompIdentifiers}`;
	showHeader: boolean;
	selectedChoice: TSelectedChoice;
}) => {
	const isTotal = component === ECompIdentifiers.T;

	return (
		<div className="tw-flex tw-gap-4 tw-pl-16 tw-mt-4">
			<div className="tw-grid tw-items-center tw-w-24">
				{showHeader && <p className="tw-font-bold">Type</p>}
				<p className="tw-m-0 tw-justify-self-start tw-break-all">
					{isTotal ? 'Total' : type}
				</p>
			</div>
			<div>
				{showHeader && <p className="tw-font-bold">Cost</p>}
				<PricingTabcomponentValue
					value={
						itemToEdit[
							`${selectedChoice}${
								isTotal ? ECompIdentifiers.T : component
							}cost` as keyof Item
						] as number
					}
					symbol="+"
				/>
			</div>
			<div>
				{showHeader && <p className="tw-font-bold">Profit</p>}
				<PricingTabcomponentValue
					value={
						itemToEdit[
							`${selectedChoice}${
								isTotal ? ECompIdentifiers.T : component
							}mu` as keyof Item
						] as number
					}
					symbol="="
				/>
			</div>
			<div>
				{showHeader && <p className="tw-font-bold">Price</p>}
				<PricingTabcomponentValue
					value={
						itemToEdit[
							`${selectedChoice}${
								isTotal ? ECompIdentifiers.T : component
							}price` as keyof Item
						] as number
					}
					symbol="+"
				/>
			</div>
			<div>
				{showHeader && <p className="tw-font-bold">Sales Tax</p>}
				<PricingTabcomponentValue
					value={
						itemToEdit[
							`${selectedChoice}${
								isTotal ? ECompIdentifiers.T : component
							}stax` as keyof Item
						] as number
					}
					symbol="="
				/>
			</div>
			<div>
				{showHeader && <p className="tw-font-bold">Total</p>}
				<PricingTabcomponentValue
					value={
						itemToEdit[
							`${selectedChoice}${
								isTotal ? ECompIdentifiers.T : component
							}PriceTotal` as keyof Item
						] as number
					}
				/>
			</div>
		</div>
	);
};

PricingTabComponent.displayName = 'PricingTabcomponentToUses';

export default PricingTabComponent;
