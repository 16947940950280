import { is } from 'date-fns/locale';
import { ApiService } from '../../../lib/api/HttpService';
const api = new ApiService();

export const getCompany = async () => await api.get('company', '');
export const getCompany2 = async () => await api.get('company2', '');

const hasDirtyCompanyFields = (dirtyFields) => {
	const { timeZoneCode, logoImageId, ...dirtyOnes } = dirtyFields;

	return Object.keys(dirtyOnes).length;
};

export const patchCompanySettings = async ({
	dirtyFields,
	originalLogoImageId,
	data: { timeZoneCode, logoImageId, ...settings },
}) => {
	// if regular company fields changed, patch that
	if (hasDirtyCompanyFields(dirtyFields)) {
		await api.patch('company/update', '', settings);
	}

	// handle our logo file if we have one
	let logoId = null;
	if (dirtyFields.logoImageId) {
		// clean up the old file if needed
		if (originalLogoImageId) {
			try {
				await api.deleteFile(originalLogoImageId);
			} catch (error) {
				console.error('Could not delete original image, moving on...');
			}
		}

		// uplaod the new file
		if (logoImageId) {
			try {
				const { id } = await api.saveFile({
					file: logoImageId,
					FileType: 'rawimage',
				});
				logoId = id;
			} catch (error) {
				console.error('Could not save new image, moving on...');
			}
		}
	}

	// patch company2 if needed
	if (dirtyFields.timeZoneCode || dirtyFields.logoImageId) {
		const hasLogo = logoId !== null;

		await api.patch('company2', '', {
			...(dirtyFields.timeZoneCode && { timeZoneCode }),
			...(dirtyFields.logoImageId && {
				logoImageId: logoId,
				logoheighttwips: 0,
				logowidthtwips: 0,
			}),
			haslogo: hasLogo,
		});
	}
};
