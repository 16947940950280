import { ApiService } from '../lib/api/HttpService';
import { Event } from './DOM';
import { getLocalStorage, setLocalStorage } from './LocalStorage';

/**
 * Get checkbox all class.
 *
 * @param totalChecked
 * @param items
 *
 * @returns {string} string.
 */
export function getCheckAllClass(
	totalChecked: number,
	items: Array<any>
): string {
	return totalChecked > 0 && totalChecked < items?.length ? 'line' : '';
}

/**
 * Check if draft is enabled before moving to another screen.
 *
 * @returns {boolean} boolean
 */
export function isDraft(): boolean {
	return getLocalStorage('isDraft') === 'true';
}

/**
 * Check if a user wants to stay on edit screen.
 *
 * @returns {boolean} boolean
 */
export function promptDraft(): boolean {
	if (confirm('Leaving this screen will discard your changes.')) {
		setLocalStorage('isDraft', 'false');
		window.onbeforeunload = null;
		return true;
	} else {
		return false;
	}
}

type TBuildQuery = {
	searchProperties: any;
	pageSize: number;
	page: number;
	currentPage: number;
	sortProperty: string;
};
export function buildAPIQuery({
	searchProperties,
	pageSize,
	page,
	currentPage,
	sortProperty,
}: TBuildQuery) {
	const filters: string[] = [];

	Object.keys(searchProperties).forEach((key) => {
		const property = searchProperties[key];
		if (property.value) {
			if (property.type === 'number') {
				filters.push(`${key} eq ${property.value}`);
			} else {
				filters.push(`contains(${key}, '${property.value}')`);
			}
		}
	});

	const filtersQuery =
		filters.length > 0 ? `&$filter=${filters.join(' and ')}` : '';

	let queryString = `$top=${pageSize + 1}&$skip=${
		((currentPage ?? page) - 1) * pageSize
	}${filtersQuery}`;

	if (sortProperty !== '') {
		queryString += `&$orderby=${sortProperty}`;
	}

	return queryString;
}
