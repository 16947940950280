import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Form, Row, Col } from 'react-bootstrap';
import Header from 'legacy/templates/components/Header';
import URI from 'legacy/defaults/RoutesDefault';
import { getRouteWithParam } from 'legacy/helpers/Router';
import { FooterFormAction } from 'legacy/templates/components/Form';
import Select, { SingleValue } from 'react-select';
// eslint-disable-next-line no-restricted-imports
import { ApiService } from 'legacy/lib/api/HttpService';
import {
	displayAlertError,
	displayAlertSuccess,
	getErrorMessage,
} from 'legacy/utilities/Response';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import { OnboardingStatus } from 'legacy/lib/api/types/PaymentProcessorResponse';
import Spinner from 'legacy/app/components/help/Spinner';
import PaymentProcessorInfo from 'legacy/app/pages/DesignPay/components/PaymentProcessorInfo';

const api = new ApiService();

interface ICompany {
	compviewm: string;
	compviewd: string;
	compviewf: string;
	compviewi: string;
	compviewl: string;
	compviewo: string;
	serialNumber: string;
}

interface ICompany2 {
	processorFeeComponentType: number | null;
	processorFeeSalesCategory: string | null;
}

interface IWebSettings {
	serialNumber: string;
	defAllowPaymentsProp: boolean;
	defAllowPaymentsInv: boolean;
	webPayType: string;
	statusEmail: string;
}

interface IWebSettingsOptions {
	taxTransactionFee: boolean;
	processor: string;
}

interface IComponent {
	value: number;
	label: string;
}

interface IOption {
	value: string;
	label: string;
}

interface IPaymentProcessor {
	onboardingStatus: string;
	processor: string;
	onboardingUrl: string;
	allowsCreditCard: boolean;
	allowsAch: boolean;
	passACHFeesToClient: boolean;
	passCreditCardFeesToClient: boolean;
}

interface ISalesCategory {
	scat: string;
	scatn: string | null;
}

const webSettingsInitialState = {
	serialNumber: '',
	statusEmail: '',
	defAllowPaymentsProp: true,
	defAllowPaymentsInv: true,
	webPayType: '',
};

const PaymentListSettingsNew = () => {
	const [paymentProcessor, setPaymentProcessor] = useState<IPaymentProcessor>({
		onboardingStatus: '',
		processor: '',
		onboardingUrl: '',
		allowsCreditCard: false,
		allowsAch: false,
		passACHFeesToClient: false,
		passCreditCardFeesToClient: false,
	});

	const [webSettings, setWebSettings] = useState<IWebSettings>(
		webSettingsInitialState
	);

	const [salesCategoryFeesVal, setSalesCategoryFeesVal] = useState<
		SingleValue<{
			value: string | null;
			label: string;
		}>
	>({
		value: null,
		label: '',
	});

	const [webSettingsOptions, setWebSettingsOptions] =
		useState<IWebSettingsOptions>({
			taxTransactionFee: false,
			processor: '',
		});
	const [component, setComponent] = useState<IComponent | null>(null);
	const [taxList, setTaxList] = useState<IComponent[]>([]);
	const [salesCategories, setSalesCategories] = useState<IOption[]>([]);
	const [companySerialNumber, setCompanySerialNumber] = useState('');
	const [paymentTypes, setPaymentTypes] = useState<IOption[]>();
	const [isLoading, setIsLoading] = useState(false);

	const connectPaymentProcessor = useCallback(async () => {
		try {
			setIsLoading(true);

			let paymentProcessor = await api.connectPaymentProcessor('Stripe');

			if (paymentProcessor.onboardingStatus !== OnboardingStatus.Verified) {
				paymentProcessor = await api.connectPaymentProcessor('Rainforest');
			}

			if (paymentProcessor.onboardingStatus === OnboardingStatus.Verified) {
				const company = (await api.getCompany()) as ICompany;
				const companyTwo = (await api.getCompany2()) as ICompany2;
				const webSettingsOptions =
					(await api.getWebSettingsOptions()) as IWebSettingsOptions;

				const [webSettings] = (await api.getWebSettings(
					`?$filter=serialNumber eq '${company.serialNumber}'`
				)) as IWebSettings[];

				const salesCategoriesData =
					(await api.getSalesCategories()) as ISalesCategory[];

				const salesCategories = salesCategoriesData.map((category) => ({
					value: category.scat,
					label: `${category.scatn} [${category.scat}]`,
				}));
				const searchSalesCategoriesFees = salesCategories.find(
					(i) => i.value === companyTwo.processorFeeSalesCategory
				);

				const paymentTypesData = await api.getPaymentTypes();
				const paymentTypes: IOption[] = paymentTypesData
					.filter((p) => p.datatype === 3)
					.map((p) => ({ value: p.type, label: p.type }));

				const taxList: IComponent[] = [
					{ value: 0, label: company.compviewm },
					{ value: 1, label: company.compviewd },
					{ value: 2, label: company.compviewf },
					{ value: 3, label: company.compviewi },
					{ value: 4, label: company.compviewl },
					{ value: 5, label: company.compviewo },
				];

				setPaymentProcessor(paymentProcessor as IPaymentProcessor);
				setWebSettings(webSettings ?? webSettingsInitialState);
				setWebSettingsOptions(webSettingsOptions);
				setPaymentTypes(paymentTypes);
				setSalesCategories(salesCategories);
				setTaxList(taxList);
				setCompanySerialNumber(company.serialNumber);
				setComponent(
					typeof companyTwo.processorFeeComponentType === 'number'
						? taxList[companyTwo.processorFeeComponentType]
						: null
				);
				setSalesCategoryFeesVal({
					value: searchSalesCategoriesFees?.value ?? null,
					label: searchSalesCategoriesFees?.label ?? '',
				});
				history.pushState(
					null,
					'',
					getRouteWithParam(URI.settings.paymentSettings.connected, {
						connected: 'connected',
					})
				);
			}
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		connectPaymentProcessor();
	}, [connectPaymentProcessor]);

	const onChangeWebSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked, value, type } = e.target;
		setWebSettings((prevState) => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value,
		}));
	};

	const onChangeWebSettingsOption = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const { checked } = e.target;
		setWebSettingsOptions((prevState) => ({
			...prevState,
			taxTransactionFee: checked,
		}));
	};

	const onChangePaymentProcessor = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;
		setPaymentProcessor((prevState) => ({
			...prevState,
			[name]: checked,
		}));
	};

	const handleClickSave = async () => {
		setIsLoading(true);

		try {
			await api.patchWebSettingsOptions({
				allowCreditCard: paymentProcessor.allowsCreditCard,
				allowAch: paymentProcessor.allowsAch,
				taxTransactionFee: webSettingsOptions.taxTransactionFee,
				passACHFeesToClient: paymentProcessor.passACHFeesToClient,
				passCreditCardFeesToClient: paymentProcessor.passCreditCardFeesToClient,
			});

			await api.patchCompany2({
				processorFeeSalesCategory: salesCategoryFeesVal?.value,
				processorFeeComponentType: component?.value,
			});

			const payload = {
				statusEmail: webSettings?.statusEmail,
				defAllowPaymentsProp: webSettings.defAllowPaymentsProp,
				defAllowPaymentsInv: webSettings.defAllowPaymentsInv,
				webPayType: webSettings.webPayType,
			};

			if (!webSettings.serialNumber) {
				await api.postWebSettingsSerialOptions({
					...payload,
					serialNumber: companySerialNumber,
				});
			} else {
				await api.patchWebSettingsSerialOptions(companySerialNumber, payload);
			}
			displayAlertSuccess('Payment settings saved succesfully.');
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		} finally {
			setIsLoading(false);
		}
	};

	const renderPaymentSettings = () => {
		const { statusEmail = '', webPayType } = webSettings ?? {};

		const {
			allowsAch,
			passACHFeesToClient,
			allowsCreditCard,
			passCreditCardFeesToClient,
		} = paymentProcessor;

		const { taxTransactionFee } = webSettingsOptions;

		return (
			<>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col>
								<Form.Label className="mb-0">
									<strong>Default Settings</strong>
								</Form.Label>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									Payment type that online payments are posted to
								</Form.Label>
							</Col>
							<Col lg={5}>
								<Select
									placeholder="Please select"
									options={paymentTypes}
									value={{
										value: webPayType,
										label: webPayType,
									}}
									onChange={(option) => {
										setWebSettings((prevState) => ({
											...prevState,
											webPayType: option?.value ?? '',
										}));
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-4 mb-lg-5">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Status Emails</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									placeholder="Please enter"
									name="statusEmail"
									value={statusEmail}
									onChange={onChangeWebSettings}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-2 mb-lg-4">
							<Col>
								<Form.Label className="mb-0">
									<strong>Payments Allowed</strong>
								</Form.Label>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">ACH</Form.Label>
							</Col>
							<Col>
								<Form.Check
									inline
									checked={allowsAch}
									label="Allow"
									name="allowsAch"
									type="checkbox"
									id="allowsAch"
									className="vtop me-4 mb-2 mb-md-0"
									onChange={onChangePaymentProcessor}
								/>
								<Form.Check
									inline
									checked={passACHFeesToClient}
									label="Charge Fees to the Client"
									name="passACHFeesToClient"
									type="checkbox"
									id="passACHFeesToClient"
									className="vtop mb-2 mb-md-0"
									onChange={onChangePaymentProcessor}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-4 mb-lg-5">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Credit Card</Form.Label>
							</Col>
							<Col>
								<Form.Check
									inline
									checked={allowsCreditCard}
									label="Allow"
									name="allowsCreditCard"
									type="checkbox"
									id="allowsCreditCard"
									className="vtop me-4 mb-2 mb-md-0"
									onChange={onChangePaymentProcessor}
								/>
								<Form.Check
									inline
									checked={passCreditCardFeesToClient}
									label="Charge Fees to the Client"
									name="passCreditCardFeesToClient"
									type="checkbox"
									id="passCreditCardFeesToClient"
									className="vtop mb-2 mb-md-0"
									onChange={onChangePaymentProcessor}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-0 mb-lg-4">
							<Col>
								<Form.Label className="mb-0">
									<strong>Tax Settings</strong>
								</Form.Label>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-4">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
							<Col>
								<div className="d-flex align-items-center">
									<Form.Check
										inline
										checked={taxTransactionFee}
										label="Taxable under type"
										name="taxTransactionFee"
										type="checkbox"
										id="taxTransactionFee"
										className="vtop me-4"
										onChange={onChangeWebSettingsOption}
									/>
									<Select
										name="component"
										placeholder="Please select"
										className="react-select w-50"
										options={taxList}
										onChange={(option) => {
											setComponent(option);
										}}
										value={component}
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row xs={1} lg={2}>
					<Col>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									Sales Category for Fees
								</Form.Label>
							</Col>
							<Col>
								<Select
									name="salesCategoryFeesVal"
									className="react-select"
									placeholder="Please select"
									options={salesCategories}
									value={salesCategoryFeesVal}
									onChange={(option) => {
										setSalesCategoryFeesVal(option);
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={224}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
						variant="primary"
						size="lg"
						onClick={handleClickSave}
					>
						Save
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	};

	const renderHeader = () => {
		return (
			<Header>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<NavLink
								to={URI.settings.list}
								className="text-white active d-flex align-items-center text-decoration-none fw-bold me-4 mb-3"
							>
								<i className="ri-arrow-left-s-line"></i> Back
							</NavLink>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{ to: URI.settings.list }}
									linkAs={Link}
								>
									Settings
								</Breadcrumb.Item>
								<Breadcrumb.Item>Payment Settings</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Payment Settings</Header.Title>
					</Header.LeftContent>
				</Header.TitleContent>
			</Header>
		);
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<>
			{renderHeader()}
			<div className="content-padding min-height has-action-bar payment-settings">
				<Container fluid>
					{paymentProcessor.onboardingStatus === OnboardingStatus.Verified ? (
						renderPaymentSettings()
					) : (
						<PaymentProcessorInfo />
					)}
				</Container>
			</div>
		</>
	);
};

PaymentListSettingsNew.displayName = 'PaymentListSettingsNew';

export default PaymentListSettingsNew;
