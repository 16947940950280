import React from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { FormInputAdOns } from 'legacy/templates/components/Form';
import InputBar from 'legacy/templates/components/InputBar';

const CheckbookReconcileChecksTotals = () => {
	return (
		<Col xs={12} lg={6} className="mb-3 mb-lg-0">
			<div className="bg-secondary-grey py-4 mb-4 roundedx-4">
				<Row className="align-items-center px-3">
					<Col className="px-3">
						<h6 className="fw-bold mb-0">Check Totals</h6>
					</Col>
				</Row>
			</div>

			<InputBar className="full multiple bg-white border-0">
				<InputBar.Links style={{ maxWidth: 'none' }} className="px-0">
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							<strong>Sum</strong>
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							<strong>Cleared Checks</strong>
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
			<InputBar className="full multiple bg-white border-0 w-100">
				<InputBar.Links style={{ maxWidth: 'none' }} className="ms-auto px-0">
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							<strong>Service Checks</strong>
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control
								style={{
									maxWidth: '128px',
								}}
								type="text"
								placeholder="0.00"
								size="sm"
							/>
						</FormInputAdOns>
					</InputBar.Link>
					<Button
						variant="ivory"
						size="sm"
						className="btn-icon px-0"
						style={{
							width: '30px',
							minWidth: 'auto',
						}}
					>
						<i className="ri-pencil-line"></i>
					</Button>
				</InputBar.Links>
			</InputBar>
		</Col>
	);
};

CheckbookReconcileChecksTotals.displayName = 'CheckbookReconcileChecksTotals';

export { CheckbookReconcileChecksTotals };
