import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../../lib/api/HttpService';
import { displayAlert } from '../../../../utilities/Response';
import dayjs from 'dayjs';
import { getCookie } from '../../../../utilities/Auth';
import { formatFiscalMonth } from '../../../../helpers/Date';

class CheckbookTransferModal extends ThreadComponent {
	constructor(props) {
		super(props);

		const currentDate = new Date();

		this.state = {
			data: {},
			accounts: [],
			fiscalmonth: currentDate,
			jedate: currentDate,
			txdesc: 'Transfer',
		};

		this.api = new ApiService();
		this.updatedData = {
			txdesc: 'Transfer',
			jenum: 'XFER$',
			camt: 0,
		};
		this.userCode = getCookie('dmUsercode');
	}

	async componentDidMount() {
		const accounts = await this.api.getGLAccounts('?$filter=specacct eq 1');

		this.setState({
			accounts: accounts.map((account) => {
				return {
					value: account.account,
					label: `[${account.account}] ${account.accountn}`,
				};
			}),
		});
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.show !== this.props.show) {
			this.updatedData = {
				txdesc: 'Transfer',
				jenum: 'XFER$',
				camt: 0,
			};
		}
	}

	handleAction = async (e) => {
		if (this.updatedData.toAccount === this.updatedData.fromAccount) {
			displayAlert(
				'danger',
				'The source account cannot be the same as the destination account.'
			);
			return;
		}

		this.updatedData.usercode = this.userCode;
		this.updatedData.damt = 0;

		if (!this.updatedData.fiscalmonth) {
			this.updatedData.fiscalmonth = formatFiscalMonth(new Date());
		}

		if (!this.updatedData.jedate) {
			this.updatedData.jedate = new Date();
		}

		try {
			const transaction = await this.api.getTransactionCounterNext(
				'txNextGLJE'
			);
			this.updatedData.txnum = transaction.newTransactionNumber;

			const toDetails = (({ usercode, txnum, camt, toAccount, damt }) => ({
				usercode,
				txnum,
				camt: damt,
				account: toAccount,
				damt: camt,
			}))(this.updatedData);
			await this.api.createJournalEntryDetails(toDetails);

			const fromDetails = (({ usercode, txnum, camt, fromAccount, damt }) => ({
				usercode,
				txnum,
				camt,
				account: fromAccount,
				damt,
			}))(this.updatedData);
			await this.api.createJournalEntryDetails(fromDetails);

			const entryData = (({
				usercode,
				txnum,
				jenum,
				jedate,
				txdesc,
				fiscalmonth,
			}) => ({
				usercode,
				txnum,
				jenum,
				jedate,
				txdesc,
				fiscalmonth,
			}))(this.updatedData);

			await this.api.createJournalEntry(entryData);

			const postSingleData = {
				transactionNumber: this.updatedData.txnum,
			};
			await this.api.postSingleTempJournalEntry(postSingleData);

			displayAlert('success', 'New Journal Entry Added for transfer');
			this.updatedData = {
				txdesc: 'Transfer',
				jenum: 'XFER$',
				camt: 0,
			};
			this.props.hideModal();
			window.onbeforeunload = null;
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		} catch (error) {
			displayAlert('danger', error.response.data.userError);
		}
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
				this.setState({
					[key]: value,
				});
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.updatedData[key] = value;
	};

	handleDateChange = (name, date) => {
		this.setState({
			[name]: date,
		});

		date =
			name === 'fiscalmonth'
				? dayjs(date).format('MM/YYYY')
				: dayjs(date).format('MM/DD/YYYY');
		this.updatedData[name] = date;
	};

	render() {
		return (
			<Modal
				size="lg"
				show={this.props.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="w-100 px-5">
						<Form.Group>
							<Row className="align-items-center mb-3">
								<Form.Label className="mb-0" column sm={4}>
									Transfer Money From (Source)
								</Form.Label>
								<Col>
									<Select
										options={this.state.accounts}
										className="react-select"
										placeholder="Please select"
										name="fromAccount"
										onChange={this.handleChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Form.Label className="mb-0" column sm={4}>
									Transfer Money To (Destination)
								</Form.Label>
								<Col>
									<Select
										options={this.state.accounts}
										className="react-select"
										placeholder="Please select"
										name="toAccount"
										onChange={this.handleChange}
									/>
								</Col>
							</Row>
							<Row>
								<Col lg={4}>
									<Row className="align-items-center mb-3">
										<Form.Label className="mb-0" column sm={3}>
											Date
										</Form.Label>
										<Col>
											<DatePicker
												selected={this.state.jedate}
												showMonthDropdown="true"
												showYearDropdown="true"
												className="form-control"
												placeholderText="Select"
												name="jedate"
												onChange={this.handleDateChange.bind(this, 'jedate')}
											/>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row className="align-items-center mb-3">
										<Form.Label className="mb-0" column sm={2}>
											Description
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												value={this.state.txdesc}
												name="txdesc"
												onChange={this.handleChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col lg={4}>
									<Row className="align-items-center mb-3">
										<Form.Label className="mb-0" column sm={3}>
											Fiscal Month
										</Form.Label>
										<Col>
											<DatePicker
												selected={this.state.fiscalmonth}
												className="form-control"
												dateFormat="MM/yyyy"
												placeholderText="Select"
												showMonthYearPicker
												name="fiscalmonth"
												onChange={this.handleDateChange.bind(
													this,
													'fiscalmonth'
												)}
											/>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row className="align-items-center mb-3">
										<Form.Label className="mb-0" column sm={2}>
											Amount
										</Form.Label>
										<Col sm={6}>
											<Form.Control
												type="text"
												placeholder="0.00"
												name="camt"
												onChange={this.handleChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Form.Group>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal.bind(null)}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(CheckbookTransferModal);
