import React from 'react';
import { Table, Spinner, Modal } from 'react-bootstrap';
import { getJournalEntryCheckbookDetail } from '../api';
import { formatNumber } from '../utils';
import { useQuery } from '@tanstack/react-query';

interface IJournalEntryCheckbookDetailProps {
	txnum: number;
}
const JournalEntryCheckbookDetail = ({
	txnum,
}: IJournalEntryCheckbookDetailProps) => {
	const { data: journalEntryCheckbookDetail = [], isLoading } = useQuery({
		queryKey: ['journalEntryCheckbookDetail', txnum],
		queryFn: () => getJournalEntryCheckbookDetail(`?$filter=txnum eq ${txnum}`),
	});

	return (
		<>
			<Modal.Header>
				<Modal.Title as="h5">Journal Entry Detail [TX #{txnum}]</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<div className="tw-flex tw-items-center tw-justify-center">
						<Spinner variant="success" />
					</div>
				) : (
					<div className="tw-overflow-x-auto">
						<Table striped bordered>
							<thead>
								<tr>
									<th>Account No.</th>
									<th>Account Name</th>
									<th>Debit</th>
									<th>Credit</th>
								</tr>
							</thead>
							<tbody>
								{journalEntryCheckbookDetail.map((item, index) => {
									const { account, accountn, amount: value } = item;
									const amount = value ?? 0;
									const debit = amount > 0 ? formatNumber(amount) : '';
									const credit =
										amount < 0 ? formatNumber(Math.abs(amount)) : '';

									return (
										<tr key={`${account}-${index}`}>
											<td>{account}</td>
											<td>{accountn}</td>
											<td className="tw-text-right">{debit}</td>
											<td className="tw-text-right">{credit}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</div>
				)}
			</Modal.Body>
		</>
	);
};

JournalEntryCheckbookDetail.displayName = 'JournalEntryCheckbookDetail';

export { JournalEntryCheckbookDetail };
