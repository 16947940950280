import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { IItemImages } from '../types/ItemImages';

const api = new ApiService();

const getFile = async (id: string) => {
	return (await api.getFile(id)) as Blob;
};

const useGetItemImages = (filter = '') => {
	return useQuery<IItemImages[], Error>(
		['itemImages', filter],
		async () => {
			const itemImages = await api.getItemImages(filter);

			const itemImagesWithFiles = await Promise.all(
				itemImages?.map(async (image) => {
					const imageFile = await getFile(image.fileId);
					return {
						...image,
						imageFile,
					};
				})
			);
			return itemImagesWithFiles;
		},
		{
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			staleTime: 0,
			cacheTime: 0,
		}
	);
};

export default useGetItemImages;
