import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ProgressBarCicle from '../components/ProgressCircle';

class Progress extends React.Component {
	render() {
		return (
			<div className="d-block mt-5">
				<div className="bg-secondary-grey pt-5 pb-4">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Progress Bar</h2>
							</Col>
						</Row>
					</Container>
				</div>
				<Container className="mt-5">
					<Row className="mt-5">
						<Col>
							{/* Primary */}
							<div>
								<div className="d-block mb-4">
									<ProgressBar variant="primary" className="primary" now={0} />
								</div>
								<div className="d-block mb-4">
									<ProgressBar variant="primary" className="primary" now={25} />
								</div>
								<div className="d-block mb-4">
									<ProgressBar variant="primary" className="primary" now={50} />
								</div>
								<div className="d-block mb-4">
									<ProgressBar
										variant="primary"
										className="primary"
										now={100}
									/>
								</div>
							</div>
						</Col>

						<Col>
							{/* Primary */}
							<div>
								<div className="d-block mb-4">
									<ProgressBar variant="primary-grey" now={0} />
								</div>
								<div className="d-block mb-4">
									<ProgressBar variant="primary-grey" now={25} />
								</div>
								<div className="d-block mb-4">
									<ProgressBar variant="primary-grey" now={50} />
								</div>
								<div className="d-block mb-4">
									<ProgressBar variant="primary-grey" now={100} />
								</div>
							</div>
						</Col>
					</Row>

					<Row className="mt-5">
						<Col>
							{/* Primary */}
							<div className="d-flex">
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary" value={0} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary" value={25} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary" value={50} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary" value={75} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary" value={100} />
								</div>
							</div>
						</Col>

						<Col>
							{/* Grey */}
							<div className="d-flex">
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary-grey" value={0} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary-grey" value={25} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary-grey" value={50} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary-grey" value={75} />
								</div>
								<div className="d-block me-4">
									<ProgressBarCicle variant="primary-grey" value={100} />
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Progress;
