import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IPlaidAccount } from 'api/resources/plaidAccounts';
import { getPlaidAccounts } from 'api/resources/plaidAccounts';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetPlaidAccounts = (
	filter?: string,
	options?: UseQueryOptions<IPlaidAccount[]>
) => {
	return useQuery<IPlaidAccount[]>({
		queryKey: [ECacheKeys.PlaidAccounts, filter],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: () => getPlaidAccounts(filter),
		...(options || {}),
	});
};
