export const CompViews: { [key: number]: string } = {
	0: 'compviewm',
	1: 'compviewd',
	2: 'compviewf',
	3: 'compviewi',
	4: 'compviewl',
	5: 'compviewo',
};

export enum ECompIdentifiers {
	M = 'm',
	D = 'd',
	F = 'f',
	I = 'i',
	L = 'l',
	O = 'o',
	T = 't',
}
