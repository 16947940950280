import React from 'react';
import { Col, Row, Form, Table } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';

class CatalogItemPricing extends ThreadComponent {
	componentInit() {
		this.hasFormAction = true;
	}

	render() {
		const List = [
			'Merchandise',
			'Freight',
			'DesF',
			'Install/Label',
			'Crating',
			'Time',
		];

		return (
			<>
				<Row>
					<Col>
						<div className="table-gradient">
							<Table hover borderless className="">
								<thead>
									<tr key="0">
										<th className="fs-xl">Type</th>
										<th className="fs-xl">Cost</th>
										<th className="fs-xl"></th>
										<th className="fs-xl">Profit</th>
										<th className="fs-xl"></th>
										<th className="fs-xl">Est. Price</th>
									</tr>
								</thead>
								<tbody>
									{List.map((item, i) => (
										<tr key={i}>
											<td className="align-middle">{item}</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
											<td className="align-middle text-secondary-ash">
												<i className="ri-add-line ri-lg"></i>
											</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
											<td className="align-middle text-secondary-ash">
												<span className="fsx-24">=</span>
											</td>
											<td>
												<Form.Control
													type="text"
													placeholder="0.00"
													size="sm"
												/>
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr className="bg-beige" key="0">
										<td className="align-middle">Total</td>
										<td>
											<Form.Control type="text" placeholder="0.00" size="sm" />
										</td>
										<td className="align-middle text-secondary-ash">
											<i className="ri-add-line ri-lg"></i>
										</td>
										<td>
											<Form.Control type="text" placeholder="0.00" size="sm" />
										</td>
										<td className="align-middle text-secondary-ash">
											<span className="fsx-24">=</span>
										</td>
										<td>
											<Form.Control type="text" placeholder="0.00" size="sm" />
										</td>
									</tr>
								</tfoot>
							</Table>
						</div>
					</Col>
				</Row>
			</>
		);
	}
}

export default WithRouter(CatalogItemPricing);
