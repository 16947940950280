import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import URI from '../../../defaults/RoutesDefault';

class UiKitHome extends ThreadComponent {
	render() {
		return (
			<Container className="mt-4">
				<Row>
					<Col>
						<Card
							className="mx-auto mt-4 shadow-lg"
							style={{ maxWidth: '38rem' }}
						>
							<Card.Body>
								<Card.Title>
									<h4 className="ff-type-bold">🚀 Welcome to Design Manager</h4>
								</Card.Title>
								<Card.Text className="mt-3">
									This is the UI Kit Homepage.
									<br />
									If you are looking for the components, click the link below.
								</Card.Text>

								<ListGroup variant="flush">
									<ListGroup.Item>
										<Link
											to={URI.uikit.typography}
											className="fw-bold text-decoration-none"
										>
											Typography
										</Link>
									</ListGroup.Item>
									<ListGroup.Item>
										<Link
											to={URI.uikit.icon}
											className="fw-bold text-decoration-none"
										>
											Icons
										</Link>
									</ListGroup.Item>
									<ListGroup.Item>
										<Link
											to={URI.uikit.colour}
											className="fw-bold text-decoration-none"
										>
											Colours
										</Link>
									</ListGroup.Item>
									<ListGroup.Item>
										<Link
											to={URI.uikit.component}
											className="fw-bold text-decoration-none"
										>
											UI Components
										</Link>
									</ListGroup.Item>
								</ListGroup>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default UiKitHome;
