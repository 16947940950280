import UnitsOfMeasureDropdown from 'legacy/app/components/dropdowns/UnitsOfMeasuresDropdown';
import FormCurrencyInput from 'legacy/app/components/form/FormCurrencyInput';
import Label from 'legacy/app/components/label/Label';
import { TWhatChangedComponent } from 'legacy/lib/api/types/WhatChangedComponent';
import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

const DiscountInputs = ({
	disableInputs,
	handleRecalculate,
}: {
	disableInputs: boolean;
	handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void;
}) => {
	const methods = useFormContext();
	const useTerms = methods.watch('useterms');

	return (
		<div className="tw-mt-8 tw-flex tw-gap-2">
			<div className="tw-flex tw-flex-col">
				<div>
					<Label label="Unit Cost" className="!tw-text-left tw-mb-2" />
					<div className="tw-flex tw-gap-4">
						<div className="tw-max-w-36">
							<Controller
								control={methods.control}
								name="estunitcost"
								render={({ field }) => (
									<FormCurrencyInput
										id="Unit Cost"
										allowNegativeValue
										disabled={useTerms || disableInputs}
										value={field.value}
										maxLength={15}
										onValueChange={field.onChange}
										onBlur={() =>
											handleRecalculate(
												TWhatChangedComponent.cwcEstimatedUnitCost
											)
										}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<div>
					<Label label="Unit List" className="!tw-text-left tw-mb-2 tw-mt-4" />
					<div className="tw-flex tw-gap-4">
						<div className="tw-max-w-36">
							<Controller
								control={methods.control}
								name="unitlist"
								render={({ field }) => (
									<FormCurrencyInput
										id="Unit List"
										allowNegativeValue
										disabled={disableInputs}
										value={field.value}
										maxLength={15}
										onValueChange={field.onChange}
										onBlur={() =>
											handleRecalculate(TWhatChangedComponent.cwcUnitListPrice)
										}
									/>
								)}
							/>
						</div>
						<span className="tw-self-center tw-pr-4">X</span>
					</div>
				</div>
			</div>
			<div className="tw-self-end">
				<div className="tw-flex">
					<div className="tw-relative tw-pr-4 tw-self-end">
						<Label
							label="Qty"
							className="tw-absolute tw-bottom-0 tw-right-6 tw-top-2 tw-text-[#84878a]"
						/>
						<Controller
							name="quantity"
							render={({ field: { value, onChange } }) => (
								<FormControl
									id="Qty"
									onChange={onChange}
									size="sm"
									value={value}
									disabled={disableInputs}
									maxLength={15}
									onBlur={(e) => {
										const isNotAValidNumber = isNaN(Number(e.target.value));
										if (
											!e.target.value ||
											isNotAValidNumber ||
											e.target.value === '-0.'
										) {
											methods.setValue('quantity', '0');
										} else {
											methods.setValue(
												'quantity',
												String(Number(e.target.value))
											);
										}
										handleRecalculate(TWhatChangedComponent.cwcQuantity);
									}}
								/>
							)}
						/>
					</div>
					<div>
						<Label label="Units" className="tw-mb-2" />
						<Controller
							control={methods.control}
							name="unitmeasure"
							render={({ field }) => (
								<div>
									<UnitsOfMeasureDropdown
										inputId="Units"
										isClearable={!!field.value}
										height={32}
										value={{
											label: '',
											value: field.value,
										}}
										onChange={(value) => field.onChange(value?.value || '')}
									/>
								</div>
							)}
						/>
					</div>
				</div>
			</div>
			<div className="tw-flex tw-flex-col">
				<div>
					<Label label="Extended Cost" className="!tw-text-left tw-mb-2" />
					<div className="tw-flex tw-gap-4">
						<div className="tw-max-w-36">
							<Controller
								control={methods.control}
								name="estcost"
								render={({ field }) => (
									<FormCurrencyInput
										id="Extended Cost"
										allowNegativeValue
										value={field?.value}
										disabled={useTerms || disableInputs}
										maxLength={15}
										onValueChange={field.onChange}
										onBlur={() =>
											handleRecalculate(TWhatChangedComponent.cwcEstimatedCost)
										}
									/>
								)}
							/>
						</div>
					</div>
				</div>
				<div>
					<Label
						label="Ext. List Price"
						className="!tw-text-left tw-mb-2 tw-mt-4"
					/>
					<div className="tw-flex tw-gap-4">
						<div className="tw-max-w-36">
							<Controller
								control={methods.control}
								name="list"
								render={({ field }) => (
									<FormCurrencyInput
										id="Ext. List Price"
										disabled={disableInputs}
										allowNegativeValue
										value={field.value}
										maxLength={15}
										onValueChange={field.onChange}
										onBlur={() =>
											handleRecalculate(TWhatChangedComponent.cwcListPrice)
										}
									/>
								)}
							/>
						</div>
						<span className="tw-self-center tw-pr-4">-</span>
					</div>
				</div>
			</div>
			<div className="tw-relative tw-self-end">
				<Label
					label="Discount"
					className="tw-absolute tw-bottom-0 tw-right-2 tw-top-2 tw-text-[#84878a]"
				/>

				<Controller
					control={methods.control}
					name="estdisc"
					render={({ field }) => (
						<FormCurrencyInput
							id="Discount"
							value={field.value}
							disabled={disableInputs}
							allowNegativeValue
							maxLength={15}
							onValueChange={field.onChange}
							onBlur={() =>
								handleRecalculate(TWhatChangedComponent.cwcEstimatedMarkup)
							}
						/>
					)}
				/>
			</div>
			<span className="tw-self-end tw-text-2xl tw-pl-8">=</span>
			<div className="tw-self-end">
				<Label label="Sell Price" className="!tw-text-left tw-mb-2" />
				<div className="tw-flex tw-gap-4">
					<div className="tw-max-w-36">
						<Controller
							control={methods.control}
							name="estprice"
							render={({ field }) => (
								<FormCurrencyInput
									id="Sell Price"
									value={field.value}
									allowNegativeValue
									maxLength={15}
									disabled={disableInputs}
									onValueChange={field.onChange}
									onBlur={() =>
										handleRecalculate(TWhatChangedComponent.cwcEstimatedPrice)
									}
								/>
							)}
						/>
					</div>
					<div className="tw-flex tw-items-center">
						<i
							className="ri-lock-fill px-2"
							onClick={() => {
								methods.setValue('usedisc', 'dmMarkup');
								methods.setValue('markup', '0');
								handleRecalculate(TWhatChangedComponent.cwcPercentType);
							}}
						/>
						<div className="tw-max-w-36">
							<Controller
								control={methods.control}
								name="taxable"
								render={({ field }) => (
									<Form.Check
										size={30}
										type="checkbox"
										label="Taxable"
										className="tw-bg-color-[]"
										disabled={disableInputs}
										checked={field.value}
										maxLength={15}
										onChange={field.onChange}
										onBlur={() =>
											handleRecalculate(TWhatChangedComponent.cwcTaxable)
										}
									/>
								)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
DiscountInputs.displayName = 'DiscountInputs';
export default DiscountInputs;
