import { Row, Table } from '@tanstack/react-table';
import { Component } from 'legacy/lib/api/types/Component';
import { Form } from 'react-bootstrap';

type TTableCheckboxCell = {
	table?: Table<Component>;
	disabled: boolean;
	row?: Row<Component>;
};

const TableCheckboxCell = ({ table, disabled, row }: TTableCheckboxCell) => {
	const getClassName = () => {
		if (disabled || !table) {
			return '';
		}

		return table.getIsAllRowsSelected() ? '' : 'line';
	};
	return (
		<div>
			<Form.Check
				disabled={disabled}
				label=""
				onChange={
					row
						? row.getToggleSelectedHandler()
						: table?.getToggleAllRowsSelectedHandler()
				}
				checked={
					row
						? row.getIsSelected()
						: table?.getIsSomeRowsSelected() || table?.getIsAllRowsSelected()
				}
				type="checkbox"
				className={getClassName()}
			/>
		</div>
	);
};
TableCheckboxCell.displayName = 'TableCheckbox';
export default TableCheckboxCell;
