import React from 'react';
import { Container, Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

class FormControls extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			startDate: '',
			minDate: new Date(),
		};
	}

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
	};

	getData() {
		return [
			{ value: 'option-1', label: 'Option 1' },
			{ value: 'option-2', label: 'Option 2' },
			{ value: 'option-3', label: 'Option 3' },
		];
	}
	render() {
		return (
			<div className="d-block mt-5">
				<div className="bg-secondary-grey pt-5 pb-4">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Textbox / Select</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4">
					<Row>
						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								<h4 className="mb-4 fw-bold">Large Controls</h4>
								{/* Large form controls */}
								<div className="d-block">
									<Form.Group controlId="formGridText1">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control type="text" placeholder="Text" size="lg" />
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText2">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="Text"
											defaultValue="Text"
											size="lg"
										/>
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText3">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="Text"
											className="is-invalid"
											defaultValue="Text"
											size="lg"
										/>
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText4">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control type="text" placeholder="" size="lg" />
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText5">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="Text"
											disabled
											size="lg"
										/>
									</Form.Group>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								<h4 className="mb-4 fw-bold">Medium Controls</h4>
								{/* Normal form controls */}
								<div className="d-block">
									<Form.Group controlId="formGridText1">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control type="text" placeholder="Text" />
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText2">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="Text"
											defaultValue="Text"
										/>
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText3">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											type="text"
											placeholder="Text"
											className="is-invalid"
											defaultValue="Text"
										/>
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText4">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control type="text" placeholder="" />
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText5">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control type="text" placeholder="Text" disabled />
									</Form.Group>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								<h4 className="mb-4 fw-bold">Small Controls</h4>
								{/* Small form controls */}
								<div className="d-block">
									<Form.Group controlId="formGridText1">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control size="sm" type="text" placeholder="Text" />
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText2">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											size="sm"
											type="text"
											placeholder="Text"
											defaultValue="Text"
										/>
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText3">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											size="sm"
											type="text"
											placeholder="Text"
											className="is-invalid"
											defaultValue="Text"
										/>
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText4">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control size="sm" type="text" placeholder="" />
									</Form.Group>
								</div>

								<div className="d-block mt-4">
									<Form.Group controlId="formGridText5">
										<Form.Label>
											<strong>Label</strong>
										</Form.Label>
										<Form.Control
											size="sm"
											type="text"
											placeholder="Text"
											disabled
										/>
									</Form.Group>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="mt-5">
							<h3 className="ui-title">Floating Textbox</h3>
						</Col>
					</Row>
					<Row>
						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Large */}
								{/* Floating labels */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											size="lg"
										/>
									</FloatingLabel>
								</div>

								{/* --- with value */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											size="lg"
											defaultValue="Text"
										/>
									</FloatingLabel>
								</div>

								{/* --- is-invalid */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											size="lg"
											className="is-invalid"
										/>
									</FloatingLabel>
								</div>

								{/* --- ::focused */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											size="lg"
										/>
									</FloatingLabel>
								</div>

								{/* --- ::disabled */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											size="lg"
											disabled
										/>
									</FloatingLabel>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Floating labels */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control type="text" placeholder="This is your text" />
									</FloatingLabel>
								</div>

								{/* --- with value */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											defaultValue="Text"
										/>
									</FloatingLabel>
								</div>

								{/* --- is-invalid */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											className="is-invalid"
										/>
									</FloatingLabel>
								</div>

								{/* --- ::focused */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control type="text" placeholder="This is your text" />
									</FloatingLabel>
								</div>

								{/* --- ::disabled */}
								<div className="d-block mt-4">
									<FloatingLabel controlId="floatingInput" label="Label input">
										<Form.Control
											type="text"
											placeholder="This is your text"
											disabled
										/>
									</FloatingLabel>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}></div>
						</Col>
					</Row>

					<Row>
						<Col className="mt-5">
							<h3 className="ui-title">Ordinary Select</h3>
						</Col>
					</Row>
					<Row>
						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Large dropdown */}
								<div className="d-block mt-4">
									<Form.Label>
										<strong>Label</strong>
									</Form.Label>
									<Form.Select size="lg">
										<option>Large dropdown</option>
									</Form.Select>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Medium dropdown */}
								<div className="d-block mt-4">
									<Form.Label>
										<strong>Label</strong>
									</Form.Label>
									<Form.Select>
										<option>Text</option>
									</Form.Select>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Small dropdown */}
								<div className="d-block mt-4">
									<Form.Label>
										<strong>Label</strong>
									</Form.Label>
									<Form.Select size="sm">
										<option>Text</option>
									</Form.Select>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						<Col xs={12} className="mt-5">
							<h3 className="ui-title">Stylized Select</h3>
						</Col>
						<Col xs={12} className="mt-4">
							<p>
								For documentation, visit{' '}
								<a
									href="https://react-select.com/home"
									alt="documentation"
									target="_blank"
									rel="noreferrer"
								>
									https://react-select.com/home
								</a>
							</p>
						</Col>
					</Row>
					<Row>
						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Large dropdown */}
								<div className="d-block mt-2">
									<Select
										options={this.getData()}
										className="react-select-lg"
									/>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Medium dropdown */}
								<div className="d-block mt-2">
									<Select options={this.getData()} className="react-select" />
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Small dropdown */}
								<div className="d-block mt-2">
									<Select
										options={this.getData()}
										className="react-select-sm"
									/>
								</div>
							</div>
						</Col>
					</Row>
				</Container>

				<Container className="mt-4"></Container>

				<div className="bg-secondary-grey pt-5 pb-4 mt-5">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Date / Time / Date Range Picker</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4">
					<Row>
						<Col className="mt-4">
							<p>
								For documentation, visit{' '}
								<a
									href="https://reactdatepicker.com/"
									alt="documentation"
									target="_blank"
									rel="noreferrer"
								>
									https://reactdatepicker.com/
								</a>
							</p>
						</Col>
					</Row>
					<Row>
						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Large dropdown */}
								<div className="d-block mt-2">
									<DatePicker
										selected={this.state.startDate}
										minDate={this.state.minDate}
										onChange={this.handleDateChange}
										className="form-control form-control-lg"
										placeholderText="Opened"
									/>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Medium dropdown */}
								<div className="d-block mt-2">
									<DatePicker
										selected={this.state.startDate}
										minDate={this.state.minDate}
										onChange={this.handleDateChange}
										className="form-control"
										placeholderText="Opened"
									/>
								</div>
							</div>
						</Col>

						<Col xs="6" lg="4">
							<div style={{ maxWidth: '300px' }}>
								{/* Small dropdown */}
								<div className="d-block mt-2">
									<DatePicker
										selected={this.state.startDate}
										minDate={this.state.minDate}
										onChange={this.handleDateChange}
										className="form-control form-control-sm"
										placeholderText="Opened"
									/>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default FormControls;
