import {
	CompNumericPercentType,
	CompStringPercentType,
} from 'legacy/lib/api/types/CompPercentType';
import { CompTypes } from 'legacy/lib/api/types/CompTypes';
import { TWhatChangedComponentResponse } from 'legacy/lib/api/types/WhatChangedComponent';

const normalizeRecalculateDataResponse = (
	values: TWhatChangedComponentResponse
) => {
	const {
		taxable,
		useDisc,
		componentId,
		useTerms,
		bTerm1,
		bTerm2,
		bTerm3,
		quantity,
		compType,
		estDisc,
		unitList,
		list,
		estMarkup,
		estUnitCost,
		estCost,
		markup,
		supDep,
		feeCost,
		feeUnitCost,
		supplier,
		estPrice,
	} = values;

	const base = {
		taxable,
		useTerms,
		quantity,
		markup,
		supDep,
		supplier: supplier || null,
		estPrice,
		bTerm1,
		bTerm2,
		bTerm3,
		id: componentId,
		compType: CompTypes[compType],
		useDisc:
			// There´s a typo on the BE
			(useDisc as string) === 'DmFee'
				? CompNumericPercentType.Fee
				: CompStringPercentType[useDisc],
	};
	if (useTerms) {
		return {
			...base,
			list,
			estDisc,
			unitList,
			estUnitCost,
			estCost,
		};
	}

	if (base.useDisc === CompNumericPercentType.Fee) {
		return {
			...base,
			feeCost,
			useDisc: CompNumericPercentType.Fee,
			estMarkup,
			feeUnitCost,
		};
	} else if (base.useDisc === CompNumericPercentType.Markup) {
		return {
			...base,
			estMarkup,
			useDisc: CompNumericPercentType.Markup,
			estUnitCost,
			estCost,
		};
	}
	return {
		...base,
		list,
		estDisc,
		unitList,
		estUnitCost,
		useDisc: CompNumericPercentType.Discount,
		estCost,
	};
};
export default normalizeRecalculateDataResponse;
