import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { formatMoney, searchData, isValidType } from './utils';
import { localeDate } from 'legacy/helpers/Date';
import { useReconcile } from './hooks/useReconcile';

const sortData = (field, direction, data) => {
	if (!field) return data;
	return [...data].sort((a, b) => {
		const aField = field === 'userdate' ? new Date(a[field]) : a[field];
		const bField = field === 'userdate' ? new Date(b[field]) : b[field];

		if (aField < bField) {
			return direction === 'asc' ? -1 : 1;
		}

		if (aField > bField) {
			return direction === 'asc' ? 1 : -1;
		}

		return 0;
	});
};

function TableHeader({
	onSelectAll,
	searches,
	searching,
	setSearches,
	sort,
	setSort,
	data,
}) {
	const { tab, check, setCheck } = useReconcile();
	const cleared = tab === 'cleared';
	const onSearchChange = ({ target: { name, value } }) => {
		setSearches((prev) => ({ ...prev, [name]: value }));
	};

	const [sortField, sortDirection] = sort;

	const onSpanClick = (field) => {
		if (sortField === field) {
			setSort((prev) => [field, prev[1] === 'asc' ? 'desc' : 'asc']);
		} else {
			setSort([field, 'desc']);
		}
	};

	const Span = ({ children, field }) => {
		const classes = cn('sort', {
			active: sortField === field,
			asc: sortDirection === 'asc',
			desc: sortDirection === 'desc',
		});
		return (
			<span className={classes} onClick={() => onSpanClick(field)}>
				{children}
			</span>
		);
	};

	const handleOnCheck = (e) => {
		const { name, checked } = e.target;

		setCheck((prevState) => ({
			...prevState,
			[name]: checked,
		}));

		onSelectAll(e);
	};

	return (
		<thead>
			<tr className="a-table-heading">
				<th>
					<div className="d-flex justify-content-center">
						{cleared ? (
							<Form.Check
								name="cleared"
								type="checkbox"
								onChange={handleOnCheck}
								className="chk-projectview-items-item form-check"
								checked={check.cleared}
								disabled={!data?.some((x) => x.cleared)}
							/>
						) : (
							<Form.Check
								name="outstanding"
								type="checkbox"
								onChange={handleOnCheck}
								checked={check.outstanding}
								className="chk-projectview-items-item form-check"
								disabled={!data?.some((x) => !x.cleared)}
							/>
						)}
					</div>
				</th>
				<th>
					<Span field="userdate">Date</Span>
				</th>
				<th>
					<Span field="supplier">Vendor Code</Span>
				</th>
				<th>
					<Span field="vendorn">Vendor Name</Span>
				</th>
				<th>
					<Span field="sinvno">Invoice No.</Span>
				</th>
				<th>
					<Span field="ponum">PO No.</Span>
				</th>
				<th>
					<Span field="txdesc">TX Description</Span>
				</th>
				<th>
					<Span field="amount">Amount</Span>
				</th>
			</tr>
			{searching && (
				<tr className="a-table-search-fields">
					<th></th>
					<th>
						<Form.Control
							type="date"
							name="userdate"
							onChange={onSearchChange}
							value={searches.dateSearch}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="supplier"
							onChange={onSearchChange}
							value={searches.supplier}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="vendorn"
							onChange={onSearchChange}
							value={searches.vendorn}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="sinvno"
							onChange={onSearchChange}
							value={searches.sinvno}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="ponum"
							onChange={onSearchChange}
							value={searches.ponum}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="txdesc"
							onChange={onSearchChange}
							value={searches.txdesc}
						/>
					</th>
					<th>
						<Form.Control
							type="text"
							name="amount"
							onChange={onSearchChange}
							value={searches.amount}
						/>
					</th>
				</tr>
			)}
		</thead>
	);
}

export default function Grid({
	data,
	onSelect,
	onSelectAll,
	onTransactionDetail,
	searching,
}) {
	const { tab, selected } = useReconcile();
	const cleared = tab === 'cleared';
	const [searches, setSearches] = useState({});
	const [sort, setSort] = useState([]);

	useEffect(() => {
		if (!searching) {
			setSearches({});
		}
	}, [searching]);

	const gridData = !searching
		? data
		: Object.keys(searches).reduce(
				(prev, cur) => searchData(searches[cur], cur, prev),
				data
		  );

	const [sortField, sortDirection] = sort;

	return (
		<div className="table-gradient sticky-container">
			<div className="table-responsive">
				<Table striped responsive className="a-table" id="projects">
					<TableHeader
						onSelectAll={onSelectAll}
						searching={searching}
						searches={searches}
						setSearches={setSearches}
						setSort={setSort}
						sort={sort}
						data={data}
					/>
					<tbody>
						{sortData(sortField, sortDirection, gridData)
							.filter(({ cleared: isCleared }) => cleared === isCleared)
							.map((transaction) => {
								const {
									amount,
									ponum,
									recnum,
									sinvno,
									supplier,
									txnum,
									txdesc,
									userdate,
									vendorn,
									type,
								} = transaction;
								const isValid = isValidType(type);
								return (
									<tr key={`${txnum}-${type}`}>
										<td>
											<div className="d-flex justify-content-center">
												<Form.Check
													type="checkbox"
													onChange={onSelect}
													value={recnum}
													name={recnum}
													aria-label="txdesc"
													checked={selected[recnum] || false}
													className="chk-projectview-items-item form-check"
												/>
											</div>
										</td>
										<td>
											<Button
												className="text-charcoal text-decoration-underline"
												onClick={
													isValid
														? () => onTransactionDetail(transaction)
														: undefined
												}
												variant="link"
												disabled={!isValid}
											>
												{localeDate(userdate)}
											</Button>
										</td>
										<td>{supplier}</td>
										<td>{vendorn}</td>
										<td>{sinvno}</td>
										<td>{ponum}</td>
										<td>{txdesc}</td>
										<td>{formatMoney(amount)}</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</div>
		</div>
	);
}
