import React from 'react';
import { Row, Col, Modal, Button, Container, Form } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { InlineFormGroupField } from '../../../components/Form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { displayAlert } from '../../../../utilities/Response';

class VendorMiscAddCheckWireModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.updatedData = {
			...(props.data ?? {}),
			...(props.updatedData ?? {}),
		};

		this.updatedData.handckdt = this.updatedData.handckdt
			? new Date(this.updatedData.handckdt)
			: new Date();

		this.state = {
			accounts: [],
			data: this.updatedData,
			// When the 'handcknum' starts with ‘WT’ is a 'wire' transfer.
			isWireTransfer: this.updatedData?.handcknum?.startsWith('WT'),
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		let accounts = await this.api.getSummaryGLAccounts(
			'?$filter=specacct eq 1'
		);
		accounts = accounts.map((i) => {
			return { label: `${i.value} (${i.key})`, value: i.key };
		});

		this.setState({
			accounts,
		});
	}

	handleChange = async (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
			if (this.state.isWireTransfer) {
				const response = await this.api.getWireTransferNumberForAccountNumber(
					value
				);
				this.updatedData['handcknum'] = response.wireTransferNumber;
			}
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
				// Check if the field is handcknum and validate for numeric input
				if (key === 'handcknum' && !/^\d*$/.test(value)) {
					displayAlert('danger', 'Check number must be numeric.');
					return; // Prevent update if validation fails
				}
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}
		this.setState({
			data: {
				...this.state.data,
				[key]: value,
			},
		});

		this.updatedData[key] = value;
	};

	handleClear = () => {
		const fields = ['handckcashacct', 'handcktxdesc', 'handckdt', 'handcknum'];

		fields.forEach((field) => {
			this.updatedData[field] = '';
		});

		const updatedData = { ...this.state.data, ...this.updatedData };
		this.setState({
			data: updatedData,
		});

		fields.forEach((field) => {
			const inputName = field;
			const inputValue = this.updatedData[field] || '';
			this.handleChange({
				target: { name: inputName, value: inputValue },
			});
		});

		this.props.onDataUpdate(this.updatedData);
	};

	handleAction = async (e) => {
		const requiredFields = [
			{ id: 'handckdt', label: 'Check Date' },
			{ id: 'handckcashacct', label: 'Checking Account' },
		];

		if (!this.state.isWireTransfer) {
			requiredFields.push({ id: 'handcknum', label: 'Check Number' });
		}

		const missingFields = requiredFields
			.filter((field) => !this.state.data[field.id])
			.map((field) => field.label);

		if (missingFields.length > 0) {
			displayAlert(
				'danger',
				`Missing required fields: ${missingFields.join(', ')}`
			);
		} else {
			this.updatedData.isWireTransfer = this.state.isWireTransfer;
			this.props.onWireOkay(this.updatedData);
		}
	};

	handleDateChange = (date, name) => {
		this.setState({
			data: {
				...this.state.data,
				[name]: date,
			},
		});

		this.updatedData[name] = date;
	};

	handleRadioChange = async (e) => {
		const isWireTransfer = e.target.value === 'wire';
		this.updatedData.isWireTransfer = isWireTransfer;
		const { data } = this.state;

		this.setState({
			isWireTransfer,
		});

		// For 'check' transfer the 'handcknum' is entered by the user
		if (!isWireTransfer) {
			this.updatedData['handcknum'] = '';
			this.setState({
				data: {
					...data,
					handcknum: '',
				},
			});
		}

		// For 'wire' transfer the 'handcknum' value comes from the BE
		if (isWireTransfer && data.handckcashacct) {
			const { wireTransferNumber } =
				await this.api.getWireTransferNumberForAccountNumber(
					data.handckcashacct
				);
			this.updatedData['handcknum'] = wireTransferNumber;

			this.setState({
				data: {
					...data,
					handcknum: wireTransferNumber,
				},
			});
		}
	};

	render() {
		return (
			<Modal
				show={true}
				size="md"
				aria-labelledby="action-modal"
				className="a-modal mx-sm-5 mx-lg-0"
				backdrop="static"
				centered
				onHide={() => {
					this.props.hideModal();
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>Check / Wire Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="content-padding min-height">
						<Container fluid className="ms-0">
							<Form.Group>
								<Row className="align-items-center mb-3">
									<Col>
										<Form.Check
											inline
											label="Hand Check"
											name="ttype"
											type="radio"
											id={`check`}
											className="vtop"
											onChange={this.handleRadioChange}
											value="check"
											checked={!this.state.isWireTransfer}
										/>

										<Form.Check
											inline
											label="Wire Transfer"
											name="ttype"
											type="radio"
											id={`wire`}
											className="vtop mx-3"
											onChange={this.handleRadioChange}
											value="wire"
											checked={this.state.isWireTransfer}
										/>
										<Button variant="secondary" onClick={this.handleClear}>
											Clear
										</Button>
									</Col>
								</Row>
								<div
									className={!this.state.isWireTransfer ? 'd-block' : 'd-none'}
								>
									<InlineFormGroupField
										label="Check Number"
										classNameParent="mb-3 align-items-center"
										className="justify-content-start col-sm-4"
										colField={8}
									>
										<Form.Control
											type="text"
											placeholder="Please enter"
											name="handcknum"
											value={this.state.data.handcknum}
											onChange={this.handleChange}
											maxLength={7}
										/>
									</InlineFormGroupField>

									<InlineFormGroupField
										label="Check Date"
										classNameParent="mb-3 align-items-center"
										className="justify-content-start col-sm-4"
										colField={8}
									>
										<div className="react-select-header">
											<DatePicker
												className="form-control"
												placeholderText="Select"
												showMonthDropdown="true"
												showYearDropdown="true"
												selected={this.state.data?.handckdt ?? new Date()}
												onChange={(date) => {
													this.handleDateChange(date, 'handckdt');
												}}
											/>
										</div>
									</InlineFormGroupField>
								</div>
								<div
									className={this.state.isWireTransfer ? 'd-block' : 'd-none'}
								>
									<InlineFormGroupField
										label="Transfer Date"
										classNameParent="mb-3 align-items-center"
										className="justify-content-start col-sm-4"
										colField={8}
									>
										<div className="react-select-header">
											<DatePicker
												className="form-control"
												placeholderText="Select"
												showMonthDropdown="true"
												showYearDropdown="true"
												selected={this.state.data?.handckdt ?? new Date()}
												onChange={(date) => {
													this.handleDateChange(date, 'handckdt');
												}}
											/>
										</div>
									</InlineFormGroupField>
								</div>

								<InlineFormGroupField
									label="Checking Account"
									classNameParent="mb-3 align-items-center"
									className="justify-content-start col-sm-4"
									colField={8}
								>
									<Select
										menuPortalTarget={document.body}
										styles={{
											menuPortal: (base) => ({
												...base,
												zIndex: 99999,
											}),
										}}
										options={this.state.accounts}
										className="react-select"
										placeholder="Please select"
										name="handckcashacct"
										value={this.state.accounts.filter((item) => {
											return item.value === this.state.data.handckcashacct;
										})}
										onChange={this.handleChange}
									/>
								</InlineFormGroupField>

								<InlineFormGroupField
									label="Trans. Desc."
									classNameParent="mb-3 align-items-center"
									className="justify-content-start col-sm-4"
									colField={8}
								>
									<Form.Control
										type="text"
										placeholder="Please enter"
										name="handcktxdesc"
										value={this.state.data.handcktxdesc}
										onChange={this.handleChange}
										maxLength={30}
									/>
								</InlineFormGroupField>
							</Form.Group>
						</Container>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={this.handleAction}>
						OK
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							this.props.hideModal();
						}}
					>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(VendorMiscAddCheckWireModal);
