import { RTFJS } from 'rtf.js';
import { stringToArrayBuffer } from '../helpers/String';

export async function mapRtfToDraft(rtf: string, removeStyles = false) {
	if (!rtf) {
		return '';
	}
	let html = '';
	const renderer = RTFJS;
	renderer.loggingEnabled(false);

	try {
		const doc = new renderer.Document(stringToArrayBuffer(rtf), {});

		const htmlElements = await doc.render();
		html = htmlElements
			.map((element) => {
				if (removeStyles) {
					element.removeAttribute('style');
				}
				return element.outerHTML;
			})
			.join(' ');
	} catch (error) {
		html = rtf;
	}

	return `${html}`;
}

export const extractTextFromRtf = async (rtf: string): Promise<string> => {
	if (!rtf) {
		return '';
	}

	try {
		const html = await mapRtfToDraft(rtf, true);
		const parser = new DOMParser();
		const docHtml = parser.parseFromString(html, 'text/html');
		return docHtml.body.textContent || '';
	} catch (error) {
		return rtf;
	}
};
