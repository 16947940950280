import { makeAutoObservable } from 'mobx';
import { AddressListResponse, AddressService } from './api/AddressService';

interface SelectOptions {
	label: string;
	value: string;
}

export default class AddressListViewModel {
	private addressService: AddressService;
	private _address: AddressListResponse[] = [];
	private _isLoaded = false;
	private _params = '';

	get addressList(): AddressListResponse[] {
		return this._address;
	}

	get isLoaded(): boolean {
		return this._isLoaded;
	}

	get params(): string {
		return this._params;
	}

	get addressTypes(): SelectOptions[] {
		const addressesTypes: string[] = [];
		const addressTypeOptions: SelectOptions[] = [];
		if (this._address && this._address.length) {
			for (const addr of this._address) {
				const typedesc = addr.typedesc || '';
				if (addr.addresstype > 1 && typedesc.length) {
					if (addressesTypes.indexOf(typedesc) === -1) {
						addressesTypes.push(typedesc);
					}
				}
			}
		}
		if (addressesTypes.length) {
			addressesTypes.sort(function (a, b) {
				return a.toLowerCase().localeCompare(b.toLowerCase());
			});
			for (let i = 0; i < addressesTypes.length; i++) {
				addressTypeOptions[i] = {
					value: addressesTypes[i],
					label: addressesTypes[i],
				};
			}
		}

		return [...[{ value: 'Custom', label: 'Custom' }], ...addressTypeOptions];
	}

	get vendorAddressTypes(): SelectOptions[] {
		const addressTypeOptions: SelectOptions[] = [];
		if (this._address && this._address.length) {
			for (const addr of this._address) {
				addressTypeOptions.push({
					value: addr.toString(),
					label: addr.toString(),
				});
			}
		}

		return [...[{ value: 'Custom', label: 'Custom' }], ...addressTypeOptions];
	}

	setAddress(addressList: AddressListResponse[]): AddressListViewModel {
		this._address = addressList;
		return this;
	}

	setParams(params: string): AddressListViewModel {
		this._params = params;
		return this;
	}

	setIsLoaded(isLoaded: boolean): AddressListViewModel {
		this._isLoaded = isLoaded;
		return this;
	}

	constructor(addressService: AddressService) {
		this.addressService = addressService;
		this.setIsLoaded(false);

		makeAutoObservable(this);
	}

	async componentDidMount() {
		this.fetchAddresses();
	}

	async fetchAddresses(type = '') {
		// Set loading state
		this._isLoaded = false;

		try {
			let response: any;
			if (type === 'vendor') {
				response = await this.addressService.getVendorAddresses(this._params);
				response = response?.typeDescList?.length ? response?.typeDescList : [];
			} else {
				response = await this.addressService.getAddresses(this._params);
			}

			this.setIsLoaded(true);
			if (response) {
				this.setAddress(response);
			}
		} catch {
			this.setIsLoaded(true);
		}
	}
}
