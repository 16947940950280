import React from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import { formatDate, formatFiscalMonth, formatNumber } from '../utils';
import { searchGLTransactions, getCheckCheckbookDetail } from '../api';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';

interface ICheckbookDetailCheckProps {
	txnum: number;
	type: number;
}

const CheckbookDetailCheck = ({ txnum, type }: ICheckbookDetailCheckProps) => {
	const {
		data: generalLedgerTransactions = [],
		isLoading: isGeneralLedgerTransactionsLoading,
	} = useQuery({
		queryKey: ['generalLedgerTransactions', type, txnum],
		queryFn: () =>
			searchGLTransactions(`?$filter=type eq ${type} and txnum eq ${txnum}`),
	});

	const [generalLedgerTransaction] = generalLedgerTransactions;
	const isGetCheckCheckbookDetailEnabled = !!generalLedgerTransaction;

	const {
		data: checkbookDetailChecks = [],
		isLoading: isCheckbookDetailChecksLoading,
	} = useQuery({
		queryKey: [
			'checkbookDetailChecks',
			generalLedgerTransaction?.checknum,
			generalLedgerTransaction?.supplier,
			generalLedgerTransaction?.userdate,
			generalLedgerTransaction?.account,
		],
		queryFn: () => {
			const date = dayjs(generalLedgerTransaction.userdate).format(
				'YYYY-MM-DD'
			);
			return getCheckCheckbookDetail(
				`?$filter=checknum eq '${generalLedgerTransaction.checknum}' and supplier eq '${generalLedgerTransaction.supplier}' and userdate eq ${date} and account eq '${generalLedgerTransaction.account}'`
			);
		},
		enabled: isGetCheckCheckbookDetailEnabled,
	});

	const isLoading =
		isGeneralLedgerTransactionsLoading ||
		(isGetCheckCheckbookDetailEnabled && isCheckbookDetailChecksLoading);

	const [checkbookDetailCheck] = checkbookDetailChecks;
	return (
		<>
			<Modal.Header>
				<Modal.Title as="h5">
					{generalLedgerTransaction &&
						`Check Detail [Check #${generalLedgerTransaction.checknum}]`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<div className="tw-flex tw-items-center tw-justify-center">
						<Spinner variant="success" />
					</div>
				) : (
					<div className="tw-overflow-x-auto">
						<Table striped bordered>
							<thead>
								<tr>
									<th>TX #</th>
									<th>Type</th>
									<th>Invoice No.</th>
									<th>Invoice Date</th>
									<th>Fiscal Month</th>
									<th>Description</th>
									<th>PO No.</th>
									<th>Amount</th>
									<th>Discount</th>
								</tr>
							</thead>
							<tbody>
								{checkbookDetailCheck && (
									<tr>
										<td>{checkbookDetailCheck.txnum}</td>
										<td>{checkbookDetailCheck.type}</td>
										<td>{checkbookDetailCheck.sinvnum}</td>
										<td>{formatDate(checkbookDetailCheck.sinvdt)}</td>
										<td>
											{formatFiscalMonth(checkbookDetailCheck.fiscalmonth)}
										</td>
										<td>{checkbookDetailCheck.txdesc}</td>
										<td>{checkbookDetailCheck.pon}</td>
										<td className="tw-text-right">
											{formatNumber(checkbookDetailCheck.amount)}
										</td>
										<td className="tw-text-right">
											{formatNumber(checkbookDetailCheck.discountamount)}
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
				)}
			</Modal.Body>
		</>
	);
};

CheckbookDetailCheck.displayName = 'CheckbookDetailCheck';

export { CheckbookDetailCheck };
