import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ThreadComponent from '../ThreadComponent';

/**
 * @link https://remixicon.com/ Search icons here.
 * @returns string
 */
class Icons extends ThreadComponent {
	render() {
		return (
			<Container className="px-0" fluid>
				<div className="bg-light pt-5 pb-4">
					<Container>
						<Row>
							<Col>
								<Breadcrumb className="mt-2">
									<Breadcrumb.Item href="/">UI Kit</Breadcrumb.Item>
									<Breadcrumb.Item href="/icons">Iconography</Breadcrumb.Item>
								</Breadcrumb>
								<h2 className="fw-normal ff-type-medium">Iconography</h2>
							</Col>
						</Row>
					</Container>
				</div>
				<Container className="mt-5">
					<Row className="mb-4">
						<Col>
							<div className="d-block mb-4">
								Search Remix icon here:{' '}
								<a
									href="https://remixicon.com/"
									rel="noreferrer"
									target="_blank"
								>
									https://remixicon.com/
								</a>
								<br />
								<u>Sample usage</u>:{' '}
								<strong>{'<i className="ri-account-box-fill"></i>'}</strong>
							</div>
							<div className="fsx-20 ui-icons-wrapper">
								<i className="ri-account-box-fill"></i>
								<i className="ri-account-circle-fill"></i>
								<i className="ri-add-circle-fill"></i>
								<i className="ri-add-line"></i>
								<i className="ri-archive-drawer-fill"></i>
								<i className="ri-archive-fill"></i>
								<i className="ri-arrow-down-line"></i>
								<i className="ri-arrow-down-s-fill"></i>
								<i className="ri-arrow-drop-down-fill"></i>
								<i className="ri-arrow-drop-down-line"></i>
								<i className="ri-arrow-drop-left-fill"></i>
								<i className="ri-arrow-drop-left-line"></i>
								<i className="ri-arrow-drop-right-fill"></i>
								<i className="ri-arrow-drop-right-line"></i>
								<i className="ri-arrow-drop-up-fill"></i>
								<i className="ri-arrow-drop-up-line"></i>
								<i className="ri-arrow-left-line"></i>
								<i className="ri-arrow-left-s-fill"></i>
								<i className="ri-arrow-left-s-line"></i>
								<i className="ri-arrow-right-line"></i>
								<i className="ri-arrow-right-s-fill"></i>
								<i className="ri-arrow-right-s-line"></i>
								<i className="ri-arrow-up-line"></i>
								<i className="ri-arrow-up-s-fill"></i>
								<i className="ri-arrow-up-s-line"></i>
								<i className="ri-attachment-fill"></i>
								<i className="ri-attachment-line"></i>
								<i className="ri-bank-card-2-fill"></i>
								<i className="ri-bank-fill"></i>
								<i className="ri-book-fill"></i>
								<i className="ri-briefcase-4-fill"></i>
							</div>
						</Col>
					</Row>
					<Row className="mb-4">
						<Col>
							<div className="fsx-20 ui-icons-wrapper">
								<i className="ri-calendar-fill"></i>
								<i className="ri-clipboard-fill"></i>
								<i className="ri-close-line"></i>
								<i className="ri-coin-fill"></i>
								<i className="ri-coins-fill"></i>
								<i className="ri-contacts-book-2-fill"></i>
								<i className="ri-currency-fill"></i>
								<i className="ri-delete-bin-fill"></i>
								<i className="ri-drag-move-2-line"></i>
								<i className="ri-information-fill"></i>
								<i className="ri-external-link-fill"></i>
								<i className="ri-external-link-line"></i>
								<i className="ri-file-chart-fill"></i>
								<i className="ri-flag-fill"></i>
								<i className="ri-folder-fill"></i>
								<i className="ri-global-fill"></i>
								<i className="ri-group-fill"></i>
								<i className="ri-hand-coin-fill"></i>
								<i className="ri-heart-fill"></i>
								<i className="ri-home-fill"></i>
								<i className="ri-lightbulb-fill"></i>
								<i className="ri-lightbulb-flash-fill"></i>
								<i className="ri-links-line"></i>
								<i className="ri-lock-2-fill"></i>
								<i className="ri-lock-fill"></i>
								<i className="ri-lock-unlock-fill"></i>
								<i className="ri-menu-line"></i>
								<i className="ri-money-dollar-box-fill"></i>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}

export default Icons;
