import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import URI from '../../defaults/RoutesDefault';

class UiKitNavigation extends React.Component {
	render() {
		return (
			<Navbar bg="dark" expand="lg" variant="dark" fixed="top">
				<Container>
					<Navbar.Brand to={URI.home} as={Link} className="me-5">
						<span className="position-relative me-2" style={{ top: '4px' }}>
							<i className="ri-apps-fill ri-lg"></i>
						</span>
						Thread (Design Manager) <small className="ms-2">ReactJS</small>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							<Link
								to={URI.home}
								className="nav-link fw-bold me-4 position-relative"
							>
								<span className="position-absolute" style={{ left: '-14px' }}>
									<i className="ri-home-4-line"></i>
								</span>{' '}
								Home
							</Link>
							<Link to={URI.uikit.typography} className="nav-link fw-bold me-4">
								Typography
							</Link>
							<Link to={URI.uikit.icon} className="nav-link fw-bold me-4">
								Icons
							</Link>
							<Link to={URI.uikit.colour} className="nav-link fw-bold me-4">
								Colours
							</Link>
							<Link to={URI.uikit.component} className="nav-link fw-bold me-4">
								Components
							</Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		);
	}
}

export default UiKitNavigation;
