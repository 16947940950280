import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';

interface State {
	show: boolean;
	min: any;
	max: any;
}

interface Props {
	show: boolean;
	defaultVal: any;
	toggleModal: any;
	updateSearch: any;
	defaultTitle: string;
}

class FilterNumberModal extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			show: false,
			min: '',
			max: '',
		};
	}

	componentDidUpdate(previousProps: Props, previousState: State) {
		if (previousProps.show !== this.props.show) {
			const tmp = this.props.defaultVal.split('-');

			this.setState({
				show: this.props.show,
				min: tmp[0],
				max: tmp[1],
			});
		}
	}

	handleFilter() {
		const min = (document.getElementById('min') as HTMLInputElement).value;
		const max = (document.getElementById('max') as HTMLInputElement).value;

		this.props.updateSearch(min, max);
		this.props.toggleModal();
	}

	render() {
		return (
			<Modal
				size="lg"
				show={this.state.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="w-100 px-5">
						<Col lg={{ span: 10, offset: 1 }}>
							<div className="mx-auto">
								<h5 className="fw-bold">{this.props.defaultTitle}</h5>
								<div className="form-fields mt-4">
									<Row>
										<Col>
											<Form.Label className="text-end">
												<strong>Greater than</strong>
											</Form.Label>
											<Form.Control
												type="number"
												placeholder=""
												name="min"
												id="min"
												defaultValue={this.state.min}
											/>
										</Col>
										<Col>
											<Form.Label className="text-end">
												<strong>Smaller than</strong>
											</Form.Label>
											<Form.Control
												type="number"
												placeholder=""
												name="max"
												id="max"
												defaultValue={this.state.max}
											/>
										</Col>
									</Row>
								</div>
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col lg={{ span: 4, offset: 2 }}>
							<Button
								variant="primary"
								onClick={this.handleFilter.bind(this)}
								className="w-100 mb-2"
							>
								Filter
							</Button>
						</Col>
						<Col lg={{ span: 4 }}>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.toggleModal.bind(this)}
								className="w-100"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(FilterNumberModal);
