import React from 'react';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import { Container, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { ApiService } from '../../../lib/api/HttpService';
import { FooterFormAction } from '../../components/Form';
import { displayAlert, displayAlertLoader } from '../../../utilities/Response';
import { Is } from '../../../helpers/Util';

class AccountSettingsReceivableDialog extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			paymentTypes: [
				{
					value: 0,
					label: 'Generic',
				},
				{
					value: 1,
					label: 'Credit Card',
				},
				{
					value: 2,
					label: 'Check',
				},
				{
					value: 3,
					label: 'Online Processor',
				},
			],
			name: this.props.selectedData.type || '',
			selectedAccount: null,
			selectedPaymentType: null,
			undeposited: this.props.selectedData.undeposited || false,
			accounts: [],
			isLoaded: false,
		};

		this.api = new ApiService();
		this.isEditing = !Is.empty(this.props.selectedData);
	}

	async componentDidMount() {
		await this.getGeneralLedgerAccounts();

		if (!Is.empty(this.props.selectedData)) {
			this.setState({
				selectedPaymentType: {
					value: this.props.selectedData.datatype,
					label:
						this.state.paymentTypes[this.props.selectedData.datatype || 0]
							.label,
				},
				selectedAccount: {
					value: this.props.selectedData?.acct || '',
					label: Object.values(this.props.selectedData).length
						? `${this.props.selectedData?.acct}-${this.props.selectedData?.accountn}`
						: null,
				},
			});
		}
	}

	async getGeneralLedgerAccounts() {
		const gEAccounts = await this.api.get(
			'generalledgeraccounts',
			'?$filter=specacct eq 1'
		);

		if (gEAccounts) {
			let acc = gEAccounts.map((a) => {
				return {
					value: a.account,
					label: `${a.account}-${a.accountn}`,
				};
			});

			if (Is.empty(this.state.selectedData)) {
				this.setState({
					selectedAccount: acc[0],
					selectedPaymentType: this.state.paymentTypes[0],
				});
			}

			this.setState({
				accounts: acc,
			});
		}

		this.setState({
			isLoaded: true,
		});
	}
	handleChange = (e, meta = {}) => {
		let target, value;
		const maxLen = e.target?.getAttribute('maxLength') ?? 5;
		if (meta && meta.hasOwnProperty('name') && typeof e === 'string') {
			target = meta.name;
			value = e.replace(/\s/g, '').substring(0, maxLen).toUpperCase();
		} else if (
			meta.name === 'selectedAccount' ||
			meta.name === 'selectedPaymentType'
		) {
			target = meta.name;
			value = e;
		} else {
			e.target.value = e.target.value
				.replace(/\s/g, '')
				.substring(0, maxLen)
				.toUpperCase();
			target = e.target.name;
			value = e.target.value;
		}

		if (target === 'undeposited') {
			value = !this.state.undeposited;
		}

		this.setState((prevState) => ({
			...prevState,
			[target]: value,
		}));
	};

	handleSubmit = (e) => {
		const postData = {
			type: this.state.name,
			acct: this.state.selectedAccount.value,
			datatype: this.state.selectedPaymentType.value,
			seqnum: 0,
			undeposited: this.state.undeposited,
		};

		displayAlertLoader(
			this.isEditing
				? 'Saving changes. Please wait...'
				: 'Creating new account type. Please wait...'
		);

		if (Object.values(this.props.selectedData).length) {
			this.api
				.updatePosPaymentType(postData)
				.then((data) => {
					if (Object.values(data).length) {
						displayAlert('success', 'Successfully updated pos payment type');
						this.setState({
							name: '',
							selectedAccount: null,
							selectedPaymentType: null,
							undeposited: false,
						});
						this.props.onOkay();
					}
				})
				.catch((error) => {
					displayAlert('danger', error.response.data.userError);
				});
		} else {
			this.api
				.createPosPaymentType(postData)
				.then((data) => {
					if (Object.values(data).length) {
						displayAlert(
							'success',
							'Successfully created new pos payment type.'
						);
						this.setState({
							name: '',
							selectedAccount: null,
							selectedPaymentType: null,
							undeposited: false,
						});
						this.props.onOkay();
					}
				})
				.catch((err) => {
					displayAlert('danger', err.response.data.userError);
				});
		}
	};

	handleModalClose = () => {
		this.props.onCancel();
		this.setState({
			paytype: '',
			selectedAccount: { value: '', label: 'Please select' },
		});
	};

	content() {
		return (
			<Form.Group className="m-4">
				<Row className="align-items-center mb-3">
					<Col lg={4} className="mb-2 mb-lg-0">
						<Form.Label className="mb-0">Name</Form.Label>
					</Col>
					<Col lg={4}>
						<Form.Control
							type="text"
							name="name"
							maxLength={7}
							onChange={this.handleChange}
							defaultValue={this.state.name}
							readOnly={Object.values(this.props.selectedData).length}
						/>
					</Col>
				</Row>
				<Row className="align-items-center mb-3">
					<Col lg={4} className="mb-2 mb-lg-0">
						<Form.Label className="mb-0">Account</Form.Label>
					</Col>
					<Col>
						<Select
							key={`${Math.floor(Math.random() * 1000)}-min`}
							name="selectedAccount"
							options={this.state.accounts}
							className="react-select"
							onChange={this.handleChange}
							defaultValue={this.state.selectedAccount}
							menuPosition={'fixed'}
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
								container: (provided) => ({
									...provided,
									width: '100%',
								}),
							}}
							isDisabled={!this.state.isLoaded}
						/>
					</Col>
				</Row>
				<Row className="align-items-center mb-3">
					<Col lg={4} className="mb-2 mb-lg-0">
						<Form.Label className="mb-0">Type</Form.Label>
					</Col>
					<Col>
						<Select
							key={`${Math.floor(Math.random() * 1000)}-min`}
							name="selectedPaymentType"
							options={this.state.paymentTypes}
							className="react-select"
							onChange={this.handleChange}
							defaultValue={
								this.state.selectedPaymentType
									? this.state.selectedPaymentType
									: this.state.paymentTypes[0]
							}
							menuPosition={'fixed'}
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
								container: (provided) => ({
									...provided,
									width: '100%',
								}),
							}}
						/>
					</Col>
				</Row>
				<Row className="align-items-center mb-3">
					<Col>
						<Form.Check
							inline
							label="Receipts will first appear in Un-deposited Funds (a blank deposit is made)"
							name="undeposited"
							type="checkbox"
							id="undeposited"
							data-type="undeposited"
							defaultChecked={
								this.props.selectedData.undeposited !== undefined &&
								this.props.selectedData.undeposited
							}
							onClick={this.handleChange}
						/>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						<FooterFormAction>
							<a
								className="btn btn-primary btn-icon btn-action me-0 me-lg-2 align-items-center"
								name="ok"
								onClick={this.handleSubmit}
							>
								<i className="ri-check-line ri-lg"></i>OK
							</a>
							<a
								onClick={this.handleModalClose}
								className="btn btn-trans-light btn-icon btn-action mb-2 mb-lg-0 align-items-center"
							>
								<i className="ri-close-line ri-lg text-primary-red"></i>
								Cancel
							</a>
						</FooterFormAction>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	render() {
		return (
			<>
				<Modal
					size="md"
					show={this.props.isShowing}
					aria-labelledby="action-modal"
					className="a-modal"
					backdrop="static"
					centered
				>
					<div className="content-padding min-height">
						<Container fluid className="mt-4">
							{this.content()}
						</Container>
					</div>
				</Modal>
			</>
		);
	}
}

export default WithRouter(AccountSettingsReceivableDialog);
