import React from 'react';

interface IBaseButton {
	text: string;
	onClick: () => void;
	type?: 'button' | 'submit' | 'reset';
	buttonClass?: string;
}

const BaseButton = ({ text, onClick, buttonClass, type }: IBaseButton) => (
	<button className={buttonClass} onClick={onClick} type={type || 'button'}>
		{text}
	</button>
);

BaseButton.displayName = 'BaseButton';

export default BaseButton;
