import React from 'react';
import { Col, Row, Form, Container } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import { ApiService } from '../../../lib/api/HttpService';
import Select from 'react-select';
import AsyncLocationsDropdown from '../../../app/components/dropdowns/AsyncLocationsDropdown';

class ProjectAddMarkupTime extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			salesCategoryOptions: [],
			timeloccode: {},
			timescatcode: {},
			isLoaded: false,
			locationQry: '',
		};
		this.api = new ApiService();
		this.updatedData = this.props.updatedData ?? {};
	}

	async componentDidMount() {
		this.hasFormAction = true;
		let locationQry = `projectId eq null`;

		const categories = await this.api.getSalesCategories();
		const categoryOptions = categories.map((category) => {
			return {
				value: category.scat,
				label: `${category.scatn} [${category.scat}]`,
			};
		});

		const locCode =
			this.updatedData.timeloccode ?? this.props.project?.timeloccode;
		let timeloccode = null;
		const proj = await this.api.getProjectByCode(this.updatedData?.proj);
		if (locCode) {
			timeloccode = await this.api.getLocationByCode(locCode, proj?.id, true);
		}

		locationQry = `?$filter=(${locationQry}${
			proj ? ` or projectId eq ${proj?.id}` : ``
		})`;

		this.setState({
			isLoaded: true,
			salesCategoryOptions: categoryOptions,
			timeloccode,
			timescatcode: categoryOptions.filter((category) => {
				return (
					category.value === this.updatedData.timescatcode ||
					category.value === this.props.project?.timescatcode
				);
			})[0],
			locationQry,
		});
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;

			this.setState({
				[key]: { value: e.value, label: e.label },
			});
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name ?? e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.props.onDataChange({ key: key, value: value });
	};

	render() {
		return (
			<>
				<Container className="ms-0">
					<Row xs={1} lg={2} className="mb-2 mb-lg-4">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										Default Location for Time Entries
									</Form.Label>
								</Col>
								<Col>
									<AsyncLocationsDropdown
										name="timeloccode"
										onChange={this.handleChange}
										defaultValue={this.state.timeloccode}
										isDisabled={!this.state.isLoaded}
										projectCode={this.updatedData?.proj}
										urlQuery={this.state.locationQry}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										Default Sales Category for Time Entries
									</Form.Label>
								</Col>
								<Col>
									<Select
										key={`${Math.floor(Math.random() * 1000)}-min`}
										name="timescatcode"
										size="sm"
										onChange={this.handleChange}
										defaultValue={this.state.timescatcode}
										options={this.state.salesCategoryOptions}
										isDisabled={!this.state.isLoaded}
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
								<Col>
									<Form.Check
										inline
										label="Only Allow Entries to be Joined to Items with Default Sales Category"
										name="timeaddtoscat"
										type="checkbox"
										id={`chk-option-markup`}
										className="vtop"
										defaultChecked={
											this.updatedData.timeaddtoscat ??
											this.props.company?.timeaddtoscat
										}
										onClick={this.handleChange}
									/>
								</Col>
							</Row>
						</Col>

						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-5 d-none d-lg-flex">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0"></Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<div className="d-none">
						{' '}
						{/* show v1 show the div */}
						<div className="bg-secondary-grey mb-4 roundedx-4">
							<Row xs={1} className="py-3">
								<Col>
									<Row className="align-items-center px-3">
										<Col className="px-3">
											<h6 className="fw-bold mb-0">Create Item Options</h6>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
						<Row xs={1} lg={2} className="mb-5">
							<Col className="mb-3 mb-lg-0">
								<div className="mb-3">
									<Form.Check
										inline
										label="User Can Choose"
										name="timecreateitem"
										type="radio"
										id={`chk-option-1`}
										className="vtop"
										defaultChecked={
											this.updatedData.timecreateitem === 0 ||
											this.props.company?.timecreateitem === 0
										}
										onClick={(e) => {
											this.props.onDataChange({
												key: e.target.name,
												value: 0,
											});
										}}
									/>
								</div>
								<div className="mb-3">
									<Form.Check
										inline
										label="New Item (Each time entry is separate line item)"
										name="timecreateitem"
										type="radio"
										id={`chk-option-2`}
										className="vtop"
										defaultChecked={
											this.updatedData.timecreateitem === 1 ||
											this.props.company?.timecreateitem === 1
										}
										onClick={(e) => {
											this.props.onDataChange({
												key: e.target.name,
												value: 1,
											});
										}}
									/>
								</div>
								<div className="mb-3">
									<Form.Check
										inline
										label="Join to Time Item:"
										name="timecreateitem"
										type="radio"
										id={`chk-option-3`}
										className="vtop"
										// defaultChecked={this.updatedData.timecreateitem === 2}
										defaultChecked="true"
										onClick={(e) => {
											this.props.onDataChange({
												key: e.target.name,
												value: 2,
											});
										}}
									/>
								</div>
								<div className="mb-3 ps-4">
									<Form.Check
										inline
										label="Only to latest Un-invoiced Date Item"
										name="timeonlydated"
										type="checkbox"
										id={`chk-option-4`}
										className="vtop"
										// defaultChecked={this.updatedData.timeonlydated}
										defaultChecked="true"
										onClick={this.handleChange}
									/>
								</div>
								<div className="mb-3 ps-4">
									<Form.Check
										inline
										label="Automatically Create Monthly Item"
										name="timeautocreate"
										type="checkbox"
										id={`chk-option-5`}
										className="vtop"
										// defaultChecked={this.updatedData.timeautocreate}
										defaultChecked="true"
										onClick={this.handleChange}
									/>
								</div>
							</Col>
						</Row>
					</div>
				</Container>
			</>
		);
	}
}

export default WithRouter(ProjectAddMarkupTime);
