import React from 'react';
import { Col, Row, Form, Container, Breadcrumb } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { routeParam, WithRouter } from '../../../helpers/Router';
import { FooterFormAction } from '../../components/Form';
import URI from '../../../defaults/RoutesDefault';
import { Link, NavLink } from 'react-router-dom';
import { HeaderLight } from '../../components/Header';
import { ApiService } from '../../../lib/api/HttpService';
import { getSaveButtonName } from '../../../utilities/String';
import { handleResponseAlert } from '../../../utilities/Response';
import MSG from '../../../defaults/Message';
import { delay } from 'lodash';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import { nameToCode, formatCode } from 'legacy/app/utils/codes';

class GlossaryAddLocations extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				loc: '',
				locn: '',
			},
			dataIsLoaded: false,
			isLoading: false,
			showButtons: false,
			isDraft: false,
		};
		this.api = new ApiService();

		this.isEditing = Boolean(this.props.params.id);
	}

	async componentDidMount() {
		this.setTitle('Add Location');
		this.setFormAction(true);

		let data = {};
		if (this.props.params.id) {
			this.dMloader(true);
			data = await this.api.get(
				'locations',
				`?$filter=id eq ${this.props.params.id}`
			);

			if (data) {
				this.setState({
					dataIsLoaded: true,
					data: data[0],
				});

				this.setTitle(`Edit ${data[0].locn}`);
				data = data[0];
			}
		}

		setTimeout(() => {
			this.setState({
				showButtons: true,
			});
			this.dMloader(false);
		}, 250);
	}

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	handleInputChange = (e) => {
		this.setState({
			isDraft: true,
		});
		const { name } = e.target;
		let { value } = e.target;

		if (!this.isEditing) {
			if (name === 'locn') {
				this.setState((prevState) => ({
					data: {
						...prevState.data,
						loc: nameToCode(value),
					},
				}));
			} else if (name === 'loc') {
				value = formatCode(value);
			}
		}

		this.setState((prevState) => ({
			data: {
				...prevState.data,
				[name]: value,
			},
		}));
	};

	handleClickSave = (e) => {
		this.setIsLoading(true);
		window.onbeforeunload = null;
		if (this.isEditing) {
			this.handleUpdate();
		} else {
			this.handleCreate(e);
		}
	};

	handleUpdate() {
		const data = {
			loc: this.state.data.loc,
			locn: this.state.data.locn,
		};

		const request = this.api.patch('locations', this.state.data.id, data);
		handleResponseAlert(request, (res) => {
			this.setIsLoading(false);

			if (res && !Number.isNaN(res.id)) {
				delay(() => {
					this.navigate(
						routeParam(URI.glossary.list, {
							path: 'locations',
						})
					);
				}, 1500);
			}
		});
	}

	handleCreate(e) {
		const data = {
			proj: '$$$$$',
			loc: this.state.data.loc,
			locn: this.state.data.locn,
		};

		const request = this.api.postJson('locations', data);
		handleResponseAlert(
			request,
			(res) => {
				this.setIsLoading(false);
				if (res && !Number.isNaN(res.id)) {
					delay(() => {
						e.target.name === 'saveNew'
							? window.location.reload(false)
							: this.navigate(
									routeParam(URI.glossary.list, {
										path: 'locations',
									})
							  );
					}, 1500);
				}
			},
			null,
			null,
			MSG.success.create.GlossaryLoc,
			null,
			MSG.loading.create.GlossaryLoc
		);
	}

	renderHeader() {
		const { isLoading, isDraft } = this.state;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.glossary.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							linkProps={{
								to: URI.settings.base,
							}}
							linkAs={Link}
						>
							Settings
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.glossary.base }}
							linkAs={Link}
						>
							Glossary
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.glossary.list, {
									path: 'locations',
								}),
							}}
							linkAs={Link}
						>
							Locations
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.glossary.add.locations }}
							linkAs={Link}
						>
							{this.title}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>{this.title}</HeaderLight.Title>

					<div className={this.state.showButtons ? 'd-block' : 'd-none'}>
						{/* Submit Button */}
						<SecureBootstrapButton
							attributeNo={42}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							className={this.props.params.id ? 'd-none' : 'd-inline-block'}
							variant="primary me-2"
							onClick={this.handleClickSave}
							name="saveNew"
							disabled={!isDraft || isLoading}
						>
							Save & New
						</SecureBootstrapButton>

						<SecureBootstrapButton
							attributeNo={42}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							onClick={this.handleClickSave}
							name="save"
							disabled={!isDraft || isLoading}
						>
							Save
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		const { data } = this.state;

		return (
			<Container className="ms-0">
				<Row xs={1} lg={2} className="py-4">
					<Col className="mb-3 mb-lg-0">
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Location Name</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									data-testid="location-name-input"
									maxLength={30}
									placeholder="Please enter"
									value={data?.locn}
									name="locn"
									onChange={this.handleInputChange}
								/>
							</Col>
						</Row>
						<Row className="align-items-center">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Location Code</Form.Label>
							</Col>
							<Col>
								<Row xs={1} lg={2}>
									<Col>
										<Form.Control
											type="text"
											data-testid="location-code-input"
											maxLength={5}
											placeholder="Please enter"
											value={data?.loc}
											name="loc"
											disabled={this.isEditing}
											readOnly={this.isEditing}
											onChange={this.handleInputChange}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	render() {
		const { isLoading, isDraft } = this.state;

		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
				</div>

				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={42}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
						variant="primary"
						size="lg"
						name="save"
						data-testid="submit-button"
						onClick={this.handleClickSave.bind(this)}
						disabled={!isDraft || isLoading}
					>
						{getSaveButtonName(isLoading, 'Save')}
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(GlossaryAddLocations);
