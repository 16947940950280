import React from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';

class Buttons extends React.Component {
	render() {
		return (
			<>
				<div className="bg-secondary-grey pt-5 pb-4">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Buttons</h2>
							</Col>
						</Row>
					</Container>
				</div>

				<Container className="mt-4">
					<Row>
						<Col>
							<div className="d-block mt-4">
								<Button variant="primary" size="lg" className="me-5">
									Button
								</Button>
								<Button variant="secondary" size="lg" className="me-5">
									Button
								</Button>
								<Button variant="light" size="lg" className="me-5">
									Button
								</Button>
								<Button variant="primary" size="lg" className="me-5" disabled>
									Button
								</Button>
								<Button variant="link" size="lg">
									Button
								</Button>
							</div>

							<div className="d-block mt-5">
								<Button variant="primary" className="me-5">
									Button
								</Button>
								<Button variant="secondary" className="me-5">
									Button
								</Button>
								<Button variant="light" className="me-5">
									Button
								</Button>
								<Button variant="trans-light" className="me-5">
									Button
								</Button>
								<Button variant="primary" className="me-5" disabled>
									Button
								</Button>
								<Button variant="link">Button</Button>
							</div>

							<div className="d-block mt-5">
								<Button variant="primary" size="sm" className="me-5">
									Button
								</Button>
								<Button variant="secondary" size="sm" className="me-5">
									Button
								</Button>
								<Button variant="light" size="sm" className="me-5">
									Button
								</Button>
								<Button variant="primary" size="sm" className="me-5" disabled>
									Button
								</Button>
								<Button variant="link" size="sm">
									Button
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default Buttons;
