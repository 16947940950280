import React, { useState, Fragment } from 'react';
import { Table, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatDate } from '../../../../helpers/Date';
import VendorInsuranceAddEditModal from './VendorInsuranceAddEditModal';
import { ApiService } from '../../../../lib/api/HttpService';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { displayAlert } from '../../../../utilities/Response';
import {
	displayAlertError,
	getErrorMessage,
} from '../../../../utilities/Response';
import useVendor from 'legacy/hooks/useVendor';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';

interface Insurance {
	active: boolean;
	carrier: string;
	effectivedt: string;
	expirationdt: string;
	insuranceno: number;
	policyno: string;
	policytype: string;
	ssmaTimeStamp: string;
	vendor: string;
}

const api = new ApiService();

type VendorInsuranceGridProps = {
	showModal: boolean;
	setShowModal: (show: boolean) => void;
};

const VendorInsuranceGrid = ({
	showModal,
	setShowModal,
}: VendorInsuranceGridProps) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [deleteInsuranceNo, setDeleteInsuranceNo] = useState<number>(0);
	const [selectedInsurance, setSelectedInsurance] =
		useState<Insurance | null>();

	const handleCloseConfirmationModal = () => setShowConfirmationModal(false);
	const { vendor } = useVendor();
	const queryClient = useQueryClient();

	const fetchInsurances = async (): Promise<Insurance[]> => {
		const filter = `?$filter=vendor eq '${vendor}'`;
		const insurances = (await api.getInsurances(filter)) as Insurance[];

		const filteredInsurances = insurances?.filter(
			(insurance: Insurance) =>
				insurance.policytype !== null ||
				insurance.carrier !== null ||
				insurance.policyno !== null
		);

		return filteredInsurances;
	};

	const { data: insurances, refetch } = useQuery(
		['insurances'],
		fetchInsurances
	);

	const deleteInsurance = useMutation(
		(insuranceNo: number) => api.deleteInsurance(insuranceNo),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['insurances']);
				displayAlert('success', 'Successfully deleted insurance.');
			},
			onError: (error) => {
				displayAlertError(getErrorMessage(error));
			},
		}
	);

	const handleEdit = (insurance: Insurance) => {
		setShowModal(true);
		setSelectedInsurance(insurance);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSelectedInsurance(null);
	};

	const handleDelete = () => {
		deleteInsurance.mutate(deleteInsuranceNo);
		handleCloseConfirmationModal();
		refetch();
	};

	const handleClickDelete = (insuranceNo: number) => {
		setDeleteInsuranceNo(insuranceNo);
		setShowConfirmationModal(true);
	};

	return (
		<Fragment>
			{(insurances ?? []).length > 0 && (
				<div className="table-gradient sticky-container">
					<Table striped responsive className="a-table">
						<thead>
							<tr className="a-table-heading">
								<th>Active</th>
								<th>Policy Template</th>
								<th>Insurance Carrier</th>
								<th>License/Policy Number</th>
								<th>Effective Date</th>
								<th>Expiration Date</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{insurances?.map((insurance) => {
								return (
									<tr
										key={insurance.insuranceno}
										data-key={insurance.insuranceno}
									>
										<td>
											<Form.Check
												type="checkbox"
												checked={insurance?.active || false}
												readOnly
											/>
										</td>
										<td>{insurance?.policytype}</td>
										<td>{insurance?.carrier}</td>
										<td>{insurance?.policyno}</td>
										<td>
											{insurance?.effectivedt &&
												formatDate(insurance?.effectivedt)}
										</td>
										<td>
											{insurance?.expirationdt &&
												formatDate(insurance?.expirationdt)}
										</td>
										<td>
											<OverlayTrigger
												overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
												placement="top"
											>
												<i
													className="ri-edit-line m-2 "
													role="button"
													onClick={() => handleEdit(insurance)}
												/>
											</OverlayTrigger>
											<OverlayTrigger
												overlay={<Tooltip id="edit-tooltip">Delete</Tooltip>}
												placement="top"
											>
												<i
													className="ri-close-line pointer"
													role="button"
													onClick={() =>
														handleClickDelete(insurance?.insuranceno)
													}
												/>
											</OverlayTrigger>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</div>
			)}
			<VendorInsuranceAddEditModal
				show={showModal}
				onHide={handleCloseModal}
				selectedInsurance={selectedInsurance}
				vendor={vendor}
			/>
			<ConfirmationModal
				confirmAction={handleDelete}
				show={showConfirmationModal}
				toggleModal={handleCloseConfirmationModal}
				message="Are you sure you want to permanently delete the selected insurance?"
				title="Delete Insurance"
			/>
		</Fragment>
	);
};

VendorInsuranceGrid.displayName = 'VendorInsuranceGrid';

export default VendorInsuranceGrid;
