import React, { FC, useState } from 'react';
import {
	Button,
	Col,
	Container,
	Dropdown,
	Form,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import Switch from '../../../components/Switch';
import { compareStr } from 'legacy/helpers/String';
import MiniZoomer from '../../../components/MiniZoomer';
import ListFilter from '../../../components/ListFilter';
import { TViewType } from './StockItemsList';

interface IStockItemsListToolbarProps {
	viewType: TViewType;
	searchAvailable: boolean;
	actionsAvailable: boolean;
	duplicateAvailable: boolean;
	onSwitchView: (viewType: TViewType) => any;
	onZoomChange: (zoom: number) => any;
	onSearch: () => void;
	onDelete: () => void;
	onDuplicate: () => void;
	onToggleInactive: (includeInactive: boolean) => void;
}
export const StockItemsListToolbar: FC<IStockItemsListToolbarProps> = ({
	viewType,
	searchAvailable,
	actionsAvailable,
	duplicateAvailable,
	onSwitchView,
	onZoomChange,
	onSearch,
	onDelete,
	onDuplicate,
	onToggleInactive,
}) => {
	const [isSearching, setSearching] = useState(false);

	const handleSearch = () => {
		setSearching(!isSearching);

		onSearch();
	};

	return (
		<>
			<Container fluid>
				<Row
					className="border-1 border-sand bg-ivory roundedx-4 justify-content-between py-3"
					style={{ padding: '14px 8px' }}
				>
					<Col className="d-flex flex-row justify-content-start align-items-center flex-grow-0">
						<div className="d-flex flex-row align-items-center me-4">
							<Form.Label className="mb-0 me-2 flex-grow-1 text-nowrap">
								<strong>Show Inactive</strong>
							</Form.Label>
							<span>
								<Switch
									onChange={(e: { target: { checked: boolean } }) => {
										onToggleInactive(e.target.checked);
									}}
								/>
							</span>
						</div>
						<Button
							variant={`${compareStr(viewType, 'table', 'dark', 'light')}`}
							size="sm"
							className={`${compareStr(
								viewType,
								'table',
								'bg-primary-ash'
							)} square wrap-after py-0 ri-list-unordered`}
							onClick={() => {
								onSwitchView('table');
							}}
						/>
						<Button
							variant={`${compareStr(viewType, 'grid', 'dark', 'light')}`}
							size="sm"
							className={`${compareStr(
								viewType,
								'grid',
								'bg-primary-ash'
							)} square wrap-after py-0 mx-2 ri-microsoft-fill`}
							onClick={() => {
								onSwitchView('grid');
							}}
						/>
						<MiniZoomer
							changeZoom={onZoomChange}
							disabled={viewType !== 'grid'}
						></MiniZoomer>
					</Col>
					<Col className="d-flex flex-row justify-content-end align-items-center">
						<Row>
							<Col
								xs={12}
								md={'auto'}
								className="d-flex justify-content-end align-items-center pe-xs-2"
							>
								{searchAvailable && (
									<Button
										variant={`${actionsAvailable ? 'ivory' : 'light'}`}
										size="sm"
										className={`btn-icon btn-action fw-bold me-2 ${
											isSearching ? 'bg-primary-ash text-white' : ''
										}`}
										disabled={!searchAvailable}
										onClick={handleSearch}
									>
										<i className="ri-search-line"></i> Search
									</Button>
								)}
								<OverlayTrigger
									overlay={
										actionsAvailable ? (
											<></>
										) : (
											<Tooltip>Select at least one item</Tooltip>
										)
									}
								>
									<Dropdown>
										<Dropdown.Toggle
											id="dropdown-autoclose-true"
											variant={`${actionsAvailable ? 'ivory' : 'light'}`}
											size="sm"
											disabled={!actionsAvailable}
										>
											Actions
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item
												onClick={() => {
													onDelete();
												}}
											>
												<i className="ri-delete-bin-line"></i> Delete
											</Dropdown.Item>
											<Dropdown.Item
												onClick={() => {
													onDuplicate();
												}}
												disabled={!duplicateAvailable}
											>
												<i className="ri-file-copy-line"></i> Duplicate
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</OverlayTrigger>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<ListFilter.Spacer />
		</>
	);
};
StockItemsListToolbar.displayName = 'StockItemsListToolbar';
