import { apiClient } from 'api/apiClient';

const RESOURCE = '/items/proposals';

export interface IProposal {
	proposalNumber: string;
	receivedDeposit: number;
	checkNumber: string;
	checkDate: string;
	proposalName: string;
	proposalDate: string;
	projectCode: string;
	itemNumber: string;
}

export const getProposals = async (filter?: string): Promise<IProposal[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());

	return data;
};
