import { useQuery } from '@tanstack/react-query';
import { getTemporaryReconciliationsChecks } from 'api/resources/temporaryReconciliations';
import type { ITemporaryReconciliationCheck } from 'api/resources/temporaryReconciliations';
import { ECacheKeys } from 'cache/CacheKeys';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetTemporaryReconciliationsChecks = (
	filter: string,
	options?: UseQueryOptions<ITemporaryReconciliationCheck[]>
) => {
	const query = useQuery<ITemporaryReconciliationCheck[]>({
		queryKey: [ECacheKeys.TemporaryReconciliations, 'Checks', filter],
		queryFn: () => getTemporaryReconciliationsChecks(filter),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
