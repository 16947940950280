import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { VendorInvoiceDetail } from './VendorInvoiceDetail';
import { JournalEntryCheckbookDetail } from './JournalEntryCheckbookDetail';
import { CheckbookDetailCheck } from './CheckbookDetailCheck';
import { CheckbookDetailDeposit } from './CheckbookDetailDeposit';
import { TRANSACTION_TYPES } from '../utils';

interface ITransactionDetailsModalProps {
	onClose: () => void;
	type: number;
	txnum: number;
}

const TransactionDetailsModal = ({
	onClose,
	type,
	txnum,
}: ITransactionDetailsModalProps) => {
	const isVendorInvoice =
		type >= TRANSACTION_TYPES.INVOICE.MIN_VALUE &&
		type <= TRANSACTION_TYPES.INVOICE.MAX_VALUE;
	const isCheckbookCheck =
		type >= TRANSACTION_TYPES.CHECK.MIN_VALUE &&
		type <= TRANSACTION_TYPES.CHECK.MAX_VALUE;
	const isCheckbookDeposit =
		type >= TRANSACTION_TYPES.DEPOSIT.MIN_VALUE &&
		type < TRANSACTION_TYPES.DEPOSIT.MAX_VALUE;
	const isCheckbookJournalEntry =
		type >= TRANSACTION_TYPES.JOURNAL_ENTRY.MIN_VALUE &&
		type <= TRANSACTION_TYPES.JOURNAL_ENTRY.MAX_VALUE;

	return (
		<Modal show={true} size="lg" centered>
			<>
				{isVendorInvoice && <VendorInvoiceDetail txnum={txnum} />}
				{isCheckbookJournalEntry && (
					<JournalEntryCheckbookDetail txnum={txnum} />
				)}
				{isCheckbookCheck && <CheckbookDetailCheck txnum={txnum} type={type} />}
				{isCheckbookDeposit && (
					<CheckbookDetailDeposit txnum={txnum} type={type} />
				)}
			</>
			<Modal.Footer>
				<Button onClick={onClose} variant="trans-light border-secondary-ash">
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

TransactionDetailsModal.displayName = 'TransactionDetailsModal';

export { TransactionDetailsModal };
