export type UserError = {
	isOk: boolean;
	resourceNotAuhtorized: boolean;
	resourceNotFound: boolean;
	resourceInvalidPayload: boolean;
	resourceForbidden: boolean;
	userError: `${INVALID_REQUESTS}`;
	internalError: string;
	errorCode: string;
};
export enum INVALID_REQUESTS {
	INVALID_INPUT = 'Please make sure your inputs have correct values.',
}
