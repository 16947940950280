import React from 'react';
import {
	PRO_CLOUD_LOGIN_PAGE,
	useUserQuery,
} from 'legacy/app/context/security';
import AppLayout from 'legacy/app/layouts/AppLayout';
import Spinner from 'legacy/app/components/help/Spinner';
import { useNavigate } from 'react-router-dom';
import './InvalidInstance.css';
import { isUserValid } from 'legacy/lib/api/utils/utils';

const InvalidInstanceComponent = () => {
	const {
		data: user,
		isLoading,
		error,
	} = useUserQuery(['SecurityProvider', 'invalidInstance']);

	const navigate = useNavigate();

	const shouldRedirect = user || isUserValid(error);

	if (isLoading) {
		return <Spinner />;
	}

	if (shouldRedirect) {
		navigate('/');
	}
	return (
		<>
			<AppLayout />
			<div className="invalid-instance-container">
				<p className="invalid-instance-text">
					Web DM is not available for your company at this time.
				</p>
				<p className="invalid-instance-text invalid-instance-link-container">
					Please continue to Pro Cloud via
					<a
						className="invalid-instance-link"
						target="_blank"
						rel="noreferrer"
						href={PRO_CLOUD_LOGIN_PAGE}
					>
						{PRO_CLOUD_LOGIN_PAGE}
					</a>
				</p>
			</div>
		</>
	);
};

InvalidInstanceComponent.displayName = 'InvalidInstanceComponent';

export default InvalidInstanceComponent;
