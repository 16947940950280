import { Container, Col, Row } from 'react-bootstrap';
import Switch from '../components/Switch';

function Toggle() {
	return (
		<div className="d-block mt-5">
			<div className="bg-secondary-grey pt-5 pb-4">
				<Container>
					<Row>
						<Col>
							<h2 className="ui-title">Toggle</h2>
						</Col>
					</Row>
				</Container>
			</div>

			<Container className="mt-4">
				<Row className="mb-4">
					<Col>
						<span className="me-4">
							Label <Switch checked={true}></Switch>
						</span>

						<span className="me-4">
							Label <Switch checked={false}></Switch>
						</span>

						<span className="me-4">
							Label <Switch checked={false} disabled={true}></Switch>
						</span>

						<span className="me-4">
							Label <Switch checked={true} disabled={true}></Switch>
						</span>
					</Col>
				</Row>
				<Row className="mb-4">
					<Col>
						<span className="me-4">
							<Switch checked={true}></Switch>
						</span>

						<span className="me-4">
							<Switch checked={false}></Switch>
						</span>

						<span className="me-4">
							<Switch checked={false} disabled={true}></Switch>
						</span>

						<span className="me-4">
							<Switch checked={true} disabled={true}></Switch>
						</span>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col>
						<span className="me-4">
							Label <Switch checked={true} nolabel={true}></Switch>
						</span>

						<span className="me-4">
							Label <Switch checked={false} nolabel={true}></Switch>
						</span>

						<span className="me-4">
							Label{' '}
							<Switch checked={false} disabled={true} nolabel={true}></Switch>
						</span>

						<span className="me-4">
							Label{' '}
							<Switch checked={true} disabled={true} nolabel={true}></Switch>
						</span>
					</Col>
				</Row>

				<Row className="mb-4">
					<Col>
						<span className="me-4">
							<Switch checked={true} nolabel={true}></Switch>
						</span>

						<span className="me-4">
							<Switch checked={false} nolabel={true}></Switch>
						</span>

						<span className="me-4">
							<Switch checked={false} disabled={true} nolabel={true}></Switch>
						</span>

						<span className="me-4">
							<Switch checked={true} disabled={true} nolabel={true}></Switch>
						</span>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default Toggle;
