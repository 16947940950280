import { apiClient } from 'api/apiClient';
import type { TRecentProject } from 'legacy/lib/api/types/RecentProject';

const RESOURCE = 'projects/cashflows';

export const getCashflowProjects = async (
	filter: string
): Promise<TRecentProject[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());
	return data;
};
