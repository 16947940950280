import React from 'react';
import { Outlet } from 'react-router-dom';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';
import { MultiSearchComponentsProps } from '../../../../app/components/multi-search/MultiSearchComponents';

class TransactionSearch extends ThreadComponent {
	render() {
		return (
			<>
				<Outlet />
			</>
		);
	}
}

const buildFilters = (type: string) => {
	switch (type) {
		case 'client':
		case 'vendor':
			return `$filter=inactive eq false `;
		case 'project':
			return `$filter=closeddt eq null `;
		case 'account':
			return ``;
		default:
			return ``;
	}
};

const accountsTypes = [
	{
		key: '(Type ge 1000 and Type lt 1400)',
		value: 'All purchases',
	},
	{
		key: '(Type eq 1000 or Type eq 1041 or Type eq 1061)',
		value: 'Vendor Deposits',
	},
	{
		key: '(Type eq 1010 or Type eq 1043)',
		value: 'Operating Expenses',
	},
	{
		key: "((Type ge 1020 and Type le 1030) or Type eq 1045 or (Type eq 1061 and Ponum gt '0000'))",
		value: 'Project / Order PO Related',
	},
	{
		key: '((Type ge 1032 and Type le 1034) or Type eq 1047 or (Type eq 1061 and Inponum ne 0))',
		value: 'Inventory PO Related',
	},
	{
		key: '(Type ge 1400 and Type lt 2000)',
		value: 'Disbursements (Checks)',
	},
	{
		key: '(Type ge 2000 and Type lt 3000 and Invadj eq false)',
		value: 'All Cash Receipts',
	},
	{
		key: '(Type eq 2000 or Type eq 2011)',
		value: 'Misc.',
	},
	{
		key: '(Type eq 2002 or Type eq 2013)',
		value: 'Retainers / Deposits on Orders',
	},
	{
		key: '(Type eq 2004 or Type eq 2015)',
		value: 'Deposits on Proposals',
	},
	{
		key: '((Type eq 2006 or Type eq 2017) and Invadj eq false)',
		value: 'Payments',
	},
	{
		key: '((Type ge 3000 and Type lt 8000) or (Type ge 2000 and Type lt 3000 and Invadj eq true))',
		value: 'Client Invoices / Invoice Adjustments',
	},
	{
		key: 'Type ge 8000',
		value: 'Journal Entries',
	},
];

const ItemSearchFields: MultiSearchComponentsProps[] = [
	{
		type: 'combobox',
		id: 'project-dropdown',
		title: 'Select Project',
		label: 'Projects',
		apifield: 'proj',
		moduletype: 'project',
		field: 'projn|proj',
		getDataFn: 'getProjectsSummaries',
		queryFilter: buildFilters('project'),
		isFixed: true,
		style: {
			minWidth: '110px',
		},
	},
	{
		type: 'combobox',
		id: 'client-dropdown',
		title: 'Select Client',
		label: 'Client',
		apifield: 'client',
		moduletype: 'client',
		queryFilter: buildFilters('client'),
		field: 'ClientName|client',
		getDataFn: 'getClientsSummaries',
		isFixed: true,
		style: {
			minWidth: '96px',
		},
	},
	{
		type: 'combobox',
		id: 'vendor-dropdown',
		title: 'Select Vendors',
		label: 'Vendor',
		apifield: 'supplier',
		moduletype: 'vendor',
		queryFilter: buildFilters('vendor'),
		field: 'vendorn|vendor',
		getDataFn: 'getVendorsSummaries',
		isFixed: false,
		style: {
			minWidth: '120px',
		},
	},
	{
		type: 'date-range',
		id: 'entry-date',
		label: 'Entry date',
		apifield: 'shipby',
		isFixed: false,
		style: {
			minWidth: '130px',
		},
	},
	{
		type: 'combobox',
		id: 'account-type-dropdown',
		title: 'Select Type',
		label: 'Type',
		apifield: 'supplier',
		options: accountsTypes,
		isFixed: false,
		style: {
			minWidth: '120px',
		},
	},
	{
		type: 'date-range',
		id: 'keyed-date',
		label: 'Keyed date',
		apifield: 'shipby',
		isFixed: false,
		style: {
			minWidth: '130px',
		},
	},
	{
		type: 'combobox',
		id: 'vendor-dropdown',
		title: 'Select Account No.',
		label: 'Account No.',
		apifield: 'supplier',
		moduletype: 'account',
		queryFilter: buildFilters('account'),
		field: 'accountn',
		getDataFn: 'getGLAccounts',
		isFixed: false,
		style: {
			minWidth: '120px',
		},
	},
	{
		type: 'text',
		id: 'trans-no',
		label: 'Trans No.',
		apifield: '',
		isFixed: false,
		style: {
			minWidth: '140px',
		},
	},
	{
		type: 'text',
		id: 'proposal-no',
		label: 'Proposal No.',
		apifield: '',
		isFixed: false,
		style: {
			minWidth: '140px',
		},
	},
	{
		type: 'number-range',
		id: 'transaction-amount',
		label: 'Transaction Amount',
		apifield: '',
		isFixed: false,
		style: {
			minWidth: '140px',
		},
	},
	{
		type: 'text',
		id: 'purchase-order-number',
		label: 'Purchase Order Number',
		apifield: '',
		isFixed: false,
		style: {
			minWidth: '140px',
		},
	},
	{
		type: 'text',
		id: 'check-number',
		label: 'Fiscal Month',
		apifield: '',
		isFixed: false,
		style: {
			minWidth: '140px',
		},
	},
	{
		type: 'text',
		id: 'check-number',
		label: 'Vendor Invoice Number',
		apifield: '',
		isFixed: false,
		style: {
			minWidth: '140px',
		},
	},
];

export { ItemSearchFields };
export default WithRouter(TransactionSearch);
