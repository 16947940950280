import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

const ITEMS_PER_PAGE = 10;

const usePaginatedQuery = <T>(
	endpoint: (filterString: string) => Promise<T[]>,
	initialFilter = ''
) => {
	const [allData, setAllData] = useState<T[]>([]);
	const [filter, setFilter] = useState(initialFilter);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	const constructFilterString = (baseFilter: string, page: number) => {
		let filterString = baseFilter
			.replace(/&?\$top=\d+/, '')
			.replace(/&?\$skip=\d+/, '');
		filterString += `${
			filterString === '' ? '?' : '&'
		}$top=${ITEMS_PER_PAGE}&$skip=${page * ITEMS_PER_PAGE}`;
		return filterString;
	};

	const fetchData = async (filter: string, page: number) => {
		const filterString = constructFilterString(filter, page);
		return await endpoint(filterString);
	};

	const { refetch, isLoading, isFetching } = useQuery<T[], Error>(
		['data', filter, page],
		() => fetchData(filter, page),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			enabled: hasMore,
			onSuccess: (newData) => {
				if (newData.length < ITEMS_PER_PAGE) {
					setHasMore(false);
				}
				if (page === 0) {
					setAllData(newData);
				} else {
					setAllData((prevData) => [...prevData, ...newData]);
				}
			},
		}
	);

	const updateFilter = (newFilter: string) => {
		setFilter(newFilter);
		setPage(0);
		setHasMore(true);
		setAllData([]);
		refetch();
	};

	const loadMore = () => {
		if (hasMore && !isFetching) {
			setPage((prevPage) => prevPage + 1);
		}
	};

	return {
		data: allData,
		isFetching,
		updateFilter,
		isLoading,
		loadMore,
		hasMore,
	};
};

export default usePaginatedQuery;
