import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Form } from 'react-bootstrap';

const DisabledCheckBoxWithTooltip = ({
	tooltipText,
}: {
	tooltipText: string;
}) => {
	return (
		<OverlayTrigger
			overlay={<Tooltip style={{ zIndex: 20000 }}>{tooltipText}</Tooltip>}
		>
			<span>
				<Form.Check
					inline
					className="chk-projectview-proposal-modal-item"
					label=""
					readOnly
					type="checkbox"
					disabled
					style={{ pointerEvents: 'none' }}
				/>
			</span>
		</OverlayTrigger>
	);
};

DisabledCheckBoxWithTooltip.displayName = 'DisabledCheckBoxWithTooltip';

export default DisabledCheckBoxWithTooltip;
