import React, { ReactNode } from 'react';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import { useExternalNavigate } from 'legacy/lib/api/hooks/useExternalNavigate';
interface ISectionContent {
	title?: string;
	subtitle?: string;
	children?: ReactNode;
}

const SectionContent = ({
	title,
	subtitle = '',
	children,
}: ISectionContent) => {
	const externalNavigate = useExternalNavigate();

	return (
		<>
			<div className="tw-flex tw-justify-between tw-items-center">
				<div>
					{title && <h2 className="tw-text-xl">{title}</h2>}
					{subtitle && <p className="tw-text-gray-400">{subtitle}</p>}
				</div>
				<BaseButton
					text="View All"
					buttonClass="tw-px-4 tw-py-2 tw-border tw-border-[#006a53] tw-rounded tw-text-black hover:tw-cursor-pointer tw-text-center"
					onClick={() =>
						externalNavigate('https://help.designmanager.com/', true)
					}
				/>
			</div>
			{children}
		</>
	);
};

SectionContent.displayName = 'SectionContent';

export default SectionContent;
