import ThreadComponent from '../../../../templates/modules/ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Spinner from '../../../../app/components/help/Spinner';

class ProjectProposalResetDepositAllocModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
		};

		this.api = new ApiService();
	}

	handleReset = async () => {
		this.setState({
			isLoading: true,
		});

		await this.api.resetDepositAllocation({
			ProjectCode: this.props.proj,
			PropNum: this.props.propnum,
		});

		this.setState({
			isLoading: false,
		});
		this.props.hideModal();
	};

	render() {
		return (
			<Modal
				size="md"
				show={this.props.show}
				onHide={() => this.hide()}
				aria-labelledby="example-modal-sizes-title-lg"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3">
						<Col className="px-5">
							<h5 className="text-center mx-auto pt-3 fw-bold">
								Re-Allocate Received Deposit
							</h5>
							<div className="text-center mx-auto pt-3">
								<h6>
									Doing this will cause all the manual changes to be lost.
									<br />
									Do you wish to continue?
								</h6>
							</div>
							<div className="text-center mt-5">
								<Button
									className="me-3"
									variant="secondary"
									onClick={this.props.hideModal}
								>
									Cancel
								</Button>
								<Button
									variant="primary"
									onClick={this.handleReset}
									disabled={this.state.isLoading}
								>
									{this.state.isLoading ? (
										<>
											<span
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
											<span className="ms-2 sr-only">Loading...</span>
										</>
									) : (
										'Ok'
									)}
								</Button>
							</div>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		);
	}
}

export default ProjectProposalResetDepositAllocModal;
