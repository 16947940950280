import React from 'react';
import {
	getRouteWithParam,
	WithRouter,
	routeParam,
} from '../../../helpers/Router';
import { Form, Table, Button, Dropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../ThreadComponent';
import Switch from '../../components/Switch';
import ListFilter from '../../components/ListFilter';
import URI from '../../../defaults/RoutesDefault';
import { ApiService } from '../../../lib/api/HttpService';
import debounce from 'lodash/debounce';
import { Pagination } from '../../../app/components/pagination/Pagination';
import { addDomClass, hasClass, removeDomClass } from '../../../helpers/DOM';
import { currencyFormat } from '../../../helpers/Number';
import DeleteModal from '../../../app/components/modal/DeleteModal';
import HandleDeleteWorker from '../../../utilities/DeleteWorker';
import { showEmpty, showLoading } from '../../../helpers/Loading';
import { isFiltered } from '../../../helpers/Util';
import {
	formatDate,
	formatDateTime,
	formatFilterDate,
} from '../../../helpers/Date';
import { DateRangePicker } from 'rsuite';
import noItems from '../../../assets/images/icons/order.svg';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import SendPurchaseOrderModal from '../modal/SendPurchaseOrderModal';
import { displayAlert, displayAlertLoader } from '../../../utilities/Response';
import MSG from '../../../defaults/Message';
import { isEmpty, isArray } from 'lodash';
import FilterSort from '../../../utilities/modules/FilterSort';
import BatchCreateConfirmModal from '../modal/BatchCreateConfirmModal';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import SecureDropdownItem from '../../../app/components/security/SecureDropdownItem';

class ProjectViewVendorOrder extends ThreadComponent {
	constructor(props) {
		super(props);

		this.projectId = this.props.params.id;
		this.fs = new FilterSort('project_vendor_invoice_list_' + this.projectId);
		this.fs.setDefaultSort('ponum desc');
		this.state = {
			checks: [],
			data: {},
			dataIsLoaded: false,
			open: this.fs.getFilter('open') ?? false,
			pageSize: 20,
			page: 1,
			sortProperty: this.fs.getSort() ?? 'ponum desc',
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
			selectedPOID: null,
			showModal: false,
			showBatchCreateConfirmModal: false,
			disabledDeleteButton: true,
			showSendModal: false,
			sendPOData: [],
			selectedItems: [],
			selectedItemTypes: [],
			project: {},
		};

		this.api = new ApiService();
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);

		this.objName = 'Project Purchase Order';
		this.objReference = 'Projects';

		this.deleteObjectParams = {
			deleteType: 'dmriTestOnly',
			objectType: 'objPO',
			objectCodeOrId: '',
		};
	}

	componentInit() {
		this.setFormAction(true);
	}

	async componentDidMount() {
		if (!isEmpty(this.props.project?.proj)) {
			this.setState(
				{
					project: this.props.project,
					dataIsLoaded: false,
				},
				this.loadData
			);
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		let project = {};
		if (previousProps.project !== this.props.project) {
			project = this.props.project || {};

			this.setState(
				{
					project,
					dataIsLoaded: false,
				},
				this.loadData
			);
		}
	}

	async loadData() {
		await this.fetchItems();
		this.enableSortTable();
	}

	async fetchItems(page, setLoading) {
		if (setLoading) this.setState({ dataIsLoaded: false });

		const dataResponse = await this.api.get(
			'ProjectPurchaseOrders',
			`?${this.buildFilters(page)}`
		);
		const data = dataResponse ? dataResponse : [];

		const lastNotificationIds = data
			.map((i) => i.lastNotificationId)
			.filter((val) => val !== null && val !== undefined);

		let notifInfo = [];
		if (lastNotificationIds.length > 0) {
			const notifResponse = await this.api.get(
				`notifications`,
				`?$filter=id in (${lastNotificationIds.join(',')})`
			);
			notifInfo = notifResponse ? notifResponse : [];
		}

		const poNumbers = data.map((item) => item.ponum);
		const projectCode = this.props.project.shipto || this.state.project.shipto;

		const paymentInfoResponse = await this.api.postJson(
			'projectpurchaseorders/check-info',
			{
				poNumbers,
				projectCode,
			}
		);

		const paymentInfo = paymentInfoResponse ?? [];
		const dataWithPaymentInfo = data.map((item) => {
			const notif = notifInfo.find(
				(i) => i && i.id === item.lastNotificationId
			);
			const toInfo = notif
				? this.getNotifPayload(notif.notificationPayload, 'To')
				: '';
			item.sentInfo =
				toInfo && Array.isArray(toInfo) ? toInfo.join('\n') : toInfo ?? '';
			let sentStatus = '';
			const sentInfo =
				notif && notif.sentInfo ? `Sent to: ${notif.sentInfo}` : '';
			if (item.notificationSent) {
				item.sentDateTime = formatDateTime(item.sentDateTimeInUserTimeZone);
				sentStatus = sentInfo;
			}
			if (item.lastNotificationId !== null && !item.notificationSent) {
				item.sentInfo = 'Pending...';
			}
			item.sentStatus = sentStatus;
			const paymentDetails = paymentInfo.find(
				(info) => info && info.poNumber === item.ponum
			);
			item.paidCheckInfo = paymentDetails ? paymentDetails.checkInfo : '';

			return item;
		});

		this.setState({ dataIsLoaded: true, data: dataWithPaymentInfo });
	}

	handleSwitch = (e) => {
		this.setState(
			{
				open: e.target.checked,
			},
			() => {
				this.handleChangePage(1);
			}
		);
		this.fs.setFilter('open', e.target.checked);
	};

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.fs.setIsActiveSearch(newTableSearch);
		this.setState({
			showTableSearch: newTableSearch,
		});

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	doTableSearch(data) {}

	buildFilters(currentPage) {
		let filtersQuery = `&$filter=proj eq '${this.props.project.proj}'`;
		let filters = [];

		if (this.state.open) {
			filters.push(`open eq true`);
		}

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.ma) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		filtersQuery += filters.length > 0 ? ` and ${filters.join(' and ')}` : '';

		let queryString = `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}${filtersQuery}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	getNotifPayload(payload, key) {
		payload = payload?.replace(/[\n\r\t]/g, '');
		if (payload?.length) {
			try {
				payload = JSON.parse(payload);
				return payload[key];
			} catch (error) {
				// Ignore
				console.error(error);
			}
		}

		return '';
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchItems(page, true);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	async handleDocument(e, id, label) {
		e.preventDefault();
		const { link } = await this.api.getPublicMappings(id, label);
		window.open(link, '_blank').focus();
	}

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	// Refresh data
	refreshDataAfterDelete = (e) => {
		const headingCheck = document.getElementById('inline-check-th-0');

		this.setState({
			checks: [],
			showModal: false,
			deleteResponses: [],
		});

		if (typeof headingCheck != 'undefined' && headingCheck != null) {
			headingCheck.checked = false;
		}

		this.fetchItems(this.state.page);
	};

	hideModal = (e) => {
		this.toggleDeleteButton(e);
		this.setState({
			showModal: false,
		});
	};

	toggleDeleteButton(e) {
		this.setState({
			disabledDeleteButton: !this.state.disabledDeleteButton,
		});
	}

	handleBatchCreate() {
		this.setState({ showBatchCreateConfirmModal: true });
	}

	async handleTestDeleteObjects(deactivateOnly) {
		if (!this.state.checks.length) {
			return;
		}

		const ids = this.state.data
			.filter((i) => this.state.checks.includes(i.ponum))
			.map((i) => i.id);

		let workers = [];

		displayAlertLoader(MSG.loading.prepare.item);

		for (const id of ids) {
			const params = {
				deleteType: 'dmriTestOnly',
				objectType: this.deleteObjectParams.objectType,
				objectCodeOrId: id,
			};
			workers.push(await this.api.postJson('deleteobjects', params));
		}

		if (workers) {
			HandleDeleteWorker(
				workers,
				{
					moduleSingular: this.objName,
					modulePlural: this.objName + 's',
					reference: this.objReference,
					objectType: this.deleteObjectParams.objectType,
				},
				false,
				(modalState) => {
					this.setState(modalState);
				}
			);
		}
	}

	handleSelectAllChecks = () => {
		let checks = [];
		let selectedItems = [];
		let selectedItemTypes = [];
		if (!this.state.checks.length) {
			this.state.data.map((item) => {
				checks.push(item.ponum);
				selectedItems.push({
					poid: item.id,
					type: item.potype,
				});

				if (!selectedItemTypes.includes(item.potype)) {
					selectedItemTypes.push(item.potype);
				}
			});
		}

		this.setState({
			checks,
			selectedItems,
			selectedItemTypes,
		});
	};

	handleCheck = (poid, id, type) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		const selectedItems = this.state.selectedItems;
		const itemIndx = selectedItems.findIndex((_item) => _item.poid === poid);

		index > -1 ? checks.splice(index, 1) : checks.push(id);
		itemIndx > -1
			? selectedItems.splice(itemIndx, 1)
			: selectedItems.push({
					poid,
					type,
			  });

		let selectedItemTypes = [];
		for (let s in selectedItems) {
			const pType = selectedItems[s].type;
			if (!selectedItemTypes.includes(pType)) {
				selectedItemTypes.push(pType);
			}
		}

		this.setState({
			selectedItemTypes: selectedItemTypes,
			checks: checks,
			selectedItems: selectedItems,
			selectedPOID: checks.length === 1 ? poid : null,
		});
	};

	openSendModal = async () => {
		let allHasPdfs = false;
		let orders = this.state.data.filter((i) =>
			this.state.checks.includes(i.ponum)
		);
		let suppliers = orders.map((i) => i.supplier);
		suppliers = suppliers.filter((x, i, a) => a.indexOf(x) === i);

		allHasPdfs = orders.length > 0 && orders.every((o) => o.fileId);

		if (suppliers.length > 1) {
			displayAlert(
				'danger',
				'To send email, please select purchase/work order(s) with the same vendor.'
			);
		} else if (!allHasPdfs) {
			displayAlert(
				'danger',
				'To send email, all the selected purchase/work order(s) must have generated PDFs.'
			);
		} else {
			this.setState({
				showSendModal: true,
				sendPOData: orders,
			});
		}
	};

	closeSendModal = () => {
		this.setState({ showSendModal: false });
		this.refreshDataAfterDelete();
	};

	hideBatchCreateConfirmModal = () => {
		this.setState({ showBatchCreateConfirmModal: false });
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	isDisplaySearch() {
		return (
			(this.state.data && this.state.data.length > 0) ||
			isFiltered(this.state.searchProperties) ||
			!this.state.dataIsLoaded
		);
	}

	renderFilter() {
		return (
			<>
				<ListFilter className="project-vendor-orders-filter">
					<ListFilter.Fields md={5} lg={4} xl={4}>
						<ListFilter.Field className="flex-wrap">
							<Form.Label style={{ minWidth: '120px', maxWidth: '100%' }}>
								Show Open Only
							</Form.Label>
							<span>
								<Switch
									onChange={this.handleSwitch}
									checked={this.state.open}
								></Switch>
							</span>
						</ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						md={7}
						lg={8}
						xl={8}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-end"
					>
						<ListFilter.Action>
							{this.isDisplaySearch() && (
								<Button
									as={Link}
									to="#"
									variant="ivory"
									size="sm"
									className={`btn-icon btn-action fw-bold ${
										this.state.showTableSearch
											? 'bg-primary-ash text-white'
											: ''
									}`}
									onClick={this.handleShowTableSearch}
								>
									<i className="ri-search-line"></i> Search
								</Button>
							)}
							{this.props.closed ? (
								<>
									<Button disabled size="sm" className="btn-icon ms-3 mw-120px">
										<i className="ri-add-fill"></i> Add Purchase Order
									</Button>
									<Button disabled size="sm" className="btn-icon ms-3 mw-120px">
										<i className="ri-add-fill"></i> Add Work Order
									</Button>
								</>
							) : (
								<>
									<SecureBootstrapButton
										attributeNo={18}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
										as={Link}
										to={routeParam(URI.project.vendorOrder.addPurchaseOrder, {
											id: this.props.project.id,
										})}
										variant="primary"
										size="sm"
										className="btn-icon ms-3 mw-120px"
									>
										<i className="ri-add-fill"></i> Add Purchase Order
									</SecureBootstrapButton>
									<SecureBootstrapButton
										attributeNo={18}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
										as={Link}
										to={routeParam(URI.project.vendorOrder.addWorkOrder, {
											id: this.props.project.id,
										})}
										variant="primary"
										size="sm"
										className="btn-icon ms-3 mw-120px"
									>
										<i className="ri-add-fill"></i> Add Work Order
									</SecureBootstrapButton>
								</>
							)}
						</ListFilter.Action>
						<ListFilter.Action className="ms-3">
							{this.props.closed ? (
								<Dropdown
									className="d-flex justify-content-end ms-auto"
									align="end"
								>
									<Dropdown.Toggle
										id="dropdown-autoclose-true"
										disabled
										size="sm"
									>
										Actions
									</Dropdown.Toggle>
								</Dropdown>
							) : (
								<Dropdown
									className="d-flex justify-content-end ms-auto"
									align="end"
								>
									<Dropdown.Toggle
										disabled={this.state.checks.length === 0}
										id="dropdown-autoclose-true"
										variant="ivory"
										size="sm"
									>
										Actions
									</Dropdown.Toggle>

									<Dropdown.Menu>
										<SecureDropdownItem
											attributeNo={50}
											attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
											className={!this.state.selectedPOID ? 'disabled' : ''}
											as={Link}
											to={routeParam(URI.project.vendorOrder.modifyStatus, {
												id: this.props.params.id,
												poId: this.state.selectedPOID,
											})}
										>
											<i className="ri-edit-fill"></i>
											Modify Status
										</SecureDropdownItem>
										{/* show v1
                                    <Dropdown.Item href={'#'}>Create Report</Dropdown.Item>
                                */}
										<SecureDropdownItem
											attributeNo={18}
											attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
											onClick={this.openSendModal}
										>
											<i className="ri-mail-send-line"></i> Send
										</SecureDropdownItem>
										{/* TODO: Find where handleBatchCreate broke and why the checks in
                                        classnames don´t work */}
										<Dropdown.Item
											onClick={this.handleBatchCreate?.bind(this)}
											href="#"
											className={
												this.state.checks?.length < 2 ||
												this.state.selectedItemTypes?.length > 1
													? 'disabled'
													: ''
											}
										>
											<i className="ri-list-settings-line"></i> Batch Create
										</Dropdown.Item>
										<SecureDropdownItem
											attributeNo={18}
											attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
											onClick={this.handleTestDeleteObjects.bind(this)}
											href="#"
											className={
												this.state.checks.length === 0 ? 'disabled' : ''
											}
										>
											<i className="ri-delete-bin-5-line"></i> Delete
										</SecureDropdownItem>
									</Dropdown.Menu>
								</Dropdown>
							)}
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderPagination() {
		if (this.state.data.length) {
			return (
				<Pagination
					onPageSizeChanged={this.onPageSizeChanged}
					onPageChanged={this.onPageChanged}
					hasPreviousPage={this.state.page > 1}
					hasNextPage={this.state.data.length > this.state.pageSize}
					page={this.state.page}
					pageSize={this.state.pageSize}
				/>
			);
		}
	}

	render() {
		return (
			<>
				<div>
					{/* Filter */}
					{this.renderFilter()}

					{(this.state.data && this.state.data.length > 0) ||
					isFiltered(this.state.searchProperties) ||
					!this.state.dataIsLoaded ? (
						<>
							<div className="table-gradient sticky-container">
								<Table striped responsive className="a-table">
									<thead>
										<tr key="0" className="a-table-heading">
											<th align="middle" className="mw-70px">
												<div className="d-flex justify-content-center">
													<Form.Check
														label=""
														type="checkbox"
														checked={this.state.checks.length}
														className={this.state.checks ? 'line' : ''}
														onChange={this.handleSelectAllChecks}
													/>
												</div>
											</th>
											<th className="mw-180px">
												<span
													className={this.sortClass('sent')}
													data-field="sent"
												>
													Sent Status
												</span>
											</th>
											<th>
												<span className="sort active as" data-field="fileId">
													Document
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('ponum')}
													data-field="ponum"
												>
													PO/WO No.
												</span>
											</th>
											<th className="mw-80px">
												<span data-field="Type">Type</span>
											</th>
											<th>
												<span
													className={this.sortClass('orderdt')}
													data-field="orderdt"
												>
													Date
												</span>
											</th>
											<th className="mw-80px">
												<span
													className={this.sortClass('open')}
													data-field="open"
												>
													Open
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('vendorName')}
													data-field="vendorName"
												>
													Vendor Name
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('shipto')}
													data-field="shipto"
												>
													Ship to Code
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('attention')}
													data-field="attention"
												>
													Ship to Name
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('poAmount')}
													data-field="poAmount"
												>
													PO Amount (Est. Cost)
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('verbalref')}
													data-field="verbalref"
												>
													Verbal/Ref. Number
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('notes')}
													data-field="notes"
												>
													Notes
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('depositSent')}
													data-field="depositSent"
												>
													Deposit Sent
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('revdt')}
													data-field="revdt"
												>
													Rev. Date
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('ackdt')}
													data-field="ackdt"
												>
													Ack Date
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('ackno')}
													data-field="ackno"
												>
													Ack Number
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('poAmountDep')}
													data-field="poAmountDep"
												>
													PO Amount Less Deposit
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('amountPaid')}
													data-field="amountPaid"
												>
													Actual Vendor Invoices Paid
												</span>
											</th>
											<th>
												<span
													className={this.sortClass('paidCheckInfo')}
													data-field="paidCheckInfo"
												>
													Payment Info.
												</span>
											</th>
										</tr>
										<tr
											className={`a-table-search-fields ${
												this.state.showTableSearch ? '' : 'd-none'
											}`}
										>
											<th></th>
											<th></th>
											<th></th>
											<th>
												<Form.Control
													type="text"
													data-field="ponum"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('ponum')}
													style={{
														minWidth: '70px',
													}}
												/>
											</th>
											<th></th>
											<th>
												<DateRangePicker
													style={{
														minWidth: '200px',
													}}
													placement="auto"
													placeholder="Select date"
													format="MM/dd/yyyy"
													defaultValue={this.fs.getValue('orderdt')}
													onChange={this.handleDateChange.bind(this, 'orderdt')}
													onClean={this.handleDateChange.bind(this, 'orderdt')}
													ranges={[
														{
															label: 'today',
															value: [
																startOfDay(new Date()),
																endOfDay(new Date()),
															],
														},
														{
															label: 'yesterday',
															value: [
																startOfDay(addDays(new Date(), -1)),
																endOfDay(addDays(new Date(), -1)),
															],
														},
														{
															label: 'last7Days',
															value: [
																startOfDay(subDays(new Date(), 6)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'Last 30 Days',
															value: [
																startOfDay(subDays(new Date(), 30)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'This month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()),
																		1
																	)
																),
																endOfDay(lastDayOfMonth(new Date())),
															],
														},
														{
															label: 'Last month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																),
																endOfDay(
																	lastDayOfMonth(
																		new Date(
																			getYear(new Date()),
																			getMonth(new Date()) - 1,
																			1
																		)
																	)
																),
															],
														},
													]}
												/>
											</th>
											<th></th>
											<th>
												<Form.Control
													type="text"
													data-field="vendorName"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('vendorName')}
													style={{
														minWidth: '100px',
													}}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="shipto"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('shipto')}
													style={{
														minWidth: '80px',
													}}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="attention"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('attention')}
													style={{
														minWidth: '100px',
													}}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="poAmount"
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('poAmount')}
													style={{
														minWidth: '80px',
													}}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="verbalref"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('verbalref')}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="notes"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('notes')}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="depositSent"
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('depositSent')}
													style={{
														minWidth: '80px',
													}}
												/>
											</th>
											<th>
												<DateRangePicker
													style={{
														minWidth: '200px',
													}}
													placement="auto"
													placeholder="Select date"
													format="MM/dd/yyyy"
													defaultValue={this.fs.getValue('revdt')}
													onChange={this.handleDateChange.bind(this, 'revdt')}
													onClean={this.handleDateChange.bind(this, 'revdt')}
													ranges={[
														{
															label: 'today',
															value: [
																startOfDay(new Date()),
																endOfDay(new Date()),
															],
														},
														{
															label: 'yesterday',
															value: [
																startOfDay(addDays(new Date(), -1)),
																endOfDay(addDays(new Date(), -1)),
															],
														},
														{
															label: 'last7Days',
															value: [
																startOfDay(subDays(new Date(), 6)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'Last 30 Days',
															value: [
																startOfDay(subDays(new Date(), 30)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'This month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()),
																		1
																	)
																),
																endOfDay(lastDayOfMonth(new Date())),
															],
														},
														{
															label: 'Last month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																),
																endOfDay(
																	lastDayOfMonth(
																		new Date(
																			getYear(new Date()),
																			getMonth(new Date()) - 1,
																			1
																		)
																	)
																),
															],
														},
													]}
												/>
											</th>
											<th>
												<DateRangePicker
													style={{
														minWidth: '200px',
													}}
													placement="auto"
													placeholder="Select date"
													format="MM/dd/yyyy"
													defaultValue={this.fs.getValue('ackdt')}
													onChange={this.handleDateChange.bind(this, 'ackdt')}
													onClean={this.handleDateChange.bind(this, 'ackdt')}
													ranges={[
														{
															label: 'today',
															value: [
																startOfDay(new Date()),
																endOfDay(new Date()),
															],
														},
														{
															label: 'yesterday',
															value: [
																startOfDay(addDays(new Date(), -1)),
																endOfDay(addDays(new Date(), -1)),
															],
														},
														{
															label: 'last7Days',
															value: [
																startOfDay(subDays(new Date(), 6)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'Last 30 Days',
															value: [
																startOfDay(subDays(new Date(), 30)),
																endOfDay(new Date()),
															],
														},
														{
															label: 'This month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()),
																		1
																	)
																),
																endOfDay(lastDayOfMonth(new Date())),
															],
														},
														{
															label: 'Last month',
															value: [
																startOfDay(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																),
																endOfDay(
																	lastDayOfMonth(
																		new Date(
																			getYear(new Date()),
																			getMonth(new Date()) - 1,
																			1
																		)
																	)
																),
															],
														},
													]}
												/>
											</th>
											<th>
												<Form.Control
													type="text"
													data-field="ackno"
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('ackno')}
												/>
											</th>
											<th>
												<Form.Control
													style={{
														minWidth: '90px',
													}}
													type="number"
													data-type="number"
													data-field="poAmountDep"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('poAmountDep')}
												/>
											</th>
											<th>
												<Form.Control
													type="number"
													data-field="amountPaid"
													data-type="number"
													onChange={this.handleSearch}
													defaultValue={this.fs.getValue('amountPaid"')}
												/>
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{!this.state.dataIsLoaded
											? showLoading()
											: this.state.data.length === 0 &&
											  isFiltered(this.state.searchProperties)
											? showEmpty()
											: this.state.data
													.slice(0, this.state.pageSize)
													.map((item, i) => (
														<tr
															key={i}
															className={this.state.checks[i] ? `active` : ''}
														>
															<td>
																<div className="d-flex justify-content-center">
																	<Form.Check
																		label=""
																		type="checkbox"
																		checked={this.state.checks.includes(
																			item.ponum
																		)}
																		onChange={(e) => {
																			this.handleCheck(
																				item.id,
																				item.ponum,
																				item.potype
																			);
																		}}
																	/>
																</div>
															</td>
															<td>
																<div className="d-flex">
																	<strong
																		className={
																			item.notificationSent
																				? `dot-primary`
																				: `dot-primary-red`
																		}
																	></strong>
																	{item.sentDateTime}
																	{item.sentStatus ? (
																		<React.Fragment>
																			<br />
																			{item.sentStatus}
																		</React.Fragment>
																	) : (
																		<>
																			<br />
																			{item.sentInfo}
																		</>
																	)}
																</div>
															</td>
															<td>
																{item.fileId && (
																	<a
																		href=""
																		onClick={(e) => {
																			this.handleDocument(
																				e,
																				item.fileId,
																				item.purchaseOrderName
																			);
																		}}
																	>
																		PDF
																	</a>
																)}
															</td>
															<td>
																{this.props.closed ? (
																	item.ponum
																) : (
																	<Link
																		to={
																			item.potype === 1
																				? routeParam(
																						URI.project.vendorOrder
																							.editWorkOrder,
																						{
																							id: this.props.project.id,
																							orderId: item.ponum,
																						}
																				  )
																				: routeParam(
																						URI.project.vendorOrder
																							.editPurchaseOrder,
																						{
																							id: this.props.project.id,
																							orderId: item.ponum,
																						}
																				  )
																		}
																		className="text-charcoal hover-view-icon"
																	>
																		{item.ponum}
																	</Link>
																)}
															</td>
															<td>{item.potype ? 'WO' : 'PO'}</td>
															<td>{formatDate(item.orderdt)}</td>
															<td>
																<strong
																	className={`dot-${
																		item.open ? 'primary' : 'primary-red'
																	}`}
																></strong>
															</td>
															<td>{item.vendorName}</td>
															<td>{item.shipto}</td>
															<td>{item.attention}</td>
															<td className="align-right">
																{currencyFormat(item.poAmount, '$')}
															</td>
															<td>{item.verbalref}</td>
															<td>{item.notes}</td>
															<td className="align-right">
																{currencyFormat(item.depositSent, '$')}
															</td>
															<td>{formatDate(item.revdt)}</td>
															<td>{formatDate(item.ackdt)}</td>
															<td>{item.ackno}</td>
															<td className="align-right">
																{currencyFormat(item.poAmountDep, '$')}
															</td>
															<td className="align-right">
																{currencyFormat(item.amountPaid, '$')}
															</td>
															<td>{item.paidCheckInfo}</td>
														</tr>
													))}
									</tbody>
								</Table>
							</div>
							{this.renderPagination()}
						</>
					) : (
						''
					)}

					<BatchCreateConfirmModal
						show={this.state.showBatchCreateConfirmModal}
						hideModal={this.hideBatchCreateConfirmModal}
						items={this.state.selectedItems}
					/>

					<DeleteModal
						refreshData={this.refreshDataAfterDelete.bind(this)}
						show={this.state.showModal}
						hideModal={this.hideModal}
						responses={this.state.deleteResponses}
					/>

					{this.state.showSendModal && (
						<SendPurchaseOrderModal
							hideModal={this.closeSendModal}
							sendPOData={this.state.sendPOData}
							project={this.props.project}
						/>
					)}
				</div>

				{this.state.dataIsLoaded &&
					!isFiltered(this.state.searchProperties) &&
					!this.state.data.length && (
						<Row className="justify-content-center text-center py-5">
							<Col lg={5}>
								<img src={noItems} className="mw-100 mb-4" alt="" />

								<h6>Create a Purchase Order</h6>
								<p>
									This is where you manage the orders that are placed with
									vendors.
								</p>

								<Row>
									<Col>
										<Button
											as={Link}
											to={routeParam(URI.project.vendorOrder.addPurchaseOrder, {
												id: this.props.project.id,
											})}
											variant="primary"
											size="md"
											className="btn-icon ms-2"
										>
											<i className="ri-add-line ri-lg"></i> Add a Purchase Order
										</Button>
									</Col>
									<Col>
										<Button
											as={Link}
											to={routeParam(URI.project.vendorOrder.addWorkOrder, {
												id: this.props.project.id,
											})}
											variant="primary"
											size="md"
											className="btn-icon ms-2"
										>
											<i className="ri-add-line ri-lg"></i> Add a Work Order
										</Button>
									</Col>
								</Row>
							</Col>
						</Row>
					)}
			</>
		);
	}
}

export default WithRouter(ProjectViewVendorOrder);
