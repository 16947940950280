import React from 'react';
import {
	Col,
	Row,
	Form,
	Breadcrumb,
	Container,
	Button,
	Table,
} from 'react-bootstrap';
import { generatePath, Link, NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import {
	getRouteWithParam,
	routeParam,
	WithRouter,
} from '../../../../helpers/Router';
import { FooterFormAction } from '../../../components/Form';
import { HeaderLight } from '../../../components/Header';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import ThreadComponent from '../../ThreadComponent';
import ListFilter from '../../../components/ListFilter';
import ModifyStatusDialog from './ModifyStatusDialog';
import { ApiService } from '../../../../lib/api/HttpService';
import DatePicker from 'react-datepicker';
import { Pagination } from '../../../../app/components/pagination/Pagination';
import {
	displayAlert,
	displayAlertLoader,
} from '../../../../utilities/Response';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import { Is } from '../../../../helpers/Util';
import { showLoading } from '../../../../helpers/Loading';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { formatDate } from '../../../../helpers/Date';
import MSG from '../../../../defaults/Message';

class ProjectVendorOrdersModifyStatus extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			sortProperty: 'refNum asc',
			data: [],
			selectedDataIndex: null,
			components: [],
			isShowing: false,
			selectedData: {},
			purchaseOrderId: null,
			order: null,
			vendor: null,
			project: null,
			ackDate: '',
			ackNo: '',
			isClosePO: false,
			poCloseDate: '',
			ponum: '',
			notes: '',
			notesrtf: '',
			page: 1,
			pageSize: 20,
			isLoaded: false,
			dataSize: 0,
			notesVal: '',
			notesValRtf: '',
			noteshtml: '',
			company: {},
			hasFound: true,
		};

		this.api = new ApiService();

		this.htmlToRtfCoverter = new HtmlToRtfBrowser();
	}

	async componentDidMount() {
		const purchaseOrder = await this.getPurchaseOrderById();
		await this.getPurchaseOrderComponents(1, purchaseOrder);
		await this.getDataSize(purchaseOrder);

		const company = await this.api.getCompany();
		this.setState({
			company: Is.empty(company) ? {} : company,
		});
		this.setIsLoaded(true);
	}

	async getPurchaseOrderById() {
		let filter = `?$filter=id eq ${this.props.params.poId}`;
		const purchaseOrder = await this.api.getProjectPurchaseOrders(filter);

		if (purchaseOrder.length) {
			this.setInitialData(purchaseOrder[0]);
		} else {
			this.setIsFound(false);
		}

		return purchaseOrder[0];
	}

	async getPurchaseOrderComponents(page, purchaseOrderDetails = {}) {
		this.setIsLoaded(true);

		let queryString = `$top=${this.state.pageSize}&$skip=${
			((page ?? this.state.page) - 1) * this.state.pageSize
		}`;
		const result = await this.api.get(
			'projectpurchaseordercomponents/status/grid',
			`?${queryString}&$filter=ponum eq '${
				purchaseOrderDetails?.ponum
					? purchaseOrderDetails?.ponum
					: this.state.ponum
			}' and proj eq '${
				purchaseOrderDetails?.proj
					? purchaseOrderDetails.proj
					: this.state.project
			}'&$orderby=refnum asc`
		);
		this.setState({
			data: result,
		});
	}

	resetUpdateState = (shouldFetchPurchaseOrderComponents) =>
		this.setState(
			{
				isShowing: false,
				selectedData: {},
				selectedDataIndex: null,
			},
			() => {
				if (shouldFetchPurchaseOrderComponents)
					this.getPurchaseOrderComponents(this.state.page);
			}
		);

	handleModalUpdate = () => this.resetUpdateState(true);

	async getDataSize(purchaseOrderDetails = {}) {
		const project = purchaseOrderDetails?.proj ?? this.state.project;
		const data = await this.api.get(
			'projectpurchaseordercomponents/status/grid',
			`?$filter=ponum eq '${
				purchaseOrderDetails?.ponum
					? purchaseOrderDetails.ponum
					: this.state.ponum
			}' and proj eq '${project}'`
		);
		const components = await Promise.all(
			data.map(async (po) =>
				this.api.getComponents(
					`?$filter= proj eq '${project}' and comp eq '${po.comp}' and item eq '${po.item}' `
				)
			)
		);

		this.setState({
			components: components.flat(),
			dataSize: data.length,
		});
	}

	async setInitialData(details) {
		this.setState({
			purchaseOrderId: details.purchaseOrderName,
			order: format(new Date(details.orderdt), 'MM/dd/yyyy'),
			vendor: details.vendorName,
			project: details.proj,
			ackDate: details.ackdt ? new Date(details.ackdt) : '',
			ackNo: details.ackno,
			isClosePO: details.manualclose,
			poCloseDate: details.closeddt ? new Date(details.closeddt) : '',
			ponum: details.ponum,
			notes: details.notes,
			notesVal: details.notes,
			notesValRtf: details.notesrtf,
			noteshtml: await mapRtfToDraft(details.notesrtf),
			projectName: details.proj,
		});
	}

	handleRowClick = (e) => {
		// Get the target menu.
		const indx = e.target.parentNode.getAttribute('data-key');

		document.getElementById(`item-check-${indx}`).checked =
			!document.getElementById(`item-check-${indx}`).checked;

		this.setState((prevState) => {
			prevState.checks[indx] = document.getElementById(
				`item-check-${indx}`
			).checked;

			return prevState;
		});
	};

	handleDateChange = (dateName, date) => {
		if (dateName === 'ackDate') {
			date = date ?? '';
			this.setState((prevState) => (prevState.ackDate = date));
		} else if (dateName === 'poCloseDate') {
			this.setState((prevState) => (prevState.poCloseDate = date));
		}
	};

	handleModal = (e) => {
		const dataIndx = parseInt(e.target.getAttribute('data-index'));
		this.setState({
			isShowing: true,
			selectedData: isNaN(dataIndx) ? {} : this.state.data[dataIndx],
			selectedDataIndex: dataIndx,
		});
	};

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		await this.getPurchaseOrderComponents(page);

		this.setState({
			page: page,
		});
	};

	handleEditorChange = (e) => {
		this.setState({
			notesVal: e.target.value,
			notesValRtf: this.htmlToRtfCoverter.convertHtmlToRtf(e.target.html),
		});
	};

	handleInputChange = (e) => {
		this.setState({
			[e.target.name]:
				e.target.name === 'isClosePO' ? e.target.checked : e.target.value,
		});
	};

	handleUpdate = async () => {
		const updatedData = {
			usercode: this.props.usercode,
			poId: this.props.params.poId,
			orderdt: this.state.order,
			vendorName: this.state.vendor,
			proj: this.props.params.id,
			ackdt: formatDate(this.state.ackDate),
			ackNum: this.state.ackNo,
			manualclose: this.state.isClosePO,
			closeddt: this.state.poCloseDate,
			notes: this.state.notesVal,
			notesrtf: this.state.notesValRtf,
			changeAll: false,
		};

		const self = this;
		displayAlertLoader(MSG.loading.update.msg);
		await this.api
			.updatePurchaseOrderStatus(updatedData)
			.then((response) => {
				displayAlert('success', 'Successfully updated purchase order', 1000);
				setTimeout(function () {
					self.props.navigate(
						generatePath(URI.project.viewPage, {
							id: self.props.params.id,
							page: 'vendor-orders',
						})
					);
				}, 1000);
			})
			.catch((error) => {
				displayAlert('danger', error.response.data.userError);
			});
	};

	renderHeader() {
		const projectId = this.props.params.id;
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={routeParam(URI.project.viewPage, {
							id: projectId,
							page: 'vendor-orders',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: projectId,
									page: 'items',
								}),
							}}
							linkAs={Link}
						>
							{this.state.projectName}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: routeParam(URI.project.viewPage, {
									id: projectId,
									page: 'vendor-orders',
								}),
							}}
							linkAs={Link}
						>
							Vendor Orders
						</Breadcrumb.Item>
						<Breadcrumb.Item>Modify Status</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>Modify Status</HeaderLight.Title>
					<div>
						<Button
							as={Link}
							variant="ivory"
							name="cancel"
							to={getRouteWithParam(URI.project.viewPage, {
								id: this.props.params.id,
								page: 'vendor-orders',
							})}
							className="mx-3"
						>
							Cancel
						</Button>
						<Button variant="primary" onClick={this.handleUpdate}>
							Update
						</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderListFilter() {
		return (
			<ListFilter className="po-status-filter">
				<ListFilter.Actions lg={12} className="justify-content-end">
					<ListFilter.Action className="">
						<Button
							onClick={this.handleModal}
							href="#"
							to="#"
							variant="ivory"
							size="sm"
							className="btn-icon btn-action"
						>
							Update All
						</Button>
					</ListFilter.Action>
				</ListFilter.Actions>
			</ListFilter>
		);
	}

	renderContent() {
		return (
			<>
				{/* Header */}
				{this.renderHeader()}

				{/* Content */}
				<div className="content-padding min-height">
					<Container fluid>
						<Row xs={1} lg={2} className="mb-4">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">PO#</Form.Label>
									</Col>
									<Col>
										<div className="form-group-extra">
											<Form.Control
												type="text"
												sizeClass={'lg'}
												readOnly
												disabled
												defaultValue={this.state.purchaseOrderId}
											/>
										</div>
									</Col>
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Ordered</Form.Label>
									</Col>
									<Col>
										<div className="form-group-extra">
											<Form.Control
												type="text"
												readOnly
												disabled
												defaultValue={this.state.order}
											/>
										</div>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Vendor</Form.Label>
									</Col>
									<Col>
										<div className="form-group-extra">
											<Form.Control
												type="text"
												readOnly
												disabled
												defaultValue={this.state.vendor}
											/>
										</div>
									</Col>
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Project</Form.Label>
									</Col>
									<Col>
										<div className="form-group-extra">
											<Form.Control
												type="text"
												readOnly
												disabled
												defaultValue={this.state.project}
											/>
										</div>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Ack. Date</Form.Label>
									</Col>
									<Col>
										<div className="react-select-header">
											<DatePicker
												selected={this.state.ackDate}
												onChange={(date) => {
													this.handleDateChange('ackDate', date);
												}}
												showMonthDropdown={true}
												showYearDropdown={true}
												className="form-control"
												placeholderText={'Select Ack. Date'}
											/>
										</div>
									</Col>
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Ack. No</Form.Label>
									</Col>
									<Col>
										<div className="form-group-extra">
											<Form.Control
												type="text"
												name="ackNo"
												placeholder="Please enter"
												defaultValue={this.state.ackNo}
												onChange={this.handleInputChange}
											/>
										</div>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0"></Col>
									<Col>
										<Form.Check
											label="Close PO Manually"
											name="isClosePO"
											id="close-po-radio"
											type="checkbox"
											onChange={this.handleInputChange}
											checked={this.state.isClosePO}
										/>
									</Col>
									<Col lg={2} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">PO Closed Date</Form.Label>
									</Col>
									<Col>
										<div className="form-group-extra">
											<DatePicker
												disabled={Boolean(
													Object.keys(this.props.params).length
												)}
												selected={this.state.poCloseDate}
												wrapperClassName="no-icon"
												className="form-control"
												placeholderText=""
											/>
										</div>
									</Col>
								</Row>
							</Col>
							<Col>
								<Row className="mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0 pt-3">
										<Form.Label className="mb-0">Notes</Form.Label>
									</Col>
									<Col>
										<WysiwygEditor
											id="notes"
											onChange={this.handleEditorChange}
										>
											{this.state.noteshtml || ''}
										</WysiwygEditor>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row>
							{this.renderListFilter()}
							<Col
								lg="12"
								className="mt-2 mb-5 pb-5 position-relative table-action-bar"
							>
								<div className="table-gradient">
									<Table striped responsive className="a-table">
										<thead>
											<tr key="0">
												<th className="sticky-cell">Ref No.</th>
												<th className="sticky-cell">Qty</th>
												<th className="sticky-cell">Mfg Cat</th>
												<th className="sticky-cell mw-180px">Description</th>
												<th className="sticky-cell">Expected Ship</th>
												<th>Recv’d Date</th>
												<th>Recv’d Qty.</th>
												<th>Vendor Invoice</th>
												<th>Vendor Invoice #</th>
												<th>Qty paid for</th>
												<th>B/O Qty</th>
												<th>CFA Date</th>
												<th>Install Date</th>
												<th>
													{this.state.company.wildcardstatus2 ?? 'User Date 2'}
												</th>
												<th>Status/Storage</th>
											</tr>
										</thead>
										<tbody>
											{!this.isLoaded
												? showLoading()
												: this.state.data.map((item, i) => (
														<tr key={i}>
															<td
																onClick={this.handleModal}
																data-index={i}
																className="text-decoration-underline"
																style={{
																	cursor: 'pointer',
																}}
															>
																{item.refnum}
															</td>
															<td>{item.qty === '0 ' ? '' : item.qty}</td>
															<td>{item.catalog}</td>
															<td>
																<span
																	style={{
																		display: '-webkit-box',
																		WebkitLineClamp: 2,
																		WebkitBoxOrient: 'vertical',
																		overflow: 'hidden',
																		whiteSpace: 'normal',
																		textOverflow: 'ellipsis',
																	}}
																>
																	{item.desc}
																</span>
															</td>
															<td>
																{item.expdt &&
																	dayjs(item.expdt).format('MM/DD/YYYY')}
															</td>
															<td>
																{item.recdt &&
																	dayjs(item.recdt).format('MM/DD/YYYY')}
															</td>
															<td>{item.recquan}</td>
															<td>
																{item.latestsindt &&
																	dayjs(item.latestsindt).format('MM/DD/YYYY')}
															</td>
															<td>{item.latestsinno}</td>
															<td>
																{item.totpaidqty === 0 ? '' : item.totpaidqty}
															</td>
															<td>{item.boquan === 0 ? '' : item.boquan}</td>
															<td>
																{item.cfadt &&
																	dayjs(item.cfadt).format('MM/DD/YYYY')}
															</td>
															<td>
																{item.wc1dt &&
																	dayjs(item.wc1dt).format('MM/DD/YYYY')}
															</td>
															<td>
																{item.wc2dt &&
																	dayjs(item.wc2dt).format('MM/DD/YYYY')}
															</td>
															<td>{item.statusnote}</td>
														</tr>
												  ))}
										</tbody>
									</Table>
								</div>

								<Pagination
									onPageSizeChanged={this.onPageSizeChanged}
									onPageChanged={this.onPageChanged}
									hasPreviousPage={this.state.page > 1}
									hasNextPage={this.state.dataSize > this.state.pageSize}
									page={this.state.page}
									pageSize={this.state.pageSize}
								/>
							</Col>
						</Row>
					</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleUpdate}>
						Update
					</Button>
				</FooterFormAction>
				{this.state.isShowing ? (
					<ModifyStatusDialog
						selectedDataIndex={this.state.selectedDataIndex}
						components={this.state.components}
						isShowing={this.state.isShowing}
						selectedData={this.state.selectedData}
						poId={this.props.params.poId}
						onCancel={this.resetUpdateState}
						onUpdate={this.handleModalUpdate}
						company={this.state.company}
					/>
				) : (
					<></>
				)}
			</>
		);
	}

	render() {
		return this.renderView(this.renderContent());
	}
}

export default WithRouter(ProjectVendorOrdersModifyStatus);
