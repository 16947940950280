import { TReconciliation } from 'legacy/lib/api/HttpService';
import { TCheckbookReconcileSort } from 'legacy/templates/modules/accounts-payable/checkbook/CheckbookHistory/CheckbookHistoryTypes';

export const filterAccounts = (account: string, data: TReconciliation[]) => {
	if (account === '') return data;

	return data.filter(({ cashaccount }) => cashaccount === account);
};

export const sortData = (
	field: unknown,
	direction: unknown,
	data: TReconciliation[]
): TReconciliation[] => {
	if (!field) return data;
	return [...data].sort((a, b) => {
		const aField =
			field === 'statementdate' || field === 'computerdate'
				? new Date(a[field])
				: a[field as keyof TReconciliation];
		const bField =
			field === 'statementdate' || field === 'computerdate'
				? new Date(b[field])
				: b[field as keyof TReconciliation];

		if (aField < bField) {
			return (direction as unknown as string) === 'asc' ? -1 : 1;
		}

		if (aField > bField) {
			return (direction as unknown as string) === 'asc' ? 1 : -1;
		}

		return 0;
	});
};

export const flagLatest = (data: TReconciliation[]): TReconciliation[] => {
	const sortedByDate = sortData(
		'statementdate',
		'desc' as unknown as TCheckbookReconcileSort,
		data
	);
	if (sortedByDate.length > 0) {
		sortedByDate[0].latest = true;
	}
	return sortedByDate;
};
