import React, { useState, useEffect, Fragment } from 'react';
import {
	Col,
	Row,
	Form,
	Container,
	Breadcrumb,
	Button,
	Alert,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { Link, NavLink, generatePath } from 'react-router-dom';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import UserRoleDropdown from '../../../../app/components/dropdowns/UserRoleDropdown';
import { ApiService } from '../../../../lib/api/HttpService';
import { displayAlert } from '../../../../utilities/Response';
import { WithRouter } from '../../../../helpers/Router';
import { FooterFormAction } from '../../../components/Form';
import { getCookie } from '../../../../utilities/Auth';
import { getAllInstances, getClientId } from '../../../../helpers/Instances';
import RoleTooltip from './RoleTooltip/RoleTooltip';

const UserAdd = () => {
	const defaultInstanceId = getCookie('dmInstanceId');
	const api = new ApiService();
	const [clientCompanyId, setClientCompanyId] = useState(null);
	const [allInstances, setAllInstances] = useState([]);
	const [defaultInstanceValues, setDefaultInstanceValues] = useState([]);
	const showInstanceList = defaultInstanceValues.length > 1;

	useEffect(() => {
		if (allInstances.length > 1) {
			setDefaultInstanceValues(allInstances.map((i) => i.instanceId));
		} else {
			setDefaultInstanceValues([defaultInstanceId]);
		}
	}, [allInstances, defaultInstanceId]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm({
		defaultValues: {
			selectedTemplate: 1,
			selectedInstances: defaultInstanceValues,
		},
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	useEffect(() => {
		document.title = 'Add User';
		getClientId(setClientCompanyId);
	}, []);

	useEffect(() => {
		clientCompanyId && getAllInstances(clientCompanyId, setAllInstances);
	}, [clientCompanyId]);

	const onSubmit = async (data) => {
		setIsButtonDisabled(true);

		const instanceIds = Object.entries(data.selectedInstances)
			.filter(([instanceId, isSelected]) => isSelected)
			.map(([instanceId]) => instanceId)
			.join(',');

		data.selectedInstances = showInstanceList ? instanceIds : defaultInstanceId;

		try {
			await api.postSendInvitation(data);
			displayAlert('success', 'Invite successfully sent');
			setTimeout(() => {
				window.location.href = generatePath(URI.settings.userManagement.list);
			}, 1500);
		} catch (error) {
			const errorMessage =
				error.response?.data?.userError || 'Unable to send invitation';
			displayAlert('danger', errorMessage);
			setIsButtonDisabled(false);
		}
	};

	const header = () => (
		<HeaderLight>
			<HeaderLight.Breadcumbs>
				<NavLink
					to={URI.settings.userManagement.list}
					className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
				>
					<i className="ri-arrow-left-s-line"></i> Back
				</NavLink>
				<Breadcrumb className="ms-4">
					<Breadcrumb.Item
						linkProps={{ to: URI.settings.userManagement.list }}
						linkAs={Link}
					>
						User Management
					</Breadcrumb.Item>
					<Breadcrumb.Item>Add User</Breadcrumb.Item>
				</Breadcrumb>
			</HeaderLight.Breadcumbs>
			<HeaderLight.Content>
				<HeaderLight.Title>Add User</HeaderLight.Title>
				<div>
					<Button
						variant="ivory"
						name="cancel"
						className="mx-3"
						href={URI.settings.userManagement.list}
					>
						Cancel
					</Button>
				</div>
			</HeaderLight.Content>
		</HeaderLight>
	);

	const renderContent = () => {
		return (
			<Container className="ms-0">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row xs={1} lg={2} className="mb-5">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Name</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Enter user's name"
										{...register('fullName', {
											required: true,
										})}
										data-testid="user-name-input"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Email address</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="email"
										placeholder="Enter user's email address"
										{...register('invitationEmail', {
											required: true,
										})}
										data-testid="user-email-input"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										Confirm email address
									</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="email"
										placeholder="Confirm user's email address"
										{...register('confirmInvitationEmail', {
											required: true,
										})}
										data-testid="user-email-confirm-input"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0 p-0">
									<Form.Label className="m-0">
										<span className="d-flex align-items-center justify-content-lg-end ps-1">
											<span className="text-nowrap ps-2">User’s Role</span>
											<RoleTooltip />
										</span>
									</Form.Label>
								</Col>
								<Col>
									<Controller
										name="selectedTemplate"
										control={control}
										id="select-role-dropdown"
										render={({ field }) => (
											<UserRoleDropdown
												{...field}
												onChange={(selectedOption) =>
													field.onChange(selectedOption.value)
												}
											/>
										)}
									/>
								</Col>
							</Row>

							{allInstances?.length > 1 && (
								<Fragment>
									<Row className="align-items-center mb-3">
										<Col>
											<Form.Label className="mb-0">
												Select the Design Manager copies the User may access
											</Form.Label>
										</Col>
									</Row>

									{showInstanceList &&
										allInstances?.map((instance) => {
											return (
												<Row
													key={instance.instanceId}
													className="align-items-center mb-3"
												>
													<Col lg={3} />
													<Col>
														<Controller
															name={`selectedInstances.${instance.instanceId}`}
															control={control}
															defaultValue={defaultInstanceValues.includes(
																instance.instanceId
															)}
															render={({ field }) => (
																<Form.Check
																	inline
																	label={instance.instanceName}
																	type="checkbox"
																	id={`selectedInstances-${instance.instanceId}`}
																	defaultChecked={defaultInstanceValues.includes(
																		instance.instanceId
																	)}
																	{...field}
																/>
															)}
														/>
													</Col>
												</Row>
											);
										})}
								</Fragment>
							)}
						</Col>
					</Row>
					<FooterFormAction>
						<Button
							variant="primary"
							size="lg"
							type="submit"
							disabled={isButtonDisabled}
							data-testid="user-add-submit"
						>
							Send Invitation
						</Button>
					</FooterFormAction>
				</Form>
			</Container>
		);
	};

	return (
		<>
			{header()}

			<div className="content-padding min-height has-action-bar">
				<Container fluid className="px-0">
					<Row>
						<Col>
							<Alert
								key={'form-errors'}
								variant={'danger'}
								id="validation-error-area"
								style={{ display: 'none' }}
							>
								Please fix the following errors:
								<br />
								<li>
									<span id="validationError"></span>
								</li>
							</Alert>
						</Col>
					</Row>
					{renderContent()}
				</Container>
			</div>
		</>
	);
};

export default WithRouter(UserAdd);
