import React, { forwardRef } from 'react';
import Select from 'react-select';

import { useAccounts } from './hooks';

export enum EAccountType {
	LIABILITY = 'liability',
	CHECKING = 'checking',
	ALL = 'all',
}

export type TAccountsDropdownOption = {
	label: string;
	value: string;
};

type TAccountsDropdownProps = {
	className?: string;
	isDisabled?: boolean;
	name?: string;
	topLabel?: string;
	onChange?: (selected: TAccountsDropdownOption) => void;
	onBlur?: () => void;
	value?: TAccountsDropdownOption | null;
	type?: EAccountType;
	omitAccounts?: string[];
};

// tmp type till we convert useAccounts hooks into TypeScript
type TAccountData = {
	account: string;
	accountn: string;
	banknum: null | number;
	cashcloseddt: null | number;
	checknum: string;
	closedfmo: null | string;
	dept: string;
	fixedType: number;
	nextchecknum: string;
	payroll: boolean;
	ppbankname: null | string;
	ppfileloc: null | string;
	ppincludehc: boolean;
	ppincludevoids: boolean;
	specacct: number;
	supplier: string;
	type: number;
};

const AccountsDropdown = forwardRef(
	(
		{
			topLabel = 'Select One...',
			className = '',
			isDisabled = false,
			name = '',
			onChange,
			onBlur,
			value,
			type = EAccountType.LIABILITY,
			omitAccounts,
		}: TAccountsDropdownProps,
		ref
	) => {
		const accountsQuery = useAccounts(type);

		const options =
			(accountsQuery.data as TAccountData[])
				?.filter((a) => !omitAccounts?.includes(a.account))
				?.map(({ account, accountn }: TAccountData) => ({
					label: `[${account}] ${accountn}`,
					value: account,
				})) || [];

		return (
			<Select
				placeholder={accountsQuery.isLoading ? 'Loading...' : topLabel}
				className={className}
				isDisabled={isDisabled}
				name={name}
				onChange={onChange as unknown as (event: any) => void}
				onBlur={onBlur}
				value={value}
				options={options}
				ref={ref as any}
			/>
		);
	}
);

AccountsDropdown.displayName = 'AccountsDropdown';

export default AccountsDropdown;
