import useGetPaymentProcessorInfo from 'legacy/lib/api/hooks/useGetPaymentProcessorStatus';
import React, { useState } from 'react';
import Spinner from 'legacy/app/components/help/Spinner';
import { OnboardingStatus } from 'legacy/lib/api/types/PaymentProcessorResponse';
import OnboardingStartFlow from './components/OnboardingStartFlow';
import OnboardedMessageComponent from './components/OnboardedMessageComponent';
import Tabs, { TTabOption } from './components/DesignPayTabs';
import PaymentsReport from './components/Payments';
import DepositsReport from './components/Deposits';
import {
	SECURITY_ATTRIBUTE_TYPES,
	useHasAccess,
} from 'legacy/app/context/security';

const TabComponentMap: { [key: string]: JSX.Element } = {
	Payments: <PaymentsReport />,
	Deposits: <DepositsReport />,
};

const DesignPay = () => {
	const { data, isLoading, error } = useGetPaymentProcessorInfo('Rainforest');
	const [activeTab, setActiveTab] = useState<TTabOption>('Payments');
	const hasAccess = useHasAccess(53, SECURITY_ATTRIBUTE_TYPES.DenyAccess);

	const toggleTab = (tab: TTabOption) => setActiveTab(tab);

	if (isLoading) {
		return <Spinner />;
	}

	if (error) {
		return <></>;
	}

	const decideComponent = () => {
		if (data?.onboardingStatus === OnboardingStatus.Initiated) {
			return <OnboardingStartFlow />;
		} else if (data?.onboardingStatus !== OnboardingStatus.Verified) {
			return <OnboardedMessageComponent />;
		}
		return hasAccess ? (
			TabComponentMap[activeTab]
		) : (
			<p className="tw-pl-4 tw-text-lg">
				The assigned user role restricts access to this area. Please contact
				your system admin for help. Role changes can be made under Settings -
				User Management.
			</p>
		);
	};

	return (
		<div className="tw-flex tw-flex-col tw-gap-4">
			<div className="tw-bg-[#006A53] tw-p-10">
				<h2 className="tw-text-4xl tw-text-white">DesignPay</h2>
				{data?.onboardingStatus === OnboardingStatus.Verified && (
					<Tabs toggleTab={toggleTab} activeTab={activeTab} />
				)}
			</div>
			{decideComponent()}
		</div>
	);
};

DesignPay.displayName = 'DesignPay';

export default DesignPay;
