import React from 'react';
import { Col, Row, Form, Container, Breadcrumb, Table } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import URI from '../../../../defaults/RoutesDefault';
import { Link } from 'react-router-dom';
import { ApiService } from '../../../../lib/api/HttpService';
import Header from '../../../components/Header';
import { getLocalStorage } from '../../../../utilities/LocalStorage';
import { showLoading } from '../../../../helpers/Loading';

class CheckbookJournalEntryDetail extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			dataIsLoaded: false,
			isLoading: false,
		};
		this.api = new ApiService();
	}

	async componentDidMount() {
		const checkbookData = getLocalStorage('checkbook_detail', true);
		if (Object.values(checkbookData).length) {
			const filter = `?$filter=txnum eq ${checkbookData.misctxnum}`;
			const data = await this.api.getJournalEntryCheckbookDetail(filter);

			this.setState({
				data,
				dataIsLoaded: true,
			});
		}
	}

	componentWillUnmount() {
		window.localStorage.removeItem('checkbook_detail');
		window.localStorage.removeItem('account_data');
	}

	renderHeader() {
		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{
										to: URI.accountsPayable.checkbook.list,
									}}
									linkAs={Link}
								>
									Accounts Payable
								</Breadcrumb.Item>
								<Breadcrumb.Item
									linkProps={{
										to: URI.accountsPayable.checkbook.list,
									}}
									linkAs={Link}
								>
									Checkbook
								</Breadcrumb.Item>
								<Breadcrumb.Item>Journal Entry Detail</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Journal Entry Detail</Header.Title>
					</Header.LeftContent>
				</Header.TitleContent>
			</Header>
		);
	}

	renderContent() {
		const { data } = this.state;

		return (
			<Container className="ms-0">
				<div className="table-gradient">
					<Table striped responsive className="a-table">
						<thead>
							<tr className="a-table-heading">
								<th>Account No.</th>
								<th>Account Name</th>
								<th>Debit</th>
								<th>Credit</th>
							</tr>
						</thead>
						<tbody>
							{!this.state.dataIsLoaded
								? showLoading()
								: this.state.data.map((item, i) => (
										<tr key={i} data-key={i}>
											<td>{item.account}</td>
											<td>{item.accountn}</td>
											<td>{item.amount > 0 ? item.amount : ''}</td>
											<td>{item.amount < 0 ? Math.abs(item.amount) : ''}</td>
										</tr>
								  ))}
						</tbody>
					</Table>
				</div>
			</Container>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(CheckbookJournalEntryDetail);
