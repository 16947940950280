import React from 'react';
import { Col, Row, Table, Form, Button, Modal } from 'react-bootstrap';
import { generatePath, Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import ListFilter from '../../../components/ListFilter';
import { ApiService } from '../../../../lib/api/HttpService';
import {
	formatDate,
	formatFiscalMonth,
	formatFilterDate,
	formatFilterMonth,
	formatDateTime,
} from '../../../../helpers/Date';
import { debounce, isEmpty } from 'lodash';
import { getCookie } from '../../../../utilities/Auth';
import { DatePicker, DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { isFiltered } from '../../../../helpers/Util';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import { Pagination } from '../../../../app/components/pagination/Pagination';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import ConfirmModal from '../../../../app/components/modal/ConfirmModal';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	displayErrors,
} from '../../../../utilities/Response';
import MSG from '../../../../defaults/Message';
import { plural } from '../../../../utilities/String';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import { mapRtfToDraft } from '../../../../utilities/Rtf';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import URI from '../../../../defaults/RoutesDefault';
import FilterSort from '../../../../utilities/modules/FilterSort';
import { currencyFormatNoWrap } from '../../../../helpers/Number';
import { BasicModal } from '../../../../app/components/modal/BasicModal';
import SecureLink from '../../../../app/components/security/SecureLink';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import SecureButton from '../../../../app/components/security/SecureButton';

class VendorInvoicesListExisting extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('ap_vendorinvoice_existing_list');
		this.fs.setDefaultSort('txnum desc');
		this.state = {
			checks: [],
			totalChecked: 0,
			data: [],
			dataIsLoaded: false,
			page: 1,
			pageSize: 20,
			dataSize: 0,
			sortProperty: this.fs.getSort() ?? 'txnum desc',
			showVoidModal: false,
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
			lastSelectedItem: undefined,
		};

		this.invoiceTypes = {
			'-1': 'Payroll',
			0: 'Expense / Misc',
			1: 'Proj.PO',
			2: 'Deposit(Proj.PO)',
			3: 'Deposit(Inventory PO)',
			4: 'Inventory PO',
		};

		this.api = new ApiService();
		this.userCode = getCookie('dmUsercode');
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);

		this.htmlToRtfConverter = new HtmlToRtfBrowser();
		this.payTypes = [];
	}

	componentInit() {
		this.setTitle('Existing | Vendor Invoices').setActionBar(true);
	}

	async componentDidMount() {
		this.getPayTypes();
		this.reloadData(1);
		this.enableSearchAndSortTable();
	}

	async reloadData(page) {
		this.setState({ dataIsLoaded: false });
		let data = await this.api.get(
			'vendorinvoices',
			`?${this.buildFilters(page)}`
		);

		data = !isEmpty(data) ? await this.getDeposits(data) : data;

		this.renderData(data);
	}

	async getPayTypes() {
		const generalLedgerAccounts = await this.api.getSummaryGLAccounts(
			'?$filter=specacct eq 2'
		);

		const payTypesResponse = await this.api.getPayTypes();

		const generalLedgerAccountsSelect = generalLedgerAccounts.map((account) => {
			return {
				label: `${account.value} (${account.key})`,
				value: account.key,
			};
		});

		const payTypeSelect = payTypesResponse.map((paytype, index) => {
			return {
				label: `${paytype.payType} (${paytype.defAcct})`,
				value: paytype.defAcct,
			};
		});

		this.payTypes = [...generalLedgerAccountsSelect, ...payTypeSelect];
	}

	async getDeposits(data) {
		try {
			const txnums = data.map((i) => i.txnum);
			const invoices = await this.api.get(
				'VendorInvoiceDetails',
				`?$filter=txnum in (${txnums.join(',')})`
			);

			if (!isEmpty(invoices)) {
				for (const inv of data) {
					let netAmount = 0;
					for (const vendorInv of invoices) {
						if (inv.txnum == vendorInv.txnum) {
							netAmount +=
								Number(vendorInv.mcost) +
								Number(vendorInv.fcost) +
								Number(vendorInv.lcost) +
								Number(vendorInv.icost) +
								Number(vendorInv.dcost) +
								Number(vendorInv.ocost);
						}
					}

					inv.tcost = netAmount;
					inv.projponum = inv.proj ? inv.proj + '-' + inv.ponum : '';
				}
			}
		} catch (error) {
			displayAlertError(error);
		}

		return data;
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleChecks = (entry) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === entry.txnum);
		const checked = index > -1;
		const updatedChecks = checked
			? checks.slice(0, index).concat(checks.slice(index + 1))
			: checks.concat(entry.txnum);

		this.setState({
			checks: updatedChecks,
			totalChecked: updatedChecks.length,
		});
	};

	handleSelectAllChecks = (e) => {
		let checks = [];
		// Get the target menu.
		const isChecked = e.target.checked;

		if (isChecked) {
			checks = this.state.data.map((item) => {
				return item.txnum;
			});
		}

		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});
	};

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleToggle = (e) => {
		e.preventDefault();
		// Get the target menu.
		const id = e.target.getAttribute('data-state');

		// Set the new state.
		this.setState((prevState) => {
			let data = {};
			data[id] = !prevState[id];

			return data;
		});
	};

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});
		this.fs.setIsActiveSearch(newTableSearch);

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	buildFilters(currentPage) {
		let queryString = '';
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.max) {
				if (property.type === 'number' || property.type === 'date') {
					if (property.type === 'number') {
						if (property.min) {
							filters.push(`${key} ge ${property.min}`);
						} else if (property.max) {
							filters.push(`${key} le ${property.max}`);
						} else {
							if (property.opt) {
								filters.push(`(${key} ${property.opt} '${property.value}')`);
							} else {
								filters.push(
									`(contains(cast(${key}, 'Edm.String'),'${property.value}'))`
								);
							}
						}
					}

					if (property.type === 'date') {
						if (property.min) {
							filters.push(`${key} ge ${property.min}`);
						}
						if (property.max) {
							filters.push(`${key} le ${property.max}`);
						}
					}
				} else if (property.type === 'month') {
					if (property.value) filters.push(`${key} eq ${property.value}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		let filtersQuery = '';
		if (filters.length > 0) {
			filtersQuery = `&$filter=${filters.join(' and ')}`;
		}

		queryString += `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}`;

		if (this.state.sortProperty !== '') {
			queryString +=
				(queryString == '' ? '' : '&') + `$orderby=${this.state.sortProperty}`;
		}

		return queryString + filtersQuery;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.reloadData(page);
		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		if (key === 'projponum') {
			const valueArr = value.split('-');
			this.setState(
				{
					searchProperties: {
						...this.state.searchProperties,
						proj: {
							value: valueArr[0] ?? '',
							type: type,
							opt: 'eq',
						},
						ponum: {
							value: valueArr[1] ?? '',
							type: type,
							opt: 'eq',
						},
					},
					dataIsLoaded: false,
				},
				() => {
					this.fs.setSearches(this.state.searchProperties);
					this.changePageHandler();
				}
			);
		} else {
			this.setState(
				{
					searchProperties: {
						...this.state.searchProperties,
						[key]: { value: value, type: type },
					},
					dataIsLoaded: false,
				},
				this.changePageHandler
			);
		}
	};

	handleSearchDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleSearchMonthChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				value: formatFilterMonth(e),
				type: 'month',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	handleVoidModal = () => {
		if (isEmpty(this.state.checks)) {
			displayAlertError(MSG.error.noSelected);
			return false;
		}

		this.toggleConfirmModal(true);
	};

	handleVoidConfirm = async () => {
		const { totalChecked, checks } = this.state;
		let errorIds = [],
			errorObjs = [];

		displayAlertLoader(MSG.loading.update.APVendorInvoiceVoid);
		this.toggleConfirmModal(false);

		for (let txnum of checks) {
			const selectedInvoice = this.state.data.find((invoice) => {
				return invoice.txnum === txnum;
			});

			const param = {
				fiscalmonth: formatFiscalMonth(selectedInvoice.fiscalmonth),
				ProcessType: 'voidTransaction',
				TransactionNumber: txnum,
				reportFormatId: 0,
			};

			await this.api.postVendorInvoiceFiscalMonth(param).catch((error) => {
				errorObjs.push({
					error,
					append: ` TxNum: ${txnum}`,
				});
				errorIds.push(txnum);
			});
		}

		const message = errorIds.length
			? 'The data with the ids [' + errorIds.join() + "] couldn't be updated"
			: plural(MSG.success.update.APVendorInvoiceVoid, 'invoice', totalChecked);
		const variant = errorIds.length ? 'danger' : 'success';

		if (errorIds.length) {
			displayErrors(errorObjs);
		}

		displayAlert(variant, message);

		if (errorIds.length < totalChecked) {
			this.setState({
				checks: [],
				totalChecked: 0,
			});
			await this.changePageHandler();
		}
	};

	handleDetailsModal = async () => {
		this.setState({
			details: [],
			projectPO: [],
			notesHtml: null,
		});

		const txnum = this.state.checks[0];
		if (!txnum) {
			return;
		}

		const notesRtf =
			this.state.data.find((item) => item.txnum === txnum)?.notesrtf ?? '';
		const notesHtml = await mapRtfToDraft(notesRtf);
		const type = this.state.data.find((item) => item.txnum === txnum)?.type;

		const details = await this.api.getVendorInvoiceDetails(
			`?$filter=txnum eq ${txnum}`
		);
		let state = {
			showDetailsModal: true,
			details: details,
			notesHtml: notesHtml,
		};

		if (type) {
			const projectPO = await this.api.getVendorInvoiceDetailsProjectPO(
				`?$filter=txnum eq ${txnum}`
			);
			state = {
				...state,
				projectPO: projectPO,
			};
		}

		this.setState(state);
	};

	handleNotesChange = (e) => {
		this.notesRtf = this.htmlToRtfConverter.convertHtmlToRtf(e.target.html);
	};

	handleDetailsConfirm = async () => {
		try {
			await this.api.patchVendorInvoices({
				txnum: this.state.checks[0],
				notesrtf: this.notesRtf,
			});
			this.notesRtf = '';
			this.setState({
				showDetailsModal: false,
			});
			displayAlert('success', 'Successfully saved notes');
			this.reloadData(1);
		} catch {
			displayAlert(
				'danger',
				error.response?.data?.userError || 'Unable to save notes'
			);
		}
	};

	toggleConfirmModal = (state) => {
		this.setState({
			showVoidModal: state,
		});
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	getItemLink(item) {
		let destinationLink = '';
		switch (item.type) {
			case 0:
				destinationLink = generatePath(
					URI.accountsPayable.vendorInvoice.editMisc,
					{
						txnum: item.txnum,
					}
				);
				break;
			case 1:
				destinationLink = generatePath(
					URI.accountsPayable.vendorInvoice.editInvoice,
					{
						id: item.txnum,
					}
				);
				break;
			default:
				destinationLink = generatePath(
					URI.accountsPayable.vendorInvoice.editDeposit,
					{
						id: item.txnum,
					}
				);
				break;
		}
		return (
			<SecureLink
				attributeNo={57}
				attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
				to={destinationLink}
				onClick={(e) => {
					this.setState({
						lastSelectedItem: item,
					});

					if (item.checkInfo) {
						e.preventDefault();
					}
				}}
				className="text-charcoal hover-view-icon"
			>
				{item.txnum}
			</SecureLink>
		);
	}

	getPayTypeName(item) {
		return this.payTypes.find((type) => {
			let acct = item.ccacct;
			if (!acct) {
				acct = item.paywith;
			}
			return type.value === acct || type.label === acct;
		})?.label;
	}

	renderFilters() {
		return (
			<>
				<ListFilter>
					<ListFilter.Fields
						md={12}
						lg={10}
						xl={10}
						className="width-33 no-auto"
					>
						<ListFilter.Field></ListFilter.Field>
					</ListFilter.Fields>
					<ListFilter.Actions
						md={2}
						lg={2}
						xl={2}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-md-end"
					>
						<ListFilter.Action>
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>

						<ListFilter.Action className="ms-auto ms-md-3">
							<SecureButton
								attributeNo={57}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec2}
								variant="ivory"
								className="btn-icon btn-action"
								size="sm"
								onClick={(e) => {
									e.preventDefault();
									this.handleVoidModal();
								}}
								disabled={this.state.checks.length !== 1}
							>
								<i className="ri-eye-off-line"></i> Void
							</SecureButton>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		const { totalChecked, checks, data } = this.state;
		return (
			<>
				<Row>
					<Col sm="12">
						{/* Filter */}
						{this.renderFilters()}

						<div className="table-gradient sticky-container">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading">
										<th>
											<div className="d-flex justify-content-center">
												<Form.Check
													inline
													label=""
													name={`inline-check-th-0`}
													type="checkbox"
													data-id={`th-0`}
													id={`inline-check-th-0`}
													checked={totalChecked > 0}
													className={getCheckAllClass(totalChecked, data)}
													onChange={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th className="mw-110px">
											<span
												className={this.sortClass('txnum')}
												data-field="txnum"
											>
												Tx Ref No.
											</span>
										</th>
										<th className="mw-110px">
											<span
												className={this.sortClass('type')}
												data-field="type"
											>
												Type
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('supplier')}
												data-field="supplier"
											>
												Vendor Code
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('vendorName')}
												data-field="vendorName"
											>
												Vendor Name
											</span>
										</th>
										<th className="mw-140px">
											<span
												className={this.sortClass('sinvnum')}
												data-field="sinvnum"
											>
												Invoice No.
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('sinvdt')}
												data-field="sinvdt"
											>
												Inv. Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('paydt')}
												data-field="paydt"
											>
												Pay Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('fiscalmonth')}
												data-field="fiscalmonth"
											>
												Fiscal Month
											</span>
										</th>
										<th>
											<span data-field="tcost">Net Amount</span>
										</th>
										<th className="mw-120px">
											<span data-field="projponum">Proj. PO No.</span>
										</th>
										{/* <th className="mw-120px">
                                            <span
                                                className={this.sortClass(
                                                    'inponum'
                                                )}
                                                data-field="inponum"
                                            >
                                                Inv. PO No.
                                            </span>
                                        </th> */}
										<th>
											<span
												className={this.sortClass('discount')}
												data-field="discount"
											>
												Discount Amount
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('discdays')}
												data-field="discdays"
											>
												Disc. Days
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('ccacct')}
												data-field="ccacct"
											>
												Credit Card
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('txdesc')}
												data-field="txdesc"
											>
												Trans. Description
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('generateddate')}
												data-field="generateddate"
											>
												Keyed Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('username')}
												data-field="username"
											>
												Keyed by User
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('checkInfo')}
												data-field="checkInfo"
											>
												Associated Check Info.
											</span>
										</th>
									</tr>
									<tr
										className={`a-table-search-fields ${
											this.state.showTableSearch ? '' : 'd-none'
										}`}
									>
										<th></th>
										<th>
											<Form.Control
												data-type="number"
												data-field="txnum"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('txnum')}
											/>
										</th>
										<th>
											{/*
                                            "userError": "No function signature for the function with name 'contains' matches the specified arguments. The function signatures considered are: contains(Edm.String Nullable=true, Edm.String Nullable=true).",
                                            <Form.Control
                                                data-type="text"
                                                data-field="type"
                                                onChange={this.handleSearch}
                                            /> */}
										</th>
										<th>
											<Form.Control
												data-type="text"
												data-field="supplier"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('supplier')}
											/>
										</th>
										<th>
											<Form.Control
												data-type="text"
												data-field="vendorName"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('vendorName')}
											/>
										</th>
										<th>
											<Form.Control
												data-type="text"
												data-field="sinvnum"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('sinvnum')}
											/>
										</th>
										<th>
											<DateRangePicker
												style={{
													minWidth: '200px',
												}}
												placement="auto"
												placeholder="Select date"
												format="MM/dd/yyyy"
												defaultValue={this.fs.getValue('sinvdt')}
												onChange={this.handleSearchDateChange.bind(
													this,
													'sinvdt'
												)}
												onClean={this.handleSearchDateChange.bind(
													this,
													'sinvdt'
												)}
												ranges={[
													{
														label: 'today',
														value: [
															startOfDay(new Date()),
															endOfDay(new Date()),
														],
													},
													{
														label: 'yesterday',
														value: [
															startOfDay(addDays(new Date(), -1)),
															endOfDay(addDays(new Date(), -1)),
														],
													},
													{
														label: 'last7Days',
														value: [
															startOfDay(subDays(new Date(), 6)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'Last 30 Days',
														value: [
															startOfDay(subDays(new Date(), 30)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'This month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()),
																	1
																)
															),
															endOfDay(lastDayOfMonth(new Date())),
														],
													},
													{
														label: 'Last month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															),
															endOfDay(
																lastDayOfMonth(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																)
															),
														],
													},
												]}
											/>
										</th>
										<th>
											<DateRangePicker
												style={{
													minWidth: '200px',
												}}
												placement="auto"
												placeholder="Select date"
												format="MM/dd/yyyy"
												defaultValue={this.fs.getValue('paydt')}
												onChange={this.handleSearchDateChange.bind(
													this,
													'paydt'
												)}
												onClean={this.handleSearchDateChange.bind(
													this,
													'paydt'
												)}
												ranges={[
													{
														label: 'today',
														value: [
															startOfDay(new Date()),
															endOfDay(new Date()),
														],
													},
													{
														label: 'yesterday',
														value: [
															startOfDay(addDays(new Date(), -1)),
															endOfDay(addDays(new Date(), -1)),
														],
													},
													{
														label: 'last7Days',
														value: [
															startOfDay(subDays(new Date(), 6)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'Last 30 Days',
														value: [
															startOfDay(subDays(new Date(), 30)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'This month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()),
																	1
																)
															),
															endOfDay(lastDayOfMonth(new Date())),
														],
													},
													{
														label: 'Last month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															),
															endOfDay(
																lastDayOfMonth(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																)
															),
														],
													},
												]}
											/>
										</th>
										<th>
											<DatePicker
												style={{
													minWidth: '100px',
												}}
												placeholder="Select month"
												format="MM/yyyy"
												defaultValue={this.fs.getValue('fiscalmonth')}
												onChange={this.handleSearchMonthChange.bind(
													this,
													'fiscalmonth'
												)}
												onClean={this.handleSearchMonthChange.bind(
													this,
													'fiscalmonth'
												)}
											/>
										</th>
										<th>
											{/* <Form.Control
                                                data-type="text"
                                                data-field="tcost"
                                                onChange={this.handleSearch}
                                            /> */}
										</th>
										<th>
											<Form.Control
												data-type="number"
												data-field="projponum"
												onChange={this.handleSearch}
											/>
										</th>
										{/* <th> */}
										{/*
                                            "userError": "Could not find a property named 'inponum' on type 'DesignManager.API.Db.DMProDb.Models.Views.GetVendorInvoices'."
                                            <Form.Control
                                                data-type="text"
                                                data-field="inponum"
                                                onChange={this.handleSearch}
                                            /> */}
										{/* </th> */}
										<th>
											<Form.Control
												data-type="number"
												data-field="discount"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('discount')}
											/>
										</th>
										<th>
											<Form.Control
												data-type="number"
												data-field="discdays"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('discdays')}
											/>
										</th>
										<th>
											<Form.Control
												data-type="text"
												data-field="ccacct"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('ccacct')}
											/>
										</th>
										<th>
											<Form.Control
												data-type="text"
												data-field="txdesc"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('txdesc')}
											/>
										</th>
										<th></th>
										<th></th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{!this.state.dataIsLoaded ? (
										showLoading()
									) : data.length === 0 &&
									  isFiltered(this.state.searchProperties) ? (
										showEmpty()
									) : data.length > 0 ? (
										data.map((item, i) => (
											<tr
												key={i}
												data-key={i}
												className={checks.includes(item.txnum) ? `active` : ''}
											>
												<td>
													<div className="d-flex justify-content-center">
														<Form.Check
															label=""
															name={`group-` + i}
															type="checkbox"
															data-id={item.item + '_' + item.id}
															id={`chk-projectview-items-` + i}
															className="chk-projectview-items-item"
															checked={checks.includes(item.txnum)}
															onClick={this.handleChecks.bind(this, item)}
														/>
													</div>
												</td>
												<td>{this.getItemLink(item)}</td>
												<td>{this.invoiceTypes[item.type]}</td>
												<td>{item.supplier}</td>
												<td>{item.vendorName}</td>
												<td>{item.sinvnum}</td>
												<td>{formatDate(item.sinvdt)}</td>
												<td>{formatDate(item.paydt)}</td>
												<td>{formatFiscalMonth(item.fiscalmonth)}</td>
												<td>{currencyFormatNoWrap(item.netAmount, '$')}</td>
												<td>{item.projponum}</td>
												{/* <td>{item.inponum || ''}</td> */}
												<td>{currencyFormatNoWrap(item.discount, '$')}</td>
												<td>{item.discdays || ''}</td>
												<td>{this.getPayTypeName(item)}</td>
												<td>{item.txdesc}</td>
												<td>{formatDateTime(item.generateddate)}</td>
												<td>{item.username}</td>
												<td>{item.checkInfo}</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan={17}>
												<strong>No existing vendor invoices available</strong>
											</td>
										</tr>
									)}
								</tbody>
							</Table>
						</div>
						{this.state.data.length > 0 && (
							<Pagination
								onPageSizeChanged={this.onPageSizeChanged}
								onPageChanged={this.onPageChanged}
								hasPreviousPage={this.state.page > 1}
								hasNextPage={this.state.data.length >= this.state.pageSize}
								page={this.state.page}
								pageSize={this.state.pageSize}
							/>
						)}
					</Col>
				</Row>

				<ConfirmModal
					show={this.state.showVoidModal}
					title="Void Vendor Invoices"
					message="Are you sure you want to void the selected item(s)?"
					confirmAction={this.handleVoidConfirm.bind(this)}
					toggleModal={this.toggleConfirmModal.bind(this, false)}
				/>

				<Modal
					size="xl"
					show={this.state.showDetailsModal}
					aria-labelledby="action-modal"
					className="a-modal mx-sm-5 mx-lg-0"
					backdrop="static"
					centered
				>
					<Modal.Body>
						<div className="bg-secondary-grey">
							<div className="d-flex p-4 flex-row justify-content-between align-items-center">
								<h6 className="fw-bold mb-0">
									{`Check / Vendor Invoice Detail [TX #${
										this.state.checks[0] ?? ''
									}]`}
								</h6>
							</div>
						</div>
						<div className="table-gradient">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading distribution">
										<th>
											<span>Account No.</span>
										</th>
										<th>
											<span>Account Name</span>
										</th>
										<th>
											<span>Debit</span>
										</th>
										<th>
											<span>Credit</span>
										</th>
									</tr>
								</thead>
								<tbody>
									{(this.state.details ?? []).map((item, i) => (
										<tr key={i} data-key={i}>
											<td>{item.account}</td>
											<td>{item.accountName}</td>
											<td>{item.amount >= 0 ? item.amount : ''}</td>
											<td>{item.amount < 0 ? Math.abs(item.amount) : ''}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
						<div className={'mb-3'}>
							* Amounts shown are for entire invoice and do not reflect partial
							payments
						</div>
						{this.state.projectPO?.length > 0 && (
							<div className="table-gradient">
								<Table striped responsive className="a-table">
									<thead>
										<tr className="a-table-heading distribution">
											<th>
												<span>Ref. No.</span>
											</th>
											<th>
												<span>Type</span>
											</th>
											<th>
												<span>Description</span>
											</th>
											<th>
												<span>PO Qty.</span>
											</th>
											<th>
												<span>PO (Est.) Cost</span>
											</th>
											<th>
												<span>Cost To Date</span>
											</th>
											<th>
												<span>Qty. On Inv.</span>
											</th>
											<th>
												<span>Cost On Inv.</span>
											</th>
										</tr>
									</thead>
									<tbody>
										{this.state.projectPO.map((item) => (
											<tr>
												<td>{item.refNum}</td>
												<td>{item.componentTypeDesc}</td>
												<td>{item.description}</td>
												<td>{item.poQuantity}</td>
												<td>{item.poCost}</td>
												<td>{item.costToDate}</td>
												<td>{item.quanpaidfor}</td>
												<td>{item.totalCost}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						)}

						<Form.Label className="my-2">Notes</Form.Label>
						<WysiwygEditor onChange={this.handleNotesChange}>
							{this.state.notesHtml}
						</WysiwygEditor>
					</Modal.Body>
					<Modal.Footer className="bg-ivory py-4">
						<Row>
							<Col className="align-items-end">
								<Button variant="primary" onClick={this.handleDetailsConfirm}>
									OK
								</Button>
							</Col>
							<Col className="align-items-end">
								<Button
									variant="trans-light border-secondary-ash"
									onClick={() => {
										this.notesRtf = '';
										this.setState({
											showDetailsModal: false,
										});
									}}
								>
									Cancel
								</Button>
							</Col>
						</Row>
					</Modal.Footer>
				</Modal>
				{this.state.lastSelectedItem?.checkInfo && (
					<BasicModal
						message={`This invoice cannot be edited because it has a payment associated with it: ${this.state.lastSelectedItem?.checkInfo}`}
						onPrimary={() => {
							this.setState({
								lastSelectedItem: undefined,
							});
						}}
						hasSecondary={false}
					/>
				)}
			</>
		);
	}
}

export default WithRouter(VendorInvoicesListExisting);
