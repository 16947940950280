import React from 'react';
import { Outlet } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import ThreadComponent from '../../ThreadComponent';

class JournalEntry extends ThreadComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.paths.pathname === '/general-ledger/journal-entries') {
			this.props.navigate(URI.generalLedger.journalEntry.add);
		}
	}

	render() {
		return (
			<>
				<Outlet />
			</>
		);
	}
}

export default WithRouter(JournalEntry);
