import {
	UseQueryOptions,
	UseQueryResult,
	useQuery,
} from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';
import { Component } from '../types/Component';
import { CompTypes } from '../types/CompTypes';
import { getTimeEntries } from 'api/resources/timeEntries';

const api = new ApiService();

export type TComponentWithTxNum = Component & {
	txnum?: number | null;
};

const getComponents = async (
	id: string,
	projectId: string
): Promise<Component[]> => {
	const components = await api.getComponents(
		`?$filter=itemid eq ${id} AND projectid eq ${projectId}`
	);
	const componentsWithTimeEntryInformation: TComponentWithTxNum[] =
		await Promise.all(
			components.map(async (comp) => {
				if (comp.comptype !== CompTypes.ctTime) return comp;
				const timeEntry = await getTimeEntries(
					`?$filter=comp eq '${comp.comp}' AND item eq '${comp.item}' AND proj eq '${comp.proj}'`
				);

				const timeEntryId = timeEntry?.[0]?.txnum;
				return { ...comp, txnum: timeEntryId || null };
			})
		);

	return componentsWithTimeEntryInformation;
};

const useGetComponents = (
	id: string,
	projectId: string,
	options?: UseQueryOptions<Component[], unknown, Component[], string[]>
): UseQueryResult<TComponentWithTxNum[]> => {
	const componentsQuery = useQuery({
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryKey: [id, projectId, 'components'],
		queryFn: () => getComponents(id, projectId),
		enabled: !!id && !!projectId,
		...{ ...(options || {}) },
	});
	useEffect(() => {
		if (componentsQuery.error) {
			displayAlertError(
				'An error occurred fetching the components, please try again'
			);
		}
	}, [componentsQuery.error]);

	return componentsQuery;
};
export default useGetComponents;
