import { makeAutoObservable } from 'mobx';
import TimeEntryItemViewModel from './TimeEntryItemViewModel';
import {
	ITimeEntryResponse,
	TimeEntryService,
	IFilter,
} from '../service/TimeEntryService';

export default class TimeEntryListViewModel {
	private timeEntryService: TimeEntryService;
	private _isLoaded = false;
	private _timeEntryList: ITimeEntryResponse[] = [];
	private _filters: IFilter[] = [];
	private _billable = 0;
	private _nonBillable = 0;
	private _total = 0;

	get timeEntryList(): ITimeEntryResponse[] {
		return this._timeEntryList;
	}

	get isLoaded(): boolean {
		return this._isLoaded;
	}

	get filters(): IFilter[] {
		return this._filters;
	}

	get billable(): number {
		return this._billable;
	}

	private set billable(value: number) {
		this._billable = value;
	}

	get nonBillable(): number {
		return this._nonBillable;
	}

	private set nonBillable(value: number) {
		this._nonBillable = value;
	}

	get total(): number {
		return this._total;
	}

	private set total(value: number) {
		this._total = value;
	}

	constructor(timeEntryService: TimeEntryService) {
		this.timeEntryService = timeEntryService;

		makeAutoObservable(this);
	}

	setTimeEntryList(timeEntryList: any): TimeEntryListViewModel {
		this._timeEntryList = timeEntryList;
		return this;
	}

	setFilters(filters: any): TimeEntryListViewModel {
		this._filters = filters;
		return this;
	}

	setIsLoaded(isLoaded: boolean): TimeEntryItemViewModel {
		this._isLoaded = isLoaded;
		return this;
	}

	getTimeEntryItems(response: any) {
		this.timeEntryService.getItems('').then((resp) => {
			if (resp) {
				for (const t of response) {
					const obj = resp.find((o) => o.item === t.item);
					t.itemData = obj ? obj : {};
				}

				response = this.setTimeEntryList(response);
				this.setIsLoaded(true);
			}
		});
	}

	fetchTimeEntries(
		page = 1,
		query = '',
		projectCode = '',
		employeeCode = '',
		startDate: '',
		endDate: ''
	): void {
		this.setIsLoaded(false);
		this.timeEntryService
			.getTimeEntries(query)
			.then((response) => {
				if (response) {
					this.timeEntryService
						.getBillingTotals({
							projectCode: projectCode,
							employeeCode: employeeCode,
							endDate: startDate,
							startDate: endDate,
						})
						.then((res) => {
							this.billable = res.billableHours;
							this.nonBillable = res.nonBillableHours;
							this.total = res.totalHours;
						})
						.catch((e) => {
							this.setIsLoaded(true);
						});

					this.setTimeEntryList(response);
					this.setIsLoaded(true);
				}
			})
			.catch((e) => {
				this.setIsLoaded(true);
				console.log(e);
			});
	}
}
