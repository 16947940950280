import React from 'react';
import MultiSearchComponents, {
	MultiSearchComponentsProps,
	MultiSearchComponentsState,
} from './MultiSearchComponents';
import { DateRangePicker } from 'rsuite';

export default class MultiSearchComponentsDateRange extends MultiSearchComponents {
	constructor(props: MultiSearchComponentsProps) {
		super(props);
		this.state = {
			props,
			dateValues: props.defaultValue ?? null,
			cleared: false,
		};
	}

	componentDidUpdate(
		prevProps: Readonly<MultiSearchComponentsProps>,
		prevState: Readonly<MultiSearchComponentsState>
	): void {
		const { isClear } = this.props;
		const { dateValues } = this.state;

		if (prevProps !== this.props) {
			this.setState({
				props: this.props,
				cleared: isClear,
				dateValues: isClear ? '' : dateValues,
			});
		}
	}

	render() {
		const { props, dateValues } = this.state;

		const dates: any = dateValues?.length ? [] : undefined;
		if (dateValues?.length) {
			dates[0] = new Date(dateValues[0]);
			dates[1] = new Date(dateValues[1]);
		}

		return (
			<div
				className={`mb-2 position-relative me-2 bg-white rounded-1 border border-sand ${
					this.isFixed ? '' : 'pe-2'
				}`}
				style={{
					...props.style,
					zIndex: 700 - (props?.zIndex ?? 0),
				}}
			>
				<div className="d-flex align-items-center justify-content-between">
					<div className="daterange-labeled-group position-relative">
						{this.renderLabel('dateValues')}
						<DateRangePicker
							key={props?.zIndex ?? 1}
							className="no-border"
							placeholder={props.label}
							format="MM/dd/yyyy"
							ranges={[]}
							value={dates}
							onChange={(dates) => {
								this.setState({
									dateValues: dates,
								});
								props.handleChangeField(dates, props);
							}}
						/>
					</div>
					{!this.isFixed && (
						<a
							className="ff-close-dropdown"
							onClick={() => {
								props.handleDetach(props.id);
							}}
						>
							<i className="ri-close-fill"></i>
						</a>
					)}
				</div>
			</div>
		);
	}
}
