import React, { FC, useEffect, useState } from 'react';
import { Pagination } from 'legacy/app/components/pagination/Pagination';
import { TViewType } from './StockItemsList';
import { StockItemsListTable } from './StockItemsListTable';
import { StockItemsListGrid } from './StockItemsListGrid';
import { TStockItem } from 'legacy/lib/api/types/StockItem';

export const StockItemsListContent: FC<{
	viewType: TViewType;
	data: TStockItem[];
	thumbnails: Map<number, any>;
	sort: { key: string; direction: string };
	gridColumns: number;
	selectedIds: number[];
	isSearching: boolean;
	isLoading: boolean;
	onPaginationChange: (pagination: { size: number; page: number }) => void;
	onSearch: (key: string, value: string, type?: string) => void;
	onSort: (sort: { key: string; direction: string }) => void;
	onDelete: (item: TStockItem) => void;
	onDuplicate: (item: TStockItem) => void;
	onCheck: (ids: number[]) => void;
}> = ({
	viewType,
	data,
	thumbnails,
	sort,
	gridColumns,
	selectedIds,
	isSearching,
	isLoading,
	onPaginationChange,
	onSearch,
	onSort,
	onDelete,
	onDuplicate,
	onCheck,
}) => {
	const [checks, setChecks] = useState<number[]>(selectedIds);
	const [pagination, setPagination] = useState({ size: 20, page: 1 });

	useEffect(() => {
		setChecks(selectedIds);
	}, [selectedIds]);

	const handlePageSizeChange = (size: number) => {
		if (isLoading) {
			return;
		}

		const newPagination = { size: size, page: 1 };
		onPaginationChange(newPagination);
		setPagination(newPagination);
	};
	const handlePageChange = (page: number) => {
		if (isLoading) {
			return;
		}

		const newPagination = { ...pagination, page: page };
		onPaginationChange(newPagination);
		setPagination(newPagination);
	};
	const handleCheckAll = () => {
		if (!checks.length) {
			const checked = data.slice(0, pagination.size).map((item) => {
				return item.id;
			});
			onCheck(checked);
			setChecks(checked);
		} else {
			onCheck([]);
			setChecks([]);
		}
	};
	const handleCheck = (id: number) => {
		const _checks = [...checks];
		const index = _checks.findIndex((check) => check == id);

		index > -1 ? _checks.splice(index, 1) : _checks.push(id);

		onCheck(_checks);
		setChecks(_checks);
	};

	return (
		<div>
			{viewType === 'table' ? (
				<StockItemsListTable
					data={data.slice(0, pagination.size)}
					thumbnails={thumbnails}
					sort={sort}
					selectedIds={checks}
					isSearching={isSearching}
					isLoading={isLoading}
					onCheck={handleCheck}
					onCheckAll={handleCheckAll}
					onSearch={onSearch}
					onSort={onSort}
				/>
			) : (
				<StockItemsListGrid
					data={data.slice(0, pagination.size)}
					thumbnails={thumbnails}
					selectedIds={checks}
					columns={gridColumns}
					onDelete={onDelete}
					onDuplicate={onDuplicate}
					onCheck={handleCheck}
				/>
			)}
			{data.length > 0 && (
				<Pagination
					onPageSizeChanged={handlePageSizeChange}
					onPageChanged={handlePageChange}
					hasPreviousPage={pagination.page > 1}
					hasNextPage={data.length > pagination.size}
					page={pagination.page}
					pageSize={pagination.size}
				/>
			)}
		</div>
	);
};
StockItemsListContent.displayName = 'StockItemsListContent';
