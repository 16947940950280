import React, { useState, useEffect, useRef, useCallback } from 'react';
import Select from 'react-select';
import AsyncDropdownLoadingIndicator from '../dropdowns/utils/AsyncDropdownLoadingIndicator';

const PIXEL_THRESHOLD = 10;

interface IOption<T> {
	value: string;
	label: string;
	data: T;
}

interface IBaseSelect<T> {
	handleSelect: (item: T | null) => void;
	placeholder?: string;
	results: T[];
	updateFilter: (filter: string) => void;
	loadMore: (page: number) => void;
	handleInputFilter: (input: string) => string;
	handleSelectedOption: (option: IOption<T> | null) => T | null;
	optionFormatter: (item: T) => IOption<T>;
	hasMore: boolean;
	isFetching: boolean;
	firstOptionAsDefault?: boolean;
	defaultSelected?: T | null;
}

const BaseSelect = <T,>({
	handleSelect,
	placeholder = 'Select an option',
	results,
	updateFilter,
	loadMore,
	handleInputFilter,
	handleSelectedOption,
	optionFormatter,
	hasMore,
	isFetching,
	firstOptionAsDefault = false,
	defaultSelected = null,
}: IBaseSelect<T>) => {
	const [selectedOption, setSelectedOption] = useState<IOption<T> | null>(null);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [allOptions, setAllOptions] = useState<IOption<T>[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const currentFilter = useRef('');
	const selectRef = useRef<HTMLDivElement | null>(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		if (defaultSelected && !selectedOption) {
			const formattedDefaultOption = optionFormatter(defaultSelected);
			setSelectedOption(formattedDefaultOption);
			handleSelect(defaultSelected);
			setIsDisabled(false);
		}
	}, [defaultSelected, optionFormatter, handleSelect, selectedOption]);

	useEffect(() => {
		if (results?.length > 0) {
			const formattedResults = results.map(optionFormatter);

			setAllOptions((prevOptions) => {
				const existingValues = new Set(prevOptions.map((opt) => opt.value));
				const newUniqueOptions = formattedResults.filter(
					(opt) => !existingValues.has(opt.value)
				);
				return [...prevOptions, ...newUniqueOptions];
			});

			if (!selectedOption && results.length > 0 && firstOptionAsDefault) {
				setIsDisabled(false);
				const firstItem = results[0];
				handleSelect(firstItem);
				setSelectedOption(optionFormatter(firstItem));
			} else {
				setIsDisabled(false);
			}
		}
	}, [
		results,
		selectedOption,
		handleSelect,
		optionFormatter,
		firstOptionAsDefault,
	]);

	const handleInputChange = (inputValue: string) => {
		const newFilter = inputValue === '' ? '' : handleInputFilter(inputValue);
		if (newFilter !== currentFilter.current) {
			currentFilter.current = newFilter;
			setAllOptions([]);
			setCurrentPage(1);
			updateFilter(newFilter);
		}
	};

	const handleOptionSelect = (option: IOption<T> | null) => {
		const selectedItem = handleSelectedOption(option);
		if (selectedItem) {
			handleSelect(selectedItem);
			setSelectedOption(optionFormatter(selectedItem));
		}
	};

	const handleMenuScrollToBottom = useCallback(() => {
		if (hasMore && !isFetching) {
			const nextPage = currentPage + 1;
			loadMore(nextPage);
			setCurrentPage(nextPage);
		}
	}, [hasMore, isFetching, currentPage, loadMore]);

	useEffect(() => {
		const menu = selectRef?.current?.querySelector('.base-select__menu-list');
		if (!menu || !isMenuOpen) return;
		const handleScroll = () => {
			if (
				menu.scrollTop + menu.clientHeight >=
				menu.scrollHeight - PIXEL_THRESHOLD
			) {
				handleMenuScrollToBottom();
			}
		};

		menu.addEventListener('scroll', handleScroll);

		return () => {
			menu.removeEventListener('scroll', handleScroll);
		};
	}, [handleMenuScrollToBottom, isMenuOpen]);

	return (
		<div ref={selectRef}>
			<Select
				options={allOptions}
				blurInputOnSelect
				onInputChange={(value) => handleInputChange(value)}
				placeholder={placeholder}
				onChange={(option) => handleOptionSelect(option as IOption<T> | null)}
				value={selectedOption}
				isDisabled={isDisabled}
				onMenuScrollToBottom={handleMenuScrollToBottom}
				isLoading={isFetching}
				components={{ LoadingIndicator: AsyncDropdownLoadingIndicator }}
				className="tw-w-full"
				classNamePrefix="base-select"
				onMenuOpen={() => setIsMenuOpen(true)}
				onMenuClose={() => setIsMenuOpen(false)}
			/>
		</div>
	);
};

BaseSelect.displayName = 'BaseSelect';

export default BaseSelect;
