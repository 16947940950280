import { getDeactivateMessage, getDeleteMessage } from './DeleteMessage';
import { displayAlert, hideAlertLoader } from './Response';

interface Params {
	moduleSingular: string;
	modulePlural: string;
	reference: string;
	objectType: string;
	from?: string;
	customMessage?: string;
	canDeactivate?: boolean;
	canComplete?: boolean;
}

function checkIfActionIsAvailable(objActions: string[]) {
	let isAction = false;
	for (const action of objActions) {
		if (action !== 'none') {
			isAction = true;
		}
	}

	return isAction;
}

export default function HandleDeleteWorker(
	workers: any,
	params: Params,
	deactivateOnly = false,
	callback: any
) {
	Promise.all(workers).then(async (responses: any) => {
		if (responses) {
			let canDeleteAll = true;
			let canProceed = true;
			let customMsg = null;
			const objIds: any = [];
			const objActions: any = [];
			const canDeactivate = params.canDeactivate ?? true;
			const canComplete = params.canComplete ?? true;

			for (const res of responses) {
				if (!res.fullDeletePossible) {
					canDeleteAll = false;

					if (res.deactivatePossible && canDeactivate) {
						objActions.push('deactivate');
					} else {
						if (res.makeCompletePossible && canComplete) {
							objActions.push('complete');
						} else if (res.result === 'dmriCannotDelLastComp') {
							canProceed = false;
							customMsg = res.message;
							objActions.push('none');
						} else {
							objActions.push('none');
						}
					}
				} else {
					objActions.push('delete');
					deactivateOnly = false;
				}
				objIds.push(res.objectCodeOrId);
			}

			// disabled the custom message from server for now for unity of messages

			const message: string = makeMessage(
				deactivateOnly,
				canDeleteAll,
				checkIfActionIsAvailable(objActions)
			);

			const useServerMessage =
				responses.length === 1 &&
				responses[0]?.message &&
				responses[0]?.result === 'dmriGLActivity';

			if (callback && canProceed) {
				callback({
					deleteResponses: {
						objIds: objIds,
						objActions: objActions,
						message: useServerMessage ? responses[0].message : message,
						objectType: params.objectType,
						deleteType: 'dmriTestOnly',
						params: params,
					},
					showModal: true,
				});
				hideAlertLoader();
			} else {
				displayAlert('danger', customMsg);
			}
		}
	});

	function makeMessage(
		deactivateOnly: boolean,
		canDeleteAll: boolean,
		canDeactivate: boolean
	): string {
		if (deactivateOnly) {
			return getDeactivateMessage(workers.length, params.from || '');
		} else {
			return getDeleteMessage(
				canDeleteAll,
				[params.moduleSingular, params.modulePlural],
				params.customMessage,
				workers.length,
				params.reference,
				canDeactivate,
				params.from || ''
			);
		}
	}
}
