import { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import { formatDate, formatDateTime } from 'legacy/helpers/Date';
import { currencyFormat } from 'legacy/helpers/Number';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { useEffect, useMemo, useState } from 'react';
import Table from 'legacy/app/components/newTable/Table';
import { useParams } from 'react-router-dom';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';
import { Button } from 'react-bootstrap';
import { useGetVendorInvoices } from 'api/hooks/useGetVendorInvoices';
import { IVendorInvoice } from 'api/resources/vendorInvoices';
import classNames from 'classnames';

const VendorInvoicesSection = ({
	itemNumber,
	comp,
}: {
	itemNumber: string;
	comp: string;
}) => {
	const { id: projectId } = useParams();

	const {
		data: project,
		isLoading: isFetchingProjects,
		error: fetchingProjectError,
	} = useGetProject(projectId as string);

	const filter = `?$filter=projectCode eq '${encodeURIComponent(
		project?.proj as string
	)}' AND itemNumber eq '${itemNumber}' AND componentNumber eq '${comp}'`;

	const {
		data: vendorInvoices,
		isLoading: isFetchingVendorInvoices,
		error,
	} = useGetVendorInvoices(filter, { enabled: !!project });

	const [enableFilters, setEnableFilters] = useState(false);

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	useEffect(() => {
		if (error || fetchingProjectError) {
			displayAlertError(
				'There was an error fetching the Vendor Invoices, please try again'
			);
		}
	}, [error, fetchingProjectError]);

	const columns = useMemo<ColumnDef<IVendorInvoice>[]>(
		() => [
			{
				accessorKey: 'transactionNumber',
				header: 'TX No.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'vendor',
				header: 'Vendor',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'invoiceNumber',
				header: 'Invoice No.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDateTime(col.invoiceDate),
				header: 'Invoice Date',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'poNumber',
				header: 'PO/WO No.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'paidForQuantity',
				header: 'Qty',
				filterFn: 'equalsString',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.cost),
				header: 'Cost',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'checkNumber',
				header: 'Check No.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDate(col.checkDate),
				header: 'Check Date',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
		],
		[]
	);

	if (isFetchingProjects || isFetchingVendorInvoices) {
		return <Spinner isChild />;
	}

	const columnFilterState = {
		columnFilters,
	};

	return vendorInvoices ? (
		<>
			<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
				<p className="tw-text-base tw-font-bold tw-m-0">Vendor Invoices</p>
				<Button
					className={classNames(
						'!tw-bg-transparent !tw-border-[#dedcd7] !tw-border-[1px] !tw-text-[#211f21] !tw-text-sm !tw-font-bold',
						enableFilters && '!tw-bg-black !tw-text-white'
					)}
					size="sm"
					onClick={() => {
						const value = !enableFilters;
						setEnableFilters(value);
						if (value === false) {
							setColumnFilters([]);
						}
					}}
				>
					<i className="ri-search-line"></i> Search
				</Button>
			</div>
			<div className="tw-pt-6 tw-overflow-x-auto">
				<Table
					state={columnFilterState}
					onColumnFiltersChange={setColumnFilters}
					data={vendorInvoices}
					columns={columns}
					enableFilters={enableFilters}
				/>
			</div>
		</>
	) : null;
};

VendorInvoicesSection.displayName = 'VendorInvoicesSection';
export default VendorInvoicesSection;
