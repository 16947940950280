import React from 'react';
import { Col, Row, Table, Form, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import URI from '../../../../defaults/RoutesDefault';
import { routeParam, WithRouter } from '../../../../helpers/Router';
import ListFilter from '../../../components/ListFilter';
import ThreadComponent from '../../ThreadComponent';
import { ActionBarVertical } from '../../../components/ActionBar';
import { ApiService } from '../../../../lib/api/HttpService';
import { generatePath } from 'react-router';
import tableEvent from '../../../../utilities/Table';
import {
	formatDate,
	formatDateTime,
	formatFilterDate,
} from '../../../../helpers/Date';
import { currencyFormat } from '../../../../helpers/Number';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';

class ItemAddComponentAddOrderStatus extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			dataIsLoaded: false,
			loading: false,
			error: null,
			pos: [],
			vinv: [],
			activeId: 0,
			showTableSearch: false,
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		try {
			this.setState({
				loading: true,
			});
			const company = await this.api.getCompany();
			this.setState({
				userDate1Title: company.wildcardstatus1,
				userDate2Title: company.wildcardstatus2,
			});

			if (Object.keys(this.props.data).length > 0) {
				let data = this.props.data || {};

				this.setState({
					data: data,
					dataIsLoaded: true,
					pos: await this.getPOS(data),
					vinv: await this.getVendorInvoices(data),
				});
			}
			this.setState({
				loading: false,
			});
			setTimeout(() => {
				tableEvent((tr) => {
					this.setState({
						activeId: tr.dataset.id,
					});
				});
			}, 1200);
		} catch {
			displayAlertError(
				'There was an error getting all the information needed, please try again'
			);
			this.setState({
				loading: false,
				error: true,
			});
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data || {};

			this.setState({
				data: data,
				dataIsLoaded: true,
				pos: await this.getPOS(data),
				vinv: await this.getVendorInvoices(data),
			});

			setTimeout(() => {
				tableEvent((tr) => {
					this.setState({
						activeId: tr.dataset.id,
					});
				});
			}, 1200);
		}
	}

	async getPOS(data) {
		return await this.api.get(
			'components/purchaseorders',
			`?$filter=projectCode eq '` +
				data.project.proj +
				`' and itemNumber eq '` +
				data.component[0].item +
				`' and componentNumber eq '` +
				data.component[0].comp +
				`'`
		);
	}

	async getVendorInvoices(data) {
		return await this.api.get(
			'components/vendorinvoices',
			`?$filter=projectCode eq '` +
				data.project.proj +
				`' and itemNumber eq '` +
				data.component[0].item +
				`' and componentNumber eq '` +
				data.component[0].comp +
				`'`
		);
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
				if (e.target.attributes['type'].value === 'date') {
					value = formatFilterDate(e.target.value);
				}
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}
		this.props.onDataChange({ key: key, value: value });
	};

	makeDefaultDate(updatedDate, existingDate) {
		const date = updatedDate || existingDate;

		if (!date) {
			return null;
		}

		return new Date(date).toISOString().split('T')[0];
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			const data = {};
			data.showTableSearch = !prev.showTableSearch;
			return data;
		});
	};

	renderFilter() {
		return (
			<>
				<ListFilter className="justify-content-end">
					<ListFilter.Actions lg={3} xl={2}>
						<ListFilter.Action className="ms-auto"></ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderContent1() {
		return (
			<Table striped responsive className="a-table">
				<thead>
					<tr className="a-table-heading">
						<th>
							<span data-field="ponum">PO/WO NO.</span>
						</th>
						<th>
							<span data-field="quantity">Qty.</span>
						</th>
						<th>
							<span data-field="orderDate">Order</span>
						</th>
						<th>
							<span data-field="vendor">Vendor</span>
						</th>
						<th>
							<span data-field="deposit">Deposit</span>
						</th>
						<th>
							<span data-field="checkNumber">Dep. Ck. No.</span>
						</th>
						<th>
							<span data-field="checkDate">CK. DT.</span>
						</th>
						<th>
							<span data-field="acknowledgementDate">Ack. DT.</span>
						</th>
						<th>
							<span data-field="acknowledgementNumber">Ack No.</span>
						</th>
						<th>
							<span data-field="shipVia">Ship Via</span>
						</th>
						<th>
							<span data-field="expectedDate">Expected</span>
						</th>
					</tr>
					<tr
						className={`a-table-search-fields ${
							this.state.showTableSearch ? '' : 'd-none'
						}`}
					>
						<th>
							<Form.Control type="text" data-field="ponum" />
						</th>
						<th>
							<Form.Control type="text" data-field="quantity" />
						</th>
						<th>
							<Form.Control type="text" data-field="orderDate" />
						</th>
						<th>
							<Form.Control type="text" data-field="vendor" />
						</th>
						<th>
							<Form.Control type="text" data-field="deposit" />
						</th>
						<th>
							<Form.Control type="text" data-field="checkNumber" />
						</th>
						<th>
							<Form.Control type="text" data-field="checkDate" />
						</th>
						<th>
							<Form.Control type="text" data-field="acknowledgementDate" />
						</th>
						<th>
							<Form.Control type="text" data-field="acknowledgementNumber" />
						</th>
						<th>
							<Form.Control type="text" data-field="shipVia" />
						</th>
						<th>
							<Form.Control type="text" data-field="expectedDate" />
						</th>
					</tr>
				</thead>

				<tbody>
					{this.state.pos.map((item, i) => (
						<tr key={i} data-id={item.ponum}>
							<td>
								<Link
									to={generatePath(URI.project.vendorOrder.modifyStatus, {
										id: this.state.data.project.id,
										poId: item.poId,
									})}
									className="text-charcoal hover-view-icon"
								>
									{item.ponum}
								</Link>
							</td>
							<td>{item.quantity}</td>
							<td>{formatDate(item.orderDate)}</td>
							<td>{item.vendor}</td>
							<td>{currencyFormat(item.deposit)}</td>
							<td>{item.checkNumber}</td>
							<td>{formatDate(item.checkDate)}</td>
							<td>{formatDate(item.acknowledgementDate)}</td>
							<td>{item.acknowledgementNumber}</td>
							<td>{item.shipVia}</td>
							<td>{formatDate(item.expectedDate)}</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	}

	renderContent2() {
		return (
			<Table striped responsive className="a-table">
				<thead>
					<tr className="a-table-heading">
						<th>
							<span data-field="transactionNumber">TX No.</span>
						</th>
						<th>
							<span data-field="vendor">Vendor</span>
						</th>
						<th>
							<span data-field="invoiceNumber">Invoice No.</span>
						</th>
						<th>
							<span data-field="inoiceDate">Invoice Date</span>
						</th>
						<th>
							<span data-field="poNumber">PO/WO No.</span>
						</th>
						<th>
							<span data-field="paidForQuantity">Qty</span>
						</th>
						<th>
							<span data-field="cost">Cost</span>
						</th>
						<th>
							<span data-field="checkNumber">Check No.</span>
						</th>
						<th>
							<span data-field="checkdate">Check Date</span>
						</th>
					</tr>
					<tr
						className={`a-table-search-fields ${
							this.state.showTableSearch ? '' : 'd-none'
						}`}
					>
						<th>
							<Form.Control type="text" data-field="transactionNumber" />
						</th>
						<th>
							<Form.Control type="text" data-field="vendor" />
						</th>
						<th>
							<Form.Control type="text" data-field="invoiceNumber" />
						</th>
						<th>
							<Form.Control type="text" data-field="inoiceDate" />
						</th>
						<th>
							<Form.Control type="text" data-field="poNumber" />
						</th>
						<th>
							<Form.Control type="text" data-field="paidForQuantity" />
						</th>
						<th>
							<Form.Control type="text" data-field="cost" />
						</th>
						<th>
							<Form.Control type="text" data-field="checkNumber" />
						</th>
						<th>
							<Form.Control type="text" data-field="checkdate" />
						</th>
					</tr>
				</thead>

				<tbody>
					{this.state.vinv.map((item, i) => (
						<tr key={i} data-id={item.invoiceNumber}>
							<td>{item.transactionNumber}</td>
							<td>{item.vendor}</td>
							<td>{item.invoiceNumber}</td>
							<td>{formatDateTime(item.invoiceDate)}</td>
							<td>{item.poNumber}</td>
							<td>{item.paidForQuantity}</td>
							<td>{currencyFormat(item.cost)}</td>
							<td>{item.checkNumber}</td>
							<td>{formatDate(item.checkDate)}</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	}

	renderVerticalActionBar() {
		return (
			<ActionBarVertical>
				<ActionBarVertical.Links>
					<li>
						<NavLink
							to={routeParam(URI.project.item.componentEditOrderStatusPO, {
								id: this.state.data.projectId,
								poId: this.state.activeId,
							})}
							className="d-flex align-items-center"
						>
							<i className="ri-edit-line mx-0"></i>
						</NavLink>
					</li>
				</ActionBarVertical.Links>
			</ActionBarVertical>
		);
	}

	renderVerticalActionBar2() {
		return (
			<ActionBarVertical>
				<ActionBarVertical.Links>
					<li>
						<NavLink
							to={routeParam(URI.project.item.componentEditOrderStatusInvoice, {
								id: this.state.data.projectId,
								invId: this.state.activeId,
							})}
							className="d-flex align-items-center"
						>
							<i className="ri-edit-line mx-0"></i>
						</NavLink>
					</li>
				</ActionBarVertical.Links>
			</ActionBarVertical>
		);
	}

	render() {
		const { data } = this.state;

		if (this.state.loading) {
			return <Spinner isChild />;
		}
		if (this.state.error) {
			return null;
		}
		return (
			<>
				<Row>
					<Col sm="12">
						<div className="bg-secondary-grey px-4 roundedx-4 mb-4">
							<Row className="py-4 align-items-center">
								<Col className="mb-2 mb-md-0">
									<h6 className="fw-bold mb-0">Purchase and Work Orders</h6>
								</Col>
								<Col sm={3}>
									<div
										style={{
											width: '100px',
											marginLeft: 'auto',
										}}
									>
										<Button
											as={Link}
											to="#"
											variant="ivory"
											size="sm"
											className={`btn-icon btn-action fw-bold ${
												this.state.showTableSearch
													? 'bg-primary-ash text-white'
													: ''
											}`}
											onClick={this.handleShowTableSearch}
										>
											<i className="ri-search-line"></i> Search
										</Button>
									</div>
								</Col>
							</Row>
						</div>

						<Row>
							<Col
								lg="12"
								className="mt-2 mb-5 pb-5 position-relative table-action-bar"
							>
								{this.renderContent1()}
							</Col>
						</Row>
					</Col>
				</Row>

				<Row className="row justify-content-center mb-3">
					<Col lg={6}>
						<Row className="mb-3 align-items-center">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">CFA Date</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="date"
									placeholder="Please enter"
									id="cfadt"
									defaultValue={this.makeDefaultDate(
										this.props.updatedData.cfadt,
										data?.component?.[0]?.cfadt
									)}
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
					</Col>
					<Col lg={6}>
						<Row className="mb-3 align-items-center">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									{this.state.userDate1Title ?? 'Install Date'}
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="date"
									placeholder="Please enter"
									id="wc1dt"
									defaultValue={this.makeDefaultDate(
										this.props.updatedData.wc1dt,
										data?.component?.[0]?.wc1dt
									)}
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row className="row justify-content-center mb-3">
					<Col lg={6}>
						<Row className="mb-3 align-items-center">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">Status/Storage Loc.</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									placeholder="Please enter"
									id="statusnote"
									defaultValue={
										this.props.updatedData.statusnote ??
										data?.component?.[0]?.statusnote
									}
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
					</Col>
					<Col lg={6}>
						<Row className="mb-3 align-items-center">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									{this.state.userDate2Title ?? 'Inspection'}
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="date"
									placeholder="Please enter"
									id="wc2dt"
									defaultValue={this.makeDefaultDate(
										this.props.updatedData.wc2dt,
										data?.component?.[0]?.wc2dt
									)}
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row>
					<Col sm="12">
						<div className="bg-secondary-grey px-4 roundedx-4 mb-4">
							<Row className="py-4 align-items-center">
								<Col className="mb-2 mb-md-0">
									<h6 className="fw-bold mb-0">Vendor Invoices</h6>
								</Col>
								<Col sm={3}>
									<div
										style={{
											width: '100px',
											marginLeft: 'auto',
										}}
									>
										<Button
											as={Link}
											to="#"
											variant="ivory"
											size="sm"
											className={`btn-icon btn-action fw-bold ${
												this.state.showTableSearch
													? 'bg-primary-ash text-white'
													: ''
											}`}
											onClick={this.handleShowTableSearch}
										>
											<i className="ri-search-line"></i> Search
										</Button>
									</div>
								</Col>
							</Row>
						</div>

						<Row>
							<Col
								lg="12"
								className="mt-2 mb-5 pb-5 position-relative table-action-bar"
							>
								{/* {this.renderVerticalActionBar2()} */}
								{this.renderContent2()}
							</Col>
						</Row>
					</Col>
				</Row>
			</>
		);
	}
}

export default WithRouter(ItemAddComponentAddOrderStatus);
