import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Button, Form, Row, Col } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import URI from '../../../../defaults/RoutesDefault';
import { routeParam, WithRouter } from '../../../../helpers/Router';
import { FooterFormAction } from '../../../components/Form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../../lib/api/HttpService';
import {
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
} from '../../../../utilities/Response';
import { getCookie } from '../../../../utilities/Auth';
import { delay } from 'lodash';
import { toggleDMPageLoader } from '../../../../utilities/DOM';
import dayjs from 'dayjs';

class CashReceiptAddMisc extends ThreadComponent {
	constructor(props) {
		super(props);

		this.defaultData = {
			userdate: new Date().toISOString(),
			fiscalmonth: new Date().toISOString(),
		};

		this.state = {
			paymentTypes: [],
			officeAccounts: [],
			data: { ...this.defaultData },
		};
		this.api = new ApiService();
		this.id = this.props.params.id;
		this.userCode = getCookie('dmUsercode');
	}

	componentInit() {
		this.isEditing = this.props.params.id != null;
		this.title = `${this.isEditing ? 'Edit' : 'Add'} Miscellaneous`;
		this.setFormAction(true);
	}

	async componentDidMount() {
		this.dMloader(true);
		const paymentTypes = await this.api.getPaymentTypes();
		const officeAccounts = await this.api.getGLAccounts();

		if (this.isEditing) {
			const data = await this.api.getNewCashReceipts(
				`?$filter=usercode eq ${this.userCode} and groupnum eq ${this.id}`
			);
			const misc = data[0];
			misc.paytype = misc.fPayType;
			misc.name = misc.fClientName;
			misc.depositslip = misc.fDepositSlip;
			misc.miscaccount = misc.offsetAccount.slice(0, 5);
			misc.amount = misc.fAmount;
			misc.checknum = misc.fCheckNum;
			misc.txnum = misc.groupnum;

			if (!misc) {
				window.location.href = routeParam(
					URI.accountsReceivable.cashReceipt.listNew,
					{}
				);
				return;
			}

			this.setState({
				data: {
					...misc,
					userdate: misc.fUserDate,
				},
			});
		}

		this.dMloader(false);
		this.setState({
			paymentTypes: paymentTypes.map((paymentType) => {
				return {
					value: paymentType.type,
					label: paymentType.type,
					undeposited: paymentType.undeposited,
				};
			}),
			officeAccounts: officeAccounts.map((account) => {
				return {
					value: account.account,
					label: `${account.accountn} [${account.account}]`,
				};
			}),
		});
	}

	shouldRenderDepositSlip() {
		return (
			this.state.paymentTypes.find(
				(type) => type.value === this.state.data.paytype
			)?.undeposited === false
		);
	}

	handleDateChange = (date) => {
		this.setState({
			data: {
				...this.state.data,
				userdate: date,
				fiscalmonth: date,
			},
		});
	};

	handleSave = async (isNew) => {
		try {
			displayAlertLoader(
				`${
					this.isEditing
						? 'Saving changes. Please wait...'
						: 'Creating Miscellaneous. Please wait...'
				}`
			);
			const data = this.state.data;
			data.userdate = dayjs(new Date(data.userdate)).format('YYYY-MM-DD');

			if (this.isEditing) {
				await this.api.updateCashReceiptMisc(data);
			} else {
				await this.api.createCashReceiptMisc(data);
			}

			displayAlert('success', 'Miscellaneous has been saved successfully');

			let redirectURL = URI.accountsReceivable.cashReceipt.listNew;

			if (isNew) {
				redirectURL = URI.accountsReceivable.cashReceipt.addMisc;
				this.setState({
					data: { ...this.defaultData },
				});
			}

			delay(() => {
				this.props.navigate(redirectURL);
			}, 1200);
		} catch (error) {
			displayAlert('danger', getErrorMessage(error));
		}
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
			if (key === 'paytype') {
				this.setState({
					data: {
						...this.state.data,
						[key]: e.value,
						depositslip: e.value,
					},
				});
				return;
			}
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.attributes['data-field'].value;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.setState({
			data: {
				...this.state.data,
				[key]: value,
			},
		});
	};

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.accountsReceivable.cashReceipt.listNew}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsReceivable.cashReceipt.listNew,
							}}
							linkAs={Link}
						>
							Accounts Receivable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsReceivable.cashReceipt.listNew,
							}}
							linkAs={Link}
						>
							Cash Receipt
						</Breadcrumb.Item>
						<Breadcrumb.Item>{`${
							this.isEditing ? 'Edit' : 'Add'
						} Miscellaneous`}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={false}>
					<HeaderLight.Title>{`${
						this.isEditing ? 'Edit' : 'Add'
					} Miscellaneous`}</HeaderLight.Title>

					<div>
						<Button
							variant="ivory"
							name="cancel"
							className="mx-3"
							onClick={() => {
								this.props.navigate(URI.accountsReceivable.cashReceipt.listNew);
							}}
						>
							Cancel
						</Button>

						{/* Submit Button */}
						<Button
							variant="primary me-2"
							onClick={this.handleSave.bind(this, true)}
						>
							Save & New
						</Button>

						<Button
							variant="primary"
							onClick={this.handleSave.bind(this, false)}
						>
							Save
						</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderContent() {
		return (
			<Form.Group>
				<div className="bg-secondary-grey roundedx-4">
					<Row xs={1} className="py-4">
						<Col>
							<Row className="align-items-center">
								<Col className="mb-2 mb-lg-0 px-5">
									<h6 className="fw-bold mb-0">Receipt Information</h6>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>

				<Row xs={1} lg={2} className="py-4">
					<Col className="mb-3 mb-lg-0">
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									<strong>Date</strong>
								</Form.Label>
							</Col>
							<Col lg={5}>
								<div className="react-select-header">
									<DatePicker
										selected={new Date(this.state.data.userdate)}
										onChange={this.handleDateChange}
										showMonthDropdown="true"
										showYearDropdown="true"
										className="form-control"
										placeholderText="Select"
									/>
								</div>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									<strong>Payment Type</strong>
								</Form.Label>
							</Col>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 9999,
										}),
									}}
									options={this.state.paymentTypes}
									value={this.state.paymentTypes.filter((account) => {
										return account.value === this.state.data.paytype;
									})}
									className="react-select"
									placeholder="Please select"
									name="paytype"
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									<strong>Check No.</strong>
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									maxLength={20}
									placeholder="Please enter"
									data-field="checknum"
									value={this.state.data.checknum ?? ''}
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									<strong>Deposit Slip No.</strong>
								</Form.Label>
							</Col>
							<Col lg={5}>
								<Form.Control
									type="text"
									maxLength={7}
									placeholder="Please enter"
									data-field="depositslip"
									value={this.state.data.depositslip ?? ''}
									onChange={(e) => {
										this.shouldRenderDepositSlip() && this.handleChange(e);
									}}
									disabled={!this.shouldRenderDepositSlip()}
									readOnly={!this.shouldRenderDepositSlip()}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<div className="bg-secondary-grey roundedx-4">
					<Row xs={1} className="py-4">
						<Col>
							<Row className="align-items-center">
								<Col className="mb-2 mb-lg-0 px-5">
									<h6 className="fw-bold mb-0">Payment Distribution</h6>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>

				<Row xs={1} lg={2} className="py-4">
					<Col className="mb-3 mb-lg-0">
						{/* <Row className="align-items-center mb-3">
                            <Col lg={3} className="text-lg-end mb-2 mb-lg-0">
                                <Form.Label className="mb-0">
                                    <strong>Trans. Desc.</strong>
                                </Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    maxLength={30}
                                    placeholder="Please enter"
                                    data-field="txdesc"
                                    value={this.state.data.txdesc ?? ''}
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row> */}
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									<strong>Name</strong>
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									type="text"
									maxLength={30}
									placeholder="Please enter"
									data-field="name"
									value={this.state.data.name ?? ''}
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									<strong>Amount</strong>
								</Form.Label>
							</Col>
							<Col lg={5}>
								<div className="form-group-extra dollar">
									<Form.Control
										type="number"
										placeholder="Please enter"
										data-field="amount"
										value={this.state.data.amount ?? ''}
										onChange={this.handleChange}
										id="txt-amount"
									/>
								</div>
							</Col>
						</Row>
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									<strong>Offset Account</strong>
								</Form.Label>
							</Col>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 9999,
										}),
									}}
									options={this.state.officeAccounts}
									value={this.state.officeAccounts.filter((account) => {
										return (
											account.value === this.state.data.miscaccount?.slice(0, 5)
										);
									})}
									className="react-select"
									placeholder="Please select"
									name="miscaccount"
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Form.Group>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height">
					<Container fluid>{this.renderContent()}</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button
						variant="primary"
						size="lg"
						onClick={this.handleSave.bind(this, false)}
					>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(CashReceiptAddMisc);
