import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { WithRouter } from '../../../../helpers/Router';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FooterFormAction } from '../../../components/Form';
import React from 'react';
import Select from 'react-select';
import { displayAlert } from '../../../../utilities/Response';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import { RTFJS } from 'rtf.js';
import { stringToArrayBuffer } from '../../../../helpers/String';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { reload } from '../../../../helpers/Util';

class WorkflowSettingsInvoices extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			remarkshtml: '',
		};
		this.api = new ApiService();
		this.styleOptions = [
			{ value: 0, label: 'Combine' },
			{ value: 1, label: 'List' },
			{ value: 2, label: 'Total' },
			{ value: 3, label: 'Ignore' },
		];
		this.pricingOptions = [
			{ value: 0, label: 'Proposal' },
			{ value: 1, label: 'Always Actual' },
			{ value: 2, label: 'Automatic' },
		];
		this.checkboxes1 = [
			{
				label: 'Sup. Loc. Heading',
				id: 'invsuploc',
				checked: props.data.company1.invsuploc,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Sup. Unit Prices',
				id: 'invsupunit',
				checked: props.data.company1.invsupunit,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Ship To Addr.',
				id: 'invshowshipto',
				checked: props.data.company1.invshowshipto,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Site Addr.',
				id: 'invshowsite',
				checked: props.data.company1.invshowsite,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Proj. Code',
				id: 'invprojcode',
				checked: props.data.company1.invprojcode,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Unit to 4 Decimal Places',
				id: 'invunit2dec',
				checked: props.data.company1.invunit2dec,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Rmks. on New Page',
				id: 'invremsep',
				checked: props.data.company1.invremsep,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Email & Website',
				id: 'invemail',
				checked: props.data.company2.invemail,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
		];
		this.checkboxes2 = [
			// {
			//     label: 'Single Item Inv.',
			//     id: 'singleiteminv',
			//     checked: props.data.company1.singleiteminv,
			//     onChange: (e, meta) => this.handleCompanyChange(e, meta),
			// },
			{
				label: 'Time Supplement',
				id: 'invsupplement',
				checked: props.data.company1.invsupplement,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Time Details',
				id: 'invshowtimedetails',
				checked: props.data.company1.invshowtimedetails,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show SE Times',
				id: 'invshowtbtime',
				checked: props.data.company2.invshowtbtime,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
			{
				label: 'Show Paid Stamp',
				id: 'invpaidstamp',
				checked: props.data.company2.invpaidstamp,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
			{
				label: 'Sup. Time Employee',
				id: 'invsuptimeemp',
				checked: props.data.company2.invsuptimeemp,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
		];
		this.invoices = [
			{ label: 'Merchandise', pricingId: 'invpricemodem' },
			{
				titleId: 'invdesigndesc',
				styleId: 'invdesignstyle',
				pricingId: 'invpricemoded',
				markUpKey: 'compviewd',
			},
			{
				titleId: 'invfreightdesc',
				styleId: 'invfreightstyle',
				pricingId: 'invpricemodef',
				markUpKey: 'compviewf',
			},
			{
				titleId: 'invinstalldesc',
				styleId: 'invinstallstyle',
				pricingId: 'invpricemodei',
				markUpKey: 'compviewi',
			},
			{
				titleId: 'invlabordesc',
				styleId: 'invlaborstyle',
				pricingId: 'invpricemodel',
				markUpKey: 'compviewl',
			},
			{
				titleId: 'invothersdesc',
				styleId: 'invothersstyle',
				pricingId: 'invpricemodeo',
				markUpKey: 'compviewo',
			},
		];
		this.updatedCompanyData = {};
		this.updatedCompany2Data = {};
		this.htmlToRtfCoverter = new HtmlToRtfBrowser();

		this.mapRtfToDraft(props.data.company1.invremarksrtf);
	}

	componentDidUpdate(prevProps, prevState) {
		this.dMloader(false, true);
	}

	handleCompanyChange = (e, meta) => {
		const keyValue = this.handleChange(e, meta);
		this.updatedCompanyData[keyValue.key] = keyValue.value;
	};

	handleCompany2Change = (e, meta) => {
		const keyValue = this.handleChange(e, meta);
		this.updatedCompany2Data[keyValue.key] = keyValue.value;
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		return { key: key, value: value };
	};

	handleRemarks = (e) => {
		this.updatedCompanyData['invremarks'] = e.target.value;
		this.updatedCompanyData['invremarksrtf'] =
			this.htmlToRtfCoverter.convertHtmlToRtf(e.target.html);
	};

	handleSave = async (e) => {
		e.preventDefault();

		try {
			await this.api.patchCompany(this.updatedCompanyData);
			await this.api.patchCompany2(this.updatedCompany2Data);

			displayAlert('success', 'Successfully saved changes.');
			reload();
		} catch (error) {
			displayAlert('danger', error.response.data.userError);
		}
	};

	mapRtfToDraft(rtf) {
		if (!rtf) {
			return;
		}

		const self = this;
		const doc = new RTFJS.Document(stringToArrayBuffer(rtf), {});

		doc
			.render()
			.then(function (htmlElements) {
				const html = htmlElements
					.map((element) => {
						return element.outerHTML;
					})
					.join(' ');
				self.setState({
					remarkshtml: `${html}`,
				});
			})
			.catch((error) => console.error(error));
	}

	render() {
		return (
			<>
				<div className="content-padding min-height">
					<Container className="mb-4">
						<Row className={'flex-nowrap overflow-auto'}>
							<Col xs={11} sm={7}>
								<Row className={'mb-2'}>
									<Col />
									<Col className="mb-2 mb-lg-0">
										<Form.Label>Title</Form.Label>
									</Col>
									<Col>
										<Form.Label>Style</Form.Label>
									</Col>
									<Col>
										<Form.Label>Pricing</Form.Label>
									</Col>
								</Row>
								{this.invoices.map((invoice) => {
									return (
										<Row
											key={`${Math.floor(Math.random() * 10000)}-min`}
											className={'flex-nowrap mb-2'}
										>
											<Col className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0 mt-2 pt-1">
													{this.props.data.company1[invoice.markUpKey]}
												</Form.Label>
											</Col>
											<Col className="text-lg-end mb-2 mb-lg-0">
												{invoice.titleId && (
													<Form.Control
														type="text"
														maxLength={30}
														placeholder=""
														id={invoice.titleId}
														defaultValue={
															this.props.data.company1[invoice.titleId]
														}
														onChange={this.handleCompanyChange}
													/>
												)}
											</Col>
											<Col>
												{invoice.styleId && (
													<Select
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 9999,
															}),
														}}
														options={this.styleOptions}
														className="react-select"
														defaultValue={
															this.styleOptions.filter((option) => {
																return (
																	option.value ===
																	this.props.data.company1[invoice.styleId]
																);
															})[0]
														}
														placeholder="Please select"
														name={invoice.styleId}
														onChange={this.handleCompanyChange}
													/>
												)}
											</Col>
											<Col>
												<Select
													menuPortalTarget={document.body}
													styles={{
														menuPortal: (base) => ({
															...base,
															zIndex: 9999,
														}),
													}}
													options={this.pricingOptions}
													className="react-select"
													defaultValue={
														this.pricingOptions.filter((option) => {
															return (
																option.value ===
																this.props.data.company1[invoice.pricingId]
															);
														})[0]
													}
													placeholder="Please select"
													name={invoice.pricingId}
													onChange={this.handleCompanyChange}
												/>
											</Col>
										</Row>
									);
								})}
							</Col>
							<Col className={'ps-4'}>
								<Row className={'mb-2'}>
									<Col />
									<Col className="mb-2 mb-lg-0">
										<Form.Label></Form.Label>
									</Col>
								</Row>
								<Row className={'flex-nowrap'}>
									<Col xs={12} sm={'auto'}>
										{this.checkboxes1.map((checkbox) => {
											return (
												<Row key={`${Math.floor(Math.random() * 10000)}-min`}>
													<Form.Check
														inline
														className={'mb-2'}
														label={checkbox.label}
														name={checkbox.id}
														type="checkbox"
														id={checkbox.id}
														defaultChecked={checkbox.checked}
														onChange={checkbox.onChange}
													/>
												</Row>
											);
										})}
									</Col>
									<Col xs={12} sm={'auto'}>
										{this.checkboxes2.map((checkbox) => {
											return (
												<Row key={`${Math.floor(Math.random() * 10000)}-min`}>
													<Form.Check
														inline
														className={'mb-2'}
														label={checkbox.label}
														name={checkbox.id}
														type="checkbox"
														id={checkbox.id}
														defaultChecked={checkbox.checked}
														onChange={checkbox.onChange}
													/>
												</Row>
											);
										})}
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xs={11} sm={7}>
								<Row>
									<Col xs={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0 mt-2">Remarks</Form.Label>
									</Col>
									<Col>
										<WysiwygEditor onChange={this.handleRemarks}>
											{this.state.remarkshtml}
										</WysiwygEditor>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</div>
				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleSave}>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(WorkflowSettingsInvoices);
