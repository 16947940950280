import { apiClient } from 'api/apiClient';

const RESOURCE = '/Plaid/link-token';

export interface IPlaidToken {
	linkToken: string;
}

export const getPlaidToken = async (
	accountId?: number
): Promise<IPlaidToken> => {
	const { data } = await apiClient.get(
		`${RESOURCE}${accountId ? `?accountId=${accountId}`.trim() : ''}`.trim()
	);

	return data;
};
