import React from 'react';
import { WithRouter } from '../../../helpers/Router';
import ResourceSectionGrid from '../../components/Grids/ResourceSectionGrid/ResourceSectionGrid';
import ClipperGrid from 'legacy/app/components/Grids/ClipperGrid/ClipperGrid';
import ImprovementsBanner from '../../components/banners/ImprovementsBanner/ImprovementsBanner';
import CashflowInformation from 'legacy/app/components/CashflowInformation/CashflowInformation';
import BusinessSummary from 'legacy/app/components/BusinessSummary/BusinessSummary';
import RecentProjects from 'legacy/app/components/RecentProjects/RecentProjects';
import { useUserQuery } from 'legacy/app/context/security';
import { IUser } from 'legacy/lib/api/types/ManagedAccounts';
import useGetRecentProjects from 'legacy/lib/api/hooks/useGetRecentProjects';
import { useSession } from 'legacy/app/context/session';

const Dashboard = () => {
	const userQuery = useUserQuery(['SecurityProvider', 'userInfo']);
	const { data } = userQuery;
	const userData = (data as IUser[]) ?? [];
	const [user] = userData;
	const displayName = user?.username || '';
	const MAX_PROJECTS = 5;
	const { usercode } = useSession();
	const filter = `?$filter=isClosed eq false and userCode eq ${usercode}&$top=${MAX_PROJECTS}&$orderby=lastactivity desc`;
	const { projects, hasAccessToFinancialInfo } = useGetRecentProjects(
		filter,
		true
	);

	const initials = displayName
		?.split(' ')
		?.map((word: string) => word[0]?.toUpperCase())
		?.join('');

	return (
		<>
			<div
				data-testid="dashboard-page"
				className="tw-bg-[length:100%_100%] tw-bg-[linear-gradient(180deg,_#006a53_23%,_#faf9f7_23%)]"
			>
				<div className="tw-bg-[#006a53] tw-px-11 tw-pb-8 tw-pt-4 tw-text-white tw-flex tw-flex-col">
					<button className="tw-bg-[#006a53] tw-hidden tw-text-xs tw-px-2 tw-py-1 tw-rounded-full tw-ml-4 tw-mt-1 tw-m-2.5 tw-border tw-border-white tw-self-end">
						✨ See what&apos;s new in V1.1
					</button>
					<div className="tw-flex tw-items-center tw-mb-6">
						<div className="tw-bg-[#cecece] tw-rounded-full tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-text-2xl">
							{initials}
						</div>
						<div className="tw-ml-4">
							<h1 className="tw-text-3xl">{`Hello, ${displayName}`}</h1>
						</div>
					</div>
					{hasAccessToFinancialInfo && <BusinessSummary />}
				</div>
				<div className="tw-px-10 tw-grid tw-gap-5">
					<RecentProjects
						maxProjects={MAX_PROJECTS}
						projects={projects}
						hasAccessToFinancialInfo={hasAccessToFinancialInfo}
					/>
					<div className="tw-mt-5">
						{hasAccessToFinancialInfo && <CashflowInformation />}
					</div>
					<ClipperGrid />
					<ResourceSectionGrid />
					<ImprovementsBanner />
				</div>
			</div>
		</>
	);
};

Dashboard.displayName = 'Dashboard';

export default WithRouter(Dashboard);
