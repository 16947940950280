import dayjs from 'dayjs';
import { currencyFormat } from '../../helpers/Number';

function randomDate(start, end) {
	return new Date(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	);
}

function randomNumbers(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Generate fake project data.
 *
 * @param {number} x Total items
 * @returns
 */
function fakeProjectData(x) {
	const statusList = [
		{ name: 'Billed', class: 'primary' },
		{ name: 'Pending', class: 'primary-yellow' },
		{ name: 'Unpaid', class: 'primary-red' },
		{ name: 'Processing', class: 'primary-blue' },
	];
	const items = [];

	for (let i = 0; i < x; i++) {
		items.push({
			ref: randomNumbers(120, 140),
			code: 'JOHN01',
			codeNo: '00001',
			name: 'Project Name',
			budget: currencyFormat(Math.random() * 20 * 2000, '$'),
			location: 'Living Room',
			category: 'Furniture',
			image: 'https://picsum.photos/id/445/500/500.jpg',
			description:
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.',
			excerpt: 'Lorem ipsum',
			qty: 1,
			status: statusList[Math.floor(Math.random() * statusList.length)],
			invoice: '4/20/2022 10:06:52am Jeff.dillon@gmail.com',
			proposal: dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
				'MM/DD/YYYY'
			),
			po: dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
				'MM/DD/YYYY'
			),
			dateOpened: dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
				'MM/DD/YYYY'
			),
			dateClosed: dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
				'MM/DD/YYYY'
			),
			retainerSent:
				dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
					'MM/DD/YYYY hh:mmA'
				) + ' jeff.dillon@gmail.com',
			retainer: 'XXXXX',
			clientCode: '',
			salesperson: {
				code: 'XXXXX',
			},
			client: {
				code: 'Client Code',
				name: 'Doe, Jane',
				orderNo: 'XXXXX',
			},
		});
	}

	return items;
}

/**
 * Generate fake data.
 *
 * @param {number} x Total items
 * @returns
 */
function fakeData(x) {
	// const statusList = [
	//     {'name': 'Billed', 'class': 'primary'},
	//     {'name': 'Pending', 'class': 'primary-yellow'},
	//     {'name': 'Unpaid', 'class': 'primary-red'},
	//     {'name': 'Processing', 'class': 'primary-blue'},
	// ];
	const items = [];

	for (let i = 0; i < x; i++) {
		items.push({
			id: i + 1,
			location: 'Living Room',
			category: 'Furniture',
			image:
				'https://picsum.photos/id/445/500/500.jpg?hmac=mm_2RbNZtJOsPI-ECtPJXL0D-QznMUPtlX8FbxWxJcc',
			description:
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.',
			excerpt: 'Lorem ipsum',
			qty: 1,
			status: '',
			projectRef: randomNumbers(120, 140),
			projectCode2: randomNumbers(120, 140),
			projectCode: 'JOHN01',
			projectCodeNo: '00001',
			projectName: 'Project Name',
			projectBudget: currencyFormat(Math.random() * 20 * 2000, '$'),
			invoice: '4/20/2022 10:06:52am Jeff.dillon@gmail.com',
			projectProposal: dayjs(
				randomDate(new Date(2012, 0, 1), new Date())
			).format('MM/DD/YYYY'),
			projectPO: dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
				'MM/DD/YYYY'
			),
			dateOpened: dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
				'MM/DD/YYYY'
			),
			dateClosed: dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
				'MM/DD/YYYY'
			),
			retainerSent:
				dayjs(randomDate(new Date(2012, 0, 1), new Date())).format(
					'MM/DD/YYYY hh:mmA'
				) + ' jeff.dillon@gmail.com',
			retainer: 'XXXXX',
			clientCode: 'Client Code',
			clientName: 'Doe, Jane',
			clientOrderNo: 'XXXXX',
			salesPersonCode: 'XXXXX',
		});
	}

	return items;
}

function fakeReactSelectOptions() {
	return [
		{ value: 'option-1', label: 'Option 1' },
		{ value: 'option-2', label: 'Option 2' },
		{ value: 'option-3', label: 'Option 3' },
	];
}

function generateFakeReactSelectOptions(data) {
	let object = [];

	if (data && data.length) {
		data.forEach((item) => object.push({ value: item, label: item }));
	}

	return object;
}

export {
	fakeData,
	fakeProjectData,
	fakeReactSelectOptions,
	generateFakeReactSelectOptions,
};
