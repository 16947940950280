import { useQuery } from '@tanstack/react-query';
import { getBillingInfo } from 'api/resources/clients';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGetBillingInfo = () => {
	const query = useQuery({
		queryKey: [ECacheKeys.Clients, 'billingInfo'],
		queryFn: getBillingInfo,
		refetchOnWindowFocus: false,
		refetchInterval: false,
	});

	return query;
};
