import React from 'react';
import { Spinner } from 'react-bootstrap';

const AsyncDropdownLoadingIndicator = () => {
	const style = {
		position: 'relative',
		width: '60px',
		margin: 0,
		top: '-1px',
	};
	return (
		<div className="d-flex justify-content-center align-items-center">
			<Spinner animation="border" variant="primary me-2" size="sm" />
			<p className="fw-bold text-muted" style={style as any}>
				loading<span className="dot-load"></span>
			</p>
		</div>
	);
};

AsyncDropdownLoadingIndicator.displayName = 'AsyncDropdownLoadingIndicator';

export default AsyncDropdownLoadingIndicator;
