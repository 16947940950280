import { Row } from '@tanstack/react-table';
import type {
	ITemporaryReconciliationCheck,
	ITemporaryReconciliationDeposit,
} from 'api/resources/temporaryReconciliations';
import { Form } from 'react-bootstrap';
import cn from 'classnames';

type TTemporaryReconciliation =
	| ITemporaryReconciliationCheck
	| ITemporaryReconciliationDeposit;

interface ICheckbookReconcileTableCheckboxCellProps {
	row?: Row<TTemporaryReconciliation>;
	handleOnChange: (
		e: React.ChangeEvent<HTMLInputElement>,
		row?: Row<TTemporaryReconciliation>
	) => void;
	checked: boolean;
}

const CheckbookReconcileCheckboxCell = ({
	row,
	handleOnChange,
	checked,
}: ICheckbookReconcileTableCheckboxCellProps) => {
	return (
		<Form.Check
			label=""
			onChange={(e) => handleOnChange(e, row)}
			checked={checked}
			type="checkbox"
			className={cn({ check: checked })}
		/>
	);
};

CheckbookReconcileCheckboxCell.displayName = 'CheckbookReconcileCheckboxCell';

export { CheckbookReconcileCheckboxCell };
