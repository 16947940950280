import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { Company } from '../types/Company';

const api = new ApiService();

const useGetCompany = (filter = '') => {
	return useQuery<Company, Error>(
		['company', filter],
		() => api.getCompany(filter),
		{
			refetchOnWindowFocus: false,
		}
	);
};

export default useGetCompany;
