import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { FormInputAdOns } from 'legacy/templates/components/Form';
import InputBar from 'legacy/templates/components/InputBar';

const CheckbookReconcileDepositsTotals = () => {
	return (
		<Col xs={12} lg={6} className="mb-3 mb-lg-0">
			<div className="bg-secondary-grey py-4 mb-4 roundedx-4">
				<Row className="align-items-center px-3">
					<Col className="px-3">
						<h6 className="fw-bold mb-0">Deposits Totals</h6>
					</Col>
				</Row>
			</div>

			<InputBar className="full multiple bg-white border-0">
				<InputBar.Links style={{ maxWidth: 'none' }} className="px-0">
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							<strong>Sum</strong>
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label htmlFor="inputPassword5" className="ilabel">
							<strong>Cleared Checks</strong>
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		</Col>
	);
};

CheckbookReconcileDepositsTotals.displayName =
	'CheckbookReconcileDepositsTotals';

export { CheckbookReconcileDepositsTotals };
