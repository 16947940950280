import { apiClient } from 'api/apiClient';

const RESOURCE = '/Components/vendorinvoices';

export interface IVendorInvoice {
	transactionNumber: number;
	poNumber: string;
	paidForQuantity: number;
	cost: number;
	invoiceNumber: string;
	invoiceDate: string;
	vendor: string;
	checkNumber: string;
	checkDate: string;
	projectCode: string;
	componentNumber: string;
	itemNumber: string;
}

export const getVendorInvoices = async (
	filter?: string
): Promise<IVendorInvoice[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());

	return data;
};
