import { ApiService } from '../lib/api/HttpService';
import { getLocalStorage, setLocalStorage } from './LocalStorage';

export async function setComponents() {
	const componentTypes: any = [];
	const api = new ApiService();
	const updatedComponentTypes: any = await api.get('company');

	componentTypes.push(updatedComponentTypes.compviewm);
	componentTypes.push(updatedComponentTypes.compviewd);
	componentTypes.push(updatedComponentTypes.compviewf);
	componentTypes.push(updatedComponentTypes.compviewi);
	componentTypes.push(updatedComponentTypes.compviewl);
	componentTypes.push(updatedComponentTypes.compviewo);

	setLocalStorage('componentTypes', componentTypes, true);
}

export function getComponentName(componentCode: number) {
	const storedComponentTypes: any = getLocalStorage('componentTypes', true);
	return storedComponentTypes[componentCode] ?? '';
}
