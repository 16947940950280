import React from 'react';
import { Dropdown, Col, Row, Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter, routeParam } from '../../../../helpers/Router';
import { fakeProjectData } from '../../../factory/fakeFactory';
import ListFilter from '../../../components/ListFilter';
import ICON from '../../../../defaults/Icon';
import { ApiService } from '../../../../lib/api/HttpService';
import debounce from 'lodash/debounce';
import { Pagination } from '../../../../app/components/pagination/Pagination';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import {
	formatDate,
	formatFilterDate,
	formatFilterMonth,
	formatFiscalMonth,
} from '../../../../helpers/Date';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
} from '../../../../utilities/Response';
import URI from '../../../../defaults/RoutesDefault';
import { DatePicker, DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { isFiltered } from '../../../../helpers/Util';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import { includes, isEmpty } from 'lodash';
import ConfirmModal from '../../../../app/components/modal/ConfirmModal';
import MSG from '../../../../defaults/Message';
import { plural } from '../../../../utilities/String';
import FilterSort from '../../../../utilities/modules/FilterSort';
import SecureDropdownItem from '../../../../app/components/security/SecureDropdownItem';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import SecureLink from '../../../../app/components/security/SecureLink';

class JournalEntryListExisting extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('gl_journal_existing_list');
		this.fs.setDefaultSort('txnum asc');
		this.state = {
			checks: [],
			totalChecked: 0,
			data: [],
			dataIsLoaded: false,
			pageSize: 20,
			page: 1,
			sortProperty: this.fs.getSort() ?? 'txnum asc',
			showVoidModal: false,
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};

		this.api = new ApiService();
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	async componentDidMount() {
		this.setTitle('Journal Entries').setFormAction(true);
		this.enableSortTable();
		this.fetchItems();
	}

	async fetchItems(page, isLoader) {
		if (isLoader) this.setState({ dataIsLoaded: false });
		let data = await this.api.get(
			'JournalEntries/existing',
			`?${this.buildFilters(page)}`
		);

		if (data) {
			const hasNextPage = data.length > this.state.pageSize;
			// Total number on pagination fixes.
			data = data.slice(0, this.state.pageSize);
			this.setState({
				dataIsLoaded: true,
				data: data,
				hasNextPage: hasNextPage,
			});
		}
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});
		this.fs.setIsActiveSearch(newTableSearch);

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	doTableSearch(data) {}

	buildFilters(currentPage) {
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.ma) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else if (property.type === 'month') {
					if (property.value) filters.push(`${key} eq ${property.value}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		const filtersQuery =
			filters.length > 0 ? `&$filter=${filters.join(' and ')}` : '';

		let queryString = `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}${filtersQuery}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchItems(page, true);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleChecks = (entry) => {
		const { checks } = this.state;

		const index = checks.findIndex((_id) => _id === entry.txnum);
		const checked = index > -1;
		const updatedChecks = checked
			? checks.slice(0, index).concat(checks.slice(index + 1))
			: checks.concat(entry.txnum);

		this.setState({
			checks: updatedChecks,
			totalChecked: updatedChecks.length,
		});
	};

	handleSelectAllChecks = (e) =>
		this.setState({
			checks: !e.target.checked
				? []
				: this.state.data.map((item) => {
						return item.txnum;
				  }),
			totalChecked: !e.target.checked ? 0 : this.state.data.length,
		});

	handleVoidConfirm = async () => {
		const { checks } = this.state;
		this.toggleConfirmModal(false);

		displayAlertLoader(
			plural(MSG.loading.update.GLJeVoid, 'journal entry', checks.length, 'ies')
		);

		let has_error = false;
		for (let i in checks) {
			await this.api
				.voidJournalEntry({
					transactionNumber: checks[i],
				})
				.catch((error) => {
					has_error = !error.response.data.isOk;
					displayAlertError(getErrorMessage(error));
				});
		}

		if (!has_error) {
			displayAlert(
				'success',
				`Successfully void a journal ${
					this.state.total > 1 ? 'entries' : 'entry'
				}`
			);
			setTimeout(() => {
				this.refreshData();
			}, 2000);
		}
	};

	toggleConfirmModal = (state) => {
		if (state === true && isEmpty(this.state.checks)) {
			displayAlertError(MSG.error.noSelected);
			return;
		}

		this.setState({
			showVoidModal: state,
		});
	};

	refreshData = () => {
		this.setState({
			checks: [],
			totalChecked: 0,
			data: [],
			dataIsLoaded: false,
			pageSize: 20,
			page: 1,
			sortProperty: '',
			searchProperties: {},
		});
		this.fetchItems();
	};

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleSearchMonthChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				value: formatFilterMonth(e),
				type: 'month',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	renderFilters() {
		return (
			<>
				<ListFilter>
					<ListFilter.Fields md={12} lg={9} xl={9} className="width-33 no-auto">
						{/* Client requested to hide this for now */}
						{/* <ListFilter.Field>
                            <Form.Label className='text-end'><strong>Fiscal<br/>Month</strong></Form.Label>
                            <Form.Select size='sm'>
                                <option>07/2022</option>
                            </Form.Select>
                        </ListFilter.Field> */}
					</ListFilter.Fields>
					<ListFilter.Actions
						md={2}
						lg={3}
						xl={3}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-md-end"
					>
						<ListFilter.Action>
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
						<ListFilter.Action className="ms-auto ms-lg-3">
							<Dropdown
								className="d-flex justify-content-end ms-auto"
								align="end"
							>
								<Dropdown.Toggle
									id="dropdown-autoclose-true"
									variant="ivory"
									size="sm"
								>
									Actions
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<SecureDropdownItem
										attributeNo={64}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec2}
										onClick={this.toggleConfirmModal.bind(this, true)}
										href="#"
									>
										<i className={ICON.a.void}></i> Void
									</SecureDropdownItem>
								</Dropdown.Menu>
							</Dropdown>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer></ListFilter.Spacer>
			</>
		);
	}

	render() {
		const { data, checks, totalChecked } = this.state;
		return (
			<>
				<Row>
					<Col sm="12">
						{/* Filter */}
						{this.renderFilters()}

						<div className="table-gradient sticky-container">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading">
										<th align="middle" className="mw-70px">
											<div className="d-flex justify-content-center">
												<Form.Check
													inline
													label=""
													name={`inline-check-th-0`}
													type="checkbox"
													data-id={`th-0`}
													id={`inline-check-th-0`}
													checked={totalChecked}
													className={getCheckAllClass(totalChecked, data)}
													onClick={this.handleSelectAllChecks.bind(this)}
												/>
											</div>
										</th>
										<th className="mw-120px">
											<span
												className={this.sortClass('txnum')}
												data-field="txnum"
											>
												Tx Ref. No.
											</span>
										</th>
										<th className="mw-120px">
											<span
												className={this.sortClass('journalEntryNumber')}
												data-field="journalEntryNumber"
											>
												Entry No.
											</span>
										</th>
										<th className="mw-120px">
											<span
												className={this.sortClass('userDate')}
												data-field="userDate"
											>
												JE Date
											</span>
										</th>
										<th className="mw-120px">
											<span
												className={this.sortClass('fiscalMonth')}
												data-field="fiscalMonth"
											>
												Fiscal Month
											</span>
										</th>
										<th className="mw-120px">
											<span
												className={this.sortClass('keyedDate')}
												data-field="keyedDate"
											>
												Keyed Date
											</span>
										</th>
										<th className="mw-120px">
											<span
												className={this.sortClass('transactionDescription')}
												data-field="transactionDescription"
											>
												Transaction Description
											</span>
										</th>
										<th className="">
											<span
												className={this.sortClass('autoRevNum')}
												data-field="autoRevNum"
											>
												Auto Rev. Tx No.
											</span>
										</th>
										<th className="">
											<span
												className={this.sortClass('keyedBy')}
												data-field="keyedBy"
											>
												Keyed By User
											</span>
										</th>
									</tr>
									<tr
										className={`a-table-search-fields ${
											this.state.showTableSearch ? '' : 'd-none'
										}`}
									>
										<th></th>
										<th>
											<Form.Control
												type="text"
												data-field="txnum"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('txnum')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="journalEntryNumber"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('journalEntryNumber')}
											/>
										</th>
										<th>
											<DateRangePicker
												style={{
													minWidth: '200px',
												}}
												placement="auto"
												placeholder="Select date"
												format="MM/dd/yyyy"
												defaultValue={this.fs.getValue('userDate')}
												onChange={this.handleDateChange.bind(this, 'userDate')}
												onClean={this.handleDateChange.bind(this, 'userDate')}
												ranges={[
													{
														label: 'today',
														value: [
															startOfDay(new Date()),
															endOfDay(new Date()),
														],
													},
													{
														label: 'yesterday',
														value: [
															startOfDay(addDays(new Date(), -1)),
															endOfDay(addDays(new Date(), -1)),
														],
													},
													{
														label: 'last7Days',
														value: [
															startOfDay(subDays(new Date(), 6)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'Last 30 Days',
														value: [
															startOfDay(subDays(new Date(), 30)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'This month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()),
																	1
																)
															),
															endOfDay(lastDayOfMonth(new Date())),
														],
													},
													{
														label: 'Last month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															),
															endOfDay(
																lastDayOfMonth(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																)
															),
														],
													},
												]}
											/>
										</th>
										<th>
											<DatePicker
												style={{
													minWidth: '200px',
												}}
												placeholder="Select month"
												format="MM/yyyy"
												defaultValue={this.fs.getValue('fiscalMonth')}
												onChange={this.handleSearchMonthChange.bind(
													this,
													'fiscalMonth'
												)}
												onClean={this.handleSearchMonthChange.bind(
													this,
													'fiscalMonth'
												)}
											/>
										</th>
										<th>
											<DateRangePicker
												style={{
													minWidth: '200px',
												}}
												placement="auto"
												placeholder="Select date"
												format="MM/dd/yyyy"
												defaultValue={this.fs.getValue('keyedDate')}
												onChange={this.handleDateChange.bind(this, 'keyedDate')}
												onClean={this.handleDateChange.bind(this, 'keyedDate')}
												ranges={[
													{
														label: 'today',
														value: [
															startOfDay(new Date()),
															endOfDay(new Date()),
														],
													},
													{
														label: 'yesterday',
														value: [
															startOfDay(addDays(new Date(), -1)),
															endOfDay(addDays(new Date(), -1)),
														],
													},
													{
														label: 'last7Days',
														value: [
															startOfDay(subDays(new Date(), 6)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'Last 30 Days',
														value: [
															startOfDay(subDays(new Date(), 30)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'This month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()),
																	1
																)
															),
															endOfDay(lastDayOfMonth(new Date())),
														],
													},
													{
														label: 'Last month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															),
															endOfDay(
																lastDayOfMonth(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																)
															),
														],
													},
												]}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="transactionDescription"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue(
													'transactionDescription'
												)}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="autoRevNum"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('autoRevNum')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="keyedBy"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('keyedBy')}
											/>
										</th>
									</tr>
								</thead>
								<tbody>
									{!this.state.dataIsLoaded
										? showLoading()
										: this.state.data.length === 0 &&
										  isFiltered(this.state.searchProperties)
										? showEmpty()
										: this.state.data.map((item, i) => (
												<tr
													key={i}
													data-key={i}
													className={
														includes(checks, item.txnum) ? `active` : ''
													}
												>
													<td>
														<div className="d-flex justify-content-center">
															<Form.Check
																label=""
																name={`group-` + i}
																type="checkbox"
																data-id={item.txnum}
																id={`chk-journalentry-items-` + i}
																className="chk-journalentry-items-item"
																checked={includes(checks, item.txnum)}
																onClick={this.handleChecks.bind(this, item)}
															/>
														</div>
													</td>
													<td>
														<SecureLink
															attributeNo={64}
															attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
															to={routeParam(
																URI.generalLedger.journalEntry.edit,
																{
																	id: item.txnum,
																	type: 'existing',
																}
															)}
															className="text-charcoal hover-view-icon"
														>
															{item.txnum}
														</SecureLink>
													</td>
													<td>{item.journalEntryNumber}</td>
													<td>{formatDate(item.userDate)}</td>
													<td>{formatFiscalMonth(item.fiscalMonth)}</td>
													<td>{formatDate(item.keyedDate)}</td>
													<td>{item.transactionDescription}</td>
													<td>
														{item.autoRevNum != '0' ? item.autoRevNum : ''}
													</td>
													<td>{item.keyedBy}</td>
												</tr>
										  ))}
								</tbody>
							</Table>
						</div>

						<Pagination
							onPageSizeChanged={this.onPageSizeChanged}
							onPageChanged={this.onPageChanged}
							hasPreviousPage={this.state.page > 1}
							hasNextPage={this.state.hasNextPage}
							page={this.state.page}
							pageSize={this.state.pageSize}
						/>
					</Col>
				</Row>

				<ConfirmModal
					show={this.state.showVoidModal}
					title="Void Vendor Invoices"
					message="Are you sure you want to void the selected item(s)?"
					confirmAction={this.handleVoidConfirm.bind(this)}
					toggleModal={this.toggleConfirmModal.bind(this, false)}
				/>
			</>
		);
	}
}

export default WithRouter(JournalEntryListExisting);
