import React from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import {
	getVendorInvoiceDetails,
	getVendorInvoices,
	getVendorInvoiceDetailsProjectPO,
} from '../api';
import { VendorInvoiceDetailProjectPO } from './VendorInvoiceDetailProjectPO';
import { formatNumber } from '../utils';
import { useQuery } from '@tanstack/react-query';

const PROJECT_PO = 1 as const;

interface IVendorInvoiceDetailProps {
	txnum: number;
}
const VendorInvoiceDetail = ({ txnum }: IVendorInvoiceDetailProps) => {
	const {
		data: vendorInvoiceDetails = [],
		isLoading: isVendorInvoiceDetailsLoading,
	} = useQuery({
		queryKey: ['vendorInvoiceDetails', txnum],
		queryFn: () => getVendorInvoiceDetails(`?$filter=txnum eq ${txnum}`),
	});

	const { data: vendorInvoices = [], isLoading: isVendorInvoicesLoading } =
		useQuery({
			queryKey: ['vendorInvoices', txnum],
			queryFn: () => getVendorInvoices(`?$filter=txnum eq ${txnum}`),
		});

	const isGetVendorInvoiceDetailsProjectPOEnabled =
		vendorInvoices?.[0]?.type === PROJECT_PO;

	const {
		data: vendorInvoiceDetailsProjectPO = [],
		isLoading: isVendorInvoiceDetailsProjectPOLoading,
	} = useQuery({
		queryKey: ['vendorInvoiceDetailsProjectPO', txnum],
		queryFn: () =>
			getVendorInvoiceDetailsProjectPO(`?$filter=txnum eq ${txnum}`),
		enabled: isGetVendorInvoiceDetailsProjectPOEnabled,
	});

	const isLoading =
		isVendorInvoiceDetailsLoading ||
		isVendorInvoicesLoading ||
		(isGetVendorInvoiceDetailsProjectPOEnabled &&
			isVendorInvoiceDetailsProjectPOLoading);

	return (
		<>
			<Modal.Header>
				<Modal.Title as="h5">
					Check / Vendor Invoice Detail [TX #{txnum}]
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<div className="tw-flex tw-items-center tw-justify-center">
						<Spinner variant="success" />
					</div>
				) : (
					<>
						<div className="tw-overflow-x-auto">
							<Table striped bordered>
								<thead>
									<tr>
										<th>Account No.</th>
										<th>Account Name</th>
										<th>Debit</th>
										<th>Credit</th>
									</tr>
								</thead>
								<tbody>
									{vendorInvoiceDetails.map((item) => {
										const { account, accountName, amount } = item;
										const debit = amount > 0 ? formatNumber(amount) : '';
										const credit =
											amount < 0 ? formatNumber(Math.abs(amount)) : '';

										return (
											<tr key={account}>
												<td>{account}</td>
												<td>{accountName}</td>
												<td className="tw-text-right">{debit}</td>
												<td className="tw-text-right">{credit}</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						</div>
						{vendorInvoiceDetailsProjectPO.length > 0 && (
							<VendorInvoiceDetailProjectPO
								vendorInvoiceDetailsProject={vendorInvoiceDetailsProjectPO}
							/>
						)}
					</>
				)}
			</Modal.Body>
		</>
	);
};

VendorInvoiceDetail.displayName = 'VendorInvoiceDetail';

export { VendorInvoiceDetail };
