export enum WhatChanged {
	SALES = 'iwcSalesCat',
	LOCATION = 'iwcLocation',
	DEPOSIT = 'iwcDepositPercent',
	QUANTITY = 'iwcQuantity',
	UNIT_BUDGET = 'iwcUnitBudget',
	EXTENDED_BUDGET = 'iwcExtendedBudget',
	PROJECT_BUDGET = 'iwcCheckBudget',
	ESTIMATED_PRICE = 'cwcEstimatedPrice',
	RECALC_FROM_COMPS_AND_STATUS = 'iwcRecalcFromComponentsAndStatus',
}

export type WhatChangedPayload = {
	whatChanged: `${WhatChanged}`;
	quantity: number | string;
	clDepPct: number | string;
	budget: number | string;
	unitBudget: number | string;
	manuallySetNewStatusNumber?: boolean;
	saveChanges?: boolean;
	loc: string | null;
	scat: string | null;
	budgetCheckOriginalEstPrices: {
		estMPrice: number;
		estDPrice: number;
		estFPrice: number;
		estIPrice: number;
		estLPrice: number;
		estOPrice: number;
		estTPrice: number;
	};
};

export interface WhatChangedResponse {
	itemId: number;
	statusNumber: number;
	quantity: number;
	completed: boolean;
	inactive: boolean;
	clDepPct: number;
	estDep: number;
	latestPrpNo: string;
	latestPrpDt: string;
	lastDepRecCkNo: string;
	lastDepRecDt: string;
	totDepRec: number;
	latestInvNo: number;
	latestInvDt: string;
	lastPaymentCkNo: string;
	lastPaymentDt: string;
	totPayment: number;
	totInvQty: number;
	totDepApp: number;
	latestPoNumOnly: string;
	latestWoNum: string;
	latestPoNum: string;
	latestPoDtOnly: string;
	latestWoDt: string;
	latestOrdDt: string;
	lastDelNum: string;
	lastDelDt: string;
	totDelQty: number;
	totShipQty: number;
	firstVendor: string;
	totVenDep: number;
	allCompsSInv: boolean;
	allCompsRec: boolean;
	allCompsAck: boolean;
	allCompsPoNa: boolean;
	allCompsInventory: boolean;
	allCompsExp: boolean;
	allCompsUserDate1: boolean;
	allCompsUserDate2: boolean;
	allCompsTime: boolean;
	budget: number;
	unitBudget: number;
	feeMCost: number;
	feeDCost: number;
	feeFCost: number;
	feeICost: number;
	feeLCost: number;
	feeOCost: number;
	feeTCost: number;
	estMCost: number;
	estDCost: number;
	estFCost: number;
	estICost: number;
	estLCost: number;
	estOCost: number;
	estTCost: number;
	estMMu: number;
	estDMu: number;
	estFMu: number;
	estIMu: number;
	estLMu: number;
	estOMu: number;
	estTMu: number;
	estMPrice: number;
	estDPrice: number;
	estFPrice: number;
	estIPrice: number;
	estLPrice: number;
	estOPrice: number;
	estTPrice: number;
	estMSTax: number;
	estDSTax: number;
	estFSTax: number;
	estISTax: number;
	estLSTax: number;
	estOSTax: number;
	estTSTax: number;
	estMTaxable: number;
	estDTaxable: number;
	estFTaxable: number;
	estITaxable: number;
	estLTaxable: number;
	estOTaxable: number;
	estTTaxable: number;
	actMCost: number;
	actDCost: number;
	actFCost: number;
	actICost: number;
	actLCost: number;
	actOCost: number;
	actTCost: number;
	actMMu: number;
	actDMu: number;
	actFMu: number;
	actIMu: number;
	actLMu: number;
	actOMu: number;
	actTMu: number;
	actMPrice: number;
	actDPrice: number;
	actFPrice: number;
	actIPrice: number;
	actLPrice: number;
	actOPrice: number;
	actTPrice: number;
	actMSTax: number;
	actDSTax: number;
	actFSTax: number;
	actISTax: number;
	actLSTax: number;
	actOSTax: number;
	actTSTax: number;
	actMTaxable: number;
	actDTaxable: number;
	actFTaxable: number;
	actITaxable: number;
	actLTaxable: number;
	actOTaxable: number;
	actTTaxable: number;
	billMPrice: number;
	billDPrice: number;
	billFPrice: number;
	billIPrice: number;
	billLPrice: number;
	billOPrice: number;
	billTPrice: number;
	billMSTax: number;
	billDSTax: number;
	billFSTax: number;
	billISTax: number;
	billLSTax: number;
	billOSTax: number;
	billTSTax: number;
	billMTaxable: number;
	billDTaxable: number;
	billFTaxable: number;
	billITaxable: number;
	billLTaxable: number;
	billOTaxable: number;
	billTTaxable: number;
	budgetCheck: {
		passed: boolean;
		isWarning: boolean;
		reasonMessage: string;
	};
}
