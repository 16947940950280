import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { IItemsTotals } from 'api/resources/itemTotals';
import { getItemTotals } from 'api/resources/itemTotals';

import { ECacheKeys } from 'cache/CacheKeys';

export const useGetItemTotals = (
	itemId: string,
	options?: UseQueryOptions<IItemsTotals>
) => {
	const query = useQuery<IItemsTotals>({
		queryKey: [ECacheKeys.ItemTotals, itemId],
		queryFn: () => getItemTotals(itemId),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
