import { CheckbookReconcileContext } from '../context/CheckbookReconcileContext';
import { useContext } from 'react';

export const useCheckbookReconcile = () => {
	const context = useContext(CheckbookReconcileContext);
	if (context === undefined) {
		throw new Error(
			'useCheckbookReconcile must be used within a CheckbookReconcileProvider'
		);
	}
	return context;
};
