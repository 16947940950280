import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { useHasAccess } from '../../../context/security';

interface IProps {
	attributeNo: number;
	attributeType: number;
	[key: string]: any;
}

const SecureDropdownToggle = (props: IProps) => {
	const { attributeNo, attributeType, disabled, children, ...rest } = props;
	const hasAccess = useHasAccess(attributeNo, attributeType);
	const isDisabled = !hasAccess ? true : disabled;

	return (
		<DropdownToggle {...rest} disabled={isDisabled}>
			{children}
		</DropdownToggle>
	);
};

SecureDropdownToggle.displayName = 'SecureDropdownToggle';

export default SecureDropdownToggle;
