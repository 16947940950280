import React from 'react';
import { Col, Row, Table, Form } from 'react-bootstrap';
import { WithRouter } from '../../../../helpers/Router';
import InputBar from '../../../components/InputBar';
import ThreadComponent from '../../ThreadComponent';
import { currencyFormat } from '../../../../helpers/Number';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import { debounce } from 'lodash';
import { formatDate } from '../../../../helpers/Date';
import SecureContent from '../../../../app/components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';
import { Is } from '../../../../helpers/Util';

class ItemAddStatus extends ThreadComponent {
	constructor(props) {
		super(props);
		this.updatedData = this.props.updatedData;

		this.state = {
			data: {},
			dataIsLoaded: false,
			inactive: false,
			completed: false,
			itemInvoiceSort: 'invoiceNumber asc',
			itemPropSort: 'proposalNumber asc',
			loading: false,
			error: false,
			bypasswip: false,
			status: {},
			sortProperty: '',
		};

		this.changePageHandler = debounce(this.handleChangePage.bind(this), 200);
	}

	async loadInitialData() {
		this.setState({
			loading: true,
		});
		try {
			let data = { ...this.props.item };
			data = await this.addStatusData(data);
			data = await this.addInvoiceData(data);
			data = await this.addProposalData(data);
			data = await this.setAdditionalData(data);

			this.setState({
				data: data,
				dataIsLoaded: true,
				loading: false,
				inactive: data.inactive,
				completed: data.completed,
				bypasswip: data.bypasswip,
				status: {
					value: data.defStatusValue,
					label: data.defStatusLabel,
				},
			});
		} catch (error) {
			this.setState({
				loading: false,
				error: true,
			});
			displayAlertError(
				'There was an error getting all the information needed, please try again'
			);
		}
		setTimeout(() => this.enableSortTable(), 500);
	}

	async componentDidMount() {
		await this.loadInitialData();
	}

	async changeSortOrder(objSort) {
		const [key, sort] = Object.entries(objSort)[0];
		this.setState({
			[key]: sort,
		});
		let data = {
			...this.state.data,
			proposals: [...this.state.data.proposals],
			invoices: [...this.state.data.invoices],
		};
		try {
			data =
				key === 'itemInvoiceSort'
					? await this.addInvoiceData(data)
					: await this.addProposalData(data);
			this.setState({
				data,
			});
		} catch {
			displayAlertError(
				'There was an error sorting the information, please try again'
			);
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data || {};

			data = await this.setAdditionalData(data);

			this.setState({
				data: data,
				dataIsLoaded: true,
				inactive: data.inactive,
				completed: data.completed,
				bypasswip: data.bypasswip,
				status: {
					value: data.defStatusValue,
					label: data.defStatusLabel,
				},
			});
		}
	}

	async addStatusData(data) {
		// Status Data
		const statusCodes = await this.api.getStatusCodes('');

		data.defStatusValue = '';
		data.defStatusLabel = 'Specifying';

		let statusList = [];
		for (let status of statusCodes) {
			statusList.push({
				value: status.statusNumber,
				label: status.statusName,
			});

			if (
				!Is.empty(data) &&
				data.statusnumber &&
				data.statusnumber === status.statusNumber
			) {
				data.defStatusValue = status.statusNumber;
				data.defStatusLabel = status.statusName;
			}
		}

		data.statusList = statusList;

		return data;
	}

	async addProposalData(data) {
		// Proposal
		const proposals = await this.api.getItemProposals(
			`?$filter=projectCode eq '${encodeURIComponent(
				this.props.project.proj
			)}' AND itemNumber eq '${this.props.item.item}'&$orderby=${
				this.state.itemPropSort
			}`
		);
		data.proposals = proposals;

		return data;
	}

	async addInvoiceData(data) {
		// Client Invoice
		const invoices = await this.api.getItemInvoices(
			`?$filter=projectCode eq '${encodeURIComponent(
				this.props.project.proj
			)}' AND itemNumber eq '${this.props.item.item}'&$orderby=${
				this.state.itemInvoiceSort
			}`
		);
		data.invoices = invoices;

		return data;
	}

	async setAdditionalData(data) {
		try {
			const totals = await this.api.get(
				`items/${this.props.params.itemId}/status/totals`
			);

			data.invoiceToDate = currencyFormat(totals.invoicedToDate ?? 0);
			data.paymentToDate = currencyFormat(totals.paymentsToDate ?? 0);
			data.balanceDue = currencyFormat(totals.balanceDue ?? 0);
		} catch {
			/* empty */
		}

		return data;
	}

	handleChangePage = () => {
		const sortKey =
			this.state.sortProperty.indexOf('proposalNumber') !== -1
				? 'itemPropSort'
				: 'itemInvoiceSort';

		const sortObj = {
			[sortKey]: this.state.sortProperty,
		};
		this.changeSortOrder(sortObj);
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						self.setState(
							{
								sortProperty: `${_e.attributes['data-field'].value} ${
									hasClass('desc', _e) ? 'asc' : 'desc'
								}`,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;

			let state = this.state;
			state[key] = { value: value, label: e.label };
			this.setState(state);
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}

			let state = this.state;
			state[key] = value;
			this.setState(state);
		}

		this.props.onDataChange({ key: key, value: value });
	};

	renderInputBar() {
		const { data } = this.state;

		return (
			<InputBar className="xlarge">
				<InputBar.Links className="full-width">
					<InputBar.Link>
						<Form.Label className="ilabel">Invoiced To Date</Form.Label>
						<div className="form-group-extra reversed flex-grow-1">
							<Form.Control
								type="text"
								placeholder="0.00"
								size="sm"
								defaultValue={data.invoiceToDate}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label className="ilabel">Payments To Date</Form.Label>
						<div className="form-group-extra reversed flex-grow-1">
							<Form.Control
								type="text"
								placeholder="0.00"
								size="sm"
								defaultValue={data.paymentToDate}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
					<InputBar.Link>
						<Form.Label className="ilabel">Balance Due</Form.Label>
						<div className="form-group-extra reversed flex-grow-1">
							<Form.Control
								type="text"
								placeholder="0.00"
								size="sm"
								defaultValue={data.balanceDue}
							/>
							<span>$</span>
						</div>
					</InputBar.Link>
				</InputBar.Links>
			</InputBar>
		);
	}

	defaultStatus() {
		const { data } = this.state;
		let status = { value: data.defStatusValue, label: data.defStatusLabel };
		if (data.statusList && data.statusList.length > 0) {
			const selectedStatus = data.statusList.filter((status) => {
				return status.value === this.updatedData.statusnumber;
			})[0];
			if (selectedStatus) {
				status = selectedStatus;
			}
		}
		return { value: status.value, label: status.label };
	}

	render() {
		if (this.state.loading) {
			return <Spinner isChild />;
		}
		if (this.state.error) {
			return null;
		}
		const { data, inactive, completed, bypasswip } = this.state;

		return (
			<>
				<Row className="zi-400 position-relative">
					<Col lg={10} className="mb-3 mb-lg-0">
						<Row className="align-items-center mb-3">
							<SecureContent
								attributeNo={14}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec3}
							>
								<SecureContent.HasAccess>
									<Col className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											<strong>Status</strong>
										</Form.Label>
									</Col>
									<Col lg={5} className="mb-3 mb-lg-0">
										<Form.Control
											type="text"
											value={this.defaultStatus().label}
											disabled
										/>
									</Col>
								</SecureContent.HasAccess>
							</SecureContent>
							<Col lg={5}>
								<SecureContent
									attributeNo={14}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec3}
								>
									<SecureContent.HasAccess>
										<Form.Check
											inline
											label="Inactive"
											name="group2"
											type="checkbox"
											id="inactive"
											className="me-2"
											checked={this.updatedData.inactive ?? inactive}
											onChange={this.handleChange}
										/>
										<Form.Check
											inline
											label="Completed"
											name="group2"
											type="checkbox"
											id="completed"
											className="me-2"
											checked={this.updatedData.completed ?? completed}
											onChange={this.handleChange}
										/>
									</SecureContent.HasAccess>
								</SecureContent>
								<Form.Check
									inline
									label="Bypass WIP"
									name="group2"
									type="checkbox"
									id="bypasswip"
									className="me-2"
									checked={this.updatedData.bypasswip ?? bypasswip}
									onChange={this.handleChange}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<div className="bg-secondary-grey my-4 roundedx-4">
					<div className="p-4">
						<h6 className="fw-bold mb-0">Proposal</h6>
					</div>
				</div>

				<div className="table-gradient zi-100 position-relative">
					<Table striped responsive className="a-table">
						<thead>
							<tr className="a-table-heading" key={0}>
								<th>
									<span className="sort active asc" data-field="proposalNumber">
										Proposal/Doc
									</span>
								</th>
								<th>Name</th>
								<th>Date</th>
								<th>Rec. Deposit</th>
								<th>Client Check no.</th>
								<th>Check date</th>
							</tr>
						</thead>
						<tbody>
							{(data.proposals || []).map((item, i) => (
								<tr key={i}>
									<td>{item.proposalNumber}</td>
									<td>{item.proposalName}</td>
									<td>{formatDate(item.proposalDate || '')}</td>
									<td>{currencyFormat(item.receivedDeposit, '$')}</td>
									<td>{item.checkNumber}</td>
									<td>{formatDate(item.checkDate || '')}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>

				<div className="bg-secondary-grey my-4 roundedx-4">
					<div className="p-4">
						<h6 className="fw-bold mb-0">Client Invoice</h6>
					</div>
				</div>

				<div className="table-gradient zi-100 position-relative">
					<Table striped responsive className="a-table">
						<thead>
							<tr className="a-table-heading" key={0}>
								<th>
									<span className="sort active asc" data-field="invoiceNumber">
										Inv. No.
									</span>
								</th>
								<th className="mw-120px">Date</th>
								<th>Qty.</th>
								<th>Amount</th>
								<th>Deposit App</th>
								<th>Sales Tax</th>
								<th>Total</th>
								<th>Payment</th>
								<th>Client CK No.</th>
								<th>Bal. Due</th>
								<th>Merchandise</th>
								<th>Freight</th>
							</tr>
						</thead>
						<tbody>
							{(data.invoices || []).map((item, i) => (
								<tr key={i}>
									<td>{item.invoiceNumber}</td>
									<td>{formatDate((item.invoiceDate || '').split('T')[0])}</td>
									<td>{item.quantity}</td>
									<td className="align-right">
										{currencyFormat(item.amount, '$')}
									</td>
									<td className="align-right">
										{currencyFormat(item.depositApplied, '$')}
									</td>
									<td className="align-right">
										{currencyFormat(item.salesTax, '$')}
									</td>
									<td className="align-right">
										{currencyFormat(item.total, '$')}
									</td>
									<td className="align-right">
										{currencyFormat(item.payment, '$')}
									</td>
									<td>{item.clientCheckNumber}</td>
									<td className="align-right">
										{currencyFormat(item.balanceDue, '$')}
									</td>
									<td className="align-right">
										{currencyFormat(item.compType0Amount, '$')}
									</td>
									<td className="align-right">
										{currencyFormat(item.compType1Amount, '$')}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>

				<div className="mt-4">{this.renderInputBar()}</div>
			</>
		);
	}
}

export default WithRouter(ItemAddStatus);
