import { useCallback } from 'react';

export const useExternalNavigate = () => {
	const navigateToExternalUrl = useCallback((url: string, newTab = false) => {
		if (newTab) {
			window.open(url, '_blank');
		} else {
			window.location.href = url;
		}
	}, []);

	return navigateToExternalUrl;
};
