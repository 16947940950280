import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Container, Button } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import EmployeesAddInfo from './EmployeesAddInfo';
import { FooterFormAction } from '../../components/Form';
import { getActiveMenuUri, WithRouter } from '../../../helpers/Router';
import URI from '../../../defaults/RoutesDefault';
import { compareStr } from '../../../helpers/String';
import { HeaderLight } from '../../components/Header';
import { getSubComponent } from '../../../helpers/Util';
import EmployeesAddNotes from './EmployeesAddNotes';
import EmployeesAddPOS from './EmployeesAddPOS';
import EmployeesAddTime from './EmployeesAddTime';
import { ApiService } from '../../../lib/api/HttpService';

class EmployeesAdd extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['info', 'notes', 'time', 'showroom'],
				'info'
			),
			data: {},
			addressesTypes: [],
		};

		this.api = new ApiService();

		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentInit() {
		this.title =
			typeof this.props.params.id !== 'undefined'
				? 'Edit Employee'
				: 'Add Employee';
		window.localStorage.removeItem('employee');
		window.localStorage.removeItem('address');
	}

	async componentDidMount() {
		let employees = [];
		let address = [];

		if (typeof this.props.params.id !== 'undefined') {
			const { id } = this.props.params;
			employees = await this.api.getClientEmployees(`?$filter=id eq ${id}`);
			address = await this.api.get(
				'addresses',
				`?$filter=(addresstype eq 3) and (code eq '${employees[0].vendor}')`
			);
		}

		let addressesData = await this.api.getAddresses();
		let addresses = [];
		let addressesTypes = [];
		// let addType = {};
		for (let addr of addressesData) {
			if (
				addr.addresstype === 2 &&
				addr.typedesc != null &&
				addr.typedesc !== ''
			) {
				addresses.push({ value: addr.id, label: addr.sortname });
				// addType = { value: addr.typedesc, label: addr.typedesc };
				if (addressesTypes.indexOf(addr.typedesc) === -1)
					addressesTypes.push(addr.typedesc);

				//   if(emp.vendor==client.manager) client.managern=emp.vendorn;
			}
		}
		addressesTypes.sort();
		for (let i = 0; i < addressesTypes.length; i++) {
			addressesTypes[i] = {
				value: addressesTypes[i],
				label: addressesTypes[i],
			};
		}

		this.renderData(employees[0], addressesTypes, address[0]);
	}

	handleSave = (e, attr) => {
		let employee = JSON.parse(window.localStorage.getItem('employee')) || null;
		if (employee) {
			this.api.patch('employees', employee.id, employee);
			window.localStorage.removeItem('employee');
		}

		let address = JSON.parse(window.localStorage.getItem('address')) || null;
		if (address) {
			this.api.patch('addresses', address.id, address);
			window.localStorage.removeItem('address');
		}
	};

	handleMenuClick = (e, attr) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	renderData(employees, addressesTypes, address) {
		let employeesData = employees;
		employeesData.address = address;
		this.setState({
			data: employeesData,
			addressesTypes: addressesTypes,
		});
	}

	renderHeader() {
		const { activeMenu } = this.state;
		const { data } = this.state;
		const nameBr =
			typeof data != 'undefined' && Object.keys(data).length
				? 'Edit Employee'
				: 'Add Employee';
		const name =
			typeof data != 'undefined' && Object.keys(data).length
				? data.vendorn
				: 'Add Employee';

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{ to: URI.contact.employee.base }}
							linkAs={Link}
						>
							Employees
						</Breadcrumb.Item>
						<Breadcrumb.Item>{nameBr}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{name}</HeaderLight.Title>

					{/* Submit Button */}
					<Button variant="primary" onClick={this.handleSave}>
						Save
					</Button>
				</HeaderLight.Content>
				<HeaderLight.Actions>
					<li>
						<Link
							to={URI.contact.employee.add}
							data-menu="info"
							className={
								`btn text-charcoal ` +
								compareStr(activeMenu, 'info', 'active', '')
							}
							onClick={this.handleMenuClick}
						>
							Info
						</Link>
					</li>
					<li>
						<Link
							to={URI.contact.employee.add}
							data-menu="notes"
							className={
								`btn text-charcoal ` +
								compareStr(activeMenu, 'notes', 'active', '')
							}
							onClick={this.handleMenuClick}
						>
							Notes
						</Link>
					</li>
					<li>
						<Link
							to={URI.contact.employee.add}
							data-menu="time"
							className={
								`btn text-charcoal ` +
								compareStr(activeMenu, 'time', 'active', '')
							}
							onClick={this.handleMenuClick}
						>
							Time
						</Link>
					</li>
					<li>
						<Link
							to={URI.contact.employee.add}
							data-menu="showroom"
							className={
								`btn text-charcoal ` +
								compareStr(activeMenu, 'showroom', 'active', '')
							}
							onClick={this.handleMenuClick}
						>
							POS/Showroom
						</Link>
					</li>
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	renderContent() {
		return getSubComponent(this.state.activeMenu, {
			info: (
				<EmployeesAddInfo
					addressesTypes={this.state.addressesTypes}
					data={this.state.data}
				/>
			),
			notes: <EmployeesAddNotes data={this.state.data} />,
			time: <EmployeesAddTime data={this.state.data} />,
			showroom: <EmployeesAddPOS data={this.state.data} />,
		});
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid>{this.renderContent()}</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Button variant="primary" onClick={this.handleSave} size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(EmployeesAdd);
