import React from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import URI from '../../../../defaults/RoutesDefault';
import Header from '../../../components/Header';
import { compareStr } from '../../../../helpers/String';
import { getSubComponent, Is } from '../../../../helpers/Util';
import WorkflowSettingsGeneral from './WorkflowSettingsGeneral';
import WorkflowSettingsPrint from './WorkflowSettingsPrint';
import WorkflowSettingsTime from './WorkflowSettingsTime';
import { ApiService } from '../../../../lib/api/HttpService';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import { handleResponseAlert } from '../../../../utilities/Response';
import WorkflowSettingsProposals from './WorkflowSettingsProposals';
import WorkflowSettingsInvoices from './WorkflowSettingsInvoices';
import WorkflowSettingsPoWoOrders from './WorkflowSettingsPoWoOrders';
import WorkflowSettingsSchedule from './WorkflowSettingsSchedule';
import Budget from '../../budget/Budget';
import { Constants } from '../../../../app/constants/Constants';

class WorkflowSettingsMain extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			workflowData: {},
			isLoading: false,
		};

		this.api = new ApiService();
	}

	async componentDidMount() {
		this.fetchData();
	}

	async fetchData() {
		this.dMloader(true);
		let data = {};

		const company1 = await this.api.get('company', '');
		const company2 = await this.api.get('company2', '');

		this.resetLocalStorage();

		data.company1 = company1 || {};
		data.company2 = company2 || {};

		data = await this.getLocationsOptionsAndDefault(data);
		data = await this.getSalesCategoryOptionsAndDefault(data);

		this.setData(data);
	}

	async getLocationsOptionsAndDefault(data) {
		if (data.company1?.timeloccode) {
			const selectedLocation = await this.api.getLocationByCode(
				data.company1.timeloccode,
				'',
				true
			);
			data.selectedLocation = selectedLocation;
		}

		return data;
	}

	async getSalesCategoryOptionsAndDefault(data) {
		if (data.company1?.timescatcode) {
			const selectedSalesCategory = await this.api.getSalesCategoryByCode(
				data.company1.timescatcode,
				true
			);
			data.selectedSalesCategory = selectedSalesCategory;
		}

		return data;
	}

	setData(workflowData) {
		this.setState({
			workflowData,
		});
		// Set local data.
		setLocalStorage('workflowCompanySettings', workflowData.company1, true);
		setLocalStorage('workflowCompany2Settings', workflowData.company2, true);
	}

	resetLocalStorage() {
		// Remove local data.
		setLocalStorage('workflowCompanySettings', {}, true);
		setLocalStorage('workflowCompany2Settings', {}, true);
	}

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	handleSaveClick = (e) => {
		const company1 = getLocalStorage('workflowCompanySettings', true);
		const company2 = getLocalStorage('workflowCompany2Settings', true);

		const request1 = this.api.patch('company/update', '', company1);
		const request2 = this.api.patch('company2', '', company2);
		this.setIsLoading(true);

		this.setNewData();

		handleResponseAlert(request1, () => {
			handleResponseAlert(request2, () => {
				this.setIsLoading(false);
			});
		});
	};

	setNewData() {
		this.setState(async (prev) => {
			const company1 = getLocalStorage('workflowCompanySettings', true);

			// Time
			const defTimeLoc = await this.api.getLocationByCode(
				company1.timeloccode,
				'',
				true
			);
			if (!Is.empty(defTimeLoc)) {
				prev.workflowData.defaultTimeLocation = defTimeLoc;
			}

			const defSalesCat = await this.api.getSalesCategoryByCode(
				company1.timescatcode,
				true
			);
			if (!Is.empty(defSalesCat)) {
				prev.workflowData.defaultSalesCategory = defSalesCat;
			}

			return prev;
		});
	}

	renderHeader() {
		const { page } = this.props;
		const { isLoading } = this.state;

		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{ to: URI.settings.list }}
									linkAs={Link}
								>
									Settings
								</Breadcrumb.Item>
								<Breadcrumb.Item>Workflow Settings</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Workflow Settings</Header.Title>
					</Header.LeftContent>
					<Header.Actions list={true}>
						<li>
							{/* <Button variant='light' size='lg' className='btn-icon text-secondary-green' onClick={this.handleSaveClick.bind(this)} disabled={isLoading}>
                                {getSaveButtonName(isLoading, 'Save')}
                            </Button> */}
						</li>
					</Header.Actions>
				</Header.TitleContent>
				<Header.Buttons>
					<li>
						<NavLink
							to={URI.settings.workflowSettings.general}
							className={`btn text-white ${compareStr(
								page,
								'general',
								'active',
								''
							)}`}
						>
							General
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.settings.workflowSettings.print}
							className={`btn text-white ${compareStr(
								page,
								'print',
								'active',
								''
							)}`}
						>
							Print
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.settings.workflowSettings.time}
							className={`btn text-white ${compareStr(
								page,
								'time',
								'active',
								''
							)}`}
						>
							Time
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.settings.workflowSettings.proposals}
							className={`btn text-white ${compareStr(
								page,
								'proposals',
								'active',
								''
							)}`}
						>
							Proposals
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.settings.workflowSettings.invoices}
							className={`btn text-white ${compareStr(
								page,
								'invoices',
								'active',
								''
							)}`}
						>
							Invoices
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.settings.workflowSettings.powoorders}
							className={`btn text-white ${compareStr(
								page,
								'powoorders',
								'active',
								''
							)}`}
						>
							PO/WO Orders
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.settings.workflowSettings.budget}
							className={`btn text-white ${compareStr(
								page,
								'budget',
								'active',
								''
							)}`}
						>
							Budget
						</NavLink>
					</li>
				</Header.Buttons>
			</Header>
		);
	}

	renderComponentIfPossible(component) {
		if (Object.keys(this.state.workflowData).length) {
			return component;
		} else {
			return <></>;
		}
	}

	renderContent() {
		return getSubComponent(this.props.page, {
			general: <WorkflowSettingsGeneral data={this.state.workflowData} />,
			print: <WorkflowSettingsPrint data={this.state.workflowData} />,
			time: <WorkflowSettingsTime data={this.state.workflowData} />,
			proposals: this.renderComponentIfPossible(
				<WorkflowSettingsProposals data={this.state.workflowData} />
			),
			invoices: this.renderComponentIfPossible(
				<WorkflowSettingsInvoices data={this.state.workflowData} />
			),
			powoorders: this.renderComponentIfPossible(
				<WorkflowSettingsPoWoOrders data={this.state.workflowData} />
			),
			schedule: this.renderComponentIfPossible(
				<WorkflowSettingsSchedule data={this.state.workflowData} />
			),
			budget: this.renderComponentIfPossible(
				<Budget
					proj={`${Constants.DEFAULT_PROJECT_ID}`}
					company={this.state.workflowData.company1}
				/>
			),
		});
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(WorkflowSettingsMain);
