import { useQueries, useMutation, useQueryClient } from '@tanstack/react-query';

import { getCompany, getCompany2, patchCompanySettings } from './api';

export const DEFAULT_TIMEZONE_CODE = 5;

export const useCompanySettings = (options = {}) => {
	const { optionsCompany1 = {} } = options;
	const { optionsCompany2 = {} } = options;
	const settingsQueries = useQueries({
		queries: [
			{
				queryKey: ['settings', 'company', 1],
				queryFn: getCompany,
				select: ({
					name,
					addr1,
					addr2,
					addr3,
					city,
					state,
					zip,
					phone,
					fax,
					email,
					website,
					ein,
				}) => ({
					name,
					addr1,
					addr2,
					addr3,
					city,
					state,
					zip,
					phone,
					fax,
					email,
					website,
					ein,
				}),
				...optionsCompany1,
			},
			{
				queryKey: ['settings', 'company', 2],
				queryFn: getCompany2,
				select: ({ logoImageId, timeZoneCode }) => {
					const tzc = timeZoneCode ?? DEFAULT_TIMEZONE_CODE;
					return {
						logoImageId,
						timeZoneCode: tzc,
						hasTimeZone: timeZoneCode !== null,
					};
				},
				...optionsCompany2,
			},
		],
	});

	const isLoading =
		settingsQueries.filter((q) => {
			return q.isLoading === true && q.isFetching;
		}).length > 0;

	if (isLoading) {
		return { isLoading, settings: {} };
	}

	const settings = settingsQueries.reduce(
		(prev, current) => ({ ...prev, ...current.data }),
		{}
	);

	return settings;
};

export const useMutateCompanySettings = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: patchCompanySettings,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['settings', 'company'],
			});
		},
	});

	return mutation;
};
