export enum CompTypes {
	ctMerchandise = 0,
	ctDesignFee = 1,
	ctFreight = 2,
	ctInstallation = 3,
	ctLabor = 4,
	ctOther = 5,
	ctTime = 5,
	ctTotal = 6,
}
