import React from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	FloatingLabel,
	Button,
	NavLink,
} from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';

import { ApiService } from '../../../lib/api/HttpService';
import URI from '../../../defaults/RoutesDefault';
import { generatePath } from 'react-router-dom';
import { displayAlert } from '../../../utilities/Response';

import logo from '../../../assets/images/logo.svg';
import logoDark from '../../../assets/images/logo-dark.svg';

import briaHammel from '../../../assets/images/bria-hammel.png';
import thomFilicia from '../../../assets/images/thom-filicia.png';
import libbyLangdon from '../../../assets/images/libby-langdon.png';

class ResetPassword extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: '',
			showErrorMessage: false,
			allowReset: false,
			isVerifying: true,
		};

		this.api = new ApiService();
		this.passwordData = {};
	}

	async componentDidMount() {
		const searchParams = new URLSearchParams(document.location.search);
		const token = searchParams.get('token');

		await this.api
			.getResetPassword(token)
			.then((resp) => {
				if (!resp.tokenExpired && !resp.invalidToken) {
					this.setState({
						allowReset: true,
						isVerifying: false,
					});
					this.passwordData = resp;
				} else {
					this.displayInvalidLink();
				}
			})
			.catch((err) => {
				this.displayInvalidLink();
			});
	}

	displayInvalidLink() {
		this.setState({
			allowReset: false,
			isVerifying: false,
		});

		window.onbeforeunload = null;
		setTimeout(() => {
			window.location.href = generatePath(URI.login.base);
		}, 2000);
	}

	handleChangePassword = async (e) => {
		if (this.passwordData.password !== this.passwordData.confirmPassword) {
			displayAlert('danger', 'Passwords do not match');
		} else {
			this.passwordData.invalidToken = true;
			this.passwordData.tokenExpired = true;

			await this.api
				.postResetPassword(this.passwordData)
				.then((resp) => {
					displayAlert('success', 'Password reset successful');
					window.onbeforeunload = null;
					setTimeout(() => {
						window.location.href = generatePath(URI.login.base);
					}, 2000);
				})
				.catch((error) => {
					const errorMessage =
						error.response?.data?.userError || 'Unable to reset password';
					displayAlert('danger', errorMessage);
				});
		}
	};

	handleChange = (e) => {
		let key, value;
		if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			}
		}
		this.passwordData[key] = value;
	};

	renderSpinner() {
		return (
			<div className="flex-grow-1 loginRight-form">
				<div>
					<h1 className="fsx-40 mb-4 text-center">Verification in Progress</h1>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: '10vh' }}
					>
						<div
							className="spinner-border"
							style={{ width: '3rem', height: '3rem' }}
							role="status"
						></div>
					</div>
					<p className="text-secondary-ash fsx-16 mb-4 text-center">
						Please wait while we validate the link.
					</p>
				</div>
			</div>
		);
	}

	renderExpiredLink() {
		return (
			<div className="flex-grow-1 loginRight-form">
				<div>
					<h1 className="fsx-40 mb-4 text-center">Link is no longer valid</h1>
					<div
						className="d-flex justify-content-center align-items-center"
						style={{ height: '10vh' }}
					>
						<div
							className="spinner-border"
							style={{ width: '3rem', height: '3rem' }}
							role="status"
						></div>
					</div>
					<p className="text-secondary-ash fsx-16 mb-4 text-center">
						Redirecting to login page.
					</p>
				</div>
			</div>
		);
	}

	render() {
		return (
			<>
				<Container fluid className="loginContainer d-lg-flex">
					<div className="p-4 mb-5 d-lg-none">
						<img
							src={logoDark}
							width="100%"
							height="auto"
							className="logo"
							alt=""
						/>
					</div>
					<Row className="w-100 justify-content-center">
						<Col lg={4} className="d-none d-lg-flex loginLeft flex-column">
							<div className="p-4 flex-grow-1">
								<img
									src={logo}
									width="100%"
									height="auto"
									className="logo"
									alt=""
								/>
							</div>
							<div className="loginLeft-footer">
								<div className="mb-3">
									TRUSTED BY THOUSANDS OF INTERIOR DESIGNERS
								</div>

								<div className="logos px-4 pb-4">
									<img src={briaHammel} alt="" />

									<img src={thomFilicia} alt="" />

									<img src={libbyLangdon} alt="" />
								</div>
							</div>
						</Col>

						<Col lg={8} className="d-flex loginRight flex-column">
							<div className="flex-grow-1 loginRight-form">
								{(() => {
									if (!this.state.isVerifying && this.state.allowReset) {
										return (
											<div>
												<h1 className="fsx-40 mb-4">Reset Your Password</h1>

												<div className="d-grid gap-3">
													<FloatingLabel
														controlId="floatingInput"
														label="Password"
													>
														<Form.Control
															type="password"
															placeholder="Email address"
															size="lg"
															name="password"
															onChange={this.handleChange}
														/>
													</FloatingLabel>
													<FloatingLabel
														controlId="floatingInput"
														label="Confirm password"
													>
														<Form.Control
															type="password"
															placeholder="Email address"
															size="lg"
															name="confirmPassword"
															onChange={this.handleChange}
														/>
													</FloatingLabel>

													<Button
														variant="primary"
														size="lg"
														onClick={this.handleChangePassword}
													>
														Change Password
													</Button>
												</div>
											</div>
										);
									} else if (
										!this.state.isVerifying &&
										!this.state.allowReset
									) {
										return this.renderExpiredLink();
									} else {
										return this.renderSpinner();
									}
								})()}
							</div>
						</Col>
					</Row>
				</Container>
				<div id="response-alert-div"></div>
			</>
		);
	}
}

export default ResetPassword;
