import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import WysiwygEditor from '../../ui-kit/WysiwygEditor';
import ThreadComponent from '../ThreadComponent';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../utilities/LocalStorage';

class EmployeesAddNotes extends ThreadComponent {
	componentInit() {
		this.hasFormAction = true;
	}

	handleChange = (value) => {
		const employee = getLocalStorage('employee', true);

		if (employee) {
			employee.id = (this.props.data || {}).id;
			employee.notes = value;
			setLocalStorage('employee', employee, true);
		}
	};

	render() {
		return (
			<>
				<Container className="ms-0">
					<Row>
						<Col lg="7">
							<WysiwygEditor onChange={this.handleChange}>
								{(this.props.data || {}).notes}
							</WysiwygEditor>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

export default WithRouter(EmployeesAddNotes);
