import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

type TFinanceChargeVoidProps = {
	showModal: boolean;
	onToggleModal: () => void;
	confirmAction: () => void;
};

export const FinanceChargesVoid = ({
	showModal,
	onToggleModal,
	confirmAction,
}: TFinanceChargeVoidProps) => {
	return (
		<ConfirmationModal
			title="Warning"
			message="Are you sure that you want to void this entire charge?"
			labelOK="Yes"
			labelCancel="No"
			show={showModal}
			confirmAction={confirmAction}
			toggleModal={() => {
				onToggleModal();
			}}
		/>
	);
};

FinanceChargesVoid.displayName = 'FinanceChargesVoid';
