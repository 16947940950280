import Select from '../form/Select';
import React from 'react';
import { TTypeOption } from './types/TTypeOption';

export type TBaseDropdownProps = {
	options?: TTypeOption[];
	value: TTypeOption | null;
	isDisabled?: boolean;
	isLoading?: boolean;
	defaultValue?: TTypeOption;
	height?: number;
	isCreatable?: boolean;
	onCreateOption?: (value: string) => void;
	placeholder?: string;
	inputId?: string;
	blurInputOnSelect?: boolean;
	onChange?: (newValue: TTypeOption | null) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
	isClearable?: boolean;
};

const BaseDropdown = ({
	options = [],
	value,
	inputId,
	defaultValue,
	isDisabled,
	isCreatable,
	onCreateOption,
	isLoading,
	height,
	blurInputOnSelect,
	placeholder = 'Please select',
	onChange,
	onBlur,
	isClearable = false,
}: TBaseDropdownProps) => {
	const { value: selectedValue } = value || {};

	return (
		<Select
			isCreatable={isCreatable}
			onCreateOption={onCreateOption}
			styles={{
				menu: (base) => ({ ...base, zIndex: 9999 }),
				control: (base) => ({
					...base,
					height: height || 41,
					minHeight: height || 41,
				}),
			}}
			inputId={inputId}
			blurInputOnSelect={blurInputOnSelect}
			value={
				options.find((cat) => cat.value === selectedValue) ||
				defaultValue ||
				null
			}
			onChange={(newValue) => {
				onChange?.((newValue as TTypeOption) || null);
			}}
			placeholder={placeholder}
			isDisabled={isLoading || isDisabled}
			onBlur={onBlur}
			options={isLoading ? [{ label: 'Loading...', value: '' }] : options}
			isClearable={isClearable}
		/>
	);
};

BaseDropdown.displayName = 'BaseDropdown';

export default BaseDropdown;
