import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../utilities/LocalStorage';
import { ApiService } from '../../../lib/api/HttpService';

class EmployeesAddInfo extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			employee: [],
			selectData: [
				{ value: 'option-1', label: 'Option 1' },
				{ value: 'option-2', label: 'Option 2' },
				{ value: 'option-3', label: 'Option 3' },
			],
			selectSortType: [
				{ value: 'Name', label: 'Name' },
				{ value: 'Company', label: 'Company' },
				{ value: 'Manual', label: 'Manual' },
			],
		};
	}

	componentInit() {
		this.title = 'Add Employees';
		this.setFormAction(true);
	}

	checkChange = (type, id, e, select) => {
		let key = '';
		let value = '';

		if (select) {
			key = e.name;
			value = select.value;
		} else if (e.target.getAttribute('type') === 'checkbox') {
			key = e.target.name;
			value = e.target.checked ? true : false;
		} else {
			key = e.target.name;
			value = e.target.value;
		}

		this.syncToStorage(type, id, key, value);
	};

	handleEmployeeChange = (e, select) => {
		this.checkChange('employee', (this.props.data || {}).id, e, select);
	};

	handleAddressChange = (e, select) => {
		this.checkChange('address', (this.props.data.address || {}).id, e, select);
	};

	syncToStorage = (type, id, key, value) => {
		let data = getLocalStorage(type, true);
		data['id'] = id;
		data[key] = value;
		setLocalStorage(type, data, true);
	};

	render() {
		return (
			<>
				<Form.Group controlId="formGridText1">
					<Row xs={1} lg={2}>
						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Code</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col lg={6}>
											<Form.Control
												type="text"
												placeholder="Please enter"
												defaultValue={(this.props.data || {}).vendor}
												readOnly
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Name</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data || {}).vendorn}
										onChange={this.handleEmployeeChange}
										name="vendorn"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Sort</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data || {}).sortName}
										onChange={this.handleEmployeeChange}
										name="sortName"
									/>
								</Col>
								<Col>
									<Select
										options={this.state.selectSortType}
										className="react-select"
										placeholder="Sort Type"
										onChange={this.handleEmployeeChange.bind(this, {
											name: 'parsetype',
										})}
										name="parsetype"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Type</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col lg={6}>
											<Select
												options={this.props.addressesTypes}
												className="react-select"
												placeholder="Please select"
												onChange={this.handleEmployeeChange.bind(this, {
													name: 'typedesc',
												})}
												name="typedesc"
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
								<Col lg={6}>
									<Form.Check
										inline
										label="Inactive"
										name="inactive"
										type="checkbox"
										id={`inline-radio-3`}
										className="checkbox"
										defaultChecked={(this.props.data || {}).inactive}
										onClick={this.handleEmployeeChange}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										<strong>Contact Information</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col className="mb-3 mb-lg-0">
							<Row className="mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Address</Form.Label>
								</Col>
								<Col>
									<Form.Control
										as="textarea"
										placeholder="Please enter"
										style={{ height: '100px' }}
										defaultValue={(this.props.data.address || {}).addr}
										onChange={this.handleAddressChange}
										name="addr"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">City</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data.address || {}).city}
										onChange={this.handleAddressChange}
										name="city"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">State</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data.address || {}).state}
										onChange={this.handleAddressChange}
										name="state"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Zip Code</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data.address || {}).zip}
										onChange={this.handleAddressChange}
										name="zip"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Contact</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data.address || {}).contact}
										onChange={this.handleAddressChange}
										name="contact"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data.address || {}).contactphone}
										onChange={this.handleAddressChange}
										name="contactphone"
									/>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">SSN</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data || {}).ein}
										onChange={this.handleEmployeeChange}
										name="ein"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">PO Designer/Ext.</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data || {}).designer}
										onChange={this.handleEmployeeChange}
										name="designer"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Email</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data.address || {}).email}
										onChange={this.handleAddressChange}
										name="email"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Website</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Please enter"
										defaultValue={(this.props.data.address || {}).website}
										onChange={this.handleAddressChange}
										name="website"
									/>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 2</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col lg={6} className="mb-3 mb-lg-0">
											<Form.Control
												type="text"
												placeholder="Phone 2 Number"
												defaultValue={(this.props.data.address || {}).phone1}
												onChange={this.handleAddressChange}
												name="phone1"
											/>
										</Col>
										<Col lg={6}>
											<Form.Control
												type="text"
												placeholder="Phone 2 Description"
												defaultValue={
													(this.props.data.address || {}).phonedesc1
												}
												onChange={this.handleAddressChange}
												name="phonedesc1"
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 3</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col lg={6} className="mb-3 mb-lg-0">
											<Form.Control
												type="text"
												placeholder="Phone 3 Number"
												defaultValue={(this.props.data.address || {}).phone2}
												onChange={this.handleAddressChange}
												name="phone2"
											/>
										</Col>
										<Col lg={6}>
											<Form.Control
												type="text"
												placeholder="Phone 3 Description"
												defaultValue={
													(this.props.data.address || {}).phonedesc2
												}
												onChange={this.handleAddressChange}
												name="phonedesc2"
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form.Group>
			</>
		);
	}
}

export default WithRouter(EmployeesAddInfo);
