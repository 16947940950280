export function formatMoney(amount) {
	if (!amount) return '$0.00';

	const USDollar = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return USDollar.format(amount);
}

export function formatDate(date) {
	if (!date) return '';

	const [dateString] = date.split('T');
	const [year, month, day] = dateString.split('-');
	return `${month}/${day}/${year}`;
}

export function formatFiscalMonth(date) {
	const [dateString] = date.split('T');
	const [year, month] = dateString.split('-');
	return `${month}/${year}`;
}

export function normalize(str) {
	if (!str) return '';
	return String(str).toLowerCase().trim();
}

export function searchData(needle, field, haystack) {
	if (needle === '') return haystack;

	return haystack.filter((row) => {
		if (normalize(row[field]).includes(normalize(needle))) {
			return true;
		}

		return false;
	});
}

export function formatNumber(value) {
	return value.toFixed(2);
}

export const TRANSACTION_TYPES = {
	INVOICE: { MIN_VALUE: 1000, MAX_VALUE: 1399 },
	CHECK: { MIN_VALUE: 1400, MAX_VALUE: 1499 },
	DEPOSIT: { MIN_VALUE: 2000, MAX_VALUE: 2999 },
	JOURNAL_ENTRY: { MIN_VALUE: 8000, MAX_VALUE: 8999 },
};

export function isValidType(type) {
	const isVendorInvoice =
		type >= TRANSACTION_TYPES.INVOICE.MIN_VALUE &&
		type <= TRANSACTION_TYPES.INVOICE.MAX_VALUE;
	const isCheckbookCheck =
		type >= TRANSACTION_TYPES.CHECK.MIN_VALUE &&
		type <= TRANSACTION_TYPES.CHECK.MAX_VALUE;
	const isCheckbookDeposit =
		type >= TRANSACTION_TYPES.DEPOSIT.MIN_VALUE &&
		type < TRANSACTION_TYPES.DEPOSIT.MAX_VALUE;
	const isCheckbookJournalEntry =
		type >= TRANSACTION_TYPES.JOURNAL_ENTRY.MIN_VALUE &&
		type <= TRANSACTION_TYPES.JOURNAL_ENTRY.MAX_VALUE;

	return (
		isVendorInvoice ||
		isCheckbookCheck ||
		isCheckbookDeposit ||
		isCheckbookJournalEntry
	);
}
