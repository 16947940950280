import Label from 'legacy/app/components/label/Label';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { TFormValues } from '../types/TFormValues';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import TextEditor from 'legacy/app/components/textEditor/TextEditor';

const SpecialIntructionsForm = () => {
	const methods = useFormContext<TFormValues>();
	const { setValue, watch } = methods;
	const sidemarkInput = methods.register('sidemark');
	const cfarequiredInput = methods.register('cfarequired');

	useEffect(() => {
		return () => {
			setValue(
				'initialSpecialInstructionsDescription',
				watch('specinsrtf').html
			);
		};
	}, [setValue, watch]);

	return (
		<>
			<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
				<p className="tw-text-base tw-font-bold tw-m-0">Special Instructions</p>
			</div>
			<div className="tw-gap-8 tw-gap-y-6 tw-py-6 tw-pl-10 tw-grid tw-grid-cols-[150px_500px] tw-max-w-[1200px]">
				<Label label="Instructions" className="tw-pt-4 tw-justify-self-end" />

				<Controller
					control={methods.control}
					name="specinsrtf"
					render={({ field }) => (
						<TextEditor
							initialData={methods.watch(
								'initialSpecialInstructionsDescription'
							)}
							onChange={(e) => field.onChange(e.target)}
						/>
					)}
				/>
				<Label label="PO Sidemark" className="tw-pt-4 tw-justify-self-end" />
				<div>
					<Form.Control
						id="PO Sidemark"
						name={sidemarkInput.name}
						placeholder="Please enter"
						ref={sidemarkInput.ref}
						onChange={sidemarkInput.onChange}
					/>
				</div>

				<div className="tw-col-end-[-1] tw-flex tw-items-center">
					<Form.Check
						label="CFA Required"
						ref={cfarequiredInput.ref}
						name="cfarequired"
						onChange={cfarequiredInput.onChange}
						type="checkbox"
					/>
				</div>
				<Label
					label="Ship On or Before"
					className="tw-self-center tw-justify-self-end"
				/>
				<div>
					<Controller
						control={methods.control}
						name="shipby"
						render={({ field }) => (
							<DatePicker
								selected={field.value}
								onChange={field.onChange}
								showMonthDropdown
								showYearDropdown
								className="form-control form-control-sm"
								placeholderText="Select"
							/>
						)}
					/>
				</div>
			</div>
		</>
	);
};

SpecialIntructionsForm.displayName = 'SpecialIntructionsForm';

export default SpecialIntructionsForm;
