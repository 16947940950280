import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { IClippedItem } from '../types/ClippedItems';
import ClipperFallback from '../../../resources/assets/images/clipper-fallback.svg';

const api = new ApiService();

const getClippedItems = async (filter = '') => {
	const response = await api.getClippedItems(filter);
	const items = response?.list;

	const itemsWithImages = await Promise.all(
		items.map(async (item: IClippedItem) => {
			const imageUrl = await fetchImageUrl(item.primaryImageId);
			return { ...item, imageUrl };
		})
	);

	return { ...response, list: itemsWithImages };
};

const fetchImageUrl = async (primaryImageId?: string): Promise<string> => {
	if (primaryImageId) {
		const response = await api.getFile(primaryImageId);
		return URL.createObjectURL(response as Blob);
	}
	return ClipperFallback;
};

const useGetClippedItems = (filter = '') =>
	useQuery(['clippedItems', filter], () => getClippedItems(filter), {
		refetchInterval: false,
		refetchOnWindowFocus: false,
	});

export default useGetClippedItems;
