import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ApiService } from '../../../lib/api/HttpService';
import AsyncSalesCategoriesDropdown from '../../../app/components/dropdowns/AsyncSalesCategoriesDropdown';
import AsyncLocationsDropdown from '../../../app/components/dropdowns/AsyncLocationsDropdown';
import {
	displayAlertLoader,
	hideAlertLoader,
} from '../../../utilities/Response';
import { BudgetWarnings, BudgetTypes } from 'legacy/app/enums/budgets/budgets';
import { FLOAT, DECIMAL_MAX_TWO_FRACTIONS } from 'legacy/app/regex/budgets';

const BUDGET_WARNING_OPTIONS = [
	{ value: BudgetWarnings.None, label: 'None' },
	{ value: BudgetWarnings.DisplayWarning, label: 'Display Warning' },
	{ value: BudgetWarnings.BlockEntry, label: 'Block Entry' },
];

const BudgetAddEditModal = ({
	title,
	budget,
	company,
	proj,
	projId,
	onCancel,
	onConfirm,
}) => {
	const [type, setType] = useState(budget.type);
	const [code, setCode] = useState(undefined);
	const [data, setData] = useState(budget);

	const components = [
		{ label: 'Merchandise', key: 'm' },
		{ label: 'Freight', key: 'd' },
		{ label: 'Design Fee', key: 'f' },
		{ label: 'Install/Labor', key: 'i' },
		{ label: 'Crating', key: 'l' },
		{ label: 'Time', key: 'o' },
	];
	const markups = [
		{ label: 'Merchandise', key: 'merc', shortKey: 'm' },
		{ label: 'Freight', key: 'design', shortKey: 'd' },
		{ label: 'Design Fee', key: 'freight', shortKey: 'f' },
		{ label: 'Install/Labor', key: 'install', shortKey: 'i' },
		{ label: 'Crating', key: 'labor', shortKey: 'l' },
	];
	const markupTypes = [
		{ value: 0, label: 'Markup' },
		{ value: -1, label: 'Discount' },
		{ value: 1, label: 'Fee' },
	];

	useEffect(() => {
		fetchData();
	}, []);

	const fetchSalesCategories = async () => {
		let salesCategories = await new ApiService().getSalesCategories();
		salesCategories = salesCategories.map((salesCategory) => {
			return {
				value: salesCategory.scat,
				label: `${salesCategory.scatn} [${salesCategory.scat}]`,
			};
		});

		return salesCategories;
	};

	const fetchLocations = async () => {
		let locations = await new ApiService().getLocations();
		locations = locations.map((location) => {
			return {
				value: location.loc,
				label: `${location.locn} [${location.loc}]`,
			};
		});

		return locations;
	};

	const fetchCodeIfNeeded = async (salesCategories, locations) => {
		if (data.type === BudgetTypes.SalesCategory) {
			let salesCategory = salesCategories.find((salesCategory) => {
				return salesCategory.value === data.code;
			});
			if (salesCategory) {
				setCode(salesCategory);
			}
		} else if (data.type === BudgetTypes.Location) {
			let location = locations.find((location) => {
				return location.value === data.code;
			});
			if (location) {
				setCode(location);
			}
		}
	};

	const fetchData = async () => {
		displayAlertLoader();
		const salesCategories = await fetchSalesCategories();
		const locations = await fetchLocations();
		hideAlertLoader();
		fetchCodeIfNeeded(salesCategories, locations);
	};

	const handleConfirm = () => {
		onConfirm(Object.keys(budget).length === 0, data);
	};

	const handleToleranceChange = (event) => {
		const { id, value } = event.target;
		if (DECIMAL_MAX_TWO_FRACTIONS.test(value) || value === '') {
			const newData = { ...data, [id]: value };
			newData.budgett = calcTotalBudget(newData);
			setData(newData);
		}
	};

	const handleChange = (e, meta) => {
		const type = e.target?.attributes['data-type']?.value;
		if (type && type === 'decimal' && !FLOAT.test(e.target.value)) {
			return;
		}

		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				if (e.target.attributes['type'].value === 'radio') {
					key = e.target.name;
					value = e.target.value;
				} else {
					value = e.target.checked;
				}
			}
		}

		const newData = { ...data, [key]: value };

		if (key === 'type') {
			setType(value);
			setCode(undefined);
		} else if (key === 'code') {
			setCode(e);
		} else if (key.includes('budget')) {
			newData.budgett = calcTotalBudget(newData);
		}
		setData(newData);
	};

	const calcTotalBudget = (data) => {
		return components
			.map((component) => {
				return Number(data[`budget${component.key}`] ?? 0);
			})
			.reduce((total, budget) => total + budget, 0);
	};

	return (
		<Modal
			show={true}
			aria-labelledby="action-modal"
			className="a-modal"
			backdrop="static"
			size="xl"
			centered
		>
			<Modal.Body>
				<Col className="px-5">
					<Row className="mb-3">
						<Col>
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								{Object.keys(budget).length === 0 ? 'Add' : 'Edit'}{' '}
								{title ?? 'Budget Setting'}
							</h5>
						</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<Col sm={12} md={7}>
									<div className="d-flex flex-column">
										<div className="fw-bold">Type</div>
										<div className="d-flex flex-row">
											<Form.Check
												inline
												name="type"
												label="Sales Category"
												type="radio"
												className="me-4"
												value={BudgetTypes.SalesCategory}
												checked={type == BudgetTypes.SalesCategory}
												onChange={handleChange}
											/>
											<Form.Check
												inline
												name="type"
												label="Location"
												type="radio"
												className="me-4"
												value={BudgetTypes.Location}
												checked={type == BudgetTypes.Location}
												onChange={handleChange}
											/>
											<div className="flex-grow-1">
												{type == BudgetTypes.SalesCategory ? (
													<AsyncSalesCategoriesDropdown
														defaultValue={code}
														name="code"
														className="react-select"
														placeholder="Please select"
														onChange={handleChange}
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 99999,
															}),
														}}
														isDisabled={undefined}
													/>
												) : (
													<AsyncLocationsDropdown
														urlQuery={
															projId
																? `?$filter=(projectId eq null or projectId eq ${projId})&$orderby=locn asc`
																: `?$filter=(projectId eq null or proj eq '${proj}')&$orderby=locn asc`
														}
														defaultValue={code}
														name="code"
														className="react-select"
														placeholder="Please select"
														onChange={handleChange}
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 99999,
															}),
														}}
														isDisabled={data.type === undefined}
													/>
												)}
											</div>
										</div>
										<div className="d-flex flex-row align-items-center mt-2">
											<div className="me-4">Note</div>
											<Form.Control
												id="note"
												type="text"
												placeholder=""
												value={data.note}
												onChange={handleChange}
											/>
										</div>
									</div>
								</Col>
							</Row>
							<Row>
								<Col sm={12} md={7}>
									<div className="d-flex flex-column mt-3">
										<div className="fw-bold">Budget</div>
										<Col>
											<Row>
												<Col></Col>
												<Col>Amount</Col>
												<Col>Action</Col>
												<Col>Tolerance %</Col>
											</Row>
											{components.map((row) => {
												return (
													<Row
														key={row.key}
														className="align-items-center my-2"
													>
														<Col>
															{company?.[`compview${row.key}`] ?? row.label}
														</Col>
														<Col>
															<Form.Control
																id={`budget${row.key}`}
																type="text"
																placeholder=""
																value={data[`budget${row.key}`] ?? ''}
																onChange={handleChange}
																data-type="decimal"
															/>
														</Col>
														<Col>
															<Select
																name={`budgetwarn${row.key}`}
																placeholder="Select"
																options={BUDGET_WARNING_OPTIONS}
																value={BUDGET_WARNING_OPTIONS.find(
																	(option) =>
																		option.value ===
																		data[`budgetwarn${row.key}`]
																)}
																onChange={handleChange}
															/>
														</Col>
														<Col>
															<Form.Control
																id={`budgettol${row.key}`}
																type="text"
																placeholder=""
																value={data[`budgettol${row.key}`] ?? ''}
																onChange={handleToleranceChange}
															/>
														</Col>
													</Row>
												);
											})}
											<Row className="align-items-center">
												<Col>Total Budget</Col>
												<Col>
													<Form.Control
														id="budgett"
														type="text"
														disabled={true}
														value={Number(data.budgett ?? 0).toFixed(2)}
														onChange={handleChange}
													/>
												</Col>
												<Col>
													<Select
														name="budgetwarnt"
														placeholder="Select"
														options={BUDGET_WARNING_OPTIONS}
														value={BUDGET_WARNING_OPTIONS.find(
															(option) => option.value === data.budgetwarnt
														)}
														onChange={handleChange}
													/>
												</Col>
												<Col></Col>
											</Row>
										</Col>
									</div>
								</Col>
								<Col>
									<div className="d-flex flex-column">
										<div className="d-flex flex-row align-items-center">
											<Form.Check
												id="overridedep"
												inline
												className="me-2"
												label="Override Client Deposit %"
												type="checkbox"
												checked={data.overridedep ?? false}
												onChange={handleChange}
											/>
											<Form.Control
												id="clientdep"
												style={{ width: '100px' }}
												type="text"
												placeholder=""
												disabled={!data.overridedep}
												value={data.clientdep ?? (data.overridedep ? '0' : '')}
												onChange={handleChange}
												data-type="decimal"
											/>
										</div>
										<Form.Check
											id="overridemu"
											inline
											className="mb-2"
											label="Override Mark-Up %"
											type="checkbox"
											checked={data.overridemu ?? false}
											onChange={handleChange}
										/>
										<div className="d-flex flex-column mt-3">
											<div className="fw-bold">Mark-Up</div>
											<Col>
												<Row>
													<Col></Col>
													<Col>% Type</Col>
													<Col>%</Col>
												</Row>
												{markups.map((row) => {
													return (
														<Row
															key={row.key}
															className="align-items-center mt-2"
														>
															<Col>
																{company?.[`compview${row.shortKey}`] ??
																	row.label}
															</Col>
															<Col>
																<Select
																	name={`usediscount${row.key}`}
																	placeholder="Select"
																	options={markupTypes}
																	isDisabled={!data.overridemu}
																	value={markupTypes.find(
																		(type) =>
																			type.value ===
																			data[`usediscount${row.key}`]
																	)}
																	onChange={handleChange}
																/>
															</Col>
															<Col>
																<Form.Control
																	id={`markup${row.key}`}
																	type="text"
																	placeholder=""
																	value={
																		data[`markup${row.key}`] ??
																		(data.overridemu ? '0' : '')
																	}
																	disabled={!data.overridemu}
																	onChange={handleChange}
																	data-type="decimal"
																/>
															</Col>
														</Row>
													);
												})}
											</Col>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-3 px-5">
				<Row>
					<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
						<Button
							variant="primary"
							onClick={handleConfirm}
							className="w-100 m-2"
						>
							OK
						</Button>

						<Button
							variant="trans-light border-secondary-ash"
							onClick={onCancel}
							className="w-100 m-2"
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};

export default BudgetAddEditModal;
