import React, { FC } from 'react';
import { WithRouter } from 'legacy/helpers/Router';
import { Outlet } from 'react-router-dom';

export const StockItems: FC = () => (
	<>
		<Outlet />
	</>
);
StockItems.displayName = 'StockItems';

export default WithRouter(StockItems);
