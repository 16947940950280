import React from 'react';
import URI from '../../../../defaults/RoutesDefault';
import { Header } from '../../../components/header/HeaderComponent';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { pathParam } from '../../../../utilities/Router';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security';
import SecureLink from '../../../components/security/SecureLink';

export default class VendorListHeader extends React.Component {
	render(): JSX.Element {
		return (
			<Header>
				<Header.LeftContent>
					<Header.Breadcumbs>
						<Breadcrumb className="breadcrumb-light">
							<Breadcrumb.Item
								linkProps={{ to: URI.contact.vendor }}
								linkAs={Link}
							>
								Contacts
							</Breadcrumb.Item>
							<Breadcrumb.Item>Vendors</Breadcrumb.Item>
						</Breadcrumb>
					</Header.Breadcumbs>
					<Header.Title>Vendors</Header.Title>
				</Header.LeftContent>
				<Header.Actions list={true}>
					<li>
						<SecureLink
							attributeNo={49}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
							to={pathParam(URI.contact.vendorAdd, {
								tab: 'info',
							})}
							className="btn-icon text-secondary-green btn btn-light btn-lg"
							title="Add Vendor"
						>
							<i className="ri-add-line ri-lg"></i> Add
						</SecureLink>
					</li>
				</Header.Actions>
			</Header>
		);
	}
}
