import { ApiService } from '../../../../lib/networking/HttpService';

export class ClientService extends ApiService {
	getClients<T = ClientListResponse>(params?: string): Promise<T> {
		return this.httpWorker.get(`/clients${params || ''}`, {});
	}

	getClientById<T = ClientListResponse>(id: number): Promise<T> {
		return this.httpWorker.get(`/clients?$filter=id eq ${id}`, {});
	}

	insert<T = ClientListResponse>(params: any): Promise<T> {
		return this.httpWorker.post(`/clients`, params);
	}

	update<T = ClientListResponse>(id: number, params: any): Promise<T> {
		return this.httpWorker.patch(`/clients/${id}`, params);
	}

	delete<T = ClientListResponse>(params: any): Promise<T> {
		return this.httpWorker.post(`/DeleteObjects`, params);
	}

	getSalesTaxCodes<T = ISalesTaxCodesResponse>(params?: string): Promise<T> {
		return this.httpWorker.get(`/salestaxcodes${params || ''}`, {});
	}
	getSalesTaxCodesSummaries<T = { results: ISalesTaxCodesResponse }>(
		params?: string
	): Promise<T> {
		return this.httpWorker.get(`/salestaxcodes/summaries${params || ''}`, {});
	}
}

export interface ClientListResponse {
	id: number;
	sortName: string;
	contact?: string;
	contactPhone?: string;
	typeDesc: string;
	client?: string;
	ClientName: string;
	addrtype: number;
	manager?: string;
	taxid?: number;
	notes?: string;
	finchgperc: number;
	finchgdays: number;
	closeddt?: string;
	notesrtf?: string;
	inactive: boolean;
	deftaxc?: string;
	defclientinvterms?: string;
	list: any;
	0: any;
	numAttachments: number;
}

export interface ISalesTaxCodesResponse {
	taxc: any;
	taxcn: string;
}
