import { useMutation } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { TItemOrder } from '../types/ItemImages';

const api = new ApiService();

const useUpdateImageOrder = () => {
	return useMutation<void, Error, TItemOrder>((imageOrder) => {
		return api.postItemImageOrder(imageOrder);
	});
};

export default useUpdateImageOrder;
