import React from 'react';
import BaseButton from 'legacy/app/components/Buttons/BaseButton';
import BaseBanner from 'legacy/app/components/banners/BaseBanner';
import RecentProjectsBackgroundImage from '../../../assets/images/Recent_Projects-BG-[No_Arrow].png';
import { DashboardUrls } from 'legacy/app/constants/dashboard/dashboardUrls';
import { useNavigate } from 'react-router-dom';

const EmptyRecentProjects = () => {
	const navigate = useNavigate();
	return (
		<BaseBanner
			imageClass="tw-hidden sm:tw-block"
			backgroundImage={RecentProjectsBackgroundImage}
		>
			<div className="tw-relative sm:tw-absolute tw-inset-0 tw-flex tw-items-start tw-justify-center tw-flex tw-flex-col">
				<div className="tw-flex tw-flex-col  tw-items-start tw-bg-white  tw-m-4 tw-rounded-lg tw-bg-opacity-75 tw-max-w-full sm:tw-max-w-[40%]">
					<h2 className="tw-text-lg tw-font-bold">Create Your First Project</h2>
					<p className="tw-mt-2 tw-text-gray-400 tw-mb-0">
						A project contains the items. proposals, and invoices for each of
						your clients. Click below to get started!
					</p>
					<BaseButton
						text="Create Project"
						buttonClass={`tw-mt-4 tw-px-4 tw-py-2 tw-bg-[#008B6D] tw-text-white tw-rounded `}
						onClick={() => {
							navigate(DashboardUrls.addUrl);
						}}
					/>
				</div>
			</div>
		</BaseBanner>
	);
};

EmptyRecentProjects.displayName = 'EmptyRecentProjects';

export default EmptyRecentProjects;
