import solidImage from '../assets/images/solid-color-image.png';

import clipper1 from '../assets/images/clipper-1.png';
import clipper2 from '../assets/images/clipper-2.png';
import clipper3 from '../assets/images/clipper-3.png';

const CONFIG = {
	documentTitle: 'Design Manager',
	solidImage: solidImage,
	clipper1: clipper1,
	clipper2: clipper2,
	clipper3: clipper3,
};

export default CONFIG;
