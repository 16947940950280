import ThreadComponent from '../../../ThreadComponent';
import { observer } from 'mobx-react';
import { Button, Col, Container, Form, Row, Alert } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { ActionBarVertical } from '../../../../templates/components/ActionBar';
import ListFilter from '../../../../templates/components/ListFilter';
import VendorAddEditViewModel from '../VendorAddEditViewModel';
import { AccountService } from '../../Account/api/AccountService';
import AccountListViewModel from '../../Account/AccountListViewModel';
import { InsuranceService } from '../api/InsuranceService';
import { VendorService } from '../api/VendorService';
import VendorGeneralDefaults from './VendorGeneralDefaults';
import VendorInsuranceGrid from '../components/VendorInsuranceGrid';
import { ApiService } from 'legacy/lib/api/HttpService';

interface SelectOptions {
	label?: string;
	value?: any;
}

interface Props {
	data: any;
	onDraft?: any;
	setIsDraft: Function;
	isNewVendor: boolean;
}

interface State {
	data: any;
	company: any;
	showInsuranceModal: boolean;
	selectData: SelectOptions[];
	showTableSearch?: boolean;
	typeOptions: SelectOptions[];
	accountOptions: SelectOptions[];
	checks: any;
}
const api = new ApiService();
class VendorsAddDefaults extends ThreadComponent<Props, State> {
	readonly vendorAddEditModel: VendorAddEditViewModel;
	readonly accountListModel: AccountListViewModel;
	constructor(props: Props) {
		super(props);

		this.vendorAddEditModel = new VendorAddEditViewModel(
			new VendorService(),
			new InsuranceService()
		);
		this.accountListModel = new AccountListViewModel(new AccountService());

		this.state = {
			data: {},
			company: {},
			showInsuranceModal: false,
			selectData: [
				{ value: 'option-1', label: 'Option 1' },
				{ value: 'option-2', label: 'Option 2' },
				{ value: 'option-3', label: 'Option 3' },
			],
			typeOptions: [
				{
					value: -1,
					label: 'Discount',
				},
				{
					value: 0,
					label: 'Markup',
				},
				{
					value: 1,
					label: 'Fee',
				},
			],
			accountOptions: [{ value: 'option-1', label: 'Option 1' }],
			showTableSearch: false,
			checks: {},
		};
	}

	async componentDidMount() {
		const { data } = this.props;
		const company = await api.getCompany();
		if (!data.hasOwnProperty('depm')) {
			data.depm = true;
		}

		this.setState({
			data: data,
			company,
		});

		await this.accountListModel.componentDidMount();
	}

	async componentDidUpdate(previousProps: Props, previousState: State) {
		if (previousProps.data !== this.props.data) {
			const { data } = this.props;

			this.setState({
				data: data,
			});
		}
	}

	handleShowTableSearch = (e: any) => {};

	setShowInsuranceModal = (show: boolean) =>
		this.setState({ showInsuranceModal: show });

	handleChange = (e: any, select?: any, isCheckbox?: any) => {
		if (!select && e.hasOwnProperty('target')) {
			// If checkbox
			if (
				isCheckbox ||
				(e?.target?.tagName?.toLowerCase() === 'input' &&
					e?.target?.getAttribute('type') === 'checkbox')
			) {
				this.vendorAddEditModel.setVendorData(
					e.target.name,
					e.target.checked ? true : false
				);
				this.props.onDraft();
				return;
			}

			this.vendorAddEditModel.setVendorData(e.target.name, e.target.value);
		}

		// If select
		console.log(e, select);
		if (select) {
			this.vendorAddEditModel.setVendorData(
				e?.target?.name || e?.name,
				select.value
			);
		} else if (!select && e.hasOwnProperty('name') && e.name === '_1099rp') {
			this.vendorAddEditModel.setVendorData('_1099rp', null);
			this.vendorAddEditModel.setVendorData('acct', null);
		}
		this.props.onDraft();
	};

	getDefaultTypeValue = (key: any) => {
		if (this.state.data) {
			return this.state.typeOptions.find(
				(type) => type.value === this.state.data[key]
			);
		}

		return null;
	};

	getDefaultTypes(): any {
		const defaults = {
			usediscountdesign: null,
			usediscountmerc: null,
			usediscountfreight: null,
			usediscountinstall: null,
			usediscountlabor: null,
		};

		Object.keys(defaults).forEach((key) => {
			// eslint-disable-next-line
			(defaults as any)[key] = this.getDefaultTypeValue(key);
		});

		return defaults;
	}

	renderActionBarVertical(): JSX.Element {
		return (
			<ActionBarVertical>
				<ActionBarVertical.Links>
					<li>
						<NavLink to={'#'} className="d-flex align-items-center mb-2">
							<i className="ri-add-line mx-0"></i>
						</NavLink>
					</li>
					<li>
						<NavLink to={'#'} className="d-flex align-items-center mb-2">
							<i className="ri-edit-line mx-0"></i>
						</NavLink>
					</li>
					<li>
						<NavLink to={'#'} className="d-flex align-items-center">
							<i className="ri-close-line mx-0"></i>
						</NavLink>
					</li>
				</ActionBarVertical.Links>
			</ActionBarVertical>
		);
	}

	renderFilters(): JSX.Element {
		return (
			<>
				<ListFilter expandable={0} style={{ zIndex: 0 }}>
					<ListFilter.Actions
						md={12}
						lg={12}
						xl={12}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-lg-end"
					>
						<ListFilter.Action className="">
							<Button
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render(): JSX.Element {
		const { data } = this.state;
		const accountOptions = [];
		let selectedAccount = null;

		for (const account of this.accountListModel.accountList) {
			const option = {
				value: account.key,
				label: `${account.key} [${account.value}]`,
			};
			accountOptions.push(option);
			selectedAccount =
				data && (account.key == data.acct || account.key === data._1099rp)
					? option
					: selectedAccount;
		}

		const { company } = this.state;

		return (
			<>
				<Container className="px-0 ms-0">
					<Form.Group controlId="formGridText1">
						<Row>
							<Col>
								<Alert
									key={'danger'}
									variant={'danger'}
									id="user-error-area"
									style={{ display: 'none' }}
								>
									Oops, something went wrong.
									<br />
									Error:{' '}
									<strong>
										<span id="userError"></span>
									</strong>
								</Alert>
							</Col>
						</Row>
						<Row xs={1} lg={2} className="mb-2 mb-lg-4">
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">PO Terms</Form.Label>
									</Col>
									<Col>
										<Row lg={2}>
											<Col>
												<Form.Control
													type="text"
													maxLength={20}
													placeholder="Please enter"
													name="terms"
													defaultValue={data?.terms}
													onChange={this.handleChange.bind(this)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">PO Ship Via</Form.Label>
									</Col>
									<Col>
										<Row lg={2}>
											<Col>
												<Form.Control
													type="text"
													maxLength={20}
													placeholder="Please enter"
													name="shipvia"
													defaultValue={data?.shipvia}
													onChange={this.handleChange.bind(this)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Deposit %</Form.Label>
									</Col>
									<Col>
										<Row lg={2}>
											<Col>
												<Form.Control
													type="text"
													placeholder="Please enter"
													name="deppct"
													defaultValue={data?.deppct}
													onChange={this.handleChange.bind(this)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="align-items-start">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											Deposit Applies To:
										</Form.Label>
									</Col>
									<Col lg={4}>
										<Row xs={1} sm={2}>
											<Col className="mb-2">
												<Form.Check
													inline
													label={company.compviewm}
													name="depm"
													type="checkbox"
													id={`checkbox-group-da-2`}
													className="mb-2 mb-md-0"
													defaultChecked={data?.depm}
													onClick={this.handleChange.bind(this)}
												/>
											</Col>
											<Col className="mb-2">
												<Form.Check
													inline
													label={company.compviewd}
													name="depd"
													type="checkbox"
													id={`checkbox-group-da-4`}
													className="mb-2 mb-md-0"
													defaultChecked={data?.depd}
													onClick={this.handleChange.bind(this)}
												/>
											</Col>
											<Col className="mb-2">
												<Form.Check
													inline
													label={company.compviewf}
													name="depf"
													type="checkbox"
													id={`checkbox-group-da-5`}
													className="mb-2 mb-md-0"
													defaultChecked={data?.depf}
													onClick={this.handleChange.bind(this)}
												/>
											</Col>
											<Col className="mb-2">
												<Form.Check
													inline
													label={company.compviewi}
													name="depi"
													type="checkbox"
													id={`checkbox-group-da-6`}
													className="mb-2 mb-md-0"
													defaultChecked={data?.depi}
													onClick={this.handleChange.bind(this)}
												/>
											</Col>
											<Col className="mb-2">
												<Form.Check
													inline
													label={company.compviewl}
													name="depl"
													type="checkbox"
													id={`checkbox-group-da-3`}
													className="mb-2 mb-md-0"
													defaultChecked={data?.depl}
													onClick={this.handleChange.bind(this)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Buying Terms</Form.Label>
									</Col>
									<Col>
										<Row xs={1} sm={3}>
											<Col className="mb-2 mb-sm-0">
												<Form.Control
													type="text"
													placeholder="Please enter"
													name="bterm1"
													defaultValue={data?.bterm1}
													onChange={this.handleChange.bind(this)}
												/>
											</Col>
											<Col className="mb-2 mb-sm-0">
												<Form.Control
													type="text"
													placeholder="Please enter"
													name="bterm2"
													defaultValue={data?.bterm2}
													onChange={this.handleChange.bind(this)}
												/>
											</Col>
											<Col className="mb-2 mb-sm-0">
												<Form.Control
													type="text"
													placeholder="Please enter"
													name="bterm3"
													defaultValue={data?.bterm3}
													onChange={this.handleChange.bind(this)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Col className="mb-3 mb-lg-0">
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">
												Early Payment Discount%
											</Form.Label>
										</Col>
										<Col>
											<Row className="align-items-center">
												<Col>
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="discperc"
														defaultValue={data?.discperc}
														onChange={this.handleChange.bind(this)}
													/>
												</Col>
												<Col className="text-lg-end mb-2 mb-lg-0">
													<Form.Label
														className="mb-0"
														style={{
															paddingLeft: '1rem',
														}}
													>
														Days for Discount
													</Form.Label>
												</Col>
												<Col>
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="discdays"
														defaultValue={data?.discdays}
														onChange={this.handleChange.bind(this)}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Payment Due Days</Form.Label>
										</Col>
										<Col>
											<Row>
												<Col lg={4}>
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="paydays"
														defaultValue={data?.paydays}
														onChange={this.handleChange.bind(this)}
													/>
												</Col>
											</Row>
										</Col>
									</Row>

									<Row className="align-items-start mb-2 mb-lg-3 align-items-center">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">
												Expense Acct. (or 1099-INT)
											</Form.Label>
										</Col>
										<Col lg={4}>
											<Select
												key={`${Math.floor(Math.random() * 1000)}-min`}
												options={accountOptions}
												className="react-select"
												placeholder="Please select"
												defaultValue={selectedAccount}
												isClearable
												name="_1099rp"
												onChange={this.handleChange.bind(this, {
													name: '_1099rp',
												})}
												styles={{
													control: (baseStyles: any, state) => ({
														...baseStyles,
														zIndex: 150,
													}),
												}}
											/>
										</Col>
										<Col>
											<Form.Check
												inline
												label="Do Not Allow on Specifications, Stock Items, or Purchase Orders"
												name="donotallowonspecs"
												type="checkbox"
												id={`checkbox-group-spec-allow`}
												defaultChecked={data?.donotallowonspecs}
												onClick={this.handleChange.bind(this)}
											/>
										</Col>
									</Row>
								</Col>
							</Col>
						</Row>
						<Row className="bg-secondary-grey roundedx-4 mb-4">
							<Row
								className="align-items-center"
								style={{
									paddingLeft: '1.5rem',
								}}
							>
								<Col className="mb-2 mb-lg-0 px-2">
									<h6 className="fw-bold mb-0">Override</h6>
								</Col>
								<Col
									style={{
										paddingBottom: this.props.isNewVendor ? '2.5rem' : 0,
										paddingTop: this.props.isNewVendor ? '0.5rem' : 0,
									}}
									className="mb-2 mb-lg-0 px-2 d-flex align-items-center gap-4"
								>
									{!this.props.isNewVendor && (
										<>
											<h6 className="fw-bold mb-0">Insurance Policy</h6>
											<Button
												onClick={() => this.setShowInsuranceModal(true)}
												variant="dark"
												size="sm"
												style={{
													marginLeft: 'auto',
												}}
												className="btn-icon mb-2 mt-2 text-secondary-green btn btn-light btn-md"
											>
												<i className="ri-add-line ri-lg"></i>
												Add
											</Button>
										</>
									)}
								</Col>
							</Row>
						</Row>
						<Row>
							<Col>
								<VendorGeneralDefaults
									handleChange={this.handleChange}
									setIsDraft={this.props.setIsDraft}
								/>
							</Col>
							<Col>
								{!this.props?.isNewVendor && (
									<VendorInsuranceGrid
										setShowModal={this.setShowInsuranceModal}
										showModal={this.state.showInsuranceModal}
									/>
								)}
							</Col>
						</Row>
					</Form.Group>
				</Container>
			</>
		);
	}
}

export default observer(VendorsAddDefaults);
