import { ApiService } from '../../../../lib/networking/HttpService';

export class InsuranceService extends ApiService {
	getInsurances<T = IInsuranceResponse>(params?: string): Promise<T> {
		return this.httpWorker.get(`/insurances${params || ''}`, {});
	}
}

export interface IInsuranceResponse {
	vendor: string;
	insuranceno: number;
	policytype: string;
	carrier: string;
	effectivedt: string;
	expirationdt: string;
	policyno: string;
	active: boolean;
	ssmaTimeStamp: string;
}
