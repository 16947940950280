import { Button, Form, Table } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FiletypeIcon } from './FiletypeIcon';
import { showEmpty, showLoading } from '../../../helpers/Loading';
import SearchableCombobox from '../../../app/components/multi-search/SearchableCombobox';
import { DateRangePicker } from 'rsuite';
import {
	addDays,
	endOfDay,
	getMonth,
	getYear,
	lastDayOfMonth,
	startOfDay,
	subDays,
} from 'date-fns';
import SecureButton from 'legacy/app/components/security/SecureButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';

export const DocumentsTable = ({
	data,
	documents,
	isSearching,
	isLoading,
	onSelect,
	onSort,
	onView,
	onEdit,
	onDownload,
	onSearch,
	onSelectedTypesChange,
	onDateRangeChange,
}) => {
	const [hoveredId, setHoveredId] = useState(undefined);
	const [availableTypes, setAvailableTypes] = useState([]);
	const allChecked = () => {
		return !data.documents.find((document) => {
			return document.isChecked === false;
		});
	};
	const handleMouseOver = (id) => {
		setHoveredId(id);
	};
	const handleMouseOut = () => {
		setHoveredId(undefined);
	};
	const handleSearch = (e) => {
		onSearch(e.target.id, e.target.value);
	};
	const handleTypeCheck = (value) => {
		const newAvailableTypes = [...availableTypes];
		newAvailableTypes.forEach((availableType) => {
			availableType.checked =
				availableType.value === value
					? !availableType.checked
					: availableType.checked;
		});
		setAvailableTypes(newAvailableTypes);
		onSelectedTypesChange(
			newAvailableTypes
				.filter((availableType) => availableType.checked)
				.map((availableType) => availableType.value)
		);
	};

	useEffect(() => {
		const newAvailableTypes = [
			...new Set(documents.map((document) => document.filetype)),
		]
			.sort()
			.map((filetype) => {
				return {
					label: filetype?.length === 0 ? 'No file extension' : `*.${filetype}`,
					value: filetype?.length === 0 ? '' : filetype,
					checked: isSearching
						? availableTypes.find((type) => type.value === filetype)?.checked ??
						  false
						: false,
				};
			});
		setAvailableTypes(newAvailableTypes);
	}, [data]);

	return (
		<div
			className="table-gradient sticky-container w-100"
			style={{ paddingBottom: '75px' }}
		>
			<Table striped responsive className="a-table mt-4">
				<thead>
					<tr>
						<th style={{ width: '50px' }}>
							<Form.Check
								label=""
								type="checkbox"
								checked={allChecked()}
								className={allChecked() ? 'line' : ''}
								onChange={() =>
									onSelect(
										data.documents.map((document) => document.id),
										!allChecked()
									)
								}
							/>
						</th>
						{data.headers.map((header, index) => {
							return (
								<th key={index} style={{ width: '220px' }}>
									<span
										className={`
                                        ${header.sortKey ? 'sort' : ''}
                                        ${
																					header.sortDirection
																						? header.sortDirection
																						: ''
																				}
                                        ${header.isActive ? 'active' : ''}
                                        `}
										onClick={() => {
											onSort(header.sortKey);
										}}
									>
										{header.label}
									</span>
								</th>
							);
						})}
					</tr>
					{isSearching && (
						<tr>
							<td></td>
							<td>
								<SearchableCombobox
									label="Types"
									options={availableTypes}
									onCheck={handleTypeCheck}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									id="filename"
									size="sm"
									onChange={handleSearch}
								/>
							</td>
							<td>
								<Form.Control
									type="text"
									id="uploadedBy"
									size="sm"
									onChange={handleSearch}
								/>
							</td>
							<td>
								<DateRangePicker
									placement="auto"
									placeholder="Select range"
									format="MM/dd/yyyy"
									onChange={onDateRangeChange}
									ranges={[
										{
											label: 'today',
											value: [startOfDay(new Date()), endOfDay(new Date())],
										},
										{
											label: 'yesterday',
											value: [
												startOfDay(addDays(new Date(), -1)),
												endOfDay(addDays(new Date(), -1)),
											],
										},
										{
											label: 'last7Days',
											value: [
												startOfDay(subDays(new Date(), 6)),
												endOfDay(new Date()),
											],
										},
										{
											label: 'Last 30 Days',
											value: [
												startOfDay(subDays(new Date(), 30)),
												endOfDay(new Date()),
											],
										},
										{
											label: 'This month',
											value: [
												startOfDay(
													new Date(getYear(new Date()), getMonth(new Date()), 1)
												),
												endOfDay(lastDayOfMonth(new Date())),
											],
										},
										{
											label: 'Last month',
											value: [
												startOfDay(
													new Date(
														getYear(new Date()),
														getMonth(new Date()) - 1,
														1
													)
												),
												endOfDay(
													lastDayOfMonth(
														new Date(
															getYear(new Date()),
															getMonth(new Date()) - 1,
															1
														)
													)
												),
											],
										},
									]}
								/>
							</td>
							<td></td>
						</tr>
					)}
				</thead>
				<tbody>
					{isLoading
						? showLoading()
						: data.documents.length
						? data.documents.map((document, index) => {
								return (
									<tr
										key={index}
										onMouseOver={() => {
											handleMouseOver(document.id);
										}}
										onMouseOut={handleMouseOut}
										onClick={() => {
											onView(document);
										}}
									>
										<td>
											<Form.Check
												label=""
												type="checkbox"
												checked={document.isChecked}
												onClick={(e) => {
													e.stopPropagation();
												}}
												onChange={(e) => {
													onSelect([document.id], !document.isChecked);
												}}
											/>
										</td>
										<td>
											<div className="documents-thumbnail">
												<FiletypeIcon
													filetype={document.filetype}
													thumbnail={document.thumbnail}
												/>
											</div>
										</td>
										<td>
											<div
												className="text-decoration-underline text-break"
												style={{ cursor: 'default' }}
											>
												{document.filename}
											</div>
										</td>
										<td>{document.uploadedBy}</td>
										<td>{document.formattedDateUploaded}</td>
										<td>
											<div style={{ height: '50px' }}>
												{hoveredId === document.id && (
													<div>
														<SecureButton
															attributeNo={39}
															attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
															href="#"
															variant="ivory"
															size="sm"
															className="border-0 fsx-20 ri-pencil-line square me-2"
															style={{
																textDecoration: 'none',
															}}
															onClick={(e) => {
																e.stopPropagation();
																onEdit(document);
															}}
														/>
														<Button
															href="#"
															variant="ivory"
															size="sm"
															className={`border-0 fsx-20 ${
																document.filetype === 'url'
																	? 'ri-links-line'
																	: 'ri-download-cloud-line'
															} square`}
															style={{
																textDecoration: 'none',
															}}
															onClick={(e) => {
																e.stopPropagation();
																document.filetype === 'url'
																	? onView(document)
																	: onDownload(document);
															}}
														/>
													</div>
												)}
											</div>
										</td>
									</tr>
								);
						  })
						: showEmpty()}
				</tbody>
			</Table>
		</div>
	);
};
