import React from 'react';
import { Col, Row, Form, Container, Breadcrumb, Button } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { getRouteWithParam, WithRouter } from '../../../helpers/Router';
import { FooterFormAction } from '../../components/Form';
import URI from '../../../defaults/RoutesDefault';
import { Link, NavLink } from 'react-router-dom';
import { HeaderLight } from '../../components/Header';
import { ApiService } from '../../../lib/api/HttpService';
import {
	displayAlert,
	displayAlertLoader,
	hideAlertLoader,
} from '../../../utilities/Response';
import { pathParam } from '../../../utilities/Router';
import { getLocalStorage } from '../../../utilities/LocalStorage';
import { generatePath } from 'react-router';

class GlossaryAddSalesTaxCalculation extends ThreadComponent {
	constructor(props) {
		super(props);
		this.api = new ApiService();
		this.state = {
			data: {},
			amountOption: 'ontotal',
			company: {},
		};

		this.isEditing = Boolean(this.props.params.taxc);
	}

	handleOnFormChange = (e) => {
		if (e.target.type === 'checkbox') {
			this.setState({
				data: { ...this.state.data, [e.target.name]: e.target.checked },
			});
		} else if (e.target.type === 'radio') {
			switch (e.target.id) {
				case 'ontotal':
					this.setState({
						data: {
							...this.state.data,
							ontotal: true,
							onbelow: false,
							onbetween: false,
							onabove: false,
						},
					});
					break;
				case 'onbelow':
					this.setState({
						data: {
							...this.state.data,
							ontotal: false,
							onbelow: true,
							onbetween: false,
							onabove: false,
						},
					});
					break;
				case 'onbetween':
					this.setState({
						data: {
							...this.state.data,
							ontotal: false,
							onbelow: false,
							onbetween: true,
							onabove: false,
						},
					});
					break;
				case 'onabove':
					this.setState({
						data: {
							...this.state.data,
							ontotal: false,
							onbelow: false,
							onbetween: false,
							onabove: true,
						},
					});
					break;
				default:
					this.setState({
						data: {
							...this.state.data,
							ontotal: false,
							onbelow: false,
							onbetween: false,
							onabove: false,
						},
					});
			}
			this.setState({ amountOption: e.target.id });
		} else {
			this.setState({
				data: { ...this.state.data, [e.target.name]: e.target.value },
			});
		}
	};

	handleSaveAndNewClick = async (e) => {
		e.preventDefault();
		const request = this.api
			.editSalesTaxCodeCalculation(this.state.data)
			.then((res) => {
				displayAlert('success', 'Successfully saved changes!');
				window.onbeforeunload = null;
				setTimeout(() => {
					window.location.href = getRouteWithParam(
						URI.glossary.add.salesTaxCalculation,
						{ taxc: this.props.params.taxc }
					);
				}, 2000);
			})
			.catch((err) => {
				const errorMessage =
					err.response?.data?.userError ||
					'Sales tax code calculation could not be saved';
				displayAlert('danger', errorMessage);
			});
	};

	handleSaveClick = async (e) => {
		e.preventDefault();
		await this.api
			.editSalesTaxCodeCalculation(this.state.data)
			.then((res) => {
				displayAlert('success', 'Successfully saved changes!');
				window.onbeforeunload = null;
				setTimeout(() => {
					if (this.isEditing) {
						window.location.href = getRouteWithParam(
							URI.glossary.salesTaxCodes,
							{
								taxc: this.props.params.taxc,
							}
						);
					} else {
						this.props.navigate(URI.glossary.add.salesTaxCodes, {
							state: { taxc: this.props.params.taxc },
						});
					}
				}, 2000);
			})
			.catch((err) => {
				const errorMessage =
					err.response?.data?.userError ||
					'Sales tax code calculation could not be saved';
				displayAlert('danger', errorMessage);
			});
	};

	componentInit() {
		this.setTitle('Sales Tax Calculation | Glossary');
		this.setFormAction(true);
	}

	async componentDidMount() {
		this.dMloader(true);
		const company = await this.api.get('company', '');
		this.setState({
			company: company,
		});
		if (this.props.params.taxc && this.props.params.seqnum) {
			let filter = `?$filter=taxc eq '${this.props.params.taxc}' and seqnum eq ${this.props.params.seqnum}`;
			let salesTaxCodeCalculation = await this.api.getSalesTaxCodeCalculations(
				filter
			);
			if (salesTaxCodeCalculation[0]) {
				let option = 'ontotal';
				if (salesTaxCodeCalculation[0].ontotal) {
					option = 'ontotal';
				} else if (salesTaxCodeCalculation[0].onabove) {
					option = 'onabove';
				} else if (salesTaxCodeCalculation[0].onbelow) {
					option = 'onbelow';
				} else if (salesTaxCodeCalculation[0].onbetween) {
					option = 'onbetween';
				}
				this.setState({
					data: salesTaxCodeCalculation[0],
					amountOption: option,
				});
			}
		}
		this.dMloader(false);

		this.isEditing = JSON.parse(getLocalStorage('isEditing'));
	}

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.timeEntry.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							linkProps={{ to: URI.settings.base }}
							linkAs={Link}
						>
							Settings
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.glossary.base }}
							linkAs={Link}
						>
							Glossary
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.glossary.list, {
									path: 'sales-tax-codes',
								}),
							}}
							linkAs={Link}
						>
							Sales Tax Codes
						</Breadcrumb.Item>
						{this.props.params?.taxc && this.isEditing ? (
							<Breadcrumb.Item
								linkProps={{
									to: pathParam(URI.glossary.edit.salesTaxCodes, {
										code: this.props.params.taxc,
									}),
								}}
								linkAs={Link}
							>
								Edit Sales Tax Code {this.props.params.taxc}
							</Breadcrumb.Item>
						) : (
							<Breadcrumb.Item
								linkProps={{
									to: URI.glossary.add.salesTaxCodes,
								}}
								linkAs={Link}
							>
								Add Sales Tax Code
							</Breadcrumb.Item>
						)}
						<Breadcrumb.Item linkProps={{ to: '#' }} linkAs={Link}>
							{this.state.data.rulen}
						</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content>
					<HeaderLight.Title>Sales Tax Calculation</HeaderLight.Title>

					<div>
						{/* Submit Button */}
						<Button onClick={this.handleSaveAndNewClick} variant="primary me-2">
							Save & New
						</Button>

						<Button onClick={this.handleSaveClick} variant="primary">
							Save
						</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderOptions() {
		const { data, amountOption } = this.state;
		return (
			<>
				{amountOption === 'onbelow' || amountOption === 'onbetween' ? (
					<Col className="mb-3 mb-md-0">
						<Form.Label className="mb-2 mb-md-1">
							<strong>Below $</strong>
						</Form.Label>
						<Form.Control
							onChange={this.handleOnFormChange}
							type="text"
							placeholder="Please enter"
							name="lowamt"
							defaultValue={data.lowamt}
						/>
					</Col>
				) : null}
				{amountOption === 'onabove' || amountOption === 'onbetween' ? (
					<Col className="mb-3 mb-md-0">
						<Form.Label className="mb-2 mb-md-1">
							<strong>Above $</strong>
						</Form.Label>
						<Form.Control
							onChange={this.handleOnFormChange}
							type="text"
							placeholder="Please enter"
							name="highamt"
							defaultValue={data.highamt}
						/>
					</Col>
				) : null}
			</>
		);
	}

	renderContent() {
		const { data } = this.state;
		return (
			<Container className="ms-0">
				<Row xs={1} lg={2} className="pt-4 pb-2">
					<Col className="mb-3 mb-lg-0">
						<Row className="align-items-center mb-3">
							<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
								<Form.Label className="mb-0">
									Authority Name/ Description
								</Form.Label>
							</Col>
							<Col>
								<Form.Control
									onChange={this.handleOnFormChange}
									type="text"
									placeholder="Please enter"
									name="rulen"
									defaultValue={data.rulen}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className="pb-4">
					<Col className="mb-3 mb-lg-0">
						<Row className="align-items-start align-items-md-end mb-3">
							<Col
								lg={1}
								className="text-lg-end mb-2 mb-lg-0"
								style={{ width: '12.5%' }}
							>
								<Form.Label className="mb-2">Tax %</Form.Label>
							</Col>
							<Col>
								<Row xs={1} md={6} className="align-items-center">
									<Col className="mb-3 mb-md-0">
										<Form.Label className="mb-2 mb-md-3">
											<strong>{this.state.company.compviewm}</strong>
										</Form.Label>
										<Form.Control
											onChange={this.handleOnFormChange}
											type="text"
											placeholder="Please enter"
											name="tpm"
											defaultValue={data.tpm}
										/>
									</Col>
									<Col className="mb-3 mb-md-0">
										<Form.Label className="mb-2 mb-md-3">
											<strong>{this.state.company.compviewd}</strong>
										</Form.Label>
										<Form.Control
											onChange={this.handleOnFormChange}
											type="text"
											placeholder="Please enter"
											name="tpd"
											defaultValue={data.tpd}
										/>
									</Col>
									<Col className="mb-3 mb-md-0">
										<Form.Label className="mb-2 mb-md-3">
											<strong>{this.state.company.compviewf}</strong>
										</Form.Label>
										<Form.Control
											onChange={this.handleOnFormChange}
											type="text"
											placeholder="Please enter"
											name="tpf"
											defaultValue={data.tpf}
										/>
									</Col>
									<Col className="mb-3 mb-md-0">
										<Form.Label className="mb-2 mb-md-3">
											<strong>{this.state.company.compviewi}</strong>
										</Form.Label>
										<Form.Control
											onChange={this.handleOnFormChange}
											type="text"
											placeholder="Please enter"
											name="tpi"
											defaultValue={data.tpi}
										/>
									</Col>
									<Col className="mb-3 mb-md-0">
										<Form.Label className="mb-2 mb-md-3">
											<strong>{this.state.company.compviewl}</strong>
										</Form.Label>
										<Form.Control
											onChange={this.handleOnFormChange}
											type="text"
											placeholder="Please enter"
											name="tpl"
											defaultValue={data.tpl}
										/>
									</Col>
									<Col className="mb-3 mb-md-0">
										<Form.Label className="mb-2 mb-md-3">
											<strong>{this.state.company.compviewo}</strong>
										</Form.Label>
										<Form.Control
											onChange={this.handleOnFormChange}
											type="text"
											placeholder="Please enter"
											name="tpo"
											defaultValue={data.tpo}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row className="align-items-end mb-3">
							<Col
								lg={1}
								className="text-lg-end mb-2 mb-lg-0"
								style={{ width: '12.5%' }}
							></Col>
							<Col>
								<Row className="align-items-center">
									<Col>
										<Form.Check
											inline
											label="Total"
											name="select-amount"
											type="radio"
											id="ontotal"
											defaultChecked={data.ontotal}
											className="me-3"
											onChange={this.handleOnFormChange}
										/>
										<Form.Check
											inline
											label="Below"
											name="select-amount"
											type="radio"
											id="onbelow"
											defaultChecked={data.onbelow}
											className="me-3"
											onChange={this.handleOnFormChange}
										/>
										<Form.Check
											inline
											label="Between"
											name="select-amount"
											type="radio"
											id="onbetween"
											defaultChecked={data.onbetween}
											className="me-3"
											onChange={this.handleOnFormChange}
										/>
										<Form.Check
											inline
											label="Above"
											name="select-amount"
											type="radio"
											id="onabove"
											defaultChecked={data.onabove}
											className="me-3"
											onChange={this.handleOnFormChange}
										/>
									</Col>
									{this.renderOptions()}
								</Row>
							</Col>
						</Row>
						<Row className="align-items-end">
							<Col
								lg={1}
								className="text-lg-end mb-2 mb-lg-0"
								style={{ width: '12.5%' }}
							></Col>
							<Col>
								<Row className="align-items-center">
									<Col>
										<Form.Check
											onChange={this.handleOnFormChange}
											inline
											label="Calculate on Unit Price"
											name="onunit"
											type="checkbox"
											id={`checkbox-group-1`}
											className="me-3"
											defaultChecked={data.onunit}
										/>
										<Form.Check
											onChange={this.handleOnFormChange}
											inline
											label="Print Separate on Invoice"
											name="invprintsep"
											type="checkbox"
											id={`checkbox-group-2`}
											className="me-3"
											defaultChecked={data.invprintsep}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}
				<div className="content-padding min-height has-action-bar">
					<Container fluid className="px-0">
						{this.renderContent()}
					</Container>
				</div>

				<FooterFormAction>
					<Button onClick={this.handleSaveClick} variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(GlossaryAddSalesTaxCalculation);
