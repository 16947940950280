import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import ListFilter from '../../components/ListFilter';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../lib/api/HttpService';
import {
	FormEvent,
	setLocalStorageKeyValueObject,
} from '../../../utilities/FormEvent';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../utilities/LocalStorage';
import {
	displayAlert,
	displayAlertError,
	getErrorMessage,
	handleResponseAlert,
} from '../../../utilities/Response';
import Select from 'react-select';
import { getCookie } from '../../../utilities/Auth';
import { DateRangePicker } from 'rsuite';
import dayjs from 'dayjs';
import { ReportRequiredFields } from '../../../utilities/modules/Reports';
import AsyncProjectsDropdown from '../../../app/components/dropdowns/AsyncProjectsDropdown';
import AsyncClientsDropdown from '../../../app/components/dropdowns/AsyncClientsDropdown';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { formatDate } from '../../../helpers/Date';
import AsyncLocationsDropdown from '../../../app/components/dropdowns/AsyncLocationsDropdown';
import AsyncProposalsDropdown from '../../../app/components/dropdowns/AsyncProposalsDropdown';
import AsyncEmployeesDropdown from '../../../app/components/dropdowns/AsyncEmployeesDropdown';
import AsyncSalesCategoriesDropdown from '../../../app/components/dropdowns/AsyncSalesCategoriesDropdown';
import AsyncActivitiesDropdown from '../../../app/components/dropdowns/AsyncActivitiesDropdown';
import AsyncPurchaseOrderDropdown from '../../../app/components/dropdowns/AsyncPurchaseOrdersDropdown';
import { ReportParamMetaTypes } from '../../../app/enums/report/reportParamMetaTypes';
import { FeatureFlags } from '../../../app/enums/featureFlags/featureFlags';
import { FeatureFlagComponentResolver } from '../../../helpers/Common';

const AsyncDropdownType = (props) => {
	switch (props.selectType) {
		case 'Project':
			return (
				<AsyncProjectsDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Salesperson':
			return (
				<AsyncPurchaseOrderDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Client':
			return (
				<AsyncClientsDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Vendor':
			return (
				<AsyncPurchaseOrderDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Location':
			return (
				<AsyncLocationsDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Employee':
		case 'Manager':
			return (
				<AsyncEmployeesDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Activity':
			return (
				<AsyncActivitiesDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Invoice':
			return (
				<AsyncPurchaseOrderDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Proposal':
			return (
				<AsyncProposalsDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		case 'Sales':
			return (
				<AsyncSalesCategoriesDropdown
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
		default:
			return (
				<Form.Control
					className={props.className}
					value={props.value}
					ref={props.ref}
					name={props.name}
					placeholder={props.placeholder}
					onChange={props.onChange}
					data-param-name={props['data-param-name']}
				/>
			);
	}
};
class ReportModal extends ThreadComponent {
	constructor(props) {
		super(props);
		this.BUDGET_PROJECT_ANALYSIS_ID = 1029;
		this.inputRefs = {};
		this.state = {
			showProjectSearchModal: false,
			activeReport: {
				reportFormats: [],
				params: [],
			},
			params: [],
			reportFormats: {},
			reportParams: {},
			reportParamValues: {},
			checkedParams: {},
			company: {},
			focusedField: '',
			isRangeSelected: false,
		};

		this.api = new ApiService();
		this.formEvent = new FormEvent('report_edit');
		dayjs.extend(customParseFormat);
	}

	componentDidMount() {
		if (this.state.focusedField) {
			this.inputRefs[this.state.focusedField].current.focus();
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.report !== this.props.report) {
			let report = this.props.report || {};
			this.setInitialData(report);
			report.reportFormats = (report.reportFormats || [])
				.filter((i) => i.allowInWeb)
				.map((report) => {
					return { value: report.id, label: report.name };
				});

			const company = await this.api.get('company', '');
			this.setState({
				activeReport: report,
				company: company,
				reportFormats: report.reportFormats[0],
			});
		}
	}

	setInitialData(report) {
		const data = {
			reportId: report ? report.id : 0,
			reportFormatId:
				report.reportFormats.length > 0 ? report.reportFormats[0].id : 0,
			reportObjectType: 'Report',
			objectId: '',
			userId: getCookie('dmUsercode'),
			instanceId: getCookie('dmInstanceId'),
			runReportParameters: [],
			label: report.label,
		};

		setLocalStorage(`report_edit`, data, true);
		setLocalStorage(`report_param_edit`, [], true);
	}

	handleFieldChange = (paramName, e) => {
		this.setState({ focusedField: paramName });
		let updatedEvent = e;
		updatedEvent.target.value = e.target.value.toUpperCase();
		this.handleRangeChange(updatedEvent);
	};

	updateReportParams(paramId, value) {
		let reportParams = getLocalStorage(`report_param_edit`, true) || [];
		const strParam = JSON.stringify(value);
		paramId = parseInt(paramId);

		const paramIndex = reportParams.findIndex((e) => e.parameterId == paramId);
		if (paramIndex > -1) {
			reportParams[paramIndex].value = strParam;
		}

		setLocalStorage(`report_param_edit`, reportParams, true);
		setLocalStorageKeyValueObject(
			`report_edit`,
			'runReportParameters',
			reportParams,
			true
		);

		this.setState({ params: reportParams });
	}

	handleSelectReportFormat = (e) => {
		const id = parseInt(e.value);
		setLocalStorageKeyValueObject(`report_edit`, 'reportFormatId', id, true);
		this.setState({ reportFormats: e });
	};

	handleSelectReportParam = (e, meta = {}) => {
		let newValue = {};
		let reportParam = {};

		// Check if the typeId indicates a range
		if (meta.typeId === ReportParamMetaTypes.Range) {
			// If it's a range, we set both range values to the selected value
			newValue[`${e.paramName}1`] = e.value;
			newValue[`${e.paramName}2`] = e.value;
		} else {
			// If it's not a range, just set the single value
			newValue[e.paramName] = e.value;
		}

		this.updateReportParams(meta.name, newValue);
		reportParam[e.paramName] = e;

		this.setState((prevState) => ({
			reportParams: {
				...prevState.reportParams,
				...reportParam,
			},
		}));
	};

	handleComponentDropdownChange = (props, attr) => {
		const [id, name] = attr.name.split('|');
		let paramValues = this.state.reportParamValues;
		paramValues = {
			...paramValues,
			[id]: {
				...paramValues[id],
				[name]: props.value,
			},
		};
		// Check if there is only one paramValues
		if (
			this.state[`isRangeSelected_${name.slice(0, -1)}`] == undefined ||
			!this.state[`isRangeSelected_${name.slice(0, -1)}`]
		) {
			// Repeat the value for the range
			const otherRangeName = name.endsWith('1')
				? `${name.slice(0, -1)}2`
				: `${name.slice(0, -1)}1`;
			paramValues[id][otherRangeName] = props.value;
		}

		let sortedValues = {};
		const sorted = Object.keys(paramValues[id]).sort();
		sorted.forEach((key) => {
			sortedValues[key] = paramValues[id][key];
		});

		paramValues[id] = sortedValues;

		this.updateReportParams(id, sortedValues);

		let reportParams = {};
		reportParams[name] = props;
		this.setState((prevState) => ({
			reportParams: {
				...prevState.reportParams,
				...reportParams,
			},
			reportParamValues: paramValues,
		}));
	};

	handleRangeCheckbox(id, paramName, status) {
		let paramValues = this.state.reportParamValues;
		let options = {};
		if (paramValues[id]) {
			const paramValue1 = paramValues[id][`${paramName}1`];
			paramValues = {
				...paramValues,
				[id]: {
					...paramValues[id],
					[`${paramName}2`]: paramValue1,
				},
			};

			this.updateReportParams(id, paramValues);

			let reportParams = {};
			reportParams[`${paramName}2`] = paramValue1;
			options = {
				reportParams: {
					...this.state.reportParams,
					...reportParams,
				},
				reportParamValues: paramValues,
			};
		}

		this.setState({
			...options,
			[`isRangeSelected_${paramName}`]: !status,
		});
	}

	handleRangeChange = (e) => {
		let reportParams = JSON.parse(getLocalStorage(`report_param_edit`)) || [];

		const { name, value, dataset } = e.target;
		const { paramName } = dataset;
		const paramId = parseInt(name);

		const paramIndex = reportParams.findIndex((e) => e.parameterId === paramId);
		if (paramIndex > -1) {
			let currValue = JSON.parse(reportParams[paramIndex].value);
			currValue[paramName] = value;
			const newValue = JSON.stringify(currValue);
			reportParams[paramIndex].value = newValue;

			this.setState({ params: reportParams }, () => {
				this.inputRefs[this.state.focusedField].current.focus();
			});
		}

		setLocalStorage(`report_param_edit`, reportParams, true);
		setLocalStorageKeyValueObject(
			`report_edit`,
			'runReportParameters',
			reportParams,
			true
		);
	};

	handleDateRangeChange = (name, paramName, date) => {
		let reportParams = JSON.parse(getLocalStorage(`report_param_edit`)) || [];
		const paramId = parseInt(name);

		const paramIndex = reportParams.findIndex((e) => e.parameterId === paramId);
		if (paramIndex > -1) {
			let { activeReport } = this.state;
			const dateString = dayjs(date).format('YYYY-MM-DD');

			const reportIndex = activeReport.params.findIndex(
				(e) => e.id === paramId
			);

			if (!activeReport.params[reportIndex].defaultValue) {
				activeReport.params[reportIndex].defaultValue = {};
			}
			activeReport.params[reportIndex].defaultValue[paramName] = dateString;

			this.setState({
				activeReport,
			});

			let currValue = JSON.parse(reportParams[paramIndex].value);
			currValue[paramName] = dateString;
			reportParams[paramIndex].value = JSON.stringify(currValue);

			this.updateReportParams(paramId, currValue);
		}

		setLocalStorage(`report_param_edit`, reportParams, true);
		setLocalStorageKeyValueObject(
			`report_edit`,
			'runReportParameters',
			reportParams,
			true
		);
	};

	handleDateRangePickerChange(name, paramName, date) {
		const startDate = date ? dayjs(date[0]).format('MM/DD/YY') : '';
		const endDate = date ? dayjs(date[1]).format('MM/DD/YY') : '';
		let newValue = {};
		newValue[`${paramName}1`] = startDate;
		newValue[`${paramName}2`] = endDate;

		this.updateReportParams(name, newValue);

		let reportParam = [];
		reportParam[paramName] = date;

		this.setState((prevState) => ({
			reportParams: {
				...prevState.reportParams,
				...reportParam,
			},
		}));
	}

	handleDateChange = (name, paramName, type = '', date) => {
		let { activeReport } = this.state;
		let dateString = dayjs(date).format('YYYY-MM-DD');
		if (type !== '') {
			dateString =
				type === 'month'
					? dayjs(date).format('MM/YYYY')
					: dayjs(date).format('YYYY');
		}
		const reportIndex = this.state.activeReport.params.findIndex(
			(e) => e.id === name
		);
		activeReport.params[reportIndex].defaultValue =
			type === 'year' ? dateString : date;

		this.setState({
			activeReport,
		});

		let newValue = {};
		newValue[paramName] = dateString;
		this.updateReportParams(name, newValue);
	};

	handleCheckChange = (e) => {
		const { name, checked, dataset } = e.target;
		const { paramName } = dataset;
		let newValue = {};
		newValue[paramName] = checked;

		this.updateReportParams(name, newValue);

		this.setState((prevState) => ({
			checkedParams: {
				...prevState.checkedParams,
				...newValue,
			},
		}));
	};

	toggleRangeSelection = () => {
		this.setState((prevState) => ({
			isRangeSelected: !prevState.isRangeSelected,
		}));
	};

	handleTextChange = (e) => {
		const { name, value, dataset } = e.target;
		const { paramName } = dataset;
		let newValue = {};
		newValue[paramName] = value;
		this.setState({ focusedField: paramName });
		this.updateReportParams(name, newValue);
	};

	loadingWindowHTML() {
		return `<div style="position: absolute; display: flex; align-items: center; justify-content: center; width: 100%; height: 100vh; padding-left: 0px;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
            <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                <div>
                <img src="${window.location.origin}/logo-dark.svg?t=08022023" width="180" height="auto" class="d-inline-block align-top" alt="React Bootstrap logo">
                <br>
                <img src="${window.location.origin}/loader.svg?t=08022023" width="180" height="130" class="d-inline-block align-top" alt="React Bootstrap logo" style="margin-top: -40px;">
                </div>
            </div>
            </div>
        </div>`;
	}

	handleRunReport = async (e) => {
		const data = getLocalStorage('report_edit', true);
		if (!this.hasInvalidValue(data)) {
			const request = this.api.postJson('RunReports', data);
			// Lets solve popup blocker.
			const windowReport = window.open('', '_blank');
			windowReport.document.write(this.loadingWindowHTML());
			handleResponseAlert(request, (res) => {
				this.props.hideModal();
				displayAlert('success', 'Please wait for your report to be generated.');
				const reportId = res.id;

				const maxRequest = 5;
				let requestCount = 0;
				let interval = window.setInterval(async () => {
					try {
						const response = await this.api.get(
							'runreports',
							`?$filter=id eq ${reportId}`
						);

						const fileId = response[0].completedFileId;
						if (fileId) {
							clearInterval(interval);

							const { link } = await this.api.getPublicMappings(
								fileId,
								data.label
							);

							windowReport.location.href = link;
						} else if (requestCount === maxRequest) {
							clearInterval(interval);
							displayAlertError(
								'Report is on pending status. Try again later.'
							);
						}
					} catch (error) {
						displayAlertError(getErrorMessage(error));
						windowReport.close();
					}

					requestCount++;
				}, 10000);
			});
		}
	};

	hasInvalidValue(data) {
		const reportLabel = this.state.activeReport?.label ?? '';
		let hasError = false;
		if (ReportRequiredFields.hasOwnProperty(reportLabel)) {
			for (let param of data.runReportParameters) {
				const paramVal = JSON.parse(param.value);
				const paramName =
					Object.keys(paramVal).length > 1
						? Object.keys(paramVal)[0].slice(0, -1)
						: Object.keys(paramVal)[0];

				if (ReportRequiredFields[reportLabel].includes(paramName)) {
					if (Object.keys(paramVal).length > 1) {
						hasError =
							paramVal[`${paramName}1`] === '' ||
							paramVal[`${paramName}2`] === '';
					} else {
						hasError = paramVal[paramName] === '';
					}
				}

				if (hasError) {
					const reportParam = this.state.activeReport.params.find(
						(p) => p.id === param.parameterId
					);
					displayAlertError(`${reportParam.label} is required`);
					return hasError;
				}
			}
		}

		return hasError;
	}

	// getDateValueByKey(key, paramName = null) {
	//     if (this.state.activeReport !== undefined) {
	//         let { activeReport } = this.state
	//         const reportIndex = activeReport.params.findIndex(
	//             (e) => e.id === key
	//         )
	//
	//         let reportParam = activeReport.params[reportIndex]
	//         if (paramName !== null) {
	//             if (reportParam.defaultValue === null) {
	//                 const date = new Date()
	//                 const firstDay = new Date(
	//                     date.getFullYear(),
	//                     date.getMonth(),
	//                     1
	//                 )
	//                 const lastDay = new Date(
	//                     date.getFullYear(),
	//                     date.getMonth() + 1,
	//                     0
	//                 )
	//                 const baseName = paramName.slice(0, -1)
	//                 reportParam.defaultValue = {}
	//                 reportParam.defaultValue[`${baseName}1`] =
	//                     firstDay.toDateString()
	//                 reportParam.defaultValue[`${baseName}2`] =
	//                     lastDay.toDateString()
	//
	//                 this.handleDateRangeChange(
	//                     key,
	//                     `${baseName}1`,
	//                     reportParam.defaultValue[`${baseName}1`]
	//                 )
	//
	//                 this.handleDateRangeChange(
	//                     key,
	//                     `${baseName}2`,
	//                     reportParam.defaultValue[`${baseName}2`]
	//                 )
	//                 activeReport.params[reportIndex] = reportParam
	//
	//                 this.setState({
	//                     activeReport,
	//                 })
	//
	//                 // return new Date(reportParam.defaultValue[paramName])
	//                 return formatDate(reportParam.defaultValue[paramName])
	//             }
	//             // return new Date(reportParam.defaultValue[paramName])
	//             return formatDate(reportParam.defaultValue[paramName])
	//         } else {
	//             // return new Date(reportParam.defaultValue)
	//             return formatDate(reportParam.defaultValue)
	//         }
	//     } else {
	//         return new Date()
	//     }
	// }

	convertDefaultValueToArray(defaultVal) {
		const cleanStr = defaultVal.replace(/[^a-zA-Z0-9,]+/g, ' ');
		const listArr = cleanStr.split(',');
		return listArr.map((item) => item.trim());
	}

	getFormattedParamName(label) {
		return label.toLowerCase().split(' ').join('_');
	}

	renderReportParameters() {
		let reportParams = [];
		let reportContent = this.state.activeReport.params.map((param, index) => {
			const { typeLabel, reportData, reportId, id, defaultValue, objectType } =
				param;

			let { label } = param;
			label = label
				.replace('{ct1}', this.state.company.compviewd)
				.replace('{ct2}', this.state.company.compviewf)
				.replace('{ct3}', this.state.company.compviewi)
				.replace('{ct4}', this.state.company.compviewl)
				.replace('{ct5}', this.state.company.compviewo);
			const paramName = this.getFormattedParamName(label);
			let itemList = null;
			let reportParam = {};
			let initialValue = {};
			const { params } = this.state;
			let parsedValue = {};

			if ([1081, 1083].includes(reportId) && [420, 399].includes(id)) {
				param.defaultValue = 'Layout 1';
			}

			const paramIndex = params.findIndex((e) => e.parameterId === id);

			if (paramIndex > -1) {
				parsedValue = JSON.parse(params[paramIndex].value);
			}

			switch (typeLabel) {
				case 'account':
				case 'cash_account':
				case 'credit_card_account':
					initialValue[paramName] = parsedValue[paramName];
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					itemList = reportData.map((item) => ({
						value: item.account,
						label: `[${item.account}] ${item.accountn}`,
						paramName,
					}));

					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={itemList}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'boolean':
					initialValue[paramName] = parsedValue.hasOwnProperty(paramName)
						? parsedValue[paramName]
						: param.defaultValue.toLowerCase() === 'true';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Check
								inline
								label={label}
								name={id}
								type="checkbox"
								data-id={`th-0`}
								id={`inline-check-th-${id}`}
								data-param-name={paramName}
								checked={initialValue[paramName]}
								onChange={this.handleCheckChange}
							/>
						</ListFilter.Field>
					);
				case 'client':
					initialValue[paramName] = parsedValue[paramName] ?? '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<AsyncClientsDropdown
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									className="react-select w-100"
									placeholder="Please select"
									onChange={this.handleSelectReportParam}
									name={id}
									data-param-name={paramName}
									value={this.state.reportParams[paramName]}
									addKeyValuePairs={{
										paramName,
									}}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'date':
					initialValue[paramName] = parsedValue[paramName];
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<DatePicker
								selected={
									parsedValue[paramName]
										? new Date(parsedValue[paramName])
										: new Date()
								}
								showMonthDropdown="true"
								showYearDropdown="true"
								className="form-control"
								placeholderText="Select"
								data-param-name={paramName}
								onChange={this.handleDateChange.bind(this, id, paramName, '')}
							/>
						</ListFilter.Field>
					);
				case 'date_range':
					initialValue[`${paramName}1`] = parsedValue[`${paramName}1`];
					initialValue[`${paramName}2`] = parsedValue[`${paramName}2`];
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<DatePicker
								showMonthDropdown="true"
								showYearDropdown="true"
								className="form-control"
								placeholderText="Select"
								selected={
									parsedValue[`${paramName}1`]
										? new Date(`${parsedValue[`${paramName}1`]}T00:00:00`)
										: new Date()
								}
								data-param-name={`${paramName}1`}
								onChange={this.handleDateRangeChange.bind(
									this,
									id,
									`${paramName}1`
								)}
							/>
							<Form.Label column sm={2} className="text-center">
								Through
							</Form.Label>
							<DatePicker
								showMonthDropdown="true"
								showYearDropdown="true"
								className="form-control"
								placeholderText="Select"
								selected={
									parsedValue[`${paramName}2`]
										? new Date(`${parsedValue[`${paramName}2`]}T00:00:00`)
										: new Date()
								}
								data-param-name={`${paramName}2`}
								onChange={this.handleDateRangeChange.bind(
									this,
									id,
									`${paramName}2`
								)}
							/>
						</ListFilter.Field>
					);
				case 'integer':
					if (paramName === 'year') {
						initialValue[paramName] = parsedValue.hasOwnProperty(paramName)
							? parsedValue[paramName]
							: dayjs(new Date()).format('YYYY');
						reportParam.parameterId = id;
						reportParam.value = JSON.stringify(initialValue);
						reportParams.push(reportParam);
						return (
							<ListFilter.Field key={id} as={Row} className="mb-2">
								<Form.Label className="mb-0" column sm={3}>
									{label} - {parsedValue[paramName]}
								</Form.Label>
								<DatePicker
									selected={
										parsedValue.hasOwnProperty(paramName)
											? new Date(parsedValue[paramName], 0)
											: new Date()
									}
									showYearPicker
									dateFormat="yyyy"
									className="form-control"
									placeholderText="Select"
									data-param-name={paramName}
									onChange={this.handleDateChange.bind(
										this,
										id,
										paramName,
										'year'
									)}
								/>
							</ListFilter.Field>
						);
					} else {
						initialValue[paramName] = '';
						reportParam.parameterId = id;
						reportParam.value = JSON.stringify(initialValue);
						reportParams.push(reportParam);
						if (!this.inputRefs[`${paramName}`]) {
							this.inputRefs[`${paramName}`] = React.createRef();
						}
						return (
							<ListFilter.Field key={id} as={Row} className="mb-2">
								<Form.Label className="mb-0" column sm={3}>
									{label}
								</Form.Label>
								<Form.Control
									ref={this.inputRefs[`${paramName}`]}
									type="number"
									placeholder=""
									name={id}
									data-param-name={paramName}
									onChange={this.handleTextChange}
									value={parsedValue[`${paramName}`] ?? initialValue[paramName]}
								/>
							</ListFilter.Field>
						);
					}
				case 'list':
					const isLayout1 = param.defaultValue === 'Layout 1';
					const optionArray = this.convertDefaultValueToArray(
						param.defaultValue
					);
					const defaultValList = optionArray.map((item, i) => ({
						value: i,
						label: item,
						paramName,
					}));

					let defaultSelected =
						id === 419 || label === 'Month Ending'
							? defaultValList[new Date().getMonth()]
							: defaultValList[0];

					initialValue[paramName] = parsedValue.hasOwnProperty(paramName)
						? parsedValue[paramName]
						: 0;

					initialValue[paramName] =
						id === 419 || label === 'Month Ending'
							? defaultSelected.value
							: initialValue[paramName];

					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);

					return (
						<ListFilter.Field
							key={id}
							as={Row}
							className={`${isLayout1 ? 'd-none' : ''} mb-2`}
						>
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={defaultValList}
									defaultValue={defaultSelected}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'month':
					let fiscalMonth = new Date();
					fiscalMonth = new Date(
						fiscalMonth.setMonth(
							fiscalMonth.getMonth() + this.state.company.fiscaloffset
						)
					);

					const dateSelected = parsedValue[paramName]
						? dayjs(parsedValue[paramName], 'MM/YYYY').toDate()
						: dayjs(formatDate(fiscalMonth), 'MM/YYYY').toDate();
					initialValue[paramName] = dayjs(dateSelected).format('MM/YYYY');
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<DatePicker
								selected={dateSelected}
								showMonthYearPicker
								dateFormat="MM/yyyy"
								className="form-control"
								placeholderText="Select"
								data-param-name={paramName}
								onChange={this.handleDateChange.bind(
									this,
									id,
									paramName,
									'month'
								)}
							/>
						</ListFilter.Field>
					);
				case 'project':
					initialValue[paramName] = parsedValue[paramName] ?? '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					itemList = reportData.map((item) => ({
						value: item.proj,
						label: `[${item.proj}] ${item.projn}`,
						paramName,
					}));

					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<AsyncProjectsDropdown
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									className="react-select w-100"
									placeholder="Please select"
									onChange={this.handleSelectReportParam}
									name={id}
									data-param-name={paramName}
									value={this.state.reportParams[paramName]}
									addKeyValuePairs={{
										paramName,
									}}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'purchase_order':
					initialValue[paramName] = '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					itemList = reportData.map((item) => ({
						value: item.purchaseOrderName,
						label: item.purchaseOrderName,
						paramName,
					}));

					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={itemList}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'range':
					initialValue[`${paramName}1`] = parsedValue[`${paramName}1`] ?? '';
					initialValue[`${paramName}2`] = parsedValue[`${paramName}2`] ?? '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					if (label.toLowerCase().includes('dates')) {
						return (
							<ListFilter.Field key={id} as={Row} className="mb-2">
								<Form.Label className="mb-0" column sm={3}>
									{label}
								</Form.Label>
								<DateRangePicker
									data-param-name={`${paramName}`}
									placement="auto"
									placeholder="Select date range"
									format="MM/dd/yyyy"
									value={this.state.reportParams[paramName]}
									onChange={this.handleDateRangePickerChange.bind(
										this,
										id,
										paramName
									)}
								/>
							</ListFilter.Field>
						);
					} else {
						if (!this.inputRefs[`${paramName}1`]) {
							this.inputRefs[`${paramName}1`] = React.createRef();
						}
						if (!this.inputRefs[`${paramName}2`]) {
							this.inputRefs[`${paramName}2`] = React.createRef();
						}

						const isRange = this.state[`isRangeSelected_${paramName}`];

						return (
							<ListFilter.Field key={id} as={Row} className="mb-2">
								<Form.Label className="mb-0" column sm={3}>
									{label}
								</Form.Label>

								<FeatureFlagComponentResolver
									featureFlag={FeatureFlags.Reporting}
									componentEnabled={
										<>
											<Col sm={8}>
												<Row>
													{isRange ? (
														<Row>
															<Col sm={5}>
																<AsyncDropdownType
																	selectType={objectType}
																	value={
																		this.state.reportParams[`${paramName}1`]
																	}
																	ref={this.inputRefs[`${paramName}1`]}
																	name={`${id}|${paramName}1`}
																	placeholder="Please Select"
																	data-param-name={`${paramName}1`}
																	onChange={this.handleComponentDropdownChange}
																/>
															</Col>
															<Col
																sm={2}
																className="d-flex justify-content-center align-items-center"
															>
																<Form.Label className="mb-0">
																	Through
																</Form.Label>
															</Col>
															<Col sm={5}>
																<AsyncDropdownType
																	selectType={objectType}
																	value={
																		this.state.reportParams[`${paramName}2`]
																	}
																	ref={this.inputRefs[`${paramName}2`]}
																	name={`${id}|${paramName}2`}
																	placeholder="Please Select"
																	data-param-name={`${paramName}2`}
																	onChange={this.handleComponentDropdownChange}
																/>
															</Col>
														</Row>
													) : (
														// Render a single dropdown
														<AsyncDropdownType
															selectType={objectType}
															ref={this.inputRefs[`${paramName}1`]}
															value={this.state.reportParams[`${paramName}1`]}
															name={`${id}|${paramName}1`}
															placeholder="Select"
															data-param-name={`${paramName}1`}
															onChange={this.handleComponentDropdownChange}
														/>
													)}
												</Row>
											</Col>
											<Col sm={3}>
												<Form.Check
													type="checkbox"
													label="Use Range"
													checked={isRange || false}
													onChange={() =>
														this.handleRangeCheckbox(id, paramName, isRange)
													}
													className="ms-3"
												/>
											</Col>
										</>
									}
									componentDisabled={
										<>
											<Form.Control
												ref={this.inputRefs[`${paramName}1`]}
												name={id}
												type="text"
												placeholder=""
												data-param-name={`${paramName}1`}
												value={parsedValue[`${paramName}1`] ?? ''}
												onChange={(e) =>
													this.handleFieldChange(`${paramName}1`, e)
												}
											/>
											<Form.Label column sm={2} className="text-center">
												Through
											</Form.Label>
											<Form.Control
												ref={this.inputRefs[`${paramName}2`]}
												name={id}
												type="text"
												placeholder=""
												data-param-name={`${paramName}2`}
												value={parsedValue[`${paramName}2`] ?? ''}
												onChange={(e) =>
													this.handleFieldChange(`${paramName}2`, e)
												}
											/>
										</>
									}
								/>
							</ListFilter.Field>
						);
					}
				case 'stock':
					initialValue[paramName] = '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					itemList = reportData.map((item) => ({
						value: item.stockno,
						label: `[${item.stockno}] ${item.itemName}`,
						paramName,
					}));

					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={itemList}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'text':
					initialValue[paramName] = '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Form.Control
								type="text"
								placeholder=""
								name={id}
								data-param-name={paramName}
								onChange={this.handleTextChange}
							/>
						</ListFilter.Field>
					);
				case 'vendor':
					initialValue[paramName] = '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					itemList = reportData.map((item) => ({
						value: item.vendor,
						label: `[${item.vendor}] ${item.vendorn}`,
						paramName,
					}));

					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={itemList}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'item_status':
					initialValue[paramName] = 0;
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					const allItems = {
						value: 0,
						label: 'All Items',
						paramName,
					};
					itemList = reportData.map((item, i) => ({
						value: i + 1,
						label: item.statusName,
						paramName,
					}));
					itemList = [allItems].concat(itemList);

					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={itemList}
									defaultValue={itemList[0]}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'client_type':
				case 'vendor_type':
				case 'shipto_type':
					initialValue[paramName] = '';
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);
					const addressList = reportData.map((item, i) => ({
						value: i,
						label: item,
						paramName,
					}));
					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={addressList}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				case 'user_list':
					initialValue[paramName] = parsedValue[paramName];
					reportParam.parameterId = id;
					reportParam.value = JSON.stringify(initialValue);
					reportParams.push(reportParam);

					const all = {
						value: 0,
						label: 'All',
						paramName,
					};

					itemList = reportData.map((item) => ({
						value: item.usercode,
						label: item.username,
						paramName,
					}));

					itemList = [all, ...itemList];

					const defaultSelectedUser = itemList[0];

					return (
						<ListFilter.Field key={id} as={Row} className="mb-2">
							<Form.Label className="mb-0" column sm={3}>
								{label}
							</Form.Label>
							<Col>
								<Select
									menuPortalTarget={document.body}
									styles={{
										menuPortal: (base) => ({
											...base,
											zIndex: 99999,
										}),
									}}
									name={id}
									data-param-name={paramName}
									options={itemList}
									defaultValue={defaultSelectedUser}
									className="react-select"
									placeholder="Please select"
									value={this.state.reportParams[paramName]}
									onChange={this.handleSelectReportParam}
								/>
							</Col>
						</ListFilter.Field>
					);
				default:
					return <></>;
			}
		});

		const storageReport =
			JSON.parse(getLocalStorage(`report_param_edit`)) || [];
		if (!storageReport.length) {
			setLocalStorage(`report_param_edit`, reportParams, true);
			setLocalStorageKeyValueObject(
				`report_edit`,
				'runReportParameters',
				reportParams,
				true
			);
		}

		return reportContent;
	}

	render() {
		return (
			<Modal
				size="lg"
				show={this.props.show}
				onHide={this.props.hideModal.bind(null)}
				animation={false}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{this.state.activeReport.label}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="w-100 px-5">
						<Col sm={12}>
							{this.state.activeReport.reportFormats.length > 1 && (
								<ListFilter.Field key="reportFormats" as={Row} className="mb-2">
									<Form.Label className="mb-0" column sm={3}>
										Report Formats
									</Form.Label>
									<Col>
										<Select
											menuPortalTarget={document.body}
											styles={{
												menuPortal: (base) => ({
													...base,
													zIndex: 99999,
												}),
											}}
											options={this.state.activeReport.reportFormats}
											value={this.state.reportFormats}
											className="react-select"
											placeholder="Please select"
											onChange={this.handleSelectReportFormat}
										/>
									</Col>
								</ListFilter.Field>
							)}
							{this.renderReportParameters()}
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.props.hideModal.bind(null)}>
						Close
					</Button>
					<Button
						variant="primary"
						disabled={
							this.props.report?.id === this.BUDGET_PROJECT_ANALYSIS_ID &&
							!this.state.reportParams?.['project_/_order']?.value
						}
						onClick={this.handleRunReport}
					>
						Run Report
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(ReportModal);
