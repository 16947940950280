import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { TRecentProject } from '../../legacy/lib/api/types/RecentProject';
import { getCashflowProjects } from 'api/resources/cashflows';

export const useGetCashflowProjects = (filter = '') => {
	const [allProjects, setAllProjects] = useState<TRecentProject[]>([]);

	const { refetch, isFetching, isLoading } = useQuery<TRecentProject[]>(
		['projects', filter],
		() => getCashflowProjects(filter),
		{
			refetchOnWindowFocus: false,
			onSuccess: (newData) => {
				setAllProjects(newData);
			},
		}
	);

	return {
		projects: allProjects,
		isFetching,
		isLoading,
		refetch,
	};
};
