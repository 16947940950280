import React from 'react';
import { Col, Row, Form, Container, Modal, Button } from 'react-bootstrap';
import { WithRouter } from '../../../utilities/Router';
import { InlineFormGroupField } from '../../components/Form';
import Header from '../../components/Header';
import MyDropzone from '../../ui-kit/MyDropzone';
import ThreadComponent from '../ThreadComponent';
import { ApiService } from '../../../lib/api/HttpService';
import WysiwygEditor from '../../ui-kit/WysiwygEditor';
import {
	FormEvent,
	setDraft,
	setLocalStorageKeyValueObject,
} from '../../../utilities/FormEvent';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../utilities/LocalStorage';
import {
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
} from '../../../utilities/Response';
import Select from 'react-select';
import { _delay } from '../../../helpers/Util';
import MSG from '../../../defaults/Message';
import Spinner from '../../../app/components/help/Spinner';
import { mapRtfToDraft } from '../../../utilities/Rtf';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import AsyncLocationsDropdown from '../../../app/components/dropdowns/AsyncLocationsDropdown';
import AsyncProjectsDropdown from '../../../app/components/dropdowns/AsyncProjectsDropdown';
import AsyncVendorsDropdown from '../../../app/components/dropdowns/AsyncVendorsDropdown';
import AsyncSalesCategoriesDropdown from '../../../app/components/dropdowns/AsyncSalesCategoriesDropdown';

class ClippedItemsModal extends ThreadComponent {
	componentInit() {
		this.title = 'Edit Clipped Items';
	}

	constructor(props) {
		super(props);
		this.state = {
			checks: {},
			data: {},
			descHtml: '',
			componentDescriptionHtml: '',
			projects: [],
			locations: [],
			filteredLocations: [],
			vendors: [],
			unitsOfMeasures: [],
			imageLoaded: false,
			image: '',
			updatedCost: {},
			dataIsLoaded: false,
			showLocationModal: false,
			locAddName: '',
			projectCode: null,
			clearedSelect: [],
			locationsQry: `?$filter=projectId eq null&$orderby=locn asc`,
		};
		this.uploadedImage = null;
		this.api = new ApiService();
		// This binding is necessary to make `this` work in the callback
		this.handleChecks = this.handleChecks.bind(this);
		this.formEvent = new FormEvent(`clipped_items_edit`);
		this.htmlToRtfConverter = new HtmlToRtfBrowser();
	}

	async componentDidMount() {
		let unitsOfMeasures = await this.api.getUnitsOfMeasures();
		unitsOfMeasures = unitsOfMeasures.map((i) => {
			return {
				value: i.value,
				label: i.value,
			};
		});

		this.setState({
			unitsOfMeasures,
		});

		if (Object.keys(this.props.data).length > 0) {
			let data = this.props.data || {};
			let item = {};
			if (data.itemCaptureId) {
				item = await this.api.getClippedItems(`/${data.itemCaptureId}`);

				item = await this.addImageData(item);
			}

			this.renderData(item);
		}
	}

	handleWysiwygChange = (e, field) => {
		this.setState({
			[field]: this.htmlToRtfConverter.convertHtmlToRtf(e.target.html),
		});
	};

	async componentDidUpdate(previousProps, previousState) {
		if (previousProps.data !== this.props.data) {
			let data = this.props.data;
			let item = {};

			this.setState({
				dataIsLoaded: false,
			});

			if (data.itemCaptureId) {
				item = await this.api.getClippedItems(`/${data.itemCaptureId}`);

				item = await this.addImageData(item);
				this.setState({
					projectCode: item.project?.projectCode,
					locationsQry: `?$filter=(projectId eq null${
						item.project?.id ? ` or projectId eq ${item.project?.id}` : ''
					})&$orderby=locn asc`,
					selectedProject: item.project?.projectCode
						? {
								label: `${item.project?.projectName} [${item.project?.projectCode}]`,
								value: item.project?.projectCode,
						  }
						: null,
					selectedSalesCategory: item.salesCategory?.salesCategoryId
						? {
								label: `${item.salesCategory?.salesCategoryName} [${item.salesCategory?.salesCategoryCode}]`,
								value: item.salesCategory?.salesCategoryId,
						  }
						: null,
				});
			}

			item.location = await this.setLocation(item);

			this.renderData(item);
		}
	}

	async getRtfContent(content) {
		const rtfPattern =
			/\\(rtf|par|pard|ansi|ansicpg|fonttbl|colortbl|red|green|blue|fnil|fs|cf|highlight|stylesheet|listtable|listlevel|listoverridetable|\\*|\\s*)/i;

		if (!rtfPattern.test(content)) {
			return await mapRtfToDraft(
				this.htmlToRtfConverter.convertHtmlToRtf(content) ?? ''
			);
		} else {
			return await mapRtfToDraft(content);
		}
	}

	async renderData(data, isSelect) {
		const { updatedCost } = this.state;

		if (isSelect !== true) {
			const descHtml = data.itemDescription;
			data.itemDescription = await this.getRtfContent(
				data.itemDescription ?? ''
			);

			const componentDescriptionHtml = data.componentDescription;
			data.componentDescription = await this.getRtfContent(
				data.componentDescription ?? ''
			);

			let selectedVendor = null;
			if (data?.vendor) {
				selectedVendor = {
					label: `${data?.vendor?.vendorName ?? ''} [${
						data?.vendor?.vendorCode
					}]`.trim(),
					value: `${data?.vendor?.id}`,
				};
			}

			this.setState({
				descHtml,
				componentDescriptionHtml,
				selectedVendor,
			});
		}

		this.setState({
			dataIsLoaded: true,
			data: data,
			updatedCost: {
				quantity: updatedCost?.quantity ?? data?.quantity ?? 0,
				unitPrice: updatedCost?.unitPrice ?? data.unitPrice ?? 0,
				unitCost: updatedCost?.unitCost ?? data.unitCost ?? 0,
			},
		});
		setLocalStorage(`clipped_items_edit`, data, true);
	}

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			prevState.checks[indx] = e.target.checked;

			return prevState;
		});
	};

	handleSave = async (e) => {
		let item = getLocalStorage(`clipped_items_edit`, true);
		const formattedItem = this.formatRequestParams(item);
		const id = this.state.data.id;

		displayAlertLoader(MSG.loading.update.msg);

		await this.uploadImage();
		const resp = this.api.put('ItemCaptures', id, formattedItem);

		resp
			.then((resp) => {
				displayAlert('success', MSG.success.msg);

				_delay(async () => {
					window.onbeforeunload = null;
					setDraft(false);
					await this.props.handleUpdate();
				}, 300);
			})
			.catch((e) => {
				displayAlert('danger', getErrorMessage(e));
			});

		this.handleCloseModal();
	};

	formatRequestParams(data) {
		return {
			itemDescription: this.state.descHtml,
			itemTitle: data.itemTitle,
			vendorObjectType: data.vendor
				? data.vendor.objectType ?? 'ExistingObject'
				: null,
			vendorId: data.vendor ? data.vendor.id : null,
			projectObjectType: data.project
				? data.project.objectType ?? 'ExistingObject'
				: null,
			projectId: data.project ? data.project.id : null,
			existingLocationId: data.location ? data.location.id : null,
			unitOfMeasure: data.unitOfMeasure,
			groupName: data.groupName,
			componentDescription: this.state.componentDescriptionHtml,
			existingSalesCategoryId: data.salesCategory
				? data.salesCategory.salesCategoryId
				: null,
			sku: data.sku,
			unitPrice: data.unitPrice,
			unitCost: data.unitCost,
			quantity: data.quantity,
			shippingCost: data.shippingCost,
			url: data.url,
			primaryImageId: data.primaryImageId,
		};
	}

	handleCloseModal = (e) => {
		this.uploadedImage = null;
		this.props.hideModal();
	};

	handleSelectChange = async (e, select) => {
		let id = e?.value;
		let propId = e?.propId;
		const name = select.name;
		let data = getLocalStorage(`clipped_items_edit`, true);
		if (!e) {
			id = null;
		}

		if (name === 'project') {
			propId = 'id';
			this.setState({
				projectCode: e?.value,
				selectedProject: e,
			});
			const [project] = await this.api.getProjects(
				`?$filter=proj eq '${encodeURIComponent(id)}' and closeddt eq null`
			);

			if (project) {
				id = project?.id;
				this.setState({
					locationsQry: `?$filter=(projectId eq null or projectId eq ${id})&$orderby=locn asc`,
				});
			}

			if (this.state.selectedLocation) {
				// Check if we can find the location inside the project.
				const { value } = this.state.selectedLocation;
				const [loc] = await this.api.getLocations(
					`?$filter=loc eq '${encodeURIComponent(
						value
					)}' and (projectId eq null or projectId eq ${id})`
				);
				// If location is not found in the project, reset the selected location to null.
				if (!loc) {
					data['location'] = null;
					data['location'] = await this.setLocation(data);
				}
			}
		} else if (name === 'vendor') {
			propId = 'id';
			this.setState({
				selectedVendor: e,
			});
		} else if (name === 'salesCategory') {
			this.setState({
				selectedSalesCategory: e,
			});
		}

		if (propId) {
			if (data[name]) {
				data[name][propId] = id;
			} else {
				data[name] = {};
				data[name][propId] = id;
			}
		} else {
			data[name] = id;
		}

		if (name === 'location') {
			data['location'] = e;
			data['location'] = await this.setLocation(data);
		}

		data['primaryImage'] = this.state.data.primaryImage;
		this.renderData(data, true);
	};

	async setLocation(data) {
		if (!data['location'] || data['location']?.value) {
			this.setState({
				selectedLocation: data['location'],
			});
		}

		const filter = data['location']?.id
			? `id eq ${data['location']?.id}`
			: `loc eq '${encodeURIComponent(data['location']?.value)}'`;
		const [location] = await this.api.getLocations(
			`?$filter=${filter} and (projectId eq null or projectId eq ${
				data['project']?.id ?? 'null'
			})`
		);

		if (location) {
			this.setState({
				selectedLocation: {
					label: `${location?.locn} [${location?.loc}]`,
					value: location?.loc,
				},
			});
		}

		return location;
	}

	handleCostChange = (e) => {
		const value = e.target.value ? e.target.value : 0;
		let { updatedCost, data } = this.state;
		updatedCost[e.target.name] = parseFloat(value);
		data[e.target.name] = parseFloat(value);

		let totalCost = updatedCost.quantity * updatedCost.unitCost;
		totalCost = totalCost.toFixed(2);
		let totalPrice = updatedCost.quantity * updatedCost.unitPrice;
		totalPrice = totalPrice.toFixed(2);

		this.setState({
			updatedCost,
			data: { ...data, totalCost, totalPrice },
		});
		setLocalStorageKeyValueObject(`clipped_items_edit`, 'totalCost', totalCost);
		setLocalStorageKeyValueObject(
			`clipped_items_edit`,
			'totalPrice',
			totalPrice
		);
		this.formEvent.handleTextChange(e);
	};

	async addImageData(data) {
		if (!data.primaryImageId) {
			return data;
		}

		let itemImage = await this.api.getFile(
			data.primaryImageId + '?maxHeight=300'
		);
		data.primaryImage = itemImage;

		return data;
	}

	onAcceptedFiles = (file) => {
		this.uploadedImage = file;
	};

	onRemoveFiles = () => {
		this.setState({
			data: {
				...this.state.data,
				primaryImage: null,
			},
		});
		this.uploadedImage = null;
	};

	async uploadImage() {
		const id = this.state.data.id;

		// Check if image is removed or there is an updated image
		if (
			(!this.state.data.primaryImage && this.state.data.primaryImageId) ||
			(this.state.data.primaryImageId && this.uploadedImage)
		) {
			const path = `${id}/images/${this.state.data.primaryImageId}`;
			await this.api.deleteItemCaptureImage(path);
		}

		if (!this.uploadedImage) {
			return;
		}

		let formData = new FormData();
		formData.append('file', this.uploadedImage, this.uploadedImage.name);
		formData.append('isPrimary', 'true');

		await this.api.saveItemCaptureImage(id, formData);
	}

	renderHeader() {
		return (
			<Header>
				<Header.Title>Clipped Items</Header.Title>
			</Header>
		);
	}

	render() {
		return (
			<>
				<Modal
					size="xl"
					show={this.props.show}
					onHide={this.handleCloseModal}
					animation={false}
					centered
					dialogClassName="modal-90w"
					backdrop="static"
				>
					<>
						<Modal.Header closeButton>
							<Modal.Title>Clipped Item Edit</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Container className="ms-0">
								{this.state.dataIsLoaded ? (
									<Row className="m-3">
										<Col lg={4} className="mb-5 mb-lg-0">
											<div className="form-fields mb-5">
												{this.uploadedImage ||
												this.state.data.primaryImageId ? (
													<MyDropzone
														image={
															this.uploadedImage ?? this.state.data.primaryImage
														}
														onAcceptedFiles={this.onAcceptedFiles}
														onRemoveFiles={this.onRemoveFiles}
													/>
												) : (
													<MyDropzone
														onAcceptedFiles={this.onAcceptedFiles}
														onRemoveFiles={this.onRemoveFiles}
													/>
												)}
											</div>
											<div className="form-fields">
												<Col>
													<Form.Label className="mb-1">Item Name</Form.Label>
												</Col>
												<Col className="mb-3">
													<Form.Control
														type="text"
														placeholder="Please enter"
														name="itemTitle"
														defaultValue={this.state.data.itemTitle}
														onChange={this.formEvent.handleTextChange.bind(
															this
														)}
													/>
												</Col>
												<Form.Group
													as={Row}
													className="mb-1"
													controlId="formPlaintextEmail"
												>
													<Form.Label column lg="4">
														My Total Cost
													</Form.Label>
													<Form.Label
														column
														lg={{
															span: 4,
															offset: 4,
														}}
													>
														Total Price
													</Form.Label>
												</Form.Group>
												<Form.Group
													as={Row}
													className="mb-3"
													controlId="formPlaintextEmail"
												>
													<Col lg="4">
														<div className="form-group-extra">
															<Form.Control
																type="text"
																size="sm"
																name="totalCost"
																value={this.state.data.totalCost}
																onChange={this.formEvent.handleTextChange.bind(
																	this
																)}
																disabled={true}
															/>
														</div>
													</Col>
													<Col
														lg={{
															span: 4,
															offset: 4,
														}}
													>
														<div className="form-group-extra">
															<Form.Control
																type="text"
																size="sm"
																name="totalPrice"
																value={this.state.data.totalPrice}
																onChange={this.formEvent.handleTextChange.bind(
																	this
																)}
																disabled={true}
															/>
														</div>
													</Col>
												</Form.Group>
											</div>
										</Col>
										<Col lg={8}>
											<div className="form-fields">
												<InlineFormGroupField
													label="Project"
													controlId="formTimeEntryDateTime"
													classNameParent="mb-3"
												>
													<AsyncProjectsDropdown
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 99999,
															}),
														}}
														name="project"
														className="react-select"
														defaultValue={this.state.selectedProject}
														value={
															this.state.projects.filter((project) => {
																return (
																	project.value === this.state.data.project?.id
																);
															})[0]
														}
														placeholder="Please select"
														onChange={this.handleSelectChange}
													/>
												</InlineFormGroupField>
												<InlineFormGroupField
													label="Location"
													controlId="formTimeEntryDateTime"
													classNameParent="mb-3"
												>
													<Row>
														<Col lg={12}>
															<AsyncLocationsDropdown
																key={`${Math.floor(Math.random() * 1000)}-min`}
																menuPortalTarget={document.body}
																styles={{
																	menuPortal: (base) => ({
																		...base,
																		zIndex: 99999,
																	}),
																}}
																name="location"
																className="react-select"
																defaultValue={this.state.selectedLocation}
																placeholder="Please select"
																onChange={this.handleSelectChange}
																projectCode={this.state.projectCode}
																isClearable={true}
																urlQuery={this.state.locationsQry}
																creatable={true}
															/>
														</Col>
													</Row>
												</InlineFormGroupField>
												{/* <InlineFormGroupField
                                            label="Group"
                                            controlId="formTimeEntryDateTime"
                                            classNameParent="mb-3"
                                        >
                                            <Form.Control
                                                type="text"
                                                data-field="stockno"
                                                placeholder="Please enter"
                                                name="groupName"
                                                onChange={this.formEvent.handleTextChange.bind(
                                                    this
                                                )}
                                                defaultValue={
                                                    this.state.data.groupName
                                                }
                                            />
                                        </InlineFormGroupField> */}
												<InlineFormGroupField
													label="Sales Category"
													controlId="formTimeEntryDateTime"
													classNameParent="mb-3"
												>
													<AsyncSalesCategoriesDropdown
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 99999,
															}),
														}}
														name="salesCategory"
														className="react-select"
														valueKey="id"
														defaultValue={this.state.selectedSalesCategory}
														placeholder="Please select"
														onChange={this.handleSelectChange}
														addKeyValuePairs={{
															propId: 'salesCategoryId',
														}}
													/>
												</InlineFormGroupField>
												<InlineFormGroupField
													label="Client Description"
													controlId="formTimeEntryDateTime"
													classNameParent="mb-3"
													labelTop={true}
												>
													<WysiwygEditor
														onChange={(e) =>
															this.handleWysiwygChange(e, 'descHtml')
														}
													>
														{this.state.data.itemDescription}
													</WysiwygEditor>
												</InlineFormGroupField>
												<InlineFormGroupField
													label="Vendor"
													controlId="formTimeEntryDateTime"
													classNameParent="mb-3"
												>
													<AsyncVendorsDropdown
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 99999,
															}),
														}}
														className="react-select"
														name="vendor"
														defaultValue={this.state.selectedVendor}
														placeholder="Please select"
														onChange={this.handleSelectChange}
														isClearable={true}
														creatable={true}
														valueKey="id"
														addKeyValuePairs={{
															propId: 'id',
														}}
													/>
												</InlineFormGroupField>
												<InlineFormGroupField
													label="PO Description"
													controlId="formTimeEntryDateTime"
													classNameParent="mb-3"
													labelTop={true}
												>
													<WysiwygEditor
														onChange={(e) =>
															this.handleWysiwygChange(
																e,
																'componentDescriptionHtml'
															)
														}
													>
														{this.state.data.componentDescription}
													</WysiwygEditor>
												</InlineFormGroupField>
												<InlineFormGroupField
													label="Sku/Mfg. Cat. No."
													controlId="formTimeEntryDateTime"
													classNameParent="mb-3"
												>
													<Form.Control
														type="text"
														data-field="stockno"
														placeholder="Please enter"
														onChange={(e) => {
															let updatedEvent = e;
															updatedEvent.target.value =
																e.target.value.substring(0, 30);
															this.formEvent.handleTextChange(updatedEvent);
														}}
														name="sku"
														defaultValue={this.state.data.sku}
													/>
												</InlineFormGroupField>
												<Form.Group
													as={Row}
													className="mb-3"
													controlId="formPlaintextEmail"
												>
													<Form.Label
														column
														lg="2"
														className="pr-0 align-right fw-500"
													>
														Quantity
													</Form.Label>
													<Col lg="4">
														<div className="form-group-extra">
															<Form.Control
																type="text"
																size="sm"
																name="quantity"
																onChange={this.handleCostChange}
																defaultValue={this.state.data.quantity}
															/>
														</div>
													</Col>
													<Form.Label
														column
														lg="2"
														className="pr-0 align-right fw-500"
													>
														Unit of Measurement
													</Form.Label>
													<Col lg="4">
														<div className="form-group-extra">
															<Select
																menuPortalTarget={document.body}
																styles={{
																	menuPortal: (base) => ({
																		...base,
																		zIndex: 99999,
																	}),
																}}
																options={this.state.unitsOfMeasures}
																isClearable={true}
																name="unitOfMeasure"
																className="react-select"
																value={
																	this.state.unitsOfMeasures.filter((unit) => {
																		return (
																			unit.value ==
																			(this.state.data.unitOfMeasure ??
																				this.state.data.vendor?.value)
																		);
																	})[0]
																}
																placeholder="Please select"
																onChange={this.handleSelectChange}
															/>
														</div>
													</Col>
												</Form.Group>
												<Form.Group
													as={Row}
													className="mb-3"
													controlId="formPlaintextEmail"
												>
													<Form.Label
														column
														lg="2"
														className="pr-0 align-right fw-500"
													>
														My Unit Cost
													</Form.Label>
													<Col lg="4">
														<div className="form-group-extra">
															<Form.Control
																type="text"
																size="sm"
																name="unitCost"
																defaultValue={this.state.data.unitCost}
																onChange={this.handleCostChange}
															/>
														</div>
													</Col>
													<Form.Label
														column
														lg="2"
														className="pr-0 align-right fw-500"
													>
														Unit Price
													</Form.Label>
													<Col lg="4">
														<div className="form-group-extra">
															<Form.Control
																type="text"
																size="sm"
																name="unitPrice"
																defaultValue={this.state.data.unitPrice}
																onChange={this.handleCostChange}
															/>
														</div>
													</Col>
												</Form.Group>
												<Form.Group
													as={Row}
													className="mb-3"
													controlId="formPlaintextEmail"
												>
													<Form.Label
														column
														lg="2"
														className="pr-0 align-right fw-500"
													>
														Shipping Cost
													</Form.Label>
													<Col lg="4">
														<div className="form-group-extra">
															<Form.Control
																type="text"
																size="sm"
																name="shippingCost"
																defaultValue={this.state.data.shippingCost}
																onChange={this.formEvent.handleTextChange.bind(
																	this
																)}
															/>
														</div>
													</Col>
												</Form.Group>
											</div>
										</Col>
									</Row>
								) : (
									<Row className="m-3 py-5">
										<Col sm={12} className="py-5">
											<Spinner></Spinner>
										</Col>
									</Row>
								)}
							</Container>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="primary"
								onClick={this.handleSave}
								className="me-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash me-2"
								onClick={this.handleCloseModal}
							>
								Cancel
							</Button>
						</Modal.Footer>
					</>
				</Modal>
			</>
		);
	}
}

export default WithRouter(ClippedItemsModal);
