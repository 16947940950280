import React from 'react';
import { Col, Row, Form, Button, Container } from 'react-bootstrap';
import ThreadComponent from '../ThreadComponent';
import { WithRouter } from '../../../helpers/Router';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../lib/api/HttpService';
import { FooterFormAction } from '../../components/Form';
import { toSortName } from '../../../utilities/String';
import { isEmpty } from 'lodash';
import InputMasked from '../../../app/components/form/MaskedInput';
import AsyncEmployeesDropdown from '../../../app/components/dropdowns/AsyncEmployeesDropdown';
import { setDraft } from '../../../utilities/FormEvent';
import AsyncSalesTaxCodesDropdown from '../../../app/components/dropdowns/AsyncSalesTaxCodesDropdown';
import AsyncAddressesDropdown from '../../../app/components/dropdowns/AsyncAddressesDropdown';
import SecureBootstrapButton from '../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../app/context/security';

class ProjectViewInfo extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			clientDropdownOptions: [],
			startDate: '',
			isEnabled1: false,
			isEnabled2: false,
			dataLoaded: false,
			data: { addressBill: {}, addressSite: {} },
			sitesameasbill: false,
			sortType: { value: 2, label: 'Manual' },
			project: {},
			shiptoQuery: '?$filter=addresstype eq 1',
		};
		this.updatedData = props.updatedData;
		this.updatedBillingAddress = props.updatedBillingAddress;
		this.updatedSiteAddress = props.updatedSiteAddress;
		this.api = new ApiService();
		this.clientData = [];
		this.sortTypes = [
			{ value: 0, label: 'Name' },
			{ value: 1, label: 'Company' },
			{ value: 2, label: 'Manual' },
		];
		// This binding is necessary to make `this` work in the callback
		this.handleInputChange = this.handleInputChange.bind(this);
		this.setIsChildSpinner(true);
		this.setIsLoaded(true);
	}

	async componentDidMount() {
		if (!isEmpty(this.props.project?.proj)) {
			this.setIsLoaded(false);
			this.setState(
				{
					project: this.props.project,
				},
				this.loadData
			);
		}
	}

	async componentDidUpdate(previousProps, previousState) {
		let project = {};
		if (previousProps.project !== this.props.project) {
			project = this.props.project || {};

			this.setIsLoaded(false);
			this.setState(
				{
					project,
				},
				this.loadData
			);
		}
	}

	async loadData() {
		if (isEmpty(this.state.project?.proj)) {
			return;
		}
		const projects = await this.api.getProjects(
			`?$filter=proj eq '${this.state.project?.proj}'`
		);
		const project = projects[0];
		const addresses = await this.api.getAddresses(
			'?$filter=addresstype in (1,6) and inactive eq false'
		);
		const billingAddresses = await this.api.getAddresses(
			`?$filter=code eq '${encodeURIComponent(
				this.state.project?.proj
			)}' and addresstype eq 0`
		);

		project.addressSite = {};
		project.addressShip = {};
		project.addressBill = {};
		project.addressShipOptions = [];
		project.selectedTaxCode = this.state.project?.selectedTaxCode;
		let selectedManager;
		if (project?.manager) {
			const { id, key, value } = await this.api.getClientEmployeeSummaryByCode(
				project.manager
			);

			selectedManager = {
				id: id.toString(),
				value: key,
				label: `${value} [${key}]`,
				name: value,
			};
		}

		if (project?.taxc) {
			const tx = await this.api.getSalesTaxCodeSummaryByCode(project.taxc);
			if (tx) {
				const { key, value } = tx;
				project.selectedTaxCode = {
					value: key,
					label: `${value} [${key}]`,
				};
				project.taxcn = value;
			}
		}

		for (let address of addresses) {
			if (
				address.addressTypeEnum === 'ProjectSite' &&
				address.code === this.state.project?.proj
			) {
				project.addressSite = address;
				this.updatedSiteAddress.id = address.id;
			} else if (address.addressTypeEnum === 'ProjectShipTo') {
				project.addressShip = addresses;
				let options = {
					value: address.code,
					label: `${address.attention} [${address.code}]`,
				};
				project.addressShipOptions.push(options);
			}
		}

		if (project.shipto) {
			project.addressShipSelected = await this.api.getAddressByCode(
				project.shipto,
				true,
				this.state.shiptoQuery.replace('?$filter=', '')
			);
		}

		if (billingAddresses[0]) {
			project.addressBill = billingAddresses[0];
			this.updatedBillingAddress.id = billingAddresses[0].id;
		}

		const clients = await this.api.getClientsSummaries();

		const clientDropdownOptions = clients?.map(({ key, value }) => ({
			label: `${value ?? ''} [${key}]`.trim(),
			value: key,
		}));

		if (project.clientId) {
			const client = clients.find((client) => client.key === project.client);
			this.setState({
				selectedClient: {
					label: `${client.value ?? ''} [${client.key}]`.trim(),
					value: client.key,
				},
			});
		}

		this.setState({
			selectedManager,
			clientDropdownOptions,
			data: project,
			sitesameasbill: project.sitesameasbill,
			isEnabled2:
				this.updatedData.isEnabled2 ?? project.proj === project.shipto,
		});
		this.setIsLoaded(true);
	}

	handleInputChange = (e) => {
		// Get the target menu.
		const id = e.target.getAttribute('data-state');

		// Set the new state.
		this.setState((prevState) => {
			let data = {};
			data[id] = e.target.checked;

			return data;
		});
	};

	handleUseSiteChange = (e) => {
		if (e.target.checked) {
			this.updatedData.shipto = this.state.data.proj;
		} else {
			delete this.updatedData.shipto;
		}
		this.updatedData.isEnabled2 = e.target.checked;

		this.props.onChange(this.updatedData);

		this.handleInputChange(e);
	};

	handleChange(e, meta = {}) {
		let key, value;
		if (!e) {
			key = meta.name;
			value = e;
		} else if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		if (meta?.name === 'manager') {
			this.setState({
				selectedManager: e,
			});
		}

		if (meta?.name === 'clientId') {
			this.setState({
				selectedClient: e,
			});
		}

		return { key: key, value: value };
	}

	handleInfoChange = async (e, meta = {}) => {
		if (meta?.name === 'dateopened') {
			const dateValue =
				e instanceof Date ? e.toISOString() : new Date().toISOString();
			this.setState((prevState) => ({
				data: {
					...prevState.data,
					dateopened: dateValue,
				},
			}));
			this.updatedData['dateopened'] = dateValue;
			this.props.onChange(this.updatedData);
			setDraft(true);
			return;
		}

		if (meta?.name === 'taxc') {
			this.setState({
				data: {
					...this.state.data,
					selectedTaxCode: e,
				},
			});
			const tx = await this.api.getSalesTaxCodeSummaryByCode(e.value);
			if (tx) {
				e = {
					value: tx.key,
					label: `${tx.value} [${tx.key}]`,
				};
			}
		}

		if (meta?.name === 'client') {
			this.setState({
				selectedClient: e,
			});
			this.updatedData.client = e.value;
			this.props.onChange(this.updatedData);
			return;
		}
		const keyValue = this.handleChange(e, meta);
		this.updatedData[keyValue.key] = keyValue.value;

		this.props.onChange(this.updatedData);

		setDraft(true);
	};

	handleBillingAddressChange = (e, meta = {}) => {
		const data = this.state.data;
		const keyValue = this.handleChange(e, meta);
		this.updatedBillingAddress[keyValue.key] = keyValue.value;
		data.addressBill[keyValue.key] = keyValue.value;
		this.setState({ data });

		this.props.onBillingAddressChange({
			key: keyValue.key,
			value: keyValue.value,
		});
		setDraft(true);
	};

	handleSiteAddressChange = (e, meta = {}) => {
		const data = this.state.data;
		const keyValue = this.handleChange(e, meta);
		this.updatedSiteAddress[keyValue.key] = keyValue.value;
		data.addressSite[keyValue.key] = keyValue.value;
		this.setState({ data });

		this.props.onSiteAddressChange({
			key: keyValue.key,
			value: keyValue.value,
		});
		setDraft(true);
	};

	handleSortChange = (e, meta = {}) => {
		const keyValue = this.handleChange(e, meta);
		this.updatedSiteAddress[keyValue.key] = keyValue.value;

		this.props.onSiteAddressChange({
			key: keyValue.key,
			value: keyValue.value,
		});
		setDraft(true);

		this.setState({
			sortType: this.sortTypes.filter((type) => {
				return type.value === keyValue.value;
			})[0],
		});

		const contact = document.getElementById('contact');
		const sortName = document.getElementById('sortname');

		if (keyValue.value === 0) {
			sortName.value = toSortName(contact.value);
		} else {
			sortName.value = contact.value ?? '';
		}
	};

	handleUseBillingCheckbox = () => {
		this.setState({
			data: {
				...this.state.data,
				...{ sitesameasbill: !this.state.data.sitesameasbill },
			},
		});
		this.updatedData.sitesameasbill = !this.state.data.sitesameasbill;

		this.props.onChange(this.updatedData);
		setDraft(true);
	};

	render() {
		let { data } = this.state;
		data = {
			...data,
			...this.updatedData,
			addressBill: {
				...data.addressBill,
				...this.updatedBillingAddress,
			},
		};

		return this.renderView(
			<>
				<Container className="ms-0">
					<Row xs={1} lg={2}>
						<Col className="mb-3">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Client*</Form.Label>
								</Col>
								<Col>
									<Row>
										<Col className="mb-2 mb-lg-0">
											<Select
												value={this.state.selectedClient}
												options={this.state.clientDropdownOptions}
												placeholder="Select"
												onChange={(value) =>
													this.handleInfoChange(value, { name: 'client' })
												}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-0 mb-lg-5">
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Project code*</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Project Code"
										defaultValue={data.proj}
										id="proj"
										onChange={this.handleInfoChange}
										disabled
										readOnly
									/>
								</Col>
							</Row>
							<Row className="align-items-center">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Project Name*</Form.Label>
								</Col>
								<Col>
									<Form.Control
										name="clientName"
										type="text"
										placeholder="Project Name"
										defaultValue={data.projn}
										id="projn"
										onChange={this.handleInfoChange}
										autoComplete="off"
									/>
								</Col>
							</Row>
						</Col>
						<Col className="mb-2 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Opened*</Form.Label>
								</Col>
								<Col>
									<DatePicker
										className="form-control"
										placeholderText="Opened"
										selected={
											data.dateopened
												? new Date(data.dateopened.replace('T', ' '))
												: ''
										}
										id="dateopened"
										onChange={(date) =>
											this.handleInfoChange(date, {
												name: 'dateopened',
											})
										}
										popperPlacement="auto"
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Client Order #</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Client Order #"
										defaultValue={data.clientorder}
										id="clientorder"
										onChange={this.handleInfoChange}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col className="mb-2 mb-lg-0">
							<Row className="mb-lg-3">
								<Col lg={3} className="text-start">
									<Form.Label className="mb-0">
										<strong>Billing Address</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-0 mb-lg-5">
						<Col className="">
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0 mt-1">
									<Form.Label className="mb-0">Address</Form.Label>
								</Col>
								<Col>
									<Form.Control
										as="textarea"
										placeholder="Address"
										rows={3}
										defaultValue={data.addressBill.addr}
										id="addr"
										onChange={this.handleBillingAddressChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">City</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="City"
										defaultValue={data.addressBill.city}
										id="city"
										onChange={this.handleBillingAddressChange}
									/>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">State</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col>
											<Form.Control
												maxLength={2}
												type="text"
												placeholder="State"
												defaultValue={data.addressBill.state}
												id="state"
												onChange={this.handleBillingAddressChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Zip</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col>
											<Form.Control
												type="text"
												placeholder="Zip"
												defaultValue={data.addressBill.zip}
												id="zip"
												onChange={this.handleBillingAddressChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col>
							<Row className="mb-3">
								<Col lg={4} className="text-start">
									<Form.Label className="mb-0">
										<strong>Contact Information</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-1 mb-lg-5">
						<Col>
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Contact</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Contact"
										defaultValue={data.addressBill.contact}
										id="contact"
										onChange={this.handleBillingAddressChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone</Form.Label>
								</Col>
								<Col>
									<InputMasked
										type="text"
										placeholder="Phone"
										value={data.addressBill.contactphone}
										id="contactphone"
										onChange={this.handleBillingAddressChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Fax</Form.Label>
								</Col>
								<Col>
									<InputMasked
										type="text"
										placeholder="Fax"
										value={data.addressBill.fax}
										id="fax"
										onChange={this.handleBillingAddressChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Email</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Email"
										defaultValue={data.addressBill.email}
										id="email"
										onChange={this.handleBillingAddressChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Website</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Website"
										defaultValue={data.addressBill.website}
										id="website"
										onChange={this.handleBillingAddressChange}
									/>
								</Col>
							</Row>
						</Col>
						<Col className="mb-3 mb-lg-0">
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 2</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col className="mb-2 mb-lg-0">
											<Form.Control
												type="text"
												placeholder="Phone 2 Description"
												defaultValue={data.addressBill.phonedesc1}
												id="phonedesc1"
												onChange={this.handleBillingAddressChange}
											/>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Phone 2 Number"
												value={data.addressBill.phone1}
												id="phone1"
												onChange={this.handleBillingAddressChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Phone 3</Form.Label>
								</Col>
								<Col>
									<Row xs={1} lg={2}>
										<Col className="mb-2 mb-lg-0">
											<Form.Control
												type="text"
												placeholder="Phone 3 Description"
												defaultValue={data.addressBill.phonedesc2}
												id="phonedesc2"
												onChange={this.handleBillingAddressChange}
											/>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Phone 3 Number"
												value={data.addressBill.phone2}
												id="phone2"
												onChange={this.handleBillingAddressChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Sales Tax Code*</Form.Label>
								</Col>
								<Col>
									<AsyncSalesTaxCodesDropdown
										defaultValue={this.state.data.selectedTaxCode}
										className="react-select"
										placeholder="Select Sales Tax Code"
										name="taxc"
										onChange={this.handleInfoChange}
									/>
								</Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">
										Salesperson / Manager
									</Form.Label>
								</Col>
								<Col>
									<AsyncEmployeesDropdown
										defaultValue={this.state.selectedManager}
										className="react-select"
										placeholder="Salesperson / Manager"
										name="manager"
										onChange={this.handleInfoChange}
										isClearable={true}
									/>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2}>
						<Col>
							<Row className="mb-0 mb-lg-3">
								<Col lg={4} className="text-start">
									<Form.Label className="mb-0">
										<strong>Site Address</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row
						xs={1}
						lg={2}
						className={`mb-0 ${
							this.state.data.sitesameasbill ? '' : 'mb-lg-5'
						}`}
					>
						<Col>
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-lg-0">
									<Form.Label className="mb-0"></Form.Label>
								</Col>
								<Col>
									<Form.Check
										inline
										label="Use billing address as site address"
										name="sitesameasbill"
										type="checkbox"
										id={`inline-radio-2`}
										checked={
											this.updatedData.sitesameasbill ??
											this.state.data.sitesameasbill ??
											false
										}
										onChange={this.handleUseBillingCheckbox}
									/>
								</Col>
							</Row>
							{!this.state.data.sitesameasbill && (
								<>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Attention</Form.Label>
										</Col>
										<Col>
											<Form.Control
												type="text"
												placeholder="Attention"
												defaultValue={data.addressSite.attention}
												id="attention"
												onChange={this.handleSiteAddressChange}
											/>
										</Col>
									</Row>
									<Row className="align-items-center mb-4 mb-lg-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Sort</Form.Label>
										</Col>
										<Col>
											<Form.Control
												type="text"
												placeholder="Please enter"
												defaultValue={data.addressSite.sortname}
												id="sortname"
												onChange={this.handleSiteAddressChange}
												name="sortname"
												readOnly={
													this.state.sortType.value === 0 ||
													this.state.sortType.value === 1
												}
											/>
										</Col>
										<Col>
											<Select
												key={`${Math.floor(Math.random() * 1000)}-min`}
												options={this.sortTypes}
												defaultValue={this.state.sortType}
												className="react-select"
												placeholder="Please select"
												onChange={this.handleSortChange}
												name="parsetype"
											/>
										</Col>
									</Row>
									<Row className="align-items-first mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0 mt-1">
											<Form.Label className="mb-0">Address</Form.Label>
										</Col>
										<Col>
											<Form.Control
												as="textarea"
												placeholder="Address"
												rows={3}
												defaultValue={data.addressSite.addr}
												id="addr"
												onChange={this.handleSiteAddressChange}
											/>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">City</Form.Label>
										</Col>
										<Col>
											<Form.Control
												type="text"
												placeholder="City"
												defaultValue={data.addressSite.city}
												id="city"
												onChange={this.handleSiteAddressChange}
											/>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">State</Form.Label>
										</Col>
										<Col>
											<Row xs={1} lg={2}>
												<Col>
													<Form.Control
														maxLength={2}
														type="text"
														placeholder="State"
														defaultValue={data.addressSite.state}
														id="state"
														onChange={this.handleSiteAddressChange}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="align-items-center mb-0 mb-lg-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Zip</Form.Label>
										</Col>
										<Col>
											<Row xs={1} lg={2}>
												<Col>
													<Form.Control
														type="text"
														placeholder="Zip"
														defaultValue={data.addressSite.zip}
														id="zip"
														onChange={this.handleSiteAddressChange}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</>
							)}
						</Col>
						{!this.state.data.sitesameasbill && (
							<>
								<Col className="mb-3 mb-lg-0">
									<Row className="align-items-first mb-0 mb-lg-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0"></Form.Label>
										</Col>
									</Row>
									<Row className="align-items-center mb-2">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">
												<strong>Contact Information</strong>
											</Form.Label>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Contact</Form.Label>
										</Col>
										<Col>
											<Form.Control
												type="text"
												placeholder="Contact"
												defaultValue={data.addressSite.contact}
												id="contact"
												onChange={this.handleSiteAddressChange}
											/>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Phone</Form.Label>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Phone"
												value={data.addressSite.contactphone}
												id="contactphone"
												onChange={this.handleSiteAddressChange}
											/>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Fax</Form.Label>
										</Col>
										<Col>
											<InputMasked
												type="text"
												placeholder="Fax"
												value={data.addressSite.fax}
												id="fax"
												onChange={this.handleSiteAddressChange}
											/>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Email</Form.Label>
										</Col>
										<Col>
											<Form.Control
												type="text"
												placeholder="Email"
												defaultValue={data.addressSite.email}
												id="email"
												onChange={this.handleSiteAddressChange}
											/>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Phone 2</Form.Label>
										</Col>
										<Col>
											<Row xs={1} lg={2}>
												<Col>
													<Form.Control
														type="text"
														placeholder="Phone 2 Description"
														defaultValue={data.addressSite.phonedesc1}
														id="phonedesc1"
														onChange={this.handleSiteAddressChange}
													/>
												</Col>
												<Col className="mb-2 mb-lg-0">
													<InputMasked
														type="text"
														placeholder="Phone 2 Number"
														defaultValue={data.addressSite.phone1}
														id="phone1"
														onChange={this.handleSiteAddressChange}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Phone 3</Form.Label>
										</Col>
										<Col>
											<Row xs={1} lg={2}>
												<Col>
													<Form.Control
														type="text"
														placeholder="Phone 3 Description"
														defaultValue={data.addressSite.phonedesc2}
														id="phonedesc2"
														onChange={this.handleSiteAddressChange}
													/>
												</Col>
												<Col className="mb-2 mb-lg-0">
													<InputMasked
														type="text"
														placeholder="Phone 3 Number"
														defaultValue={data.addressSite.phone2}
														id="phone2"
														onChange={this.handleSiteAddressChange}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</>
						)}
					</Row>

					<Row xs={1} lg={2}>
						<Col>
							<Row className="mb-3">
								<Col lg={4} className="text-start">
									<Form.Label className="mb-0">
										<strong>Shipping</strong>
									</Form.Label>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row xs={1} lg={2} className="mb-1 mb-lg-5">
						<Col>
							<Row className="align-items-first mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Ship to</Form.Label>
								</Col>
								<Col>
									<Form.Check
										inline
										label="Use site"
										name="group4"
										type="checkbox"
										defaultChecked={this.state.isEnabled2}
										onChange={this.handleUseSiteChange}
										data-state="isEnabled2"
										id={`inline-radio-4`}
									/>
								</Col>
							</Row>

							<Row className="align-items-first mb-3">
								<Col
									lg={3}
									className="text-lg-end mb-2 mb-lg-0 d-none d-lg-block"
								></Col>
								<Col>
									<AsyncAddressesDropdown
										name="shipto"
										defaultValue={data.addressShipSelected}
										isDisabled={this.state.isEnabled2}
										className="react-select"
										placeholder="Select Shipping"
										urlQuery={this.state.shiptoQuery}
										onChange={(e, meta) => {
											this.setState(
												{
													data: {
														...this.state.data,
														addressShipSelected: e,
													},
												},
												() => {
													this.handleInfoChange(e, meta);
												}
											);
										}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>

				<FooterFormAction>
					<SecureBootstrapButton
						attributeNo={13}
						attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
						variant="primary"
						size="lg"
						onClick={this.props.onSave}
						id="handleSaveProject"
					>
						Save
					</SecureBootstrapButton>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(ProjectViewInfo);
