import { AxiosError } from 'axios';

enum EAPIAuthErrorMessages {
	InvalidInstance = 'Instance invalid for Web Pro',
	InvalidCompany = 'The company is not allowed in Web Pro.',
}

export const isUserValid = (error: unknown) => {
	const userError = (error as AxiosError<{ UserError: string }>)?.response?.data
		?.UserError;
	return (
		userError !== EAPIAuthErrorMessages.InvalidInstance &&
		userError !== EAPIAuthErrorMessages.InvalidCompany
	);
};
