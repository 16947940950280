type TypeAsyncDropdownQueryFilter = {
	props: any;
	page: number;
	total?: number;
	search?: any;
	field?: string;
	searchKeys?: any;
	urlQuery?: any;
	isNext?: boolean;
};

class AsyncDropdownQueryFilter {
	search?: string;
	props?: any;
	field?: string;
	total: number;
	page: number;
	filter: string;

	defaultQuery: string;
	sortQry: string;
	searchQry: string;
	pageQry: string;
	searchKeys: string[];
	isNext: boolean;

	constructor({
		props,
		page,
		search,
		field,
		searchKeys,
		urlQuery,
		isNext,
	}: TypeAsyncDropdownQueryFilter) {
		this.search = (search ?? '').toLowerCase();
		this.field = field;
		this.page = page;
		this.props = props;
		this.filter = '';
		this.total = props?.totalPerPage ?? this.getTotalPerPage(props);
		this.isNext = isNext ?? false;

		this.defaultQuery = urlQuery ?? '';

		this.sortQry = '';
		this.searchQry = '';
		this.pageQry = '';
		this.searchKeys = searchKeys ?? '';

		this.initDefaultQuery().setInitSearchQry().setPageQry().setFilter();
	}

	getTotalPerPage = (props: any) => (props.type === 'location' ? 20 : 15);

	hasFilterQry = (qry: any) => qry.toString().indexOf('$filter') > -1;
	hasSortQry = (qry: any) => qry.toString().indexOf('$orderby') > -1;
	hasPageQry = (qry: any) => qry.toString().indexOf('$top') > -1;

	initDefaultQuery() {
		const filterArr = this.defaultQuery.replace('?', '').split('&');
		for (const filter of filterArr) {
			if (this.hasFilterQry(filter)) {
				this.setSearchQry(filter.split('=')[1]);
			}

			if (this.hasSortQry(filter)) {
				this.setSortQry(filter);
			}

			if (this.hasPageQry(filter)) {
				this.setPageQry(filter);
			}
		}

		return this;
	}

	setInitSearchQry() {
		if (this.field && this.search) {
			let otherSearchQryStr = '';
			// Check if there are extra keys
			if (this.searchKeys) {
				const otherSearchQry = this.searchKeys.map((field: any) => {
					return `contains(${field}, '${encodeURIComponent(
						this.search ?? ''
					)}')`;
				});
				otherSearchQryStr = otherSearchQry.join(' or ');
				otherSearchQryStr = ` or ${otherSearchQryStr}`;
			}
			this.setSearchQry(
				`(contains(${this.field}, '${encodeURIComponent(
					this.search
				)}')${otherSearchQryStr})`
			);
		}

		return this;
	}

	setSearchQry(qry = '', operator = 'and') {
		this.searchQry += this.searchQry.length ? ` ${operator} ${qry}` : qry;
		return this;
	}

	setSortQry(qry = '') {
		this.sortQry = qry;
		return this;
	}

	setPageQry(qry = '') {
		const skip = (this.page - 1) * this.total;
		this.pageQry = qry
			? qry
			: `$top=${this.isNext ? 2 : this.total}&$skip=${skip}`;

		return this;
	}

	getSearchQuery() {
		return this.hasFilterQry(this.searchQry)
			? this.searchQry
			: this.searchQry
			? `$filter=${this.searchQry}`
			: '';
	}

	getSortQuery() {
		return this.hasSortQry(this.sortQry)
			? this.sortQry
			: this.sortQry
			? `$orderby=${this.sortQry}`
			: `$orderby=${this.field} asc`;
	}

	getPageQuery() {
		return this.pageQry;
	}

	hasQry(qry: string) {
		return qry.indexOf('?') > -1;
	}

	addQuery(qry: string, newQry: string) {
		const concat = this.hasQry(qry) ? '&' : this.hasQry(newQry) ? '' : '?';

		return newQry ? `${qry ?? ''}${concat}${newQry ?? ''}` : qry;
	}

	setFilter() {
		return this.addFilter(this.getSearchQuery())
			.addFilter(this.getSortQuery())
			.addFilter(this.getPageQuery());
	}

	addFilter($filter: string) {
		this.filter = this.addQuery(this.filter, $filter);
		return this;
	}

	getFilter = () => this.filter;
}

export default AsyncDropdownQueryFilter;
