import { Dropdown } from 'react-bootstrap';
import React from 'react';
import { FiletypeIcon } from './FiletypeIcon';
import SecureDropdownItem from 'legacy/app/components/security/SecureDropdownItem';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';

export const DocumentsGrid = ({
	data,
	columns,
	onView,
	onEdit,
	onDownload,
	onDelete,
}) => {
	return (
		<div
			className="documents-grid a-clipper-media w-100"
			style={{ '--columns': columns }}
		>
			{data.documents.map((document, index) => {
				return (
					<div
						key={index}
						className="a-clipper-media-item-handle border rounded m-0 pb-5"
						onClick={() => {
							onView(document);
						}}
					>
						<div
							className="d-flex justify-content-end mb-2"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Dropdown>
								<Dropdown.Toggle
									variant="light"
									className="no-outline no-min-height"
								>
									<i className="ri-more-2-fill ri-lg"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu align="end">
									<SecureDropdownItem
										attributeNo={39}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
										onClick={() => {
											onEdit(document);
										}}
									>
										Edit
									</SecureDropdownItem>
									<Dropdown.Item
										onClick={() => {
											document.filetype === 'url'
												? onView(document)
												: onDownload(document);
										}}
									>
										{document.filetype === 'url' ? 'Open' : 'Download'}
									</Dropdown.Item>
									<SecureDropdownItem
										attributeNo={39}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
										onClick={() => {
											onDelete(document);
										}}
									>
										Delete
									</SecureDropdownItem>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div className="d-flex flex-column align-items-center">
							<div
								className={`documents-image-wrapper mb-5 ${
									document.thumbnail?.includes('image') ? 'bg-transparent' : ''
								}`}
							>
								<FiletypeIcon
									filetype={document.filetype}
									thumbnail={document.thumbnail}
								/>
							</div>
							<div
								className="fw-bold text-break mb-2 text-decoration-underline"
								style={{ cursor: 'default' }}
								onClick={(e) => {
									e.stopPropagation();
									document.filetype === 'url'
										? onView(document)
										: onDownload(document);
								}}
							>
								{document.filename}
							</div>
							<div className="fw-semibold text-secondary-ash mb-3">
								{document.uploadedBy}
							</div>
							<div className="fw-semibold text-secondary-ash">
								{document.formattedDateUploaded}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};
