import { IJournalEntry } from 'api/resources/financeCharges';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import AccountsDropdown, {
	EAccountType,
	TAccountsDropdownOption,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import {
	TFinanceChargeAccount,
	TOnUpdateArgs,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeTypes';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

type TCharge = {
	account: TFinanceChargeAccount | null;
	newAccount?: TFinanceChargeAccount | null;
	damt: number;
};

type TFinanceChargeUpdateProps = {
	showModal: boolean;
	onToggleModal: () => void;
	account: TFinanceChargeAccount;
	charge: TCharge | null;
	journalEntries: IJournalEntry[];
	onUpdate: (values: TOnUpdateArgs, hasUserChangedAccount: boolean) => void;
};

export const FinanceChargeUpdate = ({
	showModal,
	onToggleModal,
	account,
	charge,
	journalEntries,
	onUpdate,
}: TFinanceChargeUpdateProps) => {
	const defaultValues: TCharge = {
		account: null,
		newAccount: charge?.account || null,
		damt: charge?.damt || 0,
	};

	const { register, control, handleSubmit, reset, getValues, setValue } =
		useForm<TCharge>({ defaultValues });

	const [hasUserChangedAccount, setHasUserChangedAccount] = useState(false);

	useEffect(() => {
		// populate when modal opens and data is available
		if (!getValues('newAccount') && !getValues('damt')) {
			setValue('newAccount', charge?.account);
			setValue('damt', charge?.damt as number);
		}
	}, [charge, register, getValues, setValue]);

	const omitAccounts = [
		...(charge?.account ? [] : journalEntries.map((entry) => entry.account)),
		account.value,
	];

	const onSubmit = (data: TCharge) => {
		onUpdate(
			{
				...data,
				...{ account: charge?.account as TFinanceChargeAccount },
			} as TOnUpdateArgs,
			hasUserChangedAccount
		);

		reset();

		onToggleModal();
	};

	return (
		<ConfirmationModal
			title="Update Entry"
			show={showModal}
			toggleModal={() => {
				reset();
				onToggleModal();
			}}
			footer={false}
			size="lg"
			labelCancel="Cancel"
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="tw-mb-3">
					<div className="tw-inline-block tw-pr-3">
						<Form.Label className="tw-mb-0">Account:</Form.Label>
					</div>
					<div className="tw-inline-block">
						<Controller
							name="newAccount"
							control={control}
							render={({ field }) => (
								<AccountsDropdown
									type={EAccountType.ALL}
									omitAccounts={omitAccounts}
									isDisabled={typeof charge?.account === 'string' ?? false}
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...field}
									onChange={(selected: TAccountsDropdownOption) => {
										setHasUserChangedAccount(true);
										field.onChange(selected);
									}}
								/>
							)}
						/>
					</div>
				</div>
				<div className="tw-mb-3">
					<div className="tw-inline-block pe-3">
						<Form.Label className="tw-mb-0">Amount:</Form.Label>
					</div>
					<div className="tw-inline-block">
						<Form.Control
							type="number"
							step={0.01}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...register('damt', { required: true })}
						/>
					</div>
				</div>
				<Modal.Footer className="bg-ivory tw-py-3 tw-px-5">
					<Row className="tw-w-100">
						<Col className="tw-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="success"
								type="submit"
								className="tw-w-100 tw-m-2"
							>
								OK
							</Button>

							<Button
								variant="trans-light border-secondary-ash"
								onClick={() => {
									reset();
									onToggleModal();
								}}
								className="tw-w-100 tw-m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</form>
		</ConfirmationModal>
	);
};

FinanceChargeUpdate.displayName = 'FinanceChargeUpdate';
