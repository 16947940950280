import { Children } from 'react';

import { useHasAccess } from '../../../context/security';

export default function SecureContent({
	attributeNo,
	attributeType,
	children,
}) {
	const hasAccess = useHasAccess(attributeNo, attributeType);

	const hasAccessChildren = Children.map(children, (child) => {
		if (child?.type?.displayName === 'SecureContent.HasAccess') return child;
		return null;
	});

	const noAccessChildren = Children.map(children, (child) => {
		if (child?.type?.displayName === 'SecureContent.NoAccess') return child;
		return null;
	});

	return <>{hasAccess ? hasAccessChildren : noAccessChildren}</>;
}

SecureContent.HasAccess = ({ children }) => {
	return <>{children && children}</>;
};

SecureContent.HasAccess.displayName = 'SecureContent.HasAccess';

SecureContent.NoAccess = ({ children }) => {
	return <>{children && children}</>;
};

SecureContent.NoAccess.displayName = 'SecureContent.NoAccess';

SecureContent.GenericNoAccess = () => {
	return (
		<div>
			The assigned user role restricts access to this area. Please contact your
			system admin for help. Role changes can be made under Settings - User
			Management.
		</div>
	);
};

SecureContent.GenericNoAccess.displayName = 'SecureContent.GenericNoAccess';
