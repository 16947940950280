import { useQueryClient } from '@tanstack/react-query';
import { useGetLinkToken } from 'api/hooks/useGetLinkToken';
import { usePostConnectToPlaidAccount } from 'api/hooks/usePostConnectPlaidAccount';
import { ECacheKeys } from 'cache/CacheKeys';
import {
	displayAlertSuccess,
	displayAlertError,
} from 'legacy/utilities/Response';
import { useMemo, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

export const PlaidButton = ({
	accountId,
	disabled,
	className,
	buttonLabel = 'Add',
}: {
	disabled?: boolean;
	className?: string;
	accountId?: number;
	buttonLabel?: string;
}) => {
	const queryClient = useQueryClient();
	const {
		data: linkToken,
		isFetching: isLoadingLinkToken,
		error: errorLinkToken,
		refetch: fetchLinkToken,
	} = useGetLinkToken(accountId, {
		enabled: false,
	});
	const { mutateAsync: connectToPlaid } = usePostConnectToPlaidAccount();
	const tokenKey = useMemo(
		() => (accountId ? `${accountId}_link_token` : 'link_token'),
		[accountId]
	);
	const savedToken = useMemo(() => localStorage.getItem(tokenKey), [tokenKey]);
	const isBackFromOauth = useMemo(
		() => window.location.href.includes('?oauth_state_id='),
		[]
	);

	const config: PlaidLinkOptions = useMemo(
		() => ({
			onSuccess: async (publicToken) => {
				await connectToPlaid(
					accountId ? { reauthenticatedAccountId: accountId } : { publicToken }
				);
				localStorage.removeItem(tokenKey);
				await queryClient.resetQueries({ queryKey: [ECacheKeys.PlaidToken] });
				await queryClient.invalidateQueries({
					queryKey: [ECacheKeys.PlaidAccounts],
				});
				displayAlertSuccess('Account connected successfully!');
			},
			onExit: async (err) => {
				if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
					await fetchLinkToken();
					return;
				}
				localStorage.removeItem(tokenKey);
				await queryClient.resetQueries({
					queryKey: [ECacheKeys.PlaidToken],
				});
			},
			receivedRedirectUri: isBackFromOauth ? window.location.href : undefined,
			token: isBackFromOauth ? savedToken : (linkToken?.linkToken as string),
		}),
		[
			isBackFromOauth,
			linkToken,
			accountId,
			savedToken,
			tokenKey,
			fetchLinkToken,
			queryClient,
			connectToPlaid,
		]
	);

	const { open, ready } = usePlaidLink(config);

	useEffect(() => {
		if (errorLinkToken) {
			displayAlertError(
				'There was an error connecting the account, please try again'
			);
		}
	}, [errorLinkToken]);

	useEffect(() => {
		if (ready) {
			open();
		}
	}, [ready, open]);

	useEffect(() => {
		if (linkToken) {
			localStorage.setItem(tokenKey, linkToken.linkToken);
		}
	}, [linkToken, tokenKey]);

	return (
		<Button
			className={className}
			disabled={isLoadingLinkToken || disabled}
			variant="primary"
			onClick={() => fetchLinkToken()}
		>
			{buttonLabel}
		</Button>
	);
};
PlaidButton.displayName = 'PlaidButton';
