import React, { createContext, useState } from 'react';

interface ICheckbook {
	cashAccount: string;
	statementDate: string;
	endingBalance: number;
}

interface ISelected {
	[key: string]: boolean;
}

interface ICheckbookReconcileContext {
	started: boolean;
	setStarted: (value: boolean) => void;
	checkbook: ICheckbook | null;
	setCheckbook: (checkbook: ICheckbook) => void;
	resetState: () => void;
	clearedChecks: ISelected;
	setClearedChecks: (item: ISelected) => void;
	sumChecks: ISelected;
	setSumChecks: (item: ISelected) => void;
	updateTotals: boolean;
	setUpdateTotals: (value: boolean) => void;
}

export const CheckbookReconcileContext =
	createContext<ICheckbookReconcileContext>({
		started: false,
		setStarted: () => undefined,
		checkbook: null,
		setCheckbook: () => undefined,
		resetState: () => undefined,
		clearedChecks: {},
		setClearedChecks: () => undefined,
		sumChecks: {},
		setSumChecks: () => undefined,
		updateTotals: false,
		setUpdateTotals: () => undefined,
	});

interface ICheckbookReconcileContextProviderProps {
	children: React.ReactNode;
}

const CheckbookReconcileProvider = ({
	children,
}: ICheckbookReconcileContextProviderProps) => {
	const [started, setStarted] = useState(false);
	const [checkbook, setCheckbook] = useState<ICheckbook | null>(null);
	const [clearedChecks, setClearedChecks] = useState<ISelected>({});
	const [sumChecks, setSumChecks] = useState<ISelected>({});
	const [updateTotals, setUpdateTotals] = useState(false);

	const resetState = () => {
		setStarted(false);
		setUpdateTotals(false);
		setCheckbook(null);
	};

	return (
		<CheckbookReconcileContext.Provider
			value={{
				started,
				setStarted,
				checkbook,
				setCheckbook,
				resetState,
				clearedChecks,
				setClearedChecks,
				sumChecks,
				setSumChecks,
				updateTotals,
				setUpdateTotals,
			}}
		>
			{children}
		</CheckbookReconcileContext.Provider>
	);
};

CheckbookReconcileProvider.displayName = 'CheckbookReconcileProvider';

export { CheckbookReconcileProvider };
