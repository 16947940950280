import React from 'react';
import { Container, Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import ThreadComponent from '../../../ThreadComponent';
import { observer } from 'mobx-react';
import AddressAddEditViewModel from '../../Address/AddressAddEditViewModel';
import PayeeAddressAddEditViewModel from '../../Address/PayeeAddressAddEditViewModel';
import { AddressService } from '../../Address/api/AddressService';
import AddressListViewModel from '../../Address/AddressListViewModel';
import VendorAddEditViewModel from '../VendorAddEditViewModel';
import { VendorService } from '../api/VendorService';
import { InsuranceService } from '../api/InsuranceService';
import { toSortName } from '../../../../utilities/String';
import { getLocalStorage } from '../../../../utilities/LocalStorage';
import DeleteModal from '../../../components/modal/DeleteModal';
import { displayAlertLoader } from '../../../../utilities/Response';
import HandleDeleteWorker from '../../../../utilities/DeleteWorker';
import URI from '../../../../defaults/RoutesDefault';
import MSG from '../../../../defaults/Message';
import InputMasked from '../../../components/form/MaskedInput';
import { setDraft } from '../../../../utilities/FormEvent';
import SecureBootstrapButton from '../../../components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../context/security';
import { nameToCode, formatCode } from 'legacy/app/utils/codes';

interface SelectOptions {
	label?: string;
	value?: any;
}

interface Props {
	data: any;
	addressesTypes?: string[];
	onDraft?: any;
	navigate?: any;
}

interface State {
	data: any;
	sortTypes: SelectOptions[];
	incomeTypes: SelectOptions[];
	isDisabledSort?: boolean;
	vendorIsPayee: boolean;
	defaultType?: SelectOptions;
	defaultCategory?: SelectOptions;
	showModal: boolean;
	deleteResponses: any;
	dataIsLoaded: boolean;
	isEditing?: boolean;
}

class VendorsAddInfo extends ThreadComponent<Props, State> {
	readonly addressAddEditModel: AddressAddEditViewModel;
	readonly payeeAddressAddEditModel: PayeeAddressAddEditViewModel;
	readonly addressListModel: AddressListViewModel;
	readonly vendorAddEditModel: VendorAddEditViewModel;
	addressesTypes: string[];
	defaultSortType: SelectOptions | null;
	defaultPayeeSortType: SelectOptions | null;
	defaultCategory: SelectOptions | null;
	defaultType: SelectOptions | null;
	defaultIncomeType: SelectOptions;
	isDisabledSort: boolean;
	deleteObjectParams: any;

	constructor(props: Props) {
		super(props);

		this.addressAddEditModel = new AddressAddEditViewModel(
			new AddressService()
		);
		this.payeeAddressAddEditModel = new PayeeAddressAddEditViewModel(
			new AddressService()
		);
		this.addressListModel = new AddressListViewModel(new AddressService());
		this.vendorAddEditModel = new VendorAddEditViewModel(
			new VendorService(),
			new InsuranceService()
		);
		this.state = {
			data: {},
			sortTypes: [
				{ value: 0, label: 'Name' },
				{ value: 1, label: 'Company' },
				{ value: 2, label: 'Manual' },
			],
			incomeTypes: [
				{ value: '-1', label: 'None' },
				{ value: '0', label: 'NEC - Nonemployee Comp.' },
				{ value: '1', label: 'MISC - Rents' },
				{ value: '2', label: 'MISC - Royalties' },
				{ value: '3', label: 'MISC - Attorney' },
				{ value: '4', label: 'MISC - Other Income' },
				{ value: '100', label: 'INT - Interest Income' },
			],
			isDisabledSort: false,
			vendorIsPayee: false,
			defaultType: undefined,
			defaultCategory: undefined,
			showModal: false,
			deleteResponses: {},
			dataIsLoaded: false,
			isEditing: false,
		};
		this.addressesTypes = [];
		this.defaultSortType = { value: 1, label: 'Company' };
		this.defaultPayeeSortType = { value: 1, label: 'Company' };
		this.defaultCategory = null;
		this.defaultType = null;
		this.defaultIncomeType = this.state.incomeTypes[0];
		this.isDisabledSort = false;
		this.deleteObjectParams = {
			deleteType: 'dmriTestOnly',
			objectType: 'objSupplier',
			objectCodeOrId: '',
		};
	}

	async componentDidMount() {
		this.hasFormAction(true);

		const { data } = this.props;

		if (Object.values(data).length) {
			this.setState({
				data: data,
				vendorIsPayee: data.payeesameasvendor,
			});
		} else {
			this.vendorAddEditModel.setVendorData('payeesameasvendor', true);
			this.vendorAddEditModel.setVendorData('incometype', '-1');
			this.setState({
				data: {
					payeesameasvendor: true,
				},
				vendorIsPayee: true,
			});
		}

		await this.loadAdditionalData();
		this.setState({
			dataIsLoaded: true,
		});
	}

	componentDidUpdate(previousProps: Props, previousState: State): void {
		if (previousProps.data !== this.props.data) {
			const { data } = this.props;

			if (data?.id) {
				this.setState({
					isEditing: true,
				});
			}

			this.setState({
				data: data,
				vendorIsPayee: data.payeesameasvendor ?? true,
			});
		}
	}

	async loadAdditionalData() {
		await this.vendorAddEditModel.fetchCategories();

		// Get list for types.
		this.addressListModel.setParams('');
		await this.addressListModel.fetchAddresses('vendor');
		const requestedVendor = this.vendorAddEditModel.requestedVendor;

		const typeSelectOptions = {
			label: requestedVendor?.typeDesc,
			value: requestedVendor?.typeDesc,
		};
		const isThereAType =
			Object.values(typeSelectOptions).filter(Boolean).length;

		this.setState({
			defaultType: isThereAType ? typeSelectOptions : undefined,
			defaultCategory: this.vendorAddEditModel.categories.filter((category) => {
				return category.value === this.state.data.vendor?.category;
			})[0],
		});
	}

	setSelectDefaults() {
		for (const i of this.state.incomeTypes) {
			if (parseInt(this.state.data?.incometype) === parseInt(i.value))
				this.defaultIncomeType = i;
		}

		for (const i of this.vendorAddEditModel.categories) {
			if (this.state.data?.category === i.value) this.defaultCategory = i;
		}

		for (const x of this.state.sortTypes) {
			if (x.value === this.state.data?.address?.parsetype)
				this.defaultSortType = x;
		}

		const sortName = document.getElementById('sortName');

		if (this.defaultSortType?.value !== 2) {
			sortName?.setAttribute('readonly', 'readonly');
		} else {
			sortName?.removeAttribute('readonly');
		}

		for (const x of this.state.sortTypes) {
			if (x.value === this.state.data?.addressPayee?.parsetype)
				this.defaultPayeeSortType = x;
		}

		const payeeSortName = document.getElementById('addressPayeeSortName');

		if (this.defaultPayeeSortType?.value !== 2) {
			payeeSortName?.setAttribute('readonly', 'readonly');
		} else {
			payeeSortName?.removeAttribute('readonly');
		}
	}

	handleAddressPayeeChange = (e: any, select?: any) => {
		// If plain text
		if (!select) {
			this.payeeAddressAddEditModel.setAddressData(
				e.target.name,
				e.target.value
			);
		}

		// If select
		if (select && e.name) {
			this.payeeAddressAddEditModel.setAddressData(e.name, select.value);
		}

		this.setPayeeAddressToVendor();
		this.props.onDraft();
		setDraft(true);
	};

	setPayeeAddressToVendor() {
		const payeeAddressData: any = getLocalStorage('payeeAddressData', true);
		const vendorData: any = getLocalStorage('vendorData', true);

		vendorData['payeeAddressData'] = payeeAddressData;
		window.localStorage.setItem('vendorData', JSON.stringify(vendorData));
	}

	setAddressToVendor() {
		const addressData: any = getLocalStorage('addressData', true);
		const vendorData: any = getLocalStorage('vendorData', true);

		vendorData['address'] = addressData;
		window.localStorage.setItem('vendorData', JSON.stringify(vendorData));
	}

	handleSortTypePayeeChange = (option?: any) => {
		let sortname = '';

		if (option) {
			const sortNameInput = document.getElementById(
				'addressPayeeSortName'
			) as HTMLInputElement;
			const nameInput = document.getElementById(
				'addressPayeeVendorn'
			) as HTMLInputElement;

			if (
				sortNameInput &&
				nameInput &&
				(option.value === 1 || option.value === 0)
			) {
				if (option.value === 0) {
					sortNameInput.value = toSortName(nameInput.value);
					sortname = toSortName(nameInput.value);
				} else {
					sortNameInput.value = nameInput.value;
					sortname = nameInput.value;
				}
				sortNameInput.setAttribute('readonly', 'readonly');
			} else {
				sortNameInput.removeAttribute('readonly');
				sortname = sortNameInput.value;
			}

			this.vendorAddEditModel.setVendorData('sortName1', sortname);
			this.payeeAddressAddEditModel.setAddressData('sortname', sortname);
			this.payeeAddressAddEditModel.setAddressData('parsetype', option.value);
			this.setAddressToVendor();
		}

		this.props.onDraft();
		setDraft(true);
	};

	getSortString = () => {
		const { data, dataIsLoaded } = this.state;

		if (data.address?.parsetype === undefined && dataIsLoaded) {
			this.addressAddEditModel.setAddressData('parsetype', 1);
		}

		if (data.address?.parsetype === 0) {
			return toSortName(data?.vendorn || '');
		} else if (data.address?.parsetype === 1) {
			return data?.vendorn || '';
		}

		return data.address?.sortname;
	};

	handleVendorChange = (e: any, select?: any) => {
		if (!select) {
			// If checkbox
			if (
				e.target.tagName.toLowerCase() === 'input' &&
				e.target.getAttribute('type') === 'checkbox'
			) {
				if (e.target.name === 'payeesameasvendor')
					this.setState({
						vendorIsPayee: !!e.target.checked,
					});

				this.vendorAddEditModel.setVendorData(
					e.target.name,
					!!e.target.checked
				);

				this.props.onDraft();
				return;
			}

			if (!this.state.isEditing) {
				if (e.target.name === 'vendorn') {
					const vendorCode = nameToCode(e.target.value);
					this.vendorAddEditModel.setVendorData('vendor', vendorCode);
				} else if (e.target.name === 'vendor') {
					e.target.value = formatCode(e.target.value);
				}
			}

			this.vendorAddEditModel.setVendorData(e.target.name, e.target.value);
		}

		// If select
		if (select && e.name) {
			this.vendorAddEditModel.setVendorData(e.name, select.value);
		}
		this.props.onDraft();
		setDraft(true);
	};

	handleAddressChange = (e: any, select?: any) => {
		// If plain text
		if (!select) {
			this.addressAddEditModel.setAddressData(e.target.name, e.target.value);
		}

		// If select
		if (select && e.name) {
			this.addressAddEditModel.setAddressData(e.name, select.value);
		}
		this.setAddressToVendor();
		this.props.onDraft();
		setDraft(true);
	};

	handleTypeChange = (e: any, select?: any) => {
		if (!select) {
			this.addressAddEditModel.setAddressData('typedesc', e.target.value);
		} else {
			this.setState({
				defaultType: { value: select.value, label: select.value },
			});
			this.addressAddEditModel.setAddressData(
				'typedesc',
				select && select.value === 'Custom' ? '' : select.value
			);
		}

		this.setAddressToVendor();
		this.props.onDraft();
		setDraft(true);
	};

	handleCategoryChange = (e: any, select?: any) => {
		if (!select) {
			this.vendorAddEditModel.setVendorData('category', e.target.value);
		} else {
			this.setState({
				defaultCategory: { value: select.value, label: select.value },
			});
			this.vendorAddEditModel.setVendorData('category', select.value);
		}

		this.props.onDraft();
		setDraft(true);
	};

	handleSortTypeChange = (select: any) => {
		const vendorName: any = document.getElementById('vendorn');
		const sortName: any = document.getElementById('sortName');
		if (select.value === 0 || select.value === 1) {
			if (this.state.data?.vendorn) {
				if (select.value === 0) {
					sortName.value = toSortName(vendorName.value);
				} else {
					sortName.value = vendorName.value;
				}
				sortName?.setAttribute('readonly', 'readonly');
			}
		} else {
			sortName?.removeAttribute('readonly');
		}

		this.vendorAddEditModel.setVendorData('sortName1', sortName.value);
		this.addressAddEditModel.setAddressData('sortname', sortName.value);
		this.addressAddEditModel.setAddressData('parsetype', select.value);
		this.props.onDraft();
		setDraft(true);
	};

	getPayeeSortString = () => {
		const { data, dataIsLoaded } = this.state;

		if (data.addressPayee?.parsetype === undefined && dataIsLoaded) {
			this.payeeAddressAddEditModel.setAddressData('parsetype', 1);
		}

		if (data.addressPayee?.parsetype === 0) {
			return toSortName(data?.payee || '');
		} else if (data.addressPayee?.parsetype === 1) {
			return data?.payee || '';
		}

		return data.addressPayee?.sortname;
	};

	refreshDataAfterDelete = (e: any) => {
		this.props.navigate(URI.contact.vendor);
	};

	hideModal = (e: any) => {
		this.setState({
			showModal: false,
		});
	};

	handleDelete = (e: any) => {
		const self = this;
		const workers: any = [];

		displayAlertLoader(MSG.loading.prepare.item);

		this.deleteObjectParams.objectCodeOrId = this.state.data.id;
		workers.push(this.vendorAddEditModel.deleteVendor(this.deleteObjectParams));

		if (workers) {
			HandleDeleteWorker(
				workers,
				{
					moduleSingular: 'Vendor',
					modulePlural: 'Vendors',
					reference: 'Projects',
					objectType: self.deleteObjectParams.objectType,
				},
				false,
				(modalState: any) => {
					this.setState(modalState);
				}
			);
		}
	};

	getDefaultType() {
		return (
			this.state.defaultType ??
			this.addressListModel.vendorAddressTypes.filter((type) => {
				return type.value === this.state.data.address?.typedesc;
			})[0]
		);
	}

	render(): JSX.Element {
		const { data, isEditing } = this.state;
		this.setSelectDefaults();
		return (
			<>
				<Container className="px-0 ms-0">
					<Form.Group>
						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Name</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={40}
											placeholder="Please enter"
											defaultValue={data.vendorn}
											id="vendorn"
											onChange={this.handleVendorChange.bind(this)}
											name="vendorn"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Sort</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={40}
											placeholder="Please enter"
											value={this.getSortString()}
											id="sortName"
											onChange={this.handleAddressChange.bind(this)}
											name="sortname"
											readOnly={
												data.address?.parsetype === 0 ||
												data.address?.parsetype === 1
													? true
													: false
											}
										/>
									</Col>
									<Col>
										<Select
											key={`${Math.floor(Math.random() * 1000)}-min`}
											options={this.state.sortTypes}
											value={this.state.sortTypes[data.address?.parsetype]}
											defaultValue={this.state.sortTypes[1]}
											className="react-select"
											placeholder="Please select"
											name="parsetype"
											onChange={this.handleSortTypeChange.bind(this)}
										/>
									</Col>
								</Row>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Code</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col lg={6}>
												<Form.Control
													type="text"
													placeholder="Please enter"
													name="vendor"
													id="vendor"
													maxLength={5}
													defaultValue={data.vendor}
													readOnly={isEditing}
													disabled={isEditing}
													onChange={this.handleVendorChange}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>

							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Type</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col lg={6}>
												<Select
													key={`${Math.floor(Math.random() * 1000)}-min`}
													options={this.addressListModel.vendorAddressTypes}
													defaultValue={this.getDefaultType()}
													className="react-select"
													placeholder="Please select"
													name="typedesc"
													onChange={this.handleTypeChange.bind(this, {
														name: 'typedesc',
													})}
												/>
											</Col>
										</Row>

										{this.state.defaultType?.value === 'Custom' && (
											<Row className={'mt-2'}>
												<Col lg={6}>
													<Form.Control
														type="text"
														maxLength={20}
														placeholder="Please enter"
														defaultValue={''}
														onChange={this.handleTypeChange.bind(this)}
														name="typedesc"
													/>
												</Col>
											</Row>
										)}
									</Col>
								</Row>

								<Col className="mb-3 mb-lg-0">
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Category</Form.Label>
										</Col>
										<Col>
											<Row>
												<Col lg={6}>
													<Select
														key={`${Math.floor(Math.random() * 1000)}-min`}
														options={this.vendorAddEditModel.categories}
														defaultValue={this.defaultCategory}
														className="react-select"
														placeholder="Please select"
														onChange={this.handleCategoryChange.bind(this, {
															name: 'category',
														})}
														name="category"
													/>
												</Col>
											</Row>

											{this.state.defaultCategory?.value === 'Custom' && (
												<Row className={'mt-2'}>
													<Col lg={6}>
														<Form.Control
															type="text"
															maxLength={20}
															placeholder="Please enter"
															defaultValue={''}
															onChange={this.handleCategoryChange.bind(this)}
															name="category"
														/>
													</Col>
												</Row>
											)}
										</Col>
									</Row>
								</Col>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											<strong>
												Contact
												<br />
												Information
											</strong>
										</Form.Label>
									</Col>
									<Col></Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Address</Form.Label>
									</Col>
									<Col>
										<Form.Control
											as="textarea"
											maxLength={255}
											placeholder="Please enter"
											style={{ height: '100px' }}
											defaultValue={data.address?.addr}
											onChange={this.handleAddressChange}
											name="addr"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">City</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={30}
											placeholder="Please enter"
											defaultValue={data.address?.city}
											onChange={this.handleAddressChange}
											name="city"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">State</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											placeholder="Please enter"
											defaultValue={data.address?.state}
											onChange={this.handleAddressChange}
											name="state"
											maxLength={2}
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Zip Code</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={20}
											placeholder="Please enter"
											defaultValue={data.address?.zip}
											onChange={this.handleAddressChange}
											name="zip"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
								</Row>
							</Col>
							<Col>
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Contact</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={40}
											placeholder="Please enter"
											defaultValue={data.address?.contact}
											onChange={this.handleAddressChange}
											name="contact"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Phone</Form.Label>
									</Col>
									<Col>
										<InputMasked
											type="text"
											placeholder="Please enter"
											value={data.address?.contactphone}
											onChange={this.handleAddressChange}
											name="contactphone"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Fax</Form.Label>
									</Col>
									<Col>
										<InputMasked
											type="text"
											placeholder="Please enter"
											value={data.address?.fax}
											onChange={this.handleAddressChange}
											name="fax"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Email</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={255}
											placeholder="Please enter"
											defaultValue={data.address?.email}
											value={data.address?.email}
											onChange={this.handleAddressChange}
											name="email"
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Website</Form.Label>
									</Col>
									<Col>
										<Form.Control
											type="text"
											maxLength={50}
											placeholder="Please enter"
											defaultValue={data.address?.website}
											onChange={this.handleAddressChange}
											name="website"
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											<strong>Account Information</strong>
										</Form.Label>
									</Col>
									<Col></Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Employee ID No.</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col lg={6}>
												<Form.Control
													type="text"
													maxLength={11}
													placeholder="Please enter"
													defaultValue={data.ein}
													name="ein"
													onChange={this.handleVendorChange.bind(this)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">Account No.</Form.Label>
									</Col>
									<Col>
										<Row>
											<Col lg={6}>
												<Form.Control
													type="text"
													maxLength={20}
													placeholder="Please enter"
													defaultValue={data.account}
													name="account"
													onChange={this.handleVendorChange.bind(this)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
									<Col lg={7}>
										<Form.Check
											inline
											label="Include Account Number on check"
											name="printacctoncheck"
											type="checkbox"
											id={`inline-check-1`}
											defaultChecked={data.printacctoncheck}
											onClick={this.handleVendorChange.bind(this)}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											<strong>Use Vendor as Payee</strong>
										</Form.Label>
									</Col>
									<Col></Col>
								</Row>
							</Col>
						</Row>

						<Row xs={1} lg={2}>
							<Col className="mb-3 mb-lg-0">
								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
									<Col lg={6}>
										<Form.Check
											inline
											label="Use Vendor as Payee"
											name="payeesameasvendor"
											type="checkbox"
											id={`inline-radio-3`}
											checked={
												this.state.isEditing
													? data.payeesameasvendor
													: this.state.vendorIsPayee
											}
											onClick={this.handleVendorChange.bind(this)}
										/>
									</Col>
								</Row>

								{!this.state.vendorIsPayee && (
									<div>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Name</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													maxLength={40}
													placeholder="Please enter"
													defaultValue={data.payee}
													id="addressPayeeVendorn"
													name="payee"
													onChange={this.handleVendorChange.bind(this)}
												/>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Sort</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													maxLength={40}
													placeholder="Please enter"
													value={this.getPayeeSortString()}
													id="addressPayeeSortName"
													name="sortname"
													readOnly={
														data.addressPayee?.parsetype === 0 ||
														data.addressPayee?.parsetype === 1
													}
													onChange={this.handleAddressPayeeChange.bind(this)}
												/>
											</Col>
											<Col>
												<Select
													key={`${Math.floor(Math.random() * 1000)}-min`}
													options={this.state.sortTypes}
													defaultValue={this.defaultPayeeSortType}
													name="parsetype"
													onChange={this.handleSortTypePayeeChange.bind(this)}
													className="react-select"
													placeholder="Please select"
												/>
											</Col>
										</Row>

										<Row className="mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Address</Form.Label>
											</Col>
											<Col>
												<Form.Control
													as="textarea"
													maxLength={255}
													placeholder="Please enter"
													defaultValue={data.addressPayee?.addr}
													name="addr"
													onChange={this.handleAddressPayeeChange.bind(this)}
													style={{ height: '100px' }}
												/>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">City</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													maxLength={30}
													placeholder="Please enter"
													defaultValue={data.addressPayee?.city}
													name="city"
													onChange={this.handleAddressPayeeChange.bind(this)}
												/>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">State</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													placeholder="Please enter"
													defaultValue={data.addressPayee?.state}
													name="state"
													maxLength={2}
													onChange={this.handleAddressPayeeChange.bind(this)}
												/>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Zip Code</Form.Label>
											</Col>
											<Col>
												<Form.Control
													type="text"
													maxLength={20}
													placeholder="Please enter"
													defaultValue={data.addressPayee?.zip}
													name="zip"
													onChange={this.handleAddressPayeeChange.bind(this)}
												/>
											</Col>
										</Row>
									</div>
								)}
							</Col>

							<Col>
								<Row className="mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">
											1099 Recipient Name
										</Form.Label>
									</Col>
									<Col>
										<Form.Control
											as="textarea"
											maxLength={100}
											placeholder="Leave Blank for Payee"
											style={{ height: '100px' }}
											defaultValue={data._1099rp}
											name="_1099rp"
											onChange={this.handleVendorChange.bind(this)}
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
										<Form.Label className="mb-0">1099</Form.Label>
									</Col>
									<Col>
										<Select
											key={`${Math.floor(Math.random() * 1000)}-min`}
											options={this.state?.incomeTypes}
											defaultValue={this.defaultIncomeType}
											className="react-select"
											placeholder="Please select"
											onChange={this.handleVendorChange.bind(this, {
												name: 'incometype',
											})}
										/>
									</Col>
								</Row>

								<Row className="align-items-center mb-3">
									<Col lg={3} className="text-lg-end mb-2 mb-lg-0"></Col>
									<Col lg={6}>
										<Form.Check
											inline
											label="Inactive"
											name="inactive"
											type="checkbox"
											id={`inline-radio-5`}
											defaultChecked={data.inactive}
											onClick={this.handleVendorChange.bind(this)}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						{this.state.data.id && (
							<Row>
								<Col xs={1}>
									<SecureBootstrapButton
										attributeNo={49}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
										href="#"
										variant="danger"
										size="sm"
										className="btn-icon btn-action mt-3"
										onClick={this.handleDelete}
									>
										<i className="ri-close-line"></i> Delete
									</SecureBootstrapButton>
								</Col>
							</Row>
						)}
					</Form.Group>
				</Container>

				<DeleteModal
					refreshData={this.refreshDataAfterDelete.bind(this)}
					show={this.state.showModal}
					hideModal={this.hideModal}
					responses={this.state.deleteResponses}
				/>
			</>
		);
	}
}

export default observer(VendorsAddInfo);
