import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Container, Button } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import URI from '../../../../defaults/RoutesDefault';
import { WithRouter } from '../../../../helpers/Router';
import CatalogItemInfo from './CatalogItemInfo';
import CatalogItemSpecification from './CatalogItemSpecification';
import CatalogItemPricing from './CatalogItemPricing';
import { HeaderLight } from '../../../components/Header';
import { FooterFormAction } from '../../../components/Form';
import { ApiService } from '../../../../lib/api/HttpService';

class ItemEdit extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			activeMenu: 'info',
			item: {},
			itemComponents: [],
			vendors: [],
			unitsOfMeasures: [],
			salesCategories: [],
			dataIsLoaded: false,
			defaults: {
				salesCategory: null,
			},
		};

		this.api = new ApiService();
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	componentInit() {
		this.setTitle('Catalog No. 1234 | Catalog');
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.getAttribute('data-menu'))
		);
	};

	async getSalesCategories(item) {
		let salesCategories = [];
		const scats = await this.api.getSalesCategories();
		for (let cat of scats) {
			salesCategories.push({ value: cat.scat, label: cat.scatn });
			if (cat.scat === item.scat) {
				item.scatn = cat.scatn;
				item.defaultSalesCategory = {
					value: cat.scat,
					label: cat.scatn,
				};
			}
		}

		this.setState({
			salesCategories: salesCategories,
		});

		return item;
	}

	async getUnitsOfMeasures() {
		let unitsOfMeasures = [];
		let ums = await this.api.getUnitsOfMeasures();
		for (let um of ums) {
			unitsOfMeasures.push({ value: um.value, label: um.value });
		}

		this.setState({
			unitsOfMeasures: unitsOfMeasures,
		});
	}

	async getImage(item) {
		let file = item.primaryImageId
			? await this.api.getFile(item.primaryImageId)
			: '';

		item.image = file;

		return item;
	}

	async getVendors(item) {
		let vendorsData = await this.api.getVendors();

		let vendors = [];
		for (let v of vendorsData) {
			vendors.push({ value: v.vendor, label: v.vendorn });
			if (v.vendor === item.specmfg) item.specmfgn = v.vendorn;
			if (v.vendor === item.specsourcesup) item.specsourcesupn = v.vendorn;
		}

		this.setState({
			vendors: vendors,
		});

		return item;
	}

	async getItemComponents(cat) {
		const itemComponents = await this.api.getCatalogs(
			"?$filter=catno eq '" + cat + "'"
		);

		this.setState({
			itemComponents: itemComponents,
		});
	}

	async getCatalogItem() {
		const catalogItem = await this.api.getCatalogItems(
			'?$filter=id eq ' + this.props.params.id
		);

		return catalogItem[0];
	}

	async fetchItem() {
		let item = {};

		if (this.props.params.id !== undefined) {
			item = await this.getCatalogItem();
			item = await this.getImage(item);
		}

		item = await this.getSalesCategories(item);
		item = await this.getVendors(item);

		await this.getUnitsOfMeasures();
		await this.getItemComponents(item.catno);

		this.setState({
			item: item,
			dataIsLoaded: true,
		});
	}

	async componentDidMount() {
		await this.fetchItem();
	}

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.item.catalog.base}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item
							linkProps={{ to: URI.item.catalog.base }}
							linkAs={Link}
						>
							Items
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{ to: URI.item.catalog.base }}
							linkAs={Link}
						>
							Catalogs
						</Breadcrumb.Item>
						<Breadcrumb.Item>Item</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>
						Catalog No. {this.state.item.catno}
					</HeaderLight.Title>

					<div>
						{/* Submit Button */}
						<Button variant="primary me-2">Save & New</Button>

						<Button variant="primary">Save</Button>
					</div>
				</HeaderLight.Content>
				<HeaderLight.Actions>
					<li>
						<Link
							to="/catalogs/item"
							data-menu="info"
							className={
								`btn text-charcoal ` +
								(this.state.activeMenu === 'info' ? 'active' : '')
							}
							onClick={this.handleMenuClick}
						>
							Info
						</Link>
					</li>
					<li>
						<Link
							to="/catalogs/item"
							data-menu="specification"
							className={
								`btn text-charcoal ` +
								(this.state.activeMenu === 'specification' ? 'active' : '')
							}
							onClick={this.handleMenuClick}
						>
							Specification
						</Link>
					</li>
					<li>
						<Link
							to="/catalogs/item"
							data-menu="pricing"
							className={
								`btn text-charcoal ` +
								(this.state.activeMenu === 'pricing' ? 'active' : '')
							}
							onClick={this.handleMenuClick}
						>
							Pricing Detail
						</Link>
					</li>
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	getContent() {
		if (this.state.activeMenu === 'info')
			return (
				<CatalogItemInfo
					item={this.state.item}
					vendors={this.state.vendors}
					unitsOfMeasures={this.state.unitsOfMeasures}
					salesCategories={this.state.salesCategories}
					itemComponents={this.state.itemComponents}
				/>
			);
		if (this.state.activeMenu === 'specification')
			return (
				<CatalogItemSpecification
					item={this.state.item}
					vendors={this.state.vendors}
				/>
			);
		if (this.state.activeMenu === 'pricing')
			return <CatalogItemPricing item={this.state.item} />;
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding min-height has-action-bar">
					<Container fluid>{this.getContent()}</Container>
				</div>

				<FooterFormAction>
					<Button variant="primary" size="lg">
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(ItemEdit);
