import { useGetItemTotals } from 'api/hooks/useGetItemTotals';
import Spinner from 'legacy/app/components/help/Spinner';
import Label from 'legacy/app/components/label/Label';
import { currencyFormat } from 'legacy/helpers/Number';
import { Item } from 'legacy/lib/api/types/Item';
import { displayAlertError } from 'legacy/utilities/Response';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';

const ItemTotals = ({ itemToEdit }: { itemToEdit: Item }) => {
	const {
		data: itemTotals,
		isLoading,
		error,
	} = useGetItemTotals(String(itemToEdit.id));

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error getting the item totals, please try again'
			);
		}
	}, [error]);

	if (isLoading) {
		return <Spinner isChild />;
	}

	return itemTotals ? (
		<div className="tw-pl-8 tw-pr-8 tw-mr-10 tw-ml-10">
			<div className="tw-p-4 tw-flex tw-items-center tw-w-full tw-rounded-md tw-border-[1px] tw-border-solid tw-border-[#dedcd7] tw-bg-[#faf9f7]">
				<div className="tw-flex tw-gap-6 tw-w-full">
					<div className="tw-flex tw-items-center tw-gap-2 tw-flex-1">
						<Label label="Invoiced To Date" className="tw-text-xs" />
						<div className="form-group-extra reversed flex-grow-1">
							<Form.Control
								className="!tw-text-xs"
								type="text"
								placeholder="0.00"
								size="sm"
								readOnly
								value={currencyFormat(itemTotals.invoicedToDate)}
							/>
							<span>$</span>
						</div>
					</div>
					<div className="tw-flex tw-items-center tw-gap-2 tw-flex-1">
						<Label label="Payments To Date" className="tw-text-xs" />
						<div className="form-group-extra reversed flex-grow-1">
							<Form.Control
								type="text"
								className="!tw-text-xs"
								placeholder="0.00"
								size="sm"
								readOnly
								value={currencyFormat(itemTotals.paymentsToDate)}
							/>
							<span>$</span>
						</div>
					</div>
					<div className="tw-flex tw-items-center tw-gap-2 tw-flex-1">
						<Label label="Balance Due" className="tw-text-xs" />
						<div className="form-group-extra reversed flex-grow-1">
							<Form.Control
								className="!tw-text-xs"
								type="text"
								placeholder="0.00"
								size="sm"
								readOnly
								value={currencyFormat(itemTotals.balanceDue)}
							/>
							<span>$</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : null;
};

ItemTotals.displayName = 'ItemTotals';

export default ItemTotals;
