import React from 'react';
import MultiSearchComponents, {
	MultiSearchComponentsProps,
	MultiSearchComponentsState,
} from './MultiSearchComponents';
import Select from '../form/Select';

export default class MultiSearchComponentsReactSelect extends MultiSearchComponents {
	timeout: any;

	constructor(props: MultiSearchComponentsProps) {
		super(props);
		this.state = {
			props,
			selected: props.defaultValue ?? '',
			cleared: false,
		};

		this.timeout = null;
	}

	componentDidUpdate(
		prevProps: Readonly<MultiSearchComponentsProps>,
		prevState: Readonly<MultiSearchComponentsState>
	): void {
		const { isClear } = this.props;
		const { selected } = this.state;

		if (prevProps !== this.props) {
			this.setState({
				props: this.props,
				cleared: isClear,
				selected: isClear ? '' : selected,
			});
		}
	}

	render() {
		const { props, selected } = this.state;

		return (
			<div
				className={`position-relative mb-2 me-2 rounded-1 ${
					this.isFixed ? '' : 'pe-2 border border-sand bg-white '
				}`}
				style={{
					...props.style,
					zIndex: 700 - (props?.zIndex ?? 0),
				}}
			>
				<div className="d-flex align-items-center justify-content-between">
					<div
						className={`${
							props.label ? 'daterange-labeled-group position-relative' : ''
						}  w-100`}
					>
						{props.label && this.renderLabel('selected')}
						<Select
							onChange={(e: any) => {
								this.setValue('selected', e);
								props.handleChangeField(e, props);
							}}
							styles={{
								container: (provided) => ({
									...provided,
									width: '100%',
								}),
							}}
							options={props.options}
							className={`react-select react-select-sm ${
								this.isFixed ? 'no-shadow' : 'no-border'
							}`}
							placeholder={props.placeholder ?? 'Select'}
							value={selected}
						/>
					</div>
					{!this.isFixed && (
						<a
							className="ff-close-dropdown"
							onClick={() => {
								props.handleDetach(props.id);
							}}
						>
							<i className="ri-close-fill"></i>
						</a>
					)}
				</div>
			</div>
		);
	}
}
