import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { WithRouter } from '../../../../helpers/Router';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FooterFormAction } from '../../../components/Form';
import React from 'react';
import Select from 'react-select';
import { displayAlert } from '../../../../utilities/Response';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import { RTFJS } from 'rtf.js';
import { stringToArrayBuffer } from '../../../../helpers/String';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { reload } from '../../../../helpers/Util';

class WorkflowSettingsPoWoOrders extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			remarkshtml: '',
		};
		this.api = new ApiService();
		this.styleOptions = [
			{ value: 1, label: 'List' },
			{ value: 2, label: 'Total' },
			{ value: 3, label: 'Ignore' },
		];
		this.closedByOptions = [
			{ value: 0, label: 'Vendor Inv.' },
			{ value: 1, label: 'Received' },
			{ value: 2, label: 'Both' },
		];
		this.checkboxes1 = [
			// {
			//     label: 'Single Item',
			//     id: 'singleitempo',
			//     checked: props.data.company1.singleitempo,
			//     onChange: (e, meta) => this.handleCompanyChange(e, meta),
			// },
			// {label: '"Agent for"', id: 'poagentfor', checked: props.data.company1.poagentfor, onChange: (e, meta) => this.handleCompanyChange(e, meta)},
			{
				label: 'Show Email & Website',
				id: 'poemail',
				checked: props.data.company2.poemail,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
			{
				label: 'One PO Per Component',
				id: 'onepo',
				checked: props.data.company2.onepo,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
			{
				label: 'Default to Change Order on Edit',
				id: 'pochangeorder',
				checked: props.data.company1.pochangeorder,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			// {label: 'Show Comps. on Delivery Ticket', id: 'deltickshowcomps', checked: props.data.company2.deltickshowcomps, onChange: (e, meta) => this.handleCompany2Change(e, meta)}
		];
		this.checkboxes2 = [
			{
				label: 'Merchandise',
				id: 'poautom',
				checked: props.data.company1.poautom,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Freight 2',
				id: 'poautod',
				checked: props.data.company1.poautod,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Design Fee',
				id: 'poautof',
				checked: props.data.company1.poautof,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Install/Labor',
				id: 'poautoi',
				checked: props.data.company1.poautoi,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Crating',
				id: 'poautol',
				checked: props.data.company1.poautol,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
		];
		this.proposals = [
			{
				markUpKey: 'compviewd',
				titleId: 'podesigndesc',
				styleId: 'podesignstyle',
			},
			{
				titleId: 'pofreightdesc',
				styleId: 'pofreightstyle',
				markUpKey: 'compviewf',
			},
			{
				titleId: 'poinstalldesc',
				styleId: 'poinstallstyle',
				markUpKey: 'compviewi',
			},
			{
				titleId: 'polabordesc',
				styleId: 'polaborstyle',
				markUpKey: 'compviewl',
			},
		];
		this.updatedCompanyData = {};
		this.updatedCompany2Data = {};
		this.htmlToRtfCoverter = new HtmlToRtfBrowser();

		this.mapRtfToDraft(props.data.company2.poremarksrtf);
	}

	componentDidUpdate(prevProps, prevState) {
		this.dMloader(false, true);
	}

	handleCompanyChange = (e, meta) => {
		const keyValue = this.handleChange(e, meta);
		this.updatedCompanyData[keyValue.key] = keyValue.value;
	};

	handleCompany2Change = (e, meta) => {
		const keyValue = this.handleChange(e, meta);
		this.updatedCompany2Data[keyValue.key] = keyValue.value;
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		return { key: key, value: value };
	};

	handleRemarks = (e) => {
		this.updatedCompany2Data['poremarks'] = e.target.value;
		this.updatedCompany2Data['poremarksrtf'] =
			this.htmlToRtfCoverter.convertHtmlToRtf(e.target.html);
	};

	handleSave = async (e) => {
		e.preventDefault();

		try {
			await this.api.patchCompany(this.updatedCompanyData);
			await this.api.patchCompany2(this.updatedCompany2Data);

			displayAlert('success', 'Successfully saved changes.');
			reload();
		} catch {
			displayAlert('danger', 'Could not save changes.');
		}
	};

	mapRtfToDraft(rtf) {
		if (!rtf) {
			return;
		}

		const self = this;
		const doc = new RTFJS.Document(stringToArrayBuffer(rtf), {});

		doc
			.render()
			.then(function (htmlElements) {
				const html = htmlElements
					.map((element) => {
						return element.outerHTML;
					})
					.join(' ');
				self.setState({
					remarkshtml: `${html}`,
				});
			})
			.catch((error) => console.error(error));
	}

	render() {
		return (
			<>
				<div className="content-padding min-height">
					<Container className="mb-4">
						<Col>
							<Row className={'mb-2'}>
								<Col xs={11} lg={6}>
									<Row>
										<Col xs={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 mt-2 pt-1">
												PO Title
											</Form.Label>
										</Col>
										<Col xs={4}>
											<Form.Control
												type="text"
												maxLength={30}
												placeholder=""
												id="potitle"
												defaultValue={this.props.data.company1.potitle}
												onChange={this.handleCompanyChange}
											/>
										</Col>
									</Row>
								</Col>
								<Col className="mt-sm-2 mt-lg-0">
									<Row className={'align-items-center'}>
										<Col xs={4}>
											<Form.Label className="mb-0">
												Closed By (Does not apply to inventory POs)
											</Form.Label>
										</Col>
										<Col xs={4}>
											<Select
												menuPortalTarget={document.body}
												styles={{
													menuPortal: (base) => ({
														...base,
														zIndex: 9999,
													}),
												}}
												options={this.closedByOptions}
												className="react-select"
												defaultValue={
													this.closedByOptions.filter((option) => {
														return (
															option.value ===
															this.props.data.company1.poclosedby
														);
													})[0]
												}
												placeholder="Please select"
												name="poclosedby"
												onChange={this.handleCompanyChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="flex-nowrap overflow-auto">
								<Col xs={11} lg={6}>
									<Row className={'flex-nowrap mb-2'}>
										<Col xs={3} />
										<Col className="mb-2 mb-lg-0">
											<Form.Label className="mb-0 mt-2 pt-1">Title</Form.Label>
										</Col>
										<Col>
											<Form.Label className="mb-0 mt-2 pt-1">Style</Form.Label>
										</Col>
									</Row>

									{this.proposals.map((proposal) => {
										const title = `${proposal.markUpKey}`;
										return (
											<Row
												key={`${Math.floor(Math.random() * 10000)}-min`}
												className={'flex-nowrap mb-2'}
											>
												<Col xs={3} className="text-lg-end mb-2 mb-lg-0">
													<Form.Label className="mb-0 mt-2 pt-1">
														{this.props.data.company1[title]}
													</Form.Label>
												</Col>
												<Col className="text-lg-end mb-2 mb-lg-0">
													<Form.Control
														type="text"
														maxLength={30}
														placeholder=""
														id={proposal.titleId}
														defaultValue={
															this.props.data.company1[proposal.titleId]
														}
														onChange={this.handleCompanyChange}
													/>
												</Col>
												<Col>
													<Select
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 9999,
															}),
														}}
														options={this.styleOptions}
														className="react-select"
														defaultValue={
															this.styleOptions.filter((option) => {
																return (
																	option.value ===
																	this.props.data.company1[proposal.styleId]
																);
															})[0]
														}
														placeholder="Please select"
														name={proposal.styleId}
														onChange={this.handleCompanyChange}
													/>
												</Col>
											</Row>
										);
									})}
								</Col>
								<Col xs={12} md={6} className={'ps-4'}>
									<Row>
										<Col>
											{this.checkboxes1.map((checkbox) => {
												return (
													<Row key={`${Math.floor(Math.random() * 10000)}-min`}>
														<Form.Check
															inline
															className={'mb-2'}
															label={checkbox.label}
															name={checkbox.id}
															type="checkbox"
															id={checkbox.id}
															defaultChecked={checkbox.checked}
															onChange={checkbox.onChange}
														/>
													</Row>
												);
											})}
										</Col>
										<Col>
											<Row>
												<Form.Label className="mb-2 mt-2 pt-1 ps-0">
													PO/WO Dates N/A
												</Form.Label>
											</Row>
											{this.checkboxes2.map((checkbox) => {
												return (
													<Row key={`${Math.floor(Math.random() * 10000)}-min`}>
														<Form.Check
															inline
															className={'mb-2'}
															label={checkbox.label}
															name={checkbox.id}
															type="checkbox"
															id={checkbox.id}
															defaultChecked={checkbox.checked}
															onChange={checkbox.onChange}
														/>
													</Row>
												);
											})}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col xs={11} lg={6}>
									<Row>
										<Col xs={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 mt-2">
												Purchase Order Conditions
											</Form.Label>
										</Col>
										<Col>
											<WysiwygEditor onChange={this.handleRemarks}>
												{this.state.remarkshtml}
											</WysiwygEditor>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Container>
				</div>

				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleSave}>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(WorkflowSettingsPoWoOrders);
