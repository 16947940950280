import {
	Button,
	Col,
	Container,
	Dropdown,
	OverlayTrigger,
	Row,
	Tooltip,
} from 'react-bootstrap';
import { compareStr } from '../../../helpers/String';
import MiniZoomer from '../../components/MiniZoomer';
import ListFilter from '../../components/ListFilter';
import React, { useState } from 'react';
import SecureButton from 'legacy/app/components/security/SecureButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import SecureDropdownItem from 'legacy/app/components/security/SecureDropdownItem';

export const Toolbar = ({
	viewType,
	actionsAvailable,
	searchAvailable,
	downloadAvailable,
	onSwitchView,
	onZoomChange,
	onAddFile,
	onAddLink,
	onDelete,
	onDownload,
	onSearch,
}) => {
	const [isSearching, setSearching] = useState(false);

	const handleSearch = () => {
		setSearching(!isSearching);

		onSearch();
	};

	return (
		<>
			<Container fluid>
				<Row
					className="border-1 border-sand bg-ivory roundedx-4 justify-content-between py-3"
					style={{ padding: '14px 8px' }}
				>
					<Col className="d-flex flex-row justify-content-start align-items-center flex-grow-0">
						<Button
							variant={`${compareStr(viewType, 'table', 'dark', 'light')}`}
							size="sm"
							className={`${compareStr(
								viewType,
								'table',
								'bg-primary-ash'
							)} square wrap-after py-0 ri-list-unordered`}
							data-view-type="table"
							onClick={onSwitchView}
						/>
						<Button
							variant={`${compareStr(viewType, 'grid', 'dark', 'light')}`}
							size="sm"
							className={`${compareStr(
								viewType,
								'grid',
								'bg-primary-ash'
							)} square wrap-after py-0 mx-2 ri-microsoft-fill`}
							data-view-type="grid"
							onClick={onSwitchView}
						/>
						<MiniZoomer
							changeZoom={onZoomChange}
							disabled={viewType !== 'grid'}
						></MiniZoomer>
					</Col>
					<Col className="d-flex flex-row justify-content-end align-items-center">
						<Row>
							<Col
								xs={12}
								md={'auto'}
								className="d-flex justify-content-end align-items-center pe-xs-2 pe-md-0"
							>
								{searchAvailable && (
									<Button
										variant={`${actionsAvailable ? 'ivory' : 'light'}`}
										size="sm"
										className={`btn-icon btn-action fw-bold me-2 ${
											isSearching ? 'bg-primary-ash text-white' : ''
										}`}
										disabled={!searchAvailable}
										onClick={handleSearch}
									>
										<i className="ri-search-line"></i> Search
									</Button>
								)}
								<Dropdown>
									<Dropdown.Toggle
										id="dropdown-autoclose-true"
										variant={`${actionsAvailable ? 'ivory' : 'light'}`}
										size="sm"
										disabled={!actionsAvailable}
									>
										Actions
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<SecureDropdownItem
											attributeNo={39}
											attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
											onClick={onDelete}
										>
											<i className="ri-delete-bin-line"></i> Delete
										</SecureDropdownItem>
										<OverlayTrigger
											overlay={
												<Tooltip>
													Only selected files will be downloaded
												</Tooltip>
											}
										>
											<Dropdown.Item
												onClick={onDownload}
												disabled={downloadAvailable}
											>
												<i className="ri-download-line"></i> Download
											</Dropdown.Item>
										</OverlayTrigger>
									</Dropdown.Menu>
								</Dropdown>
							</Col>
							<Col className="d-flex justify-content-end align-items-center mt-2 mt-md-0">
								<SecureButton
									attributeNo={13}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
									variant="primary"
									size="sm"
									className="btn-icon me-2"
									onClick={onAddLink}
								>
									<i className="ri-add-fill"></i> Add Link
								</SecureButton>
								<SecureButton
									attributeNo={13}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
									variant="primary"
									size="sm"
									className="btn-icon me-2"
									onClick={onAddFile}
								>
									<i className="ri-add-fill"></i> Add File
								</SecureButton>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
			<ListFilter.Spacer />
		</>
	);
};
