import ThreadComponent from '../../../../templates/modules/ThreadComponent';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import React from 'react';
import { WithRouter } from '../../../../helpers/Router';
import { currencyFormat } from '../../../../helpers/Number';
import { delay } from 'lodash';
import { navigateOnSuccess } from '../../../../utilities/Router';
import { ApiService } from '../../../../lib/api/HttpService';

var timeout;

class ProjectProposalEditModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: {},
			proposal: {},
			receivedDeposit: 0,
			receivedDepositValueOnly: 0,
		};

		this.api = new ApiService();
	}

	async handleSave() {
		const { manualdep, id } = this.props.proposal;
		if (manualdep) {
			// post
			await this.api.updateProposalDeposit({
				receivedDeposit: this.state.receivedDepositValueOnly,
			});
		} else {
			// patch
			await this.api.patchProposalDeposit(id, {
				manualdep: true,
			});
		}

		this.props.hideModal();
	}

	handleDateChange = (value) => {
		if (!isNaN(value)) {
			this.setState(
				{
					receivedDeposit: value,
					receivedDepositValueOnly: value,
				},
				this.formatValue
			);
		}
	};

	async componentDidUpdate(previousProps, previousState) {
		if (previousState.data != this.props.data) {
			this.setState({
				data: this.props.data,
				receivedDeposit: this.props.data.actdep,
			});
		}
	}

	formatValue = () => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			this.setState({
				receivedDeposit: currencyFormat(this.state.receivedDeposit, ''),
			});
		}, 2000);
	};

	render() {
		return (
			<Modal
				size="lg"
				show={this.props.show}
				onHide={() => this.props.hideModal()}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal"
				backdrop="static"
			>
				<Modal.Body>
					<div className="p-5 mt-5 mx-auto" style={{ maxWidth: '600px' }}>
						<h4 className="ff-type-medium">Edit Deposit</h4>
						<div className="form-fields px-4 mt-5">
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="4"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Requested Deposit:
								</Form.Label>
								<Col sm="8">
									<Form.Control
										disabled={true}
										type="text"
										placeholder=""
										size="sm"
										value={currencyFormat(
											this.state.data.estdep
												? this.state.data.estdep.toFixed(2)
												: 0.0,
											''
										)}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="4"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Received Deposit:
								</Form.Label>
								<Col sm="8">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										value={this.state.receivedDeposit}
										onChange={(e) => this.handleDateChange(e.target.value)}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="4"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Applied Deposit:
								</Form.Label>
								<Col sm="8">
									<Form.Control
										disabled={true}
										type="text"
										placeholder=""
										size="sm"
										value={currencyFormat(
											this.state.data.invbal
												? this.state.data.invbal.toFixed(2)
												: 0.0,
											''
										)}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="4"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Available Deposit:
								</Form.Label>
								<Col sm="8">
									<Form.Control
										type="text"
										placeholder=""
										size="sm"
										value={currencyFormat(
											this.state.data.invbal
												? (
														parseFloat(this.state.receivedDeposit) -
														parseFloat(this.state.data.invbal)
												  ).toFixed(2)
												: 0.0,
											''
										)}
									/>
								</Col>
							</Form.Group>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="bg-beige py-4 px-5 sidebar-footer-style">
					<Row className="w-100">
						<Col lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								className="w-100 mb-2"
								onClick={() => {
									this.handleSave();
								}}
							>
								Save
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								className="w-100"
								onClick={() => {
									this.props.hideModal();
								}}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(ProjectProposalEditModal);
