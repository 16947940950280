export default function WarningMessage() {
	return (
		<div className="d-flex align-items-center">
			<div>
				<i className="ri-alert-fill ri-3x text-warning"></i>
			</div>
			<div className="px-3">
				<div className="fs-5 fw-bold">WARNING:</div>
				<div>
					This reconciliation does <span className="fw-bold">NOT</span> balance.
				</div>
			</div>
		</div>
	);
}
