import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { displayAlert } from 'legacy/utilities/Response';
import { FC } from 'react/index';

export const StockItemsDuplicateModal: FC<{
	onConfirm: (stockNo: string) => void;
	onCancel: () => void;
}> = ({ onConfirm, onCancel }) => {
	const [stockNo, setStockNo] = useState('');

	const handleChange = (e: any) => {
		const value = e.target.value;
		if (value.length > 0 && !/^[a-z0-9]+$/i.test(value)) {
			return;
		}

		setStockNo(value.toUpperCase());
	};

	const handleConfirm = () => {
		if (!stockNo.length) {
			displayAlert('danger', 'Stock No. is required');

			return;
		}

		onConfirm(stockNo);
	};

	return (
		<Modal
			show={true}
			aria-labelledby="action-modal"
			className="a-modal"
			backdrop="static"
			centered
		>
			<Modal.Body>
				<Row className="mb-3">
					<Col className="px-5">
						<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
							Duplicate
						</h5>
						<div className="text-center mx-auto fw-bold pt-4">
							<Row className="align-items-center mb-3">
								<Col xs={3} className="text-end">
									<Form.Label className="mb-0">Stock No.</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										onChange={handleChange}
										maxLength={20}
										value={stockNo ?? ''}
									/>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-3 px-5">
				<Row className="w-100">
					<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
						<Button
							variant="primary"
							onClick={handleConfirm}
							className="w-100 m-2"
						>
							OK
						</Button>

						<Button
							variant="trans-light border-secondary-ash"
							onClick={onCancel}
							className="w-100 m-2"
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};
StockItemsDuplicateModal.displayName = 'StockItemsDuplicateModal';
