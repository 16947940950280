import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useController, useForm } from 'react-hook-form';
import { useGetReconciliations } from 'api/hooks/useGetReconciliations';
import dayjs from 'dayjs';
import { usePostTemporaryReconciliationsStart } from 'api/hooks/usePostTemporaryReconciliationsStart';
import AccountsDropdown, {
	EAccountType,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { Controller } from 'react-hook-form';
import CurrencyInput from 'legacy/pages/accounts-payable/credit-card/reconcile/CurrencyInput';
import cn from 'classnames';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { TCashAccount, TFormValues } from './types/CheckbookReconcileTypes';
import { useAccounts } from 'legacy/pages/accounts-payable/credit-card/reconcile/hooks';

const CheckbookReconcileForm = () => {
	const { started, setStarted, setCheckbook, resetState, setUpdateTotals } =
		useCheckbookReconcile();
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [showReconciliationErrorModal, setShowReconciliationErrorModal] =
		useState(false);
	const { data: accounts } = useAccounts(EAccountType.CHECKING);

	const { data: reconciliations = [], isSuccess: isGetReconciliationsSuccess } =
		useGetReconciliations({
			enabled: formSubmitted,
		});

	const {
		mutate: postTemporaryReconciliationStart,
		isSuccess: isPostReconciliationStartSuccess,
	} = usePostTemporaryReconciliationsStart();

	const {
		control,
		formState: { errors, isValid },
		getValues,
		handleSubmit,
		setValue,
	} = useForm<TFormValues>({
		defaultValues: {
			cashAccount: null,
			statementDate: null,
			endingBalance: null,
		},
	});

	const { field } = useController({
		name: 'cashAccount',
		control,
		rules: { required: true },
	});

	// Set the first account.
	useEffect(() => {
		if (!accounts) return;
		const [firstAccount] = accounts;
		if (firstAccount) {
			setValue('cashAccount', {
				label: `[${firstAccount.account}] ${firstAccount.accountn}`,
				value: firstAccount.account,
			});
		}
	}, [accounts, setValue]);

	const postReconciliationStart = useCallback(() => {
		if (
			formSubmitted &&
			isGetReconciliationsSuccess &&
			reconciliations.length === 0
		) {
			const payload = {
				cashAccount: getValues()?.cashAccount?.value ?? '',
				statementDate: dayjs(new Date(getValues()?.statementDate ?? '')).format(
					'YYYY-MM-DD'
				),
			};
			postTemporaryReconciliationStart(payload);
		}
	}, [
		formSubmitted,
		isGetReconciliationsSuccess,
		reconciliations,
		getValues,
		postTemporaryReconciliationStart,
	]);

	const handleReconciliationError = useCallback(() => {
		if (
			formSubmitted &&
			isGetReconciliationsSuccess &&
			reconciliations.length > 0
		) {
			setShowReconciliationErrorModal(true);
		}
	}, [formSubmitted, isGetReconciliationsSuccess, reconciliations]);

	const startReconciliation = useCallback(() => {
		if (isPostReconciliationStartSuccess) {
			setStarted(true);

			const { cashAccount, statementDate, endingBalance } = getValues();

			setCheckbook({
				cashAccount: cashAccount?.value ?? '',
				statementDate: dayjs(new Date(statementDate ?? '')).format(
					'YYYY-MM-DD'
				),
				endingBalance: endingBalance ?? 0,
			});

			setUpdateTotals(true);
		}
	}, [
		isPostReconciliationStartSuccess,
		setStarted,
		getValues,
		setCheckbook,
		setUpdateTotals,
	]);

	useEffect(() => {
		postReconciliationStart();
	}, [postReconciliationStart]);

	useEffect(() => {
		handleReconciliationError();
	}, [handleReconciliationError]);

	useEffect(() => {
		startReconciliation();
	}, [startReconciliation]);

	const handleOnConfirm = () => {
		resetState();
		setShowReconciliationErrorModal(false);
	};

	const handleOnAccountChange = (account: TCashAccount) => {
		resetState();
		setValue('cashAccount', account);
		setValue('statementDate', null);
		setValue('endingBalance', null);
		setFormSubmitted(false);
	};

	const onSubmit = () => {
		setFormSubmitted(true);
	};

	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="tw-grid tw-grid-flow-col tw-justify-start tw-align-baseline tw-gap-2 lg:tw-gap-4"
			>
				<div className="tw-grid tw-grid-flow-row tw-justify-center tw-items-center tw-gap-1">
					<label>Account *</label>
					<AccountsDropdown
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...field}
						className={cn('tw-self-end tw-w-64 tw-h-10', {
							'tw-border tw-rounded tw-border-red-500': errors.cashAccount,
						})}
						type={EAccountType.CHECKING}
						onChange={handleOnAccountChange}
					/>
				</div>

				<div className="tw-grid tw-grid-flow-row tw-justify-center tw-items-center tw-gap-1">
					<label>Statement Ending Date *</label>
					<div>
						<Controller
							control={control}
							name="statementDate"
							rules={{ required: true }}
							render={({ field: { onChange, value } }) => (
								<ReactDatePicker
									minDate={new Date()}
									dateFormat="MM/dd/yyyy"
									onChange={onChange}
									className={cn(
										'form-control form-control-sm tw-w-48 tw-h-10',
										{
											'border-danger': errors.statementDate,
										}
									)}
									showMonthDropdown={true}
									showYearDropdown={true}
									placeholderText="mm-dd-yyyy"
									autoComplete="off"
									selected={value}
									disabled={started}
								/>
							)}
						/>
					</div>
				</div>

				<div className="tw-grid tw-grid-flow-row tw-justify-center tw-items-center tw-gap-1">
					<label>Statement Ending Balance *</label>
					<CurrencyInput
						className={cn('tw-w-48 tw-h-10', {
							'border-danger': errors.endingBalance,
						})}
						defaultValue={''}
						name="endingBalance"
						control={control}
						rules={{ required: true }}
						disabled={started}
					/>
				</div>
				<div className="tw-self-end">
					<Button
						variant="primary"
						type="submit"
						disabled={!isValid || started}
					>
						Start Reconcile
					</Button>
				</div>
			</form>
			{!started && (
				<div className="tw-mt-4">
					<p>Fields marked with an asterisk (*) are required.</p>
				</div>
			)}
			<ConfirmationModal
				show={showReconciliationErrorModal}
				title="Checkbook Reconciliation"
				message="There is already a reconciliation for this account that is newer or the same as this one.  Please use the history button to view completed reconciliations."
				hideCancel={true}
				confirmAction={handleOnConfirm}
				toggleModal={() => setShowReconciliationErrorModal(false)}
			/>
		</>
	);
};

CheckbookReconcileForm.displayName = 'CheckbookReconcileForm';

export { CheckbookReconcileForm };
