import CompTypeDropdown from 'legacy/app/components/dropdowns/CompTypeDropdown';
import FormCurrencyInput from 'legacy/app/components/form/FormCurrencyInput';
import useComponentRecalculate from 'legacy/lib/api/hooks/useComponentRecalculate';
import { Component } from 'legacy/lib/api/types/Component';
import { CompNumericPercentType } from 'legacy/lib/api/types/CompPercentType';
import {
	TWhatChangedComponent,
	TWhatChangedComponentPayload,
} from 'legacy/lib/api/types/WhatChangedComponent';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import normalizeRecalculateDataPayload from '../utils/normalizeRecalculateDataPayload';
import convertObjectPropsToLowercase from 'legacy/utilities/convertObjectPropsToLowercase';
import normalizeRecalculateDataResponse from '../normalizeRecalculateDataResponse';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import useGetCompanyInfo from 'legacy/lib/api/hooks/useGetCompanyInfo';
import getDescriptionForSubComponent from 'legacy/utilities/getDescriptionForSubComponent';
import { Company } from 'legacy/lib/api/types/Company';
import { useQueryClient } from '@tanstack/react-query';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { useParams } from 'react-router-dom';

type TSubComponentProps = {
	component: Component;
	mainComponentComp: string;
	updateComponent: (newComp: Component) => void;
	disableInputs: boolean;
	setDeletedComponent: (component: Component) => void;
};

const arePropsEqual = (
	prevProps: TSubComponentProps,
	nextProps: TSubComponentProps
) => {
	return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

const SubComponent = memo(
	({
		component,
		mainComponentComp,
		updateComponent,
		disableInputs,
		setDeletedComponent,
	}: TSubComponentProps) => {
		const [whatChanged, setWhatChanged] = useState<
			TWhatChangedComponentPayload | object
		>({});
		const { data: company, isLoading: isFetchingCompany } = useGetCompanyInfo();
		const { id: projectId } = useParams();
		const { data: project } = useGetProject(projectId as string);
		const queryClient = useQueryClient();
		const componentRef = useRef(component);
		const compTypeRef = useRef<null | number>(null);
		const costRef = useRef<null | number>(null);
		const priceRef = useRef<null | string>(null);

		const { data: recalculatedData, isFetching: isRecalculating } =
			useComponentRecalculate(
				Number(component.id),
				JSON.stringify(whatChanged),
				{
					enabled: Object.values(whatChanged).length > 0,
				}
			);

		useEffect(() => {
			componentRef.current = component;
		}, [component]);

		useEffect(() => {
			if (recalculatedData) {
				updateComponent({
					...componentRef.current,
					...(convertObjectPropsToLowercase(
						normalizeRecalculateDataResponse(recalculatedData)
					) as Component),
				});
			}
		}, [recalculatedData, updateComponent]);

		const handleRecalculate = (
			whatPropertyChanged: `${TWhatChangedComponent}`,
			component: Component
		) => {
			const newWhatChanged = {
				whatChanged: whatPropertyChanged,
				...normalizeRecalculateDataPayload(component),
			};
			setWhatChanged(newWhatChanged);

			if (JSON.stringify(newWhatChanged) === JSON.stringify(whatChanged)) {
				queryClient.resetQueries([
					Number(component.id),
					'recalculateComponent',
					JSON.stringify(newWhatChanged),
				]);
			}
		};

		const getCostInputProperty = () =>
			component.usedisc === CompNumericPercentType.Fee ? 'feecost' : 'estcost';

		return (
			<>
				<div className="tw-self-end">
					<CompTypeDropdown
						isDisabled={isRecalculating || disableInputs || isFetchingCompany}
						value={{
							label: '',
							value: component.comptype,
						}}
						onChange={(newValue) => {
							compTypeRef.current = newValue?.value as number;
							const componentCopy = {
								...componentRef.current,
								comptype: compTypeRef.current,
							};

							updateComponent({
								...component,
								...getDescriptionForSubComponent(
									mainComponentComp,
									componentCopy,
									company as Company
								),
								comptype: Number(newValue?.value),
							});
						}}
						blurInputOnSelect
						onBlur={() => {
							if (compTypeRef.current === null) {
								return;
							}
							handleRecalculate(TWhatChangedComponent.cwcComponentType, {
								...component,
								// Need to use a ref because the onBlur event doesn´t have the value
								comptype: compTypeRef.current as number,
							});
						}}
					/>
				</div>
				<div>
					<FormCurrencyInput
						disabled={isRecalculating || disableInputs}
						id="Cost"
						style={{
							height: 41,
						}}
						decimalSeparator="."
						groupSeparator=","
						allowNegativeValue
						value={component[getCostInputProperty()]}
						maxLength={15}
						onValueChange={(value) => {
							// Need to use a ref because the onBlur event doesn´t normalize the value
							costRef.current = Number(value);
							updateComponent({
								...component,
								[getCostInputProperty()]: value,
							});
						}}
						onBlur={() => {
							const value = Number(costRef.current) || 0;
							handleRecalculate(
								component.usedisc === CompNumericPercentType.Discount
									? TWhatChangedComponent.cwcListPrice
									: TWhatChangedComponent.cwcEstimatedCost,
								{
									...component,
									estunitcost: value,
									unitlist: value,
									list: value,
									[getCostInputProperty()]: value,
								}
							);
						}}
					/>
				</div>
				<div>
					<FormControl
						disabled={isRecalculating || disableInputs}
						id="Markup"
						onChange={(e) =>
							updateComponent({
								...component,
								markup: e.target.value,
							})
						}
						value={component.markup}
						maxLength={15}
						onBlur={(e) => {
							const isNotAValidNumber = isNaN(Number(e.target.value));
							const isNotValid =
								!e.target.value ||
								isNotAValidNumber ||
								e.target.value === '-0.';
							if (isNotValid) {
								updateComponent({
									...component,
									markup: 0,
								});
							} else {
								updateComponent({
									...component,
									markup: Number(e.target.value),
								});
							}
							handleRecalculate(TWhatChangedComponent.cwcPercent, {
								...component,
								markup: isNotValid ? 0 : Number(e.target.value),
							});
						}}
					/>
				</div>
				<div>
					<FormCurrencyInput
						disabled={isRecalculating || disableInputs}
						id="Price"
						style={{
							height: 41,
						}}
						decimalSeparator="."
						groupSeparator=","
						allowNegativeValue
						value={component.estprice}
						maxLength={15}
						onValueChange={(value) => {
							// Need to use a ref because the onBlur event doesn´t normalize the value
							priceRef.current = value;
							updateComponent({
								...component,
								estprice: priceRef.current,
							});
						}}
						onBlur={() => {
							handleRecalculate(TWhatChangedComponent.cwcEstimatedPrice, {
								...component,
								estprice: Number(priceRef.current) || 0,
							});
						}}
					/>
				</div>
				<Form.Check
					size={30}
					disabled={isRecalculating || disableInputs}
					className="tw-self-end"
					type="checkbox"
					label="Taxable"
					checked={component.taxable}
					maxLength={15}
					onChange={(e) =>
						updateComponent({
							...component,
							taxable: e.target.checked,
						})
					}
					onBlur={(e) => {
						handleRecalculate(TWhatChangedComponent.cwcTaxable, {
							...component,
							taxable: e.target.checked,
						});
					}}
				/>

				<SecureBootstrapButton
					attributeNo={14}
					attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
					variant="ivory"
					size="sm"
					disabled={disableInputs || !!project?.closeddt}
					onClick={() => setDeletedComponent(component)}
					className="tw-self-end"
				>
					<i className="ri-close-line" /> Delete
				</SecureBootstrapButton>
			</>
		);
	},
	arePropsEqual
);
SubComponent.displayName = 'SubComponent';
export default SubComponent;
