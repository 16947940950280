import React, { FC } from 'react';
import { ApiService } from 'legacy/lib/api/HttpService';
import {
	AlertProps,
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	getErrorMessage,
} from 'legacy/utilities/Response';
import MSG from 'legacy/defaults/Message';
import { Button, Col, Modal, Row } from 'react-bootstrap';

export interface IResponses {
	objIds: string[];
	objActions: [];
	message: string;
	objectType: string;
	deleteType: string;
	params: {
		moduleSingular: string;
		modulePlural: string;
	};
}

export const StockItemsDeleteModal: FC<{
	responses: IResponses;
	refreshData: () => void;
	hideModal: () => void;
	title?: string;
}> = ({ responses, refreshData, hideModal, title }) => {
	const handleAction = async () => {
		if (
			responses.message !=
			'This Proposal cannot be deleted because it either has a deposit received or one or more items have been purchased or invoiced.'
		) {
			displayAlertLoader(MSG.loading.delete.msg);

			try {
				const errIds: any = [];

				const completeResponses = [
					'dmriFullyDeleted',
					'dmriMadeInactive',
					'dmriMarkedComplete',
				];
				const deleteObjectParams: any = {
					objectType: 'objInventoryItem',
				};

				for (let i = 0; i < responses.objIds.length; i++) {
					deleteObjectParams.objectCodeOrId = responses.objIds[i];
					switch (responses.objActions[i]) {
						case 'delete':
							deleteObjectParams.deleteType = 'dmriTestAndFullDeleteIfPossible';
							break;
						case 'deactivate':
							deleteObjectParams.deleteType = 'dmriTestAndDeactivate';
							break;
						case 'complete':
							deleteObjectParams.deleteType = 'dmriTestAndMakeComplete';
							break;
						default:
							deleteObjectParams.deleteType = '';
							break;
					}

					if (deleteObjectParams.deleteType !== '') {
						const res: any = await new ApiService().postJson(
							'deleteobjects',
							deleteObjectParams
						);

						if (!completeResponses.includes(res.result))
							errIds.push(responses.objIds[i]);
					}
				}

				const variant: AlertProps['variant'] = errIds.length
					? 'danger'
					: 'success';

				displayAlert(variant, makeErrorMessage(errIds, deleteObjectParams));

				refreshData();
			} catch (error: any) {
				displayAlertError(getErrorMessage(error));
			}
		}

		hideModal();
	};

	const makeErrorMessage = (errIds: [], deleteObjectParams: any): string => {
		const name =
			responses.objIds.length > 1
				? responses.params.modulePlural
				: responses.params.moduleSingular;

		if (errIds.length) {
			return `Oops, something went wrong while deleting some ${name}.`;
		}

		switch (deleteObjectParams.deleteType) {
			case 'dmriTestAndMakeComplete':
				return `${name} cannot be deleted/deactivated, marked as completed instead.`;
			case 'dmriTestAndDeactivate':
				return `Successfully deactivated ${name}.`;
			case 'dmriTestAndFullDeleteIfPossible':
				return `Successfully deleted ${name}.`;
			default:
				return `Successfully deleted/deactivated ${name}.`;
		}
	};

	const checkActionsIsAvailable = () => {
		let isAction = false;

		for (const action of responses.objActions) {
			if (action !== 'none') {
				isAction = true;
			}
		}

		return isAction;
	};

	const hasAction =
		(responses.objIds.length == 1 && checkActionsIsAvailable()) ||
		(responses.objIds.length && checkActionsIsAvailable());

	return (
		<Modal
			show={true}
			aria-labelledby="action-modal"
			className="a-modal"
			backdrop="static"
			centered
		>
			<Modal.Body>
				<Row className="mb-3">
					<Col className="px-5">
						<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
							{title ?? 'Delete Confirm'}
						</h5>
						<div className="text-center mx-auto fw-bold pt-4">
							<h6>{responses.message}</h6>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="bg-ivory py-3 px-5">
				<Row className="w-100">
					<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
						{hasAction ? (
							<Button
								variant="danger"
								onClick={handleAction}
								className="w-100 m-2"
							>
								OK
							</Button>
						) : (
							<Button
								variant="trans-light border-secondary-ash"
								onClick={hideModal}
								className="w-100 m-2"
							>
								OK
							</Button>
						)}

						<Button
							variant="trans-light border-secondary-ash"
							onClick={hideModal}
							className={`w-100 m-2 ${hasAction ? 'd-block' : 'd-none'}`}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};
StockItemsDeleteModal.displayName = 'StockItemsDeleteModal';
