import React from 'react';
import {
	Col,
	Container,
	Row,
	Table,
	Form,
	Alert,
	Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { WithRouter } from '../../../helpers/Router';
import Switch from '../../components/Switch';
import DatePicker from 'react-datepicker';
import URI from '../../../defaults/RoutesDefault';
import ThreadComponent from '../ThreadComponent';
import Header from '../../components/Header';
import ListFilter from '../../components/ListFilter';
import { ApiService } from '../../../lib/api/HttpService';
import { formatDate } from '../../../helpers/Date';
import { showLoading } from '../../../helpers/Loading';

class TaskAppointment extends ThreadComponent {
	constructor() {
		super();

		this.state = {
			checks: {},
			totalChecked: 0,
			data: [],
			showCalender: true,
			minDate: new Date(),
			dataIsLoaded: false,
		};
	}

	componentInit() {
		this.setTitle('Task & Appointment').setActionBar(true);
	}

	async componentDidMount() {
		let api = new ApiService();

		let appts = await api.getAppointments('');

		this.renderData(appts);
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			prev.showTableSearch = !prev.showTableSearch;
			return prev;
		});
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			let totalChecked = 0;
			prevState.checks[indx] = e.target.checked;

			if (prevState.checks) {
				/* eslint-disable no-unused-vars */
				for (const [index, value] of Object.entries(prevState.checks)) {
					if (value) {
						totalChecked++;
					}
				}
				/* eslint-enable no-unused-vars */
			}

			prevState.totalChecked = totalChecked;

			if (totalChecked > 0) {
				document.getElementById('inline-check-th-0').checked = true;
			} else {
				document.getElementById('inline-check-th-0').checked = false;
			}

			return prevState;
		});
	};

	handleSelectAllChecks = (e) => {
		// Get the target menu.
		const isChecked = e.target.checked;

		this.setState((prevState) => {
			if (isChecked) {
				if (this.state.totalChecked < this.state.data.length) {
					this.state.data.map((item, i) => {
						prevState.checks[i] = true;
						document.getElementById(
							'chk-projectview-items-' + i
						).checked = true;
						return null;
					});
				}
			} else {
				prevState.checks = {};
				prevState.totalChecked = 0;

				const checks = document.querySelectorAll(
					'.chk-projectview-items-item input'
				);
				if (checks) {
					checks.forEach((e) => {
						e.checked = false;
					});
				}
			}

			return prevState;
		});
	};

	handleCalendarShow = (e) => {
		this.setState((ps) => (ps.showCalender = e.target.checked));
	};

	handleDateChange = (date) => {
		this.setState((prevState) => (prevState.startDate = date));
	};

	renderHeader() {
		return (
			<Header>
				<Header.Title>Tasks & Appointments</Header.Title>
				<Header.Actions list={true}>
					<li>
						<Button
							as={Link}
							to={URI.timeManagement.appointmentAdd}
							variant="light"
							size="lg"
							className="btn-icon text-secondary-green"
						>
							<i className="ri-add-line ri-lg"></i> Add Appointment
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							to={URI.timeManagement.appointmentToDoAdd}
							variant="light"
							size="lg"
							className="btn-icon text-secondary-green"
						>
							<i className="ri-add-line ri-lg"></i> Add Task
						</Button>
					</li>
				</Header.Actions>
			</Header>
		);
	}

	renderCalendar() {
		if (this.state.showCalender) {
			return (
				<div
					className={`${
						this.state.showCalender ? 'd-block' : 'd-none'
					} ps-md-4 my-4 border-start border-beige bg-white zi-100`}
				>
					<h6 className="text-center fw-bold d-flex align-items-center justify-content-center mb-3">
						<i className="ri-calendar-fill me-2"></i> Calendar
					</h6>

					<div className="inline-react-datepicker react-select-header">
						<DatePicker
							selected={this.state.startDate}
							onChange={this.handleDateChange}
							showMonthDropdown="true"
							showYearDropdown="true"
							inline
						/>
						<div className="tasks-list mt-4">
							<Alert variant={'light'} className="border-0 active">
								<h6 className="mb-1 fw-bold fs-md">Meeting</h6>
								<small>Today at 4:00pm</small>
							</Alert>
							<Alert variant={'light'} className="border-0">
								<h6 className="mb-1 fw-bold fs-md">Task</h6>
								<small>Tomorrow at 8:00pm</small>
							</Alert>
							<Alert variant={'light'} className="border-0">
								<h6 className="mb-1 fw-bold fs-md">Meeting</h6>
								<small>August 10, 2022 at 2:00pm</small>
							</Alert>
						</div>
					</div>
				</div>
			);
		}
	}

	renderFilters() {
		return (
			<>
				<ListFilter className="task-appointment-filter">
					<ListFilter.Fields md={12} lg={8} xl={8} className="width-20">
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Show For</strong>
							</Form.Label>
							<Form.Select size="sm">
								<option>All</option>
							</Form.Select>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								<strong>Show Type</strong>
							</Form.Label>
							<Form.Select size="sm">
								<option>All</option>
							</Form.Select>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">
								Show past Appoinments / Complete task
							</Form.Label>
							<span>
								<Switch checked={true}></Switch>
							</span>
						</ListFilter.Field>
						<ListFilter.Field>
							<Form.Label className="text-end">Display Calendar</Form.Label>
							<span>
								<Switch
									checked={this.state.showCalender}
									onChange={this.handleCalendarShow}
								></Switch>
							</span>
						</ListFilter.Field>
					</ListFilter.Fields>

					<ListFilter.Actions md={12} lg={4} xl={4}>
						<ListFilter.Action className="ms-auto">
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
							<Button
								as={Link}
								to=""
								variant="ivory"
								size="sm"
								className="btn-icon btn-action ms-3"
							>
								<i className="ri-checkbox-fill"></i> Edit Associated Item
							</Button>
							<Button
								as={Link}
								to=""
								variant="ivory"
								size="sm"
								className="btn-icon btn-action ms-3"
							>
								<i className="ri-apps-fill"></i> Create Report
							</Button>
							<Button
								as={Link}
								to=""
								variant="ivory"
								size="sm"
								className="btn-icon btn-action ms-3"
							>
								<i className="ri-close-line"></i> Delete
							</Button>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar">
					<Container fluid className="px-0">
						<Row>
							{/* Filter */}
							<Col sm="12">{this.renderFilters()}</Col>
						</Row>

						<Row>
							<Col className="d-flex w-100 table-responsive-full">
								<Table striped responsive className="a-table w-100">
									<thead>
										<tr className="a-table-heading">
											<th align="middle" className="mw-70px">
												<div className="d-flex justify-content-center">
													<Form.Check
														inline
														label=""
														name={`inline-check-th-0`}
														type="checkbox"
														data-id={`th-0`}
														id={`inline-check-th-0`}
														defaultChecked={
															this.state.totalChecked > 0 ? true : false
														}
														className={`${
															this.state.totalChecked > 0 &&
															this.state.totalChecked < this.state.data.length
																? 'line'
																: ''
														}`}
														onClick={this.handleSelectAllChecks}
													/>
												</div>
											</th>
											<th>
												<span
													className="sort active asc"
													data-field="projectCodeNo"
												>
													Start Date
												</span>
											</th>
											<th>
												<span className="sort">End/Due Date</span>
											</th>
											<th>
												<span className="sort">Type</span>
											</th>
											<th className="text-center">
												<span className="sort">Completed</span>
											</th>
											<th>
												<span className="sort">Subject</span>
											</th>
											<th>
												<span className="sort">Location</span>
											</th>
											<th>
												<span className="sort">Importance</span>
											</th>
											<th>
												<span className="sort">Reminder</span>
											</th>
											<th>
												<span className="sort">Private</span>
											</th>
											<th>
												<span className="sort">Contact/Notes</span>
											</th>
											<th>
												<span className="sort">Project Code</span>
											</th>
											<th>
												<span className="sort">Item No.</span>
											</th>
										</tr>
										<tr
											className={`a-table-search-fields ${
												this.state.showTableSearch ? '' : 'd-none'
											}`}
										>
											<th></th>
											<th>
												<Form.Control type="text" data-field="projectCodeNo" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
											<th>
												<Form.Control type="text" data-field="clientName" />
											</th>
										</tr>
									</thead>
									<tbody>
										{!this.state.dataIsLoaded
											? showLoading()
											: this.state.data.map((item, i) => (
													<tr
														key={i}
														data-key={i}
														className={this.state.checks[i] ? `active` : ''}
													>
														<td>
															<div className="d-flex justify-content-center">
																<Form.Check
																	label=""
																	name={`group-` + i}
																	type="checkbox"
																	data-id={i}
																	id={`chk-projectview-items-` + i}
																	className="chk-projectview-items-item"
																	defaultValue={i}
																	onClick={this.handleChecks}
																/>
															</div>
														</td>
														<td>{formatDate(item.startdatetime)}</td>
														<td>{formatDate(item.endduedatetime)}</td>
														<td>{item.istask ? 'Task' : 'Meeting'}</td>
														<td>
															<div className="d-flex justify-content-center">
																<strong
																	className={
																		item.complete
																			? `dot-primary`
																			: `dot-primary-red`
																	}
																></strong>
															</div>
														</td>
														<td>
															<Link
																to="/"
																className="text-charcoal hover-view-icon"
															>
																{item.subject}
															</Link>
														</td>

														<td>{item.location}</td>
														<td>
															<strong
																className={
																	item.importance
																		? `dot-primary`
																		: `dot-placeholder`
																}
															></strong>
														</td>
														<td>{formatDate(item.reminderdatetime)}</td>
														<td>
															<strong
																className={
																	item.private
																		? `dot-primary`
																		: `dot-placeholder`
																}
															></strong>
														</td>
														<td>{item.body}</td>
														<td>{item.code}</td>
														<td>{item.item}</td>
													</tr>
											  ))}
									</tbody>
								</Table>

								{this.renderCalendar()}
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(TaskAppointment);
