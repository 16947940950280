import { IJournalEntry } from 'api/resources/financeCharges';

export const formatChargesList = (journalEntries: IJournalEntry[]) => {
	return journalEntries.map((entry) => {
		return {
			account: entry.account,
			damt: entry.damt,
		};
	});
};

export const getAcctNameFromString = (accountName: string) => {
	return accountName?.replace(/ *\[[^)]*\] */g, '');
};
