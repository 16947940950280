import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Button,
	Container,
	Form,
	Row,
	Col,
	Table,
	Modal,
	Dropdown,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { HeaderLight } from '../../../components/Header';
import { WithRouter } from '../../../../helpers/Router';
import { fakeData } from '../../../factory/fakeFactory';
import ListFilter from '../../../components/ListFilter';
import URI from '../../../../defaults/RoutesDefault';

class VendorInvoiceAddRecurring extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			data: fakeData(4),
			checks: {},
			dataIsLoaded: false,
		};
	}

	componentInit() {
		this.setTitle('Add Invoice | Accounts Payable').setFormAction(true);

		// Set API calls.
		this.setApiURL('/data/products.json')
			.setApiParams({ responseKey: 'response' })
			.enableSearchAndSortTable()
			.fetchData();
	}

	renderData(data) {
		this.setState({
			dataIsLoaded: true,
			data: data,
		});
	}

	handleShowTableSearch = (e) => {
		this.setState((prev) => {
			prev.showTableSearch = !prev.showTableSearch;
			return prev;
		});
	};

	handleChecks = (e) => {
		// Get the target menu.
		const indx = e.target.getAttribute('data-id');
		// Set the new state.
		this.setState((prevState) => {
			prevState.checks[indx] = e.target.checked;
			return prevState;
		});
	};

	renderHeaderTitle() {
		let title = 'Recurring Expenses';

		return title;
	}

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={URI.accountsPayable.vendorInvoice.listNew}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold me-4"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsPayable.vendorInvoice.listNew,
							}}
							linkAs={Link}
						>
							Accounts Payable
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: URI.accountsPayable.vendorInvoice.listNew,
							}}
							linkAs={Link}
						>
							Vendor Invoices
						</Breadcrumb.Item>
						<Breadcrumb.Item>{this.renderHeaderTitle()}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.renderHeaderTitle()}</HeaderLight.Title>

					<div>
						{/* Submit Button */}

						<Button variant="primary">Save</Button>
					</div>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderListFilter() {
		return (
			<ListFilter expandable={0}>
				<ListFilter.Actions
					sm={12}
					className="ms-auto"
					replaceclassmatch="justify-content-xl-end"
					replaceclassmatchwith="justify-content-end"
				>
					<ListFilter.Action>
						<Button
							as={Link}
							to={URI.accountsPayable.vendorInvoice.editRec}
							variant="primary"
							size="sm"
							className="btn-icon ms-3"
						>
							<i className="ri-add-fill"></i> Add
						</Button>

						<Button
							as={Link}
							variant="light"
							size="sm"
							className="btn-icon ms-3"
						>
							<i className="ri-close-line"></i> Delete
						</Button>
					</ListFilter.Action>
				</ListFilter.Actions>
			</ListFilter>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar">
					<Container fluid className="px-0">
						<Row>
							<Col sm="12">
								{/* Filter */}
								{this.renderListFilter()}

								<div className="table-gradient">
									<Table striped responsive className="a-table">
										<thead>
											<tr key="0" className="a-table-heading">
												<th className="w-20px">
													<Form.Check
														inline
														label=""
														name={`group-all`}
														type="checkbox"
														data-id="all"
														id={`inline-radio-all`}
														onChange={this.handleChecks}
													/>
												</th>
												<th>
													<span
														className="sort active asc"
														data-field="projectCodeNo"
													>
														Vendor Code
													</span>
												</th>
												<th>
													<span className="sort">Vendor</span>
												</th>
												<th>
													<span className="sort">Description</span>
												</th>
												<th>
													<span className="sort">Amount</span>
												</th>
												<th>
													<span className="sort">Reminder</span>
												</th>
											</tr>
											<tr
												className={`a-table-search-fields ${
													this.state.showTableSearch ? '' : 'd-none'
												}`}
											>
												<th></th>
												<th>
													<Form.Control
														type="text"
														data-field="projectCodeNo"
													/>
												</th>
												<th>
													<Form.Control type="text" data-field="clientName" />
												</th>
												<th>
													<Form.Control type="text" data-field="clientName" />
												</th>
												<th>
													<Form.Control type="text" data-field="clientName" />
												</th>
												<th>
													<Form.Control type="text" data-field="clientName" />
												</th>
											</tr>
										</thead>
										<tbody>
											{this.state.data.map((item, i) => (
												<tr
													key={i}
													className={this.state.checks[i] ? `active` : ''}
												>
													<td>
														<Form.Check
															inline
															label=""
															name={`group-` + i}
															type="checkbox"
															data-id={i}
															id={`inline-radio-` + i}
															onChange={this.handleChecks}
														/>
													</td>
													<td>
														<Link
															to={URI.accountsPayable.vendorInvoice.editRec}
															className="text-charcoal hover-view-icon"
														>
															JOH01-0001
														</Link>
													</td>
													<td>Lorem ipsum name</td>
													<td>Lorem Ipsum</td>
													<td>$5000.00</td>
													<td>Lorem</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default WithRouter(VendorInvoiceAddRecurring);
