import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import {
	getErrorMessage,
	handleResponseAlert,
} from '../../../utilities/Response';
import { removeSpaces } from '../../../utilities/String';
import VendorAddEditViewModel from '../../pages/Vendor/VendorAddEditViewModel';
import { AlertError } from '../bootstrap/BAlerts';
import AddressAddEditViewModel from '../../pages/Address/AddressAddEditViewModel';
import PayeeAddressAddEditViewModel from '../../pages/Address/PayeeAddressAddEditViewModel';
import { InsuranceService } from '../../pages/Vendor/api/InsuranceService';
import { VendorService } from '../../pages/Vendor/api/VendorService';
import { AddressService } from '../../pages/Address/api/AddressService';
import { nameToCode, formatCode } from 'legacy/app/utils/codes';

interface Props {
	hideModal?: any;
	handleSuccess?: any;
	vendorNewName?: string;
	disableButtons?: boolean;
}

interface State {
	isLoading?: boolean;
	errorMessage?: string | null;
	vendorn?: string;
	vendor?: string;
}

class VendorQuickAddModal extends ThreadComponent<Props, State> {
	readonly vendorAddEditModel: VendorAddEditViewModel;
	addressAddEditModel: AddressAddEditViewModel;
	payeeAddressAddEditModel: PayeeAddressAddEditViewModel;

	constructor(props: Props) {
		super(props);

		this.state = {
			isLoading: false,
			errorMessage: null,
			vendor: nameToCode(this.props.vendorNewName ?? ''),
			vendorn: this.props.vendorNewName ?? '',
		};

		const vendorService = new VendorService();
		const insuranceService = new InsuranceService();
		const addressService = new AddressService();

		this.vendorAddEditModel = new VendorAddEditViewModel(
			vendorService,
			insuranceService
		);
		this.addressAddEditModel = new AddressAddEditViewModel(addressService);
		this.payeeAddressAddEditModel = new PayeeAddressAddEditViewModel(
			addressService
		);
	}

	setIsLoading(state: boolean) {
		this.setState({
			isLoading: state,
		});
	}

	handleAction: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		const data = {
			vendorn: this.state.vendorn ?? '',
			vendor: this.state.vendor ?? '',
			sortName1: this.state.vendorn,
		};
		let errorMessage;

		if (!removeSpaces(data.vendorn)) errorMessage = 'Vendor name is required';
		else if (!removeSpaces(data.vendor))
			errorMessage = 'Vendor code is required';

		if (errorMessage) {
			this.setState({
				errorMessage,
			});

			return;
		}

		const request = this.vendorAddEditModel.insertVendor(data);

		this.setIsLoading(true);
		this.setState({
			errorMessage: null,
		});

		handleResponseAlert(
			request,
			(res: any) => {
				this.setIsLoading(false);
				if (res.id) {
					const addressData: any = {};
					addressData.code = res?.vendor;
					addressData.addresstype = 2;
					addressData.sortname = res?.vendorn;

					this.addressAddEditModel.setClientCode(
						encodeURIComponent(res?.vendor)
					);

					try {
						this.addressAddEditModel.modifyAddress(
							undefined,
							addressData,
							() => {
								const payeeAddressId = undefined;
								const payeeAddress: any = {};
								payeeAddress.addresstype = 3;
								payeeAddress.code = res?.vendor;
								payeeAddress.parsetype = 1;
								payeeAddress.sortname = res?.vendorn;

								this.payeeAddressAddEditModel.modifyAddress(
									payeeAddressId,
									payeeAddress,
									() => {
										// Success
									}
								);
							}
						);
					} catch (error) {
						console.error(error);
					}

					if (this.props.handleSuccess) {
						this.props.handleSuccess(res);
					}
				} else {
					this.setState({
						errorMessage: getErrorMessage(res),
					});
				}
			},
			10000
		);
	};

	handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (name === 'vendorn') {
			this.setState({
				vendorn: value,
				vendor: nameToCode(value),
			});
		} else if (name === 'vendor') {
			this.setState({
				vendor: formatCode(value),
			});
		}
	};

	render() {
		const { isLoading, vendor, vendorn, errorMessage } = this.state;

		return (
			<Modal
				show={true}
				onHide={this.props.hideModal}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row xs={1} lg={1} className="align-items-start my-2 px-5">
						<Col>
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								Add New Vendor
							</h5>
							<Row className="align-items-center mb-3 pt-4">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor Name</Form.Label>
								</Col>
								<Col>
									<Form.Control
										type="text"
										placeholder="Vendor name"
										name="vendorn"
										onChange={this.handleInputChange}
										value={vendorn}
									/>
								</Col>
							</Row>
						</Col>
						<Col>
							<Row className="align-items-center mb-3">
								<Col lg={4} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Vendor Code</Form.Label>
								</Col>
								<Col>
									<Form.Control
										maxLength={5}
										type="text"
										placeholder="Vendor code"
										name="vendor"
										onChange={this.handleInputChange}
										value={vendor}
									/>
								</Col>
							</Row>
						</Col>
						{errorMessage && (
							<Col>
								<Row className="align-items-center">
									<Col>
										<AlertError
											variant="danger"
											className="mb-0 compact"
											onClose={() =>
												this.setState({
													errorMessage: '',
												})
											}
										>
											{errorMessage}
										</AlertError>
									</Col>
								</Row>
							</Col>
						)}
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
								disabled={isLoading || this.props.disableButtons}
							>
								OK
							</Button>
							<Button
								disabled={this.props.disableButtons}
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default VendorQuickAddModal;
