import React, { ReactNode } from 'react';

interface ILinkItem {
	linkItemClass?: string;
	url?: string;
	children: ReactNode;
	openInNewTab?: boolean;
}

const LinkItem = ({
	linkItemClass = '',
	url,
	children,
	openInNewTab = false,
}: ILinkItem) => {
	return (
		<a
			className={linkItemClass}
			href={url || '#'}
			target={url && openInNewTab ? '_blank' : '_self' || undefined}
			rel={'noreferrer'}
		>
			{children}
		</a>
	);
};

LinkItem.displayName = 'LinkItem';

export default LinkItem;
