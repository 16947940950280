import React from 'react';
import { Modal, Spinner, Table } from 'react-bootstrap';
import {
	searchGLTransactions,
	getDepositCheckbookDetail,
	getPaydistDepositCheckbookDetail,
} from '../api';
import dayjs from 'dayjs';
import { formatNumber } from '../utils';
import { useQuery } from '@tanstack/react-query';

interface ICheckbookDetailCheckProps {
	type: number;
	txnum: number;
}

const CheckbookDetailDeposit = ({
	type,
	txnum,
}: ICheckbookDetailCheckProps) => {
	const {
		data: generalLedgerTransactions = [],
		isLoading: isGeneralLedgerTransactionsLoading,
	} = useQuery({
		queryKey: ['generalLedgerTransactions', type, txnum],
		queryFn: () =>
			searchGLTransactions(`?$filter=type eq ${type} and txnum eq ${txnum}`),
	});

	const [generalLedgerTransaction] = generalLedgerTransactions;
	const isGetCheckCheckbookDetailEnabled = !!generalLedgerTransaction;

	const {
		data: depositCheckbookDetail = [],
		isLoading: isDepositCheckbookDetailLoading,
	} = useQuery({
		queryKey: [
			'checkbookDetailChecks',
			generalLedgerTransaction?.userdate,
			generalLedgerTransaction?.account,
			generalLedgerTransaction?.depositslip,
		],
		queryFn: () => {
			const date = dayjs(generalLedgerTransaction.userdate).format(
				'YYYY-MM-DD'
			);
			return getDepositCheckbookDetail(
				`?$filter=userdate eq ${date} and account eq '${generalLedgerTransaction.account}' and depositslip eq '${generalLedgerTransaction.depositslip}'`
			);
		},
		enabled: isGetCheckCheckbookDetailEnabled,
	});

	const {
		data: paydistDepositCheckbookDetail = [],
		isLoading: isPaydistDepositCheckbookDetailLoading,
	} = useQuery({
		queryKey: [
			'paydistDepositCheckbookDetail',
			generalLedgerTransaction?.crgroupnum,
		],
		queryFn: () =>
			getPaydistDepositCheckbookDetail(
				`?$filter=crgroupnum eq ${generalLedgerTransaction.crgroupnum}`
			),
		enabled: isGetCheckCheckbookDetailEnabled,
	});

	const isLoading =
		isGeneralLedgerTransactionsLoading ||
		(isGetCheckCheckbookDetailEnabled &&
			(isDepositCheckbookDetailLoading ||
				isPaydistDepositCheckbookDetailLoading));

	return (
		<>
			<Modal.Header>
				<Modal.Title as="h5">
					{generalLedgerTransaction
						? `Checkbook Deposit Detail [Check #${generalLedgerTransaction.checknum}]`
						: 'Checkbook Deposit Detail'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{isLoading ? (
					<div className="tw-flex tw-items-center tw-justify-center">
						<Spinner variant="success" />
					</div>
				) : (
					<>
						<div className="tw-overflow-x-auto">
							<Table striped bordered>
								<thead>
									<tr>
										<th>Account</th>
										<th>Client</th>
										<th>Fiscal Month</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{depositCheckbookDetail.map((x) => (
										<tr key={x.checknum}>
											<td>{x.account}</td>
											<td>{x.clientn}</td>
											<td>{x.fiscalmonth}</td>
											<td>{formatNumber(x.amount)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>

						<div className="tw-overflow-x-auto">
							<Table striped bordered>
								<thead>
									<tr>
										<th>TX #</th>
										<th>Type</th>
										<th>Description</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{paydistDepositCheckbookDetail.map((x) => (
										<tr key={x.txnum}>
											<td>{x.txnum}</td>
											<td>{x.type}</td>
											<td>{x.txdesc}</td>
											<td>{formatNumber(x.amount)}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</>
				)}
			</Modal.Body>
		</>
	);
};

CheckbookDetailDeposit.displayName = 'CheckbookDetailDeposit';

export { CheckbookDetailDeposit };
