import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';
import { IManagedAccounts } from '../types/ManagedAccounts';

const api = new ApiService();

const getManagedAccounts = async (): Promise<IManagedAccounts> => {
	const managedAccounts = (await api.get(
		'manageaccount'
	)) as Promise<IManagedAccounts>;

	return managedAccounts;
};

const useGetManagedAccounts = (
	options?: UseQueryOptions<
		IManagedAccounts,
		unknown,
		IManagedAccounts,
		string[]
	>
) => {
	const managedAccountsQuery = useQuery({
		queryKey: ['managedaccounts'],
		queryFn: getManagedAccounts,
		...(options || {}),
	});

	useEffect(() => {
		if (managedAccountsQuery.error) {
			displayAlertError(
				'An error occurred fetching the managed accounts info, please try again'
			);
		}
	}, [managedAccountsQuery.error]);

	return managedAccountsQuery;
};
export default useGetManagedAccounts;
