import React from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import DatePicker from 'react-datepicker';
import { ApiService } from '../../../lib/api/HttpService';
import { displayAlert } from '../../../utilities/Response';
import dayjs from 'dayjs';
import { _delay } from '../../../helpers/Util';
import { dateToPayload } from '../../../helpers/Date';
import Select from 'react-select';

class CreateDepositSlipListExistingModal extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			useDate: true,
			transactionNumber: 0,
			selectedAccount: [],
			slipNumber: dayjs().format('MMDDYY'),
		};

		this.api = new ApiService();
	}

	handleSelectAccount = async (e) => {
		this.setState({
			dataIsLoaded: false,
			selectedAccount: e.target.value,
		});
	};

	handleChange = (e, meta) => {
		let key, value;
		if (!meta) {
			key = e.target.getAttribute('name');
			value = e.target.value;
		} else if (
			meta &&
			meta.hasOwnProperty('name') &&
			e.hasOwnProperty('value')
		) {
			key = e.value;
			value = e.value;
		}
		this.setState((prevState) => ({
			...prevState,
			[key]: value,
		}));

		// If the slipNumber has changed, call the function passed via props
		if (key === 'slipNumber') {
			this.props.onSlipNumberChange(value);
		}
	};

	handleAction = async () => {
		const data = {
			slipNumber: this.props.slipNumber,
			slipNotNumberedUseDate: this.state.useDate,
			depositDate: dateToPayload(this.props.depositDate),
			cashAccountNumber: this.props.cashAccount,
			transactionNumber: this.state.transactionNumber,
		};
		try {
			await this.props.onPostDepositSlip(data);
			this.props.hideModal();
		} catch (error) {
			console.error('Error during report generation: ', error);
		}
	};

	render() {
		return (
			<Modal
				size="lg"
				show={this.props.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row>
						<Col lg={{ span: 10, offset: 1 }}>
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								Create Deposit Slip for Cash Receipt
							</h5>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Cash Account</Form.Label>
								</Col>
								<Col>
									<Form.Control
										size="sm"
										defaultValue={this.props.cashAccount}
										disabled={true}
									/>
								</Col>
								<Col></Col>
							</Row>
							<Row className="align-items-center mb-3">
								<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
									<Form.Label className="mb-0">Deposit Slip No.</Form.Label>
								</Col>
								<Col>
									<Form.Control
										name="slipNumber"
										type="text"
										placeholder="Please enter"
										onChange={this.handleChange}
										value={this.props.slipNumber}
									/>
								</Col>
								<Col></Col>
							</Row>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal.bind(this)}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(CreateDepositSlipListExistingModal);
