import React from 'react';
import { getClassNames } from '../../../utilities/DOM';

interface Props {
	children?: any;
	className?: any;
	variant?: any;
	buttons?: any;
	list?: boolean;
	actions?: boolean;
}

export class Header<P> extends React.Component<Props> {
	static Actions(props: Props): JSX.Element {
		return (
			<div className="a-header-cta">
				{props.list === undefined || props.list ? (
					<ul className="d-flex justify-content-end list-unstyled mb-0 p-0">
						{props.children}
					</ul>
				) : (
					<>{props.children}</>
				)}
			</div>
		);
	}

	static Title(props: Props): JSX.Element {
		return (
			<h2
				className={getClassNames(
					props,
					`text-${props.variant || 'white'} ${props.className || ''}`
				)}
			>
				{props.children}
			</h2>
		);
	}

	static TitleContent(props: Props): JSX.Element {
		return (
			<div
				className={getClassNames(
					props,
					`a-header-title d-flex justify-content-between align-items-end align-items-md-center mb-1 ${
						props.className || ''
					}`
				)}
			>
				{props.children}
			</div>
		);
	}

	static Breadcumbs(props: Props): JSX.Element {
		return (
			<div className="a-header-breadcrumb mb-4">
				<div className="d-flex align-items-center">{props.children}</div>
			</div>
		);
	}

	static LeftContent(props: Props): JSX.Element {
		return <div className="d-block">{props.children}</div>;
	}

	static Buttons(props: Props): JSX.Element {
		return (
			<ul className="a-header-buttons d-flex list-unstyled mb-0 p-0">
				{props.children}
			</ul>
		);
	}

	render(): JSX.Element {
		return (
			<div
				className={getClassNames(
					this.props,
					`a-header bg-${this.props.variant || 'secondary-green'} ${
						this.props.className || ''
					} gradient`
				)}
			>
				{!this.props.buttons
					? Header.TitleContent(this.props)
					: this.props.children}
			</div>
		);
	}
}

export class HeaderLight extends Header<Props> {
	static Title(props: Props) {
		return (
			<Header.Title
				className={`${props.className || ''}`}
				variant={props.variant || 'charcoal'}
			>
				{props.children}
			</Header.Title>
		);
	}

	static Actions(props: Props) {
		return (
			<ul
				className={`a-header-buttons d-flex list-unstyled mb-0 p-0 ${
					props.className || ''
				}`}
			>
				{props.children}
			</ul>
		);
	}

	static Content(props: Props) {
		return (
			<div
				className={`a-header-title d-flex justify-content-between align-items-center ${
					props.actions ? 'mb-4' : ''
				} ${props.className}`}
			>
				{props.children}
			</div>
		);
	}

	render() {
		return (
			<div
				className={`a-header bg-${
					this.props.variant || 'ivory'
				} gradient light`}
			>
				{this.props.children}
			</div>
		);
	}
}
