import React from 'react';
import { Row, Col, Modal, Button } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';

class DepositWarningModal extends ThreadComponent {
	render() {
		return (
			<Modal
				size="sm"
				show={this.props.show}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<div className="text-center mx-auto fw-bold">
						<p>
							Warning! Proposal #{this.props.propId} contains at least one item
							that has already been invoiced.
						</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Row className="w-100">
						<Col className="d-flex" sm={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={() => this.props.hideModal(true)}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={() => this.props.hideModal(false)}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(DepositWarningModal);
