/**
 * Set browser cookie
 * @param cookieName
 * @returns
 */
export const setCookie = (
	cookieName: string,
	cookieValue: string,
	{ expires = 0 }
) => {
	let expireString = '';

	if (expires > 0) {
		const d = new Date();
		d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
		expireString = ';expires=' + d.toUTCString();
	}

	document.cookie = `${cookieName}=${cookieValue}${expireString};path=/`;
};

/**
 * Get browser cookie
 * @param cookieName
 * @returns
 */
export const getCookie = (cookieName: string) => {
	const name = cookieName + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');

	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
};

/**
 * Delete browser cookie
 * @param cookieName
 */
export const deleteCookie = (cookieName: string) => {
	const expires = 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
	document.cookie = cookieName + '=;' + expires + ';path=/';
};
