import React from 'react';
import {
	Breadcrumb,
	Dropdown,
	Col,
	Container,
	Row,
	Table,
	Button,
	Form,
	Modal,
} from 'react-bootstrap';
import { generatePath, Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import URI from '../../../../defaults/RoutesDefault';
import Header from '../../../components/Header';
import ListFilter from '../../../components/ListFilter';
import ICON from '../../../../defaults/Icon';
import AccountBalancesModal from '../../modal/AccountBalancesModal';
import { ApiService } from '../../../../lib/api/HttpService';
import { Pagination } from '../../../../app/components/pagination/Pagination';
import debounce from 'lodash/debounce';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import { formatFiscalMonth } from '../../../../helpers/Date';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { isFiltered } from '../../../../helpers/Util';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import {
	displayAlert,
	displayAlertLoader,
	hideAlertLoader,
} from '../../../../utilities/Response';
import MSG from '../../../../defaults/Message';
import Select from 'react-select';
import FilterSort from '../../../../utilities/modules/FilterSort';
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import { renderSecureContent } from '../../../../utilities/modules/SecureContent';
import SecureDropdownItem from '../../../../app/components/security/SecureDropdownItem';

class AccountList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('gl_accounts_list');
		this.fs.setDefaultSort('account asc');
		this.state = {
			checks: [],
			data: [],
			allData: [],
			accountTypes: {
				1: 'Asset',
				101: 'Asset (Bank/Checking)',
				2: 'Liability',
				202: 'Liability (Credit Card)',
				3: 'Equity',
				4: 'Revenue',
				5: 'Cost of Goods',
				6: 'Operating Expense',
				7: 'Other Income/Expense',
			},
			showBalancesModal: false,
			dataIsLoaded: false,
			deleteResponses: [],
			pageSize: 20,
			page: 1,
			sortProperty: this.fs.getSort() ?? 'account asc',
			showConfirmDeleteModal: false,
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};

		this.api = new ApiService();
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	componentInit() {
		this.setTitle('Accounts');
	}

	async componentDidMount() {
		this.fetchData();
		this.fetchAllData();
		this.enableSortTable();
	}

	async fetchAllData(page) {
		const accData = await this.api.getGLAccounts();

		let acc = accData.map((a) => {
			a.typen = this.state.accountTypes[a.fixedType];
			return a;
		});

		this.setState({
			dataIsLoaded: true,
			allData: acc,
		});
	}

	async fetchData(page, isLoader) {
		if (isLoader) this.setState({ dataIsLoaded: false });
		const accData = await this.api.getGLAccounts(`?${this.buildFilters(page)}`);

		let acc = accData.map((a) => {
			a.typen = this.state.accountTypes[a.fixedType];
			return a;
		});

		this.setState({
			dataIsLoaded: true,
			data: acc,
		});
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});
		this.fs.setIsActiveSearch(newTableSearch);

		if (JSON.stringify(this.state.searchProperties) !== '{}') {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	showBalancesModal = (e) => {
		this.setState((prev) => (prev.showBalancesModal = true));
		e.preventDefault();
	};

	handleSelectAllChecks = (e) => {
		let checks = [];
		if (
			e.target.checked &&
			this.state.checks.length !== this.state.data.length
		) {
			checks = this.state.data.map((item) => {
				return item.account;
			});
		}

		this.setState({
			checks: checks,
		});
	};

	handleCheck = (id) => {
		const checks = this.state.checks;
		const index = checks.findIndex((_id) => _id === id);

		index > -1 ? checks.splice(index, 1) : checks.push(id);

		this.setState({
			checks: checks,
		});
	};

	// Refresh data
	refreshDataAfterDelete = (e) => {
		const headingCheck = document.getElementById('inline-check-th-0');
		headingCheck.checked = false;

		this.setState({
			checks: [],
			showModal: false,
			deleteResponses: [],
		});

		this.fetchData();
	};

	async handleTestDeleteObjects(deactivateOnly) {
		if (!this.state.checks.length) {
			return;
		}

		displayAlertLoader(MSG.loading.prepare.item);

		let workers = [];

		for (const id of this.state.checks) {
			const params = {
				deleteType: 'dmriTestAndFullDeleteIfPossible',
				objectType: 'objAccount',
				objectCodeOrId: id,
			};
			workers.push(await this.api.postJson('deleteobjects', params));
		}

		Promise.all(workers).then(async (responses) => {
			hideAlertLoader();

			const deleted = responses.filter((item) => item.fullDeletePossible);
			const failed = responses.filter((item) => !item.fullDeletePossible);

			if (failed.length > 0) {
				if (this.state.checks.length === 1) {
					this.setState({
						showTransferAccount: true,
					});
				} else {
					this.setState({
						showModal: true,
						deleteResult: {
							deletedCount: deleted.length,
							failed: failed,
						},
					});
				}
			} else {
				displayAlert('success', 'Successfully deleted account(s)');
			}

			this.fetchData();
		});
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	doTableSearch(data) {}

	buildFilters(currentPage) {
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		const filtersQuery =
			filters.length > 0 ? `&$filter=${filters.join(' and ')}` : '';

		let queryString = `$top=${this.state.pageSize + 1}&$skip=${
			((currentPage ?? this.state.page) - 1) * this.state.pageSize
		}${filtersQuery}`;

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchData(page, true);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleTransferAccountChange = (e) => {
		this.setState({
			newAccount: e.value,
		});
	};

	confirmAccountTransfer = async () => {
		displayAlertLoader('Transferring Account...');
		try {
			await this.api.transferGLAccount({
				oldAccount: this.state.checks[0],
				newAccount: this.state.newAccount,
			});

			await this.api.postJson('deleteobjects', {
				deleteType: 'dmriTestAndFullDeleteIfPossible',
				objectType: 'objAccount',
				objectCodeOrId: this.state.checks[0],
			});

			this.closeAccountTransfer();
			hideAlertLoader();
			displayAlert('success', 'Account activity transfer successful');

			this.fetchData();
		} catch (error) {
			hideAlertLoader();
			const errorMessage =
				error.response?.data?.userError ||
				'Unable to transfer account activity';
			displayAlert('danger', errorMessage);
		}
	};

	closeAccountTransfer = () => {
		let state = this.state;
		state.checks = [];
		delete state.newAccount;

		this.setState({
			...state,
			showTransferAccount: false,
		});
	};

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	renderHeader() {
		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{
										to: URI.generalLedger.account.base,
									}}
									linkAs={Link}
								>
									General Ledger
								</Breadcrumb.Item>
								<Breadcrumb.Item>Accounts</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Accounts</Header.Title>
					</Header.LeftContent>
					<Header.Actions list={true}>
						<li>
							<SecureBootstrapButton
								attributeNo={31}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAdd}
								as={Link}
								to={URI.generalLedger.account.add}
								variant="light"
								size="lg"
								className="btn-icon text-secondary-green"
							>
								<i className="ri-add-line ri-lg"></i> Add
							</SecureBootstrapButton>
						</li>
					</Header.Actions>
				</Header.TitleContent>
			</Header>
		);
	}

	renderFilters() {
		return (
			<>
				<ListFilter expandable={0}>
					<ListFilter.Actions
						md={12}
						lg={12}
						xl={12}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-lg-end"
					>
						<ListFilter.Action className="">
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
						<ListFilter.Action className="ms-auto ms-lg-3">
							<Dropdown className="d-flex justify-content-end" align="end">
								<Dropdown.Toggle
									id="dropdown-autoclose-true"
									variant="ivory"
									size="sm"
								>
									Actions
								</Dropdown.Toggle>

								<Dropdown.Menu>
									{/* <Dropdown.Item href='#' onClick={this.showBalancesModal}><i className={ICON.a.show}></i> Show Balances</Dropdown.Item> */}
									<SecureDropdownItem
										attributeNo={31}
										attributeType={SECURITY_ATTRIBUTE_TYPES.DenyDel}
										onClick={() => {
											this.setState({
												showConfirmDeleteModal: true,
											});
										}}
										href="#"
									>
										<i className={ICON.a.delete}></i> Delete
									</SecureDropdownItem>
								</Dropdown.Menu>
							</Dropdown>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		const { totalChecked, data } = this.state;
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding sticky-container">
					<Container fluid className="px-0">
						<Row>
							<Col sm="12">
								{renderSecureContent(
									<>
										{this.renderFilters()}
										<div className="table-gradient">
											<Table striped responsive className="a-table">
												<thead>
													<tr className="a-table-heading">
														<th align="middle" className="mw-70px">
															<div className="d-flex justify-content-center">
																<Form.Check
																	inline
																	label=""
																	name={`inline-check-th-0`}
																	type="checkbox"
																	data-id={`th-0`}
																	id={`inline-check-th-0`}
																	defaultChecked={totalChecked > 0}
																	className={getCheckAllClass(
																		totalChecked,
																		data
																	)}
																	onClick={this.handleSelectAllChecks}
																/>
															</div>
														</th>
														<th>
															<span
																className={this.sortClass('account')}
																data-field="account"
															>
																Account Number
															</span>
														</th>
														<th className="mw-200px">
															<span
																className={this.sortClass('accountn')}
																data-field="accountn"
															>
																Account Name
															</span>
														</th>
														<th className="">
															<span data-field="typen">Account Type</span>
														</th>
														<th>
															<span
																className={this.sortClass('dept')}
																data-field="dept"
															>
																Department Code
															</span>
														</th>
														<th>
															<span data-field="closeddate">Closed As Of</span>
														</th>
													</tr>
													<tr
														className={`a-table-search-fields ${
															this.state.showTableSearch ? '' : 'd-none'
														}`}
													>
														<th></th>
														<th>
															<Form.Control
																type="text"
																data-field="account"
																onChange={this.handleSearch}
																defaultValue={this.fs.getValue('account')}
															/>
														</th>
														<th>
															<Form.Control
																type="text"
																data-field="accountn"
																onChange={this.handleSearch}
																defaultValue={this.fs.getValue('accountn')}
															/>
														</th>
														<th></th>
														<th>
															<Form.Control
																type="text"
																data-field="dept"
																onChange={this.handleSearch}
																defaultValue={this.fs.getValue('dept')}
															/>
														</th>
														<th>
															<Form.Control
																type="text"
																data-field="closeddate"
																data-type="number"
																onChange={this.handleSearch}
																defaultValue={this.fs.getValue('closeddate')}
															/>
														</th>
													</tr>
												</thead>
												<tbody>
													{!this.state.dataIsLoaded
														? showLoading()
														: this.state.data.length === 0 &&
														  isFiltered(this.state.searchProperties)
														? showEmpty()
														: this.state.data
																.slice(0, this.state.pageSize)
																.map((item, i) => (
																	<tr
																		key={i}
																		data-key={i}
																		className={
																			this.state.checks.includes(item.account)
																				? `active`
																				: ''
																		}
																	>
																		<td>
																			<div className="d-flex justify-content-center">
																				<Form.Check
																					label=""
																					type="checkbox"
																					checked={this.state.checks.includes(
																						item.account
																					)}
																					onChange={() => {
																						this.handleCheck(item.account);
																					}}
																				/>
																			</div>
																		</td>
																		<td>
																			<Link
																				to={generatePath(
																					URI.generalLedger.account.edit,
																					{
																						id: item.account,
																					}
																				)}
																				className="text-charcoal hover-view-icon"
																			>
																				{item.account}
																			</Link>
																		</td>
																		<td>{item.accountn}</td>
																		<td>{item.typen}</td>
																		<td>{item.dept}</td>
																		<td>{formatFiscalMonth(item.closedfmo)}</td>
																	</tr>
																))}
												</tbody>
											</Table>
										</div>
										{this.state.data.length > 0 && (
											<Pagination
												onPageSizeChanged={this.onPageSizeChanged}
												onPageChanged={this.onPageChanged}
												hasPreviousPage={this.state.page > 1}
												hasNextPage={
													this.state.data.length > this.state.pageSize
												}
												page={this.state.page}
												pageSize={this.state.pageSize}
											/>
										)}
									</>
								)(31, SECURITY_ATTRIBUTE_TYPES.DenyAccess)}
							</Col>
						</Row>
					</Container>
				</div>

				{/* Bind  `hideRetainerModal` method to this component. */}
				<AccountBalancesModal
					show={this.state.showBalancesModal}
					hideModal={() =>
						this.setState((prev) => (prev.showBalancesModal = false))
					}
				/>

				<Modal
					show={this.state.showModal && this.state.deleteResult}
					aria-labelledby="action-modal"
					className="a-modal"
					backdrop="static"
					centered
				>
					<Modal.Body>
						<Row className="mb-3">
							<Col className="px-5">
								<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
									{`${this.state.deleteResult?.deletedCount} items were deleted. The following items
                                    failed to be deleted because:`}
								</h5>
								<div
									className="text-center mx-auto fw-bold pt-4"
									style={{
										maxHeight: '300px',
										overflowY: 'auto',
									}}
								>
									{this.state.deleteResult?.failed.map((item) => (
										<h6>{`${item.objectCodeOrId} - ${item.message}`}</h6>
									))}
								</div>
								<h6 className="text-center mx-auto fw-bold">
									If you wish to transfer the activity for these accounts,
									please delete each account individually
								</h6>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="bg-ivory py-4">
						<Row>
							<Col className="align-items-end">
								<Button
									variant="trans-light border-secondary-ash"
									onClick={() => {
										this.setState({
											deleteResult: undefined,
											showModal: false,
										});
									}}
								>
									OK
								</Button>
							</Col>
						</Row>
					</Modal.Footer>
				</Modal>

				<Modal
					show={this.state.showTransferAccount}
					aria-labelledby="action-modal"
					className="a-modal"
					backdrop="static"
					centered
				>
					<Modal.Body>
						<Row className="mb-3">
							<Col className="px-5">
								<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
									Account Glossary
								</h5>
								<div className="text-center mx-auto fw-bold pt-4">
									<h6>
										This Account either has activity or has been used somewhere
										in Design Manager. The account activity must be transferred
										to another account.
										<br />
										<br />
										If you wish to proceed with the account activity transfer
										select one of the accounts below and click 'OK' to confirm.
									</h6>
								</div>
								<Select
									options={this.state.allData
										.filter((item) => item.account !== this.state.checks[0])
										.map((item) => {
											return {
												value: item.account,
												label: `${item.account} - ${item.accountn}`,
											};
										})}
									className="react-select"
									placeholder="Please select"
									onChange={this.handleTransferAccountChange}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="bg-ivory py-3 px-5">
						<Row className="w-100">
							<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
								<Button
									variant="primary"
									onClick={this.confirmAccountTransfer}
									className="w-100 m-2"
								>
									OK
								</Button>

								<Button
									variant="trans-light border-secondary-ash"
									onClick={this.closeAccountTransfer}
									className="w-100 m-2"
								>
									Close
								</Button>
							</Col>
						</Row>
					</Modal.Footer>
				</Modal>
				<Modal
					show={this.state.showConfirmDeleteModal}
					aria-labelledby="action-modal"
					className="a-modal"
					backdrop="static"
					centered
				>
					<Modal.Body>
						<Row className="mb-3">
							<Col className="px-5">
								<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
									Delete Confirm
								</h5>
								<div className="text-center mx-auto fw-bold pt-4">
									<h6>
										Are you sure you want to permanently delete the account(s)?
									</h6>
								</div>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer className="bg-ivory py-3 px-5">
						<Row className="w-100">
							<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
								<Button
									variant="danger"
									onClick={() => {
										this.handleTestDeleteObjects();
										this.setState({
											showConfirmDeleteModal: false,
										});
									}}
									className="w-100 m-2"
								>
									OK
								</Button>

								<Button
									variant="trans-light border-secondary-ash"
									onClick={() => {
										this.setState({
											showConfirmDeleteModal: false,
										});
									}}
									className="w-100 m-2"
								>
									Cancel
								</Button>
							</Col>
						</Row>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}

export default WithRouter(AccountList);
