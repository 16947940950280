import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import type { IVendorInvoice } from 'api/resources/vendorInvoices';
import { getVendorInvoices } from 'api/resources/vendorInvoices';

import { ECacheKeys } from 'cache/CacheKeys';

export const useGetVendorInvoices = (
	filter?: string,
	options?: UseQueryOptions<IVendorInvoice[]>
) => {
	const query = useQuery<IVendorInvoice[]>({
		queryKey: [ECacheKeys.VendorInvoices, filter],
		queryFn: () => getVendorInvoices(filter),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
