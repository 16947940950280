import Label from 'legacy/app/components/label/Label';
import { TWhatChangedComponent } from 'legacy/lib/api/types/WhatChangedComponent';
import React from 'react';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

const BTermsInputs = ({
	disableInputs,
	handleRecalculate,
}: {
	disableInputs: boolean;
	handleRecalculate: (whatPropertyChanged: `${TWhatChangedComponent}`) => void;
}) => {
	const methods = useFormContext();
	return (
		<>
			<Label label="Terms" />
			<div className="tw-flex tw-gap-4">
				<Controller
					name="bterm1"
					render={({ field: { value, onChange } }) => (
						<FormControl
							onChange={onChange}
							value={value}
							maxLength={15}
							disabled={disableInputs}
							onBlur={(e) => {
								const isNotAValidNumber = isNaN(Number(e.target.value));
								if (
									!e.target.value ||
									isNotAValidNumber ||
									e.target.value === '-0.'
								) {
									methods.setValue('bterm1', '0');
								} else {
									methods.setValue('bterm1', String(Number(e.target.value)));
								}
								handleRecalculate(TWhatChangedComponent.cwcBuyingTerms);
							}}
						/>
					)}
				/>
				<Controller
					name="bterm2"
					render={({ field: { value, onChange } }) => (
						<FormControl
							onChange={onChange}
							value={value}
							maxLength={15}
							disabled={disableInputs}
							onBlur={(e) => {
								const isNotAValidNumber = isNaN(Number(e.target.value));
								if (
									!e.target.value ||
									isNotAValidNumber ||
									e.target.value === '-0.'
								) {
									methods.setValue('bterm2', '0');
								} else {
									methods.setValue('bterm2', String(Number(e.target.value)));
								}
								handleRecalculate(TWhatChangedComponent.cwcBuyingTerms);
							}}
						/>
					)}
				/>
				<Controller
					name="bterm3"
					render={({ field: { value, onChange } }) => (
						<FormControl
							onChange={onChange}
							value={value}
							disabled={disableInputs}
							maxLength={15}
							onBlur={(e) => {
								const isNotAValidNumber = isNaN(Number(e.target.value));
								if (
									!e.target.value ||
									isNotAValidNumber ||
									e.target.value === '-0.'
								) {
									methods.setValue('bterm3', '0');
								} else {
									methods.setValue('bterm3', String(Number(e.target.value)));
								}
								handleRecalculate(TWhatChangedComponent.cwcBuyingTerms);
							}}
						/>
					)}
				/>
			</div>
		</>
	);
};
BTermsInputs.displayName = 'BTermsInput';
export default BTermsInputs;
