import React from 'react';
import { Breadcrumb, Dropdown, Form, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { getActiveMenuUri, WithRouter } from '../../../../helpers/Router';
import { fakeProjectData } from '../../../factory/fakeFactory';
import URI from '../../../../defaults/RoutesDefault';
import Header from '../../../components/Header';
import ListFilter from '../../../components/ListFilter';
import { compareStr } from '../../../../helpers/String';
import CashReceiptListNew from './CashReceiptListNew';
import CashReceiptListExisting from './CashReceiptListExisting';
import { getSubComponent } from '../../../../helpers/Util';
import CashReceiptListBankDeposit from './CashReceiptListBankDeposit';
import { renderSecureContent } from '../../../../utilities/modules/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton';

class CashReceiptList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			checks: {},
			data: fakeProjectData(10),
			showRetainerModal: false,
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['new', 'existing', 'bank-deposit'],
				'new'
			),
		};

		// This binding is necessary to make `this` work in the callback
		this.handleRowClick = this.handleRowClick.bind(this);
	}

	componentInit() {
		this.title = 'Projects';
		this.hasActionBar = true;
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleRowClick = (e) => {
		// Get the target menu.
		const indx = e.target.parentNode.getAttribute('data-key');

		document.getElementById(`item-check-${indx}`).checked =
			!document.getElementById(`item-check-${indx}`).checked;

		this.setState((prevState) => {
			prevState.checks[indx] = document.getElementById(
				`item-check-${indx}`
			).checked;

			return prevState;
		});
	};

	handleToggle = (e) => {
		e.preventDefault();
		// Get the target menu.
		const id = e.target.getAttribute('data-state');

		// Set the new state.
		this.setState((prevState) => {
			let data = {};
			data[id] = !prevState[id];

			return data;
		});
	};

	hideRetainerModal = () => {
		// Set the new state.
		this.setState((prevState) => (prevState.showRetainerModal = false));
	};

	renderHeader() {
		const { activeMenu } = this.state;

		return (
			<Header buttons={1}>
				<Header.TitleContent>
					<Header.LeftContent>
						<Header.Breadcumbs>
							<Breadcrumb className="breadcrumb-light">
								<Breadcrumb.Item
									linkProps={{
										to: URI.accountsReceivable.base,
									}}
									linkAs={Link}
								>
									Accounts Receivable
								</Breadcrumb.Item>
								<Breadcrumb.Item>Cash Receipts</Breadcrumb.Item>
							</Breadcrumb>
						</Header.Breadcumbs>
						<Header.Title>Cash Receipts</Header.Title>
					</Header.LeftContent>
					<Header.Actions list={true}>
						<li
							className={
								compareStr(this.props.page, 'new', 'active', '')
									? 'd-block'
									: 'd-none'
							}
						>
							<SecureBootstrapButton
								as={Link}
								attributeNo={53}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
								to={URI.accountsReceivable.cashReceipt.addMisc}
								variant="transparent"
								size="lg"
								className="btn-icon text-white border-white"
							>
								<i className="ri-add-line ri-lg"></i> Add Misc
							</SecureBootstrapButton>
						</li>
						<li
							className={
								compareStr(this.props.page, 'new', 'active', '')
									? 'd-block'
									: 'd-none'
							}
						>
							<SecureBootstrapButton
								attributeNo={53}
								attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
								as={Link}
								to={URI.accountsReceivable.cashReceipt.add}
								variant="light"
								size="lg"
								className="btn-icon text-secondary-green"
							>
								<i className="ri-add-line ri-lg"></i> Add
							</SecureBootstrapButton>
						</li>
					</Header.Actions>
				</Header.TitleContent>
				<Header.Buttons>
					<li>
						<NavLink
							to={URI.accountsReceivable.cashReceipt.listNew}
							data-menu="new"
							className={`btn text-white fw-bold ${compareStr(
								this.props.page,
								'new',
								'active',
								''
							)}`}
						>
							New
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.accountsReceivable.cashReceipt.listExisting}
							data-menu="existing"
							className={`btn text-white fw-bold ${compareStr(
								this.props.page,
								'existing',
								'active',
								''
							)}`}
						>
							Existing
						</NavLink>
					</li>
					<li>
						<NavLink
							to={URI.accountsReceivable.cashReceipt.listBankDeposit}
							data-menu="bank-deposit"
							className={`btn text-white fw-bold ${compareStr(
								this.props.page,
								'bank-deposit',
								'active',
								''
							)}`}
						>
							Make Bank Deposit
						</NavLink>
					</li>
				</Header.Buttons>
			</Header>
		);
	}

	renderFilters() {
		return (
			<ListFilter>
				<ListFilter.Fields md={10} lg={10} xl={10}>
					<ListFilter.Field>
						<Form.Label className="text-end">
							<strong>
								Fiscal
								<br />
								Month
							</strong>
						</Form.Label>
						<Form.Select size="sm">
							<option>07/2022</option>
						</Form.Select>
					</ListFilter.Field>
					<ListFilter.Field>
						<Form.Label className="text-end">
							<strong>
								Total
								<br />
								Receipts
							</strong>
						</Form.Label>
						<div className="form-group-extra">
							<Form.Control type="text" placeholder="580.00" size="sm" />
							<span className="text-secondary-ash">USD</span>
						</div>
					</ListFilter.Field>
				</ListFilter.Fields>
				<ListFilter.Actions md={2} lg={2} xl={2}>
					<ListFilter.Action className="ms-auto">
						<Dropdown
							className="d-flex justify-content-end ms-auto"
							align="end"
						>
							<Dropdown.Toggle
								id="dropdown-autoclose-true"
								variant="ivory"
								size="sm"
							>
								Actions
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item href="">
									<i className="ri-eye-fill"></i> View
								</Dropdown.Item>
								<Dropdown.Item href="">
									<i className="ri-door-closed-fill"></i> Close Projects
								</Dropdown.Item>
								<Dropdown.Item href="">
									<i className="ri-close-line"></i> Delete
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</ListFilter.Action>
				</ListFilter.Actions>
			</ListFilter>
		);
	}

	renderContent() {
		return getSubComponent(this.props.page, {
			new: renderSecureContent(<CashReceiptListNew />)(
				53,
				SECURITY_ATTRIBUTE_TYPES.DenyAccess
			),
			existing: renderSecureContent(<CashReceiptListExisting />)(
				54,
				SECURITY_ATTRIBUTE_TYPES.DenyAccess
			),
			'bank-deposit': renderSecureContent(<CashReceiptListBankDeposit />)(
				227,
				SECURITY_ATTRIBUTE_TYPES.DenyAccess
			),
		});
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="content-padding has-action-bar">
					{this.renderContent()}
				</div>
			</>
		);
	}
}

export default WithRouter(CashReceiptList);
