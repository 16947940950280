import React from 'react';
import { Row, Col, Modal, Button, Form } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import dayjs from 'dayjs';
import { ApiService } from '../../../lib/api/HttpService';
import {
	displayAlert,
	displayAlertLoader,
	getErrorMessage,
} from '../../../utilities/Response';
import MSG from '../../../defaults/Message';

class PaymentActionCheckWirePaymentModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			isWireTransfer: false,
		};

		this.updatedData = { isWireTransfer: false };

		this.api = new ApiService();
		this.errIds = [];
	}

	async componentDidMount() {
		const company = await this.api.getCompany();
		const company2 = await this.api.getCompany2();

		let fiscalMonth = new Date();
		if (company2.autodeffmo) {
			fiscalMonth = new Date(
				fiscalMonth.setMonth(fiscalMonth.getMonth() + company.fiscaloffset)
			);
		} else {
			fiscalMonth = new Date(company2.closedapfmo);
			fiscalMonth.setMonth(fiscalMonth.getMonth() + 1);
		}

		this.updatedData = {
			...this.updatedData,
			fiscalMonth: dayjs(fiscalMonth).format('MM/YYYY'),
		};

		this.setState({
			fiscalMonth: fiscalMonth,
		});
	}

	componentWillUnmount() {
		this.setState({
			fiscalMonth: new Date(),
		});
	}

	componentDidUpdate(previousProps, previousState) {
		if (previousProps.checkedData !== this.props.checkedData) {
			this.updatedData = { isWireTransfer: false };
			this.errIds = [];

			this.setState({
				isWireTransfer: false,
				checkDate: null,
			});
		}
	}

	handleChange = (e, meta = {}) => {
		let key, value;
		if (e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.name;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		this.updatedData[key] = value;
	};

	handleDateChange = (name, date) => {
		this.setState({
			[name]: date,
		});

		const format = name === 'fiscalMonth' ? 'MM/YYYY' : 'YYYY/MM/DD';
		this.updatedData[name] = dayjs(date).format(format);
	};

	handleRadioChange = (e) => {
		this.updatedData.isWireTransfer = e.target.value === '1' ? true : false;
		this.setState(
			(prevState) =>
				(prevState.isWireTransfer = e.target.value === '1' ? true : false)
		);
	};

	async updateTransactionTag(txnum) {
		const data = {
			txnum: parseInt(txnum),
			tag: true,
		};

		await this.api.editVendorPayments(data).catch((error) => {
			this.errIds.push(txnum);
		});
	}

	handleAction = async (e) => {
		displayAlertLoader(MSG.loading.update.msg);

		for (let txnum of this.props.checkedData) {
			await this.updateTransactionTag(txnum);
		}

		if (this.errIds.length) {
			displayAlert(
				'danger',
				'The data with the ids [' + this.errIds.join() + "] couldn't be updated"
			);
		}

		await this.api
			.postVendorPaymentsCheckWire(this.updatedData)
			.then((resp) => {
				displayAlert('success', MSG.success.update.APPayment);
				this.props.hideModal(true, true);
				this.props.resetData();
			})
			.catch((err) => {
				displayAlert(
					'danger',
					getErrorMessage(err, MSG.error.update.APPayment)
				);
			});
	};

	render() {
		return (
			<Modal
				size="lg"
				show={true}
				aria-labelledby="action-modal"
				className="a-modal"
				backdrop="static"
				centered
			>
				<Modal.Body>
					<Row className="mb-3">
						<Col className="px-5">
							<h5 className="text-center mx-auto fw-bold pt-1 border-bottom pb-3">
								Check/Wire Payment
							</h5>
							<Form.Group>
								<Row className="pt-3">
									<Row className="align-items-center mb-3">
										<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0">Select Type</Form.Label>
										</Col>
										<Col>
											<Form.Check
												inline
												label="Hand Check"
												name="check-group-type"
												type="radio"
												id={`check-group-type-1`}
												className="me-3"
												defaultChecked={true}
												onClick={this.handleRadioChange}
												value={0}
											/>
											<Form.Check
												inline
												label="Wire Transfer"
												name="check-group-type"
												type="radio"
												id={`check-group-type-2`}
												className="me-3"
												onClick={this.handleRadioChange}
												value={1}
											/>
										</Col>
									</Row>
								</Row>
								{!this.state.isWireTransfer && (
									<Row className="">
										<Col>
											<Row className="align-items-center mb-3">
												<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
													<Form.Label className="mb-0">Check Number</Form.Label>
												</Col>
												<Col>
													<Row lg={2}>
														<Col>
															<Form.Control
																type="text"
																name="checkNumber"
																placeholder="Please enter"
																onChange={this.handleChange}
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
								)}
								<Row className="">
									<Col>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">
													{this.state.isWireTransfer
														? 'Transfer Date'
														: 'Check Date'}
												</Form.Label>
											</Col>
											<Col>
												<Row lg={2}>
													<Col>
														<DatePicker
															selected={this.state.checkDate}
															name="checkDate"
															showMonthDropdown="true"
															showYearDropdown="true"
															className="form-control"
															placeholderText="Select"
															onChange={this.handleDateChange.bind(
																this,
																'checkDate'
															)}
														/>
													</Col>
												</Row>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Fiscal Month</Form.Label>
											</Col>
											<Col>
												<Row lg={2}>
													<Col>
														<DatePicker
															selected={this.state.fiscalMonth}
															name="fiscalMonth"
															className="form-control"
															dateFormat="MM/yyyy"
															placeholderText="Select"
															showMonthYearPicker
															onChange={this.handleDateChange.bind(
																this,
																'fiscalMonth'
															)}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col>
										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">
													Checking Account
												</Form.Label>
											</Col>
											<Col>
												<Row lg={2}>
													<Col>
														<Select
															name="checkingAccount"
															options={this.props.glAccounts}
															className="react-select"
															placeholder="Please select"
															onChange={this.handleChange}
														/>
													</Col>
												</Row>
											</Col>
										</Row>

										<Row className="align-items-center mb-3">
											<Col lg={3} className="text-lg-end mb-2 mb-lg-0">
												<Form.Label className="mb-0">Trans. Desc</Form.Label>
											</Col>
											<Col>
												<Row lg={2}>
													<Col>
														<Form.Control
															name="transactionDesription"
															type="text"
															placeholder="Please enter"
															onChange={this.handleChange}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-3 px-5">
					<Row className="w-100">
						<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.handleAction}
								className="w-100 m-2"
							>
								OK
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={() => this.props.hideModal(false)}
								className="w-100 m-2"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(PaymentActionCheckWirePaymentModal);
