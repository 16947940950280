import { Form, Row } from 'react-bootstrap';
import cn from 'classnames';

const FormGroup = ({ controlId, align = 'center', children }) => (
	<Form.Group
		as={Row}
		className={cn(
			{
				'align-items-center': align === 'center',
				'align-items-start': align === 'start',
			},
			'mb-3'
		)}
		controlId={controlId}
	>
		{children}
	</Form.Group>
);

export default FormGroup;
