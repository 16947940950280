import { useQuery } from '@tanstack/react-query';
import { TBusinessSummaryResponse } from '../types/BusinessSummary';
import { ApiService } from '../HttpService';

const api = new ApiService();

const useBusinessSummary = () => {
	return useQuery<TBusinessSummaryResponse, Error>(
		['businessSummary'],
		() => api.getBusinessSummary(),
		{
			refetchOnWindowFocus: false,
		}
	);
};

export default useBusinessSummary;
