import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	Breadcrumb,
	Container,
	Button,
	Row,
	Col,
	Table,
	Form,
} from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import { routeParam, WithRouter } from '../../../../helpers/Router';
import { fakeData } from '../../../factory/fakeFactory';
import { HeaderLight } from '../../../../templates/components/Header';
import ListFilter from '../../../components/ListFilter';
import ComboBox from '../../../components/ComboBox';
import { DatePicker, DateRangePicker } from 'rsuite';
import { delay, kebabCase } from 'lodash';
import { ApiService } from '../../../../lib/api/HttpService';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { currencyFormat } from '../../../../helpers/Number';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import URI from '../../../../defaults/RoutesDefault';
import FilterSort from '../../../../utilities/modules/FilterSort';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import { renderSecureContent } from '../../../../utilities/modules/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';

var timeout;

class TransactionSearchList extends ThreadComponent {
	constructor(props) {
		super(props);

		this.api = new ApiService();

		this.fs = new FilterSort('transaction_search_list');
		const filterDates = {
			entry: this.fs.getSearch('entry'),
			keyedate: this.fs.getSearch('keyedate'),
		};
		this.state = {
			checks: {},
			totalChecked: 0,
			startDate: '',
			minDate: new Date(),
			isDisabled: true,
			data: fakeData(4),
			dataIsLoaded: false,
			additionalFilters: this.fs.getSearch('selected_filters') ?? [],
			fieldValues: this.fs.getSearches(),
			filters: {
				'transaction-number': {
					type: 'number',
					label: 'Trans No.',
					id: 'transaction-number',
					options: [
						{
							label: 'Trans 1',
							value: '1',
						},
						{
							label: 'Trans 2',
							value: '2',
						},
					],
				},
				'proposal-number': {
					type: 'text',
					label: 'Proposal Number',
					id: 'proposal-number',
					options: [
						{
							label: 'Proposal 1',
							value: '1',
						},
						{
							label: 'Proposal 2',
							value: '2',
						},
					],
				},
				'transaction-amount': {
					type: 'number-range',
					label: 'Transaction Amount',
					id: 'transaction-amount',
				},
				'purchase-order-number': {
					type: 'number',
					label: 'Purchase Order Number',
					id: 'purchase-order-number',
					options: [
						{
							label: 'Purchase Order Number 1',
							value: '1',
						},
						{
							label: 'Purchase Order Number 2',
							value: '2',
						},
					],
				},
				'check-number': {
					type: 'text',
					label: 'Check Number',
					id: 'check-number',
				},
				'fiscal-month': {
					type: 'date-range',
					label: 'Fiscal Month',
					id: 'fiscal-month',
					format: 'MM/yyyy',
				},
				'vendor-invoice-number': {
					type: 'text',
					label: 'Vendor Invoice Number',
					id: 'vendor-invoice-number',
				},
			},
			filterOptions: [],
			dateValues: {
				entry: filterDates.entry
					? [new Date(filterDates.entry[0]), new Date(filterDates.entry[1])]
					: undefined,
				keyedate: filterDates.keyedate
					? [
							new Date(filterDates.keyedate[0]),
							new Date(filterDates.keyedate[1]),
					  ]
					: undefined,
			},
			isLoaded: false,
			clear: false,
			page: 1,
			pageSize: { value: 10, label: '10' },
			propertyFilter: this.fs.getSearch('active_filters') ?? {},
			sortProperty: this.fs.getSort() ?? 'txnum asc',
		};

		this.accountsTypes = [
			{
				key: '(Type ge 1000 and Type lt 1400)',
				value: 'All purchases',
			},
			{
				key: '(Type eq 1000 or Type eq 1041 or Type eq 1061)',
				value: 'Vendor Deposits',
			},
			{
				key: '(Type eq 1010 or Type eq 1043)',
				value: 'Operating Expenses',
			},
			{
				key: "((Type ge 1020 and Type le 1030) or Type eq 1045 or (Type eq 1061 and Ponum gt '0000'))",
				value: 'Project / Order PO Related',
			},
			{
				key: '((Type ge 1032 and Type le 1034) or Type eq 1047 or (Type eq 1061 and Inponum ne 0))',
				value: 'Inventory PO Related',
			},
			{
				key: '(Type ge 1400 and Type lt 2000)',
				value: 'Disbursements (Checks)',
			},
			{
				key: '(Type ge 2000 and Type lt 3000 and Invadj eq false)',
				value: 'All Cash Receipts',
			},
			{
				key: '(Type eq 2000 or Type eq 2011)',
				value: 'Misc.',
			},
			{
				key: '(Type eq 2002 or Type eq 2013)',
				value: 'Retainers / Deposits on Orders',
			},
			{
				key: '(Type eq 2004 or Type eq 2015)',
				value: 'Deposits on Proposals',
			},
			{
				key: '((Type eq 2006 or Type eq 2017) and Invadj eq false)',
				value: 'Payments',
			},
			{
				key: '((Type ge 3000 and Type lt 8000) or (Type ge 2000 and Type lt 3000 and Invadj eq true))',
				value: 'Client Invoices / Invoice Adjustments',
			},
			{
				key: 'Type ge 8000',
				value: 'Journal Entries',
			},
		];
		this.filterOptions = [
			{
				key: 'transaction-number',
				value: 'Trans No.',
			},
			{
				key: 'proposal-number',
				value: 'Proposal Number',
			},
			{
				key: 'transaction-amount',
				value: 'Transaction Amount',
			},
			{
				key: 'purchase-order-number',
				value: 'Purchase Order Number',
			},
			{
				key: 'check-number',
				value: 'Check Number',
			},
			{
				key: 'fiscal-month',
				value: 'Fiscal Month',
			},
			{
				key: 'vendor-invoice-number',
				value: 'Vendor Invoice Number',
			},
		];
		this.availablePageSize = [
			{ value: '10', label: '10' },
			{ value: '20', label: '20' },
			{ value: '50', label: '50' },
			{ value: '75', label: '75' },
			{ value: '100', label: '100' },
		];
		this.filterFields = {
			client: 'Client',
			vendor: 'Supplier',
			project: 'Proj',
			account: 'Account',
			entry: 'Userdate',
			keyedate: 'Computerdate',
			type: 'Type',
			'fiscal-month': 'fiscalmonth',
			'check-number': 'Checknum',
			'purchase-order-number': 'Ponum|Inponum',
			'vendor-invoice-number': 'Sinvnum',
			'transaction-amount': 'Amount',
			'transaction-number': 'Txnum',
			'proposal-number': 'Propnum',
		};
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
	}

	async componentDidMount() {
		this.loadData();
		this.enableSortTable();
	}

	async loadData() {
		await this.fetchData();
	}

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						self.setState(
							{
								sortProperty: `${_e.attributes['data-field'].value} ${
									hasClass('desc', _e) ? 'asc' : 'desc'
								}`,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	fetchData = async (page) => {
		let query = '';
		let filters = [];
		let halt = false;
		_.forEach(this.state.propertyFilter, (filter, k) => {
			if (typeof filter == 'object') {
				let range = '';
				if (filter.from !== undefined || filter.to !== undefined) {
					range = filter.from ? filter.from : '';
					range =
						range.length && filter.to
							? `${range} and ${filter.to}`
							: filter.to ?? range;

					range.length && filters.push(`(${range})`);
				}
			} else {
				filters.push(filter);
			}
		});

		if (halt) {
			return;
		}

		if (filters.length > 0) {
			query += `$filter=${filters.join(' and ')}&`;
		}

		query += `$top=${this.state.pageSize.value + 1}&$skip=${
			((page ?? this.state.page) - 1) * this.state.pageSize.value
		}`;

		if (this.state.sortProperty !== '') {
			query += `&$orderby=${this.state.sortProperty}`;
		}

		const data = await this.api.searchGLTransactions(`?${query}`);
		this.setState({
			data: data,
			isLoaded: true,
		});
	};

	buildFilters(type) {
		switch (type) {
			case 'client':
			case 'vendor':
				return `$filter=inactive eq false `;
			case 'project':
				return `$filter=closeddt eq null `;
			case 'account':
				return ``;
		}
	}

	handleChangePage = async (page) => {
		this.fetchData(page);
		this.setState({
			page: page,
		});
	};

	handleFilter = (name) => (e) => {
		this.setState(
			{
				[name]: e,
				page: 1,
			},
			this.changePageHandler
		);
	};

	reloadDataAndSetFilter() {
		this.fs.setSearch('active_filters', this.state.propertyFilter);
		clearTimeout(timeout);
		timeout = delay(() => {
			this.loadData();
		}, 200);
	}

	handleSelect = (selected, type) => {
		let filters = this.state.propertyFilter;
		const field = this.filterFields[type];
		if (selected == '') {
			delete filters[type];
		} else {
			if (selected.length > 1) {
				filters[type] = `(${field} eq '${selected.join(
					`' or ${field} eq '`
				)}')`;
			} else {
				filters[type] = `${field} eq '${selected.join(`' or ${field} eq '`)}'`;
			}
		}

		this.setState(
			{
				propertyFilter: filters,
				clear: false,
				isLoaded: false,
			},
			() => {
				this.fs.setSearch(type, selected);
				this.reloadDataAndSetFilter();
			}
		);
	};

	handleDateSelect = (dates, type) => {
		let filters = this.state.propertyFilter;
		let dateValues = this.state.dateValues;
		const field = this.filterFields[type];
		if (dates == '' || dates == null) {
			delete filters[type];
			delete dateValues[type];
		} else {
			if (dates.length == undefined) {
				const dayStart = dayjs(dates).startOf('month').format('YYYY-MM-DD');
				const dayEnd = dayjs(dates).endOf('month').format('YYYY-MM-DD');
				dates = [dayStart, dayEnd];
			}

			const date1 =
				field === 'fiscalmonth'
					? dayjs(dates[0]).startOf('month').format('YYYY-MM-DD')
					: dayjs(dates[0]).format('YYYY-MM-DD');
			const date2 =
				field === 'fiscalmonth'
					? dayjs(dates[1]).endOf('month').format('YYYY-MM-DD')
					: dayjs(dates[1]).format('YYYY-MM-DD');

			filters[
				type
			] = `(${field} ge ${date1} and ${this.filterFields[type]} le ${date2})`;

			dateValues[type] = dates;
		}

		this.setState(
			{
				propertyFilter: filters,
				dateValues: dateValues,
				isLoaded: false,
			},
			() => {
				this.fs.setSearch(type, dates);
				this.reloadDataAndSetFilter();
			}
		);
	};

	handleSelectType = (selected) => {
		let filters = this.state.propertyFilter;
		const selectedQrys = this.accountsTypes
			.filter((t) => {
				return selected.indexOf(kebabCase(t.key)) > -1;
			})
			?.map((t) => t.key);
		if (!selectedQrys?.length) {
			delete filters['type'];
		} else {
			filters['type'] = `${selectedQrys.join(' or ')}`;
		}

		this.setState(
			{
				propertyFilter: filters,
				isLoaded: false,
			},
			() => {
				this.fs.setSearch('type', selected);
				this.reloadDataAndSetFilter();
			}
		);
	};

	handleChangeField = (value, type, isNumber) => {
		let filters = this.state.propertyFilter;
		const field = this.filterFields[type];
		if (value == '') {
			delete filters[field];
		} else {
			if (field.split('|').length > 1) {
				const fd = field.split('|');
				filters[field] = `(${fd
					.map(
						(_fd) =>
							`${_fd} eq ${isNumber && _fd !== 'Ponum' ? value : `'${value}'`}`
					)
					.join(' or ')})`;
			} else {
				if (isNumber !== undefined && isNumber == true) {
					filters[field] = `${field} eq ${value}`;
				} else {
					filters[field] = `${field} eq '${value}'`;
				}
			}
		}

		this.setState(
			{
				propertyFilter: filters,
				isLoaded: false,
			},
			() => {
				this.fs.setSearch(type, value);
				this.reloadDataAndSetFilter();
			}
		);
	};

	handleNumRangeChangeField = (value, type, pos) => {
		let filters = this.state.propertyFilter;
		const field = this.filterFields[type];
		let ranges = typeof filters[field] === 'object' ? filters[field] : {};
		let searchRange = this.fs.getSearch(type) ?? {};

		if (value == '') {
			delete ranges[pos];
			delete searchRange[pos];
		} else {
			searchRange[pos] = value;
		}

		if (searchRange['from'] && !searchRange['to']) {
			ranges = `((${field} ge ${searchRange['from']} and ${field} le ${
				searchRange['from']
			}) or (${field} ge ${-searchRange['from']} and ${field} le ${-searchRange[
				'from'
			]}))`;
		} else if (searchRange['from'] && searchRange['to']) {
			ranges = `((${field} ge ${searchRange['from']} and ${field} le ${
				searchRange['to']
			}) or (${field} ge ${-searchRange['to']} and ${field} le ${-searchRange[
				'from'
			]}))`;
		}

		filters[field] = ranges;

		this.setState(
			{
				propertyFilter: filters,
				isLoaded: false,
			},
			() => {
				this.fs.setSearch(type, searchRange);
				this.reloadDataAndSetFilter();
			}
		);
	};

	handleSelectMoreFilters = (selected) => {
		const activeFilters = this.state.additionalFilters;

		const [removeFilter] =
			activeFilters?.filter((f) => selected.indexOf(f) < 0) ?? [];

		this.setState({
			additionalFilters: selected,
		});

		this.reloadDataFilterRemove(removeFilter, selected);
		this.fs.setSearch('selected_filters', selected);
		this.fs.setSearch(removeFilter, null);
	};

	handleDetach = (id) => {
		const newId = id.replace('-dropdown', '');
		let selected = this.state.additionalFilters.filter(
			(item) => item !== newId
		);

		// let filters = this.state.propertyFilter
		// const field = this.filterFields[id]
		// delete filters[field]
		// this.setState({
		//     additionalFilters: selected,
		//     propertyFilter: filters,
		//     isLoaded: false,
		// })

		this.reloadDataFilterRemove(id, selected);
		this.fs.setSearch('selected_filters', selected);
		this.fs.setSearch(newId, null);
	};

	reloadDataFilterRemove(filterId, selected) {
		const field = this.filterFields[filterId];
		let filters = this.state.propertyFilter;
		const dfilter = this.state.propertyFilter[field];
		if (filterId) {
			delete filters[field];
		}

		this.setState(
			{
				propertyFilter: filters,
				isLoaded: dfilter?.length ? false : true,
			},
			() => {
				this.setState({
					additionalFilters: selected,
				});
				if (dfilter?.length) {
					this.reloadDataAndSetFilter();
				}
			}
		);
	}

	mapAccount = async (data) => {
		let rows = [];
		_.forEach(data, (i) => {
			rows.push({
				key: i.account,
				value: i.accountn,
			});
		});
		return rows;
	};

	handleDateClear(type) {
		this.setState((prevState) => ({
			dateValues: {
				...prevState.dateValues,
				[type]: null,
			},
		}));
	}

	clearFilters() {
		this.setState(
			{
				additionalFilters: [],
				propertyFilter: {},
				clear: true,
				isLoaded: false,
				dateValues: {
					entry: [],
					keyedate: [],
				},
			},
			() => {
				this.fs.setSearches({});
				this.reloadDataAndSetFilter();
				this.setState({
					clear: false,
				});
			}
		);
	}

	getTransactionLink(item) {
		const { type, txnum, invadj } = item;
		const _void = item.void;
		let link = '';

		if (_void === true || (type >= 1400 && type < 2000)) return <>{txnum}</>;
		if (type >= 1000 && type < 1400) {
			link = routeParam(URI.accountsPayable.vendorInvoice.editInvoice, {
				id: txnum,
			});
		}

		if (type >= 2000 && type < 3000 && invadj == false) {
			link = routeParam(
				URI.accountsReceivable.cashReceipt.existingReceiptView,
				{
					refno: txnum,
				}
			);
		}

		if (
			(type >= 3000 && type < 8000) ||
			(type >= 2000 && type < 3000 && invadj == true)
		) {
			link = routeParam(
				URI.accountsReceivable.clientInvoices.existingInvoiceView,
				{
					invnum: txnum,
				}
			);
		}
		if (type >= 8000) {
			link = routeParam(URI.generalLedger.journalEntry.edit, {
				type: 'existing',
				id: txnum,
			});
		}

		if (link) {
			return (
				<Link to={link} className="text-charcoal hover-view-icon">
					{txnum}
				</Link>
			);
		} else {
			return <>{txnum}</>;
		}
	}

	renderHeader() {
		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to="/projects"
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: '/projects' }} linkAs={Link}>
							General Ledger
						</Breadcrumb.Item>
						<Breadcrumb.Item>Transaction Search</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>Transaction Search</HeaderLight.Title>
				</HeaderLight.Content>
			</HeaderLight>
		);
	}

	renderFilterComponent(filter, i) {
		const selection = this.state.filters[filter];
		const { fieldValues } = this.state;

		switch (selection.type) {
			case 'text':
				return (
					<div
						key={i}
						className="position-relative mb-2 me-2 bg-white px-2 py-1 rounded-1 border border-sand"
					>
						<div className="d-flex align-items-center justify-content-between">
							<Form.Control
								type="text"
								placeholder={selection.label}
								className="me-1 w-180px form-control-sm no-border"
								onChange={(e) =>
									this.handleChangeField(e.target.value, selection.id)
								}
								defaultValue={fieldValues[selection.id]}
							/>
							<a
								className="ff-close-dropdown"
								onClick={() => {
									this.handleDetach(selection.id);
								}}
							>
								<i className="ri-close-fill"></i>
							</a>
						</div>
					</div>
				);
			case 'number-range':
				return (
					<div
						key={i}
						className="position-relative mb-2 me-2 bg-white px-2 py-1 rounded-1 border border-sand"
					>
						<div className="d-flex align-items-center justify-content-between">
							<label className="me-1">
								<small>{selection.label}</small>
							</label>
							<Form.Control
								type="number"
								placeholder={`From`}
								className="form-control-sm me-2 w-80px input-no-arrows"
								onChange={(e) =>
									this.handleNumRangeChangeField(
										e.target.value,
										selection.id,
										'from'
									)
								}
								defaultValue={fieldValues[selection.id]?.from}
							/>
							<Form.Control
								type="number"
								placeholder={`To`}
								className="form-control-sm me-1 w-80px input-no-arrows"
								onChange={(e) =>
									this.handleNumRangeChangeField(
										e.target.value,
										selection.id,
										'to'
									)
								}
								defaultValue={fieldValues[selection.id]?.to}
							/>
							<a
								className="ff-close-dropdown"
								onClick={() => this.handleDetach(selection.id)}
							>
								<i className="ri-close-fill"></i>
							</a>
						</div>
					</div>
				);
			case 'number':
				return (
					<div
						key={i}
						className="mb-2 position-relative me-2 bg-white px-2 py-1 rounded-1 border border-sand"
					>
						<div className="d-flex align-items-center justify-content-between">
							<Form.Control
								type="number"
								placeholder={selection.label}
								className="me-1 w-180px form-control-sm no-border input-no-arrows"
								onChange={(e) =>
									this.handleChangeField(e.target.value, selection.id, true)
								}
								defaultValue={fieldValues[selection.id]}
							/>
							<a
								className="ff-close-dropdown"
								onClick={() => {
									this.handleDetach(selection.id);
								}}
							>
								<i className="ri-close-fill"></i>
							</a>
						</div>
					</div>
				);
			case 'date-range':
				return (
					<div
						key={i}
						className="mb-2 position-relative me-2 bg-white px-2 py-1 rounded-1 border border-sand"
					>
						<div className="d-flex align-items-center justify-content-between">
							<DateRangePicker
								key={i}
								className="me-1 no-border"
								style={{ minWidth: '150px' }}
								placeholder={selection.label}
								format={selection.format || 'dd/MM/yyyy'}
								ranges={[]}
								onChange={(dates) => this.handleDateSelect(dates, selection.id)}
								defaultValue={
									fieldValues[selection.id] &&
									fieldValues[selection.id].length === 2
										? [
												new Date(fieldValues[selection.id][0]),
												new Date(fieldValues[selection.id][1]),
										  ]
										: null
								}
							/>
							<a
								className="ff-close-dropdown"
								onClick={() => {
									this.handleDetach(selection.id);
								}}
							>
								<i className="ri-close-fill"></i>
							</a>
						</div>
					</div>
				);
			case 'date':
				return (
					<div
						key={i}
						className="mb-2 position-relative me-2 bg-white px-2 rounded-1 border border-sand"
					>
						<div className="d-flex align-items-center justify-content-between">
							<DatePicker
								key={i}
								className="me-2 no-border no-outline-active"
								style={{ minWidth: '150px' }}
								placeholder={selection.label}
								format="MM/yyyy"
								onChange={(dates) => this.handleDateSelect(dates, selection.id)}
								defaultValue={
									fieldValues[selection.id]
										? new Date(fieldValues[selection.id][0])
										: null
								}
							/>
							<a
								className="ff-close-dropdown"
								onClick={() => {
									this.handleDetach(selection.id);
								}}
							>
								<i className="ri-close-fill"></i>
							</a>
						</div>
					</div>
				);
		}

		return <></>;
	}

	renderFilters() {
		const { fieldValues } = this.state;
		return (
			<>
				<ListFilter className="project-list-filter" breakpoint={1030}>
					<div className="d-flex flex-row justify-content-between">
						<ListFilter.Fields md={12} lg={9} xl={9} className="width-30">
							<ListFilter.Field className="flex-wrap w-100">
								<ComboBox
									id="client-dropdown"
									title={'Select Client'}
									label={'Client'}
									type={'client'}
									onSelect={this.handleSelect}
									filters={this.buildFilters('client')}
									onClear={this.state.clear}
									field={'ClientName|client'}
									getDataFn={'getClientsSummaries'}
									className="me-2 mw-100px"
									selected={fieldValues['client']}
								/>
								<ComboBox
									id="vendor-dropdown"
									title={'Select Vendor'}
									label={'Vendor'}
									alwaysShowLabelAndValue
									type={'vendor'}
									onSelect={this.handleSelect}
									filters={this.buildFilters('vendor')}
									onClear={this.state.clear}
									field={'vendorn|vendor'}
									getDataFn={'getVendorsSummaries'}
									className="me-2 mw-100px"
									selected={fieldValues['vendor']}
								/>
								<div className="daterange-labeled-group position-relative">
									{this.state.dateValues.entry !== undefined &&
									this.state.dateValues.entry?.length > 0 ? (
										<span className="group-label position-absolute fs-8">
											Entry date
										</span>
									) : (
										''
									)}
									<DateRangePicker
										className="me-2 mb-2"
										style={{ minWidth: '170px' }}
										placeholder="Entry date"
										format="MM/dd/yyyy"
										value={this.state.dateValues.entry}
										onClean={() => this.handleDateClear('entry')}
										onChange={(dates) => this.handleDateSelect(dates, 'entry')}
										ranges={[
											{
												label: 'today',
												value: [startOfDay(new Date()), endOfDay(new Date())],
											},
											{
												label: 'yesterday',
												value: [
													startOfDay(addDays(new Date(), -1)),
													endOfDay(addDays(new Date(), -1)),
												],
											},
											{
												label: 'last7Days',
												value: [
													startOfDay(subDays(new Date(), 6)),
													endOfDay(new Date()),
												],
											},
											{
												label: 'Last 30 Days',
												value: [
													startOfDay(subDays(new Date(), 30)),
													endOfDay(new Date()),
												],
											},
											{
												label: 'This month',
												value: [
													startOfDay(
														new Date(
															getYear(new Date()),
															getMonth(new Date()),
															1
														)
													),
													endOfDay(lastDayOfMonth(new Date())),
												],
											},
											{
												label: 'Last month',
												value: [
													startOfDay(
														new Date(
															getYear(new Date()),
															getMonth(new Date()) - 1,
															1
														)
													),
													endOfDay(
														lastDayOfMonth(
															new Date(
																getYear(new Date()),
																getMonth(new Date()) - 1,
																1
															)
														)
													),
												],
											},
										]}
									/>
								</div>
								<ComboBox
									id="project-dropdown"
									title={'Select Project'}
									label={'Project'}
									type={'project'}
									onSelect={this.handleSelect}
									filters={this.buildFilters('project')}
									onClear={this.state.clear}
									field={'projn|proj'}
									getDataFn={'getProjectsSummaries'}
									className="me-2 mw-100px"
									selected={fieldValues['project']}
								/>
								<ComboBox
									id="type-dropdown"
									title={'Select Type'}
									label={'Type'}
									type={'type'}
									setSuffix={false}
									onSelect={this.handleSelectType}
									options={this.accountsTypes}
									onClear={this.state.clear}
									className="me-2 mw-100px"
									overrideOptionKey={(option) => {
										return kebabCase(option.key);
									}}
									selected={fieldValues['type']}
								/>
								<div className="daterange-labeled-group position-relative">
									{this.state.dateValues.keyedate !== undefined &&
									this.state.dateValues.keyedate?.length > 0 ? (
										<span className="group-label position-absolute fs-8">
											Keyed date
										</span>
									) : (
										''
									)}
									<DateRangePicker
										className="me-2 mb-2"
										style={{ minWidth: '170px' }}
										placeholder="Keyed date"
										format="MM/dd/yyyy"
										value={this.state.dateValues.keyedate}
										onClean={() => this.handleDateClear('keyedate')}
										onChange={(dates) =>
											this.handleDateSelect(dates, 'keyedate')
										}
										ranges={[
											{
												label: 'today',
												value: [startOfDay(new Date()), endOfDay(new Date())],
											},
											{
												label: 'yesterday',
												value: [
													startOfDay(addDays(new Date(), -1)),
													endOfDay(addDays(new Date(), -1)),
												],
											},
											{
												label: 'last7Days',
												value: [
													startOfDay(subDays(new Date(), 6)),
													endOfDay(new Date()),
												],
											},
											{
												label: 'Last 30 Days',
												value: [
													startOfDay(subDays(new Date(), 30)),
													endOfDay(new Date()),
												],
											},
											{
												label: 'This month',
												value: [
													startOfDay(
														new Date(
															getYear(new Date()),
															getMonth(new Date()),
															1
														)
													),
													endOfDay(lastDayOfMonth(new Date())),
												],
											},
											{
												label: 'Last month',
												value: [
													startOfDay(
														new Date(
															getYear(new Date()),
															getMonth(new Date()) - 1,
															1
														)
													),
													endOfDay(
														lastDayOfMonth(
															new Date(
																getYear(new Date()),
																getMonth(new Date()) - 1,
																1
															)
														)
													),
												],
											},
										]}
									/>
								</div>
								<ComboBox
									id="account-no-dropdown"
									title={'Select Account No.'}
									label={'Account No.'}
									type={'account'}
									onSelect={this.handleSelect}
									filters={this.buildFilters('account')}
									onClear={this.state.clear}
									field={'accountn|account'}
									getDataFn={'getGLAccounts'}
									map={this.mapAccount}
									className="me-2 mw-150px"
									selected={fieldValues['account']}
								/>
								{this.state.additionalFilters.map((filter, i) =>
									this.renderFilterComponent(filter, i)
								)}
							</ListFilter.Field>
						</ListFilter.Fields>
						<ListFilter.Actions
							md={12}
							lg={4}
							xl={3}
							className="justify-content-center"
						>
							<ListFilter.Action className="">
								<Button
									href="#"
									to="#"
									variant="white"
									size="sm"
									className="fw-bold btn-icon btn-action me-3 mb-2"
									onClick={this.clearFilters.bind(this)}
								>
									Clear
								</Button>
								<ComboBox
									id="more-options-dropdown"
									title={'Add More Search Criteria '}
									label={'More +'}
									type={'more-options'}
									baseVariant="button"
									position="right"
									searchPlaceholder="Search"
									setSuffix={false}
									onSelect={this.handleSelectMoreFilters}
									options={this.filterOptions}
									selected={this.state.additionalFilters}
									className="me-2 mw-100px"
								/>
							</ListFilter.Action>
						</ListFilter.Actions>
					</div>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	renderPagination() {
		if (this.state.data.length) {
			return (
				<div className={'d-flex flex-wrap justify-content-center'}>
					<div className={'d-flex flex-row align-items-center pagination'}>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={this.state.page === 1}
							onClick={() => this.handleChangePage(this.state.page - 1)}
						>
							<i className="ri-arrow-left-s-line"></i> Previous
						</Button>
						<span className={'pagination-span'}>{this.state.page}</span>
						<Button
							variant="ivory"
							size="sm"
							className={'btn-icon pagination-btn'}
							disabled={this.state.data.length <= this.state.pageSize.value}
							onClick={() => this.handleChangePage(this.state.page + 1)}
						>
							Next <i className="ri-arrow-right-s-line ms-1"></i>
						</Button>
					</div>
					<Select
						onChange={this.handleFilter('pageSize')}
						key={`${Math.floor(Math.random() * 1000)}-min`}
						options={this.availablePageSize}
						defaultValue={this.state.pageSize}
						className="react-select pagination-select mx-3"
						placeholder="Please select"
					/>
				</div>
			);
		}
	}

	renderDebtAmount(amount) {
		if (!amount || amount < 0) {
			return '';
		}

		return <span>{currencyFormat(amount)}</span>;
	}

	renderCreditAmount(amount) {
		if (!amount || amount > 0) {
			return '';
		}

		return <span>{currencyFormat(Math.abs(amount))}</span>;
	}

	renderContent() {
		return (
			<Row>
				<Col sm="12">{this.renderFilters()}</Col>

				<div className="table-gradient sticky-container">
					<Table striped responsive className="a-table">
						<thead>
							<tr className="a-table-heading">
								<th className="mw-90px">
									<span className="sort active asc" data-field="txnum">
										Trans No.
									</span>
								</th>
								<th className="mw-90px">
									<span className="sort" data-field="revnum">
										Revision
									</span>
								</th>
								<th className="mw-90px">
									<span className="sort" data-field="typeDesc">
										Type
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="userdate">
										Date
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="fiscalmonth">
										Fiscal Month
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="account">
										Account No.
									</span>
								</th>
								<th className="mw-200px">
									<span className="sort" data-field="accountn">
										Account Name
									</span>
								</th>
								<th className="mw-120px">
									<span className="sort" data-field="amount">
										Debt Amount
									</span>
								</th>
								<th className="mw-120px">
									<span className="sort" data-field="amount">
										Credit Amount
									</span>
								</th>
								<th className="mw-250px">
									<span className="sort" data-field="txdesc">
										Description
									</span>
								</th>
								<th className="mw-250px">
									<span className="sort" data-field="revisiondesc">
										Revision Description
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="proj">
										Proj. Code / Order No.
									</span>
								</th>
								<th className="mw-120px">
									<span className="sort" data-field="client">
										Client Code
									</span>
								</th>
								<th className="mw-90px">
									<span className="sort" data-field="ponum">
										Po No.
									</span>
								</th>
								<th className="mw-120px">
									<span className="sort" data-field="propnum">
										Proposal No.
									</span>
								</th>
								<th className="mw-120px">
									<span className="sort" data-field="checknum">
										Check No.
									</span>
								</th>
								<th className="mw-90px">
									<span className="sort" data-field="supplier">
										Vendor Code
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="sinvnum">
										Vendor Invoice No
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="computerdate">
										Keyed Date
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="depositslip">
										Deposit Slip No.
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="paymentduedate">
										Due Date
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="orgname">
										Misc. CR Name
									</span>
								</th>
								<th className="mw-90px">
									<span className="sort" data-field="jenum">
										JE No.
									</span>
								</th>
								<th className="mw-120px">
									<span className="sort" data-field="txnum">
										Payment Tx Ref.
									</span>
								</th>
								<th className="mw-150px">
									<span className="sort" data-field="username">
										Keyed by user
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{!this.state.isLoaded
								? showLoading()
								: this.state.data.length > 0
								? this.state.data
										.slice(0, this.state.pageSize.value)
										.map((item, i) => (
											<tr key={i} data-key={i}>
												<td>{this.getTransactionLink(item)}</td>
												<td>{item.revnum < 1 ? '' : item.revnum}</td>
												<td>{item.typeDesc}</td>
												<td>
													{item.userdate
														? dayjs(item.userdate).format('MM/DD/YYYY')
														: ''}
												</td>
												<td>
													{item.fiscalmonth
														? dayjs(item.fiscalmonth).format('MM/YYYY')
														: ''}
												</td>
												<td>{item.account}</td>
												<td>{item.accountn}</td>
												<td>
													{item.amount > 0 ? currencyFormat(item.amount) : ''}
												</td>
												<td>
													{item.amount < 0 ? currencyFormat(item.amount) : ''}
												</td>
												<td>{item.txdesc}</td>
												<td>{item.revisiondesc}</td>
												<td>{item.proj}</td>
												<td>{item.client}</td>
												<td>{item.ponum}</td>
												<td>{item.propnum}</td>
												<td>{item.checknum}</td>
												<td>{item.supplier}</td>
												<td>{item.sinvnum}</td>
												<td>{item.computerdate}</td>
												<td>{item.depositslip}</td>
												<td>{item.paymentduedate}</td>
												<td>{item.orgname}</td>
												<td>{item.jenum}</td>
												<td>{item.txnum}</td>
												<td>{item.username}</td>
											</tr>
										))
								: showEmpty()}
						</tbody>
					</Table>
					{this.renderPagination()}
				</div>
			</Row>
		);
	}

	render() {
		return (
			<>
				{this.renderHeader()}

				<div className="ff-transaction-search content-padding min-height has-action-bar">
					{renderSecureContent(
						<Container fluid>{this.renderContent()}</Container>
					)(33, SECURITY_ATTRIBUTE_TYPES.DenyAccess)}
				</div>
			</>
		);
	}
}

export default WithRouter(TransactionSearchList);
