import dropzoneOverlayImage from '../../../assets/images/document.png';
import excelImage from '../../../assets/images/icons/excel.svg';
import wordImage from '../../../assets/images/icons/word.svg';
import pdfImage from '../../../assets/images/icons/pdf.svg';
import unknown from '../../../assets/images/icons/file.svg';
import urlImage from '../../../assets/images/icons/url.svg';

export const DocumentsAssets = {
	Image: {
		dropzoneOverlay: dropzoneOverlayImage,
		excel: excelImage,
		word: wordImage,
		pdf: pdfImage,
		unknown: unknown,
		url: urlImage,
	},
};
