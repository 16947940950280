import { Button } from 'react-bootstrap';
import cn from 'classnames';
import Stack from 'react-bootstrap/Stack';
import { useEffect, useState } from 'react';
import { useForm, useController, Controller } from 'react-hook-form';
import CurrencyInput from './CurrencyInput';
import { checkReconcile, startReconcile } from './api';
import AccountsDropdown from './AccountsDropdown';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { EAccountType } from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { useAccounts } from './hooks';
import { useReconcile } from './hooks/useReconcile';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';

const ReconcileSetup = ({ data, onDownload, onAccept, onStart }) => {
	const { started, selected, setStarted, setSelected } = useReconcile();
	const [badStart, setBadStart] = useState(false);
	const { data: accounts } = useAccounts(EAccountType.LIABILITY);
	const [newAccount, setNewAccount] = useState(null);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	const {
		control,
		formState: { errors, isValid },
		handleSubmit,
		setValue,
	} = useForm({
		defaultValues: {
			endingBalance: '',
			statementDate: null,
			account: null,
		},
	});

	const { field } = useController({
		name: 'account',
		control,
		rules: { required: true },
	});

	useEffect(() => {
		// Set the first account.
		if (!accounts) return;
		const [firstAccount] = accounts;
		if (firstAccount) {
			setValue('account', {
				label: `[${firstAccount.account}] ${firstAccount.accountn}`,
				value: firstAccount.account,
			});
		}
	}, [accounts, setValue]);

	const onSubmit = async (data) => {
		const response = await checkReconcile(
			data.account.value,
			format(new Date(data.statementDate), 'yyyy-MM-dd')
		);

		if (response.length > 0) {
			setBadStart(true);
		} else {
			setBadStart(false);

			await startReconcile({
				statementEndingDate: format(new Date(data.statementDate), 'yyyy/MM/dd'),
				creditCardAccount: data.account.value,
			});

			onStart(data);
		}
	};

	const handleOnAccountChange = (account) => {
		const { value, label } = account;

		if (started) {
			let hasOutstandingTaggedItems = false;
			for (const recnum of Object.keys(selected)) {
				if (
					selected[recnum] &&
					data.some((x) => x.recnum === Number(recnum) && !x.cleared)
				) {
					hasOutstandingTaggedItems = true;
					break;
				}
			}
			if (hasOutstandingTaggedItems) {
				setNewAccount({
					value,
					label,
				});
				setShowConfirmationModal(true);
			} else {
				reset();
				setValue('account', { value, label });
			}
		} else {
			reset();
			setValue('account', { value, label });
		}
	};

	const reset = () => {
		setStarted(false);
		setSelected({});
		setValue('endingBalance', '', { shouldValidate: true });
		setValue('statementDate', null, { shouldValidate: true });
	};

	const onConfirm = () => {
		setValue('account', newAccount);
		reset();
		setShowConfirmationModal(false);
	};

	const onCancel = () => {
		setNewAccount(null);
		setShowConfirmationModal(false);
	};

	const selectClasses = cn({
		'border-danger': !!errors.account,
	});
	const statementDateClasses = cn({
		'border-danger': !!errors.statementDate,
	});
	const endingBalanceClasses = cn('form-control', {
		'border-danger': !!errors.endingBalance,
	});

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="bg-ivory light a-header pb-3">
					<Stack direction="horizontal" gap={3} className="align-items-end">
						<div className="w-25">
							Account
							<AccountsDropdown
								{...field}
								className={`${selectClasses} tw-h-10`}
								onChange={handleOnAccountChange}
							/>
						</div>
						<div>
							<span className="tw-block">Statement Date</span>
							<Controller
								control={control}
								name="statementDate"
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<ReactDatePicker
										dateFormat="MM/dd/yyyy"
										onChange={onChange}
										className={`${statementDateClasses} form-control form-control-sm tw-h-10`}
										showMonthDropdown={true}
										showYearDropdown={true}
										placeholderText="mm-dd-yyyy"
										autoComplete="off"
										selected={value}
										disabled={started}
									/>
								)}
							/>
						</div>
						<div>
							Statement Ending Balance
							<CurrencyInput
								className={endingBalanceClasses}
								disabled={started}
								name="endingBalance"
								control={control}
								rules={{ required: true }}
							/>
						</div>
						<div>
							{!started ? (
								<Button variant="primary" type="submit" disabled={!isValid}>
									Start Reconcile
								</Button>
							) : (
								<Stack direction="horizontal" gap={3}>
									<Button variant="primary" onClick={onAccept}>
										Accept Reconciliation
									</Button>
									<Button variant="outline-dark ms-auto" onClick={onDownload}>
										Get PDF
									</Button>
								</Stack>
							)}
						</div>
					</Stack>
					{badStart && (
						<p className="mt-4 alert alert-danger">
							There is already a reconciliation for this account that is newer
							or the same as this one. Please use the history button to view
							completed reconciliations.
						</p>
					)}
				</div>
			</form>
			<ConfirmationModal
				confirmAction={onConfirm}
				show={showConfirmationModal}
				toggleModal={onCancel}
				message="Are you sure you want to leave this reconciliation?  All tagged Outstanding entries that you have not marked as cleared will no longer be tagged for this account upon your return."
				title="Exit Reconciliation"
				labelOK="Yes"
				labelCancel="No"
			/>
		</>
	);
};

ReconcileSetup.displayName = 'ReconcileSetup';

export { ReconcileSetup };
