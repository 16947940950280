import React from 'react';
import BaseGrid from '../BaseGrid/BaseGrid';
import Section from '../BaseGrid/Section';
import Icon from '../../Icon/Icon';
import ResourcesIcon from '../../../../assets/images/icons/ResourcesIcon.svg';
import SectionContent from './SectionContent';
import { ResourcesGridConstants } from '../../../constants/dashboard/resourcesGridConstants';
import GridLinkItem from './GridLinkItem';

const ResourceSectionGrid = () => (
	<BaseGrid gridClass="tw-grid  tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-3 tw-p-5 tw-bg-[#faf9f7] tw-rounded-lg tw-shadow-md  ">
		<Section sectionClass="tw-col-span-full mb-2">
			<div className="tw-flex tw-items-center tw-space-x-3">
				<div className="tw-App tw-flex tw-items-center">
					<Icon path={ResourcesIcon} iconClass="tw-mr-5" />
					<h1 className="tw-text-2xl">{ResourcesGridConstants.gridTitle}</h1>
				</div>
			</div>
		</Section>

		<Section sectionClass="tw-col-span-1 tw-border tw-border-gray-200 tw-rounded-lg tw-bg-white tw-p-5">
			<SectionContent
				title={ResourcesGridConstants.sectionOneTitle}
				subtitle={ResourcesGridConstants.sectionOneSubtitle}
			>
				<div className="tw-mt-4">
					{ResourcesGridConstants.sectionOneItems.map((item, index) => {
						return (
							<GridLinkItem
								key={`grid-section-item-${index}`}
								text={item.text}
								subtext={item?.subtext}
								hasIcon={!!item?.icon}
								linkIconClass="tw-w-32 tw-h-32 tw-bg-cover tw-bg-center tw-rounded-lg"
								linkIconStyle={{
									background: `url(${item.icon})`,
									backgroundSize: 'cover',
									height: '100%',
									width: '100%',
									backgroundPosition: 'center',
								}}
								url={item?.url}
								linkTextClass="tw-text-md tw-font-medium tw-text-sm"
							/>
						);
					})}
				</div>
			</SectionContent>
		</Section>

		<Section sectionClass="tw-col-span-1 tw-border tw-border-gray-200 tw-rounded-lg tw-bg-white tw-p-5">
			<SectionContent
				title={ResourcesGridConstants.sectionTwoTitle}
				subtitle={ResourcesGridConstants.sectionTwoSubtitle}
			>
				<div className="tw-mt-4">
					{ResourcesGridConstants.sectionTwoItems.map((item, index) => {
						return (
							<GridLinkItem
								key={`grid-section-two-item-${index}`}
								text={item.text}
								subtext={item?.subtext}
								hasIcon={!!item?.icon}
								url={item?.url}
								linkTextClass="tw-text-md tw-font-medium tw-text-[#006a53] tw-text-sm"
							/>
						);
					})}
				</div>
			</SectionContent>
		</Section>
	</BaseGrid>
);

ResourceSectionGrid.displayName = 'ResourceSectionGrid';

export default ResourceSectionGrid;
