import React from 'react';
import { Dropdown, Col, Row, Table, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ThreadComponent from '../../ThreadComponent';
import { WithRouter } from '../../../../helpers/Router';
import ListFilter from '../../../components/ListFilter';
import { ApiService } from '../../../../lib/api/HttpService';
import { currencyFormat } from '../../../../helpers/Number';
import { pathParam } from '../../../../utilities/Router';
import URI from '../../../../defaults/RoutesDefault';
import { Pagination } from '../../../../app/components/pagination/Pagination';
import debounce from 'lodash/debounce';
import { addDomClass, hasClass, removeDomClass } from '../../../../helpers/DOM';
import { formatDate, formatFilterDate } from '../../../../helpers/Date';
import { DateRangePicker } from 'rsuite';
import {
	startOfDay,
	endOfDay,
	addDays,
	subDays,
	getMonth,
	getYear,
	lastDayOfMonth,
} from 'date-fns';
import ClientInvoicesPostModal from '../../modal/ClientInvoicesPostModal';
import { getCookie } from '../../../../utilities/Auth';
import { format } from 'date-fns';
import { Event } from '../../../../utilities/DOM';
import { showEmpty, showLoading } from '../../../../helpers/Loading';
import { Is, isFiltered } from '../../../../helpers/Util';
import {
	displayAlert,
	displayAlertError,
	displayAlertLoader,
	displayErrors,
	getErrorMessage,
	hideAlertLoader,
} from '../../../../utilities/Response';
import MSG from '../../../../defaults/Message';
import { getCheckAllClass } from '../../../../utilities/ModuleHelper';
import ConfirmModal from '../../../../app/components/modal/ConfirmModal';
import {
	clone,
	concat,
	delay,
	findIndex,
	includes,
	isEmpty,
	pullAt,
	remove,
} from 'lodash';
import { plural } from '../../../../utilities/String';
import FilterSort from '../../../../utilities/modules/FilterSort';

class ClientInvoicesListNew extends ThreadComponent {
	constructor(props) {
		super(props);

		this.fs = new FilterSort('ar_clientinvoice_new_list');
		this.fs.setDefaultSort('invnum asc');
		this.state = {
			checks: [],
			totalChecked: 0,
			data: [],
			pageSize: 20,
			page: 1,
			sortProperty: this.fs.getSort() ?? 'invnum asc',
			dataIsLoaded: false,
			showPostModal: false,
			showTableSearch: this.fs.isSearchActive(),
			searchProperties: this.fs.getSearches(),
		};

		this.api = new ApiService();
		this.changePageHandler = debounce(this.handleChangePage.bind(this, 1), 200);
		this.userCode = getCookie('dmUsercode');
		this.updateErrors = [];
		this.updateErrIds = [];
	}

	componentInit() {
		this.setTitle('New | Client Invoices').setActionBar(true);
	}

	async componentDidMount() {
		this.fetchItems();
		this.enableSortTable();
	}

	resetState() {
		this.setState({
			dataIsLoaded: false,
		});
	}

	async fetchItems(page) {
		this.resetState();
		let inv = await this.api.getNewInvoices(`?${this.buildFilters(page)}`);
		let projects = [];

		const projectCodes = inv
			.filter((i) => !isEmpty(i.proj))
			.map((i) => `'${encodeURIComponent(i.proj)}'`);

		if (!isEmpty(projectCodes)) {
			try {
				projects = await this.api.getProjects(
					`?$filter=proj in (${projectCodes.join(',')})`
				);
				if (!isEmpty(projects)) {
					for (let i of inv) {
						i.projectdet = projects.find((p) => p.proj === i.proj);
					}
				}
			} catch (error) {
				// Ignore errors
			}
		}

		this.setState({
			dataIsLoaded: true,
			data: inv,
		});

		const holdArray = [];
		const getHoldChecks = inv
			.slice(0, this.state.pageSize)
			.forEach((item, i) => {
				if (!item.hold) {
					holdArray.push(item.invnum);
				}
			});

		this.setState({
			checks: holdArray,
			totalChecked: holdArray.length,
		});
	}

	handleShowTableSearch = (e) => {
		const filters = document.querySelectorAll('.a-table-search-fields input');
		if (filters) {
			filters.forEach((e) => {
				e.value = '';
			});
		}

		let newTableSearch = !this.state.showTableSearch;
		this.setState({
			showTableSearch: newTableSearch,
		});
		this.fs.setIsActiveSearch(newTableSearch);

		if (this.hasSearchFilter(this.state.searchProperties)) {
			this.fs.setSearches({});
			this.setState(
				{
					searchProperties: {},
				},
				this.changePageHandler
			);
		}
	};

	handleCheck = async (entry) => {
		const { checks, data } = this.state;

		const index = findIndex(checks, (_id) => _id === entry.invnum);
		const checked = index > -1;

		const updatedChecks = checked
			? checks.slice(0, index).concat(checks.slice(index + 1))
			: checks.concat(entry.invnum);

		this.setState({
			checks: updatedChecks,
			totalChecked: updatedChecks.length,
		});

		await this.api
			.patchTemporaryClientInvoices({
				invnum: entry.invnum,
				hold: checked,
				usercode: this.userCode,
			})
			.then((data) => {
				hideAlertLoader();
			})
			.catch((error) => {
				displayAlert('danger', getErrorMessage(error));
			});
	};

	handleSelectAllChecks = (e) => {
		this.updateErrors = [];
		this.updateErrIds = [];
		let checks = [];
		// Get the target menu.
		const isChecked = e.target.checked;

		if (isChecked) {
			Event('.single-data-checkbox .form-check-input').check();
			checks = this.state.data.map((item) => {
				return item.invnum;
			});
		} else {
			Event('.single-data-checkbox .form-check-input').uncheck();
			checks = [];
		}

		this.setState({
			checks: checks,
			totalChecked: checks.length,
		});

		this.handleMultipleSelect(!isChecked);
	};

	async handleMultipleSelect(hold) {
		displayAlertLoader(MSG.loading.hold);
		for (let item of this.state.data) {
			item.hold = hold;

			const data = {
				invnum: item.invnum,
				hold: item.hold,
				usercode: this.userCode,
			};

			await this.api.patchTemporaryClientInvoices(data).catch((error) => {
				this.updateErrors.push({
					error,
					append: ` Invoice No: ${item.txnum}`,
				});
				this.updateErrIds.push(item.txnum);
			});
		}

		const message = this.updateErrIds.length
			? 'The data with the ids [' +
			  this.updateErrIds.join() +
			  "] couldn't be updated"
			: 'The data was succesfully updated';
		const variant = this.updateErrIds.length ? 'danger' : 'success';

		await this.fetchItems();
		hideAlertLoader();

		if (this.updateErrIds.length) {
			displayErrors(this.updateErrors);
			displayAlert(variant, message);
		}
	}

	handleMenuClick = (e) => {
		e.preventDefault();

		this.setState(
			(prevState) => (prevState.activeMenu = e.target.dataset.menu)
		);
	};

	handleToggle = (e) => {
		e.preventDefault();
		// Get the target menu.
		const id = e.target.getAttribute('data-state');

		// Set the new state.
		this.setState((prevState) => {
			let data = {};
			data[id] = !prevState[id];

			return data;
		});
	};

	enableSortTable() {
		const sort = document.querySelectorAll('.a-table-heading .sort');
		const self = this;

		// Add change event
		if (sort) {
			sort.forEach((_e) => {
				_e.addEventListener(
					'click',
					function (e) {
						sort.forEach((_e2) => {
							if (_e !== _e2) {
								removeDomClass('desc', _e2);
								removeDomClass('asc', _e2);
								removeDomClass('active', _e2);
							}
						});

						addDomClass('active', _e);

						if (hasClass('desc', _e)) {
							removeDomClass('desc', _e);
							addDomClass('asc', _e);
						} else if (hasClass('asc', _e)) {
							removeDomClass('asc', _e);
							addDomClass('desc', _e);
						} else {
							addDomClass('desc', _e);
						}

						const sortProperty = `${_e.attributes['data-field'].value} ${
							hasClass('desc', _e) ? 'asc' : 'desc'
						}`;

						// Save sortProperty to localStorage
						self.fs.setSort(sortProperty);

						self.setState(
							{
								sortProperty,
							},
							self.changePageHandler
						);
					},
					false
				);
			});
		}
	}

	doTableSearch(data) {}

	buildFilters(currentPage) {
		let filters = [];

		Object.keys(this.state.searchProperties).forEach((key) => {
			const property = this.state.searchProperties[key];
			if (property.value || property.min || property.ma) {
				if (property.type === 'number') {
					filters.push(`${key} eq ${property.value}`);
				} else if (property.type === 'date') {
					if (property.min) filters.push(`${key} ge ${property.min}`);
					if (property.max) filters.push(`${key} le ${property.max}`);
				} else {
					filters.push(`contains(${key}, '${property.value}')`);
				}
			}
		});

		const filtersQuery =
			filters.length > 0 ? `&$filter=${filters.join(' and ')}` : '';
		let queryString = filtersQuery; //`$top=${this.state.pageSize + 1}&$skip=${((currentPage ?? this.state.page) - 1) * this.state.pageSize}${filtersQuery}`

		if (this.state.sortProperty !== '') {
			queryString += `&$orderby=${this.state.sortProperty}`;
		}

		return queryString;
	}

	onPageSizeChanged = (size) => {
		this.setState(
			{
				pageSize: size,
				page: 1,
			},
			() => {
				this.handleChangePage(1);
			}
		);
	};

	onPageChanged = (page) => {
		this.handleChangePage(page);
	};

	handleChangePage = async (page) => {
		this.fetchItems(page);

		this.setState({
			page: page,
		});
	};

	handleSearch = (e) => {
		const key = e.target.attributes['data-field'].value;
		const value = e.target.value;
		const type = e.target.attributes['data-type']
			? e.target.attributes['data-type'].value
			: 'string';

		this.setState(
			{
				searchProperties: {
					...this.state.searchProperties,
					[key]: { value: value, type: type },
				},
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	handleDateChange = (key, e) => {
		let tmp = this.state.searchProperties;
		if (e !== null) {
			tmp[key] = {
				min: formatFilterDate(e[0]),
				max: formatFilterDate(e[1]),
				type: 'date',
			};
		} else {
			delete tmp[key];
		}
		this.setState(
			{
				searchProperties: tmp,
				dataIsLoaded: false,
			},
			() => {
				this.fs.setSearches(this.state.searchProperties);
				this.changePageHandler();
			}
		);
	};

	openPostModal = () => {
		this.setState({
			showPostModal: true,
		});
	};

	hidePostModal = () => {
		this.setState({
			showPostModal: false,
		});
	};

	handleSave = async (fiscalMonth) => {
		this.dMloader(true, null, false);
		try {
			await this.api.updateTemporaryClientInvoicesFiscalMonth({
				newFiscalMonth: format(fiscalMonth, 'MM/yyyy'),
				updateAccounts: true,
				userCode: this.userCode,
			});

			const company = await this.api.getCompany2();

			await this.api.postTemporaryClientInvoice({
				reportFormatId: company.invstyle,
			});

			displayAlert('success', 'Successfully posted invoice.');
		} catch (error) {
			displayAlertError(getErrorMessage(error));
		}

		document.getElementById('inline-check-th-0').checked = false;

		this.hidePostModal();
		this.dMloader(false, true);

		this.fetchItems();
		this.enableSortTable();
	};

	toggleDeleteModal = (state) => {
		if (true === state && Is.empty(this.state.checks)) {
			// Check if there are selected.
			displayAlert('danger', MSG.error.noSelected, 4000);
			return;
		}

		this.setState({
			showDeleteModal: state,
		});
	};

	onDeleteData = async () => {
		this.updateErrors = [];
		this.updateErrIds = [];
		const count = this.state.totalChecked;

		displayAlertLoader(
			plural(MSG.loading.delete.ARClientInv, 'invoice', count)
		);
		this.toggleDeleteModal(false);

		for (const id of this.state.checks) {
			if (id) {
				const receipt = _.find(this.state.data, {
					invnum: id,
				});
				await this.handleDelete(id, receipt?.fType);
			}
		}

		const message = this.updateErrIds.length
			? 'The data with the ids [' +
			  this.updateErrIds.join() +
			  "] couldn't be deleted"
			: plural(MSG.success.delete.ARClientInv, 'invoice', count);
		const variant = this.updateErrIds.length ? 'danger' : 'success';

		// This first delay will give us some time to process the api call.
		delay(async () => {
			hideAlertLoader();
			displayErrors(this.updateErrors);
			displayAlert(variant, message, 3000);
			await this.fetchItems();
		}, 1700);
	};

	async handleDelete(id, type) {
		const path = `?UserCode=${this.userCode}&InvNum=${id}`;

		await this.api
			.deleteTemporaryClientInvoices(path)
			.then((res) => {
				if (res.status !== 200) this.updateErrIds.push(id);
			})
			.catch((error) => {
				this.updateErrIds.push(id);
				this.updateErrors.push({
					error,
					append: ` Inv Num: ${id}`,
				});
			});
	}

	sortClass(name) {
		return `sort ${this.fs.isActiveSort(name)}`;
	}

	renderFilters() {
		const { totalChecked } = this.state;
		return (
			<>
				<ListFilter>
					<ListFilter.Fields
						md={12}
						lg={10}
						xl={10}
						className="width-33 no-auto"
					/>
					<ListFilter.Actions
						md={2}
						lg={2}
						xl={2}
						replaceclassmatch="justify-content-xl-end"
						replaceclassmatchwith="justify-content-md-end"
					>
						<ListFilter.Action>
							<Button
								as={Link}
								to="#"
								variant="ivory"
								size="sm"
								className={`btn-icon btn-action ${
									this.state.showTableSearch ? 'bg-primary-ash text-white' : ''
								}`}
								onClick={this.handleShowTableSearch}
							>
								<i className="ri-search-line"></i> Search
							</Button>
						</ListFilter.Action>
						<ListFilter.Action className="ms-auto ms-md-3">
							<Dropdown
								className="d-flex justify-content-end ms-auto"
								align="end"
							>
								<Dropdown.Toggle
									id="dropdown-autoclose-true"
									variant="ivory"
									size="sm"
								>
									Actions
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item
										onClick={totalChecked < 1 ? null : this.openPostModal}
										disabled={totalChecked < 1}
									>
										<i className="ri-upload-fill"></i> Post
									</Dropdown.Item>
									<Dropdown.Item
										onClick={this.toggleDeleteModal.bind(this, true)}
									>
										<i className="ri-delete-bin-5-line"></i> Delete
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</ListFilter.Action>
					</ListFilter.Actions>
				</ListFilter>
				<ListFilter.Spacer />
			</>
		);
	}

	render() {
		const { checks, totalChecked, data } = this.state;
		return (
			<>
				<Row>
					<Col sm="12">
						{/* Filter */}
						{this.renderFilters()}

						<div className="table-gradient sticky-container">
							<Table striped responsive className="a-table">
								<thead>
									<tr className="a-table-heading">
										<th align="middle" className="mw-80px">
											<div className="d-flex justify-content-center">
												<Form.Check
													inline
													label=""
													name={`inline-check-th-0`}
													type="checkbox"
													data-id={`th-0`}
													id={`inline-check-th-0`}
													checked={totalChecked > 0}
													className={getCheckAllClass(totalChecked, data)}
													onClick={this.handleSelectAllChecks}
												/>
											</div>
										</th>
										<th>
											<span
												className={this.sortClass('invnum')}
												data-field="invnum"
											>
												Temp.
												<br />
												Inv. No.
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('invdt')}
												data-field="invdt"
											>
												Date
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('proj')}
												data-field="proj"
											>
												Project Code
											</span>
										</th>
										<th>
											<span data-field="">Project Name</span>
										</th>
										<th>
											<span data-field="">Client Code</span>
										</th>
										<th>
											<span data-field="">Client Name</span>
										</th>
										<th>
											<span
												className={this.sortClass('propnum')}
												data-field="propnum"
											>
												Prop. No.
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('txdesc')}
												data-field="txdesc"
											>
												Transaction Description
											</span>
										</th>
										<th className="mw-180px">
											<span
												className={this.sortClass('totalPrice')}
												data-field="totalPrice"
											>
												Total Sales
											</span>
										</th>
										<th className="mw-180px">
											<span
												className={this.sortClass('totalSalesTax')}
												data-field="totalSalesTax"
											>
												Sales Tax
											</span>
										</th>
										<th className="mw-160px">
											<span
												className={this.sortClass('totalDepositApplied')}
												data-field="totalDepositApplied"
											>
												Deposit Applied
											</span>
										</th>
										<th className="mw-180px">
											<span
												className={this.sortClass('balanceDue')}
												data-field="balanceDue"
											>
												Balance Due
											</span>
										</th>
										<th>
											<span
												className={this.sortClass('posemployee')}
												data-field="posemployee"
											>
												Manager
												<br />
												Salesperson
											</span>
										</th>
									</tr>
									<tr
										className={`a-table-search-fields ${
											this.state.showTableSearch ? '' : 'd-none'
										}`}
									>
										<th></th>
										<th>
											<Form.Control
												type="text"
												data-field="invnum"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('invnum')}
											/>
										</th>
										<th>
											<DateRangePicker
												style={{
													minWidth: '200px',
												}}
												placement="auto"
												placeholder="Select date"
												format="MM/dd/yyyy"
												defaultValue={this.fs.getValue('invdt')}
												onChange={this.handleDateChange.bind(this, 'invdt')}
												onClean={this.handleDateChange.bind(this, 'invdt')}
												ranges={[
													{
														label: 'today',
														value: [
															startOfDay(new Date()),
															endOfDay(new Date()),
														],
													},
													{
														label: 'yesterday',
														value: [
															startOfDay(addDays(new Date(), -1)),
															endOfDay(addDays(new Date(), -1)),
														],
													},
													{
														label: 'last7Days',
														value: [
															startOfDay(subDays(new Date(), 6)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'Last 30 Days',
														value: [
															startOfDay(subDays(new Date(), 30)),
															endOfDay(new Date()),
														],
													},
													{
														label: 'This month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()),
																	1
																)
															),
															endOfDay(lastDayOfMonth(new Date())),
														],
													},
													{
														label: 'Last month',
														value: [
															startOfDay(
																new Date(
																	getYear(new Date()),
																	getMonth(new Date()) - 1,
																	1
																)
															),
															endOfDay(
																lastDayOfMonth(
																	new Date(
																		getYear(new Date()),
																		getMonth(new Date()) - 1,
																		1
																	)
																)
															),
														],
													},
												]}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="proj"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('proj')}
											/>
										</th>
										<th></th>
										<th></th>
										<th></th>
										<th>
											<Form.Control
												type="text"
												data-field="propnum"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('propnum')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="txdesc"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('txdesc')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="totalPrice"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('totalPrice')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="totalSalesTax"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('totalSalesTax')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="totalDepositApplied"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('totalDepositApplied')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="balanceDue"
												data-type="number"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('balanceDue')}
											/>
										</th>
										<th>
											<Form.Control
												type="text"
												data-field="posemployee"
												onChange={this.handleSearch}
												defaultValue={this.fs.getValue('posemployee')}
											/>
										</th>
									</tr>
								</thead>
								<tbody>
									{!this.state.dataIsLoaded
										? showLoading()
										: this.state.data.length === 0 &&
										  isFiltered(this.state.searchProperties)
										? showEmpty()
										: this.state.data
												.slice(0, this.state.pageSize)
												.map((item, i) => (
													<tr
														key={i}
														data-key={i}
														className={
															includes(checks, item.invnum) ? `active` : ''
														}
													>
														<td>
															<div className="d-flex justify-content-center">
																<Form.Check
																	label=""
																	name={`group-` + i}
																	type="checkbox"
																	data-id={item.invnum}
																	id={`chk-projectview-items-` + i}
																	className="chk-projectview-items-item single-data-checkbox"
																	checked={includes(checks, item.invnum)}
																	onChange={() => {
																		this.handleCheck(item);
																	}}
																/>
															</div>
														</td>
														<td>
															<Link
																to={pathParam(
																	URI.accountsReceivable.clientInvoices.edit,
																	{
																		invoiceId: item.invnum,
																	}
																)}
																className="text-charcoal hover-view-icon"
															>
																{item.invnum}
															</Link>
														</td>
														<td>{formatDate(item.invdt)}</td>
														<td>{item.proj}</td>
														<td>{item.projectdet?.projn}</td>
														<td>{item.projectdet?.client}</td>
														<td>{item.projectdet?.clientName}</td>
														<td>{item.propnum}</td>
														<td>{item.txdesc}</td>
														<td>{currencyFormat(item.totalPrice)}</td>
														<td>{currencyFormat(item.totalSalesTax)}</td>
														<td>{currencyFormat(item.totalDepositApplied)}</td>
														<td>{currencyFormat(item.balanceDue)}</td>
														<td>{item.posemployee}</td>
													</tr>
												))}
								</tbody>
							</Table>
						</div>

						{this.state.data.length > 0 && (
							<Pagination
								onPageSizeChanged={this.onPageSizeChanged}
								onPageChanged={this.onPageChanged}
								hasPreviousPage={this.state.page > 1}
								hasNextPage={this.state.data.length > this.state.pageSize}
								page={this.state.page}
								pageSize={this.state.pageSize}
							/>
						)}

						{this.state.showPostModal && (
							<ClientInvoicesPostModal
								hideModal={this.hidePostModal}
								handleSave={this.handleSave}
								totalInvoices={this.state.checks.length ?? ''}
							/>
						)}
					</Col>
				</Row>
				<ConfirmModal
					show={this.state.showDeleteModal}
					toggleModal={this.toggleDeleteModal.bind(this, false)}
					confirmAction={this.onDeleteData.bind(this)}
				/>
			</>
		);
	}
}

export default WithRouter(ClientInvoicesListNew);
