import ThreadComponent from '../../ThreadComponent';
import { ApiService } from '../../../../lib/api/HttpService';
import { WithRouter } from '../../../../helpers/Router';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FooterFormAction } from '../../../components/Form';
import React from 'react';
import Select from 'react-select';
import { displayAlert } from '../../../../utilities/Response';
import WysiwygEditor from '../../../ui-kit/WysiwygEditor';
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import { RTFJS } from 'rtf.js';
import { stringToArrayBuffer } from '../../../../helpers/String';
import { reload } from '../../../../helpers/Util';

class WorkflowSettingsProposals extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			remarkshtml: '',
		};
		this.api = new ApiService();
		this.setData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.data !== prevProps.data) {
			this.setData();
		}

		this.dMloader(false, true);
	}

	setData() {
		this.styleOptions = [
			{ value: 0, label: 'Combine' },
			{ value: 1, label: 'List' },
			{ value: 2, label: 'Total' },
			{ value: 3, label: 'Ignore' },
		];

		this.checkboxes1 = [
			{
				label: 'Show Proposal Name',
				id: 'propsupname',
				checked: !this.props.data.company1.propsupname,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Proposal Number',
				id: 'propsupnum',
				checked: !this.props.data.company1.propsupnum,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Use Name as Number',
				id: 'propnameasnum',
				checked: this.props.data.company1.propnameasnum,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Suppress Loc. Heading',
				id: 'propsuploc',
				checked: this.props.data.company1.propsuploc,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Suppress Unit Prices',
				id: 'propsupunit',
				checked: this.props.data.company1.propsupunit,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Unit to 4 Decimal Places',
				id: 'propunit2dec',
				checked: this.props.data.company1.propunit2dec,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			// commenting out below option momentarily from the proposals tab
			// {
			//     label: 'Suppress Bid Spec Prices',
			//     id: 'propsupbidprice',
			//     checked: this.props.data.company2.propsupbidprice,
			//     onChange: (e, meta) => this.handleCompany2Change(e, meta),
			// },
		];
		this.checkboxes2 = [
			{
				label: 'Suppress Designer Signature',
				id: 'propsuppressdes',
				checked: this.props.data.company2.propsuppressdes,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
			{
				label: 'Remarks on New Page',
				id: 'propremsep',
				checked: this.props.data.company1.propremsep,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Site Address',
				id: 'propshowsite',
				checked: this.props.data.company1.propshowsite,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Show Project Code',
				id: 'propprojcode',
				checked: this.props.data.company1.propprojcode,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Suppress Sales Tax',
				id: 'propshowtax',
				checked: this.props.data.company1.propshowtax,
				onChange: (e, meta) => this.handleCompanyChange(e, meta),
			},
			{
				label: 'Allow One Proposal Per Item',
				id: 'oneproposal',
				checked: this.props.data.company2.oneproposal,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
			{
				label: 'Show Email & Website',
				id: 'propemail',
				checked: this.props.data.company2.propemail,
				onChange: (e, meta) => this.handleCompany2Change(e, meta),
			},
		];
		this.proposals = [
			{
				titleId: 'propdesigndesc',
				styleId: 'propdesignstyle',
				markUpKey: 'compviewd',
			},
			{
				titleId: 'propfreightdesc',
				styleId: 'propfreightstyle',
				markUpKey: 'compviewf',
			},
			{
				label: 'Install/Labor',
				titleId: 'propinstalldesc',
				styleId: 'propinstallstyle',
				markUpKey: 'compviewi',
			},
			{
				label: 'Crating',
				titleId: 'proplabordesc',
				styleId: 'proplaborstyle',
				markUpKey: 'compviewl',
			},
			{
				label: 'Time',
				titleId: 'propothersdesc',
				styleId: 'propothersstyle',
				markUpKey: 'compviewo',
			},
		];
		this.updatedCompanyData = {};
		this.updatedCompany2Data = {};
		this.htmlToRtfCoverter = new HtmlToRtfBrowser();

		this.mapRtfToDraft(this.props.data.company1.propremarksrtf);
	}

	handleCompanyChange = (e, meta) => {
		const keyValue = this.handleChange(e, meta);
		let key = keyValue.key;
		let value = keyValue.value;

		if (key === 'propsupname' || key === 'propsupnum') {
			value = !keyValue.value;
		}

		this.updatedCompanyData[key] = value;
	};

	handleCompany2Change = (e, meta) => {
		const keyValue = this.handleChange(e, meta);
		this.updatedCompany2Data[keyValue.key] = keyValue.value;
	};

	handleChange = (e, meta = {}) => {
		let key, value;
		if (meta && e.hasOwnProperty('value') && e.hasOwnProperty('label')) {
			// Select
			key = meta.name;
			value = e.value;
		} else if (e.hasOwnProperty('target')) {
			// Form
			key = e.target.id;
			if (e.target.hasOwnProperty('value')) {
				value = e.target.value;
			} else if (e.target.hasOwnProperty('checked')) {
				value = e.target.checked;
			}
		}

		return { key: key, value: value };
	};

	handleRemarks = (e) => {
		this.updatedCompanyData['propremarks'] = e.target.value;
		this.updatedCompanyData['propremarksrtf'] =
			this.htmlToRtfCoverter.convertHtmlToRtf(e.target.html);
	};

	handleSave = async (e) => {
		e.preventDefault();

		try {
			await this.api.patchCompany(this.updatedCompanyData);
			await this.api.patchCompany2(this.updatedCompany2Data);

			displayAlert('success', 'Successfully saved changes.');
			reload();
		} catch (error) {
			displayAlert('danger', error.response.data.userError);
		}
	};

	mapRtfToDraft(rtf) {
		if (!rtf) {
			return;
		}

		const self = this;
		const doc = new RTFJS.Document(stringToArrayBuffer(rtf), {});

		doc
			.render()
			.then(function (htmlElements) {
				const html = htmlElements
					.map((element) => {
						return element.outerHTML;
					})
					.join(' ');
				self.setState({
					remarkshtml: `${html}`,
				});
			})
			.catch((error) => console.error(error));
	}

	render() {
		return (
			<>
				<div className="content-padding min-height">
					<Container className="mb-4">
						<Col>
							<Row className={'mb-2'}>
								<Col xs={11} lg={6}>
									<Row>
										<Col xs={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 mt-2 pt-1">
												Proposal Title
											</Form.Label>
										</Col>
										<Col xs={4}>
											<Form.Control
												type="text"
												maxLength={30}
												placeholder=""
												id="proptitle"
												defaultValue={this.props.data.company1.proptitle}
												onChange={this.handleCompanyChange}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="flex-nowrap overflow-auto">
								<Col xs={11} lg={6}>
									<Row className={'flex-nowrap mb-2'}>
										<Col xs={3} />
										<Col className="mb-2 mb-lg-0">
											<Form.Label className="mb-0 mt-2 pt-1">Title</Form.Label>
										</Col>
										<Col>
											<Form.Label className="mb-0 mt-2 pt-1">Style</Form.Label>
										</Col>
									</Row>

									{this.proposals.map((proposal) => {
										return (
											<Row
												key={`${Math.floor(Math.random() * 10000)}-min`}
												className={'flex-nowrap mb-2'}
											>
												<Col xs={3} className="text-lg-end mb-2 mb-lg-0">
													<Form.Label className="mb-0 mt-2 pt-1">
														{this.props.data.company1[proposal.markUpKey]}
													</Form.Label>
												</Col>
												<Col className="text-lg-end mb-2 mb-lg-0">
													<Form.Control
														type="text"
														maxLength={30}
														placeholder=""
														id={proposal.titleId}
														defaultValue={
															this.props.data.company1[proposal.titleId]
														}
														onChange={this.handleCompanyChange}
													/>
												</Col>
												<Col>
													<Select
														menuPortalTarget={document.body}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 9999,
															}),
														}}
														options={this.styleOptions}
														className="react-select"
														defaultValue={
															this.styleOptions.filter((option) => {
																return (
																	option.value ===
																	this.props.data.company1[proposal.styleId]
																);
															})[0]
														}
														placeholder="Please select"
														name={proposal.styleId}
														onChange={this.handleCompanyChange}
													/>
												</Col>
											</Row>
										);
									})}
								</Col>
								<Col xs={12} md={6} className={'ps-4'}>
									<Row>
										<Col>
											{this.checkboxes1.map((checkbox) => {
												return (
													<Row key={`${Math.floor(Math.random() * 10000)}-min`}>
														<Form.Check
															inline
															className={'mb-2'}
															label={checkbox.label}
															name={checkbox.id}
															type="checkbox"
															id={checkbox.id}
															defaultChecked={checkbox.checked}
															onChange={checkbox.onChange}
														/>
													</Row>
												);
											})}
										</Col>
										<Col>
											{this.checkboxes2.map((checkbox) => {
												return (
													<Row key={`${Math.floor(Math.random() * 10000)}-min`}>
														<Form.Check
															inline
															className={'mb-2'}
															label={checkbox.label}
															name={checkbox.id}
															type="checkbox"
															id={checkbox.id}
															defaultChecked={checkbox.checked}
															onChange={checkbox.onChange}
														/>
													</Row>
												);
											})}
										</Col>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col xs={11} lg={6}>
									<Row>
										<Col xs={3} className="text-lg-end mb-2 mb-lg-0">
											<Form.Label className="mb-0 mt-2">Remarks</Form.Label>
										</Col>
										<Col>
											<WysiwygEditor onChange={this.handleRemarks}>
												{this.state.remarkshtml}
											</WysiwygEditor>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Container>
				</div>
				<FooterFormAction>
					<Button variant="primary" size="lg" onClick={this.handleSave}>
						Save
					</Button>
				</FooterFormAction>
			</>
		);
	}
}

export default WithRouter(WorkflowSettingsProposals);
