import React from 'react';
import { Form } from 'react-bootstrap';
import { delay } from 'lodash';
import MultiSearchComponents, {
	MultiSearchComponentsProps,
	MultiSearchComponentsState,
} from './MultiSearchComponents';
import ComboBox from '../../../templates/components/ComboBox';

export default class MultiSearchComponentsCombobox extends MultiSearchComponents {
	constructor(props: MultiSearchComponentsProps) {
		super(props);
		this.state = {
			props,
			comboSelected: props.defaultValue ?? '',
			cleared: false,
		};
	}

	componentDidUpdate(
		prevProps: Readonly<MultiSearchComponentsProps>,
		prevState: Readonly<MultiSearchComponentsState>
	): void {
		const { isClear } = this.props;
		const { comboSelected } = this.state;

		if (prevProps !== this.props) {
			this.setState({
				props: this.props,
				cleared: isClear,
				comboSelected: isClear ? [] : comboSelected,
			});
		}
	}

	render() {
		const { props, comboSelected } = this.state;

		return (
			<div
				className={`position-relative me-2 rounded-1 ${
					this.isFixed ? '' : 'border border-sand pe-2 bg-white mb-2'
				}`}
				style={{
					minWidth: '110px',
					...props.style,
					zIndex: 700 - (props?.zIndex ?? 0),
				}}
			>
				<div className="d-flex align-items-center justify-content-between">
					<ComboBox
						{...props}
						id={props.id}
						title={props.title}
						label={props.label}
						type={props.moduletype}
						onSelect={props.handleChangeField}
						filters={props.queryFilter}
						onClear={this.state.cleared}
						field={props.field}
						getDataFn={props.getDataFn}
						className="w-100"
						zIndex={props.zIndex ?? 10}
						isFixed={props.isFixed}
						selected={comboSelected}
					/>
					{!this.isFixed && (
						<a
							className="ff-close-dropdown"
							onClick={() => {
								props.handleDetach(props.id);
							}}
						>
							<i className="ri-close-fill"></i>
						</a>
					)}
				</div>
			</div>
		);
	}
}
