import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Buttons from './../../ui-kit/Buttons';
import FormControls from './../../ui-kit/FormControls';
import Badges from './../../ui-kit/Badges';
import Progress from './../../ui-kit/Progress';
import Toggle from './../../ui-kit/Toggle';
import RadioCheckbox from './../../ui-kit/RadioCheckbox';
import Menu from './../../ui-kit/Menu';
import Headers from './../../ui-kit/Headers';
import ActionBarUi from './../../ui-kit/ActionBar';
import InputBarUi from './../../ui-kit/InputBar';
import TableUi from './../../ui-kit/Table';
import Modals from './../../ui-kit/Modals';
import InputGroup from './../../ui-kit/InputGroup';
import InputTable from './../../ui-kit/InputTable';
import Clipper from './../../ui-kit/Clipper';
import ReportingTreeView from './../../ui-kit/ReportingTreeView';
import ThreadComponent from '../ThreadComponent';
import WysiwygEditor from '../../ui-kit/WysiwygEditor';

class UiKitComponents extends ThreadComponent {
	render() {
		return (
			<Container className="px-0" fluid>
				<div className="bg-light pt-5 pb-4">
					<Container>
						<Row>
							<Col>
								<Breadcrumb className="mt-2">
									<Breadcrumb.Item href="/">UI Kit</Breadcrumb.Item>
									<Breadcrumb.Item href="/components">
										Components
									</Breadcrumb.Item>
								</Breadcrumb>
								<h2 className="fw-normal ff-type-medium">Components</h2>
							</Col>
						</Row>
					</Container>
				</div>
				<Row>
					<Col>
						<Buttons></Buttons>
					</Col>
				</Row>
				<Row>
					<Col>
						<Toggle></Toggle>
					</Col>
				</Row>
				<Row>
					<Col>
						<RadioCheckbox></RadioCheckbox>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormControls></FormControls>
					</Col>
				</Row>
				<Row>
					<Col>
						<Badges></Badges>
					</Col>
				</Row>
				<Row>
					<Col>
						<Progress></Progress>
					</Col>
				</Row>
				<Row>
					<Col>
						<Menu></Menu>
					</Col>
				</Row>
				<Row>
					<Col>
						<Headers></Headers>
					</Col>
				</Row>
				<Row>
					<Col>
						<ActionBarUi></ActionBarUi>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputBarUi></InputBarUi>
					</Col>
				</Row>
				<Row>
					<Col>
						<Modals></Modals>
					</Col>
				</Row>
				<Row>
					<Col>
						<TableUi></TableUi>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup></InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputTable></InputTable>
					</Col>
				</Row>
				<Row>
					<Col>
						<Clipper></Clipper>
					</Col>
				</Row>
				<Row>
					<Col>
						<ReportingTreeView></ReportingTreeView>
					</Col>
				</Row>

				<div className="bg-secondary-grey pt-5 pb-4 mt-5">
					<Container>
						<Row>
							<Col>
								<h2 className="ui-title">Wysiwyg</h2>
							</Col>
						</Row>
					</Container>
				</div>
				<Container className="my-4">
					<Row>
						<Col lg={6}>
							<WysiwygEditor></WysiwygEditor>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}

export default UiKitComponents;
