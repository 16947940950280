import Spinner from 'legacy/app/components/help/Spinner';
import useStartPaymentProcessorSession from 'legacy/lib/api/hooks/useStartPaymentProcessorSession';
import { PaymentSessionType } from 'legacy/lib/api/types/PaymentSessionType';
import React from 'react';

const PaymentsReport = (): JSX.Element | null => {
	const {
		data: sessionInfo,
		isLoading,
		error,
	} = useStartPaymentProcessorSession(PaymentSessionType.PAYMENTREPORT);

	if (isLoading) {
		return <Spinner isChild />;
	}

	if (error) {
		return null;
	}

	return (
		<rainforest-payment-report
			show-export-button
			data-filters={JSON.stringify({
				merchant_id: sessionInfo?.merchantId,
			})}
			session-key={sessionInfo?.sessionKey}
		/>
	);
};

PaymentsReport.displayName = 'PaymentsReport';

export default PaymentsReport;
