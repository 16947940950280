import { CompNumericPercentType } from 'legacy/lib/api/types/CompPercentType';
import { Component } from 'legacy/lib/api/types/Component';
import { TFormValues } from '../types/TFormValues';

const normalizeRecalculateDataPayload = (values: TFormValues | Component) => {
	const {
		supplier,
		usedisc,
		feecost,
		feeunitcost,
		estprice,
		useterms,
		estdisc,
		list,
		unitlist,
		bterm1,
		markup,
		supdep,
		estmarkup,
		estcost,
		estunitcost,
		comptype,
		quantity,
		bterm2,
		bterm3,
		taxable,
	} = values;

	const compPercentType = usedisc;
	const base = {
		comptype,
		supdep,
		quantity,
		usedisc,
		useterms,
		supplier: supplier || null,
		markup,
		bterm1,
		bterm2,
		bterm3,
		taxable,
		estprice,
	};

	if (useterms) {
		return {
			...base,
			estcost,
			estdisc,
			estunitcost,
			list,
			unitlist,
		};
	}

	if (compPercentType === CompNumericPercentType.Fee) {
		return {
			...base,
			feecost,
			estmarkup,
			feeunitcost,
		};
	} else if (compPercentType === CompNumericPercentType.Markup) {
		return {
			...base,
			estcost,
			estmarkup,
			estunitcost,
		};
	}

	return {
		...base,
		estcost,
		estdisc,
		estunitcost,
		list,
		unitlist,
	};
};
export default normalizeRecalculateDataPayload;
