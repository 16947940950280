import { Col } from 'react-bootstrap';

import FormRow from './FormRow';

const FormHeading = ({ children }) => (
	<FormRow>
		<Col>
			<div className="font-bold mb-3">{children}</div>
		</Col>
	</FormRow>
);

export default FormHeading;
